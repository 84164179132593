import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { createCategory } from '../api'
import CategoryCreateForm from '../components/CategoryCreateForm'
import { categoryCreateSerialize } from '../serializer'

function CategoryCreateContainer () {
  const categoryCreate = usePost(createCategory)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = useCallback((values: any) => {
    return categoryCreate.postData({ data: categoryCreateSerialize(values) })
      .then(() => snackbar({ message: 'Продуктовая корзина успешно создана' }))
      .then(() => navigate(ROUTES.CATALOG_CATEGORY_LIST))
  }, [])

  return (
    <AppLayout>
      <CategoryCreateForm
        onSubmit={handleSubmit}
        initialValues={{ territories:[] }}
      />
    </AppLayout>
  )
}

export default CategoryCreateContainer
