import React, { ReactNode } from 'react'

interface Props {
  readonly justify?: 'start' | 'space-around' | 'space-evenly' | 'space-between' | 'center' | 'flex-end' | 'flex-start'
  readonly align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  readonly direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  readonly wrap?: boolean
  readonly gap?: string | number
  readonly style?: object
  readonly className?: string
  readonly flex?: boolean
  readonly children: ReactNode
}

function FlexBox ({ justify, align, gap, children, direction, wrap, className, flex, style }:Props) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: justify,
        flexDirection: direction,
        alignItems: align,
        flexWrap: wrap ? 'wrap' : undefined,
        gap: gap,
        flex: flex ? '1 1' : '',
        ...style
      }}
      className={className}
    >
      {children}
    </div>
  )
}

export default FlexBox
