import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getNewsList = (options?: Options) => {
  return getRequest<any>(API.NewsList, options)
}

export const newsCreate = (options?: Options) => {
  return postRequest<any>(API.NewsCreate, options)
}

export const newsUpdate = (options?: Options) => {
  return putRequest<any>(API.NewsUpdate, options)
}

export const newsGetById = (options?: Options) => {
  return getRequest<any>(API.NewsGetById, options)
}

export const newsDelete = (options?: Options) => {
  return deleteRequest<any>(API.NewsDelete, options)
}

export const newsDeleteByIds = (options?: Options) => {
  return deleteRequest<any>(API.NewsDeleteByIds, options)
}
