import React, { ReactElement, ReactNode } from 'react'

import { FilterPopup } from '../main/FilterPopup'

interface Props {
  readonly open: boolean;
  readonly setOpen: (value: boolean) => void
  readonly filterNode?: ReactNode
}

interface FilterNodeProps {
  open: boolean;
  setOpen: (value:boolean) => void;
}

function TableFilterPopup ({ open, setOpen, filterNode }: Props) {
  if (!filterNode) {
    return null
  }

  const clonedFilterNode = React.cloneElement(filterNode as ReactElement<FilterNodeProps>, {
    open,
    setOpen,
  })

  return (
    <FilterPopup
      open={open}
      onClose={() => setOpen(false)}
    >
      {open && clonedFilterNode}
    </FilterPopup>
  )
}

export default TableFilterPopup
