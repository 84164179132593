import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { propOr, trim } from 'ramda'

import { sharedContactCategoryDelete, getSharedContactCategoryList } from '../api'
import AgentCategoryRowMenu from '../components/AgentCategoryRowMenu'

const columns = [
  {
    headerName: 'Название',
    field: 'name',
  },
  {
    headerName: 'Дата создания',
    field: 'createdDate',
  }
]

function SharedContactCategoryListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const sharedContactCategoryList = useGetList(getSharedContactCategoryList)
  const deleteSharedContactCategory = useDelete(sharedContactCategoryDelete)

  const handleDelete = useCallback((values:any) => {
    const message = `Вы уверены что хотите удалить категория контрагента ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        deleteSharedContactCategory.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Поделенная категория контакты успешно удалена' }))
          .then(() => sharedContactCategoryList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    sharedContactCategoryList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Поделенная категория контакты"
        onAddClick={() => navigate(ROUTES.BACKEND_SHARED_CONTACT_CATEGORY_CREATE)}
        dataRequest={sharedContactCategoryList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row:any) => (
          <AgentCategoryRowMenu
            item={row}
            handleDelete={handleDelete}
          />
        )}
      />
    </AppLayout>
  )
}

export default SharedContactCategoryListContainer
