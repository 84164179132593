import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useField } from 'formik'
import { makeStyles } from '@mui/styles'
import FlexBox from 'components/main/FlexBox'
import { propOr } from 'ramda'

const useStyles = makeStyles({
  autocomplete: {
    width:'100%',
    '& .MuiAutocomplete-input':{
      padding:'5px 10px 5px 18px!important'
    },
    '& .MuiAutocomplete-root .MuiOutlinedInput-root':{
      paddingRight:0
    },
  },
  field: {
    '& .MuiInputBase-root': {
      borderRadius: '5px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#1557FF'
      },
      '& .MuiInputBase-input': {
        color: '#202020',
        background: 'white'
      },
      '& ::placeholder': {
        opacity: 1,
        color: '#7c7c7c',
        fontWeight: '500'
      }
    },
  }
})

interface Props {
  name: string,
  disabled?: boolean,
  size?: 'medium' | 'small',
  label: string,
}
const MultipleField = ({ name, label, disabled, size = 'small', ...props }: Props) => {
  const [input, meta, helpers] = useField(name)
  const classes = useStyles()
  const [tags, setTags] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (event: any, value: string) => {
    setInputValue(value)
  }

  const handleBlur = () => {
    // Add inputValue to tags when the field loses focus, if it's not empty
    if (inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()])
      helpers.setValue([...tags, inputValue.trim()]) // Update Formik value as well
      setInputValue('') // Clear the input field
    }
  }

  const submitError = propOr(null, 'submitError', meta)
  const stringOfSubmitError = submitError ? JSON.stringify(submitError) : ''

  return (
    <FlexBox align="center" gap="8px" flex={true}>
      <Autocomplete
        className={classes.autocomplete}
        multiple
        disabled={disabled}
        options={tags}
        size={size}
        value={input.value || tags}
        onChange={(event, value) => {
          helpers.setValue(value)
        }}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        freeSolo
        onBlur={handleBlur}
        renderInput={(params) => (
          <TextField
            className={classes.field}
            label={label}
            variant="outlined"
            error={!!meta.error}
            helperText={stringOfSubmitError || meta.error}
            {...props}
            {...params}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                if (inputValue.trim() !== '') {
                  setTags([...tags, inputValue.trim()])
                  setInputValue('')
                }
              }
            }}
          />
        )}
      />
    </FlexBox>
  )
}

export default MultipleField
