import * as API from 'constants/Api'

import React from 'react'
import { prop } from 'ramda'
import MultiSelectSearchField from 'components/form/MultiSelectSearchField'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)
    const code = prop('code', option)

    return { value, name, id: value, code }
  }

  return null
}

function AccountField (props: any) {
  return (
    <MultiSelectSearchField
      name="employeeIds"
      api={API.EmployeeSuggestionList}
      getOptionValue={getOptionValue}
      label="Аккаунт"
      params={{ query:{ type:'' } }}
      {...props}
    />
  )
}

export default AccountField
