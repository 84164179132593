import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'
import { GetListResponse } from 'api/BaseDto'

export const getBillingList = (options?: Options) => {
  return getRequest<GetListResponse<any>>(API.BillingPlanList, options)
}

export const getBillingDetail = (options?:Options) => {
  return getRequest<GetListResponse<any>>(API.BillingPlanDetail, options)
}

export const createBilling = (options?: Options) => {
  return postRequest<GetListResponse<any>>(API.BillingPlanCreate, options)
}

export const updateBilling = (options?: Options) => {
  return putRequest<GetListResponse<any>>(API.BillingPlanUpdate, options)
}

export const deleteBilling = (options?: Options) => {
  return deleteRequest<GetListResponse<any>>(API.BillingPlanDelete, options)
}

export const deleteBillings = (options?:Options) => {
  return deleteRequest<any>(API.BillingPlanDeleteByIds, options)
}

export const availableColumns = (options?:Options) => {
  return getRequest<any>(API.ListingFilterGetByType, options)
}

export const contactsGetByIds = (options?:Options) => {
  return postRequest<any>(API.ContactsGetByIds, options)
}

export const sharedContactCategoryList = (options?:Options) => {
  return getRequest<any>(API.SharedContactCategorySuggestions, options)
}
