import * as ROUTES from 'constants/Routes'

import * as Yup from 'yup'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { createTaskReturn } from '../api'
import TaskReturnCreateForm from '../components/TaskReturnCreateForm'
import { taskReturnSerializer } from '../serializer'

export const validationSchema = Yup.object({
  territories:Yup.array().min(1, 'Требуется территория.').required('Обязательный.'),
})

function TaskReturnCreateContainer () {
  const returnTaskCreate = usePost(createTaskReturn)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = useCallback((values: any) => {
    return returnTaskCreate.postData({ data: taskReturnSerializer(values) })
      .then(() => snackbar({ message: 'Повторяющееся таски успешно создана.' }))
      .then(() => navigate(ROUTES.CRM_RETURN_TASK_LIST))
  }, [])

  return (
    <AppLayout>
      <TaskReturnCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default TaskReturnCreateContainer
