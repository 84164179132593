import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'
import { useGetList } from 'hooks/useGetList'
import { prop } from 'ramda'
import { usePut } from 'hooks/usePut'

import FilterCreateForm from './components/FilterCreateForm'
import { filterInitialValues, orderFilterSerializer } from './serializer'
import { filterValidationSchema } from './filter-form-values'

import { createFilter, getAvailableColumns, updateFilter } from '../../api'

interface Props {
  readonly onBack:() => void
  readonly filterList:any
  readonly onDelete:any
  readonly onFilter:any
  readonly onFilterPrev:any
  readonly filter:any
  readonly filterPreviewData:any
  readonly setPinFilter:() => void
}

const FilterListingCreate = ({
  onBack,
  filterList,
  onDelete,
  onFilter,
  onFilterPrev,
  filter,
  filterPreviewData,
  setPinFilter
}:Props) => {
  const snackbar = useSnackbar()
  const filterCreate = usePost(createFilter)
  const availableColumns = useGetList(getAvailableColumns, { params: { type: 'PRODUCT_LIST_PANEL' } })
  const [isNewFilter, setIsNewFilter] = useState(false)
  const list = prop('list', availableColumns)
  const filterUpdate = usePut(updateFilter)
  const isHasFilterPreview = Object.keys(filterPreviewData).length > 0

  useEffect(() => {
    availableColumns.getList()
  }, [])

  const onUpdate = useCallback((values:any) => {
    filterUpdate.putData({ data:orderFilterSerializer(values) })
      .then(() => onBack())
      .then(() => snackbar({ message: 'Фильтр успешно обновлён' }))
  }, [])

  const onCreate = useCallback((values:any, form:any) => {
    filterCreate.postData({ data:orderFilterSerializer(values) })
      .then(() => onBack())
      .then(() => filterList.getList())
      .then(() => snackbar({ message: 'Фильтр успешно создан' }))
      .then(() => form.resetForm({ type: 'PRODUCT_LIST_PANEL', criteria: [] }))
      .then(() => availableColumns.getList())
  }, [])

  const initialValues = useMemo(() => {
    if (isNewFilter) {
      return { name:'', type: 'PRODUCT_LIST_PANEL', criteria: list }
    }
    if (isHasFilterPreview) {
      return filterInitialValues(filterPreviewData)
    } else {
      return filterInitialValues(filter)
    }
  }, [filter, isNewFilter, list, isHasFilterPreview, filterPreviewData])

  const onSubmit = isNewFilter ? onCreate : onUpdate
  return (
    <>
      <FilterCreateForm
        setPinFilter={setPinFilter}
        isHasFilterPreview={isHasFilterPreview}
        onBack={onBack}
        setIsNewFilter={setIsNewFilter}
        filter={initialValues}
        onFilterPrev={onFilterPrev}
        onFilter={onFilter}
        onDelete={onDelete}
        availableColumns={availableColumns}
        filterList={filterList}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={filterValidationSchema}
      />
    </>
  )
}

export default FilterListingCreate
