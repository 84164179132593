import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getPackageList = (options?: Options) => {
  return getRequest<any>(API.PackageList, options)
}

export const getPackageDetail = (options?: Options) => {
  return getRequest<any>(API.PackageGetDetail, options)
}

export const createPackage = (options?: Options) => {
  return postRequest<any>(API.PackageCreate, options)
}

export const updatePackage = (options?: Options) => {
  return putRequest<any>(API.PackageUpdate, options)
}

export const deletePackage = (options?: Options) => {
  return deleteRequest<any>(API.PackageDelete, options)
}

export const deletePackageByIds = (options?: Options) => {
  return deleteRequest<any>(API.PackageDeleteByIds, options)
}
