import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid, IconButton,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import TextField from 'components/form/TextField'
import CardHeader from 'components/card/CardHeader'
import { FieldArray, useField } from 'formik'
import { useTranslationI18n } from 'i18n/I18nContext'
import SkeletonList from 'components/skeleton/SkeletonList'
import { pathOr, prop, propOr } from 'ramda'
import useDialog from 'hooks/useDialog'
import { formatMoney, toNumberFormat } from 'utils/formatMoney'
import { makeStyles } from '@mui/styles'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import DoubleFormatField from 'components/form/DoubleFormatField'
import Typography from '@mui/material/Typography'

import SaleOrderVariantAddDialog from './SaleOrderVariantAddDialog'
import DiscountField from './DiscountField'

export const useStyles = makeStyles({
  tableRoot:{
    maxHeight:'460px',
    borderRadius:'7px',
    border:'1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      cursor:'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly loading?: boolean
  readonly productList: any
  readonly disabled?:boolean
  readonly handleGetPriceLevel: (price: any, product: any) => Promise<any>
}

export function LineItems ({ loading, productList, handleGetPriceLevel, disabled }: Props) {
  const classes = useStyles()
  const variantAddDialogue = useDialog()
  const { t } = useTranslationI18n()
  const [levelId, setLevelId] = useState<any>(0)

  const [input, meta, field] = useField('items')
  const lineItems = input.value || []
  const lineItemsChange = field.setValue
  const error = meta.error

  const priceLevelField = useField('priceLevelId')
  const priceLevel = priceLevelField[0].value
  const priceLevelId = propOr(priceLevel, 'id', priceLevel)
  const warehouseGroupField = useField('warehouseGroupId')
  const warehouseGroup = warehouseGroupField[0].value
  const warehouseGroupId = prop('id', warehouseGroup)
  useEffect(() => {
    const newLineItems = lineItems
    if (priceLevelId !== levelId && priceLevelId && lineItems.length > 0) {
      setLevelId(priceLevelId)
      for (let i = 0; i < newLineItems.length; i++) {
        const item = newLineItems[i]
        const productId = prop('productId', item)
        handleGetPriceLevel(priceLevelId, productId).then((res) => {
          newLineItems[i].salesPrice = formatMoney(pathOr(0, ['list', 0, 'value'], res))
          if (i === newLineItems.length - 1) {
            lineItemsChange(newLineItems)
            variantAddDialogue.handleClose()
          }
        })
      }
    }
  }, [priceLevelId, lineItems, levelId])

  useEffect(() => {
    if (!warehouseGroupId) {
      lineItemsChange([])
    }
  }, [warehouseGroupId])

  const handleVariantsAdd = useCallback((variants: any) => {
    const lineItemPackageIds = new Set(lineItems.map((item: any) => prop('id', item)))

    const uniquePackages = variants.filter((item: any) => {
      const packageId = prop('id', item)
      return !lineItemPackageIds.has(packageId)
    })
    const allVariants = uniquePackages.map((item: any) => {
      const productId = prop('id', item)
      const productName = prop('productName', item)
      const quantity = 1
      const salesPrice = formatMoney(0)
      return {
        productId,
        product: { name: productName },
        salesPrice,
        quantity
      }
    })

    const otherLineItems = lineItems.filter((lineItem: any) => {
      return variants.find((item: any) => prop('productId', item) === prop('productId', lineItem))
    })

    const addVariants = allVariants.filter((variant: any) => variant)
    for (let i = 0; i < addVariants.length; i++) {
      const variant = addVariants[i]
      handleGetPriceLevel(priceLevelId, variant.productId).then((res) => {
        addVariants[i].salesPrice = pathOr(0, ['list', 0, 'value'], res)
        if (i === addVariants.length - 1) {
          setLevelId(priceLevelId)
          lineItemsChange([...otherLineItems, ...addVariants])
          variantAddDialogue.handleClose()
          productList.reset()
        }
      })
    }

    if (allVariants.length === 0 || !allVariants) {
      lineItemsChange([...otherLineItems, ...addVariants])
    }
    productList.reset()
    variantAddDialogue.handleClose()
  }, [lineItems, priceLevelId])

  return (
    <Card>
      <CardHeader title="Продукты" />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={10}>
            <TextField name="browse" disabled={!priceLevelId} />
          </Grid>
          <Grid item={true} lg={2}>
            <Button
              fullWidth={true}
              onClick={() => variantAddDialogue.handleOpen()}
              disabled={!priceLevelId || !warehouseGroupId || disabled}
            >
              Просмотреть
            </Button>
          </Grid>
          {variantAddDialogue.open && (
            <SaleOrderVariantAddDialog
              open={variantAddDialogue.open}
              onClose={variantAddDialogue.handleClose}
              onAddVariants={handleVariantsAdd}
              productList={productList}
              warehouseGroupId={warehouseGroupId}
            />
          )}
          <FieldArray name="items">
            {(fields: any) => {
              const renderLineItems = Boolean(!loading && lineItems.length)
              return (
                <Grid item={true} lg={12}>
                  <TableContainer className={classes.tableRoot}>
                    <Table size="small" stickyHeader aria-label="sticky table">
                      <TableHead className={classes.tableHeader}>
                        <TableRow>
                          <TableCell width="30%">{t('Товар')}</TableCell>
                          <TableCell width="10%">{t('Количество')}</TableCell>
                          <TableCell width="10%">{t(`Стоимость`)}</TableCell>
                          <TableCell width="10%">{t(`Скидка`)}</TableCell>
                          <TableCell width="20%">{t('Итого')}</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBody}>
                        {loading && <SkeletonList columnCount={7} />}
                        {renderLineItems && lineItems.map((item: any, index: number) => {
                          const productName = pathOr('', ['product', 'name'], item)
                          const salesPrice = prop('salesPrice', item)
                          const quantity = propOr(0, 'quantity', item) as number
                          const totalPrice = toNumberFormat(salesPrice) * quantity
                          return (
                            <TableRow>
                              <TableCell>{productName}</TableCell>
                              <TableCell>
                                <TextField name={`items[${index}].quantity`} type="number" />
                              </TableCell>
                              <TableCell>
                                <DoubleFormatField name={`items[${index}].salesPrice`} />
                              </TableCell>
                              <TableCell>
                                <DiscountField name={`items[${index}].discountId`} />
                              </TableCell>
                              <TableCell>{formatMoney(totalPrice)}</TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => fields.remove(index)}
                                  size="large"
                                  disabled={disabled}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {error && <Typography color="error" ml={2} py={2} variant="caption">{error}</Typography>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            }}
          </FieldArray>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default LineItems
