import React from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import FormHelperText from '@mui/material/FormHelperText'
import { ClassNames } from '@emotion/react'
import 'react-phone-input-2/lib/material.css'
import { useField } from 'formik'
import { makeStyles } from '@mui/styles'

const SMALL = 'small'

const useStyles = makeStyles({
  phoneField:{
    '& .special-label':{
      fontSize:'10px!important',
      lineHeight:'16px'
    }
  }
})

function PhoneField ({ name, size, label, invalid, helperText, required, ...props }: any) {
  const field = useField(name)
  const labelWithReq = required ? label + ' *' : label
  const classes = useStyles()
  return (
    <>
      <ClassNames>
        {({ css, cx }) => {
          const inputClass = css({
            borderRadius: '5px !important',
            outline: 'none !important',
            fontSize: '13px !important',
            width: '100% !important',
            padding:'12px 10px 12px 40px!important',
            '&:focus': {
              border: '1px solid #1557FF !important'
            }
          })

          const smallClass = css({
            height: '40px',
            paddingTop: '12px',
            paddingBottom: '12px'
          })

          return (
            <ReactPhoneInput
              inputExtraProps={{ required: true }}
              specialLabel={labelWithReq}
              className={classes.phoneField}
              inputClass={cx({
                [inputClass]: true,
                [smallClass]: size === SMALL
              }
              )}
              country="uz"
              countryCodeEditable={false}
              masks={{ 'uz': '(..) ...-..-..' }}
              placeholder="+998 (91) 234-56-78"
              disableDropdown={true}
              isValid={!invalid}
              onChange={field[2].setValue}
              {...props}
              inputProps={{
                'data-cy' : props['data-cy']
              }}
              {...props}
              value={field[0].value}
              name={name}
            />
          )
        }}
      </ClassNames>
      {invalid && (
        <FormHelperText sx={{ color: 'error.main' }}>{helperText}</FormHelperText>
      )}
    </>
  )
}

export default PhoneField
