import React, { useCallback, useMemo, useState } from 'react'
import { pathOr, propOr } from 'ramda'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { usePut } from 'hooks/usePut'
import useDialog from 'hooks/useDialog'
import { ALTER_ERROR, useSnackbar } from 'context/snackbar'

import { changePicklistStatus, getPickList, mergePicklist, pdfExport } from '../api'
import PickerStatusRow from '../components/PickStatusRow'
import PickRowMenu from '../components/PickRowMenu'
import TableActionsRow from '../components/TableActionsRow'
import PicklistPickerDialogue from '../components/PicklistPickerDialogue'
import { getWarehouseIdIfAllItemsAreEqual, PicklistPickerSerializer } from '../serializer'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 150
  },
  {
    headerName: 'Номер',
    field: 'number',
  },
  {
    headerName: 'Склад',
    field: 'warehouse',
    renderCell:(item:any) => pathOr('', ['warehouse', 'name'], item)
  },
  {
    headerName: 'Сборщик',
    field: 'picker',
    renderCell:(item:any) => pathOr('', ['picker', 'name'], item)
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell:(item:any) => <PickerStatusRow status={item.status} />
  },
  {
    headerName: 'Количество',
    field: 'itemsCount',
  },
  {
    headerName: 'Номера заказов',
    field: 'orderNumbers',
  }
]

function PickerListContainer () {
  const snackbar = useSnackbar()
  const pickerDialogue = useDialog()

  const pickList = useGetList(getPickList)
  const setPickerBulk = usePut(changePicklistStatus)
  const picklistMerge = usePut(mergePicklist)
  const picklistGetPDF = usePut(pdfExport)
  const [selectedRows, setSelectedRows] = useState([])

  const selectedIds = useMemo(() => {
    const allMatchStatus = selectedRows.every((item: any) =>
      item.status === 'BOOKED' || item.status === 'IN_PROCESS'
    )

    return allMatchStatus ? selectedRows.map((item: any) => item.id) : null
  }, [selectedRows])

  const onMergePicklist = useCallback(() => {
    if (getWarehouseIdIfAllItemsAreEqual(selectedRows)) {
      if (selectedIds) {
        picklistMerge.putData({ data:getWarehouseIdIfAllItemsAreEqual(selectedRows) })
          .then(() => snackbar({ message: 'Выбранный раскрывающийся список успешно объединен' }))
          .then(() => pickList.getList())
          .then(() => setSelectedRows([]))
      } else {
        snackbar({ message:'Пожалуйста, выберите товар со статусом забронирования и в процессе', type:ALTER_ERROR })
      }
    } else {
      snackbar({ message:'Пожалуйста, выберите товар на том же складе', type:ALTER_ERROR })
    }
  }, [selectedRows])

  const onPickerBulk = useCallback((values:any) => {
    if (selectedIds) {
      setPickerBulk.putData({ data: PicklistPickerSerializer(values) })
        .then(() => snackbar({ message: 'Пикер успешно выбрал' }))
        .then(() => pickList.getList())
        .then(() => setSelectedRows([]))
        .then(() => pickerDialogue.handleClose())
    } else {
      snackbar({
        message: 'Пожалуйста, выберите товар со статусом забронирования и в процессе',
        type: ALTER_ERROR,
      })
      pickerDialogue.handleClose()
      setSelectedRows([])
    }
  }, [selectedIds])

  const onPrintPDF = useCallback((id: number) => {
    picklistGetPDF.putData({ params: { id }, data: { id }, responseType: 'blob' })
      .then((response: any) => {
        // Convert response to blob
        const blob = new Blob([response], { type: 'application/pdf' })

        // Create a URL for the blob
        const pdfUrl = window.URL.createObjectURL(blob)

        // Open a new window with the PDF
        const newWindow = window.open(pdfUrl, '_blank')

        // Check if the window opened successfully
        if (newWindow) {
          // Add a print event listener
          newWindow.addEventListener('load', () => {
            newWindow.print()
          })

          // Cleanup
          window.URL.revokeObjectURL(pdfUrl)
        }
      })
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = propOr('', 'search', value)
    pickList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Сбор"
        dataRequest={pickList}
        columns={columns}
        onSearchClick={onTableSearch}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row:any) => <PickRowMenu item={row} onPrint={onPrintPDF} />}
        tableActions={(<TableActionsRow
          onMerge={onMergePicklist}
          onPickerBulk={pickerDialogue.handleOpen}
          hasMerge={selectedRows.length > 1}
        />
        )}
      />
      {pickerDialogue.open && (
        <PicklistPickerDialogue
          open={pickerDialogue.open}
          initialValues={{ picklistIds:selectedIds }}
          onSubmit={onPickerBulk}
          handleClose={pickerDialogue.handleClose}
        />
      )}
    </AppLayout>
  )
}

export default PickerListContainer
