import React, { useCallback } from 'react'
import { Card, CardContent, Divider, Grid } from '@mui/material'
import CardHeader from 'components/card/CardHeader'
import SwitchField from 'components/form/SwitchField'
import TextField from 'components/form/TextField'
import { useField } from 'formik'
import Container from 'components/container/Container'
import { generateBarcode } from 'utils/barcode'
import { useLocales } from 'hooks/useLocales'

import { AvailableCustomField } from './custom-field/AvailableCustomField'
import ProductTypeField from './form/ProductTypeField'
import BarcodeField from './form/BarcodeField'
import CategoryField from './form/CategoryField'
import BrandField from './form/BrandField'
import UnitMeasurementField from './form/UnitMeasurementField'
import MultipleSupplierField from './form/MultipleSupplierField'
import CountryField from './form/CountryField'
import ImagesUpload from './form/ImagesUpload'
import PackageLineItems from './form/PackageLineItems'

export const BulkEditGeneral = ({ customFields, packageList, isGeneral }:any) => {
  const { t } = useLocales('product')
  const barcodeField = useField('barcode')
  const barcodeValue = barcodeField[0].value || []
  const barcodeChange = barcodeField[2].setValue

  const onGenerateBarcode = useCallback(() => {
    const newBarcode = generateBarcode()
    barcodeChange([...barcodeValue, newBarcode])
  }, [barcodeValue])

  return (
    <Container>
      <Grid container={true} spacing={3}>
        <Grid item={true} lg={12}>
          <Card>
            <CardHeader title="Детали" />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Наименование товара"
                    name="name"
                    disabled={isGeneral}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Короткое наименование"
                    name="shortName"
                    disabled={isGeneral}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <BarcodeField
                    label="Штрих-код"
                    name="barcode"
                    buttonTitle={t('Generate')}
                    onGenerate={onGenerateBarcode}
                  />
                </Grid>
                <Grid item={true} lg={4}>
                  <TextField
                    label="Картонный штрих-код"
                    name="cartonBarcode"
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Артикул"
                    name="sku"
                    disabled={isGeneral}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Порядковый номер"
                    name="sortNumber"
                    type="number"
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Штук(кол-во внутри одной единицы)"
                    name="pieces"
                    type="number"
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <CategoryField />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <BrandField />
                </Grid>
                <Grid item={true} lg={4}>
                  <UnitMeasurementField />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <MultipleSupplierField />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <CountryField name="producedIn" />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <ProductTypeField disabled={!isGeneral} />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Описание"
                    name="description"
                    multiline={true}
                    minRows={4}
                    disabled={isGeneral}
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                      <SwitchField
                        name="enableExpiryDateTracking"
                        label="Отслеживание срока действия"
                      />
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                      <SwitchField
                        name="enableBatchNumbers"
                        label="Номера пакетов"
                      />
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                      <SwitchField
                        name="sellInPackages"
                        label="Продовать только в упаковке"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
          </Card>
        </Grid>
        <Grid item={true} lg={12}>
          <ImagesUpload />
        </Grid>
        <Grid item={true} lg={12}>
          <Card>
            <CardHeader title="Размеры продукта" />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Вес нетто"
                    name="weight"
                    type="number"
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Вес брутто"
                    name="weightBrutto"
                    type="number"
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Высота"
                    name="height"
                    type="number"
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Длина"
                    name="length"
                    type="number"
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Ширина"
                    name="width"
                    type="number"
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    label="Объемный Размер"
                    name="volumetricSize"
                    type="number"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
          </Card>
        </Grid>
        <Grid item={true} lg={12}>
          <PackageLineItems
            packageList={packageList} />
        </Grid>
        {isGeneral ? (
          <Grid item={true} lg={12}>
            <AvailableCustomField customFields={customFields} />
          </Grid>
        ) : null}
      </Grid>
    </Container>
  )
}
