import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useNavigate } from 'react-router'
import { ALTER_ERROR, useSnackbar } from 'context/snackbar'
import { prop, propOr } from 'ramda'

import ManualEntryCreateForm from '../components/ManualEntryCreateForm'
import { createManualEntry } from '../api'
import { TRANSACTION_TYPE } from '../components/ManualEntryTypeField'
import { manualEntryCreateSerializer } from '../serializers'

function ManualEntryCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const manualEntryCreate = usePost(createManualEntry)

  const handleSubmit = useCallback((values: any) => {
    const items = propOr([], 'items', values) as any
    const creditItems = items.filter((item: any) => prop('transactionType', item) === TRANSACTION_TYPE.CREDIT)
    const debitItems = items.filter((item: any) => prop('transactionType', item) === TRANSACTION_TYPE.DEBIT)
    const creditAmount = creditItems.reduce((a: any, b: any) => propOr(0, 'amount', b) + a, 0)
    const debitAmount = debitItems.reduce((a: any, b: any) => propOr(0, 'amount', b) + a, 0)
    if (creditAmount === debitAmount) {
      manualEntryCreate.postData({ data: manualEntryCreateSerializer(values) })
        .then(() => navigate(ROUTES.FINANCE_MANUAL_ENTRY_LIST))
        .then(() => snackbar({ message: 'Корректировка успешно создана' }))
    } else {
      snackbar({ message: 'Количество дебит и кредит должны быть равны', type: ALTER_ERROR })
    }
  }, [])

  return (
    <AppLayout>
      <ManualEntryCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default ManualEntryCreateContainer
