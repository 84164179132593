import React, { useCallback, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import { prop, propOr } from 'ramda'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, DialogTitle, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useField } from 'formik'
import TableHead from '@mui/material/TableHead'
import InfinityScrollSearch from 'components/form/InfinityScrollSearch'
import { useTranslationI18n } from 'i18n/I18nContext'
import TextOverflow from 'components/main/TextOverflow'
import TableSkeletonList from 'components/table/TableSkeletonList'

const TableRowStyled = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    background: '#0000000A'
  }
})

const DialogContentStyled = styled(DialogContent)({
  padding: '0',
  position: 'relative',
  height: 'calc(100vh / 2)'
})

interface Props {
  readonly open: boolean
  readonly onClose: () => void
  readonly onAddVariants: (value: any) => void
  readonly productList: any
}

function PurchaseVariantAddDialog ({ open, onClose, onAddVariants, productList }: Props) {
  const { t } = useTranslationI18n()

  const lineItemsField = useField('items')
  const lineItemsFieldValue = lineItemsField[0].value || []

  const [selected, setSelected] = useState(lineItemsFieldValue as any)

  const results = propOr([], 'list', productList) as any
  const count = prop('count', productList) as number

  const searchField = useField('search')
  const searchFieldValue = searchField[0].value

  const onEscape = useCallback((event: any) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }, [onClose])

  const handleCheck = (checked: boolean, variant: any) => {
    const id = prop('id', variant)
    if (checked) {
      setSelected((prevSelected: any) => prevSelected.filter((select: any) => {
        return prop('id', select) !== id
      }))
    } else {
      setSelected((prevSelected: any) => [...prevSelected, variant])
    }
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} onKeyDown={onEscape}>
      <DialogTitle>
        <Typography component="h2" variant="h6">
          {`${t('Просмотр продуктов')} - ${t('Отмечено')} (${selected.length})`}
        </Typography>
        <Box sx={{ padding: '24px 0' }}>
          <InfinityScrollSearch getList={productList.getList} />
        </Box>
      </DialogTitle>
      <DialogContentStyled id="purchaseVariantTable">
        <InfiniteScroll
          dataLength={results.length}
          hasMore={count > results.length}
          loader=""
          next={() => productList.getList({ query: { searchText: searchFieldValue } })}
          scrollableTarget="purchaseVariantTable"
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={100}>{t('Пометить')}</TableCell>
                  <TableCell width={250}>{t('Продукт')}</TableCell>
                  <TableCell width={150}>{t('SKU')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((variant: any, index: number) => {
                  const id = prop('id', variant)
                  const checked = selected.find((select: any) => prop('id', select) === id)
                  return (
                    <TableRowStyled onClick={() => handleCheck(checked, variant)}>
                      <TableCell data-cy={`variant-${index}`}>
                        <Checkbox checked={Boolean(checked)} color="secondary" />
                      </TableCell>
                      <TableCell>
                        <TextOverflow lines={1}>
                          {prop('name', variant)}
                        </TextOverflow>
                      </TableCell>
                      <TableCell>
                        {prop('sku', variant)}
                      </TableCell>
                    </TableRowStyled>
                  )
                })}
                {productList.loading && (
                  <TableSkeletonList columnsCount={3} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </DialogContentStyled>
      <DialogActions>
        <Button
          onClick={onClose}
          fullWidth={true}
        >
          Отменить
        </Button>
        <Button
          data-cy="add"
          variant="contained"
          fullWidth={true}
          onClick={() => onAddVariants(selected)}
        >
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PurchaseVariantAddDialog
