import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'
import { GetListResponse } from 'api/BaseDto'

export const getVisitList = (options?: Options) => {
  return getRequest<GetListResponse<any>>(API.VisitList, options)
}

export const createVisit = (options?: Options) => {
  return postRequest<GetListResponse<any>>(API.VisitCreate, options)
}

export const updateVisit = (options?: Options) => {
  return putRequest<any>(API.VisitUpdate, options)
}

export const updateVisitStatus = (options?: Options) => {
  return patchRequest<GetListResponse<any>>(API.VisitStatus, options)
}

export const deleteVisit = (options?: Options) => {
  return deleteRequest<any>(API.VisitDelete, options)
}

export const changeVisitStatus = (options?: Options) => {
  return patchRequest<any>(API.VisitStatus, options)
}

export const getVisitById = (options?: Options) => {
  return getRequest<any>(API.VisitGetById, options)
}

export const listingCounts = (options?: Options) => {
  return getRequest<any>(API.VisitListingCounts, options)
}

export const uploadFiles = (options?: Options) => {
  return postRequest<any>(API.VisitUpload, options)
}

export const deleteUploadFile = (options?: Options) => {
  return deleteRequest<any>(API.VisitUploadDelete, options)
}

export const getFilterList = (options?: Options) => {
  return getRequest<any>(API.ListingFilterList, options)
}

export const deleteFilter = (options?: Options) => {
  return deleteRequest<any>(API.ListingFilterDelete, options)
}

export const deleteSelectedFilter = (options?: Options) => {
  return deleteRequest<any>(API.ListingFilterDeleteByIds, options)
}

export const createFilter = (options?: Options) => {
  return postRequest<any>(API.ListingFilterCreate, options)
}

export const updateFilter = (options?: Options) => {
  return putRequest<any>(API.ListingFilterUpdate, options)
}

export const filterGetById = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetById, options)
}

export const getAvailableColumns = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetByType, options)
}

export const VisitListCount = (options?:Options) => {
  return getRequest<any>(API.VisitGetListCount, options)
}

export const getColumnsGetByType = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetByType, options)
}

export const getColumnsList = (options?: Options) => {
  return getRequest<any>(API.ColumnsList, options)
}

export const createColumn = (options?: Options) => {
  return postRequest<any>(API.ColumnsCreate, options)
}

export const columnGetById = (options?: Options) => {
  return getRequest<any>(API.ColumnsGetById, options)
}

export const updateColumn = (options?: Options) => {
  return putRequest<any>(API.ColumnsUpdate, options)
}

export const deleteColumn = (options?: Options) => {
  return deleteRequest<any>(API.ColumnsDelete, options)
}

export const deleteColumns = (options?: Options) => {
  return deleteRequest<any>(API.ColumnsDeleteByIds, options)
}

export const getTabList = (options?:Options) => {
  return getRequest<any>(API.ListingTabList, options)
}

export const createTab = (options?:Options) => {
  return postRequest<any>(API.ListingTabCreate, options)
}

export const updateTab = (options?:Options) => {
  return putRequest<any>(API.ListingTabUpdate, options)
}

export const getByIdTab = (options?:Options) => {
  return getRequest<any>(API.ListingTabGetById, options)
}

export const deleteTab = (options?:Options) => {
  return deleteRequest<any>(API.ListingTabDelete, options)
}

export const deleteTabByIds = (options?:Options) => {
  return deleteRequest<any>(API.ListingTabDeleteByIds, options)
}

export const getCustomFields = (options?: Options) => {
  return getRequest<any>(API.CustomFieldsGetByFormType, options)
}

export const soListGet = (options?: Options) => {
  return getRequest<any>(API.SaleOrderList, options)
}

export const taskUploadVideo = (options?: Options) => {
  return patchRequest<any>(API.VisitVideoUpload, options)
}

export const taskRemoveVideo = (options?: Options) => {
  return patchRequest<any>(API.VisitVideoURemove, options)
}

export const taskUploadPhoto = (options?: Options) => {
  return patchRequest<any>(API.VisitPhotoUpload, options)
}

export const taskRemovePhoto = (options?: Options) => {
  return patchRequest<any>(API.VisitPhotoRemove, options)
}

export const taskComments = (options?: Options) => {
  return getRequest<any>(API.TaskCommentsSuggestions, options)
}
