import * as API from 'constants/Api'

import { getRequest, Options, postRequest } from 'api/BaseApi'

export interface SignInResponse {
  readonly accessToken: string
  readonly tokenType: string
  readonly expireIn: number
}

export interface AuthGetResponse {
  id:number,
  name:string
}

export const signInApi = (options?: Options) => {
  return postRequest<SignInResponse>(API.SignIn, options)
}

export const userCompanies = (options?: Options) => {
  return getRequest<any>(API.UserCompanies, options)
}
