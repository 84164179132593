import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { useNavigate } from 'react-router'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { propOr, trim } from 'ramda'

import { deleteBonus, getBonusList } from '../api'
import BonusTypeRow from '../components/BonusTypeRow'
import BonusActionTypeRow from '../components/BonusActionTypeRow'
import BonusStatusRow from '../components/BonusStatusRow'
import BonusRowMenu from '../components/BonusRowMenu'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 100
  },
  {
    headerName: 'Наименование',
    field: 'name',
  },
  {
    headerName: 'Тип',
    field: 'type',
    renderCell:(row:any) => <BonusTypeRow status={row.type} />
  },
  {
    headerName: 'От даты',
    field: 'fromDate',
  },
  {
    headerName: 'До даты',
    field: 'toDate'
  },
  {
    headerName: 'Тип действия',
    field: 'actionType',
    renderCell: (row:any) => <BonusActionTypeRow status={row.actionType} />
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (row:any) => <BonusStatusRow status={row.status} />
  },
]

function BonusListContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const bonusList = useGetList(getBonusList)
  const bonusDelete = useDelete(deleteBonus)

  const handleDelete = useCallback((id: number) => {
    return bonusDelete.deleteData({ params: { id } })
      .then(() => snackbar({ message: 'Акция успешно удалена' }))
      .then(() => bonusList.getList())
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    bonusList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Акции"
        onAddClick={() => navigate(ROUTES.MARKETING_BONUS_CREATE)}
        dataRequest={bonusList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row:any) => (
          <BonusRowMenu
            item={row}
            onDelete={handleDelete}
          />
        )}
      />
    </AppLayout>
  )
}

export default BonusListContainer
