import { omit, path, prop, propOr } from 'ramda'
import { toNumber } from 'utils/formatMoney'
import { toPayloadDate } from 'utils/dates'

export const companyCreateSerializer = (values: any) => {
  const sharedWarehouses = propOr([], 'sharedWarehouses', values) as any
  const newSharedWarehouses = sharedWarehouses.map((warehouse: any) => warehouse.value)
  const balance = toNumber(prop('balance', values))
  return {
    ...values,
    sharedWarehouses: newSharedWarehouses,
    balance
  }
}

export const companyUpdateSerializer = (values: any) => {
  const sharedWarehouses = propOr([], 'sharedWarehouses', values) as any
  const newSharedWarehouses = sharedWarehouses.map((warehouse: any) => warehouse.value)
  const balance = toNumber(prop('balance', values))
  const activationDate = toPayloadDate(prop('activationDate', values))
  const renewalDate = toPayloadDate(prop('renewalDate', values))
  return {
    ...values,
    sharedWarehouses: newSharedWarehouses,
    balance,
    activationDate,
    renewalDate
  }
}

export const userCreateSerializer = (values:any) => {
  const omittedValues = omit(['companyName'], values)
  const newAddresses = prop('addresses', omittedValues)
  const addresses = newAddresses && newAddresses.map((address: any) => {
    const countryId = prop('id', address.countryId)
    const regionId = prop('id', address.regionId)
    return { ...address, regionId, countryId }
  })
  const picture = prop('pictureId', omittedValues)
  const pictureId = propOr(null, 'id', picture)
  const getTerritoryRoles = prop('territoryRoles', values)
  const territoryRoles = getTerritoryRoles && getTerritoryRoles.map((item: any) => {
    const rolesList = prop('roles', item)
    const roleGetValue = rolesList && rolesList.map((role: any) => role.role)
    const territoryId = path(['territoryId', 'id'], item)
    return {
      ...item,
      territoryId,
      roles: roleGetValue
    }
  })
  return {
    ...omittedValues,
    territoryRoles,
    addresses,
    pictureId
  }
}

export const billingSetSerializer = (values:any) => {
  const billingPlanId = path(['billingPlanId', 'id'], values)
  const activationDate = toPayloadDate(prop('activationDate', values))
  return {
    ...values,
    billingPlanId,
    activationDate
  }
}
