export const getTranslatedColumn = (code: string, name: string): string => {
  const translations: { [key: string]: string } = {
    'ORDER_NUMBER': 'Номер заказа',
    'ORDER_DATE': 'Дата заказа',
    'WAREHOUSE_GROUP': 'Группа складов',
    'CLIENT': 'Клиент',
    'TERRITORY': 'Территория',
    'ORDER_STATUS': 'Статус',
    'PAYMENT_AMOUNT': 'Сумма',
    'PRICE_LEVEL': 'Ценовая политика',
    'DELIVERY_TERMS': 'Условия',
    'CURRENCY': 'Валюта',
    'PAYMENT_METHOD': 'Способ оплаты',
  }

  return translations[code] || name
}
