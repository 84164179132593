import React, { ReactNode } from 'react'
import Button from '@mui/material/Button'
import { Menu } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslationI18n } from 'i18n/I18nContext'
import cx from 'classnames'

import ActionIcon from '../icons/ActionIcon'
import DownArrowIcon from '../icons/DownArrowIcon'
import { ALTER_WARNING, useSnackbar } from '../../context/snackbar'

const useStyles = makeStyles({
  menu: {
    '& ::before': {
      opacity: '0 !important'
    }
  },
  actionButton: {
    transition: '0.3s',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    width: '135px !important',
    height: '30px !important',
    background: '#202020 !important',
    borderRadius: '10px !important',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    '&.disabled': {
      color: '#7C7C7C !important',
      background: '#EFEFEF !important',
      cursor: 'not-allowed',
      '& path': {
        stroke: '#7C7C7C !important'
      }
    }
  },
  arrow: {
    '&.open': {
      transform: 'rotate(180deg)',
      transition: '0.3s'
    }
  }
})

interface Props {
  readonly actionsBan?:boolean
  readonly actionBanWarning?:string
  readonly disabled?: boolean
  readonly tableActions?: ReactNode
}

export function TableActionButtons ({ disabled = false, tableActions, actionsBan, actionBanWarning }: Props) {
  const classes = useStyles()
  const { t } = useTranslationI18n()
  const snackbar = useSnackbar()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const onHasWarning = () => {
    snackbar({ type:ALTER_WARNING, message:actionBanWarning })
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Button
        id="table-actions"
        aria-controls={open ? 'table-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={actionsBan ? onHasWarning : handleClick}
        className={cx(classes.actionButton, { disabled: disabled })}
      >
        <ActionIcon />
        <span>{t('Actions')}</span>
        <DownArrowIcon className={cx(classes.arrow, { 'open': Boolean(anchorEl) })} />
      </Button>
      <Menu
        id="table-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'table-actions', }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        className={classes.menu}
      >
        {tableActions}
      </Menu>
    </>
  )
}

export default TableActionButtons
