import * as ROUTES from 'constants/Routes'

import React from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { pathParams } from 'path-params'
import { useNavigate } from 'react-router'

interface Props {
  readonly row: any
  readonly handleDelete: (values: any) => void
  readonly handleComplete: (values: any) => void
  readonly index: number
}

function ReceiveRowMenu ({ row, handleDelete, handleComplete, index }: Props) {
  const navigate = useNavigate()
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null)

  const anchorIsOpen = Boolean(optionsAnchorEl)

  const handleOpenOptions = (event: any) => {
    setOptionsAnchorEl(event.currentTarget)
  }

  const handleCloseOptions = () => {
    setOptionsAnchorEl(null)
  }

  const isCompleted = row.status === 'COMPLETED'

  const updatePath = pathParams(ROUTES.WAREHOUSE_RECEIVE_UPDATE, { id: row.id })

  return (
    <React.Fragment>
      <IconButton
        data-cy={`table-action-row-${index}`}
        aria-label="more"
        id="long-button"
        aria-controls={anchorIsOpen ? 'long-menu' : undefined}
        aria-expanded={anchorIsOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenOptions}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="table-item-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={optionsAnchorEl}
        open={anchorIsOpen}
        onClose={handleCloseOptions}
      >
        <MenuItem
          onClick={() => {
            navigate(updatePath)
            handleCloseOptions()
          }}
          disabled={isCompleted}
        >
          Получить
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDelete(row.id)
            handleCloseOptions()
          }}
          data-cy="delete"
        >
          Удалить
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleComplete(row.id)
            handleCloseOptions()
          }}
          data-cy="complete"
          disabled={isCompleted}
        >
          Завершить
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default ReceiveRowMenu
