import React, { memo, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'

export const StyledRootScrollbar = styled(Box)({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
})

export const StyledScrollbar = styled(Box)(({ theme }) => ({
  maxHeight: '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: 10,
    height: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: alpha(theme.palette.grey[600], 0.48),
    borderRadius: 4,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.grey[600],
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
}))

interface ScrollbarProps {
  sx?: object;
  children: ReactNode
  [key: string]: any;
}

const Scrollbar = ({ children, sx, ...other }: ScrollbarProps) => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    )
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  )
}

Scrollbar.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
}

export default memo(Scrollbar)
