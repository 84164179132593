import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'

import WarehouseGroupCreateForm from '../components/WarehouseGroupCreateForm'
import { createWarehouseGroup } from '../api'
import { warehouseGroupCreateSerializer } from '../serializer'

function WarehouseGroupCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const warehouseGroupCreate = usePost(createWarehouseGroup)

  const handleSubmit = useCallback((data: any) => {
    return warehouseGroupCreate.postData({ data: warehouseGroupCreateSerializer(data) })
      .then(() => navigate(ROUTES.WAREHOUSE_GROUP_LIST))
      .then(() => snackbar({ message: 'Группа успешно создана' }))
  }, [])

  return (
    <AppLayout>
      <WarehouseGroupCreateForm
        onSubmit={handleSubmit}
        initialValues={{ warehouseLineItems:[] }}
      />
    </AppLayout>
  )
}

export default WarehouseGroupCreateContainer
