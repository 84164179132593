import * as API from 'constants/Api'

import React from 'react'
import { prop, propOr } from 'ramda'

import AutoSelectField from './AutoSelectField'

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('id', value)
    const name = propOr('', 'name', value)
    return { id, name }
  }

  return null
}

function CountryField ({ ...props }: any) {
  return (
    <AutoSelectField
      api={API.CountryList}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default CountryField
