import * as React from 'react'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FlexBox from 'components/main/FlexBox'

function LinearProgressWithLabel (props: LinearProgressProps & { title:string, value: number }) {
  return (
    <FlexBox align="center" direction="column" flex={true}>
      <Typography>
        {props.title}
      </Typography>
      <FlexBox align="center" flex={true} style={{ width:'100%' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </FlexBox>
    </FlexBox>
  )
}

interface Props {
  readonly title?:string
}

export default function LinearWithValueLabel ({ title = 'Ваш файл загружается...' }:Props) {
  const [progress, setProgress] = React.useState(10)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10))
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel title={title} value={progress} />
    </Box>
  )
}
