import * as API from 'constants/Api'

import React, { createContext, useContext, useState, useEffect } from 'react'
import { getRequest } from 'api/BaseApi'

import Context from './context'

interface NotificationContextType {
  readonly count: number
  readonly fetchNotificationCount:() => void
}

const NotificationContext = createContext<NotificationContextType | undefined>(Context)

export const useNotification = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider')
  }
  return context
}

const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const token = localStorage.getItem('antex-token')
  const [count, setCount] = useState(0)

  const fetchNotificationCount = async () => {
    try {
      const response:any = await getRequest(API.NotificationCount)
      if (typeof response === 'number') {
        setCount(response)
      } else {
        setCount(0)
      }
    } catch (error) {
      console.error('Failed to fetch notification count:', error)
    }
  }

  useEffect(() => {
    if (token) {
      fetchNotificationCount()

      const intervalId = setInterval(fetchNotificationCount, 300000)

      return () => clearInterval(intervalId)
    }
  }, [])

  return (
    <NotificationContext.Provider value={{ count, fetchNotificationCount }}>
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
