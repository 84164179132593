import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { ALTER_INFO, useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { useConfirm } from 'context/confirm'
import { propOr, trim } from 'ramda'

import { deletePackage, deletePackageByIds, getPackageList } from '../api'
import PackageRowMenu from '../components/PackageRowMenu'
import TableActionsRow from '../components/TableActionsRow'
import { selectedRowSerializer } from '../../brand/serializer'
import { useLocales } from '../../../../hooks/useLocales'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 150
  },
  {
    headerName: 'Name',
    field: 'name',
  }
]

function PackageListContainer () {
  const { t } = useLocales('productPackage')
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const packageList = useGetList(getPackageList)
  const packageDelete = useDelete(deletePackage)
  const packageDeleteByIds = useDelete(deletePackageByIds)
  const [selectedRows, setSelectedRows] = useState<any>([])

  const handleDeletePackage = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить упаковку ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        packageDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Упаковка успешно удалена' }))
          .then(() => packageList.getList())
      })
      .disagree()
  }, [])

  const handleDeleteSelected = useCallback(async () => {
    const message = `Вы уверены что хотите удалить выбранные упаковки?`

    onConfirm({ message })
      .agree(() => {
        packageDeleteByIds.deleteData({ data: selectedRowSerializer(selectedRows) })
          .then((res:any) => {
            if (res.length === 0) {
              setSelectedRows([])
              snackbar({ message: `${selectedRows.length} упаковки успешно удалён` })
            } else {
              const filteredProducts = selectedRows.filter((item: { id: any }) => res.includes(item.id))
              setSelectedRows(filteredProducts)
              snackbar({ message: `${selectedRows.length - res.length} упаковки были успешно удалены,
               но ${res.length} упаковки не могут быть удалены, поскольку они используются.`,
              type: ALTER_INFO
              })
            }
          })
          .then(() => packageList.getList())
      })
      .disagree()
  }, [selectedRows, packageList])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    packageList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title={t('Product package')}
        onAddClick={() => navigate(ROUTES.CATALOG_PACKAGE_CREATE)}
        dataRequest={packageList}
        columns={columns}
        onSearchClick={onTableSearch}
        translationModule="productPackage"
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchPlaceholder="Поиск по названию или ID"
        actions={(row) => (
          <PackageRowMenu
            item={row}
            handleDelete={handleDeletePackage}
          />
        )}
        tableActions={(
          <TableActionsRow
            handleDeleteSelected={handleDeleteSelected}
          />
        )}
      />
    </AppLayout>
  )
}

export default PackageListContainer
