import React from 'react'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'
import DateTimePickerField from 'components/form/DateTimePickerField'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'

import CustomSelectField from './CustomSelectField'
import CustomFieldCheckbox from './CustomFieldCheckbox'
import SingleSearchField from './form/SearchField'
import CustomFieldRadioButton from './form/CustomFieldButton'
import MultipleSearchField from './form/MultipleSearchField'

import { CUSTOM_FIELD_TYPE } from '../../constants'

const getFieldByType = (
  fieldType: string,
  values:any,
  lookUpType:string,
  disabled:boolean,
  showInlisting:boolean,
  rest: any
) => {
  if (fieldType === CUSTOM_FIELD_TYPE.DATE_PICKER && showInlisting) {
    return (
      <Grid item={true}>
        <DateField
          label="Дата"
          disabled={disabled}
          {...rest}
        />
      </Grid>
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.DROPDOWN && values && showInlisting) {
    return (
      <Grid item={true} lg={4}>
        <CustomSelectField
          label="Дата"
          options={values}
          disabled={disabled}
          {...rest}
          name={`${rest.name}.value.value`}
        />
      </Grid>

    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.CHECK_BOX && showInlisting) {
    return (
      <Grid item={true} lg={4}>
        <Typography variant="body2">
          {rest.label}
        </Typography>
        {values && values.map((item:any, index:number) => {
          return (
            <CustomFieldCheckbox
              index={index}
              disabled={disabled}
              name={`${rest.name}.value`}
              label={item}
              value={item}
            />
          )
        })}
      </Grid>
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.TEXT_AREA && showInlisting) {
    return (
      <Grid item={true} lg={4}>
        <TextField
          label="Описание"
          minRows={4}
          multiline
          disabled={disabled}
          {...rest}
          name={`${rest.name}.value.value`}
        />
      </Grid>
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.DATE_TIME_PICKER && showInlisting) {
    return (
      <Grid item={true} lg={4}>
        <DateTimePickerField
          label="Дата и время"
          disabled={disabled}
          {...rest}
          name={`${rest.name}.value.value`}
        />
      </Grid>
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.NUMBER_BOX && showInlisting) {
    return (
      <Grid item={true} lg={4}>
        <TextField
          label="Ценить"
          type="number"
          disabled={disabled}
          {...rest}
          name={`${rest.name}.value.value`}
        />
      </Grid>
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.RADIO_BUTTON && showInlisting) {
    return (
      <Grid item={true} lg={4}>
        <Typography variant="body2">
          {rest.label}
        </Typography>
        {values && values.map((item:any, index:number) => {
          return (
            <CustomFieldRadioButton
              index={index}
              disabled={disabled}
              name={`${rest.name}.value`}
              label={item}
              value={item}
            />
          )
        })}
      </Grid>
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.LOOKUP && lookUpType && showInlisting) {
    return (
      <Grid item={true} lg={4}>
        <SingleSearchField
          status={lookUpType}
          name={`${rest.name}.value`}
          disabled={disabled}
        />
      </Grid>
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.MULTI_LOOKUP && lookUpType && showInlisting) {
    return (
      <Grid item={true} lg={4}>
        <MultipleSearchField
          status={lookUpType}
          name={`${rest.name}.values`}
          disabled={disabled}
        />
      </Grid>
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.MULTI_CHECK_BOX && showInlisting) {
    return (
      <Grid item={true} lg={4}>
        <Typography variant="body2">
          {rest.label}
        </Typography>
        {values && values.map((item:any, index:number) => {
          return (
            <CustomFieldCheckbox
              index={index}
              disabled={disabled}
              name={`${rest.name}.values[${index}]`}
              label={item}
              value={item}
            />
          )
        })}
      </Grid>
    )
  }
  if (showInlisting) {
    return (
      <Grid item={true} lg={4}>
        <TextField
          label="Значение поля"
          disabled={disabled}
          {...rest}
          name={`${rest.name}.value.value`}
        />
      </Grid>
    )
  }
  return null
}

export const CustomValueField = (props: any) => {
  const { type, values, lookUpType, disabled, showInlisting, ...rest } = props
  return getFieldByType(type, values, lookUpType, disabled, showInlisting, rest)
}
