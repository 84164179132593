import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const createSorting = (options?: Options) => {
  return postRequest<any>(API.SortingCreate, options)
}

export const updateSorting = (options?: Options) => {
  return putRequest<any>(API.SortingUpdate, options)
}

export const getByIdSorting = (options?: Options) => {
  return getRequest<any>(API.SortingDetail, options)
}

export const deleteSorting = (options?: Options) => {
  return deleteRequest<any>(API.SortingDelete, options)
}

export const sortingList = (options?: Options) => {
  return getRequest<any>(API.SortingList, options)
}

export const sortingDeleteByIds = (options?: Options) => {
  return getRequest<any>(API.SortingDeleteBulk, options)
}
