import { useCallback, useState } from 'react'
import { pathOr, prop, propOr } from 'ramda'

import { GetListResponse } from '../api/BaseDto'
import { Options } from '../api/BaseApi'
import useAllSearchParams from '../utils/useAllSearchParams'
import { ALTER_ERROR, useSnackbar } from '../context/snackbar'

export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_PAGE_NUMBER = 0

export const useGetList = <T>(api: (options?: Options) => Promise<GetListResponse<T>>, listOptions?: Options) => {
  const searchParams = useAllSearchParams()
  const snackbar = useSnackbar()
  const [result, setResult] = useState({} as GetListResponse<T>)
  const [list, setList] = useState([] as T[])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState({}) as any

  const getList = useCallback((options?: Options) => {
    const limit = prop('limit', searchParams) || DEFAULT_PAGE_SIZE
    const start = prop('start', searchParams) || DEFAULT_PAGE_NUMBER
    const defaultGetQuery = {
      query: {
        start: Number(start),
        limit: Number(limit),
        ...listOptions?.query,
        ...options?.query
      }
    }
    setLoading(true)
    return api({ ...listOptions, ...options, ...defaultGetQuery }).then((response) => {
      setResult(response)
      setQuery(defaultGetQuery.query)
      const list = prop('list', response)
      const content = prop('content', response)
      if (Array.isArray(response)) {
        setList(response)
      }
      if (list) {
        setList(response.list)
      } if (content) {
        setList(response.content)
      }
      setLoading(false)
      return response
    }).catch((error) => {
      setList([])
      setLoading(false)
      const dataError = pathOr('Oops, Something went wrong', ['data', 'error'], error)
      const userMsg = typeof dataError === 'object'
        ? propOr('Oops, Something went wrong', 'user_msg', dataError)
        : dataError

      snackbar({ message: userMsg, type: ALTER_ERROR })
      throw error
    })
  }, [api, searchParams])
  return { getList, result, loading, list, query }
}
