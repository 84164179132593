import React from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles({
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    width: 'fit-content',
    textTransform:'uppercase',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    },
    '&.warning': {
      background: '#ffcc00'
    },
    '&.error': {
      background: '#EB4C42',
      color: '#fff'
    }
  }
})

interface Props {
  readonly status: string
}
const translations: { [key: string]: string } = {
  ACTIVE: 'Активный',
  INACTIVE: 'Неактивный',
  INCOMPLETE: 'Незавершенный',
  COMPLETED: 'Завершенный',
  CANCELLED : 'Отмененный',
  PLANNED:'Запланировано',
  ONHOLD:'Приостановлено',
  INPROGRESS:'В процессе',
  DRAFT:'Черновик'
}

const getTranslatedStatus = (code: string): string => {
  return translations[code] || code
}

function VisitStatusRow ({ status }: Props) {
  const isActive = status === 'COMPLETED' || status === 'ACTIVE'
  const isWarning = status === 'INCOMPLETE'
  const isError = status === 'INACTIVE' || status === 'CANCELLED'
  const classes = useStyles()
  return (
    <div className={cx(classes.statusIndicator, { 'active': isActive, 'warning': isWarning, 'error':isError })}>
      {getTranslatedStatus(status)}
    </div>
  )
}

export default VisitStatusRow
