import * as ROUTES from 'constants/Routes'

import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import MUIDivider from '@mui/material/Divider'
import Tabs from 'components/main/Tabs'

export enum VISIT_TABS {
  DETAIL = 'detail',
  SOTable = 'orders',
}

const tabs = [
  { value: VISIT_TABS.DETAIL, label: 'Детали' },
  { value: VISIT_TABS.SOTable, label: 'Заказы' },
]

interface Props {
  readonly value: string
}

function VisitTabs ({ value }: Props) {
  const { id } = useParams()

  const navigate = useNavigate()

  const onChange = (event: any, tab: any) => {
    const redirect = generatePath(ROUTES.CATALOG_VISIT_DETAILS, { id, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />

      <MUIDivider sx={{ mb: 3 }} />
    </>
  )
}

export default VisitTabs
