import * as API from 'constants/Api'

import { getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getCommentList = (options?: Options) => {
  return getRequest<any>(API.TaskCommentsList, options)
}

export const commentDetail = (options?: Options) => {
  return getRequest<any>(API.TaskCommentsDetail, options)
}

export const createComment = (options?: Options) => {
  return postRequest<any>(API.TaskCommentsCreate, options)
}

export const updateComment = (options?: Options) => {
  return putRequest<any>(API.TaskCommentsUpdate, options)
}
