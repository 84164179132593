import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import TextField from 'components/form/TextField'

import WarehouseField from './Field/WarehouseField'
import LineItems from './Field/LineItems'
import StatusField from './Field/AdjustmentStatusField'
import SupplierField from './Field/SupplierField'
import CurrencyField from './Field/CurrencyField'
import PriceLevelField from './Field/PriceLevelField'

import DateField from '../../../../components/form/DateField'

interface Props {
  readonly productList: any
  readonly handleGetPriceLevel: (price: number, product: any) => Promise<any>
}

function AdjustmentCreateForm ({ productList, handleGetPriceLevel }: Props) {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать корректировку"
          rightButton={(
            <>
              <Button type="submit">Создать</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <TextField name="refNumber" label="Привязанный номер" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <WarehouseField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <SupplierField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <PriceLevelField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CurrencyField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <StatusField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DateField name="date" label="Дата" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField name="description" label="Описание" multiline={true} minRows={4} />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems productList={productList} handleGetPriceLevel={handleGetPriceLevel} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(AdjustmentCreateForm)
