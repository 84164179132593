import * as ROUTES from 'constants/Routes'

import * as Yup from 'yup'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'

import { createProduct, getCustomFields, getPackageList } from '../api'
import ProductCreateForm from '../components/ProductCreateForm'
import { productCreateSerialize } from '../serializer'

export const validationSchema = Yup.object({
  name: Yup.string().required('Требуется наименование товара'),
  categoryIds: Yup.array().min(1, 'Требуется продуктовая корзина').required('Обязательный'),
  packageLineItems: Yup.array().when('sellInPackages', (sellInPackages, scheme) => {
    if (sellInPackages[0]) {
      return scheme.min(1, 'Требуются предметы из упаковки.').required('Обязательный')
    } else return scheme
  }),
  pieces: Yup.number().required('Требуется штук')
})

function ProductCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const [customFields, setCustomField] = useState([])

  const productCreate = usePost(createProduct)
  const packageList = useGetList(getPackageList)
  const customFieldsGet = useGetList(getCustomFields)

  const handleSubmit = useCallback((values: any) => {
    return productCreate.postData({ data: productCreateSerialize(values) })
      .then(() => snackbar({ message: 'Продукт успешно создан' }))
      .then(() => navigate(ROUTES.CATALOG_PRODUCT_LIST))
  }, [])

  useEffect(() => {
    customFieldsGet.getList({ params: { formType: 'PRODUCT_FORM' } }).then((res: any) => {
      setCustomField(res)
    })
  }, [])

  return (
    <AppLayout>
      {customFieldsGet.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
      {!customFieldsGet.loading && (
        <ProductCreateForm
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={{
            name: '',
            pieces: 1,
            customFieldValue: customFields,
            packageLineItems: [],
            categoryIds: [],
            supplierIds: [],
            images: []
          }}
          packageList={packageList}
        />
      )}
    </AppLayout>
  )
}

export default ProductCreateContainer
