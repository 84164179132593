import React from 'react'
import { pathOr } from 'ramda'
import { useGetList } from 'hooks/useGetList'
import Table from 'components/table/Table'
import { useParams } from 'react-router-dom'
import { formatMoney } from 'utils/formatMoney'

import CompanyStatusRow from './CompanyStatusRow'

import { historyList } from '../api'

const columns = [
  {
    headerName: 'Тарифный план',
    field: 'billingPlan',
    renderCell: pathOr('', ['billingPlan', 'name'])
  },
  {
    headerName: 'Категория тарифного плана',
    field: 'billingPlan',
    renderCell: pathOr('', ['billingPlan', 'category', 'name'])
  },
  {
    headerName: 'Сумма тарифного плана',
    field: 'amount',
    renderCell: (item:any) => formatMoney(pathOr('', ['billingPlan', 'amount'], item))
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell:(item:any) => <CompanyStatusRow status={item?.billingPlan?.active} />
  },
  {
    headerName: 'Дата',
    field: 'date',
  },
]

function CompanyBillingHistoryTable () {
  const { id } = useParams()
  const companyHistoryList = useGetList(historyList, { query:{ companyId:id } })
  return (
    <Table
      columns={columns}
      title="Company billing history"
      dataRequest={companyHistoryList}
      withoutBar={true}
    />
  )
}

export default CompanyBillingHistoryTable
