import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { Provider as SnackbarProvider } from './context/snackbar'
import { Provider as ConfirmProvider } from './context/confirm'
import { Provider as RoutesProvider } from './context/route'
import { Provider as UserProvider } from './context/user'
import { Provider as NotificationProvider } from './context/notification'
import { AppLanguage, I18nProvider } from './i18n/I18nContext'
import * as ROUTES from './constants/Routes'
import { theme } from './theme'
import { isValidToken } from './utils/tokenSerializer'
import SignInContainer from './modules/auth/containers/SignInContainer'
import SelectCompanyContainer from './modules/auth/containers/SelectCompanyContainer'
// Directories
import ProductListContainer from './modules/catalog/product/containers/ProductListContainer'
import CategoryListContainer from './modules/catalog/category/containers/CategoryListContainer'
import CategoryCreateContainer from './modules/catalog/category/containers/CategoryCreateContainer'
import CategoryUpdateContainer from './modules/catalog/category/containers/CategoryUpdateContainer'
import BrandListContainer from './modules/catalog/brand/containers/BrandListContainer'
import BrandCreateContainer from './modules/catalog/brand/containers/BrandCreateContainer'
import BrandUpdateContainer from './modules/catalog/brand/containers/BrandUpdateContainer'
import ProductCreateContainer from './modules/catalog/product/containers/ProductCreateContainer'
import ProductUpdateContainer from './modules/catalog/product/containers/ProductUpdateContainer'
import ProductBulkUpdateContainer from './modules/catalog/product/containers/ProductBulkUpdateContainer'
import PriceLevelListContainer from './modules/settings/price-level/containers/PriceLevelListContainer'
import PriceLevelCreateContainer from './modules/settings/price-level/containers/PriceLevelCreateContainer'
import PriceLevelUpdateContainer from './modules/settings/price-level/containers/PriceLevelUpdateContainer'
import TerritoryListContainer from './modules/settings/territory/containers/TerritoryListContainer'
import TerritoryCreateContainer from './modules/settings/territory/containers/TerritoryCreateContainer'
import TerritoryUpdateContainer from './modules/settings/territory/containers/TerritoryUpdateContainer'
import UnitMeasurementListContainer from './modules/catalog/mml/containers/UnitMeasurementListContainer'
import UnitMeasurementCreateContainer from './modules/catalog/mml/containers/UnitMeasurementCreateContainer'
import UnitMeasurementUpdateContainer from './modules/catalog/mml/containers/UnitMeasurementUpdateContainer'
import CurrencyListContainer from './modules/settings/currency/containers/CurrencyListContainer'
import CurrencyCreateContainer from './modules/settings/currency/containers/CurrencyCreateContainer'
import CurrencyUpdateContainer from './modules/settings/currency/containers/CurrencyUpdateContainer'
import CountryListContainer from './modules/catalog/country/containers/CountryListContainer'
import CountryUpdateContainer from './modules/catalog/country/containers/CountryUpdateContainer'
import CountryCreateContainer from './modules/catalog/country/containers/CountryCreateContainer'
import ContactCategoryListContainer from './modules/finance/agent-category/containers/AgentCategoryListContainer'
import AgentCategoryCreateContainer from './modules/finance/agent-category/containers/AgentCategoryCreateContainer'
import AgentCategoryUpdateContainer from './modules/finance/agent-category/containers/AgentCategoryUpdateContainer'
import CategoryAddProductContainer from './modules/catalog/category/containers/CategoryAddProductContainer'
import TerritoryBulkContainer from './modules/settings/territory/containers/TerritoryBulkContainer'
// Others
import WarehouseListContainer from './modules/warehouse/warehouse/containers/WarehouseListContainer'
import WarehouseCreateContainer from './modules/warehouse/warehouse/containers/WarehouseCreateContainer'
import WarehouseUpdateContainer from './modules/warehouse/warehouse/containers/WarehouseUpdateContainer'
import ReceiveListContainer from './modules/warehouse/receive/containers/ReceiveListContainer'
import ReceiveUpdateContainer from './modules/warehouse/receive/containers/ReceiveUpdateContainer'
import ReceiveCreateContainer from './modules/warehouse/receive/containers/ReceiveCreateContainer'
import AdjustmentListContainer from './modules/warehouse/adjustment/containers/AdjustmentListContainer'
import WarehouseGroupListContainer from './modules/warehouse/group/containers/WarehouseGroupListContainer'
import WarehouseGroupCreateContainer from './modules/warehouse/group/containers/WarehouseGroupCreateContainer'
import WarehouseGroupUpdateContainer from './modules/warehouse/group/containers/WarehouseGroupUpdateContainer'
import ShipmentListContainer from './modules/sale-order/shipment/containers/ShipmentListContainer'
import ShipmentUpdateContainer from './modules/sale-order/shipment/containers/ShipmentUpdateContainer'
import AdjustmentCreateContainer from './modules/warehouse/adjustment/containers/AdjustmentCreateContainer'
import AdjustmentUpdateContainer from './modules/warehouse/adjustment/containers/AdjustmentUpdateContainer'
import PaymentListContainer from './modules/sale-order/payment/containers/PaymentListContainer'
import PaymentCreateContainer from './modules/sale-order/payment/containers/PaymentCreateContainer'
import PaymentUpdateContainer from './modules/sale-order/payment/containers/PaymentUpdateContainer'
import CustomFieldListContainer from './modules/settings/custom-field/containers/CustomFieldListContainer'
import CustomFieldCreateContainer from './modules/settings/custom-field/containers/CustomFieldCreateContainer'
import CustomFieldUpdateContainer from './modules/settings/custom-field/containers/CustomFieldUpdateContainer'
import StockListContainer from './modules/catalog/stock/containers/StockListContainer'
import SaleOrderListContainer from './modules/sale-order/sale-order/containers/SaleOrderListContainer'
import SaleOrderCreateContainer from './modules/sale-order/sale-order/containers/SaleOrderCreateContainer'
import SaleOrderUpdateContainer from './modules/sale-order/sale-order/containers/SaleOrderUpdateContainer'
import PreOrderListContainer from './modules/sale-order/pre-order/containers/PreOrderListContainer'
import PurchaseOrderListContainer from './modules/finance/purchase-order/containers/PurchaseListContainer'
import PurchaseCreateContainer from './modules/finance/purchase-order/containers/PurchaseCreateContainer'
import PurchaseUpdateContainer from './modules/finance/purchase-order/containers/PurchaseUpdateContainer'
import ManualEntryListContainer from './modules/finance/manual-entry/containers/ManualEntryListContainer'
import ManualEntryCreateContainer from './modules/finance/manual-entry/containers/ManualEntryCreateContainer'
import ManualEntryUpdateContainer from './modules/finance/manual-entry/containers/ManualEntryUpdateContainer'
import TransactionListContainer from './modules/finance/transaction/containers/TransactionListContainer'
import ReceivePODetailContainer from './modules/finance/purchase-order/containers/ReceivePODetailContainer'
import EmployeeListContainer from './modules/hr/employee/containers/EmployeeListContainer'
import EmployeeCreateContainer from './modules/hr/employee/containers/EmployeeCreateContainer'
import EmployeeUpdateContainer from './modules/hr/employee/containers/EmployeeUpdateContainer'
import TrackingListContainer from './modules/hr/tracking/containers/TrackingListContainer'
// Backend
import CompanyListContainer from './modules/backend/company/containers/CompanyListContainer'
import CompanyUpdateContainer from './modules/backend/company/containers/CompanyUpdateContainer'
import CompanyCreateContainer from './modules/backend/company/containers/CompanyCreateContainer'
import CompanyCreateUserContainer from './modules/backend/company/containers/CompanyCreateUserContainer'
import NewsListContainer from './modules/backend/news/containers/NewsListContainer'
import NewsCreateContainer from './modules/backend/news/containers/NewsCreateContainer'
import NewsUpdateContainer from './modules/backend/news/containers/NewsUpdateContainer'
import BillingPlanListContainer from './modules/backend/billing/containers/BillingPlanListContainer'
import BillingPlanCreateContainer from './modules/backend/billing/containers/BillingPlanCreateContainer'
import BillingPlanUpdateContainer from './modules/backend/billing/containers/BillingPlanUpdateContainer'
import BillingPLanCategoryListContainer
  from './modules/backend/billing-plan-category/containers/BillingPLanCategoryListContainer'
import BillingPlanCategoryCreateContainer
  from './modules/backend/billing-plan-category/containers/BillingPlanCategoryCreateContainer'
import BillingPlanCategoryUpdateContainer
  from './modules/backend/billing-plan-category/containers/BillingPlanCategoryUpdateContainer'
import SharedContactCategoryUpdateContainer
  from './modules/backend/shared-category/containers/SharedContactCategoryUpdateContainer'
import SharedContactCategoryListContainer
  from './modules/backend/shared-category/containers/SharedContactCategoryListContainer'
import SharedContactCategoryCreateContainer
  from './modules/backend/shared-category/containers/SharedContactCategoryCreateContainer'
import SharedContactListContainer from './modules/backend/shared-contact/containers/SharedContactListContainer'
import SharedContactCreateContainer from './modules/backend/shared-contact/containers/SharedContactCreateContainer'
import SharedContactUpdateContainer from './modules/backend/shared-contact/containers/SharedContactUpdateContainer'
// Finance
import TransferListContainer from './modules/finance/transfer/containers/TransferListContainer'
import TransferCreateContainer from './modules/finance/transfer/containers/TransferCreateContainer'
import TransferUpdateContainer from './modules/finance/transfer/containers/TransferUpdateContainer'
import VisitListContainer from './modules/visit/visit/containers/VisitListContainer'
import VisitCreateContainer from './modules/visit/visit/containers/VisitCreateContainer'
import VisitUpdateContainer from './modules/visit/visit/containers/VisitUpdateContainer'
import ChartOfAccountingListContainer
  from './modules/finance/chart-of-account/containers/ChartOfAccountingListContainer'
import ChartOfAccountCreateContainer from './modules/finance/chart-of-account/containers/ChartOfAccountCreateContainer'
import PackageListContainer from './modules/catalog/product-package/containers/PackageListContainer'
import PackageCreateContainer from './modules/catalog/product-package/containers/PackageCreateContainer'
import PackageUpdateContainer from './modules/catalog/product-package/containers/PackageUpdateContainer'
import AgentListContainer from './modules/finance/agent/containers/AgentListContainer'
import AgentCreateContainer from './modules/finance/agent/containers/AgentCreateContainer'
import AgentUpdateContainer from './modules/finance/agent/containers/AgentUpdateContainer'
import SaleOrderReturnListContainer
  from './modules/sale-order/sale-order-return/containers/SaleOrderReturnListContainer'
import SaleOrderReturnCreateContainer
  from './modules/sale-order/sale-order-return/containers/SaleOrderReturnCreateContainer'
import SaleOrderReturnUpdateContainer
  from './modules/sale-order/sale-order-return/containers/SaleOrderReturnUpdateContainer'
import PickerListContainer from './modules/sale-order/pick/containers/PickListContainer'
import PickUpdateContainer from './modules/sale-order/pick/containers/PickUpdateContainer'
import TaskReturnListContainer from './modules/crm/task-return/containers/TaskReturnListContainer'
import TaskReturnCreateContainer from './modules/crm/task-return/containers/TaskReturnCreateContainer'
import TaskReturnUpdateContainer from './modules/crm/task-return/containers/TaskReturnUpdateContainer'
// Marketing
import RentListContainer from './modules/marketing/rent/containers/RentListContainer'
import RentCreateContainer from './modules/marketing/rent/containers/RentCreateContainer'
import RentUpdateContainer from './modules/marketing/rent/containers/RentUpdateContainer'
import BonusListContainer from './modules/marketing/bonus/containers/BonusListingContainer'
import BonusCreateContainer from './modules/marketing/bonus/containers/BonusCreateContainer'
import BonusUpdateContainer from './modules/marketing/bonus/containers/BonusUpdateContainer'
import SupplierPaymentsListContainer from './modules/finance/supplier-payments/containers/SupplierPaymentsListContainer'
import SupplierPaymentsCreateContainer
  from './modules/finance/supplier-payments/containers/SupplierPaymentsCreateContainer'
import SupplierPaymentsUpdateContainer
  from './modules/finance/supplier-payments/containers/SupplierPaymentsUpdateContainer'
import BulkPaymentListContainer from './modules/sale-order/bulk-payment/containers/BulkPaymentListContainer'
import BulkPaymentCreateContainer from './modules/sale-order/bulk-payment/containers/BulkPaymentCreateContainer'
import BulkPaymentUpdateContainer from './modules/sale-order/bulk-payment/containers/BulkPaymentUpdateContainer'
import ApprovalProcessCreateContainer
  from './modules/settings/approval-process/containers/ApprovalProcessCreateContainer'
import WarehouseBulkContainer from './modules/warehouse/warehouse/containers/WarehouseBulkContainer'
import DocumentListContainer from './modules/settings/document/containers/DocumentListContainers'
import DocumentCreateContainer from './modules/settings/document/containers/DocumentCreateContainer'
import DocumentUpdateContainer from './modules/settings/document/containers/DocumentUpdateContainer'
import FolderListContainer from './modules/settings/document/containers/FolderListContainer'
import FolderCreateContainer from './modules/settings/document/containers/FolderCreateContainer'
import DiscountListContainer from './modules/catalog/discount/containers/DiscountListContainer'
import DiscountCreateContainer from './modules/catalog/discount/containers/DiscountCreateContainer'
import DiscountUpdateContainer from './modules/catalog/discount/containers/DiscountUpdateContainer'
import TransferCashListContainer from './modules/finance/transfer-cash/containers/TransferCashListContainer'
import TransferCashCreateContainer from './modules/finance/transfer-cash/containers/TransferCashCreateContainer'
import TransferCashUpdateContainer from './modules/finance/transfer-cash/containers/TransferCashUpdateContainer'
import ListingUpdateContainer from './modules/settings/listing/containers/ListingUpdateContainer'
import VisitTypeListContainer from './modules/visit/visit-type/containers/VisitTypeListContainer'
import VisitTypeCreateContainer from './modules/visit/visit-type/containers/VisitTypeCreateContainer'
import VisitTypeUpdateContainer from './modules/visit/visit-type/containers/VisitTypeUpdateContainer'
import ConsignmentListContainer from './modules/finance/consignment/containers/ConsignmentListContainer'
import ConsignmentCreateContainer from './modules/finance/consignment/containers/ConsignmentCreateContainer'
import ConsignmentUpdateContainer from './modules/finance/consignment/containers/ConsignmentUpdateContainer'
import PreOrderCreateContainer from './modules/sale-order/pre-order/containers/PreOrderCreateContainer'
import PreOrderUpdateContainer from './modules/sale-order/pre-order/containers/PreOrderUpdateContainer'
import VisitCommentListContainer from './modules/visit/visit-comments/containers/VisitCommentListContainer'
import VisitCommentCreateContainer from './modules/visit/visit-comments/containers/VisitCommentCreateContainer'
import VisitCommentUpdateContainer from './modules/visit/visit-comments/containers/VisitCommentUpdateContainer'
import AgentsAddressesListContainer
  from './modules/catalog/agents/agents-addresses/containers/AgentsAddressesListContainer'
import LandingSearchContainer from './modules/landing/containers/LandingSearchContainer'
import TimeSlotListContainer from './modules/settings/user-time-slot/containers/TimeSlotListContainer'
import TimeSlotCreateContainer from './modules/settings/user-time-slot/containers/TimeSlotCreateContainer'
import TimeSlotUpdateContainer from './modules/settings/user-time-slot/containers/TimeSlotUpdateContainer'
import PrivateDomainSharedContact from './modules/landing/private/containers/PrivateDomainSharedContact'
import SharedPrivateDetailContainer
  from './modules/landing/shared-private-detail/containers/SharedPrivateDetailContainer'
import NewDesignContainer from './modules/new-design/containers/NewDesignContainer'
import PublicCompanyCreateContainer from './modules/public/company/containers/PublicCompanyCreateContainer'

export enum AUTHORIZED_STATUS {
  YES = 'yes',
  NO = 'no',
  SELECT_COMPANY = 'select-company',
  CREATE_USER = 'create-user'
}

function App () {
  const [isAuthorized, setIsAuthorized] = useState('' as AUTHORIZED_STATUS)
  const host = window.location.host
  const isApp = host.includes('app')
  const token = localStorage.getItem(isApp ? 'antex-app-token' : 'antex-token') || ''
  const language = localStorage.getItem('language') || ''

  useEffect(() => {
    setIsAuthorized(token && isValidToken(token) ? AUTHORIZED_STATUS.YES : AUTHORIZED_STATUS.NO)
  }, [token])

  return (
    <NotificationProvider>
      <I18nProvider data={{ language: language as AppLanguage }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider>
            <UserProvider>
              <ConfirmProvider>
                <RoutesProvider>
                  <ThemeProvider theme={theme}>
                    <BrowserRouter>
                      <Routes>
                        {isApp && isAuthorized === AUTHORIZED_STATUS.YES && (
                          <>
                            <Route path={ROUTES.LANDING_SEARCH} element={<LandingSearchContainer />} />
                            <Route
                              path={ROUTES.LANDING_SHARED_CONTACT}
                              element={<NewDesignContainer />}
                            />
                            <Route path="*" element={<Navigate replace to={ROUTES.LANDING_SEARCH} />} />
                          </>
                        )}
                        {isApp && isAuthorized === AUTHORIZED_STATUS.NO && (
                          <>
                            <Route path={ROUTES.LANDING_SEARCH} element={<LandingSearchContainer />} />
                            <Route
                              path={ROUTES.LANDING_SHARED_CONTACT}
                              element={<NewDesignContainer />}
                            />
                            <Route path="*" element={<Navigate replace to={ROUTES.LANDING_SEARCH} />} />
                          </>
                        )}
                        {(!isApp && isAuthorized === AUTHORIZED_STATUS.NO) && (
                          <>
                            <Route
                              path={ROUTES.SIGN_IN}
                              element={<SignInContainer setIsAuthorized={setIsAuthorized} />}
                            />
                            <Route
                              path={ROUTES.AUTH_SELECT}
                              element={<SelectCompanyContainer setIsAuthorized={setIsAuthorized} />}
                            />
                            <Route path={ROUTES.AUTH_SIGNUP} element={<PublicCompanyCreateContainer />} />
                            <Route path={ROUTES.LANDING_SEARCH_PUBLIC} element={<PrivateDomainSharedContact />} />
                            <Route path={ROUTES.LANDING_SEARCH_DETAIL} element={<SharedPrivateDetailContainer />} />
                            <Route path="*" element={<Navigate replace to={ROUTES.LANDING_SEARCH} />} />
                          </>
                        )}
                        {(!isApp && isAuthorized === AUTHORIZED_STATUS.YES) && (
                          <>
                            <Route path={ROUTES.LANDING_NEW_DESIGN} element={<NewDesignContainer />} />
                            <Route path={ROUTES.LANDING_SEARCH_PUBLIC} element={<PrivateDomainSharedContact />} />
                            <Route path={ROUTES.LANDING_SEARCH_DETAIL} element={<SharedPrivateDetailContainer />} />
                            <Route path={ROUTES.SETTINGS_TERRITORY_LIST} element={<TerritoryListContainer />} />
                            <Route path={ROUTES.SETTINGS_TERRITORY_CREATE} element={<TerritoryCreateContainer />} />
                            <Route path={ROUTES.SETTINGS_TERRITORY_UPDATE} element={<TerritoryUpdateContainer />} />
                            <Route path={ROUTES.CATALOG_TERRITORY_BULK} element={<TerritoryBulkContainer />} />
                            <Route path={ROUTES.CRM_RETURN_TASK_LIST} element={<TaskReturnListContainer />} />
                            <Route path={ROUTES.CRM_RETURN_TASK_CREATE} element={<TaskReturnCreateContainer />} />
                            <Route path={ROUTES.CRM_RETURN_TASK_UPDATE} element={<TaskReturnUpdateContainer />} />

                            <Route path={ROUTES.CATALOG_PRODUCT_LIST} element={<ProductListContainer />} />
                            <Route path={ROUTES.CATALOG_PRODUCT_CREATE} element={<ProductCreateContainer />} />
                            <Route path={ROUTES.CATALOG_PRODUCT_EDIT} element={<ProductUpdateContainer />} />
                            <Route path={ROUTES.CATALOG_PRODUCT_BULK_UPDATE} element={<ProductBulkUpdateContainer />} />

                            <Route path={ROUTES.CATALOG_DISCOUNT} element={<DiscountListContainer />} />
                            <Route path={ROUTES.CATALOG_DISCOUNT_CREATE} element={<DiscountCreateContainer />} />
                            <Route path={ROUTES.CATALOG_DISCOUNT_UPDATE} element={<DiscountUpdateContainer />} />

                            <Route path={ROUTES.SETTINGS_CUSTOM_FIELD_LIST} element={<CustomFieldListContainer />} />
                            <Route
                              path={ROUTES.SETTINGS_CUSTOM_FIELD_CREATE}
                              element={<CustomFieldCreateContainer />}
                            />
                            <Route
                              path={ROUTES.SETTINGS_CUSTOM_FIELD_UPDATE}
                              element={<CustomFieldUpdateContainer />}
                            />
                            <Route path={ROUTES.SETTINGS_APPROVAL_LIST} element={<ApprovalProcessCreateContainer />} />

                            <Route path={ROUTES.CATALOG_CATEGORY_LIST} element={<CategoryListContainer />} />
                            <Route path={ROUTES.CATALOG_CATEGORY_CREATE} element={<CategoryCreateContainer />} />
                            <Route path={ROUTES.CATALOG_CATEGORY_EDIT} element={<CategoryUpdateContainer />} />
                            <Route path={ROUTES.CATALOG_ADD_PRODUCT} element={<CategoryAddProductContainer />} />

                            <Route path={ROUTES.CATALOG_BRAND_LIST} element={<BrandListContainer />} />
                            <Route path={ROUTES.CATALOG_BRAND_CREATE} element={<BrandCreateContainer />} />
                            <Route path={ROUTES.CATALOG_BRAND_EDIT} element={<BrandUpdateContainer />} />

                            <Route path={ROUTES.CATALOG_PACKAGE_LIST} element={<PackageListContainer />} />
                            <Route path={ROUTES.CATALOG_PACKAGE_CREATE} element={<PackageCreateContainer />} />
                            <Route path={ROUTES.CATALOG_PACKAGE_EDIT} element={<PackageUpdateContainer />} />

                            <Route path={ROUTES.CATALOG_COUNTRY_LIST} element={<CountryListContainer />} />
                            <Route path={ROUTES.CATALOG_COUNTRY_UPDATE} element={<CountryUpdateContainer />} />
                            <Route path={ROUTES.CATALOG_COUNTRY_CREATE} element={<CountryCreateContainer />} />

                            <Route path={ROUTES.SETTINGS_PRICE_LEVEL_LIST} element={<PriceLevelListContainer />} />
                            <Route path={ROUTES.SETTINGS_PRICE_LEVEL_CREATE} element={<PriceLevelCreateContainer />} />
                            <Route path={ROUTES.SETTINGS_PRICE_LEVEL_EDIT} element={<PriceLevelUpdateContainer />} />
                            <Route path={ROUTES.SETTINGS_CURRENCY_LIST} element={<CurrencyListContainer />} />
                            <Route path={ROUTES.SETTINGS_CURRENCY_CREATE} element={<CurrencyCreateContainer />} />
                            <Route path={ROUTES.SETTINGS_CURRENCY_UPDATE} element={<CurrencyUpdateContainer />} />
                            <Route path={ROUTES.SETTINGS_MEDIA_FILES} element={<FolderListContainer />} />
                            <Route path={ROUTES.SETTINGS_MEDIA_FILES_CREATE} element={<FolderCreateContainer />} />
                            <Route path={ROUTES.SETTINGS_MEDIA_FILES_DETAIL} element={<DocumentListContainer />} />
                            <Route path={ROUTES.SETTINGS_LISTING_UPDATE} element={<ListingUpdateContainer />} />
                            <Route
                              path={ROUTES.SETTINGS_MEDIA_FILES_DOCUMENT_CREATE}
                              element={<DocumentCreateContainer />}
                            />
                            <Route
                              path={ROUTES.SETTINGS_MEDIA_FILES_DOCUMENT_UPDATE}
                              element={<DocumentUpdateContainer />}
                            />
                            <Route path={ROUTES.SETTINGS_TIMESLOT_LIST} element={<TimeSlotListContainer />} />
                            <Route path={ROUTES.SETTINGS_TIMESLOT_CREATE} element={<TimeSlotCreateContainer />} />
                            <Route path={ROUTES.SETTINGS_TIMESLOT_UPDATE} element={<TimeSlotUpdateContainer />} />

                            <Route path={ROUTES.CATALOG_VISIT_LIST} element={<VisitListContainer />} />
                            <Route path={ROUTES.CATALOG_VISIT_CREATE} element={<VisitCreateContainer />} />
                            <Route path={ROUTES.CATALOG_VISIT_DETAILS} element={<VisitUpdateContainer />} />
                            <Route path={ROUTES.VISIT_VISIT_TYPE_LIST} element={<VisitTypeListContainer />} />
                            <Route path={ROUTES.VISIT_VISIT_TYPE_CREATE} element={<VisitTypeCreateContainer />} />
                            <Route path={ROUTES.VISIT_VISIT_TYPE_UPDATE} element={<VisitTypeUpdateContainer />} />
                            <Route path={ROUTES.VISIT_VISIT_COMMENTS_LIST} element={<VisitCommentListContainer />} />
                            <Route
                              path={ROUTES.VISIT_VISIT_COMMENTS_CREATE}
                              element={<VisitCommentCreateContainer />}
                            />
                            <Route
                              path={ROUTES.VISIT_VISIT_COMMENTS_UPDATE}
                              element={<VisitCommentUpdateContainer />}
                            />

                            <Route path={ROUTES.CATALOG_MML} element={<UnitMeasurementListContainer />} />
                            <Route path={ROUTES.CATALOG_MML_CREATE} element={<UnitMeasurementCreateContainer />} />
                            <Route path={ROUTES.CATALOG_MML_EDIT} element={<UnitMeasurementUpdateContainer />} />

                            <Route path={ROUTES.SALE_ORDER_LIST} element={<SaleOrderListContainer />} />
                            <Route path={ROUTES.SALE_ORDER_CREATE} element={<SaleOrderCreateContainer />} />
                            <Route path={ROUTES.SALE_ORDER_UPDATE} element={<SaleOrderUpdateContainer />} />
                            <Route path={ROUTES.SALE_ORDER_PRE_ORDER_LIST} element={<PreOrderListContainer />} />
                            <Route path={ROUTES.SALE_ORDER_PRE_ORDER_CREATE} element={<PreOrderCreateContainer />} />
                            <Route path={ROUTES.SALE_ORDER_PRE_ORDER_UPDATE} element={<PreOrderUpdateContainer />} />
                            <Route path={ROUTES.SALE_ORDER_SHIPMENT_LIST} element={<ShipmentListContainer />} />
                            <Route path={ROUTES.SALE_ORDER_SHIPMENT_UPDATE} element={<ShipmentUpdateContainer />} />
                            <Route path={ROUTES.SALE_ORDER_RETURN_LIST} element={<SaleOrderReturnListContainer />} />
                            <Route
                              path={ROUTES.SALE_ORDER_RETURN_CREATE}
                              element={<SaleOrderReturnCreateContainer />}
                            />
                            <Route
                              path={ROUTES.SALE_ORDER_RETURN_UPDATE}
                              element={<SaleOrderReturnUpdateContainer />}
                            />
                            <Route path={ROUTES.ORDER_PICKER_LIST} element={<PickerListContainer />} />
                            <Route path={ROUTES.ORDER_PICKER_UPDATE} element={<PickUpdateContainer />} />
                            <Route path={ROUTES.ORDER_BULK_PAYMENT_LIST} element={<BulkPaymentListContainer />} />
                            <Route path={ROUTES.ORDER_BULK_PAYMENT_CREATE} element={<BulkPaymentCreateContainer />} />
                            <Route path={ROUTES.ORDER_BULK_PAYMENT_UPDATE} element={<BulkPaymentUpdateContainer />} />

                            <Route path={ROUTES.WAREHOUSE_LIST} element={<WarehouseListContainer />} />
                            <Route path={ROUTES.WAREHOUSE_CREATE} element={<WarehouseCreateContainer />} />
                            <Route path={ROUTES.WAREHOUSE_UPDATE} element={<WarehouseUpdateContainer />} />
                            <Route path={ROUTES.WAREHOUSE_BULK} element={<WarehouseBulkContainer />} />

                            <Route path={ROUTES.WAREHOUSE_RECEIVE_LIST} element={<ReceiveListContainer />} />
                            <Route path={ROUTES.WAREHOUSE_RECEIVE_CREATE} element={<ReceiveCreateContainer />} />
                            <Route path={ROUTES.WAREHOUSE_RECEIVE_UPDATE} element={<ReceiveUpdateContainer />} />

                            <Route path={ROUTES.FINANCE_PURCHASE_LIST} element={<PurchaseOrderListContainer />} />
                            <Route path={ROUTES.FINANCE_PURCHASE_CREATE} element={<PurchaseCreateContainer />} />
                            <Route path={ROUTES.FINANCE_PURCHASE_UPDATE} element={<PurchaseUpdateContainer />} />
                            <Route
                              path={ROUTES.FINANCE_PURCHASE_RECEIVE_DETAIL}
                              element={<ReceivePODetailContainer />}
                            />
                            <Route path={ROUTES.FINANCE_CONSIGNMENT_LIST} element={<ConsignmentListContainer />} />
                            <Route path={ROUTES.FINANCE_CONSIGNMENT_CREATE} element={<ConsignmentCreateContainer />} />
                            <Route path={ROUTES.FINANCE_CONSIGNMENT_EDIT} element={<ConsignmentUpdateContainer />} />
                            <Route path={ROUTES.FINANCE_MOVING_BETWEEN_CASH} element={<TransferCashListContainer />} />
                            <Route
                              path={ROUTES.FINANCE_MOVING_BETWEEN_CASH_CREATE}
                              element={<TransferCashCreateContainer />}
                            />
                            <Route
                              path={ROUTES.FINANCE_MOVING_BETWEEN_CASH_UPDATE}
                              element={<TransferCashUpdateContainer />}
                            />

                            <Route path={ROUTES.ORDER_PAYMENT_LIST} element={<PaymentListContainer />} />
                            <Route path={ROUTES.ORDER_PAYMENT_CREATE} element={<PaymentCreateContainer />} />
                            <Route path={ROUTES.ORDER_PAYMENT_UPDATE} element={<PaymentUpdateContainer />} />

                            <Route path={ROUTES.FINANCE_MANUAL_ENTRY_LIST} element={<ManualEntryListContainer />} />
                            <Route path={ROUTES.FINANCE_MANUAL_ENTRY_CREATE} element={<ManualEntryCreateContainer />} />
                            <Route path={ROUTES.FINANCE_MANUAL_ENTRY_UPDATE} element={<ManualEntryUpdateContainer />} />

                            <Route path={ROUTES.FINANCE_TRANSACTION_LIST} element={<TransactionListContainer />} />

                            <Route path={ROUTES.WAREHOUSE_ADJUSTMENT_LIST} element={<AdjustmentListContainer />} />
                            <Route path={ROUTES.WAREHOUSE_ADJUSTMENT_CREATE} element={<AdjustmentCreateContainer />} />
                            <Route path={ROUTES.WAREHOUSE_ADJUSTMENT_UPDATE} element={<AdjustmentUpdateContainer />} />

                            <Route path={ROUTES.WAREHOUSE_GROUP_LIST} element={<WarehouseGroupListContainer />} />
                            <Route path={ROUTES.WAREHOUSE_GROUP_CREATE} element={<WarehouseGroupCreateContainer />} />
                            <Route path={ROUTES.WAREHOUSE_GROUP_UPDATE} element={<WarehouseGroupUpdateContainer />} />

                            <Route path={ROUTES.FINANCE_PURCHASE_LIST} element={<PurchaseOrderListContainer />} />
                            <Route path={ROUTES.FINANCE_PURCHASE_CREATE} element={<PurchaseCreateContainer />} />
                            <Route path={ROUTES.FINANCE_PURCHASE_UPDATE} element={<PurchaseUpdateContainer />} />
                            <Route path={ROUTES.FINANCE_AGENT_LIST} element={<AgentListContainer />} />
                            <Route path={ROUTES.FINANCE_AGENT_CREATE} element={<AgentCreateContainer />} />
                            <Route path={ROUTES.FINANCE_AGENT_EDIT} element={<AgentUpdateContainer />} />
                            <Route
                              path={ROUTES.CATALOG_AGENTS_ADDRESSES_MAP}
                              element={<AgentsAddressesListContainer />}
                            />
                            <Route
                              path={ROUTES.FINANCE_AGENT_CATEGORY_LIST}
                              element={<ContactCategoryListContainer />}
                            />
                            <Route
                              path={ROUTES.FINANCE_AGENT_CATEGORY_CREATE}
                              element={<AgentCategoryCreateContainer />}
                            />
                            <Route
                              path={ROUTES.FINANCE_AGENT_CATEGORY_EDIT}
                              element={<AgentCategoryUpdateContainer />}
                            />
                            <Route
                              path={ROUTES.FINANCE_SUPPLIER_PAYMENTS_LIST}
                              element={<SupplierPaymentsListContainer />}
                            />
                            <Route
                              path={ROUTES.FINANCE_SUPPLIER_PAYMENTS_CREATE}
                              element={<SupplierPaymentsCreateContainer />}
                            />
                            <Route
                              path={ROUTES.FINANCE_SUPPLIER_PAYMENTS_UPDATE}
                              element={<SupplierPaymentsUpdateContainer />}
                            />

                            <Route
                              path={ROUTES.FINANCE_CHART_OF_ACCOUNT_LISTING}
                              element={<ChartOfAccountingListContainer />}
                            />
                            <Route
                              path={ROUTES.FINANCE_CHART_OF_ACCOUNT_CREATE}
                              element={<ChartOfAccountCreateContainer />}
                            />

                            <Route path={ROUTES.HR_EMPLOYEE_LIST} element={<EmployeeListContainer />} />
                            <Route path={ROUTES.HR_EMPLOYEE_CREATE} element={<EmployeeCreateContainer />} />
                            <Route path={ROUTES.HR_EMPLOYEE_UPDATE} element={<EmployeeUpdateContainer />} />
                            <Route path={ROUTES.HR_TRACKING_LIST} element={<TrackingListContainer />} />

                            <Route path={ROUTES.WAREHOUSE_MOVEMENT_LIST} element={<TransferListContainer />} />
                            <Route path={ROUTES.WAREHOUSE_MOVEMENT_CREATE} element={<TransferCreateContainer />} />
                            <Route path={ROUTES.WAREHOUSE_MOVEMENT_UPDATE} element={<TransferUpdateContainer />} />
                            // Backend
                            <Route path={ROUTES.BACKEND_COMPANY_LIST} element={<CompanyListContainer />} />
                            <Route path={ROUTES.BACKEND_COMPANY_CREATE} element={<CompanyCreateContainer />} />
                            <Route path={ROUTES.BACKEND_COMPANY_ADD_USER} element={<CompanyCreateUserContainer />} />
                            <Route path={ROUTES.BACKEND_COMPANY_EDIT} element={<CompanyUpdateContainer />} />
                            <Route path={ROUTES.BACKEND_NEWS_LIST} element={<NewsListContainer />} />
                            <Route path={ROUTES.BACKEND_NEWS_CREATE} element={<NewsCreateContainer />} />
                            <Route path={ROUTES.BACKEND_NEWS_UPDATE} element={<NewsUpdateContainer />} />
                            <Route path={ROUTES.BACKEND_BILLING_LIST} element={<BillingPlanListContainer />} />
                            <Route path={ROUTES.BACKEND_BILLING_CREATE} element={<BillingPlanCreateContainer />} />
                            <Route path={ROUTES.BACKEND_BILLING_UPDATE} element={<BillingPlanUpdateContainer />} />
                            <Route
                              path={ROUTES.BACKEND_BILLING_CATEGORY_LIST}
                              element={<BillingPLanCategoryListContainer />}
                            />
                            <Route
                              path={ROUTES.BACKEND_BILLING_CATEGORY_CREATE}
                              element={<BillingPlanCategoryCreateContainer />}
                            />
                            <Route
                              path={ROUTES.BACKEND_BILLING_CATEGORY_UPDATE}
                              element={<BillingPlanCategoryUpdateContainer />}
                            />
                            <Route
                              path={ROUTES.BACKEND_SHARED_CONTACT_CATEGORY_LIST}
                              element={<SharedContactCategoryListContainer />}
                            />
                            <Route
                              path={ROUTES.BACKEND_SHARED_CONTACT_CATEGORY_CREATE}
                              element={<SharedContactCategoryCreateContainer />}
                            />
                            <Route
                              path={ROUTES.BACKEND_SHARED_CONTACT_CATEGORY_UPDATE}
                              element={<SharedContactCategoryUpdateContainer />}
                            />
                            <Route
                              path={ROUTES.BACKEND_SHARED_CONTACT_LIST}
                              element={<SharedContactListContainer />}
                            />
                            <Route
                              path={ROUTES.BACKEND_SHARED_CONTACT_CREATE}
                              element={<SharedContactCreateContainer />}
                            />
                            <Route
                              path={ROUTES.BACKEND_SHARED_CONTACT_UPDATE}
                              element={<SharedContactUpdateContainer />}
                            />
                            // Marketing
                            <Route path={ROUTES.MARKETING_RENT_LIST} element={<RentListContainer />} />
                            <Route path={ROUTES.MARKETING_RENT_CREATE} element={<RentCreateContainer />} />
                            <Route path={ROUTES.MARKETING_RENT_UPDATE} element={<RentUpdateContainer />} />
                            <Route path={ROUTES.MARKETING_BONUS_LIST} element={<BonusListContainer />} />
                            <Route path={ROUTES.MARKETING_BONUS_CREATE} element={<BonusCreateContainer />} />
                            <Route path={ROUTES.MARKETING_BONUS_UPDATE} element={<BonusUpdateContainer />} />

                            <Route path={ROUTES.REPORTS_WAREHOUSE_REMAINS} element={<StockListContainer />} />

                            <Route path="*" element={<Navigate replace to={ROUTES.CATALOG_PRODUCT_LIST} />} />
                          </>
                        )}
                      </Routes>
                    </BrowserRouter>
                  </ThemeProvider>
                </RoutesProvider>
              </ConfirmProvider>
            </UserProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </I18nProvider>
    </NotificationProvider>
  )
}

export default App
