import React from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles({
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    },
    '&.rejected': {
      background: '#e33b3b',
      color: 'white'
    }
  }
})

interface Props {
  readonly status?: string
}

function PurchaseStatusRow ({ status = 'N/A' }: Props) {
  const classes = useStyles()
  const cxProps = { 'active': status === 'DELIVERED', 'rejected': status === 'REJECTED' }
  return (
    <div
      className={cx(classes.statusIndicator, cxProps)}>
      {status}
    </div>
  )
}

export default PurchaseStatusRow
