import React, { useCallback, useEffect, useState } from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import DateField from 'components/form/DateField'
import { path, pathOr, prop } from 'ramda'
import { useField } from 'formik'
import useDialog from 'hooks/useDialog'
import Stepper from 'components/stepper/Stepper'

import WarehouseGroupField from './form/WarehouseField'
import PaymentMethodField from './form/PaymentMethodField'
import LineItems from './form/LineItems'
import SaleOrderSummery from './SaleOrderSummery'
import ClientField from './form/ClientField'
import PriceLevelField from './form/PriceLevelField'
import TerritoryField from './form/TerritoryField'
import AttachmentField from './form/AttachmentField'
import CurrencyField from './form/CurrencyField'
import ClientCreateDialogue from './ClientCreateDialogue'
import BonusAddDialog from './BonusAddDialogue'
import AgentField from './form/AgentField'
import DriverField from './form/DriverField'
import VisitField from './form/VisitField'
import CountryField from './form/CountryField'
import RegionField from './form/RegionField'
import ContactAddressSelectField from './form/ContactAddressSelectField'
import VisitDateField from './form/VisitDateField'

interface Props {
  readonly productList: object
  readonly form: any
  readonly bonuses:any[]
  readonly onCheckBonuses:(values:any) => void
  readonly onComplete:(data:any) => void
  readonly handleGetPriceLevel: (price: number, product: any) => Promise<any>
  readonly onClientCreate: (values: any) => Promise<any>
}

const steps = [
  { id:1, label:'Детали' },
  { id:2, label:'Выберите продукты' }
]

function SaleOrderCreateForm (props: Props) {
  const {
    productList,
    form,
    handleGetPriceLevel,
    onComplete,
    onClientCreate,
    bonuses,
    onCheckBonuses
  } = props
  const clientCreateDialogue = useDialog()
  const bonusAddDialogue = useDialog()
  const [activeStep, setActiveStep] = useState(steps[0].id)
  const [files, setFiles] = useState([] as any)
  const items = pathOr([], ['values', 'items'], form)
  const bonusId = path(['values', 'bonusId'], form)
  const error = prop('errors', form)
  const isValid = Object.keys(error).length === 0
  const attachmentsField = useField('attachments')
  const attachmentSetFieldValues = attachmentsField[2]

  const bonusIdField = useField('bonusId')
  const bonusIdsSet = bonusIdField[2].setValue

  const handleFileSet = (value: any) => {
    setFiles((prev: any) => [...prev, value])
  }

  useEffect(() => {
    attachmentSetFieldValues.setValue(files)
  }, [files])

  const onNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const onBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleFileDelete = (id: any) => {
    setFiles((prev: any) => prev.filter((img: any) => img.id !== id))
  }

  const handleComplete = () => {
    onComplete(form.values)
  }

  const onSubmitClient = useCallback((values: any) => {
    onClientCreate(values).then(() => {
      clientCreateDialogue.handleClose()
    })
  }, [onClientCreate])

  const onCheck = () => {
    onCheckBonuses(form.values)
    bonusAddDialogue.handleOpen()
  }

  const onAddBonusIds = useCallback((values:any) => {
    bonusIdsSet(values)
    bonusAddDialogue.handleClose()
  }, [])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать заказ"
          form={form}
          rightButton={(
            <>
              {activeStep === 1 && (
                <Button
                  type="button"
                  onClick={onNext}
                >
                  Следующий
                </Button>
              )}
              {activeStep === 2 && (
                <>
                  <Button
                    type="button"
                    onClick={onBack}
                  >
                    Назад
                  </Button>
                  <Button
                    type="button"
                    disabled={!isValid || bonusId}
                    onClick={onCheck}
                  >
                    Проверьте бонусов
                  </Button>
                  <Button
                    type="button"
                    onClick={handleComplete}
                  >
                    Создать и Забронировать
                  </Button>
                  <Button type="submit">Создать</Button>
                </>
              )}
            </>
          )}
        />
      </Header>
      <Content>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
        <Grid container={true} spacing={3}>
          {activeStep === 1 && (
            <>
              <Grid item={true} lg={12}>
                <Grid item={true} lg={12}>
                  <Card>
                    <CardHeader title="Детали заказа" />
                    <CardContent>
                      <Grid container={true} spacing={3}>
                        <Grid item={true} lg={4}>
                          <TerritoryField />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <DateField
                            label="Дата заказа"
                            name="orderDate"
                          />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <WarehouseGroupField />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <DriverField />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <PriceLevelField />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <CurrencyField />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <PaymentMethodField />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <TextField
                            label="Описание"
                            name="notes"
                            minRows={4}
                            multiline
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                  </Card>
                  <Card>
                    <CardHeader title="Клиент" />
                    <CardContent>
                      <Grid container={true} spacing={3}>
                        <Grid item={true} lg={8}>
                          <Grid container={true} spacing={2}>
                            <Grid item={true} lg={8}>
                              <ClientField />
                            </Grid>
                            <Grid item={true} lg={4}>
                              <Button
                                fullWidth={true}
                                type="button"
                                onClick={() => clientCreateDialogue.handleOpen()}
                              >
                                Создать
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item={true} lg={4}>
                          <ContactAddressSelectField />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                  </Card>
                  <Card>
                    <CardHeader title="Адрес" />
                    <CardContent>
                      <Grid container={true} spacing={3}>
                        <Grid item={true} lg={4}>
                          <TextField label="Адресс" name="address.addressLine1" />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <TextField label="Адрес дополнительный" name="address.addressLine2" />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <TextField label="Ориентир" name="address.landmark" />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <CountryField label="Название страны" name="address.countryId" />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <RegionField label="Город" name="address.regionId" />
                        </Grid>
                        <Grid item={true} lg={4}>
                          <TextField label="Координаты - лат/лон" name="address.location" />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                  </Card>
                  <Card>
                    <CardHeader title="Детали визиты" />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item lg={6}>
                          <VisitField />
                        </Grid>
                        <Grid item={true} lg={6}>
                          <AgentField />
                        </Grid>
                        <Grid item={true} lg={6}>
                          <VisitDateField
                            disabled={true}
                            label="Дата посещения"
                            name="visitDate"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Grid item={true} lg={12}>
                <LineItems
                  productList={productList}
                  handleGetPriceLevel={handleGetPriceLevel}
                />
              </Grid>
              <SaleOrderSummery items={items} />
              <Grid item={true} lg={12}>
                <AttachmentField
                  files={files}
                  onFileSet={handleFileSet}
                  onFileDelete={handleFileDelete}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Content>
      {clientCreateDialogue.open && (
        <ClientCreateDialogue
          open={clientCreateDialogue.open}
          handleClose={clientCreateDialogue.handleClose}
          loading={false}
          onSubmit={onSubmitClient}
          initialValues={{}}
        />
      )}
      {activeStep === 2 && bonusAddDialogue.open && (
        <BonusAddDialog
          onAddBonusIds={onAddBonusIds}
          onClose={bonusAddDialogue.handleClose}
          open={bonusAddDialogue.open}
          bonuses={bonuses}
        />
      )}
    </Container>
  )
}

export default withForm(SaleOrderCreateForm)
