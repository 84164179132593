import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import React, { useEffect } from 'react'
import { FieldArray, useField } from 'formik'
import { pathOr, prop } from 'ramda'
import { makeStyles } from '@mui/styles'
import DoubleFormatField from 'components/form/DoubleFormatField'
import { stringFormatMoney } from 'utils/formatMoney'
import SwitchField from 'components/form/SwitchField'
import { useLocales } from 'hooks/useLocales'

const useStyles = makeStyles({
  tableRoot:{
    borderRadius:'7px',
    border:'1px solid #E9E9E9'
  },
  tableHeader: {
    background: '#F1F1F1',
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset'
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '10px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      boxShadow: '2px 2px 4px rgba(167, 172, 176, 0.1)',
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly editView: boolean
  readonly lineItems:any
}

function LineItemsTable ({ editView, lineItems }: Props) {
  const { t } = useLocales('priceLevel')
  const classes = useStyles()
  const lineItemsField = useField('lineItemDTOs')
  const lineItemsInit = lineItemsField[0].value || []

  useEffect(() => {
    if (lineItems.length > 0) {
      lineItemsField[2].setValue(lineItems)
    }
  }, [lineItems])

  return (
    <FieldArray name="lineItemDTOs">
      {() => {
        const renderLineItems = Boolean(lineItemsInit.length)
        return (
          <TableContainer className={classes.tableRoot}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell width={100}>{t('Product')}</TableCell>
                  {editView && <TableCell width={30}>{t('Make it dynamic')}</TableCell>}
                  <TableCell width={200}>{t('Min. price')}</TableCell>
                  <TableCell width={200}>{t('Max. price')}</TableCell>
                  <TableCell width={200}>{t('Price')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {renderLineItems && lineItemsInit.map((item: any, index: any) => {
                  const productName = pathOr('', ['product', 'name'], item)
                  const minPrice = pathOr('', ['minPrice'], item)
                  const maxPrice = pathOr('', ['maxPrice'], item)
                  const value = pathOr('', ['value'], item)
                  const onDynamic = prop('onDynamic', item)
                  const isMinPriceEdit = onDynamic ? minPrice : value
                  const isMaxPriceEdit = onDynamic ? maxPrice : value

                  return editView ? (
                    <TableRow>
                      <TableCell width={100}>
                        {productName}
                      </TableCell>
                      <TableCell width={30}>
                        <SwitchField name={`lineItemDTOs[${index}].onDynamic`} />
                      </TableCell>
                      <TableCell width={200}>
                        <DoubleFormatField
                          name={`lineItemDTOs[${index}].minPrice`}
                          value={isMinPriceEdit}
                          disabled={!onDynamic}
                        />
                      </TableCell>
                      <TableCell width={200}>
                        <DoubleFormatField
                          name={`lineItemDTOs[${index}].maxPrice`}
                          value={isMaxPriceEdit}
                          disabled={!onDynamic}
                        />
                      </TableCell>
                      <TableCell width={200}>
                        <DoubleFormatField name={`lineItemDTOs[${index}].value`} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell>{productName}</TableCell>
                      <TableCell>{stringFormatMoney(minPrice)}</TableCell>
                      <TableCell>{stringFormatMoney(maxPrice)}</TableCell>
                      <TableCell>{stringFormatMoney(value)}</TableCell>
                    </TableRow>
                  )
                })}

              </TableBody>
            </Table>
          </TableContainer>
        )
      } }
    </FieldArray>
  )
}

export default LineItemsTable
