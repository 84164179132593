import { path, prop } from 'ramda'

export const tabSerializer = (values:any) => {
  const listingColumnId = path(['listingColumnId', 'id'], values)
  const listingFilterId = path(['listingFilterId', 'id'], values)
  const listingSortId = path(['listingSortId', 'id'], values)
  return { ...values, listingColumnId, listingFilterId, listingSortId }
}

export const tabDetailSerializer = (values:any) => {
  const listingColumnId = prop('listingColumn', values)
  const listingFilterId = prop('listingFilter', values)
  const listingSort = prop('listingSort', values)
  return { ...values, listingColumnId, listingFilterId, listingSort }
}
