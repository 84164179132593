import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { name: 'Завершено', value: 'COMPLETED' },
  { name: 'Черновик', value: 'DRAFT' },
  { name: 'Не выполнено', value: 'FAILED' },
  { name: 'Запланировано', value: 'PLANNED' },
  { name: 'В процессе', value: 'IN_PROGRESS' },
  { name: 'Ожидание', value: 'WAITING' },

]

function VisitStatusField (props: any) {
  return (
    <SelectField
      name="status"
      label="Статус"
      options={OPTIONS}
      {...props}
    />
  )
}

export default VisitStatusField
