import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getBrandList = (options?: Options) => {
  return getRequest<any>(API.BrandList, options)
}

export const getBrandDetail = (options?: Options) => {
  return getRequest<any>(API.BrandDetail, options)
}

export const createBrand = (options?: Options) => {
  return postRequest<any>(API.BrandCreate, options)
}

export const updateBrand = (options?: Options) => {
  return putRequest<any>(API.BrandUpdate, options)
}

export const deleteBrand = (options?: Options) => {
  return deleteRequest<any>(API.BrandDelete, options)
}

export const deleteBrandByIds = (options?: Options) => {
  return deleteRequest<any>(API.BrandDeleteByIds, options)
}

export const updateStatus = (options?:Options) => {
  return patchRequest<any>(API.BrandUpdateStatus, options)
}

export const updateStatusBulk = (options?:Options) => {
  return patchRequest<any>(API.BrandUpdateStatusBulk, options)
}

export const brandImportExcel = (options?:Options) => {
  return postRequest<any>(API.BrandImport, options)
}
