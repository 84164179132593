import React, { useCallback } from 'react'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { FieldArray, useField } from 'formik'
import Container from 'components/container/Container'
import Content from 'components/container/Content'
import withForm from 'components/form/withForm'
import Header from 'components/container/Header'
import PageTitle from 'components/main/PageTitle'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import SwitchField from 'components/form/SwitchField'
import { path } from 'ramda'

import CustomFieldType, { CUSTOM_FIELD_TYPE } from './CustomFieldType'
import CustomFieldFormType from './CustomFieldFormType'
import CustomLookupField from './form/CustomLookupField'

function CustomFieldUpdateForm ({ form }:any) {
  const name = path(['values', 'name'], form)
  const predefinedField = useField('predefinedValues')
  const fieldType = useField('fieldType')
  const predefinedValues = predefinedField[0].value
  const predefinedChange = predefinedField[2].setValue
  const fieldTypeValue = fieldType[0].value === CUSTOM_FIELD_TYPE.MULTI_LOOKUP ||
    fieldType[0].value === CUSTOM_FIELD_TYPE.LOOKUP
  const onFieldTypeChange = useCallback((value: string) => {
    if (
      value === CUSTOM_FIELD_TYPE.DROPDOWN ||
      value === CUSTOM_FIELD_TYPE.MULTI_CHECK_BOX ||
      value === CUSTOM_FIELD_TYPE.RADIO_BUTTON
    ) {
      predefinedChange([])
    } else predefinedChange(null)
  }, [])

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить настраевоемое поле (${name})`}
          rightButton={(
            <>
              <Button type="submit">Сохранить изменения</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Тип" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <CustomFieldFormType />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Настраиваемое поле" />
              <CardContent>
                <Grid item={true} lg={12}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Название"
                        name="name"
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <CustomFieldType onChange={onFieldTypeChange} />
                    </Grid>
                    <Grid item={true} lg={4}>
                      {fieldTypeValue && (
                        <CustomLookupField />
                      )}
                    </Grid>
                    <Grid item={true} lg={4}>
                      <SwitchField
                        name="required"
                        label="Обезательное"
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <SwitchField
                        name="showInListing"
                        label="Показывать в списке"
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <SwitchField
                        name="disabled"
                        label="Отключён"
                      />
                    </Grid>
                    {predefinedValues && !fieldTypeValue && (
                      <Grid item={true} lg={12}>
                        <FieldArray name="predefinedValues">
                          {({ push, remove }: any) => (
                            <Grid container={true} spacing={3}>
                              <Grid item={true} lg={12}>Значения</Grid>
                              {predefinedValues.map((item: string, index: number) => {
                                return (
                                  <Grid item={true} lg={12}>
                                    <Grid container={true} spacing={3}>
                                      <Grid item={true} lg={8}>
                                        <TextField
                                          label="Значение"
                                          name={`predefinedValues[${index}]`}
                                        />
                                      </Grid>
                                      <Grid item={true} lg={4}>
                                        <Button onClick={() => remove(index)} fullWidth={true}>
                                          Удалить
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )
                              })}
                              <Grid item={true} lg={12}>
                                <Button onClick={() => push('')}>Добавить значение</Button>
                              </Grid>
                            </Grid>
                          )}
                        </FieldArray>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(CustomFieldUpdateForm)
