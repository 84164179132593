import * as API from 'constants/Api'

import React from 'react'
import { prop, propOr } from 'ramda'

import FilterAvailableColumnSearchField from './FilterAvailableColumnSearchField'

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('id', value)
    const name = propOr('', 'name', value)
    const code = prop('code', value)
    const fieldType = prop('fieldType', value)
    const lookUpType = prop('lookUpType', value)

    return { id, name, code, fieldType, lookUpType }
  }

  return null
}

function FilterAvailableColumnField (props:any) {
  return (
    <FilterAvailableColumnSearchField
      api={API.ListingFilterGetByType}
      label="Доступные колонки"
      param={{ type: 'SHIPMENT_LIST_PANEL' }}
      getOptionValue={getOptionValue}
      primaryKey="code"
      {...props}
    />
  )
}

export default FilterAvailableColumnField
