import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getContactList = (options?: Options) => {
  return getRequest<any>(API.SharedContactList, options)
}

export const getByIdContact = (options?: Options) => {
  return getRequest<any>(API.SharedContactGetById, options)
}

export const createContact = (options?: Options) => {
  return postRequest<any>(API.SharedContactCreate, options)
}

export const updateContact = (options?: Options) => {
  return putRequest<any>(API.SharedContactUpdate, options)
}

export const deleteContact = (options?: Options) => {
  return patchRequest<any>(API.SharedContactDelete, options)
}

export const uploadFiles = (options?:Options) => {
  return postRequest<any>(API.AttachmentPublicUpload, options)
}

export const deleteUploadFile = (options?:Options) => {
  return deleteRequest<any>(API.RemoveAttachment, options)
}

export const importExcelContact = (options?:Options) => {
  return postRequest<any>(API.ImportExcelContact, options)
}

export const defaultImageContact = (options?:Options) => {
  return patchRequest<any>(API.ContactDefaultImage, options)
}
