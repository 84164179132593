import React from 'react'
export const ProductsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 2048 2048"
    >
      <path
        fill="currentColor"
        d="M960 120l832 416v1040l-832 415-832-415V536zm625 456L960 264 719 384l621 314zM960 888l238-118-622-314-241
         120zM256 680v816l640 320v-816zm768 1136l640-320V680l-640 320z"
      />
    </svg>
  )
}
