import * as API from 'constants/Api'

import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import { getRequest } from 'api/BaseApi'
import { Button } from '@mui/material'
import { useGetDetail } from 'hooks/useGetDetail'
import { path, pathOr } from 'ramda'
import { useLocales } from 'hooks/useLocales'

import ProductTabs, { PRODUCT_TABS } from './ProductTabs'
import ProductUpdateFormGeneral from './ProductUpdateFormGeneral'
import ProductStockHistoryTable from './ProductStockHistoryTable'
import ProductStockTable from './ProductStockTable'

import { getStockListByProductId } from '../api'

interface Props {
  readonly stockHistory: any
  readonly packageList: any
  readonly customFields: any
  readonly form:any
}

function ProductUpdateForm ({ stockHistory, packageList, customFields, form }: Props) {
  const { t } = useLocales('product')
  const { id, tab } = useParams()
  const name = path(['values', 'name'], form)
  const stockListByProductId = useGetDetail(getStockListByProductId, { query: { productId: id } })
  const stockList = pathOr('[]', ['result', 'list'], stockListByProductId)

  const onGetStockExcel = useCallback(() => {
    getRequest(API.StockHistoryListExcel, { query: { productId: id }, responseType: 'blob' }).then((response:any) => {
      // Convert response to blob
      const blob = new Blob([response])

      // Create a download link
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.setAttribute('download', `stock-history.xlsx`)
      document.body.appendChild(link)
      link.click()

      // Cleanup
      window.URL.revokeObjectURL(link.href)
    })
  }, [])

  useEffect(() => {
    if (form.dirty && form.status) {
      form.resetForm({ values: form.values })
      form.setStatus(undefined)
    }
  }, [form.dirty, form.status])

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`${t('Update product')} (${name})`}
          rightButton={(
            <>
              {tab === PRODUCT_TABS.STOCK && (
                <Button
                  type="button"
                  onClick={onGetStockExcel}
                >
                  {t('Export')}
                </Button>
              )}
              <Button
                type="submit"
              >
                {t('Save changes')}
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <ProductTabs value={tab as string} />
        {tab === PRODUCT_TABS.GENERAL && (
          <ProductUpdateFormGeneral
            form={form}
            packageList={packageList}
            customFields={customFields}
          />
        )}
        {tab === PRODUCT_TABS.STOCK && (
          <ProductStockHistoryTable
            stockHistory={stockHistory}
            initialValues={{}}
            onSubmit={null}
          />
        )}
        {tab === PRODUCT_TABS.INVENTORY && (
          <ProductStockTable
            stockList={stockList}
          />
        )}
      </Content>
    </Container>
  )
}

export default withForm(ProductUpdateForm)
