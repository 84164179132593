import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import AppLayout from 'components/main/AppLayout'
import { useNavigate, useParams } from 'react-router'
import { ALTER_ERROR, useSnackbar } from 'context/snackbar'
import { prop, propOr } from 'ramda'

import ManualEntryUpdateForm from '../components/ManualEntryUpdateForm'
import { updateManualEntry, getManualEntryDetail } from '../api'
import { usePut } from '../../../../hooks/usePut'
import { useGetDetail } from '../../../../hooks/useGetDetail'
import { TRANSACTION_TYPE } from '../components/ManualEntryTypeField'
import { manualEntryCreateSerializer, manualEntryUpdateInitialSerializer } from '../serializers'

function ManualEntryUpdateContainer () {
  const { id } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const transactionUpdate = usePut(updateManualEntry)
  const transactionDetail = useGetDetail(getManualEntryDetail, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    const items = propOr([], 'items', values) as any
    const creditItems = items.filter((item: any) => prop('transactionType', item) === TRANSACTION_TYPE.CREDIT)
    const debitItems = items.filter((item: any) => prop('transactionType', item) === TRANSACTION_TYPE.DEBIT)
    const creditAmount = creditItems.reduce((a: any, b: any) => propOr(0, 'amount', b) + a, 0)
    const debitAmount = debitItems.reduce((a: any, b: any) => propOr(0, 'amount', b) + a, 0)
    if (creditAmount === debitAmount) {
      transactionUpdate.putData({ data: manualEntryCreateSerializer(values) })
        .then(() => navigate(ROUTES.FINANCE_MANUAL_ENTRY_LIST))
        .then(() => snackbar({ message: 'Корректировка успешно обновлена' }))
    } else {
      snackbar({ message: 'Количество дебит и кредит должны быть равны', type: ALTER_ERROR })
    }
  }, [])

  const initialValues = useMemo(() => {
    return manualEntryUpdateInitialSerializer(transactionDetail.result)
  }, [transactionDetail])

  return (
    <AppLayout>
      {!transactionDetail.loading && (
        <ManualEntryUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      )}
      {transactionDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default ManualEntryUpdateContainer
