import React from 'react'

function ActionIcon (props: any) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_271_4791)">
        <path
          d="M10.8332 2.5V8.33333H15.8332L9.1665 17.5V11.6667H4.1665L10.8332 2.5Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_271_4791">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ActionIcon
