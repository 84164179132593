import React, { useCallback } from 'react'
import { Button, Grid } from '@mui/material'
import { useField } from 'formik'
import TextField from 'components/form/TextField'

import AgentMapCard from '../AgentMapCard'
import RegionField from '../form/RegionField'
import CountryField from '../form/CountryField'

interface Props {
  readonly index: number
  readonly remove: any
  readonly push: any
  readonly addresses: any
}

function AddressRow ({ index, remove, push, addresses }: Props) {
  const latField = useField(`addresses[${index}].lat`)
  const lngField = useField(`addresses[${index}].lon`)
  const nameField = useField(`addresses[${index}].name`)

  const onChangeLatLng = useCallback(({ lat, lng, label }: any) => {
    latField[2].setValue(lat)
    lngField[2].setValue(lng)
    if (label) {
      nameField[2].setValue(label)
    }
  }, [])

  return (
    <Grid item={true} key={index} lg={12}>
      <Grid container={true} spacing={3}>
        <Grid item={true} lg={6}>
          <Grid container={true} spacing={3}>
            <Grid item={true} lg={6}>
              <TextField
                label={'Имя адреса ' + (index + 1)}
                name={`addresses[${index}].name`}
              />
            </Grid>
            <Grid item={true} lg={6}>
              <TextField
                label={'Адрес ' + (index + 1)}
                name={`addresses[${index}].addressLine1`}
              />
            </Grid>
            <Grid item={true} lg={6}>
              <TextField
                label={'Адрес дополнительный ' + (index + 1)}
                name={`addresses[${index}].addressLine2`}
              />
            </Grid>
            <Grid item={true} lg={6}>
              <TextField
                label={'Ориентир ' + (index + 1)}
                name={`addresses[${index}].landmark`}
              />
            </Grid>
            <Grid item={true} lg={6}>
              <CountryField
                label={'Название страны ' + (index + 1)}
                name={`addresses[${index}].countryId`}
              />
            </Grid>
            <Grid item={true} lg={6}>
              <RegionField
                label={'Город ' + (index + 1)}
                name={`addresses[${index}].regionId`}
              />
            </Grid>
            <Grid item={true} lg={6}>
              <Button
                onClick={() => remove(index)}
                fullWidth
              >
                Удалить
              </Button>
            </Grid>
            {index + 1 === addresses.length && (
              <Grid item={true} lg={6}>
                <Button
                  onClick={() => {
                    push({
                      name:'',
                      landmark:'',
                      countryId:'',
                      regionId:'',
                      addressLine1:'',
                      addressLine2:'',
                    })
                  }}
                  fullWidth
                >
                  Добавит адрес
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item={true} lg={6}>
          <AgentMapCard
            onChange={onChangeLatLng}
            lat={latField[0].value}
            lng={lngField[0].value}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddressRow
