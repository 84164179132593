import React from 'react'
import { makeStyles } from '@mui/styles'
import { useField, useFormikContext } from 'formik'
import { path, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import { Button, Grid } from '@mui/material'
import cx from 'classnames'
import FlexBox from 'components/main/FlexBox'
import TabSettingsIcon from 'assets/tab-settings-icon.svg'
import TextField from 'components/form/TextField'
import SaveIcon from 'assets/save-icon.svg'
import withForm from 'components/form/withForm'
import SwitchField from 'components/form/SwitchField'

import FilterColumns from './form/FilterColumns'
import FilterFieldVisibility from './form/FilterFieldVisibility'
import FilterFieldRoles from './form/FilterFieldRoles'
import FilterFieldUsers from './form/FilterFieldUsers'

export const useStyles = makeStyles({
  tableRoot:{
    maxHeight:'460px',
    borderRadius:'7px',
    border:'1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      cursor:'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA'
    }
  },
  header: {
    padding: '17px 20px',
    background: 'rgba(253, 253, 253, 0.5)',
    backdropFilter: 'blur(15px)',
    borderBottom:'1px solid #f1f1f1'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-between'
  },
  wrapper:{
    width:'800px',
    background:'#fff',
    minHeight: 'calc(100vh - 60px)'
  },
  addButton: {
    cursor: 'pointer'
  },
  searchBox:{
    padding:'20px',
    borderBottom:'1px solid #f1f1f1'
  },
  columnBox:{
    padding:'20px',
    height: 'calc(100vh - 256px)',
    overflowY:'auto'
  },
  menuItem:{
    padding:'10px 20px',
    '&:hover':{
      background:'#F6F8FD'
    }
  },
  actions:{
    padding:'20px',
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end'
  },
  button: {
    fontSize: '13px',
    display: 'flex',
    alignItems:'center',
    gap:'5px',
    height: '40px',
    borderRadius: '5px',
    padding: '9px 15px',
    textTransform:'capitalize',
    '&.disabled': {
      background: '#202020 !important',
      opacity: '0.5'
    }
  }
})

interface Props {
  readonly onBack:() => void
}

const ColumnCreateForm = ({ onBack }:Props) => {
  const classes = useStyles()
  const { values } = useFormikContext()
  const columns = propOr([], 'columns', values) as any
  const visibilityField = useField('visibleTo')
  const visibilityValue = path(['0', 'value'], visibilityField)
  const isUserAndRoles = visibilityValue === 'SELECTED_USERS_OR_ROLES'

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <FlexBox align="center">
            <img src={TabSettingsIcon} alt="AddIcon" />
            <Typography sx={{ marginLeft: '10px' }}>
              Настройка колонок
            </Typography>
          </FlexBox>
        </div>
        <Grid container={true} spacing={1} pt={2}>
          <Grid item={true} lg={9}>
            <TextField placeholder="Умный фильтр" name="name" size="small" inputVariant="outlined" />
          </Grid>
          <Grid item={true} lg={3}>
            <Button type="submit" className={cx(classes.button, { 'disabled': '' })} fullWidth={true}>
              <img src={SaveIcon} alt="save-icon" />
              Сохранить колонок
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className={classes.columnBox}>
        <Grid container={true} spacing={2}>
          <Grid item={true} lg={6}>
            <FilterFieldVisibility />
          </Grid>
          <Grid item={true} lg={6} >
            <SwitchField
              label="Фильтр по умолчанию"
              name="defaultSelected"
            />
          </Grid>
          {isUserAndRoles && (
            <>
              <Grid item={true} lg={6}>
                <FilterFieldRoles />
              </Grid>
              <Grid item={true} lg={6}>
                <FilterFieldUsers />
              </Grid>
            </>
          )}
          <Grid item={true} lg={12}>
            <FilterColumns name="columns" initial={columns} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.actions}>
        <FlexBox align="center" gap="8px">
          <Button
            color="secondary"
            variant="contained"
            className={classes.button}
            onClick={onBack}
          >
            <img src={SaveIcon} alt="save-icon" />
            Отменить
          </Button>
        </FlexBox>
      </div>
    </div>
  )
}

export default withForm(ColumnCreateForm)
