import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useField } from 'formik'
import axios from 'axios'

import TextField from './TextField'

import useDebounce from '../../hooks/useDebounce'
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect'

const { CancelToken } = axios
const getCancelToken = () => CancelToken.source()

function InfinityScrollSearch ({ onEffect, search = 'searchKey', getList, params }: any) {
  const searchField = useField('search')
  const searchFieldValue = searchField[0].value
  const debouncedValue = useDebounce(searchFieldValue)

  useDeepCompareEffect(() => {
    const { token, cancel } = getCancelToken()
    onEffect && onEffect()
    getList({ query: { [search]: searchFieldValue, start: 0, ...params }, cancelToken: token })
    return () => {
      cancel()
    }
  }, [debouncedValue, params])

  return (
    <TextField
      data-cy="searchVariant"
      name="search"
      placeholder="Search"
      size="small"
      variant="outlined"
      autoFocus={true}
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <Tooltip title="Search">
            <IconButton
              edge="start"
              size="medium"
              sx={{ margin: 0, padding: '5px' }}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        )
      }}
    />
  )
}

export default InfinityScrollSearch
