import React from 'react'
import { path } from 'ramda'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'

import ClientField from './ClientField'
import WarehouseGroupField from './WarehouseGroupField'
import TerritoryField from './TerritoryField'
import OrderStatusField from './OrderStatusField'
import PriceLevelField from './PriceLevelField'
import CurrencyField from './CurrencyField'

const getFieldByType = (fieldType: string, lookUpType:string, rest: any) => {
  if (fieldType === 'LOOKUP' && lookUpType === 'CONTACT') {
    return <ClientField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'WAREHOUSE_GROUP') {
    return <WarehouseGroupField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'TERRITORY') {
    return <TerritoryField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'ORDER_STATUS') {
    return <OrderStatusField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'PRICE_LEVEL') {
    return <PriceLevelField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'CURRENCY') {
    return <CurrencyField {...rest} />
  }
  if (fieldType === 'DATE_TIME_PICKER') {
    return (
      <DateField
        label="Дата"
        {...rest}
      />
    )
  }
  if (fieldType === 'NUMBER_BOX') {
    return (
      <TextField
        label="Ценить"
        type="number"
        {...rest}
      />
    )
  }

  return (
    <TextField
      label="Значение поля"
      {...rest}
    />
  )
}

export const FilterComparatorValueField = (props: any) => {
  const { item, ...rest } = props
  const fieldType = path(['code', 'fieldType'], item)
  const lookUpType = path(['code', 'lookUpType'], item)
  return getFieldByType(fieldType, lookUpType, rest)
}
