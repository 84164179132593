import { prop, propOr } from 'ramda'
import { UserProps } from 'context/user/Provider'

export const territorySerialize = (data: any, user?: UserProps) => {
  const newClients = propOr([], 'clients', data) as any
  const clients = newClients.map((client: any) => prop('id', client))
  return { ...data, companyId: user?.userCompanyId, clients }
}

export const detailSerializer = (data: any) => {
  const clients = propOr([], 'clients', data) as any
  return { ...data, clients }
}
