import React, { useCallback } from 'react'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'
import { useSnackbar } from 'context/snackbar'

import ColumnUpdateForm from './components/TabUpdateForm'
import { tabDetailSerializer, tabSerializer } from './serializers'
import { getByIdTab, updateTab } from './api'

interface Props {
  readonly id:number
  readonly onBack:() => void
}

const TabsUpdate = ({ id, onBack }:Props) => {
  const snackbar = useSnackbar()
  const columnDetail = useGetDetail(getByIdTab, { params: { id } })
  const columnUpdate = usePut(updateTab)

  const onUpdate = useCallback((values:any) => {
    columnUpdate.putData({ data:tabSerializer(values) })
      .then(() => onBack())
      .then(() => snackbar({ message: 'Вкладка успешно обновлён' }))
  }, [])

  return (
    <>
      {!columnDetail.loading && (
        <ColumnUpdateForm
          onBack={onBack}
          initialValues={tabDetailSerializer(columnDetail.result)}
          onSubmit={onUpdate}
        />)}
    </>
  )
}

export default TabsUpdate
