import * as API from 'constants/Api'

import React from 'react'
import MultiSelectSearchField from 'components/form/MultiSelectSearchField'
import { prop } from 'ramda'

const getOptionValue = (option: any) => {
  if (option) {
    const role = prop('role', option)
    const name = prop('displayName', option)

    return { name, role }
  }

  return null
}

const getOptionSelected = (option: any, value: any) => {
  return option.role === value.role
}

function RoleField (props: any) {
  return (
    <MultiSelectSearchField
      label="Роль сотрудника"
      name="roles"
      api={API.AuthRoles}
      params={{ query: { language:'ru' } }}
      searchText=""
      getOptionValue={getOptionValue}
      getOptionSelected={getOptionSelected}
      {...props}
    />
  )
}

export default RoleField
