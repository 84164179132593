import React from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid, IconButton,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import Delete from '@mui/icons-material/Delete'
import TextField from 'components/form/TextField'
import CardHeader from 'components/card/CardHeader'
import { FieldArray, useField } from 'formik'
import { useTranslationI18n } from 'i18n/I18nContext'
import SkeletonList from 'components/skeleton/SkeletonList'
import { path, pathOr, prop, propEq, propOr } from 'ramda'
import useDialog from 'hooks/useDialog'
import { formatMoney } from 'utils/formatMoney'
import { makeStyles } from '@mui/styles'

import TransferAddDialog from '../TransferAddDialog'

const useStyles = makeStyles({
  tableRoot:{
    borderRadius:'7px',
    border:'1px solid #E9E9E9'
  },
  tableHeader: {
    background: '#F1F1F1',
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset'
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      boxShadow: '2px 2px 4px rgba(167, 172, 176, 0.1)',
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly loading?: boolean
  readonly productList: any
}

export function LineItems ({ loading, productList }: Props) {
  const classes = useStyles()
  const variantAddDialogue = useDialog()
  const { t } = useTranslationI18n()

  const warehouseField = useField('fromWarehouseId')
  const lineItemsField = useField('lineItems')
  const lineItems = lineItemsField[0].value || []
  const warehouseFieldValue = warehouseField[0].value?.id

  const lineItemsChange = lineItemsField[2].setValue

  const handleVariantsAdd = (variants: any) => {
    const newVariants = variants.map((variant: any) => {
      const variantId = path(['product', 'id'], variant)
      const isDuplicate = lineItems.find((item: any) => path(['product', 'id'], item) === variantId)
      if (!isDuplicate && variantId) {
        const stockId = prop('id', variant)
        const productId = pathOr('', ['product', 'id'], variant)
        const quantity = prop('quantityOnHand', variant)
        const name = pathOr('', ['product', 'name'], variant)
        const cost = pathOr(0, ['product', 'costPrice'], variant)
        return {
          stockId,
          product: { id:productId, name },
          productId,
          quantity,
          cost
        }
      } else return null
    }).filter((value: any) => Boolean(value))

    const otherLineItems = lineItems.filter((lineItem: any) => {
      return variants.find(propEq(path(['product', 'id']), prop('productId', lineItem)))
    })

    lineItemsChange([...otherLineItems, ...newVariants])
    productList.reset()
    variantAddDialogue.handleClose()
  }

  return (
    <Card>
      <CardHeader title="Продукты" />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={10}>
            <TextField name="browse" />
          </Grid>
          <Grid item={true} lg={2}>
            <Button
              fullWidth={true}
              onClick={() => variantAddDialogue.handleOpen()}
              disabled={!warehouseFieldValue}
            >
              Добавить продукт
            </Button>
          </Grid>
          {variantAddDialogue.open && (
            <TransferAddDialog
              open={variantAddDialogue.open}
              onClose={variantAddDialogue.handleClose}
              onAddVariants={handleVariantsAdd}
              productList={productList}
              warehouseId={warehouseFieldValue}
            />
          )}
          <FieldArray name="lineItems">
            {(fields: any) => {
              const renderLineItems = Boolean(!loading && lineItems.length)
              return (
                <Grid item={true} lg={12}>
                  <TableContainer className={classes.tableRoot}>
                    <Table size="small">
                      <TableHead className={classes.tableHeader}>
                        <TableRow>
                          <TableCell>{t('Товар')}</TableCell>
                          <TableCell width="20%">{t('Количество')}</TableCell>
                          <TableCell width="20%">{t('Стоимость')}</TableCell>
                          <TableCell width="20%">{t('Итого')}</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBody}>
                        {loading && <SkeletonList columnCount={7} />}
                        {renderLineItems && lineItems.map((item: any, index: number) => {
                          const productName = pathOr('', ['product', 'name'], item)
                          const quantity = propOr(0, 'quantity', item) as number
                          const cost:number = propOr(0, 'cost', item)
                          const totalPrice = cost * quantity
                          return (
                            <TableRow>
                              <TableCell>{productName}</TableCell>
                              <TableCell>
                                <TextField name={`lineItems[${index}].quantity`} type="number" />
                              </TableCell>
                              <TableCell>
                                {formatMoney(cost)}
                              </TableCell>
                              <TableCell>
                                {formatMoney(totalPrice)}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton onClick={() => fields.remove(index)} size="large">
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            }}
          </FieldArray>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default LineItems
