import * as ROUTES from 'constants/Routes'
import * as API from 'constants/Api'

import * as Yup from 'yup'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { prop } from 'ramda'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'
import useScrollList from 'hooks/useScrollList'
import { getRequest } from 'api/BaseApi'
import useAllSearchParams from 'utils/useAllSearchParams'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'
import { useUser } from 'context/user'

import {
  bookSaleOrder,
  createSaleOrder,
  getSaleOrderDetail,
  getProductList,
  createContact, saleOrderCheckBonus
} from '../api'
import SaleOrderCreateForm from '../components/SaleOrderCreateForm'
import {
  SaleOrderCreateSerializer,
  SaleOrderGetInitialValues,
  orderAgentCreateSerialize,
  checkBonusSerializer
} from '../serializers'

const validationSchema = Yup.object({
  territoryId:Yup.object({ id:Yup.number(), name:Yup.string() }).required('Требуются территории'),
  warehouseGroupId:Yup.object().required('Требуются складская группа'),
  driverId:Yup.object().required('Требуется водитель'),
  priceLevelId:Yup.object().required('Требуются ценовая политика'),
  contactId:Yup.object().required('Требуются клиент'),
  agentId:Yup.object().required('Требуются агент'),
  items:Yup.array().min(1, 'Требуются продукт').required('Требуются продукт')
})

const initialValues = {
  territoryId:null,
  warehouseGroupId:null,
  priceLevelId:null,
  items:[],
  attachments:[],
  currency:null,
  agentId:'',
  driverId:null,
  address:{
    countryId:'',
    regionId:'',
    addressLine1:'',
    addressLine2:'',
    landmark:'',
    lat:'',
    lon:''
  }
}

function SaleOrderCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const searchParams = useAllSearchParams()
  const { user } = useUser()
  const copyId = prop('copyId', searchParams)
  const saleOrderCreate = usePost(createSaleOrder)
  const saleOrderBook = usePut(bookSaleOrder)
  const agentCreate = usePost(createContact)
  const saleOrderDetail = copyId ? useGetDetail(getSaleOrderDetail, { params: { id: copyId } }) : null as any
  const productList = useScrollList(getProductList)
  const salesOrderBonus = usePost(saleOrderCheckBonus)
  const [bonuses, setBonuses] = useState([])

  const handleSubmit = useCallback((data: any) => {
    return saleOrderCreate.postData({ data: SaleOrderCreateSerializer(data) })
      .then(() => snackbar({ message: 'Заказ успешно создан' }))
      .then(() => navigate(ROUTES.SALE_ORDER_LIST))
  }, [])

  const handleComplete = useCallback((data: any) => {
    saleOrderCreate.postData({ data: SaleOrderCreateSerializer(data) })
      .then((response:any) => saleOrderBook.putData({ data: { id:response.id } })
        .then(() => navigate(ROUTES.SALE_ORDER_LIST))
        .then(() => snackbar({ message: 'Завершенный приход успешно создан' })))
  }, [])

  const onGetPriceLevel = useCallback((id: any, products: any) => {
    return getRequest(API.PriceLevelByProductId, { params: { id }, query: { products } })
  }, [])

  const initialCopyValues = useMemo(() => {
    if (copyId) {
      return SaleOrderGetInitialValues(saleOrderDetail.result)
    }
  }, [saleOrderDetail, copyId])

  const getContactAddress = useCallback((contact: any) => {
    const contactId = prop('id', contact)
    return getRequest(API.SupplierGetById, { params: { id: contactId } })
  }, [])

  const handleClientCreate = useCallback((values: any) => {
    return agentCreate.postData({ data: orderAgentCreateSerialize(values) })
      .then(() => snackbar({ message: 'Контрагент успешно создан' }))
  }, [])

  const onCheckBonuses = useCallback((values:any) => {
    salesOrderBonus.postData({ data: checkBonusSerializer(values) })
      .then((response:any) => setBonuses(response))
  }, [])

  return (
    <AppLayout>
      {copyId && !saleOrderDetail.loading && (
        <SaleOrderCreateForm
          onSave={handleSubmit}
          onSubmit={handleSubmit}
          onComplete={handleComplete}
          initialValues={initialCopyValues}
          productList={productList}
          handleGetPriceLevel={onGetPriceLevel}
          getContactAddress={getContactAddress}
          onClientCreate={handleClientCreate}
        />
      )}
      {!copyId && (
        <SaleOrderCreateForm
          bonuses={bonuses}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          onCheckBonuses={onCheckBonuses}
          onSave={handleSubmit}
          onComplete={handleComplete}
          initialValues={{ ...initialValues, agentId: { id:user.profileId, name:user.fullName } }}
          productList={productList}
          handleGetPriceLevel={onGetPriceLevel}
          getContactAddress={getContactAddress}
          onClientCreate={handleClientCreate}
        />
      )}
      {copyId && saleOrderDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default SaleOrderCreateContainer
