import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useField } from 'formik'
import { path } from 'ramda'
import { makeStyles } from '@mui/styles'
import { useEffect } from 'react'

const useStyles = makeStyles({
  checkbox:{
    '& .MuiFormControlLabel-label':{
      color: '#202020',
      fontSize:'12px',
      fontWeight:'500'
    }
  }
})

export default function CheckboxField ({ name, label, ...props }: any) {
  const field = useField(name)
  const fieldValue = path([0, 'value'], field)
  const fieldSetValue = path([2, 'setValue'], field)
  const classes = useStyles()

  useEffect(() => {
    if (!fieldValue) {
      fieldSetValue(false)
    }
    if (props.checked === false) {
      fieldSetValue(false)
    }
  }, [fieldValue, props.checked])

  return (
    <FormControlLabel
      className={classes.checkbox}
      control={
        <Checkbox
          checked={fieldValue || props.checked}
          onChange={() => fieldSetValue(!fieldValue)}
          color={'success'}
        />
      }
      label={label}
      {...props}
    />
  )
}
