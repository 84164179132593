import * as API from 'constants/Api'

import React from 'react'
import MultiSelectSearchField from 'components/form/MultiSelectSearchField'
import { prop, propOr } from 'ramda'
import useDeepCompareEffect from 'hooks/useDeepCompareEffect'
import { request } from 'api/BaseApi'
import { useField } from 'formik'

const getOptionValue = (option: any) => {
  if (option) {
    const role = prop('role', option)
    const name = prop('displayName', option)

    return { name, role }
  }

  return null
}

const getOptionSelected = (option: any, value: any) => {
  return option.role === value.role
}

function EmployeeRoleField (props: any) {
  const { form } = props
  const [, meta, helpers] = useField(props.name)

  useDeepCompareEffect(() => {
    if (meta.initialValue) {
      request(API.AuthRoles, { query: { language:'ru' } }).then((response) => {
        const content = propOr([], 'content', response) as any
        const list = propOr([], 'list', response) as any
        const responseList = Array.isArray(response) ? response : []
        const results = [...content, ...list, ...responseList]
        const foundItem = results.filter(item => meta.initialValue.includes(item.role))
        const optionItem = foundItem.map((item:any) => getOptionValue(item))
        helpers.setValue(optionItem)
        form.setStatus(true)
      })
    }
  }, [])

  return (
    <MultiSelectSearchField
      label="Роль сотрудника"
      name="roles"
      api={API.AuthRoles}
      params={{ query: { language:'ru' } }}
      searchText=""
      getOptionValue={getOptionValue}
      getOptionSelected={getOptionSelected}
      {...props}
    />
  )
}

export default EmployeeRoleField
