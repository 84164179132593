import { path, prop } from 'ramda'
import { toPayloadDate } from 'utils/dates'
import { toNumberFormat } from 'utils/formatMoney'

export const supplierPaymentsCreateSerializer = (values:any) => {
  const contactId = path(['contactId', 'id'], values)
  const accountId = path(['accountId', 'id'], values)
  const currency = path(['currency', 'code'], values)
  const amount = toNumberFormat(prop('amount', values))
  const date = toPayloadDate(prop('date', values))
  return { ...values, contactId, amount, accountId, currency, date }
}

export const supplierPaymentsDetailSerializer = (values:any) => {
  const contactId = prop('contact', values)
  const accountId = prop('account', values)
  const currency = prop('currency', values)
  const date = toPayloadDate(prop('date', values))
  return { ...values, contactId, accountId, currency, date }
}
