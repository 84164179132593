import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useNavigate } from 'react-router'
import { usePut } from 'hooks/usePut'
import { useGetList } from 'hooks/useGetList'
import { propOr } from 'ramda'

import { approveManualEntry, deleteManualEntry, getManualEntryList } from '../api'
import ManualEntryRowMenu from '../components/ManualEntryRowMenu'
import ManualEntryStatusRow from '../components/ManualEntryStatusRow'
import MoreActionButtons from '../components/MoreRowMenu'
import ManualEntryExcelImport from '../components/ManualEntryExcelExport'

const columns = [
  {
    headerName: 'Номер заказа',
    field: 'number'
  },
  {
    headerName: 'Дата создания',
    field: 'createdDate'
  },
  {
    headerName: 'Дата последнего изменения',
    field: 'modifiedDate'
  },
  {
    headerName: 'Привязанный номер',
    field: 'referenceNumber'
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell:(item:any) => <ManualEntryStatusRow status={item.status} />
  },
]

function ManualEntryListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const manualEntryList = useGetList(getManualEntryList)
  const manualEntryDelete = useDelete(deleteManualEntry)
  const manualEntryApprove = usePut(approveManualEntry)

  const handleDeleteSupplier = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить корректировку ${values.poNumber}`

    onConfirm({ message })
      .agree(() => {
        manualEntryDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Успешно удалён' }))
          .then(() => manualEntryList.getList())
      })
      .disagree()
  }, [])

  const handleApprove = useCallback((id:number) => {
    manualEntryApprove.putData({ params: { id } })
      .then(() => snackbar({ message: 'Корректировка успешно одобрена' }))
      .then(() => manualEntryList.getList())
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = propOr('', 'search', value)
    manualEntryList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Корректировки"
        onAddClick={() => navigate(ROUTES.FINANCE_MANUAL_ENTRY_CREATE)}
        dataRequest={manualEntryList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        exportNode={(
          <ManualEntryExcelImport
            list={manualEntryList}
          />
        )}
        actions={(row) => (
          <ManualEntryRowMenu
            item={row}
            onApprove={handleApprove}
            onDelete={handleDeleteSupplier}
          />
        )}
        moreActions={(actions) => (
          <MoreActionButtons
            onOpen={actions.onExportClick} />
        )}
      />
    </AppLayout>
  )
}

export default ManualEntryListContainer
