import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { BaseTextFieldProps } from '@mui/material'
import MUITextField from '@mui/material/TextField'
import { useTranslationI18n } from 'i18n/I18nContext'

interface Props extends BaseTextFieldProps {
  readonly inputVariant?: string
  readonly InputProps?: any
}

function TextField (props: Props) {
  const { t } = useTranslationI18n()
  const [field, meta, helpers] = useField(props.name || '')
  const translatedPlaceholder = props.placeholder ? t(props.placeholder) : ''
  const [labelShrink, setLabelShrink] = useState(false)
  const CHARACTER_LIMIT = 1000
  const error = meta.touched && !!meta.error
  const valueLength = field.value?.length || 0
  const helperTextLimit = props.multiline ? `${valueLength}/${CHARACTER_LIMIT}` : ''
  const isNumber = props.type === 'number' ? { inputProps: { min:0 } } : props.InputProps || {}
  const isNumberKey = props.type === 'number' ? (event: { key: string; preventDefault: () => void }) => {
    if (event?.key === '-' || event?.key === '+') {
      event.preventDefault()
    }
  } : undefined
  const isHasValue = props.value !== undefined && (props.value === 0 || Boolean(props.value))
  useEffect(() => {
    if (meta.initialValue) {
      helpers.setValue(meta.initialValue)
    }
  }, [])

  useEffect(() => {
    if ((field.value && !labelShrink) || isHasValue) {
      setLabelShrink(true) // Trigger label shrink animation when field value is set
    }
  }, [field.value, labelShrink, isHasValue])

  const handleFocus = () => {
    setLabelShrink(true) // Set label shrink when field is focused
  }

  const handleBlur = () => {
    if (!field.value) {
      setLabelShrink(false) // Reset label shrink when field loses focus and has no value
    }
  }

  const borderColor = error || meta.initialError ? 'error.main' : 'default' // Use MUI theme colors
  return (
    <MUITextField
      {...field}
      {...props}
      variant="outlined"
      maxRows={props.maxRows || props.minRows}
      inputProps={{ maxLength: CHARACTER_LIMIT }}
      InputProps={isNumber}
      error={error}
      size="small"
      helperText={(meta.touched && meta.error) || helperTextLimit || meta.initialError}
      onKeyDown={isNumberKey}
      onFocus={handleFocus}
      onBlur={handleBlur}
      // @ts-ignore
      onWheel={(e) => e.target.blur()}
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline':{
          borderColor:borderColor
        }
      }}
      placeholder={translatedPlaceholder}
      InputLabelProps={{
        shrink: labelShrink, // Pass the shrink state to control label animation
      }}
    />
  )
}

export default TextField
