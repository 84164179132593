import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getWarehouseGroupList = (options?: Options) => {
  return getRequest<any>(API.WarehouseGroupList, options)
}

export const getWarehouseGroupDetail = (options?: Options) => {
  return getRequest<any>(API.WarehouseGroupDetail, options)
}

export const createWarehouseGroup = (options?: Options) => {
  return postRequest<any>(API.WarehouseGroupCreate, options)
}

export const updateWarehouseGroup = (options?: Options) => {
  return putRequest<any>(API.WarehouseGroupUpdate, options)
}

export const deleteWarehouseGroup = (options?: Options) => {
  return deleteRequest<any>(API.WarehouseGroupDelete, options)
}
