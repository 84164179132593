import * as ROUTES from 'constants/Routes'

import React from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { pathParams } from 'path-params'
import { useNavigate } from 'react-router'

interface Props {
  readonly item: any
  readonly onDelete: (item: any) => void
}

function AdjustmentRowMenu ({ item, onDelete }: Props) {
  const navigate = useNavigate()
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null)

  const anchorIsOpen = Boolean(optionsAnchorEl)

  const handleOpenOptions = (event: any) => {
    setOptionsAnchorEl(event.currentTarget)
  }

  const handleCloseOptions = () => {
    setOptionsAnchorEl(null)
  }

  const updatedURL = pathParams(ROUTES.WAREHOUSE_ADJUSTMENT_UPDATE, { id: item.id })

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={anchorIsOpen ? 'long-menu' : undefined}
        aria-expanded={anchorIsOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenOptions}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="table-item-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={optionsAnchorEl}
        open={anchorIsOpen}
        onClose={handleCloseOptions}
      >
        <MenuItem
          onClick={() => {
            navigate(updatedURL)
            handleCloseOptions()
          }}
        >
          Просмотр
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete(item)
            handleCloseOptions()
          }}
        >
          Удалить
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default AdjustmentRowMenu
