import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { path, propOr, trim } from 'ramda'
import { useNavigate } from 'react-router-dom'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useDelete } from 'hooks/useDelete'

import { deletePreOrder, getPreOrderList } from '../api'
import PreOrderStatusRow from '../components/PreOrderStatusRow'
import PreOrderRowMenu from '../components/PreOrderRowMenu'

const columns = [
  {
    headerName: 'Название предзаказа',
    field: 'number',
  },
  {
    headerName: 'Склад',
    field: 'warehouseGroup',
    renderCell:(item:any) => path(['warehouseGroup', 'name'], item)
  },
  {
    headerName: 'Территории',
    field: 'territories',
    renderCell:(item:any) => path(['territory', 'name'], item)
  },
  {
    headerName: 'Клиент',
    field: 'contact',
    renderCell:(item:any) => path(['contact', 'name'], item)
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell:(item:any) => <PreOrderStatusRow status={item.status} />
  },
]

function PreOrderListContainer () {
  const snackbar = useSnackbar()
  const onConfirm = useConfirm()
  const navigate = useNavigate()
  const preOrderList = useGetList(getPreOrderList)
  const preOrderDelete = useDelete(deletePreOrder)

  const onDelete = useCallback((values: any) => {
    const message = `Вы уверены, что хотите удалить пред-заказ ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        preOrderDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Пред-заказ успешно удалён' }))
          .then(() => preOrderList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    preOrderList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Пред-заказ"
        onAddClick={() => navigate(ROUTES.SALE_ORDER_PRE_ORDER_CREATE)}
        dataRequest={preOrderList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        searchPlaceholder="Поиск по названию или ID"
        actions={(row) => (
          <PreOrderRowMenu
            item={row}
            onDelete={onDelete}
          />
        )}
      />
    </AppLayout>
  )
}

export default PreOrderListContainer
