import * as ROUTES from 'constants/Routes'
import * as API from 'constants/Api'

import React, { useCallback } from 'react'
import Logo from 'assets/logo.svg'
import TextField from 'components/form/TextField'
import FlexBox from 'components/main/FlexBox'
import { Button, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { prop } from 'ramda'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslationI18n } from 'i18n/I18nContext'
import { AUTHORIZED_STATUS } from 'App'
import { usePost } from 'hooks/usePost'
import { useUser } from 'context/user'
import { getRequest } from 'api/BaseApi'
import { parseJwt } from 'utils/tokenSerializer'
import PasswordTextField from 'components/form/PasswordField'

import { signInContainerStyles } from './styles/SignInContainerStyles'

import { AuthGetResponse, signInApi, SignInResponse } from '../api'

interface SignInFormProps {
  readonly email: string
  readonly password: string
}

const validationSchema = Yup.object({
  email: Yup.string().required('Обязательный'),
  password: Yup.string().required('Обязательный'),
})

const initialValues = {
  email: '',
  password: ''
}

interface Props {
  readonly setIsAuthorized: (value: AUTHORIZED_STATUS) => void
}

function SignInContainer ({ setIsAuthorized }: Props) {
  const { t } = useTranslationI18n()
  const { setUser } = useUser()
  const classes = signInContainerStyles()
  const navigate = useNavigate()
  const signIn = usePost(signInApi)
  const currentYear = new Date().getFullYear()
  const handleSubmit = useCallback((values: SignInFormProps) => {
    signIn.postData({ data: values }).then((response: SignInResponse) => {
      const accessToken = prop('accessToken', response)
      const accessDetail = parseJwt(accessToken)
      const id = prop('userId', accessDetail)
      const companyId = prop('userCompanyId', accessDetail)
      localStorage.setItem('antex-token', accessToken)
      getRequest(API.EmployeeGetWithAuthId,
        { headers:{ Authorization:`'Bearer' + ${accessToken}` }, params: { id, companyId } })
        .then((response) => {
          const authResponse = response as AuthGetResponse
          getRequest(API.EmployeeDetail, { params: { id:authResponse.id } }).then((response:any) => {
            setUser(response)
          })
        })
      setIsAuthorized(AUTHORIZED_STATUS.YES)
    }).catch((err:any) => {
      if (err.data.error?.error_code === 999999) {
        navigate(ROUTES.AUTH_SELECT, { state:{ values } })
      }
    })
  }, [setIsAuthorized])

  return (
    <FlexBox justify="center" direction="column" className={classes.root}>
      <FlexBox
        justify="space-between"
        flex={true}
        gap={50}
        align="center"
        direction="column"
      >
        <img src={Logo} alt="logo" />
        <FlexBox className={classes.loginForm}>
          <FlexBox direction="column" gap={30} flex={true}>
            <FlexBox direction="column" gap={10}>
              <h1 className={classes.title}>{t('Login')}</h1>
              <p className={classes.titleBody}>{t('Manage your company in one click')}</p>
            </FlexBox>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={12}>
                    <TextField
                      name="email"
                      placeholder="Phone number or email"
                      autoComplete="username"
                      data-cy="email"
                    />
                  </Grid>
                  <Grid item={true} lg={12}>
                    <PasswordTextField
                      autoComplete="current-password"
                      name="password"
                      placeholder="Password"
                      data-cy="password"
                    />
                  </Grid>
                  <Grid item={true} lg={12} sx={{ textAlign: 'center' }}>
                    <Link
                      to={ROUTES.FORGOT_PASSWORD}
                      className={classes.forgotPassword}
                    >
                      {t('Forgot password?')}
                    </Link>
                  </Grid>
                  <Grid item={true} lg={12}>
                    <Button fullWidth={true} type="submit" disabled={signIn.loading} data-cy="login">
                      {t('Login')}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </FlexBox>
        </FlexBox>
        <FlexBox className={classes.loginFooter}>
          <p>© Antex 2021-{currentYear}</p>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default SignInContainer
