import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useField } from 'formik'
import { makeStyles } from '@mui/styles'
const defaultGetOptionLabel = (option: any) => option?.name

const useStyles = makeStyles({
  autocomplete: {
    '& .MuiAutocomplete-input':{
      padding:'5px 10px 5px 12px!important'
    }
  },
  field: {
    '& .MuiInputBase-root': {
      borderRadius: '5px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#1557FF'
      },
      '& .MuiInputBase-input': {
        padding: '16px',
        fontSize: '13px',
        color: '#202020',
        background: 'white'
      },
      '& ::placeholder': {
        opacity: 1,
        color: '#7c7c7c',
        fontSize: '13px',
        fontWeight: '500'
      }
    }
  }
})

const defaultGetOptionSelected = (option: any, value: any) => {
  return option.value === value.value
}

function MultiSelectField (props: any) {
  const {
    name,
    size = 'small',
    disabled,
    options,
    required,
    getOptionLabel = defaultGetOptionLabel,
    renderOption,
    ListboxProps,
    onInputChange = () => null,
    onValueChange = () => null,
    getOptionSelected = defaultGetOptionSelected,
    ...rest
  } = props
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [input, meta, helpers] = useField(name)

  const handleChange = (event: any, value: any) => {
    onValueChange(value)
    onInputChange(value)
    helpers.setValue(value)
    helpers.setTouched(false)
  }

  return (
    <Autocomplete
      className={classes.autocomplete}
      open={open}
      disabled={disabled}
      size={size}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      multiple={true}
      disableCloseOnSelect={true}
      disableClearable={true}
      value={input.value || []}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      onInputChange={(event, value) => {
        onInputChange(value)
      }}
      options={options.length === 0 && !open ? input.value || [] : options}
      filterOptions={item => item}
      onChange={handleChange}
      ListboxProps={ListboxProps}
      renderInput={defaultProps => {
        return (
          <TextField
            required={required}
            className={classes.field}
            {...defaultProps}
            {...rest}
            error={!!(meta.touched && meta.error)}
            helperText={meta.touched && meta.error ? meta.error : ''}
            FormHelperTextProps={{ style: { position: 'absolute', top: '100%' } }}
          />
        )
      }}
    />
  )
}

export default MultiSelectField
