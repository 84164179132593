import React from 'react'
import { Card, CardContent, Divider, Grid } from '@mui/material'
import { propOr } from 'ramda'
import CardHeader from 'components/card/CardHeader'
import { formatMoney, stringFormatMoney, toNumberFormat } from 'utils/formatMoney'
import { useField } from 'formik'

interface Props {
  readonly items: any
}

function PaymentBulkSummery ({ items }: Props) {
  const [ input, , ] = useField('totalAmount')
  const summery = items.reduce((a: any, b: any) => {
    const amount = propOr(0, 'amount', b) as number
    return amount + a
  }, 0) || 0

  const overpayment = toNumberFormat(input.value) > summery ? toNumberFormat(input.value) - summery : null

  return (
    <Grid item={true} lg={12}>
      <Card>
        <CardHeader title="Суммарно" />
        <CardContent>
          <Grid container={true} spacing={1}>
            <Grid item={true} lg={12}>
              Суммарно: {stringFormatMoney(input.value)}
            </Grid>
            <Grid item={true} lg={12}>
              Сумма к оплате: {formatMoney(summery)}
            </Grid>
            {overpayment && (
              <Grid item={true} lg={12}>
                Переплата: {formatMoney(overpayment)}
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </Grid>
  )
}

export default PaymentBulkSummery
