import React from 'react'
import { useField } from 'formik'
import { Checkbox, FormControlLabel } from '@mui/material'

interface CustomCheckboxProps {
  readonly name: string
  readonly label: string
  readonly value: string
  readonly index:number
  readonly disabled:boolean
}

const CustomFieldCheckbox: React.FC<CustomCheckboxProps> = ({
  index,
  name,
  label,
  value,
  disabled
}) => {
  const [field, , helpers] = useField(name)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    helpers.setValue(isChecked ? { id:index, value } : '')
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          checked={field.value?.value === value}
          onChange={handleChange}
          color="primary"
        />
      }
      label={label}
    />
  )
}

export default CustomFieldCheckbox
