import React from 'react'
import DetailTable from 'components/table/DetailTable'
import { pathOr } from 'ramda'
import { Grid } from '@mui/material'
import withForm from 'components/form/withForm'
import TextField from 'components/form/TextField'

import StockProductStatusRow from './StockProductStatusRow'

const columns = [
  {
    headerName: 'Название склада',
    field: 'warehouse',
    renderCell: pathOr('', ['warehouse', 'name'])
  },
  {
    headerName: 'Тип',
    field: 'type',
    renderCell:(row:any) => <StockProductStatusRow row={row} />
  },
  {
    headerName: 'Количество',
    field: 'quantity',
    renderCell:(item:any) => item.type === 'IN' ? `+${item.quantity}` : item.type === 'OUT'
      ? `-${item.quantity}` : item.quantity
  },
  {
    headerName: 'Дата транзакции',
    field: 'transactionDate',
  },
  {
    headerName: 'Дата истечения',
    field: 'expireDate',
  },
  {
    headerName: 'Серийный номер',
    field: 'batchNumber',
  }
]

interface Props {
  readonly stockHistory: any
}

function ProductStockHistoryTable ({ stockHistory }: Props) {
  const list = pathOr([], ['result', 'items'], stockHistory)
  const beginningBalance = pathOr(0, ['result', 'beginningBalance'], stockHistory)
  const endingBalance = pathOr(0, ['result', 'endingBalance'], stockHistory)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} lg={12}>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={6}>
            <TextField
              label="Начальный баланс"
              name="beginningBalance"
              disabled={true}
              value={beginningBalance}
            />
          </Grid>
          <Grid item={true} lg={6}>
            <TextField
              label="Конечный баланс"
              name="endingBalance"
              disabled={true}
              value={endingBalance}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} lg={12}>
        <DetailTable
          list={list}
          loading={stockHistory.loading}
          listGet={stockHistory.getList}
          columns={columns}
        />
      </Grid>
    </Grid>
  )
}

export default withForm(ProductStockHistoryTable)
