import React from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import CardHeader from 'components/card/CardHeader'
import { prop } from 'ramda'

import { CustomValueField } from './CustomValueField'

interface Props {
  readonly customFields: any
}

export const AvailableCustomField = ({ customFields }:Props) => {
  return (
    <Card>
      <CardHeader title="Настраиваемые поля" />
      <CardContent>
        <Grid container={true} spacing={3}>
          {customFields.map((field: any) => {
            const aliasName = prop('name', field)
            const fieldType = prop('fieldType', field)
            const columnCode = prop('columnCode', field)
            const predefinedValues = prop('predefinedValues', field)
            return (
              <Grid item={true} lg={4}>
                <CustomValueField
                  label={aliasName}
                  name={`customFields.${columnCode}.value.value`}
                  type={fieldType}
                  values={predefinedValues}
                />
              </Grid>
            )
          })}
        </Grid>
      </CardContent>
    </Card>
  )
}
