import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import { prop, propOr } from 'ramda'
import { useField } from 'formik'
import { getRequest } from 'api/BaseApi'

import AutoSelectField from './AutoSelectField'

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('id', value)
    const name = propOr('', 'name', value)
    return { id, name }
  }

  return null
}

function DriverField ({ ...props }: any) {
  const field = useField(props.name)

  useEffect(() => {
    if (field[0].value) {
      getRequest(API.EmployeeDetail, { params: { id:field[0].value } })
        .then((response:any) => {
          const id = prop('profileId', response)
          const name = prop('fullName', response)
          field[2].setValue({ id, name })
        })
    }
  }, [])
  return (
    <AutoSelectField
      name="driverId"
      api={API.EmployeeSuggestionList}
      getOptionValue={getOptionValue}
      params={{ role:'ROLE_DRIVER' }}
      label="Водитель"
      {...props}
    />
  )
}

export default DriverField
