import { dissoc, omit, path, pathOr, prop, propOr } from 'ramda'
import { toPayloadDate } from 'utils/dates'
import { toNumberFormat } from 'utils/formatMoney'

export const SaleOrderCreateSerializer = (data: any) => {
  const warehouseGroupId = path(['warehouseGroupId', 'value'], data)
  const priceLevelId = path(['priceLevelId', 'value'], data)
  const contactId = path(['contactId', 'value'], data)
  const driverId = path(['driverId', 'id'], data)
  const agentId = path(['agentId', 'id'], data)
  const visitId = path(['visitId', 'id'], data)
  const territoryId = path(['territoryId', 'value'], data)
  const currency = path(['currency', 'code'], data)
  const orderDate = prop('orderDate', data)
  const visitDate = prop('visitDate', data)
  const getBonusId = prop('bonusId', data)
  const bonusId = prop(0, getBonusId)
  const oldItems = propOr([], 'items', data) as any
  const items = oldItems.map((item: any) => ({
    ...item,
    salesPrice:toNumberFormat(prop('salesPrice', item)),
    taxId: path(['taxId', 'value'], item),
    discountId:path(['discountId', 'id'], item)
  }))
  const addressId = pathOr('', ['addressId', 'id'], data)
  const result = omit(['address'], data)
  return {
    ...result,
    bonusId,
    visitId,
    driverId,
    agentId,
    warehouseGroupId,
    orderDate: toPayloadDate(orderDate),
    visitDate: toPayloadDate(visitDate),
    items,
    contactId,
    priceLevelId,
    territoryId,
    currency,
    addressId
  }
}

export const checkBonusSerializer = (data: any) => {
  const warehouseGroupId = path(['warehouseGroupId', 'value'], data)
  const priceLevelId = path(['priceLevelId', 'value'], data)
  const contactId = path(['contactId', 'value'], data)
  const driverId = path(['driverId', 'id'], data)
  const agentId = path(['agentId', 'id'], data)
  const visitId = path(['visitId', 'id'], data)
  const territoryId = path(['territoryId', 'value'], data)
  const currency = path(['currency', 'code'], data)
  const orderDate = prop('orderDate', data)
  const visitDate = prop('visitDate', data)
  const oldItems = propOr([], 'items', data) as any
  const items = oldItems.map((item: any) => ({
    ...item,
    salesPrice:toNumberFormat(prop('salesPrice', item)),
    taxId: path(['taxId', 'value'], item),
    discountId:path(['discountId', 'id'], item)
  }))
  const addressId = pathOr('', ['addressId', 'id'], data)
  return {
    ...data,
    visitId,
    driverId,
    agentId,
    warehouseGroupId,
    orderDate: toPayloadDate(orderDate),
    visitDate: toPayloadDate(visitDate),
    items,
    contactId,
    priceLevelId,
    territoryId,
    currency,
    addressId
  }
}

export const SaleOrderGetInitialValues = (data: any) => {
  const warehouseGroupId = prop('warehouseGroup', data)
  const agentId = prop('agent', data)
  const driverId = prop('driver', data)
  const visitId = prop('visit', data)
  const contactId = prop('contact', data)
  const items = propOr([], 'items', data) as any
  const territoryId = prop('territory', data)
  const priceLevelId = prop('priceLevel', data)
  const newItems = items.map((item: any) => ({
    productId: path(['product', 'id'], item),
    discountId:path(['discount', 'id'], item),
    ...item
  }))
  const addressId = prop('address', data)
  return {
    ...data,
    visitId,
    driverId,
    agentId,
    items: newItems,
    warehouseGroupId,
    contactId,
    territoryId,
    priceLevelId,
    addressId
  }
}

export const SaleOrderUpdateSerializer = (data:any) => {
  const fieldsToRemove = [
    'contact',
    'priceLevel',
    'territory',
    'warehouseGroup',
    'address',
    'agent',
    'driver',
    'shippingMethod'
  ]
  const bonusId = path(['bonusId', 'id'], data)
  const result = fieldsToRemove.reduce((acc, field) => dissoc(field, acc), data)
  const visitId = path(['visitId', 'id'], data)
  const agentId = path(['agentId', 'id'], result)
  const driverId = path(['driverId', 'id'], result)
  const warehouseGroupId = path(['warehouseGroupId', 'id'], result)
  const priceLevelId = path(['priceLevelId', 'id'], result)
  const contactId = path(['contactId', 'id'], result)
  const territoryId = path(['territoryId', 'id'], result)
  const currency = path(['currency', 'code'], result)
  const orderDate = prop('orderDate', result)
  const visitDate = prop('visitDate', result)
  const oldItems = propOr([], 'items', result) as any
  const items = oldItems.map((item: any) => {
    const resultItem = omit(['product'], item)
    return ({
      ...resultItem,
      salesPrice: toNumberFormat(prop('salesPrice', item)),
      taxId: path(['taxId', 'id'], item),
      discountId: path(['discountId', 'id'], item)
    })
  })
  const addressId = pathOr('', ['addressId', 'id'], data)
  return {
    ...result,
    visitId,
    driverId,
    agentId,
    bonusId,
    warehouseGroupId,
    orderDate: toPayloadDate(orderDate),
    visitDate: toPayloadDate(visitDate),
    items,
    currency,
    contactId,
    priceLevelId,
    territoryId,
    addressId
  }
}

export const orderAgentCreateSerialize = (data: any) => {
  const newAddresses = prop('addresses', data)
  const currency = path(['currency', 'code'], data)
  const newTerritories = prop('territories', data) as any
  const addresses = newAddresses && newAddresses.map((address: any) => {
    const countryId = prop('value', address.countryId)
    const regionId = prop('value', address.regionId)
    return { ...address, regionId, countryId }
  })
  const territories = newTerritories && newTerritories.map((ids: any) => prop('value', ids))
  return { ...data, territories, addresses, currency }
}

export const orderFilterSerializer = (values:any) => {
  const result = omit(['availableColumns'], values)
  const getUsers = prop('users', result)
  const users = getUsers && getUsers.map((user:any) => user.id)
  const getRoles = prop('roles', result)
  const roles = getRoles && getRoles.map((role:any) => role.role)
  return { ...result, users, roles }
}

export const selectedRowSerializer = (data: any) => {
  const ids = data && data.map((row: any) => prop('id', row))
  return [...ids]
}

export const onGetTab = (values:any) => {
  if (Array.isArray(values)) {
    return values && values.map((tab: any) => {
      const id = prop('id', tab)
      const name = prop('name', tab)
      return { id, title:name, status: id, count:name.toLowerCase() }
    })
  }
}

export function transformList (list:any): { [key: string]: number } {
  const transformedObject: { [key: string]: number } = {}

  if (Array.isArray(list)) {
    list.forEach((item) => {
      const lowercaseName: string = item.name.toLowerCase()
      transformedObject[lowercaseName] = item.records
    })
  }

  return transformedObject
}
