import { omit, prop } from 'ramda'

export const columnsSettingCreateSerializer = (values:any) => {
  const result = omit(['availableColumns'], values)
  const getUsers = prop('users', result)
  const users = getUsers && getUsers.map((user:any) => user.id)
  const getRoles = prop('roles', result)
  const roles = getRoles && getRoles.map((role:any) => role.role)
  return { ...result, users, roles }
}
