import * as API from 'constants/Api'
import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import MultiSelectSearchField from 'components/form/MultiSelectSearchField'
import { prop } from 'ramda'
import useDialog from 'hooks/useDialog'
import { useGetList } from 'hooks/useGetList'
import { useField } from 'formik'

import MultipleSupplierAddDialog from './MultipleSupplierAddDialog'

import { getSupplierSuggestions } from '../../api'

const getOptionValue = (value:any) => {
  if (value) {
    const id = prop('id', value)
    const name = prop('name', value)

    return { value: id, name, id }
  }

  return null
}

function MultipleWarehouseField (props: any) {
  const tableDialog = useDialog()
  const tableRequest = useGetList(getSupplierSuggestions)
  const [field, , helpers] = useField('supplierIds')
  const handleVariantsAdd = useCallback((packages: any) => {
    const lineItemPackageIds = new Set(field.value.map((item: any) => prop('id', item)))

    const uniquePackages = packages.filter((item: any) => {
      const packageId = prop('id', item)
      return !lineItemPackageIds.has(packageId)
    })

    const otherLineItems = field.value.filter((lineItem: any) => {
      return packages.find((item: any) => prop('id', item) === prop('id', lineItem))
    })

    helpers.setValue([...otherLineItems, ...uniquePackages])
    tableDialog.handleClose()
  }, [])
  return (
    <>
      <MultiSelectSearchField
        name="supplierIds"
        onAddRoute={ROUTES.FINANCE_AGENT_CREATE}
        api={API.SupplierSuggestions}
        onOpenDialog={tableDialog.handleOpen}
        getOptionValue={getOptionValue}
        label={props.label}
        {...props}
      />
      {tableDialog.open && (
        <MultipleSupplierAddDialog
          name="supplierIds"
          packageList={tableRequest}
          onAddVariants={handleVariantsAdd}
          open={tableDialog.open}
          onClose={tableDialog.handleClose}
        />
      )}
    </>
  )
}

export default MultipleWarehouseField
