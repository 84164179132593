import React, { useEffect, useRef, useState } from 'react'
import { prop, propOr } from 'ramda'
import L from 'leaflet'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import FlexBox from 'components/main/FlexBox'
import Typography from '@mui/material/Typography'

import AutoFocusMap from './AutoFocusMap'

L.Marker.prototype.options.icon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
})

const startIcon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [2, -40],
})

const highlightedIcon = L.icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [2, -40],
})

interface Props {
  selectedLotLan: any
  contactListTracking: { list: any[] }
}

const MapContent = ({ contactListTracking, selectedLotLan }: Props) => {
  return (
    <MapContainer
      className="Map"
      center={{ lat: 41.3123363, lng: 69.2787079 }}
      zoom={15}
      scrollWheelZoom={false}
      style={{ width: '100%', height: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MapMarkers contactListTracking={contactListTracking} selectedLotLan={selectedLotLan} />
      <AutoFocusMap contactList={contactListTracking.list} />
    </MapContainer>
  )
}

const MapMarkers = ({ contactListTracking, selectedLotLan }: Props) => {
  const map = useMap()
  const [openedPopup, setOpenedPopup] = useState<L.Popup | null>(null)
  const popupRefs = useRef<Map<string, L.Popup>>(new Map())

  useEffect(() => {
    if (selectedLotLan) {
      const { lat, lon } = selectedLotLan

      // Check if lat and lng are defined and valid
      if (lat !== undefined &&
        lon !== undefined &&
        !isNaN(lat) &&
        !isNaN(lon) &&
        lat !== null &&
        lon !== null) {
        map.setView([lat, lon], 14, {
          animate: true,
        })

        if (openedPopup) {
          openedPopup.close()
        }

        const newPopup = popupRefs.current.get(`${lat}-${lon}`)
        if (newPopup) {
          newPopup.openOn(map)
          setOpenedPopup(newPopup)
        }
      }
    }
  }, [selectedLotLan, map, openedPopup])

  const contactTracking = prop('list', contactListTracking)

  return (
    <>
      {contactTracking.map((item) => {
        const name = prop('name', item)
        const shortName = prop('shortName', item)
        const addressList = propOr([], 'addressList', item) as any

        return addressList.map((addressItem:any) => {
          const lat = prop('lat', addressItem)
          const lng = prop('lon', addressItem)
          const isHighlighted =
            lat === selectedLotLan?.lat && lng === selectedLotLan?.lon

          return (
            <Marker
              key={`${lat}-${lng}`}
              position={{ lat, lng }}
              icon={isHighlighted ? highlightedIcon : startIcon}
            >
              <Popup
                ref={(popup) => {
                  if (popup) {
                    popupRefs.current.set(`${lat}-${lng}`, popup)
                    if (isHighlighted) {
                      setOpenedPopup(popup)
                    }
                  }
                }}
                autoClose={false}
              >
                <FlexBox align="center" direction="column" gap={4}>
                  <Typography variant="caption">Client: {name}</Typography>
                  <Typography variant="caption">
                    Short Name: {shortName}
                  </Typography>
                </FlexBox>
              </Popup>
            </Marker>
          )
        })
      })}
    </>
  )
}

export default MapContent
