import * as API from 'constants/Api'

import { getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getCurrencyList = (options?: Options) => {
  return getRequest<any>(API.CurrencyList, options)
}

export const getCurrencyDetail = (options?: Options) => {
  return getRequest<any>(API.CurrencyGetById, options)
}

export const createCurrency = (options?: Options) => {
  return postRequest<any>(API.CurrencyCreate, options)
}

export const updateCurrency = (options?: Options) => {
  return putRequest<any>(API.CurrencyUpdate, options)
}
