import React from 'react'
import MultiSelectField from 'components/form/MultipleSelectField'

const OPTIONS = [
  { value: 'MONDAY', name: 'ПОНЕДЕЛЬНИК' },
  { value: 'TUESDAY', name: 'ВТОРНИК' },
  { value: 'WEDNESDAY', name: 'СРЕДА' },
  { value: 'THURSDAY', name: 'ЧЕТВЕРГ' },
  { value: 'FRIDAY', name: 'ПЯТНИЦА' },
  { value: 'SATURDAY', name: 'СУББОТА' },
  { value: 'SUNDAY', name: 'ВОСКРЕСЕНЬЕ' },
]

function DayOfWeeksField (props:any) {
  return (
    <MultiSelectField
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default DayOfWeeksField
