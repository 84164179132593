import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'
import Container from 'components/container/Container'

import { validationSchema } from './PriceLevelCreateContainer'

import PriceLevelForm from '../components/form/PriceLevelUpdateForm'
import { getByIdPriceLevel, updatePriceLevel } from '../api'
import {
  priceLevelDetailSerializer,
  priceLevelUpdateSerializer
} from '../serializer'
import { PRICE_LEVEL_TABS } from '../components/PriceLevelTabs'
import ProductsPriceTable from '../components/ProductsPriceTable'
import TableFilterPopup from '../../../../components/table/TableFilterSidePopup'
import PriceLevelExcelExport from '../components/PriceLevelExcelExport'

function PriceLevelUpdateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { id, tab } = useParams()
  const [openImport, setOpenImport] = useState(false)
  const [lineItems, setLineItems] = useState([])
  const priceLevelLineItemsUpdate = usePut(updatePriceLevel)
  const priceLevelDetail = useGetDetail(getByIdPriceLevel, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    const jointLineItems = [].concat(...Object.values(lineItems))
    const data = priceLevelUpdateSerializer({ ...values, lineItemDTOs: jointLineItems })
    return priceLevelLineItemsUpdate.putData({ data })
      .then(() => snackbar({ message: 'Ценовая политика успешно обновлена' }))
      .then(() => navigate(ROUTES.SETTINGS_PRICE_LEVEL_LIST))
  }, [lineItems])

  const initialValues = useMemo(
    () => priceLevelDetailSerializer(priceLevelDetail.result),
    [priceLevelDetail])

  return (
    <AppLayout>
      <Container>
        {!priceLevelDetail.loading && (
          <PriceLevelForm
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
          />
        )}
        {tab === PRICE_LEVEL_TABS.PRODUCT_PRICE && (
          <ProductsPriceTable
            setOpenImport={setOpenImport}
            lineItems={lineItems}
            setLineItems={setLineItems}
          />
        )}
      </Container>
      {(priceLevelDetail.loading) && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
      <TableFilterPopup
        open={openImport}
        setOpen={setOpenImport}
        filterNode={(
          <PriceLevelExcelExport
            id={id}
            productList={priceLevelDetail}
            setOpen={setOpenImport}
          />
        )}
      />
    </AppLayout>
  )
}

export default PriceLevelUpdateContainer
