import * as ROUTES from 'constants/Routes'

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Divider, IconButton, MenuItem, Popover, Typography } from '@mui/material'
import { path, prop } from 'ramda'
import { makeStyles } from '@mui/styles'
import { pathParams } from 'path-params'

const useStyles = makeStyles({
  menuItem: {
    border: 'none',
    padding: '8px',
    '&:hover': {
      background: '#E5EDFF',
    },
  },
})

interface Props {
  readonly user: any
}

export default function AccountPopover ({ user }: Props) {
  const picture = path(['picture', 'link'], user) as string
  const name = prop('fullName', user) as string
  const email = prop('email', user) as string
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const navigate = useNavigate()
  const classes = useStyles()

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onLogout = () => {
    localStorage.setItem('antex-token', '')
    location.reload()
    handleClose()
  }

  const updatedURL = pathParams(ROUTES.HR_EMPLOYEE_UPDATE, { id: user.profileId })

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          background: 'rgba(0, 0, 0, 0.08)', // Example of alpha grey color
        }}
      >
        <Avatar src={picture} alt="photoURL">
          {name ? name.charAt(0).toUpperCase() : ''}
        </Avatar>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

        <MenuItem
          sx={{ mt: '8px', mx: 1.5 }}
          className={classes.menuItem}
          onClick={() => navigate(updatedURL)}
        >
          Профиль
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed', m: 0 }} />
        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={onLogout}
          className={classes.menuItem}
          sx={{ typography: 'body2', color: 'error.main', py: 1.5, mx: 1.5, mb: 1.5 }}
        >
          Выйти
        </MenuItem>
      </Popover>
    </>
  )
}
