import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'
import { useField } from 'formik'
import { path, prop } from 'ramda'
import DoubleFormatField from 'components/form/DoubleFormatField'

import VisitField from './form/VisitField'
import CustomerField from './form/CustomerField'
import PaymentMethodField from './form/PaymentMethodField'
import PaymentLineItems from './form/PaymentLineItems'
import ReceiverField from './form/ReceiverField'
import DepositAccountField from './form/DepositAccountField'
import ChargeAccountField from './form/ChargeAccountField'
import StatusField from './form/StatusField'
import { AvailableCustomField } from './custom-fields/AvailableCustomField'

interface Props {
  readonly onCollect: (values: any) => void
  readonly onDeposit: (values: any) => void
  readonly statusChangeLoading: boolean
  readonly form: any
  readonly customFields:any
}

function PaymentUpdateForm ({ onCollect, statusChangeLoading, form, onDeposit, customFields }: Props) {
  const accountSelectionField = useField('account')
  const accountSelectionValue = path([0, 'value'], accountSelectionField)
  const accountSelectionId = prop('id', accountSelectionValue)
  const name = path(['values', 'refNumber'], form)
  const values = prop('values', form)
  const status = prop('status', values)
  const requestedAmount = prop('requestedAmount', values)
  const amount = prop('amount', values)
  const isEqual = requestedAmount === Number(amount)
  const isPaid = status === 'PAID'
  const isScheduled = status === 'SCHEDULED'
  const isDeposited = status === 'DEPOSITED'
  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить оплату (${name})`}
          rightButton={(
            <>
              <Button
                type="button"
                disabled={!accountSelectionId || statusChangeLoading || !isScheduled}
                onClick={() => onCollect(values)}
              >
                Сбор оплаты
              </Button>
              <Button
                type="button"
                disabled={!isPaid}
                onClick={() => onDeposit(values)}
              >
                Сделать депозит
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <VisitField disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CustomerField disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DateField label="Дата оплаты" name="paymentDate" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <PaymentMethodField disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Привязанный номер оплаты"
                      name="paymentReferenceNumber"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <ReceiverField disabled={!isScheduled} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DoubleFormatField
                      label="Сумма"
                      name="amount"
                      disabled={!isScheduled}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DoubleFormatField
                      label="Сумма к оплате"
                      name="requestedAmount"
                      disabled={isEqual || !isScheduled}
                    />
                  </Grid>
                  {!isDeposited && (
                    <Grid item={true} lg={4}>
                      <ChargeAccountField disabled={(isEqual && isScheduled)} />
                    </Grid>
                  )}
                  <Grid item={true} lg={4}>
                    <DepositAccountField disabled={!isPaid} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <StatusField disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Примечания"
                      name="notes"
                      multiline={true}
                      minRows={4}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <PaymentLineItems loading={false} />
          </Grid>
          <Grid item={true} lg={12}>
            <AvailableCustomField customFields={customFields} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(PaymentUpdateForm)
