import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { updateAdjustment, getAdjustmentDetail, getProductList } from '../api'
import AdjustmentUpdateForm from '../components/AdjustmentUpdateForm'
import { adjustmentGetInitialValues, adjustmentUpdateValues } from '../serializer'
import useScrollList from '../../../../hooks/useScrollList'
import { getRequest } from '../../../../api/BaseApi'
import * as API from '../../../../constants/Api'

function AdjustmentUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const adjustmentUpdate = usePut(updateAdjustment)
  const adjustmentDetail = useGetDetail(getAdjustmentDetail, { params: { id } })
  const productList = useScrollList(getProductList)

  const handleSubmit = useCallback((values: any) => {
    return adjustmentUpdate.putData({ data: adjustmentUpdateValues(values) })
      .then(() => snackbar({ message: 'Корректировка успешно обновлена' }))
      .then(() => navigate(ROUTES.WAREHOUSE_ADJUSTMENT_LIST))
  }, [])

  const onGetPriceLevel = useCallback((id: any, products: any) => {
    return getRequest(API.PriceLevelByProductId, { params: { id }, query: { products } })
  }, [])

  const initialValues = useMemo(() => {
    return adjustmentGetInitialValues(adjustmentDetail.result)
  }, [adjustmentDetail.result])

  return (
    <AppLayout>
      {!adjustmentDetail.loading && (
        <AdjustmentUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          productList={productList}
          enableReinitialize={true}
          handleGetPriceLevel={onGetPriceLevel}
        />
      )}
      {adjustmentDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default AdjustmentUpdateContainer
