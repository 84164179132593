import React from 'react'
import AppLayout from 'components/main/AppLayout'

import CategoryAddProductForm from '../components/CategoryAddProductForm'

function CategoryAddProductContainer () {
  return (
    <AppLayout>
      <CategoryAddProductForm />
    </AppLayout>
  )
}

export default CategoryAddProductContainer
