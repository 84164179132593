import React from 'react'
import { FieldArray } from 'formik'
import { Button, Grid } from '@mui/material'

import AddressRow from './AddressRow'

interface Props {
  readonly addresses: any
}

function AddressesArray ({ addresses }: Props) {
  return (
    <FieldArray name="addresses">
      {({ push, remove }: any) => (
        <Grid container={true} spacing={3}>
          {addresses.map((address: any, index: number) => (
            <AddressRow
              index={index}
              remove={remove}
              push={push}
              addresses={addresses}
            />
          ))}
          {addresses.length <= 0 && (
            <Grid item={true} lg={3}>
              <Button
                onClick={() => {
                  push({
                    name: '',
                    landmark: '',
                    countryId: '',
                    regionId: ''
                  })
                }}
              >
                Добавит адрес
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </FieldArray>
  )
}

export default AddressesArray
