import React from 'react'
import SelectField from 'components/form/SelectField'

export enum TRANSACTION_TYPE {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

const OPTIONS = [{ value: TRANSACTION_TYPE.CREDIT, name: 'Credit' }, { value: TRANSACTION_TYPE.DEBIT, name: 'Debit' }]

function ManualEntryTypeField (props: any) {
  return (
    <SelectField
      name="transactionType"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ManualEntryTypeField
