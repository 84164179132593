import React from 'react'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import withForm, { WithFormProps } from 'components/form/withForm'
import { useField } from 'formik'
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { pathOr, prop } from 'ramda'
import SkeletonList from 'components/skeleton/SkeletonList'
import TextField from 'components/form/TextField'
import { useTranslationI18n } from 'i18n/I18nContext'
import Container from 'components/container/Container'
import Header from 'components/container/Header'
import PageTitle from 'components/main/PageTitle'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'

import WarehouseField from './form/WarehouseField'

interface Props extends WithFormProps {
  readonly loading?: boolean
}

function ReceivePODetailForm ({ loading }: Props) {
  const navigate = useNavigate()
  const itemsField = useField('items')
  const items = itemsField[0].value || []
  const { t } = useTranslationI18n()

  const handleClick = () => {
    navigate(-1)
  }
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Приход"
          rightButton={(
            <Button type="button" onClick={handleClick}>Назад</Button>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <WarehouseField disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField name="poNumber" disabled={true} label="Номер закупки" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField name="receiveNumber" disabled={true} label="Полученный номер" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField name="transferNumber" disabled={true} label="Номер прихода" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField name="expectedDate" disabled={true} label="Ожидаемая дата" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField name="receiveDate" disabled={true} label="Дата получения" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField name="receiveStatus" disabled={true} label="Статус" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField name="notes" label="Примечания" multiline={true} minRows={4} disabled={true} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Продукты" />
              <Divider />
              <CardContent>
                <Grid item={true} lg={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('Название продукта')}</TableCell>
                          <TableCell>{t('Количество получения')}</TableCell>
                          <TableCell>{t('Заказанное количетсво')}</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading && <SkeletonList columnCount={2} />}
                        {items.map((item: any, index: number) => {
                          const productName = pathOr('', ['product', 'name'], item)
                          const expectedQuantity = prop('expectedQuantity', item)
                          const receivedQuantity = prop('receivedQuantity', item)
                          return (
                            <TableRow key={index}>
                              <TableCell>{productName}</TableCell>
                              <TableCell>
                                {receivedQuantity}
                              </TableCell>
                              <TableCell>{expectedQuantity}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(ReceivePODetailForm)
