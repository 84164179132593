import React, { useCallback, useEffect, useState } from 'react'
import { Box, Card, CardContent, Divider, Grid, IconButton, Skeleton } from '@mui/material'
import { usePost } from 'hooks/usePost'
import CardHeader from 'components/card/CardHeader'
import Dropzone from 'components/upload/Dropzone'
import useDialog from 'hooks/useDialog'
import { ImgCarousel } from 'components/upload/img-carousel/ImgCarousel'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import { useField } from 'formik'
import { prop } from 'ramda'
import { ImagePreview } from 'components/upload/image-preview/ImagePreview'
import FlexBox from 'components/main/FlexBox'
import { useSnackbar } from 'context/snackbar'
import CheckboxField from 'components/form/CheckboxField'
import { usePut } from 'hooks/usePut'
import { useParams } from 'react-router-dom'

import { uploadFiles, deleteUploadFile, defaultImageContact } from '../../api'

interface Props {
  readonly isCreateForm?:boolean
}

function ImageUpload ({ isCreateForm = false }:Props) {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const imagePreviewDialogue = useDialog()
  const carouselImageDialogue = useDialog()
  const fileUpload = usePost(uploadFiles)
  const contactDeleteImage = usePost(deleteUploadFile)
  const productPrimaryImage = usePut(defaultImageContact)
  const imageField = useField('images')
  const imageValue = imageField[0].value || []
  const defaultImage = useField('image')
  const defaultImageValue = defaultImage[0].value
  const attachmentSetFieldValues = imageField[2]
  const [images, setImages] = useState(imageValue as any)
  const [choosedImage, setChoosedImage] = useState(null)
  const [data, setData] = useState(null) as any
  const [image, setImage] = useState('')
  const [checkedImageId, setCheckedImageId] = useState(defaultImageValue?.id || null) as any

  useEffect(() => {
    if (defaultImageValue?.id) {
      setCheckedImageId(defaultImageValue?.id)
    }
    attachmentSetFieldValues.setValue(images)
  }, [images])

  const onDrop = useCallback((values: any = []) => {
    const data = new FormData()
    values.forEach((file: any) => {
      data.append('uploadFile', file)
    })
    setImage(URL.createObjectURL(values[0]))
    setData(data)
    imagePreviewDialogue.handleOpen()
  }, [])

  const handleUpload = useCallback(() => {
    fileUpload.postData({ data }).then((response) => {
      setImages((prev: any) => [...prev, response])
    })
      .then(() => imagePreviewDialogue.handleClose())
      .then(() => setData(null))
  }, [data])

  const handleClosePreview = () => {
    setData(null)
    setImage('')
    imagePreviewDialogue.handleClose()
  }

  const handleDelete = useCallback((id: any) => {
    contactDeleteImage.postData({ params: { id } }).then(() => {
      setImages((prev: any) => prev.filter((img: any) => img.id !== id))
    })
  }, [])

  const handleClick = (item:any) => {
    setChoosedImage(item)
    carouselImageDialogue.handleOpen()
  }

  const onPrimary = useCallback((image:any) => {
    if (isCreateForm) {
      const imageIndex = images.findIndex((img: { id: any }) => img.id === image.id)
      if (imageIndex !== -1) {
        const reorderedImages = [...images]
        const selectedImage = reorderedImages.splice(imageIndex, 1)[0]
        reorderedImages.unshift(selectedImage)
        snackbar({ message: 'Выбранное изображение успешно обновлено в качестве основного' })
        setCheckedImageId(image.id)
        setImages(reorderedImages)
      }
    } else {
      productPrimaryImage.putData({ params:{ contactId:id, imageId:image.id } })
        .then(() => snackbar({ message:'Выбранное изображение успешно обновлено в качестве основного' }))
        .then(() => setCheckedImageId(image.id))
        .then(() => defaultImage[2].setValue(image))
        .then(() => {
          const imageIndex = images.findIndex((img: { id: any }) => img.id === image.id)
          if (imageIndex !== -1) {
            const reorderedImages = [...images]
            const selectedImage = reorderedImages.splice(imageIndex, 1)[0]
            reorderedImages.unshift(selectedImage)
            attachmentSetFieldValues.setValue(reorderedImages)
          }
        })
    }
  }, [images])

  return (
    <Card>
      <CardHeader title="Добавить изображение для контрагента" />
      <CardContent>
        <Grid container={true} spacing={2}>
          <Grid item lg={12}>
            <FlexBox align="flex-start" gap={24}>
              <Dropzone onDrop={onDrop} />
              {images.length > 0 && images.map((image: any) => {
                const link = prop('link', image)
                const id = prop('id', image)
                const originalName = prop('originalName', image)
                return (
                  <>
                    <Box
                      sx={{
                        display:'flex',
                        alignItems:'flex-end',
                        justifyContent:'center',
                        position: 'relative',
                        width:'200px',
                        height:'200px',
                        background:'#F8F8F8',
                        borderRadius:'8px 8px 0 0',
                      }}
                    >
                      <img
                        src={link}
                        alt={originalName}
                        onClick={() => handleClick(image)}
                        style={{
                          height: 150,
                          width:150,
                          borderRadius:'8px 8px 0 0',
                          objectFit: 'cover',
                          cursor:'pointer'
                        }}
                      />
                      <Box
                        sx={{
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'flex-end',
                          gap:'20px',
                          position:'absolute',
                          right:'25px',
                          top:'15px',
                          width:'150px',
                          height:'30px'
                        }}
                      >
                        <CheckboxField
                          color="white"
                          name="checked"
                          label="Основной"
                          checked={checkedImageId === id}
                          onClick={() => onPrimary(image)}
                        />
                        <IconButton onClick={() => handleDelete(id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </>
                )
              })}
              {fileUpload.loading && (
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'flex-end',
                    justifyContent:'center',
                    width:'200px',
                    height:'200px',
                    background:'#F8F8F8',
                    borderRadius:'8px 8px 0 0',
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={150}
                    height={150}
                    sx={{ borderRadius:'8px 8px 0 0' }}
                  />
                </Box>
              )}
            </FlexBox>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      {carouselImageDialogue.open && (
        <ImgCarousel
          image={choosedImage}
          images={images}
          onDelete={handleDelete}
          open={carouselImageDialogue.open}
          handleClose={carouselImageDialogue.handleClose}
          defaultImageValue={defaultImageValue}
          onDefaultImage={onPrimary}
        />
      )}
      {imagePreviewDialogue.open && (
        <ImagePreview
          image={image}
          onUpload={handleUpload}
          onClose={handleClosePreview}
          open={imagePreviewDialogue.open}
          handleClose={imagePreviewDialogue.handleClose}
        />
      )}
    </Card>
  )
}

export default ImageUpload
