import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { createPackage } from '../api'
import PackageCreateForm from '../components/PackageCreateForm'

function PackageCreateContainer () {
  const packageCreate = usePost(createPackage)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = useCallback((values: any) => {
    return packageCreate.postData({ data: values })
      .then(() => snackbar({ message: 'Упаковка успешно создана' }))
      .then(() => navigate(ROUTES.CATALOG_PACKAGE_LIST))
  }, [])

  return (
    <AppLayout>
      <PackageCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default PackageCreateContainer
