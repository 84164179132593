import * as ROUTES from 'constants/Routes'

import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import AdbIcon from '@mui/icons-material/Adb'
import FlexBox from 'components/main/FlexBox'
import Logo from 'assets/logo.svg'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router'
import { useUser } from 'context/user'
import NotificationIcon from 'assets/notification-icon.svg'
import AccountPopover from 'components/main/AccountPopover'
import { isValidToken } from 'utils/tokenSerializer'
import { Link } from 'react-router-dom'

import LanguageSelect from '../../../../components/language-select/LanguageSelect'

const useStyles = makeStyles({
  appBar:{
    '&.MuiAppBar-root':{
      background:'transparent'
    }
  },
  buttonLogin:{
    padding:'8px 16px',
    textTransform:'capitalize'
  },
  notification: {
    width: '35px',
    height: '35px',
    background: '#474747',
    padding: '8px 7px',
    marginRight: '20px',
    borderRadius: '50%',
    cursor: 'pointer'
  }
})

const pages = [
  {
    id:1,
    page:'Дашборд',
    link:ROUTES.CATALOG_PRODUCT_LIST
  },
  {
    id:2,
    page:'О компании',
    link:ROUTES.LANDING_SEARCH
  },
  {
    id:3,
    page:'Демо',
    link:ROUTES.LANDING_SEARCH
  },
  {
    id:4,
    page:'Создать компанию',
    link:ROUTES.LANDING_SEARCH
  }
]

function LandingAppBar () {
  const { user } = useUser()
  const classes = useStyles()
  const navigate = useNavigate()
  const token = localStorage.getItem('antex-token') || ''
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const onLogin = () => {
    navigate(ROUTES.SIGN_IN)
  }

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ padding:'0 30px' }}>
          <FlexBox align="center" style={{ marginRight:'30px', xs:'none' }}>
            <img src={Logo} alt="Logo_icon" />
          </FlexBox>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page.page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Box
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={Logo} alt="Logo_icon" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', gap:'10px' } }}>
            {pages.map((page) => {
              return (
                <Link to={'https://dev.antexpert.uz//catalog/product/list'}>
                  <Typography
                    key={page.id}
                    textAlign="center"
                    variant="body1"
                    sx={{ my: 2, display: 'block', cursor: 'pointer', color: '#202020' }}
                  >
                    {page.page}
                  </Typography>
                </Link>
              )
            })}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <FlexBox align="center" gap={10}>
              {user && isValidToken(token) ? (
                <FlexBox align="center">
                  <img src={NotificationIcon} alt="notification" className={classes.notification} />
                  <AccountPopover user={user} />
                </FlexBox>
              ) : (
                <FlexBox align="center" gap={10}>
                  <Button
                    color="secondary"
                    variant="contained"
                    className={classes.buttonLogin}
                    onClick={onLogin}>
                    <Typography variant="body2" textAlign="center">Вход</Typography>
                  </Button>
                  <Button className={classes.buttonLogin} onClick={handleCloseNavMenu}>
                    <Typography variant="body2" textAlign="center">Начать бесплатную пробную версию</Typography>
                  </Button>
                </FlexBox>
              )}
              <LanguageSelect />
            </FlexBox>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default LandingAppBar
