import React from 'react'
import { prop } from 'ramda'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'

import { FILTER_FIELD_TYPE } from '../../../constants'
import VisitTypeField from '../form/field/VisitTypeField'
import VisitStatusField from '../form/field/VisitStatusField'
import TerritoryField from '../form/field/TerritoryField'
import UserField from '../form/field/UserField'
import ClientField from '../form/field/ClientField'
import SuccessorField from '../form/field/SuccessorField'
import PredecessorField from '../form/field/PredecessorField'

const getFieldByType = (fieldType: string, lookUpType:string, name:string, rest: any, code:string) => {
  if (fieldType === 'LOOKUP' && lookUpType === 'TASK_TYPE') {
    return <VisitTypeField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'TASK_STATUS') {
    return <VisitStatusField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'TERRITORY') {
    return <TerritoryField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'USER') {
    return <UserField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'CONTACT') {
    return <ClientField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'TASK' && code === 'SUCCESSOR') {
    return <SuccessorField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'TASK' && code === 'PREDECESSOR') {
    return <PredecessorField {...rest} />
  }
  if (fieldType === FILTER_FIELD_TYPE.DATE_TIME_PICKER) {
    return (
      <DateField
        label="Дата"
        {...rest}
      />
    )
  }
  if (fieldType === FILTER_FIELD_TYPE.NUMBER_BOX) {
    return (
      <TextField
        label="Ценить"
        type="number"
        {...rest}
      />
    )
  }

  return (
    <TextField
      label="Значение поля"
      {...rest}
    />
  )
}

export const ComparatorValueFields = (props: any) => {
  const { item, ...rest } = props
  const fieldType = prop('fieldType', item)
  const lookUpType = prop('lookUpType', item)
  const name = prop('name', item)
  const code = prop('code', item)
  return getFieldByType(fieldType, lookUpType, name, rest, code)
}
