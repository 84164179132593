import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import MultiSelectSearchField from 'components/form/MultiSelectSearchField'
import { path, prop } from 'ramda'
import { useField } from 'formik'

const getOptionValue = (option: any) => {
  if (option) {
    const role = prop('role', option)
    const name = prop('displayName', option)

    return { name, role }
  }

  return null
}

const getOptionSelected = (option: any, value: any) => {
  return option?.role === value?.role
}

function SortingFieldRoles (props: any) {
  const visibilityField = useField('visibleTo')
  const visibilityValue = path(['0', 'value'], visibilityField)
  const rolesField = useField('roles')
  const rolesSetValue = path(['2', 'setValue'], rolesField)
  const type = visibilityValue === 'ONLY_ME' || visibilityValue === 'EVERYONE'

  useEffect(() => {
    if (visibilityValue === 'ONLY_ME') {
      rolesSetValue([null])
    }
  }, [])

  return (
    <MultiSelectSearchField
      label="Роль"
      name="roles"
      api={API.AuthRoles}
      params={{ query: { language:'ru' } }}
      disabled={type}
      searchText=""
      getOptionValue={getOptionValue}
      getOptionSelected={getOptionSelected}
      {...props}
    />
  )
}

export default SortingFieldRoles
