import { dissoc, omit, path, pathOr, prop } from 'ramda'
import { getCurrentDate } from 'utils/dates'

export const visitCreateSerializer = ({ customFields, ...values }: any) => {
  const customerId = path(['customerId', 'id'], values)
  const userId = path(['userId', 'id'], values)
  const predecessorId = path(['predecessorId', 'id'], values)
  const typeId = path(['typeId', 'id'], values)
  const territoryId = path(['territoryId', 'id'], values)
  const status = path(['status', 'code'], values)
  const addressId = pathOr('', ['addressId', 'id'], values)
  return {
    ...values,
    predecessorId,
    typeId,
    territoryId,
    customerId,
    userId,
    status,
    addressId,
    customFieldValue: customFields
  }
}
export const visitInitialValuesSerializer = (values: any) => {
  const userId = prop('user', values)
  const territoryId = prop('territory', values)
  const predecessorId = prop('predecessor', values)
  const typeId = prop('type', values)
  const customerId = prop('customer', values)
  const getAttachments = prop('attachments', values)
  const attachments = getAttachments || []
  const addressId = prop('address', values)
  return {
    ...values,
    attachments,
    customerId,
    userId,
    territoryId,
    predecessorId,
    typeId,
    addressId
  }
}

export const visitUpdateSerializer = ({ customFields, ...values }: any) => {
  const fieldsToRemove = [
    'customer',
    'predecessor',
    'successor',
    'territories',
    'user',
    'address',
    'photoActions',
    'videoActions',
    'territory',
    'type'
  ]
  const result = fieldsToRemove.reduce((acc, field) => dissoc(field, acc), values)
  const customerId = path(['customerId', 'id'], result)
  const userId = path(['userId', 'id'], result)
  const predecessorId = path(['predecessorId', 'id'], result)
  const territoryId = path(['territoryId', 'id'], result)
  const typeId = path(['typeId', 'id'], result)
  const status = path(['status', 'code'], values)
  const addressId = pathOr('', ['addressId', 'id'], values)
  return {
    ...result,
    customerId,
    userId,
    predecessorId,
    typeId,
    territoryId,
    status,
    addressId,
    customFieldValue: customFields
  }
}

export const selectedRowSerializer = (data: any) => {
  const ids = data && data.map((row: any) => prop('id', row))
  return [...ids]
}

export const onGetTab = (values: any) => {
  if (Array.isArray(values)) {
    return values && values.map((tab: any) => {
      const id = prop('id', tab)
      const name = prop('name', tab)
      return { id, title: name, status: id, count: name.toLowerCase() }
    })
  }
}

export function transformList (list: any): { [key: string]: number } {
  const transformedObject: { [key: string]: number } = {}

  if (Array.isArray(list)) {
    list.forEach((item) => {
      const lowercaseName: string = item.name.toLowerCase()
      transformedObject[lowercaseName] = item.records
    })
  }

  return transformedObject
}

export const visitCopySerializer = (values: any) => {
  const omittedValues = omit(['statusName', 'predecessor', 'plannedDate'], values)
  const id = prop('id', values)
  const name = prop('name', values)
  const predecessorId = { id, name }
  const territoryId = prop('territory', values)
  const customerId = prop('customer', values)
  const plannedDate = getCurrentDate()
  const userId = prop('user', values)
  const typeId = prop('type', values)
  const getAttachments = prop('attachments', values)
  const attachments = getAttachments || []
  const addressId = prop('address', values)
  return {
    ...omittedValues,
    predecessorId,
    territoryId,
    customerId,
    plannedDate,
    userId,
    typeId,
    attachments,
    addressId
  }
}

export const changeStatusSerializer = (values: any) => {
  const status = path(['status', 'code'], values)
  return { ...values, status }
}

export const isOnComplete = (values: any) => {
  const type = values?.type || {}
  const {
    order,
    payment,
    photo_receipts: photoReceipts,
    video
  } = type

  const notEqualFields = []

  if (order !== values.order) {
    notEqualFields.push('Заказ')
  }
  if (payment !== values.payment) {
    notEqualFields.push('Оплата')
  }
  if (photoReceipts !== values.photo_receipts) {
    notEqualFields.push('Фото')
  }
  if (video !== values.video) {
    notEqualFields.push('Видео')
  }

  return {
    isEqual: notEqualFields.length === 0,
    notEqualFields
  }
}
