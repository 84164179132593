import React from 'react'
import isEqual from 'react-fast-compare'
import { Form, Formik } from 'formik'

export
interface WithFormProps {
  readonly onSubmit: (values: any) => void
  readonly initialValues: any
  readonly validate: any
  readonly validationSchema:any
  readonly initialErrors:any
}

function withForm (Component: any, FormProps?: any) {
  const AntexForm = (props: any) => {
    const { onSubmit, initialValues, validate, validationSchema, initialErrors, ...rest } = props as WithFormProps
    return (
      <Formik
        onSubmit={onSubmit}
        validate={validate}
        initialValuesEqual={isEqual}
        initialValues={initialValues}
        validationSchema={validationSchema}
        initialErrors={initialErrors}
        validateOnChange={true}
        validateOnBlur={false}
        {...FormProps}
      >
        {(form: any) => (
          <Form>
            <Component {...rest} form={form} />
          </Form>
        )}
      </Formik>
    )
  }
  return React.memo(AntexForm, isEqual)
}

export default withForm
