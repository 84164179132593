import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'

import { createVisitType } from '../api'
import VisitTypeCreateFrom from '../components/VisitTypeCreateForm'

function TaskTypeCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const visitTypeCreate = usePost(createVisitType)

  const handleSubmit = useCallback((data: any) => {
    return visitTypeCreate.postData({ data })
      .then(() => navigate(ROUTES.VISIT_VISIT_TYPE_LIST))
      .then(() => snackbar({ message: 'Тип посещения успешно создана' }))
  }, [])

  return (
    <AppLayout>
      <VisitTypeCreateFrom
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default TaskTypeCreateContainer
