import React from 'react'
import { map, range } from 'ramda'
import Skeleton from '@mui/material/Skeleton'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { styled } from '@mui/material'

const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (propName) => propName !== 'isBorderNone' && propName !== 'rowHeight'
})(({ theme, isBorderNone, rowHeight }: any) => ({
  height: rowHeight,
  background: theme.palette.background.paper,
  ...(isBorderNone && {
    '& > td': {
      borderBottom: 'none'
    }
  })
})) as any

function SkeletonList (props: any) {
  const {
    rowCount = 5,
    columnCount = 6,
    lastRowBorderHidden = false,
    rowHeight = 43,
    height
  } = props

  return (
    <>
      {map(index => (
        <TableRowStyled
          key={index}
          isBorderNone={lastRowBorderHidden && columnCount - 1 === index}
          rowHeight={rowHeight}
        >
          {map(index => (
            <TableCell key={index}>
              <Skeleton
                animation="wave"
                height={height}
                width="80%"
                style={{ marginBottom: 5, marginTop: 5 }}
              />
            </TableCell>
          ), range(0, columnCount))}
        </TableRowStyled>
      ), range(0, rowCount))}
    </>
  )
}

export default SkeletonList
