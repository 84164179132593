import React from 'react'
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'

interface Props {
  items: any[]
  setItems: (items: any[]) => void
  children: any
}

export const Sortable = ({ items, children, setItems }: Props) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function handleDragEnd (event: any) {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item: Record<string, any>) => item.code === active.id)
      const newIndex = items.findIndex((item: Record<string, any>) => item.code === over.id)
      const newItems = arrayMove(items, oldIndex, newIndex)
      setItems(newItems)
    }
  }

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext items={items.map(item => item.code)} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  )
}
