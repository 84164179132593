import { useEffect, useRef, useState } from 'react'

const useClickOutside = <T extends HTMLElement>(
  initialFocused = false
) => {
  const [isFocused, setIsFocused] = useState(initialFocused)
  const ref = useRef<T>(null)

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }
      setIsFocused(false)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref])

  return { ref, isFocused, setIsFocused }
}

export default useClickOutside
