import { omit, path, prop } from 'ramda'

export const documentCreateSerializer = (data:any) => {
  const attachmentId = path(['attachmentId', 'id'], data)
  return { ...data, attachmentId }
}

export const documentGetInitialValuesSerializer = (data:any) => {
  const attachmentId = prop('attachment', data)
  return { ...data, attachmentId }
}

export const documentUpdateSerializer = (data:any) => {
  const attachmentId = path(['attachmentId', 'id'], data)
  const folderId = path(['folder', 'id'], data)
  const result = omit(['documentType', 'attachment', 'companyId'], data)
  return { ...result, attachmentId, folderId }
}
