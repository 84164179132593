import React from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles({
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    },
    '&.warning': {
      background: '#ffcc00',
    },
    '&.error': {
      background: '#EB4C42',
      color: '#fff'
    }
  }
})

interface Props {
  readonly status: string
}

const statusTranslations: { [key: string]: string } = {
  DRAFT: 'ЧЕРНОВИК',
  BOOKED: 'ЗАБРОНИРОВАНО',
  SUBMITTED: 'ОТПРАВЛЕНО',
  IN_PROCESS: 'В ПРОЦЕССЕ',
  COMPLETED: 'ЗАВЕРШЕНО',
  REJECTED: 'ОТКЛОНЕНО',
  RETURNED: 'ВОЗВРАЩЕНО',
  CANCELLED: 'ОТМЕНЕНО',
  PARTIAL_COMPLETED: 'ЧАСТИЧНО ЗАВЕРШЕНО'
}

const getTranslatedStatus = (status: string): string | undefined => {
  return statusTranslations[status]
}

function PreOrderStatusRow ({ status }: Props) {
  const isCompleted = status === 'COMPLETED' || status === 'BOOKED'
  const isWarning = status === 'SUBMITTED' || status === 'IN_PROCESS'
  const isError = status === 'REJECTED' || status === 'RETURNED' || status === 'CANCELLED'
  const classes = useStyles()
  return (
    <div className={cx(classes.statusIndicator, { 'active': isCompleted, 'warning':isWarning, 'error':isError })}>
      {getTranslatedStatus(status)}
    </div>
  )
}

export default PreOrderStatusRow
