import { omit, path, prop } from 'ramda'
import { toPayloadDate } from 'utils/dates'
import { toNumberFormat } from 'utils/formatMoney'

export const bulkPaymentCreateSerializer = (values:any) => {
  const currency = path(['currency', 'code'], values)
  const contactId = path(['contactId', 'id'], values)
  const accountId = path(['accountId', 'id'], values)
  const totalAmount = toNumberFormat(prop('totalAmount', values))
  const bankChargeAccountId = path(['bankChargeAccountId', 'id'], values)
  const shipmentLineItems = prop('shipmentPaymentIds', values)
  const date = toPayloadDate(prop('date', values))
  const shipmentPaymentIds = shipmentLineItems && shipmentLineItems.map((item:any) => {
    return item.id
  })
  const result = omit(['search'], values)
  return { ...result, date, contactId, currency, accountId, bankChargeAccountId, shipmentPaymentIds, totalAmount }
}

export const bulkPaymentDetailSerializer = (values:any) => {
  const accountId = prop('account', values)
  const bankChargeAccountId = prop('bankChargeAccount', values)
  const shipmentPaymentIds = prop('items', values)
  const date = toPayloadDate(prop('date', values))
  const result = omit(['search'], values)
  return { ...result, date, accountId, bankChargeAccountId, shipmentPaymentIds }
}
