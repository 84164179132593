import React, { useEffect } from 'react'
import { useField } from 'formik'
import ReactNumberFormat from 'react-number-format'

import TextField from './TextField'

const DoubleFormatField = (props:any) => {
  const [ field, , helpers ] = useField(props.name || '')

  useEffect(() => {
    if (props.value) {
      helpers.setValue(props.value)
    }
  }, [props.value])

  return (
    <ReactNumberFormat
      {...field}
      {...props}
      prefix={props.prefix}
      label={props.label}
      thousandSeparator=","
      allowNegative={false}
      decimalScale={2}
      decimalSeparator="."
      fixedDecimalScale={true}
      customInput={TextField}
    />
  )
}

export default DoubleFormatField
