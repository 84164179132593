import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'

import BillingPlanCreateForm from '../components/BillingPlanCreateForm'
import { createBilling } from '../api'
import { billingCreateSerializer } from '../serializers'

function BillingPlanCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const billingCreate = usePost(createBilling)

  const onSubmit = useCallback((values: any) => {
    return billingCreate.postData({ data: billingCreateSerializer(values) })
      .then(() => snackbar({ message: 'Тарифный план успешно создан' }))
      .then(() => navigate(ROUTES.BACKEND_BILLING_LIST))
  }, [])

  return (
    <AppLayout>
      <BillingPlanCreateForm
        onSubmit={onSubmit}
        initialValues={{ columns:[], items:[], modules:[] }}
        enableReinitialize={true}
      />
    </AppLayout>
  )
}

export default BillingPlanCreateContainer
