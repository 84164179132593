import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { path, propOr, trim } from 'ramda'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'context/snackbar'
import { useDelete } from 'hooks/useDelete'

import { deleteConsignment, getConsignmentList } from '../api'
import ConsignmentRowMenu from '../components/ConsignmentRowMenu'
import { financialFormat } from '../../../../utils/nomberFormats'

const columns = [
  {
    headerName: 'Номер консигнации',
    field: 'number',
  },
  {
    headerName: 'Клиент',
    field: 'contact',
    renderCell:(row:any) => path(['contact', 'name'], row)
  },
  {
    headerName: 'Общая сумма консигнации',
    field:'total',
    renderCell:(row:any) => financialFormat(row.total)
  },
  {
    headerName: 'Дата',
    field:'date',
  }
]

function ConsignmentListContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const consignmentList = useGetList(getConsignmentList)
  const consignmentDelete = useDelete(deleteConsignment)
  const [selectedRows, setSelectedRows] = useState([])

  const onDelete = useCallback((id:number) => {
    consignmentDelete.deleteData({ params:{ id } })
      .then(() => consignmentList.getList())
      .then(() => snackbar({ message:'Консигнация успешно удалена' }))
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    consignmentList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Консигнации"
        onAddClick={() => navigate(ROUTES.FINANCE_CONSIGNMENT_CREATE)}
        dataRequest={consignmentList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkbox={true}
        searchPlaceholder="Поиск по названию или ID"
        actions={(row:any) => (
          <ConsignmentRowMenu
            item={row}
            handleDelete={onDelete}
          />
        )}
      />
    </AppLayout>
  )
}

export default ConsignmentListContainer
