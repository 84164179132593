import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material'
import cx from 'classnames'
import Typography from '@mui/material/Typography'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

import FlexBox from './FlexBox'

import { SectionsIcon } from '../icons/SectionsIcon'

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh'
  },
  container: {
    flex: '1 1',
    overflow: 'auto',
    overflowX: 'hidden',
    background: '#FFFFFF',
    position: 'relative'
  },
  leftSide:{
    position:'relative',
    maxWidth:'280px',
    width:'100%',
    border:'1px solid #f1f1f1',
    padding:'8px',
    transition: '0.3s ease',
    '&.leftSideExpand':{
      maxWidth:'56px',
      width:'100%',
      padding:'8px 5px'
    }
  },
  leftSideHeaderText:{
    fontSize:'16px',
    fontWeight:'600',
    lineHeight:'18px',
    '&.leftSideExpand':{
      display:'none'
    }
  },
  rightSide:{
    width:'calc(100% - 280px)',
    '&.rightSideExpand':{
      width:'calc(100% - 40px)'
    }
  },
  iconBox:{
    display:'flex',
    align:'center'
  },
  absoluteIconButton: {
    padding:'0',
    background:'#f1f1f1',
    position: 'absolute',
    top: '22px',
    right:'-9px',
    transition: '0.3s ease',
    '&.leftSideExpand':{
      transform: 'rotate(-180deg)'
    }
  },
  menuListItem:{
    margin:'8px 0',
    '& .MuiListItemText-root':{
      '& .MuiTypography-root':{
        fontSize:'14px',
        fontWeight:'500',
        lineHeight:'16.94px'
      }
    }
  },
  list:{
    padding:'0',
    margin:'10px 0',
    '& .MuiListItemButton-root':{
      borderRadius:'5px',
      padding:'8px 12px',
      '&.leftSideExpand':{
        padding:'10px',
        '& .MuiListItemText-root':{
          display:'none'
        }
      },
      '&.active':{
        color:'#1557FF',
        background:'#E5EDFF'
      }
    },
    '& .MuiListItemIcon-root':{
      minWidth:'30px'
    }
  },
})

interface Props {
  readonly children: React.ReactNode
  readonly menuList:any
  readonly selectedMenu:string
  readonly setSelectedMenu:(value:string) => void
}

function SidebarLayout ({ children, selectedMenu, setSelectedMenu, menuList }:Props) {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)

  const onExpand = () => {
    setIsExpanded(prevState => !prevState)
  }

  return (
    <FlexBox flex={true} gap={10}>
      <Box className={cx(classes.leftSide, { 'leftSideExpand': isExpanded })}>
        <FlexBox
          align="center"
          justify={isExpanded ? 'center' : 'start'}
          style={{
            padding:'12px 0'
          }}
          gap={8}
        >
          <Tooltip
            title="Разделы"
            placement="right"
          >
            <Box className={classes.iconBox}>
              <SectionsIcon />
            </Box>
          </Tooltip>

          <Typography
            className={cx(classes.leftSideHeaderText, { 'leftSideExpand':isExpanded })}
          >
            Разделы
          </Typography>
          <IconButton
            type="button"
            onClick={onExpand}
            className={cx(classes.absoluteIconButton, { 'leftSideExpand':isExpanded })}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </FlexBox>
        <Divider />
        <List className={classes.list}>
          {menuList.map((menu:any, index:number) => (
            <ListItem
              key={index}
              onClick={() => setSelectedMenu(menu.value)}
              disablePadding
              className={classes.menuListItem}
            >
              <ListItemButton className={cx({ 'active': selectedMenu === menu.value, 'leftSideExpand':isExpanded })}>
                {menu?.icon ? (
                  <Tooltip title={menu.label} placement="right">
                    <ListItemIcon>
                      {menu?.icon}
                    </ListItemIcon>
                  </Tooltip>) : null
                }
                <ListItemText
                  primary={menu.label}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box className={cx(classes.rightSide, { 'rightSideExpand': isExpanded })}>
        {children}
      </Box>
    </FlexBox>
  )
}

export default SidebarLayout
