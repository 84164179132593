import * as ROUTES from 'constants/Routes'
import * as API from 'constants/Api'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { prop } from 'ramda'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'
import useScrollList from 'hooks/useScrollList'
import { getRequest } from 'api/BaseApi'

import PreOrderCreateForm from '../components/PreOrderCreateForm'
import { createPreOrder, createContact, getProductList } from '../api'
import { preOrderAgentCreateSerialize, preOrderCreateSerializer } from '../serializers'
import { preOrderInitialValues } from '../components/form/form-values'

function PreOrderCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const preOrderCreate = usePost(createPreOrder)
  const agentCreate = usePost(createContact)
  const productList = useScrollList(getProductList)

  const onSubmit = useCallback((data: any) => {
    return preOrderCreate.postData({ data: preOrderCreateSerializer(data) })
      .then(() => snackbar({ message: 'Перед-заказ успешно создан' }))
      .then(() => navigate(ROUTES.SALE_ORDER_PRE_ORDER_LIST))
  }, [])

  const onGetPriceLevel = useCallback((id: any, products: any) => {
    return getRequest(API.PriceLevelByProductId, { params: { id }, query: { products } })
  }, [])

  const getContactAddress = useCallback((contact: any) => {
    const contactId = prop('id', contact)
    return getRequest(API.SupplierGetById, { params: { id: contactId } })
  }, [])

  const handleClientCreate = useCallback((values: any) => {
    return agentCreate.postData({ data: preOrderAgentCreateSerialize(values) })
      .then(() => snackbar({ message: 'Контрагент успешно создан' }))
  }, [])

  return (
    <AppLayout>
      <PreOrderCreateForm
        onSave={onSubmit}
        onSubmit={onSubmit}
        onComplete={() => null}
        initialValues={preOrderInitialValues}
        productList={productList}
        handleGetPriceLevel={onGetPriceLevel}
        getContactAddress={getContactAddress}
        onClientCreate={handleClientCreate}
      />
    </AppLayout>
  )
}

export default PreOrderCreateContainer
