import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'

import { agentCategoryGetById, agentCategoryUpdate } from '../api'
import AgentCategoryUpdateForm from '../components/AgentCategoryUpdateForm'
import { categoryCreateSerializer, categoryInitialValues } from '../serializer'

function AgentCategoryUpdateContainer () {
  const { id } = useParams()
  const agentCategoryById = useGetDetail(agentCategoryGetById, { params: { id } })
  const updateAgentCategory = usePut(agentCategoryUpdate)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = useCallback((values: any) => {
    return updateAgentCategory.putData({ data: categoryCreateSerializer(values) })
      .then(() => snackbar({ message: 'Категория контрагента успешно обновлена' }))
      .then(() => navigate(ROUTES.FINANCE_AGENT_CATEGORY_LIST))
  }, [])

  const initialValues = useMemo(() => categoryInitialValues(agentCategoryById.result), [agentCategoryById])

  return (
    <AppLayout>
      {!agentCategoryById.loading && (
        <AgentCategoryUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      )}
    </AppLayout>
  )
}

export default AgentCategoryUpdateContainer
