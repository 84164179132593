import React, { useCallback } from 'react'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'

import ColumnCreateForm from './components/ColumnCreateForm'
import { columnsSettingCreateSerializer } from './serializer'

import { createColumn } from '../../api'

export const useStyles = makeStyles({
  header: {
    padding: '17px 20px',
    background: 'rgba(253, 253, 253, 0.5)',
    backdropFilter: 'blur(15px)',
    borderBottom:'1px solid #f1f1f1'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-between'
  },
  wrapper:{
    width:'800px',
    background:'#fff',
    minHeight: 'calc(100vh - 60px)'
  },
  addButton: {
    cursor: 'pointer'
  },
  searchBox:{
    padding:'20px',
    borderBottom:'1px solid #f1f1f1'
  },
  columnBox:{
    padding:'20px',
    height: 'calc(100vh - 256px)',
    overFlowY:'auto'
  },
  menuItem:{
    padding:'10px 20px',
    '&:hover':{
      background:'#F6F8FD'
    }
  },
  actions:{
    padding:'20px',
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end'
  },
  button: {
    fontSize: '13px',
    display: 'flex',
    gap:'5px',
    height: '40px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    padding: '9px 20px',
    textTransform:'capitalize',
    '&.disabled': {
      background: '#202020 !important',
      opacity: '0.5'
    }
  }
})

interface Props {
  readonly onBack:() => void
  readonly filterList:any
}

const ColumnSettingCreate = ({ onBack, filterList }:Props) => {
  const snackbar = useSnackbar()
  const filterCreate = usePost(createColumn)

  const onCreate = useCallback((values:any) => {
    filterCreate.postData({ data:columnsSettingCreateSerializer(values) })
      .then(() => onBack())
      .then(() => filterList.getList())
      .then(() => snackbar({ message: 'Колонок успешно создан' }))
  }, [])

  return (
    <>
      <ColumnCreateForm
        onBack={onBack}
        initialValues={{ type:'PRODUCT_LIST_PANEL', columns:[] }}
        onSubmit={onCreate}
      />
    </>
  )
}

export default ColumnSettingCreate
