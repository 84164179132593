import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'
import DoubleFormatField from 'components/form/DoubleFormatField'

import VisitField from './form/VisitField'
import CustomerField from './form/CustomerField'
import PaymentMethodField from './form/PaymentMethodField'
import PaymentLineItems from './form/PaymentLineItems'

function PaymentCreateForm () {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать оплату"
          rightButton={(
            <>
              <Button type="submit">Создать</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <VisitField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CustomerField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DoubleFormatField
                      label="Сумма к оплате"
                      name="paymentAmount"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DateField label="Дата оплаты" name="paymentDate" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <PaymentMethodField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Привязанный номер оплаты"
                      name="paymentReferenceNumber"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Аккаунт получения"
                      name="accountSelection"
                    />
                  </Grid>
                  <Grid item={true} lg={4} />
                  <Grid item={true} lg={4} />
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Примечания"
                      name="notes"
                      multiline={true}
                      minRows={4}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <PaymentLineItems loading={false} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(PaymentCreateForm)
