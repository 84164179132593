import Container from 'components/container/Container'
import Header from 'components/container/Header'
import PageTitle from 'components/main/PageTitle'
import { Button, Card, CardContent, Grid } from '@mui/material'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import React from 'react'
import withForm from 'components/form/withForm'

function FolderCreateForm () {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать папку"
          rightButton={(
            <>
              <Button
                type="submit"
              >
                Создать
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label="Название папки"
                      name="name"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(FolderCreateForm)
