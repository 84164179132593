import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { path, propOr, trim } from 'ramda'
import { useGetList } from 'hooks/useGetList'
import useDialog from 'hooks/useDialog'
import { usePost } from 'hooks/usePost'
import { formatMoney } from 'utils/formatMoney'

import { getExcelTransactions, getTransactionList } from '../api'
import TransactionRowMenu from '../components/TransactionRowMenu'
import MoreActionsRow from '../components/MoreRowMenu'
import TransactionStatusRow from '../components/TransactionStatusRow'
import TransactionTypeRow from '../components/TransactionTypeRow'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 100
  },
  {
    headerName: 'Имя учетной записи',
    field: 'account',
    renderCell: path(['account', 'name'])
  },
  {
    headerName: 'Название контрагента',
    field: 'contact',
    renderCell: path(['contact', 'name'])
  },
  {
    headerName: 'Добавлено',
    field: 'journalDate'
  },
  {
    headerName: 'Тип транзакции',
    field: 'entityType',
    renderCell: (item:any) => <TransactionStatusRow status={item.entityType} />
  },
  {
    headerName: 'Валюта',
    field: 'currency'
  },
  {
    headerName: 'Сумма',
    field: 'amount',
    renderCell:(item:any) => item.type === 'CREDIT' ? formatMoney(-item.amount)
      : '+' + formatMoney(item.amount)
  },
  {
    headerName: 'Тип',
    field: 'type',
    renderCell:(item:any) => <TransactionTypeRow status={item.type} />
  },
  {
    headerName: 'Номер Документа',
    field: 'refNumber'
  },
]

function TransactionListContainer () {
  const transactionsExcelDialogue = useDialog()
  const transactionList = useGetList(getTransactionList)
  const transactionGetExcel = usePost(getExcelTransactions)

  const handleGetExcel = useCallback(() => {
    transactionGetExcel.postData({ responseType: 'blob' })
      .then((response:any) => {
      // Convert response to blob
        const blob = new Blob([response])

        // Create a download link
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', `Transactions.xlsx`)
        document.body.appendChild(link)
        link.click()

        // Cleanup
        window.URL.revokeObjectURL(link.href)
      })
      .then(() => transactionsExcelDialogue.handleClose())
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    transactionList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Транзакции"
        dataRequest={transactionList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={() => <TransactionRowMenu />}
        moreActions={() => <MoreActionsRow onExportExcel={handleGetExcel} />}
      />
    </AppLayout>
  )
}

export default TransactionListContainer
