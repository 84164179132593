import React, { useEffect } from 'react'
import { useField } from 'formik'

import { ComparatorValueFields } from './ComparatorValueFields'

const translations: { [key: string]: string } = {
  'NUMBER': 'Номер заказа',
  'ORDER': 'Заказ',
  'WAREHOUSE': 'Склад',
  'DRIVER': 'Водитель',
  'PICKER': 'Сборщик',
  'STATUS': 'Статус',
  'PACKER': 'Упаковщик',
  'DELIVERY_DATE': 'Дата доставки',
  'CURRENCY': 'Валюта',
}

const getTranslatedColumn = (code: string, name:string): string | undefined => {
  return translations[code] || name
}

export const FilterSearchField = (props:any) => {
  const { item, index, ...rest } = props
  const criteriaCodeType = useField(`criteria[${index}].code`)
  const criteriaCodeTypeSetField = criteriaCodeType[2].setValue

  useEffect(() => {
    criteriaCodeTypeSetField(item?.code)
  }, [])

  return (
    <ComparatorValueFields
      label={getTranslatedColumn(item?.code, item?.name)}
      name={`criteria[${index}].value`}
      item={item}
      {...rest}
    />
  )
}
