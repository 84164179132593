import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [{ value: true, name: 'Активный' }, { value: false, name: 'Не активный' }]

function StatusField (props: any) {
  return (
    <SelectField
      name="active"
      label="Статус"
      options={OPTIONS}
      {...props}
    />
  )
}

export default StatusField
