import { path, prop } from 'ramda'
import { toPayloadDate } from 'utils/dates'
import { toNumber, toNumberFormat } from 'utils/formatMoney'

export const consignmentCreateSerializer = (values:any) => {
  const date = toPayloadDate(prop('date', values))
  const getItems = prop('items', values)
  const currencyCode = path(['currencyCode', 'code'], values)
  const contactId = path(['contactId', 'id'], values)
  const items = getItems && getItems.map((item:any) => {
    const amount = toNumberFormat(prop('amount', item))
    const dueDate = prop('dueDate', item)
    return {
      ...item,
      amount,
      dueDate,
    }
  })
  return {
    ...values,
    date,
    items,
    currencyCode,
    contactId
  }
}

export const consignmentDetail = (values:any) => {
  const contactId = prop('contact', values)
  return {
    ...values,
    contactId
  }
}

export const consignmentUpdateSerializer = (values:any) => {
  const date = toPayloadDate(prop('date', values))
  const getItems = prop('items', values)
  const currencyCode = path(['currencyCode', 'code'], values)
  const contactId = path(['contactId', 'id'], values)
  const items = getItems && getItems.map((item:any) => {
    const amount = toNumber(prop('amount', item))
    const dueDate = prop('dueDate', item)
    return {
      ...item,
      amount,
      dueDate,
    }
  })
  const total = toNumber(prop('total', values))
  return {
    ...values,
    date,
    items,
    currencyCode,
    contactId,
    total
  }
}
