import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Grid } from '@mui/material'
import DateField from 'components/form/DateField'
import DoubleFormatField from 'components/form/DoubleFormatField'

import AccountField from './form/AccountField'
import CurrencyField from './form/CurrencyField'

function TransferCashCreateForm () {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать перемешение"
          rightButton={(
            <>
              <Button type="submit">Создать</Button>
            </>
          )}
        />
      </Header>

      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={3}>
                    <DateField name="date" label="Дата" />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <AccountField name="fromAccountId" label="Перевод С" />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <AccountField name="toAccountId" label="Перевод В" />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <DoubleFormatField name="amount" label="Сумма" />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <CurrencyField />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(TransferCashCreateForm)
