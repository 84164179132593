import * as ROUTES from 'constants/Routes'

import * as Yup from 'yup'
import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'

import TransferCashCreateForm from '../components/TransferCashCreateForm'
import { transferCashCreate } from '../api'
import { transferCashSerializer } from '../serializer'

export const validationSchema = Yup.object().shape({
  fromAccountId: Yup.object({
    id: Yup.number(),
    name: Yup.string()
  }).required('Требуется со склада'),
  toAccountId: Yup.object({
    id: Yup.number().nullable(),
    name:Yup.string()
  }).required('Требуется на складе')
    .test('not-same-warehouse', 'Тот же аккаунт не рекомендуется, пожалуйста, выберите другой склад',
      function (value) {
        const fromAccountId:Record<string, number> = this.resolve(Yup.ref('fromAccountId'))
        const toAccountId = value

        return !(fromAccountId && toAccountId && fromAccountId.id === toAccountId.id)
      })
})

function TransferCashCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const transferCreate = usePost(transferCashCreate)

  const handleSubmit = useCallback((values: any) =>
    transferCreate.postData({ data: transferCashSerializer(values) })
      .then(() => navigate(ROUTES.FINANCE_MOVING_BETWEEN_CASH))
      .then(() => snackbar({ message: 'Перемещение успешно создан' }))
  , [])

  return (
    <AppLayout>
      <TransferCashCreateForm
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{ fromAccountId:null, toAccountId:null }}
      />
    </AppLayout>
  )
}

export default TransferCashCreateContainer
