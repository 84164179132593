import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'ASC', name: 'По возрастанию' },
  { value: 'DESC', name: 'По убыванию' },
  { value: '', name: 'Нет действий' }
]

function SortingSortType (props: any) {
  return (
    <SelectField
      label="Тип сортировки"
      options={OPTIONS}
      defaultValue={OPTIONS[2].value}
      data-cy="status"
      {...props}
    />
  )
}

export default SortingSortType
