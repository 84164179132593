import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { path, propOr, trim } from 'ramda'
import { usePost } from 'hooks/usePost'

import { getStockExcel, getStockList } from '../api'
import MoreActionButtons from '../components/MoreRowMenu'
import { StockTable } from '../components/StockTable'

const columns = [
  {
    headerName: 'название продукта',
    field: 'product',
    renderCell:path(['product', 'name'])
  },
  {
    headerName: 'Общее количество в наличии',
    field: 'totalQuantityOnHand'
  },
  {
    headerName: 'Общее забронированное количество',
    field: 'totalBookedQuantity'
  },
  {
    headerName: 'Название склада',
    field: 'stock',
    renderCell: (item:any) => (<StockTable stock={item.stock} />)
  }
]

function StockListContainer () {
  const stockList = useGetList(getStockList)
  const stockGetExcel = usePost(getStockExcel)

  const handleGetExcel = useCallback(() => {
    stockGetExcel.postData({ responseType: 'blob' })
      .then((response:any) => {
        // Convert response to blob
        const blob = new Blob([response])

        // Create a download link
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', `Stock.xlsx`)
        document.body.appendChild(link)
        link.click()

        // Cleanup
        window.URL.revokeObjectURL(link.href)
      })
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    stockList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Остатки"
        dataRequest={stockList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        moreActions={() => <MoreActionButtons onExportExcel={handleGetExcel} />}
      />
    </AppLayout>
  )
}

export default StockListContainer
