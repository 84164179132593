import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getDiscountList = (options?: Options) => {
  return getRequest<any>(API.DiscountList, options)
}

export const getDiscountDetail = (options?: Options) => {
  return getRequest<any>(API.DiscountGetById, options)
}
export const createDiscount = (options?: Options) => {
  return postRequest<any>(API.DiscountCreate, options)
}

export const updateDiscount = (options?: Options) => {
  return putRequest<any>(API.DiscountUpdate, options)
}

export const deleteDiscount = (options?: Options) => {
  return deleteRequest<any>(API.DiscountDelete, options)
}
