import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { prop } from 'ramda'
import { useLocation, useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'

import { createUser } from '../api'
import CompanyCreateUserForm from '../components/CompanyCreateUserForm'
import { userCreateSerializer } from '../serializer'

function CompanyCreateUserContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()
  const companyInfo = prop('state', location)
  const userCreate = usePost(createUser)

  const handleSubmit = useCallback((values: any) => {
    return userCreate.postData({ data: userCreateSerializer(values) })
      .then(() => snackbar({ message: 'Пользователь успешно создан' }))
      .then(() => navigate(ROUTES.BACKEND_COMPANY_LIST))
  }, [])

  return (
    <AppLayout>
      <CompanyCreateUserForm
        onSubmit={handleSubmit}
        initialValues={{ ...companyInfo, territoryRoles:[] }}
        enableReinitialize={true}
      />
    </AppLayout>
  )
}

export default CompanyCreateUserContainer
