import { MenuItem } from '@mui/material'
import React from 'react'

interface Props{
  readonly handleDeleteSelected:() => void
  readonly onUpdateStatusBulk:(status:string) => void
}
function TableActionsRow ({ handleDeleteSelected, onUpdateStatusBulk }:Props) {
  return (
    <>
      <MenuItem onClick={handleDeleteSelected}>
        Удалить выбранное
      </MenuItem>
      <MenuItem onClick={() => onUpdateStatusBulk('ACTIVE')}>
        Активировать выбранное
      </MenuItem>
      <MenuItem onClick={() => onUpdateStatusBulk('INACTIVE')}>
        Деактивировать выбранное
      </MenuItem>
    </>
  )
}

export default TableActionsRow
