import React, { useCallback } from 'react'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { prop } from 'ramda'
import useDialog from 'hooks/useDialog'
import { useField } from 'formik'

import ColumnsAddDialogue from './ColumnsAddDialogue'

const useStyles = makeStyles({
  item:{
    padding:'5px'
  },
  button:{
    textTransform:'initial',
    display:'flex',
  }
})

interface Props {
  readonly filterList:any
}

export const AddSortingColumns = ({ filterList }:Props) => {
  const parametersDialog = useDialog()
  const [ field, , helpers ] = useField('columns')
  const classes = useStyles()

  const handleVariantsAdd = useCallback((packages: any) => {
    const lineItemPackageIds = new Set(field.value.map((item: any) => prop('code', item)))

    const uniquePackages = packages.filter((item: any) => {
      const packageId = prop('code', item)
      return !lineItemPackageIds.has(packageId)
    })

    const otherLineItems = field.value.filter((lineItem: any) => {
      return packages.find((item: any) => prop('code', item) === prop('code', lineItem))
    })
    helpers.setValue([...otherLineItems, ...uniquePackages])
    parametersDialog.handleClose()
  }, [])

  const onOpenDialog = () => {
    parametersDialog.handleOpen()
  }

  return (
    <React.Fragment>
      <Button
        onClick={onOpenDialog}
        variant="outlined"
        color="secondary"
        className={classes.button}
      >
        Добавить колонки
      </Button>
      {parametersDialog.open && (
        <ColumnsAddDialogue
          open={parametersDialog.open}
          onClose={parametersDialog.handleClose}
          onAddVariants={handleVariantsAdd}
          packageList={filterList}
        />
      )}
    </React.Fragment>
  )
}
