import React, { useCallback, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import { prop, propOr } from 'ramda'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, DialogTitle } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useField } from 'formik'
import TableHead from '@mui/material/TableHead'
import InfinityScrollSearch from 'components/form/InfinityScrollSearch'
import { useTranslationI18n } from 'i18n/I18nContext'
import TextOverflow from 'components/main/TextOverflow'
import TableSkeletonList from 'components/table/TableSkeletonList'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'
import FlexBox from 'components/main/FlexBox'

export const useStyles = makeStyles({
  dialogContent:{
    padding: '16px',
    position: 'relative',
    maxHeight:'700px'
  },
  tableRoot:{
    height:'460px',
    borderRadius:'7px',
    border:'1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      cursor:'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly open: boolean
  readonly onClose: () => void
  readonly onAddVariants: (value: any) => void
  readonly productList: any
}

function AdjustmentVariantAddDialog ({ open, onClose, onAddVariants, productList }: Props) {
  const { t } = useTranslationI18n()
  const classes = useStyles()

  const lineItemsField = useField('items')
  const lineItemsFieldValue = lineItemsField[0].value || []

  const [selected, setSelected] = useState(lineItemsFieldValue as any)

  const results = propOr([], 'list', productList) as any
  const count = prop('count', productList) as number

  const searchField = useField('search')
  const searchFieldValue = searchField[0].value

  const onEscape = useCallback((event: any) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }, [onClose])

  const handleCheck = (checked: boolean, variant: any) => {
    const id = prop('id', variant)
    if (checked) {
      setSelected((prevSelected: any) => prevSelected.filter((select: any) => {
        return prop('productId', select) !== id
      }))
    } else {
      const newVariant = { ...variant, productId:id }
      setSelected((prevSelected: any) => [...prevSelected, newVariant])
    }
  }

  return (
    <Dialog open={open} fullWidth={true} onKeyDown={onEscape}>
      <DialogTitle sx={{ padding:'16px' }}>
        <Typography component="h2" variant="body1" sx={{ fontWeight:'700', fontSize:'20px' }}>
          {`${t('Просмотр продуктов')} - ${t('Отмечено')} (${selected.length})`}
        </Typography>
        <Box sx={{ padding: '8px 0' }}>
          <InfinityScrollSearch getList={productList.getList} />
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} id="adjustmentVariantTable">
        <InfiniteScroll
          dataLength={results.length}
          hasMore={count > results.length}
          loader=""
          next={() => productList.getList({ query: { searchText: searchFieldValue } })}
          scrollableTarget="adjustmentVariantTable"
        >
          <TableContainer className={classes.tableRoot}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell width={100}>{t('Пометить')}</TableCell>
                  <TableCell width={250}>{t('Продукт')}</TableCell>
                  <TableCell width={150}>{t('SKU')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {results.map((variant: any, index: number) => {
                  const id = prop('id', variant)
                  const checked = selected.find((select: any) => prop('productId', select) === id)
                  return (
                    <TableRow
                      className={cx({ 'selected': checked })}
                      onClick={() => handleCheck(checked, variant)}>
                      <TableCell data-cy={`variant-${index}`}>
                        <Checkbox checked={Boolean(checked)} color="secondary" />
                      </TableCell>
                      <TableCell>
                        <TextOverflow lines={1}>
                          {prop('name', variant)}
                        </TextOverflow>
                      </TableCell>
                      <TableCell>
                        {prop('sku', variant)}
                      </TableCell>
                    </TableRow>
                  )
                })}
                {productList.loading && (
                  <TableSkeletonList columnsCount={3} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </DialogContent>
      <DialogActions sx={{ padding:'16px' }}>
        <FlexBox align="center" gap="8px">
          <Button
            color="secondary"
            variant="contained"
            onClick={onClose}
            fullWidth={true}
          >
            Отменить
          </Button>
          <Button
            data-cy="add"
            variant="contained"
            fullWidth={true}
            onClick={() => onAddVariants(selected)}
          >
            Добавить
          </Button>
        </FlexBox>
      </DialogActions>
    </Dialog>
  )
}

export default AdjustmentVariantAddDialog
