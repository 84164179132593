import React from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles({
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    },
    '&.warning': {
      background: '#ffcc00'
    },
    '&.error': {
      background: '#EB4C42',
      color: '#fff'
    }
  }
})

interface Props {
  readonly status: string
}

const getTranslatedStatus = (status: string) => {
  if (status === 'SALES_TASK') {
    return 'ПРОДАЖА'
  }
  if (status === 'MARKETING_TASK') {
    return 'МАРКЕТИНГ'
  }
  if (status === 'DELIVERY_TASK') {
    return 'ДОСТАВКА'
  }
  if (status === 'PAYMENT_COLLECTION') {
    return 'ОПЛАТА'
  }
  if (status === 'CHECKING_OF_DUE_TASK') {
    return 'ПРОВЕРКА ЗАДАЧИ'
  }
  if (status === 'CHECKING_OF_DUE_SHELVES') {
    return 'ПРОВЕРКА ПОЛОК'
  }
  if (status === 'CUSTOM') {
    return 'ОБЫЧАЙ'
  }
}

function TaskReturnTypeRow ({ status }: Props) {
  const isActive = status === 'SALES_TASK'
  const isWarning = status === 'MARKETING_TASK' || status === 'DELIVERY_TASK' ||
    status === 'WAITING' || status === 'CHECKING_OF_DUE_TASK' || status === 'CHECKING_OF_DUE_SHELVES'
  const isError = status === 'PAYMENT_COLLECTION' || status === 'CUSTOM'
  const classes = useStyles()
  return (
    <div className={cx(classes.statusIndicator, { 'active': isActive, 'warning': isWarning, 'error':isError })}>
      {getTranslatedStatus(status)}
    </div>
  )
}

export default TaskReturnTypeRow
