import React, { useEffect } from 'react'
import LandingLayout from 'components/landing/LandingLayout'
import { useParams } from 'react-router-dom'
import { useGetDetailWithoutCall } from 'hooks/useGetDetailWithoutCall'
import { prop, propOr } from 'ramda'

import { getContactByAntexId, signInApi } from '../api'
import DetailForm from '../components/DetailForm'
import { usePost } from '../../../hooks/usePost'
import { SignInResponse } from '../../auth/api'
import { getCustomFields } from '../../finance/agent/api'

function NewDesignContainer () {
  const { id } = useParams()
  const detailContact = useGetDetailWithoutCall(getContactByAntexId, { params:{ antextId:id } })
  const contactDetail = prop('result', detailContact)
  const signIn = usePost(signInApi)
  const token = localStorage.getItem('antex-app-token')
  const customFieldsGet = useGetDetailWithoutCall(getCustomFields)
  const customFieldsList = propOr([], 'result', customFieldsGet)

  useEffect(() => {
    if (token) {
      detailContact.getDetail({ params:{ antexId:id } })
      customFieldsGet.getDetail({ params: { formType: 'CONTACT_FORM' } })
    } else {
      signIn.postData({ data: { email:'admintest@test.com', password:'Admin123' } })
        .then((response: SignInResponse) => {
          const accessToken = prop('accessToken', response)
          localStorage.setItem('antex-app-token', accessToken)
        }).then(() => {
          detailContact.getDetail({ params:{ antexId:id } })
          customFieldsGet.getDetail({ params: { formType: 'CONTACT_FORM' } })
        })
    }
  }, [])

  return (
    <LandingLayout hideLanguage={true}>
      {!detailContact.loading && !customFieldsGet.loading && (
        <DetailForm
          customFieldsList={customFieldsList}
          contactDetail={contactDetail}
        />
      )}
      {detailContact.loading && !customFieldsGet.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </LandingLayout>
  )
}

export default NewDesignContainer
