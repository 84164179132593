import * as API from 'constants/Api'

import React from 'react'
import { prop, propOr } from 'ramda'
import SearchField from 'components/form/SearchField'

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('id', value)
    const name = propOr('', 'name', value)
    return { id, name }
  }

  return null
}

function DriverField ({ ...props }: any) {
  return (
    <SearchField
      name="driverId"
      api={API.EmployeeSuggestionList}
      getOptionValue={getOptionValue}
      params={{ role:'ROLE_DRIVER' }}
      label="Водитель"
      {...props}
    />
  )
}

export default DriverField
