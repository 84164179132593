import React, { useCallback, useState } from 'react'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'

import ColumnListing from './components/ColumnListing'
import ColumnSettingCreate from './ColumnSettingCreate'
import ColumnSettingUpdate from './ColumnSettingUpdate'

import { deleteColumn, deleteColumns } from '../../api'
import { selectedRowSerializer } from '../../serializers'

interface Props {
  readonly filterList: any
  readonly onFilter: (item:any) => void
  readonly setOpen?: (value:boolean) => void
}

const ColumnSettingList = ({ setOpen, onFilter, filterList }:Props) => {
  const snackbar = useSnackbar()
  const onConfirm = useConfirm()
  const filterDelete = useDelete(deleteColumn)
  const filterDeleteSelected = useDelete(deleteColumns)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [id, setId] = useState(0)
  const [selectedRows, setSelectedRows] = useState<any>([])

  const onUpdate = useCallback((id:number) => {
    setId(id)
    setOpenUpdate(true)
  }, [])

  const onCreate = useCallback(() => {
    setOpenCreate(true)
  }, [])

  const onBack = () => {
    setOpenUpdate(false)
    setOpenCreate(false)
  }

  const handleFilter = (item:any) => {
    onFilter(item)
  }

  const onDelete = useCallback((id:number) => {
    filterDelete.deleteData({ params: { id } })
      .then(() => filterList.getList())
      .then(() => snackbar({ message: 'Колонок успешно обновлён' }))
  }, [])

  const handleDeleteSelected = useCallback(async () => {
    const message = `Вы уверены что хотите удалить выбранные колонок?`

    onConfirm({ message })
      .agree(() => {
        filterDeleteSelected.deleteData({ data: selectedRowSerializer(selectedRows) })
          .then(() => filterList.getList())
          .then(() => snackbar({ message: `${selectedRows.length} Колонок успешно удалён` }))
      })
      .disagree()
  }, [selectedRows, filterList])

  return (
    <>
      {(!openUpdate && !openCreate) && (
        <ColumnListing
          onClose={setOpen}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onDelete={onDelete}
          onDeleteSelected={handleDeleteSelected}
          onFilter={onFilter}
          onCreate={onCreate}
          onUpdate={onUpdate}
          initialValues={{}}
          onSubmit={() => null}
          dataRequest={filterList}
        />
      )}
      {openUpdate && (
        <ColumnSettingUpdate
          id={id}
          onBack={onBack}
          onFilter={handleFilter}
        />
      )}
      {openCreate && (
        <ColumnSettingCreate
          onBack={onBack}
          filterList={filterList}
        />
      )}
    </>
  )
}

export default ColumnSettingList
