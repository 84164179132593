import React, { ReactNode, useCallback } from 'react'
import { Grid, IconButton, styled, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { is, prop } from 'ramda'
import { useNavigate } from 'react-router-dom'
import CancelIcon from 'assets/cancel-icon.svg'
import { useTranslationI18n } from 'i18n/I18nContext'
import { useConfirm } from 'context/confirm'

import FlexBox from './FlexBox'

const RootGridStyled = styled(Grid, {
  shouldForwardProp: propName => propName !== 'isSticky'
})(({ theme, isSticky }: any) => ({
  height:'57.5px',
  width:'100%',
  background:'#FCFCFC',
  padding:'15px 0',
  flexWrap:'nowrap!important',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  '& > *': {
    padding: 0,
    alignItems: 'flex-end'
  },
  [theme.breakpoints.down('lg')]: {
    '& > *': {
      alignItems: 'flex-end'
    }
  },
  ...(isSticky && {
    paddingTop: 0,
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'flex-start',
      '& > *': {
        padding: 0
      }
    }
  })
})) as any

const LeftTitleGridStyled = styled(Grid, {
  shouldForwardProp: propName => propName !== 'isSticky'
})(({ theme, isSticky }: any) => ({
  ...(isSticky && {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  })
})) as any

const RightButtonStyled = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap:'8px',
  [theme.breakpoints.down('lg')]: {
    width: '100%'
  }
}))

interface Props {
  readonly onClose?:any
  readonly pageTitle: any
  readonly pageTitleLoading?: boolean
  readonly parentTitle?: any
  readonly rightButton?: ReactNode
  readonly isSticky?: boolean
  readonly form?:any
}

function PageTitle (props: Props) {
  const onConfirm = useConfirm()
  const navigate = useNavigate()
  const { form, pageTitle, isSticky = false } = props
  const isValuesChanges = prop('dirty', form)

  const { t } = useTranslationI18n()
  const title = is(String, pageTitle)
    ? t(pageTitle)
    : pageTitle

  const onCancelWithWarning = useCallback(() => {
    const message = `Вы уверены, что выйдете без сохранения изменений?`

    onConfirm({ message })
      .agree(() => {
        navigate(-1)
      })
      .disagree()
  }, [])

  const onCancel = () => {
    navigate(-1)
  }

  return (
    <RootGridStyled
      container={true}
      isSticky={isSticky}
    >
      <LeftTitleGridStyled item={true}>
        {!isSticky && (
          <Typography
            component="h2"
            variant="overline"
          >
            {t(props.parentTitle)}
          </Typography>
        )}
        <Typography component="div" variant="h5">
          {props.pageTitleLoading
            ? <Skeleton variant="text" width={300} />
            : <FlexBox align="center">
              <IconButton
                sx={{ padding:0 }}
                onClick={isValuesChanges ? onCancelWithWarning : (props.onClose || onCancel)}
              >
                <img src={CancelIcon} alt="Cance_Icon" />
              </IconButton>
              <Typography
                sx={{
                  fontSize:'18px',
                  lineHeight:'21.78px',
                  fontWeight:'700',
                  color:'#202020',
                  marginLeft:'10px'
                }}
              >
                {title}
              </Typography>
            </FlexBox>}
        </Typography>

      </LeftTitleGridStyled>
      <RightButtonStyled item={true}>
        {props.rightButton}
      </RightButtonStyled>
    </RootGridStyled>
  )
}

export default PageTitle
