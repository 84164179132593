import React from 'react'
import FlexBox from 'components/main/FlexBox'

export const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <FlexBox align="center" justify="flex-end" style={{ padding:'0 30px' }}>
      <p>© Antex 2021-{currentYear}</p>
    </FlexBox>
  )
}
