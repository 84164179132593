import { omit, path, pathOr, prop, propOr } from 'ramda'
import { toPayloadDate } from 'utils/dates'
import { toNumberFormat } from 'utils/formatMoney'

export const receiveCreateSerializer = (values: any) => {
  const receiveDate = prop('receiveDate', values)
  const expectedDate = prop('expectedDate', values)
  const warehouseId = path(['warehouseId', 'value'], values)
  const supplierId = path(['supplierId', 'value'], values)
  const priceLevelId = path(['priceLevelId', 'id'], values)
  const currency = path(['currency', 'code'], values)
  const oldItems = propOr([], 'items', values) as any
  const orderId = path(['orderId', 'value'], values)
  const items = oldItems.map((item:any) => {
    const price = toNumberFormat(prop('price', item))
    return {
      ...omit(['id', 'product', 'packages'], item),
      price,
      expectedQuantity: propOr(0, 'receivedQuantity', item),
    }
  })
  const territoryIds = propOr([], 'territories', values) as any
  const newTerritoryIds = territoryIds.map((item: any) => item.id)
  return {
    ...values,
    receiveDate: toPayloadDate(receiveDate),
    expectedDate: toPayloadDate(expectedDate),
    warehouseId,
    supplierId,
    items,
    orderId,
    territories: newTerritoryIds,
    currency,
    priceLevelId
  }
}
export const getReceiveUpdateInitialValues = (values: any) => {
  const warehouseId = pathOr('', ['warehouse', 'id'], values)
  const supplierId = pathOr('', ['supplier', 'id'], values)
  const territoryIds = propOr([], 'territories', values) as any
  const priceLevelId = prop('priceLevel', values)
  const oldItems = propOr([], 'items', values) as any
  const items = oldItems.map((item: any) => ({
    ...item,
    productId: pathOr('', ['product', 'id'], item)
  }))
  return { ...values, warehouseId, supplierId, items, territoryIds, priceLevelId }
}
