import React from 'react'
export const ExternalLinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#1557FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M24 17.333v8A2.667 2.667 0 0121.333 28H6.667A2.667 2.667 0 014 25.333V10.667A2.667 2.667 0 016.667 8h8M20
         4h8v8M13.333 18.667L28 4"
      />
    </svg>
  )
}
