import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'
import DoubleFormatField from 'components/form/DoubleFormatField'

import ConsignmentStatusField from './form/ConsignmentStatusField'
import ContactField from './form/ContactField'
import LineItems from './LineItems'
import CurrencyField from './form/CurrencyField'

interface Props {
  readonly form:any
}

function ConsignmentUpdateForm ({ form }:Props) {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать консигнация"
          rightButton={(
            <>
              <Button type="submit">Сохранить изменения</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={3}>
                    <TextField name="number" label="Привязанный номер" />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <DateField name="date" label="Дата" />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <ContactField name="contactId" />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <DoubleFormatField
                      label="Общая сумма консигнации"
                      name="total"
                    />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <CurrencyField form={form} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <ConsignmentStatusField />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(ConsignmentUpdateForm)
