import React, { useEffect } from 'react'
import { useField } from 'formik'

import { ComparatorValueFields } from './ComparatorValueFields'

import { getTranslatedColumn } from '../../../../translations'

export const FilterSearchField = (props:any) => {
  const { item, index, ...rest } = props
  const criteriaCodeType = useField(`criteria[${index}].code`)
  const criteriaCodeTypeSetField = criteriaCodeType[2].setValue

  useEffect(() => {
    criteriaCodeTypeSetField(item?.code)
  }, [])

  return (
    <ComparatorValueFields
      label={getTranslatedColumn(item?.code, item?.name)}
      name={`criteria[${index}].value`}
      item={item}
      {...rest}
    />
  )
}
