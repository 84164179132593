import * as ROUTES from 'constants/Routes'

import React from 'react'
import { useNavigate } from 'react-router'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Grid } from '@mui/material'
import { path } from 'ramda'

import MultipleUsersField from './form/UsersField'
import TerritoryTypeField from './form/TerritoryTypeField'

function TerritoryUpdateForm ({ form }:any) {
  const navigate = useNavigate()
  const name = path(['values', 'territoryName'], form)

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить территорию (${name})`}
          rightButton={(
            <>
              <Button type="button" onClick={() => navigate(ROUTES.SETTINGS_TERRITORY_LIST)}>Назад</Button>
              <Button type="submit">Сохранить изменения</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label="Название территории"
                      name="territoryName"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TerritoryTypeField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <MultipleUsersField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Примечания"
                      name="notes"
                      multiline={true}
                      minRows={4}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(TerritoryUpdateForm)
