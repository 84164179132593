import React, { ReactElement, ReactNode, useContext, useMemo, useState } from 'react'

import { I18nRussian } from './I18nRussian'
import { I18nUzbek } from './I18nUzbek'

export enum AppLanguage {
  Russian = 'ru',
  Uzbek = 'uz',
}

export interface I18nData {
  readonly language: AppLanguage;
}

export type TranslateFunction = (code: string) => string;

export function getLocalSchema (language: AppLanguage): any {
  if (language === AppLanguage.Russian) {
    return I18nRussian
  } else {
    return I18nUzbek
  }
}

export interface I18nContextProps {
  readonly t: TranslateFunction;
  readonly language: AppLanguage;
  readonly setLanguage: (value: AppLanguage) => void
}

export interface I18nProviderProps {
  data?: I18nData;
  children: ReactNode;
}

export const I18nContext = React.createContext<I18nContextProps>({
  t: () => '',
  language: localStorage.getItem('language') as AppLanguage,
  setLanguage: () => null
})

export function I18nProvider ({ data = {} as I18nData, children }: I18nProviderProps): ReactElement<object> {
  const [state, setState] = useState(data.language || AppLanguage.Russian)

  const schema = useMemo(() => getLocalSchema(state), [state])

  const setLanguage = (value: AppLanguage) => {
    setState(value)
    localStorage.setItem('language', value)
  }

  const translate = (code: string) => {
    const translation = schema[code]

    return translation == null ? code : translation
  }

  const value = {
    t: translate,
    language: state,
    setLanguage
  }

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>
}

export function useTranslationI18n (): I18nContextProps {
  return useContext(I18nContext)
}
