import React, { useCallback, useState } from 'react'
import { Box, Card, CardContent, Divider, Grid, IconButton, Skeleton } from '@mui/material'
import { path, prop } from 'ramda'
import useDialog from 'hooks/useDialog'
import { useField } from 'formik'
import { usePost } from 'hooks/usePost'
import { usePut } from 'hooks/usePut'
import { useSnackbar } from 'context/snackbar'

import Dropzone from './Dropzone'
import { productFileAttach, uploadFile, productImageDelete, productImagePrimary } from './api'
import { ImgCarousel } from './img-carousel/ImgCarousel'
import { ImagePreview } from './image-preview/ImagePreview'

import CardHeader from '../card/CardHeader'
import { DeleteIcon } from '../icons/DeleteIcon'
import CheckboxField from '../form/CheckboxField'

interface Props {
  readonly onImageSet?: any
  readonly images?: any
  readonly productId: any
  readonly onImageDelete?: any
}

function ImageUpload ({ onImageSet, images = [], productId, onImageDelete }: Props) {
  const carouselImageDialogue = useDialog()
  const imagePreviewDialogue = useDialog()
  const snackbar = useSnackbar()
  const fileUpload = usePost(uploadFile)
  const productAttachFile = usePost(productFileAttach)
  const productDeleteImage = usePost(productImageDelete)
  const productPrimaryImage = usePut(productImagePrimary)
  const defaultImage = useField('image')
  const defaultImageValue = defaultImage[0].value
  const [choosedImage, setChoosedImage] = useState(null)
  const [image, setImage] = useState('')
  const [data, setData] = useState(null) as any
  const [checkedImageId, setCheckedImageId] = useState(defaultImageValue?.id || null) as any

  const onDrop = useCallback((values: any = []) => {
    const data = new FormData()
    values.forEach((file: any) => {
      data.append('uploadFile', file)
    })
    setImage(URL.createObjectURL(values[0]))
    setData(data)
    imagePreviewDialogue.handleOpen()
  }, [])

  const onPrimary = useCallback((image:any) => {
    productPrimaryImage.putData({ params:{ imageId:image.id } })
      .then(() => snackbar({ message:'Выбранное изображение успешно обновлено в качестве основного' }))
      .then(() => setCheckedImageId(image.id))
      .then(() => defaultImage[2].setValue(image))
  }, [])

  const handleUpload = useCallback(() => {
    fileUpload.postData({ data }).then((response) => {
      const id = prop('id', response)
      productAttachFile.postData({ data: { productId, imageId: id } }).then((imageResponse) => {
        onImageSet(imageResponse)
      })
        .then(() => imagePreviewDialogue.handleClose())
        .then(() => setData(null))
    })
  }, [data])

  const handleClosePreview = () => {
    setData(null)
    setImage('')
    imagePreviewDialogue.handleClose()
  }

  const handleDelete = useCallback((id: any) => {
    productDeleteImage.postData({ params: { id } }).then(() => {
      onImageDelete(id)
    })
  }, [])

  const handleClick = (item:any) => {
    setChoosedImage(item)
    carouselImageDialogue.handleOpen()
  }

  return (
    <Card>
      <CardHeader title="Добавьте изображения для продукта." />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={2}>
            <Dropzone onDrop={onDrop} />
          </Grid>
          {images.map((item: any) => {
            const link = path(['medium', 'link'], item)
            const id = prop('id', item)
            const originalName = prop('originalName', item)
            return (
              <Grid item={true} lg={2} sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'flex-end',
                    justifyContent:'center',
                    width:'200px',
                    height:'200px',
                    background:'#F8F8F8',
                    borderRadius:'8px 8px 0 0',
                    position: 'relative'
                  }}
                >
                  <img
                    src={link}
                    alt={originalName}
                    onClick={() => handleClick(item)}
                    style={{
                      height: 150,
                      width:150,
                      borderRadius:'8px 8px 0 0',
                      objectFit: 'cover',
                      cursor:'pointer'
                    }}
                  />
                  <Box
                    sx={{
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'flex-end',
                      gap:'20px',
                      position:'absolute',
                      right:'25px',
                      top:'15px',
                      width:'150px',
                      height:'30px'
                    }}
                  >
                    <CheckboxField
                      color="white"
                      name="checked"
                      label="Основной"
                      checked={checkedImageId === id}
                      onClick={() => onPrimary(item)}
                    />
                    <IconButton onClick={() => handleDelete(id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            )
          })}
          {(productAttachFile.loading || fileUpload.loading) && (
            <Grid item={true} lg={3}>
              <Box
                sx={{
                  display:'flex',
                  alignItems:'flex-end',
                  justifyContent:'center',
                  width:'200px',
                  height:'200px',
                  background:'#F8F8F8',
                  borderRadius:'8px 8px 0 0',
                }}
              >
                <Skeleton variant="rectangular" width={150} height={150} sx={{ borderRadius:'8px 8px 0 0' }} />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Divider />
      {carouselImageDialogue.open && (
        <ImgCarousel
          onDefaultImage={onPrimary}
          defaultImageValue={defaultImageValue}
          image={choosedImage}
          images={images}
          onDelete={handleDelete}
          open={carouselImageDialogue.open}
          handleClose={carouselImageDialogue.handleClose}
        />
      )}
      {imagePreviewDialogue.open && (
        <ImagePreview
          image={image}
          onUpload={handleUpload}
          onClose={handleClosePreview}
          open={imagePreviewDialogue.open}
          handleClose={imagePreviewDialogue.handleClose}
        />
      )}
    </Card>
  )
}

export default ImageUpload
