import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { timeSlotGetById, timeSlotUpdate } from '../api'
import TimeSlotUpdateForm from '../components/TimeSlotUpdateForm'
import { timeSlotInitialValues, timeSlotUpdateSerializer } from '../serializer'

function TimeSlotUpdateContainer () {
  const snackbar = useSnackbar()
  const { id } = useParams()
  const navigate = useNavigate()
  const timeSlotDetail = useGetDetail(timeSlotGetById, { params: { id } })
  const updateTimeSlot = usePut(timeSlotUpdate)

  const handleSubmit = useCallback((values: any) => {
    return updateTimeSlot.putData({ data: timeSlotUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Интервал рабочего времени пользователя успешно обновлен' }))
      .then(() => navigate(ROUTES.SETTINGS_TIMESLOT_LIST))
  }, [])

  const initialValues = useMemo(() => timeSlotInitialValues(timeSlotDetail.result), [timeSlotDetail])

  return (
    <AppLayout>
      {!timeSlotDetail.loading && (
        <TimeSlotUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        />
      )}
      {timeSlotDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default TimeSlotUpdateContainer
