import React, { useEffect } from 'react'
import { Button, IconButton, Menu, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import FlexBox from 'components/main/FlexBox'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import { propOr } from 'ramda'
import cx from 'classnames'
import Box from '@mui/material/Box'

const useStyles = makeStyles({
  menuList:{
    '& .MuiList-root':{
      padding:'10px',
      display:'flex',
      flexDirection:'column',
      gap:'8px'
    }
  },
  item:{
    borderRadius:'5px',
    padding:'5px',
    cursor:'pointer',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    gap:'10px',
    '&.active':{
      background:'#f1f1f1'
    },
  },
  button:{
    textTransform:'initial',
    display:'flex',
  }
})

interface Props {
  readonly filterList:any
  readonly onDelete:any
  readonly onFilter:any
  readonly activeFilter:number
  readonly onFilterPrev:any
}

export const Filters = ({ filterList, onDelete, onFilter, onFilterPrev, activeFilter }:Props) => {
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null)
  const classes = useStyles()
  const anchorIsOpen = Boolean(optionsAnchorEl)
  const list = propOr([], 'list', filterList) as any
  const handleOpenOptions = (event: any) => {
    setOptionsAnchorEl(event.currentTarget)
  }

  const handleCloseOptions = () => {
    setOptionsAnchorEl(null)
  }

  useEffect(() => {
    filterList.getList()
  }, [])

  return (
    <React.Fragment>
      <Button
        aria-label="more"
        id="long-button"
        aria-controls={anchorIsOpen ? 'long-menu' : undefined}
        aria-expanded={anchorIsOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenOptions}
        variant="outlined"
        color="secondary"
        className={classes.button}
      >
        Шаблоны фильтра
      </Button>
      <Menu
        id="table-item-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={optionsAnchorEl}
        open={anchorIsOpen}
        onClose={handleCloseOptions}
        className={classes.menuList}
      >
        {list.map((filter:any) => {
          const isActive = filter.id === activeFilter
          return (
            <Box
              onClick={() => {
                onFilter(filter)
                onFilterPrev(filter)
              }}
              className={cx(classes.item, { active:isActive })}>
              <Typography variant="caption">
                {filter.name}
              </Typography>
              <FlexBox align="center" gap={5}>
                <Tooltip title="Удалить">
                  <IconButton onClick={() => onDelete(filter.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </FlexBox>
            </Box>
          )
        })}
      </Menu>
    </React.Fragment>
  )
}
