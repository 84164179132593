import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardContent, Divider, Grid } from '@mui/material'
import { useField } from 'formik'
import { useParams } from 'react-router-dom'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import SwitchField from 'components/form/SwitchField'
import { generateBarcode } from 'utils/barcode'
import ImageUpload from 'components/upload/ImageUpload'
import { useLocales } from 'hooks/useLocales'
import AttachmentUpload from 'components/upload/AttachmentUpload'

import CategoryField from './form/CategoryField'
import BrandField from './form/BrandField'
import UnitMeasurementField from './form/UnitMeasurementField'
import BarcodeField from './form/BarcodeField'
import PackageLineItems from './form/PackageLineItems'
import MultipleSupplierField from './form/MultipleSupplierField'
import CountryField from './form/CountryField'
import ProductTypeField from './form/ProductTypeField'
import { AvailableCustomField } from './custom-field/AvailableCustomField'
import ProducedInField from './ProducedInField'

interface Props {
  readonly packageList: any
  readonly customFields: any
  readonly form:any
}

function ProductUpdateFormGeneral ({ packageList, customFields, form }: Props) {
  const { id } = useParams()
  const { t } = useLocales('product')
  const imagesField = useField('images')
  const imagesFieldValues = imagesField[0].value || []
  const imagesFieldChange = imagesField[2].setValue
  const [images, setImages] = useState(imagesFieldValues || [] as any)

  const barcodeField = useField('barcode')
  const barcodeValue = barcodeField[0].value || []
  const barcodeChange = barcodeField[2].setValue

  const attachmentsField = useField('attachments')
  const attachmentsFieldValues = attachmentsField[0].value || []
  const attachmentsFieldChange = attachmentsField[2].setValue

  useEffect(() => {
    imagesFieldChange(images)
  }, [images])

  const handleImageSet = (value: any) => {
    setImages((prev: any) => [...prev, value])
  }

  const handleImageDelete = (id: any) => {
    setImages((prev: any) => prev.filter((img: any) => img.id !== id))
  }

  const handleAttachmentSet = (value: any) => {
    attachmentsFieldChange([...attachmentsFieldValues, value])
  }

  const handleAttachmentDelete = (id: any) => {
    attachmentsFieldChange(attachmentsFieldValues.filter((img: any) => img.id !== id))
  }

  const onGenerateBarcode = useCallback(() => {
    const newBarcode = generateBarcode()
    barcodeChange([...barcodeValue, newBarcode])
  }, [barcodeValue])

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} lg={12}>
        <Card>
          <CardHeader title={t('Detail')} />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Name')}
                  name="name"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Short name')}
                  name="shortName"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <BarcodeField
                  label="Штрих-код"
                  name="barcode"
                  buttonTitle={t('Generate')}
                  onGenerate={onGenerateBarcode}
                />
              </Grid>
              <Grid item={true} lg={4}>
                <TextField
                  label={t('Carton barcode')}
                  name="cartonBarcode"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Sku')}
                  name="sku"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Sort number')}
                  name="sortNumber"
                  type="number"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Pieces')}
                  name="pieces"
                  type="number"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <CategoryField
                  label={t('Categories')}
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <BrandField
                  label={t('Brand')}
                />
              </Grid>
              <Grid item={true} lg={4}>
                <UnitMeasurementField
                  label={t('Unit measurement')}
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <MultipleSupplierField
                  label={t('Suppliers')}
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <CountryField
                  label={t('Country')}
                  name="countryId"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <ProducedInField
                  form={form}
                  label={t('Produced in')}
                  name="producedIn"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <ProductTypeField
                  label={t('Product type')}
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Description')}
                  name="description"
                  multiline={true}
                  minRows={4}
                />
              </Grid>
              <Grid item={true} lg={12}>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <SwitchField
                      label={t('Enable expiry date tracking')}
                      name="enableExpiryDateTracking"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <SwitchField
                      label={t('Enable batch numbers')}
                      name="enableBatchNumbers"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <SwitchField
                      label={t('Sell in packages')}
                      name="sellInPackages"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </Grid>
      <Grid item={true} lg={12}>
        <ImageUpload
          onImageSet={handleImageSet}
          images={images}
          productId={id}
          onImageDelete={handleImageDelete}
        />
      </Grid>
      <Grid item={true} lg={12}>
        <AttachmentUpload
          onAddAttachment={handleAttachmentSet}
          attachments={attachmentsFieldValues}
          onDeleteAttachment={handleAttachmentDelete}
        />
      </Grid>
      <Grid item={true} lg={12}>
        <Card>
          <CardHeader title={t('Product size')} />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Weight')}
                  name="weight"
                  type="number"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Weight brutto')}
                  name="weightBrutto"
                  type="number"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Height')}
                  name="height"
                  type="number"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Length')}
                  name="length"
                  type="number"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Width')}
                  name="width"
                  type="number"
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('Volumetric size')}
                  name="volumetricSize"
                  type="number"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </Grid>
      <Grid item={true} lg={12}>
        <PackageLineItems packageList={packageList} />
      </Grid>
      <Grid item={true} lg={12}>
        <AvailableCustomField customFields={customFields} />
      </Grid>
    </Grid>
  )
}

export default ProductUpdateFormGeneral
