import * as API from 'constants/Api'

import { getRequest, Options, putRequest } from 'api/BaseApi'

export const getActiveColumnList = (options?: Options) => {
  return getRequest<any>(API.ColumnsGetById, options)
}

export const getAllTableColumns = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetByType, options)
}

export const updateTableColumns = (options?: Options) => {
  return putRequest<any>(API.ColumnsUpdate, options)
}
