import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { propOr, trim } from 'ramda'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'context/snackbar'
import { useDelete } from 'hooks/useDelete'

import { deleteVisitType, getVisitTypeList } from '../api'
import VisitTypeRowMenu from '../components/VisitTypeRowMenu'

const columns = [
  {
    headerName: 'Название страны',
    field: 'name',
  },
  {
    headerName: 'Примечания',
    field: 'notes',
  }
]

function TaskTypeListContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const countryList = useGetList(getVisitTypeList)
  const countryBulkDelete = useDelete(deleteVisitType)
  const [selectedRows, setSelectedRows] = useState([])

  const onDelete = useCallback((id:number) => {
    countryBulkDelete.deleteData({ data:{ id } })
      .then(() => countryList.getList())
      .then(() => snackbar({ message:'Тип задачи успешно удален' }))
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    countryList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Тип посещения"
        onAddClick={() => navigate(ROUTES.VISIT_VISIT_TYPE_CREATE)}
        dataRequest={countryList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkbox={true}
        actions={(row:any) => (
          <VisitTypeRowMenu
            item={row}
            onDelete={onDelete}
          />
        )}
      />
    </AppLayout>
  )
}

export default TaskTypeListContainer
