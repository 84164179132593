import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { useConfirm } from 'context/confirm'
import { useNavigate } from 'react-router'
import { pathOr, propOr, trim } from 'ramda'
import { usePut } from 'hooks/usePut'
import { useGetDetailWithoutCall } from 'hooks/useGetDetailWithoutCall'
import { usePatchList } from 'hooks/usePatchList'
import { useRoutes } from 'context/route'

import {
  bookSaleOrder,
  deleteSaleOrder, getColumnsList,
  getSaleOrderList,
  processSaleOrder,
  tabsCounts,
  getByIdTab,
  saleOrderFilterPreview
} from '../api'
import SaleOrderRowMenu from '../components/SaleOrderRowMenu'
import SaleOrderStatusRow from '../components/SaleOrderStatusRow'
import Filter from '../components/filter/Filter'
import { getColumnsBySetting } from '../components/SaleOrderListFilteredColumn'
import ColumnSettingList from '../components/column-settings/ColumnSettingList'
import TabsList from '../components/tabs/TabsList'
import { productFilterPreviewSerializer } from '../components/filter/serializer'
import MoreActionButtons from '../components/MoreRowMenu'
import SortingList from '../components/sorting/SortingList'

const columns = [
  {
    headerName: 'Дата заказа',
    field: 'orderDate',
    width: 300
  },
  {
    headerName: 'Номер заказа',
    field: 'soNumber',
    width: 200
  },
  {
    headerName: 'Клиент',
    field: 'contact',
    renderCell: pathOr('', ['contact', 'name'])
  },
  {
    headerName: 'Склад',
    field: 'warehouse',
    renderCell: pathOr('', ['warehouseGroup', 'name'])
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (item: any) => <SaleOrderStatusRow status={item.status} />
  },
  {
    headerName: 'Количество товаров',
    field: 'lineItemCount'
  }
]

function SaleOrderListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { isHasActiveTab } = useRoutes()
  const [tabId, setTabId] = useState('') as any
  const [limit, setLimit] = useState(10)
  const [filter, setFilter] = useState({})
  const [filterPreviewData, setFilterPreviewData] = useState([])
  const [settingColumns, setSettingColumns] = useState([])
  const [sorting, setSorting] = useState({}) as any
  const columnList = useGetList(getColumnsList, { query:{ type:'SALES_ORDER_LIST_PANEL' } })
  const tabList = useGetList(tabsCounts)
  const listingTabsRecords = useGetDetailWithoutCall(tabsCounts)
  const saleOrderList = useGetList(getSaleOrderList, { query: { limit, tabId } })
  const listingTabDetail = useGetDetailWithoutCall(getByIdTab)
  const saleOrderDelete = useDelete(deleteSaleOrder)
  const saleOrderBook = usePut(bookSaleOrder)
  const saleOrderProcess = usePut(processSaleOrder)
  const saleOrderFilterPreviewList = usePatchList(
    saleOrderFilterPreview,
    { data: productFilterPreviewSerializer(filterPreviewData) })
  const lastActiveTab = isHasActiveTab('salesOrder')
  const lastActiveTabId = propOr('', 'tabId', lastActiveTab) as number
  const listGet = filterPreviewData.length === 0 ? saleOrderList : saleOrderFilterPreviewList
  const tabs = propOr([], 'result', listingTabsRecords) as any

  useEffect(() => {
    const updateDetails = (id:number) => {
      listingTabDetail.getDetail({ params: { id } })
        .then((res) => {
          const settings = propOr({}, 'listingColumn', res) as any
          const filter = propOr({}, 'listingFilter', res) as any
          const sorting = propOr({}, 'listingSort', res)
          setFilter(filter)
          setSorting(sorting)
          setSettingColumns(settings)
        })
    }
    if (lastActiveTabId) {
      updateDetails(lastActiveTabId)
    }
  }, [tabId, lastActiveTabId])

  useEffect(() => {
    if (Object.keys(filterPreviewData).length > 0) {
      listGet.getList()
    }
  }, [filterPreviewData])

  const getColumns = useMemo(() => {
    const settingId = propOr('', 'id', settingColumns)
    if (settingId) {
      return getColumnsBySetting(settingColumns)
    } else return columns
  }, [settingColumns])

  const handleDeleteSaleOrder = useCallback((values: any) => {
    const message = `Вы уверены, что хотите удалить заказ ${values.soNumber}?`

    onConfirm({ message })
      .agree(() => {
        saleOrderDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Заказ успешно удалён' }))
          .then(() => saleOrderList.getList())
      })
      .disagree()
  }, [])

  const onBookOrder = useCallback((options: any) => {
    return saleOrderBook.putData(options)
      .then(() => snackbar({ message: 'Заказ успешно забронирован' }))
      .then(() => saleOrderList.getList())
  }, [])

  const onProcessOrder = useCallback((options: any) => {
    return saleOrderProcess.putData(options)
      .then(() => snackbar({ message: 'Статус заказа успешно обновлен' }))
      .then(() => saleOrderList.getList())
  }, [])

  const onFilter = useCallback((item: any) => {
    const limit = propOr(10, 'limitRow', item) as number
    setLimit(limit)
    setTabId(item.id)
    setFilter(item)
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    saleOrderList.getList({ query: { searchKey: search } })
  }, [])

  const onSetColumn = useCallback((item: any) => {
    setSettingColumns(item)
  }, [])

  const onFilterPreview = useCallback((filter:any) => {
    setFilterPreviewData(filter)
  }, [])

  const onSetTab = useCallback((tabId:number) => {
    setTabId(tabId)
  }, [])

  return (
    <AppLayout>
      <Table
        title="Заказы"
        onAddClick={() => navigate(ROUTES.SALE_ORDER_CREATE)}
        dataRequest={saleOrderList}
        tabRequest={listingTabsRecords}
        lastActiveTab={lastActiveTab}
        listingTableName="salesOrder"
        columns={getColumns}
        onSearchClick={onTableSearch}
        limit={limit}
        setLimit={setLimit}
        tabId={tabId}
        setTabId={setTabId}
        filter={filter}
        tabs={tabs}
        checkbox={true}
        actions={(row) => (
          <SaleOrderRowMenu
            item={row}
            onDelete={handleDeleteSaleOrder}
            onBook={onBookOrder}
            onProcess={onProcessOrder}
          />
        )}
        moreActions={(actions) => (
          <MoreActionButtons
            actions={actions}
          />
        )}
        filterNode={(
          <Filter
            filter={filter}
            onFilter={onFilter}
            onFilterPreview={onFilterPreview}
            filterPreviewData={filterPreviewData}
          />
        )}
        columnNode={(
          <ColumnSettingList
            filterList={columnList}
            onFilter={onSetColumn}
          />
        )}
        tabNode={(
          <TabsList
            filterList={tabList}
            onFilter={onSetTab}
          />
        )}
        sortingNode={(
          <SortingList
            sorting={sorting}
          />
        )}
      />
    </AppLayout>
  )
}

export default SaleOrderListContainer
