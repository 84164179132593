import React, { useCallback, useEffect, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import { pathOr, prop, propOr } from 'ramda'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Checkbox from '@mui/material/Checkbox'
import DialogActions from '@mui/material/DialogActions'
import { Button, DialogTitle } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useField } from 'formik'
import TableHead from '@mui/material/TableHead'
import { useTranslationI18n } from 'i18n/I18nContext'
import TextOverflow from 'components/main/TextOverflow'
import TableSkeletonList from 'components/table/TableSkeletonList'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'
import FlexBox from 'components/main/FlexBox'
import TextField from 'components/form/TextField'

import ListImage from '../ListImage'

const useStyles = makeStyles({
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: '750px',
    },
  },
  dialogContent: {
    padding: '16px',
    position: 'relative',
    maxHeight: '700px',
  },
  tableRoot: {
    height: '460px',
    borderRadius: '7px',
    border: '1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height: '36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    },
  },
  tableBody: {
    '& .MuiTableCell-root': {
      height: '46px',
      padding: '2px 16px',
      fontSize: '13px',
      lineHeight: '15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color: '#020020',
    },
    '& .MuiTableRow-root': {
      cursor: 'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important',
      },
    },
    '& .MuiTableRow-root:hover': {
      background: '#FAFAFA',
    },
  },
})

interface Props {
  readonly open: boolean
  readonly onClose: () => void
  readonly onAddVariants: (value: any) => void
}

function ConvertAddDialog ({ open, onClose, onAddVariants }: Props) {
  const { t } = useTranslationI18n()
  const classes = useStyles()

  const lineItemsField = useField('items')
  const lineItemsFieldValue = lineItemsField[0].value || []

  const [selected, setSelected] = useState([] as any)
  const [orderQuantities, setOrderQuantities] = useState<{ [key: string]: number }>({})

  const searchField = useField('search')
  const searchFieldValue = searchField[0].value

  const searchProductByName = (lineItems: any[], search: string) => {
    if (search) {
      return lineItems.filter(item =>
        item.product.name.toLowerCase().includes(search.toLowerCase())
      )
    }
    return lineItems
  }

  const filteredItems = searchProductByName(lineItemsFieldValue, searchFieldValue)

  const onEscape = useCallback((event: any) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }, [onClose])

  const handleCheck = (checked: boolean, variant: any) => {
    const id = prop('id', variant)
    if (checked) {
      setSelected((prevSelected: any) =>
        prevSelected.filter((select: any) => prop('id', select) !== id)
      )
    } else {
      setSelected((prevSelected: any) => [...prevSelected, variant])
    }
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog className={classes.dialog} open={open} fullWidth={true} onKeyDown={onEscape}>
      <DialogTitle sx={{ padding: '16px' }}>
        <Typography component="h2" variant="body1" sx={{ fontWeight: '700', fontSize: '20px' }}>
          {`${t('Просмотр продуктов')} - ${t('Отмечено')} (${selected.length})`}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} id="saleOrderVariantTable">
        <TableContainer className={classes.tableRoot}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell width={50}>{t('Пометить')}</TableCell>
                <TableCell width={250}>{t('Товар')}</TableCell>
                <TableCell width={50}>{t('Предзаказано')}</TableCell>
                <TableCell width={50}>{t('Осталось')}</TableCell>
                <TableCell width={50}>{t('Преобразованное количество')}</TableCell>
                <TableCell width={150}>{t('Заказать')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {filteredItems.map((variant: any, index: number) => {
                const id = prop('id', variant)
                const imageUrl = propOr(
                  'https://cdn3d.iconscout.com/3d/premium/thumb/product-5806313-4863042.png',
                  'imageUrl',
                  variant
                ) as string
                const checked = selected.find((select: any) => prop('id', select) === id)
                const [ orderQuantityField, , ] = useField(`items[${index}].orderQuantity`)

                useEffect(() => {
                  setOrderQuantities(prev => ({
                    ...prev,
                    [id]: orderQuantityField.value || 0
                  }))
                }, [orderQuantityField.value, id])

                return (
                  <TableRow key={id} className={cx({ 'selected': checked })}>
                    <TableCell
                      data-cy={`variant-${id}`}
                      onClick={() => handleCheck(checked, variant)}
                    >
                      <Checkbox checked={Boolean(checked)} color="secondary" />
                    </TableCell>
                    <TableCell>
                      <TextOverflow lines={1}>
                        <ListImage src={imageUrl} name={pathOr('', ['product', 'name'], variant)} />
                      </TextOverflow>
                    </TableCell>
                    <TableCell>
                      {propOr('Нет в наличии', 'quantity', variant)}
                    </TableCell>
                    <TableCell>
                      {propOr('Нет в наличии', 'remainingQuantity', variant)}
                    </TableCell>
                    <TableCell>
                      {propOr(0, 'convertedQuantity', variant)}
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`items[${index}].orderQuantity`}
                        type="number"
                        value={orderQuantities[id] || ''}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
              {!lineItemsFieldValue && (
                <TableSkeletonList columnsCount={3} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <FlexBox align="center" gap="8px">
          <Button
            color="secondary"
            variant="contained"
            onClick={handleClose}
            fullWidth={true}
          >
            Отменить
          </Button>
          <Button
            disabled={selected.length === 0}
            data-cy="add"
            variant="contained"
            fullWidth={true}
            onClick={() => onAddVariants(selected.map((item:any) => ({
              ...item,
              orderQuantity: orderQuantities[prop('id', item)] || 0
            })))}
          >
            Конвертировать
          </Button>
        </FlexBox>
      </DialogActions>
    </Dialog>
  )
}

export default ConvertAddDialog
