import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Grid } from '@mui/material'
import { FormikFormProps, FormikProps } from 'formik'
import { path } from 'ramda'
import { useLocales } from 'hooks/useLocales'

interface Props{
  readonly form:FormikProps<FormikFormProps>
}

function CurrencyUpdateForm ({ form }:Props) {
  const { t } = useLocales('currency')
  const name = path(['values', 'name'], form)
  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`${t('Update currency')} (${name})`}
          rightButton={(
            <>
              <Button
                type="submit"
                disabled={!form.isValid}
              >
                {t('Save changes')}
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title={t('Details')} />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label={t('Currency name')}
                      name="name"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label={t('Currency code')}
                      name="code"
                      disabled
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(CurrencyUpdateForm)
