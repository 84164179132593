import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid, IconButton,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import TextField from 'components/form/TextField'
import CardHeader from 'components/card/CardHeader'
import { FieldArray, useField } from 'formik'
import SkeletonList from 'components/skeleton/SkeletonList'
import { prop } from 'ramda'
import useDialog from 'hooks/useDialog'
import SwitchField from 'components/form/SwitchField'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import PackageAddDialog from '../PackageAddDialog'
import { useLocales } from '../../../../../hooks/useLocales'

export const useStyles = makeStyles({
  tableRoot:{
    maxHeight:'460px',
    borderRadius:'7px',
    border:'1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      cursor:'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly loading?: boolean
  readonly packageList: any
}

export function PackageLineItems ({ loading, packageList }: Props) {
  const variantAddDialogue = useDialog()
  const { t } = useLocales('product')
  const classes = useStyles()
  const lineItemsField = useField('packageLineItems')
  const searchField = useField('search')
  const setSearchValue = searchField[2]
  const lineItems = lineItemsField[0].value || [] as any
  const lineItemsChange = lineItemsField[2].setValue
  const error = lineItemsField[1]
  const defaultIndex = useMemo(() => {
    return lineItems.findIndex((item:any) => item.default === true)
  }, [])
  const [idChecked, setIdChecked] = useState(defaultIndex || null) as any

  useEffect(() => {
    setIdChecked(defaultIndex)
  }, [])

  const handleVariantsAdd = useCallback((packages: any) => {
    const lineItemPackageIds = new Set(lineItems.map((item: any) => prop('packageId', item)))

    const uniquePackages = packages.filter((item: any) => {
      const packageId = prop('packageId', item)
      return !lineItemPackageIds.has(packageId)
    })

    const allVariants = uniquePackages.map((item: any) => ({
      ...item,
      productId: 0,
      packageId: prop('packageId', item),
      quantity: 1
    }))

    const otherLineItems = lineItems.filter((lineItem: any) => {
      return packages.find((item: any) => prop('packageId', item) === prop('packageId', lineItem))
    })
    lineItemsChange([...otherLineItems, ...allVariants])
    setSearchValue.setValue('')
    variantAddDialogue.handleClose()
  }, [])

  return (
    <Card>
      <CardHeader title={t('Product package')} />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={10}>
            <TextField name="browse" />
          </Grid>
          <Grid item={true} lg={2}>
            <Button
              fullWidth={true}
              onClick={() => variantAddDialogue.handleOpen()}
            >
              {t('View packages')}
            </Button>
          </Grid>
          {variantAddDialogue.open && (
            <PackageAddDialog
              open={variantAddDialogue.open}
              onClose={variantAddDialogue.handleClose}
              onAddVariants={handleVariantsAdd}
              packageList={packageList}
            />
          )}
          <FieldArray name="packageLineItems">
            {(fields: any) => {
              const renderLineItems = Boolean(!loading && lineItems.length)
              return (
                <Grid item={true} lg={12}>
                  <TableContainer className={classes.tableRoot}>
                    <Table size="small" sx={{ width: '100%' }} stickyHeader aria-label="sticky table">
                      {renderLineItems && (
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            <TableCell width="30%">{t('Warehouse Group')}</TableCell>
                            <TableCell width="10%">{t('Quantity')}</TableCell>
                            <TableCell width="10%">{t('Default')}</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                      )}
                      <TableBody className={classes.tableBody}>
                        {loading && <SkeletonList columnCount={7} />}
                        {renderLineItems && lineItems.map((item: any, index: number) => {
                          const name = prop('name', item)
                          return (
                            <TableRow>
                              <TableCell>{name}</TableCell>
                              <TableCell>
                                <TextField name={`packageLineItems[${index}].quantity`} type="number" />
                              </TableCell>
                              <TableCell>
                                <SwitchField
                                  onClick={() => setIdChecked(index)}
                                  checked={idChecked === index}
                                  name={`packageLineItems[${index}].default`}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <IconButton onClick={() => fields.remove(index)} size="large">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {error.error && <Typography color="error" ml={2} py={2}>{error.error}</Typography>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            }}
          </FieldArray>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default PackageLineItems
