import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getFolderList = (options?: Options) => {
  return getRequest<any>(API.FolderList, options)
}

export const getFolderDetail = (options?: Options) => {
  return getRequest<any>(API.FolderDetail, options)
}

export const createFolder = (options?: Options) => {
  return postRequest<any>(API.FolderCreate, options)
}

export const updateFolder = (options?: Options) => {
  return putRequest<any>(API.FolderUpdate, options)
}

export const deleteFolder = (options?: Options) => {
  return deleteRequest<any>(API.FolderDelete, options)
}

export const getDocumentList = (options?: Options) => {
  return getRequest<any>(API.DocumentList, options)
}

export const createDocument = (options?: Options) => {
  return postRequest<any>(API.DocumentCreate, options)
}

export const updateDocument = (options?: Options) => {
  return putRequest<any>(API.DocumentUpdate, options)
}

export const documentDetail = (options?: Options) => {
  return getRequest<any>(API.DocumentDetail, options)
}

export const documentUpload = (options?: Options) => {
  return postRequest<any>(API.DocumentAttachmentPublicUpload, options)
}
export const documentDeleteUpload = (options?: Options) => {
  return deleteRequest<any>(API.DocumentAttachmentDelete, options)
}

export const documentDelete = (options?:Options) => {
  return deleteRequest(API.DocumentDelete, options)
}
