import { MenuItem } from '@mui/material'
import React from 'react'

interface Props{
  readonly handleDeleteSelected:() => void
}
function TableActionsRow ({ handleDeleteSelected }:Props) {
  return (
    <>
      <MenuItem onClick={handleDeleteSelected}>
        Удалить выбранное
      </MenuItem>
    </>
  )
}

export default TableActionsRow
