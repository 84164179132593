import * as ROUTES from 'constants/Routes'

import { styled, Table, TableBody, TableHead } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { prop } from 'ramda'
import React from 'react'
import { pathParams } from 'path-params'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

interface Props {
  readonly receiveList: any
}

const TableRowStyled = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    background: '#0000000A'
  }
})
const useStyles = makeStyles({
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    }
  }
})

function ReceiveTablePO ({ receiveList }: Props) {
  const navigate = useNavigate()
  const classes = useStyles()
  const handleClick = (id: number) => {
    const updatedURL = pathParams(ROUTES.FINANCE_PURCHASE_RECEIVE_DETAIL, { id: id })
    navigate(updatedURL)
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            Получить номер
          </TableCell>
          <TableCell>
            Дата получения
          </TableCell>
          <TableCell>
            Статус
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(receiveList) && receiveList.map((item: any) => {
          const receiveStatus = prop('receiveStatus', item)
          return (<TableRowStyled key={item.id} onClick={() => handleClick(item.id)}>
            <TableCell>
              {prop('receiveNumber', item)}
            </TableCell>
            <TableCell>
              {prop('receiveDate', item)}
            </TableCell>
            <TableCell>
              <div className={cx(classes.statusIndicator, { 'active': receiveStatus === 'COMPLETED' })}>
                {receiveStatus === 'COMPLETED' ? 'Черновик' : 'Завершенный'}
              </div>
            </TableCell>
          </TableRowStyled>)
        })}
      </TableBody>
    </Table>
  )
}

export default ReceiveTablePO
