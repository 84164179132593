import React from 'react'
import { path } from 'ramda'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'

import WarehouseField from './WarehouseField'
import SalesOrderField from './SalesOrderField'
import DriverField from './DriverField'
import PickerField from './PickerField'
import PackerField from './PackerField'

import ShipmentStatusField from '../../form/ShipmentStatusField'

const getFieldByType = (fieldType: string, lookUpType:string, rest: any) => {
  if (fieldType === 'LOOKUP' && lookUpType === 'WAREHOUSE') {
    return (
      <WarehouseField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'SALES_ORDER') {
    return (
      <SalesOrderField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'SHIPMENT_STATUS') {
    return (
      <ShipmentStatusField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'DRIVER') {
    return (
      <DriverField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'PICKER') {
    return (
      <PickerField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'PACKER') {
    return (
      <PackerField {...rest} />
    )
  }
  if (fieldType === 'DATE_TIME_PICKER') {
    return (
      <DateField
        label="Дата"
        {...rest}
      />
    )
  }
  if (fieldType === 'NUMBER_BOX') {
    return (
      <TextField
        label="Ценить"
        type="number"
        {...rest}
      />
    )
  }

  return (
    <TextField
      label="Значение поля"
      {...rest}
    />
  )
}

export const FilterComparatorValueField = (props: any) => {
  const { item, ...rest } = props
  const fieldType = path(['code', 'fieldType'], item)
  const lookUpType = path(['code', 'lookUpType'], item)
  return getFieldByType(fieldType, lookUpType, rest)
}
