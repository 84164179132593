import { omit, path, prop } from 'ramda'
import { toPayloadDate } from 'utils/dates'
import { parseStringToNumber, toNumber } from 'utils/formatMoney'

export const transferCashSerializer = (values:any) => {
  const date = prop('date', values)
  const toAccountId = path(['toAccountId', 'id'], values)
  const fromAccountId = path(['fromAccountId', 'id'], values)
  const currency = path(['currency', 'code'], values)
  const amount = parseStringToNumber(prop('amount', values))
  return { ...values, date:toPayloadDate(date), toAccountId, fromAccountId, currency, amount }
}

export const transferCashDetailSerializer = (values:any) => {
  const date = prop('date', values)
  const toAccountId = prop('toAccount', values)
  const fromAccountId = prop('fromAccount', values)
  const currency = prop('currency', values)
  return { ...values, date:toPayloadDate(date), toAccountId, fromAccountId, currency }
}

export const transferCashUpdateSerializer = (values:any) => {
  const date = prop('date', values)
  const toAccountId = path(['toAccountId', 'id'], values)
  const fromAccountId = path(['fromAccountId', 'id'], values)
  const currency = path(['currency', 'code'], values)
  const amount = toNumber(prop('amount', values))
  const result = omit(['fromAccount', 'toAccount'], values)
  return { ...result, date:toPayloadDate(date), toAccountId, fromAccountId, amount, currency }
}
