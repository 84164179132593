import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import AppLayout from 'components/main/AppLayout'
import Table from 'components/table/Table'
import { useUser } from 'context/user'
import { financialFormat } from 'utils/nomberFormats'
import { usePostList } from 'hooks/usePostList'
import { usePost } from 'hooks/usePost'
import useDialog from 'hooks/useDialog'
import { propOr } from 'ramda'

import { getAccountExcel, getChartOfAccountList } from '../api'
import MoreActionButtons from '../components/MoreRowMenu'
import ChartOfAccountStatusRow from '../components/ChartOfAccountStatusRow'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 300
  },
  {
    headerName: 'Название',
    field: 'name',
    width: 300
  },
  {
    headerName: 'Тип',
    field: 'type',
    renderCell:(row:any) => <ChartOfAccountStatusRow status={row.type} />
  },
  {
    headerName: 'Баланс в долларах',
    field: 'balanceUsd',
    width: 300,
    renderCell: (row: any) => financialFormat(row.balanceUsd)
  },
  {
    headerName: 'Баланс в сумах',
    field: 'balanceUzs',
    width: 300,
    renderCell: (row: any) => financialFormat(row.balanceUzs)
  },
  {
    headerName: 'Баланс в рублях',
    field: 'balanceRub',
    width: 300,
    renderCell: (row: any) => financialFormat(row.balanceRub)
  },
  {
    headerName: 'Баланс в фунтах стерлингов',
    field: 'balanceGbp',
    width: 300,
    renderCell: (row: any) => financialFormat(row.balanceGbp)
  },
  {
    headerName: 'Баланс в фунтах стерлингов',
    field: 'balanceKzt',
    width: 300,
    renderCell: (row: any) => financialFormat(row.balanceKzt)
  },
  {
    headerName: 'Баланс в юанях',
    field: 'balanceCny',
    width: 300,
    renderCell: (row: any) => financialFormat(row.balanceCny)
  },
]

function ChartOfAccountingListContainer () {
  const { user } = useUser()
  const accountExcelDialogue = useDialog()
  const chartOfAccountList = usePostList(
    getChartOfAccountList,
    { data: { companyId: user.userCompanyId } },
  )
  const accountGetExcel = usePost(getAccountExcel)
  const navigate = useNavigate()

  const handleGetExcel = useCallback(() => {
    accountGetExcel.postData({ responseType: 'blob' })
      .then((response:any) => {
        // Convert response to blob
        const blob = new Blob([response])

        // Create a download link
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', `ChartOfAccount.xlsx`)
        document.body.appendChild(link)
        link.click()

        // Cleanup
        window.URL.revokeObjectURL(link.href)
      })
      .then(() => accountExcelDialogue.handleClose())
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = propOr('', 'search', value)
    chartOfAccountList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Счета"
        onAddClick={() => navigate(ROUTES.FINANCE_CHART_OF_ACCOUNT_CREATE)}
        dataRequest={chartOfAccountList}
        columns={columns}
        onSearchClick={onTableSearch}
        checkbox={true}
        moreActions={() => <MoreActionButtons onExportExcel={handleGetExcel} />}
      />
    </AppLayout>
  )
}

export default ChartOfAccountingListContainer
