import React, { useCallback, useState } from 'react'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'

import SortingCreate from './SortingCreate'
import SortingUpdate from './SortingUpdate'
import SortListing from './components/SortListing'

import { deleteTab, deleteTabByIds } from '../../api'
import { selectedRowSerializer } from '../../serializer'

interface Props {
  readonly sorting:any
  readonly filterList: any
  readonly setOpen?: (value:boolean) => void
}

const SortingList = ({ setOpen, filterList, sorting }:Props) => {
  const snackbar = useSnackbar()
  const onConfirm = useConfirm()
  const filterDelete = useDelete(deleteTab)
  const filterDeleteSelected = useDelete(deleteTabByIds)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [id, setId] = useState(0)
  const [selectedRows, setSelectedRows] = useState<any>([])

  const onUpdate = useCallback((id:number) => {
    setId(id)
    setOpenUpdate(true)
  }, [])

  const onCreate = useCallback(() => {
    setOpenCreate(true)
  }, [])

  const onBack = () => {
    setOpenUpdate(false)
    setOpenCreate(false)
  }

  const onDelete = useCallback((id:number) => {
    filterDelete.deleteData({ params: { id } })
      .then(() => filterList.getList())
      .then(() => snackbar({ message: 'Вкладка успешно обновлён' }))
  }, [])

  const handleDeleteSelected = useCallback(async () => {
    const message = `Вы уверены что хотите удалить выбранные вкладка?`

    onConfirm({ message })
      .agree(() => {
        filterDeleteSelected.deleteData({ data: selectedRowSerializer(selectedRows) })
          .then(() => filterList.getList())
          .then(() => snackbar({ message: `${selectedRows.length} Вкладки успешно удалён` }))
      })
      .disagree()
  }, [selectedRows, filterList])

  return (
    <>
      {(!openUpdate && !openCreate) && (
        <SortListing
          activeTab={sorting.id}
          onClose={setOpen}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onDelete={onDelete}
          onDeleteSelected={handleDeleteSelected}
          // onFilter={onFilter}
          onCreate={onCreate}
          onUpdate={onUpdate}
          initialValues={{}}
          onSubmit={() => null}
          dataRequest={filterList}
        />
      )}
      {openUpdate && (
        <SortingUpdate
          id={id}
          onBack={onBack}
        />
      )}
      {openCreate && (
        <SortingCreate
          onBack={onBack}
          filterList={filterList}
        />
      )}
    </>
  )
}

export default SortingList
