import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'role', name: 'По роли' },
  { value: 'user', name: 'По сотрудникам' },
]

function ApproveByTypeField (props:any) {
  return (
    <SelectField
      name="approverType"
      label="Тип утверждающего лица"
      options={OPTIONS}
      defaultValue={OPTIONS[0].value}
      data-cy="status"
      {...props}
    />
  )
}

export default ApproveByTypeField
