import React, { ReactNode } from 'react'
import MuiCardHeader from '@mui/material/CardHeader'
import PropTypes from 'prop-types'
import { is } from 'ramda'
import { useTranslationI18n } from 'i18n/I18nContext'
import { makeStyles } from '@mui/styles'

interface Props {
  readonly title: any
  readonly required?: boolean
  readonly action?: ReactNode
}

const useStyles = makeStyles({
  title:{
    '& .MuiCardHeader-title':{
      fontWeight:'600',
      fontSize:'18px',
      lineHeight:'21.8px',
      color:'#202020'
    }
  }
})

function CardHeader ({ title, required, ...props }: Props) {
  const { t } = useTranslationI18n()
  const classes = useStyles()
  const translatedTitle = is(String, title) ? t(title) : title
  const requiredTitle = is(String, title) ? required ? translatedTitle + ' *' : translatedTitle : title

  return (
    <MuiCardHeader title={requiredTitle} className={classes.title} {...props} />
  )
}

CardHeader.propTypes = {
  title: PropTypes.any.isRequired,
  required: PropTypes.bool
}

export default CardHeader
