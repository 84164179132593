import { useEffect, useRef } from 'react'
import equal from 'fast-deep-equal'

const useDeepCompareMemoize = (value: any) => {
  const ref = useRef()

  if (!equal(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

const useDeepCompareEffect = (callback: () => void, dependencies: any) => {
  useEffect(callback, useDeepCompareMemoize(dependencies))
}

export default useDeepCompareEffect
