import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import BlankImage from 'assets/blank-image.svg'

interface ImageProps {
  readonly src?: string;
  readonly alt: string;
  readonly className?: string;
  readonly style?: React.CSSProperties
  readonly onClick?:() => void
}

export function LoadImage ({ src, alt, style, onClick, ...props }: ImageProps) {
  const [loading, setLoading] = useState(true)
  const [imgSrc, setImgSrc] = useState<string>(typeof src === 'string' ? src : BlankImage)

  useEffect(() => {
    setImgSrc(typeof src === 'string' ? src : BlankImage)
  }, [src])

  const handleLoad = () => {
    setLoading(false)
  }

  const handleError = () => {
    setImgSrc(BlankImage)
    setLoading(false)
  }

  return (
    <StyledImage
      loading="lazy"
      imageLoading={loading}
      src={imgSrc}
      onLoad={handleLoad}
      alt={alt}
      onError={handleError}
      style={style}
      onClick={onClick}
      {...props}
    />
  )
}

interface StyledImageProps {
  imageLoading: boolean
}

const StyledImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'imageLoading',
})<StyledImageProps>(({ imageLoading }) => ({
  filter: imageLoading ? 'blur(15px) grayscale(100%)' : 'blur(0) grayscale(0)',
  opacity: imageLoading ? 0.5 : 1,
  transform: imageLoading ? 'scale(1.1)' : 'scale(1)',
  transition: 'filter 0.5s ease, opacity 0.5s ease, transform 0.5s ease',
}))
