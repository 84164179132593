import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'

import { createCompany } from '../api'
import CompanyCreateForm, { CompanyForm } from '../components/CompanyCreateForm'
import { companyCreateSerializer } from '../serializer'

function CompanyCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const companyCreate = usePost(createCompany)

  const handleSubmit = useCallback((values: any) => {
    return companyCreate.postData(
      { data: companyCreateSerializer({ ...values, countryCode: 'UZ', stateCode: null }) as CompanyForm })
      .then((response) => {
        snackbar({ message: 'Компания успешно создана' })
        navigate(ROUTES.BACKEND_COMPANY_ADD_USER,
          { state:{ companyId:response.id, companyName:response.companyName } }
        )
      })
  }, [])

  return (
    <AppLayout>
      <CompanyCreateForm
        onSubmit={handleSubmit}
        initialValues={{} as CompanyForm}
        enableReinitialize={true}
      />
    </AppLayout>
  )
}

export default CompanyCreateContainer
