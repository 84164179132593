import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'ONLY_ME', name: 'Только я' },
  { value: 'SELECTED_USERS_OR_ROLES', name: 'Выбранный пользователь' },
  { value: 'EVERYONE', name: 'Все' }
]

function SortingVisibility () {
  return (
    <SelectField
      name="visibleTo"
      label="Видимость для"
      options={OPTIONS}
      data-cy="status"
    />
  )
}

export default SortingVisibility
