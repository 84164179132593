import React, { useEffect } from 'react'
import { prop } from 'ramda'
import { CardContent, Grid, IconButton, Typography } from '@mui/material'
import CardHeader from 'components/card/CardHeader'
import { FieldArray, useField } from 'formik'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import FlexBox from 'components/main/FlexBox'
import Box from '@mui/material/Box'

import { FilterSearchField } from './filter-components/FilterSearchField'
import FilterCriteriaSelectField from './form/FilterCriteriaSelectField'
import { CriteriaTypeSelectField } from './form/CriteriatypeSelectField'

export const CriteriaFields = () => {
  const [ fields, meta, helpers ] = useField('criteria')
  const criteriaValue = prop('value', fields)

  useEffect(() => {
    if (meta.initialValue) {
      helpers.setValue(meta.initialValue || [])
    }
  }, [])

  return (
    <>
      <CardHeader title="Параметры" />
      <CardContent>
        <FieldArray name="criteria">
          {({ remove }: any) => {
            return (
              <Grid container={true} spacing={3}>
                {criteriaValue && criteriaValue.map((item: any, index: number) => {
                  return (
                    <Grid item={true} lg={12} key={index}>
                      <FlexBox gap={5} align="center">
                        <Typography variant="body1">
                          {index + 1}.
                        </Typography>
                        <FilterSearchField
                          index={index}
                          item={item}
                        />
                        <Box sx={{ width:'40%' }}>
                          <CriteriaTypeSelectField
                            index={index}
                            type={item?.fieldType}
                          />
                        </Box>
                        <Box sx={{ width:'120px' }}>
                          <FilterCriteriaSelectField
                            name={`criteria[${index}].criteriaType`}
                          />
                        </Box>
                        <IconButton onClick={() => remove(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </FlexBox>
                    </Grid>
                  )
                })}
              </Grid>
            )
          }}
        </FieldArray>
      </CardContent>
    </>
  )
}
