import * as ROUTES from 'constants/Routes'

import * as Yup from 'yup'
import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import useScrollList from 'hooks/useScrollList'
import { usePost } from 'hooks/usePost'
import { usePut } from 'hooks/usePut'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'

import ReceiveCreateForm from '../components/ReceiveCreateForm'
import { receiveCreateSerializer } from '../serializers'
import { completeReceive, getProductList, receiveCreate } from '../api'

function ReceiveCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const productList = useScrollList(getProductList)
  const createReceive = usePost(receiveCreate)
  const receiveCompleate = usePut(completeReceive)

  const handleSubmit = useCallback((values: any) =>
    createReceive.postData({ data: receiveCreateSerializer(values) })
      .then(() => navigate(ROUTES.WAREHOUSE_RECEIVE_LIST))
      .then(() => snackbar({ message: 'Приход успешно создано' }))
  , [])

  const handleComplete = useCallback((values: any) => {
    createReceive.postData({ data: receiveCreateSerializer(values) })
      .then((response:any) => receiveCompleate.putData({ params: { id:response.id } })
        .then(() => navigate(ROUTES.WAREHOUSE_RECEIVE_LIST))
        .then(() => snackbar({ message: 'Завершенный приход успешно создан.' })))
  }, [])

  const validationSchema = Yup.object({
    territories: Yup.array().min(1, 'Требуется территория.')
      .required('Обязательный'),
  })

  return (
    <AppLayout>
      <ReceiveCreateForm
        initialValues={{ territories:[], priceLevelId:null, supplierId:null, warehouseId:null }}
        onSave={handleSubmit}
        productList={productList}
        onComplete={handleComplete}
        validationSchema={validationSchema}
      />
    </AppLayout>
  )
}

export default ReceiveCreateContainer
