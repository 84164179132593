import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest } from 'api/BaseApi'

export const getPaymentList = (options?: Options) => {
  return getRequest<any>(API.PaymentList, options)
}

export const getPaymentDetail = (options?: Options) => {
  return getRequest<any>(API.PaymentDetail, options)
}

export const createPayment = (options?: Options) => {
  return postRequest<any>(API.PaymentCreate, options)
}

export const changeStatus = (options?: Options) => {
  return patchRequest<any>(API.PaymentChangeStatus, options)
}

export const deletePayment = (options?: Options) => {
  return deleteRequest<any>(API.PaymentDelete, options)
}

export const getExcelPayment = (options?: Options) => {
  return getRequest<any>(API.PaymentListExcel, options)
}

export const getCustomFields = (options?: Options) => {
  return getRequest<any>(API.CustomFieldsGetByFormType, options)
}
