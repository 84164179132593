import React, { useEffect } from 'react'
import { useField } from 'formik'

import { ComparatorValueFields } from './ComparatorValueFields'

const columnTranslations: { [key: string]: string } = {
  'NAME': 'Название посещение',
  'TYPE': 'Тип посещения',
  'STATUS': 'Статус',
  'PLANNED_DATE': 'Планируемая дата',
  'ACTUAL_DATE': 'Дата посещения',
  'USER': 'Пользователь',
  'DURATION': 'Продолжительность',
  'CUSTOMER': 'Клиент',
  'NOTE': 'Заметка',
  'DELETED': 'Удалено',
  'PREDECESSOR': 'Предшественник',
  'SUCCESSOR': 'Преемник',
  'TIMES': 'Время посещения',
  'TERRITORIES': 'Территория'
}

const getTranslatedColumn = (code: string, name:string): string | undefined => {
  return columnTranslations[code] || name
}

export const FilterSearchField = (props:any) => {
  const { item, index, ...rest } = props
  const criteriaCodeType = useField(`criteria[${index}].code`)
  const criteriaCodeTypeSetField = criteriaCodeType[2].setValue

  useEffect(() => {
    criteriaCodeTypeSetField(item?.code)
  }, [])

  return (
    <ComparatorValueFields
      label={getTranslatedColumn(item?.code, item?.name)}
      name={`criteria[${index}].value`}
      item={item}
      {...rest}
    />
  )
}
