import * as API from 'constants/Api'

import React from 'react'
import SearchField from 'components/form/SearchField'
import { propOr, prop } from 'ramda'

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('id', value)
    const name = propOr('', 'name', value)
    return { id, name }
  }

  return null
}

function PickerField (props: any) {
  return (
    <SearchField
      name="pickerId"
      api={API.EmployeeSuggestionList}
      getOptionValue={getOptionValue}
      label="Сборщик"
      {...props}
    />
  )
}

export default PickerField
