import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'
import { useGetDetail } from 'hooks/useGetDetail'
import { useParams } from 'react-router-dom'
import { usePut } from 'hooks/usePut'

import { getVisitTypeById, updateVisitType } from '../api'
import VisitTypeUpdateFrom from '../components/VisitTypeUpdateForm'

function TaskTypeUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const visitTypeDetail = useGetDetail(getVisitTypeById, { params:{ id } })
  const visitTypeUpdate = usePut(updateVisitType)

  const onSubmit = useCallback((data: any) => {
    return visitTypeUpdate.putData({ data })
      .then(() => navigate(ROUTES.VISIT_VISIT_TYPE_LIST))
      .then(() => snackbar({ message: 'Тип достижения успешно обновлен' }))
  }, [])

  return (
    <AppLayout>
      {!visitTypeDetail.loading && (
        <VisitTypeUpdateFrom
          onSubmit={onSubmit}
          reinitialize={true}
          initialValues={visitTypeDetail.result}
        />
      )}
    </AppLayout>
  )
}

export default TaskTypeUpdateContainer
