import React from 'react'
import { prop } from 'ramda'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'

import { FILTER_FIELD_TYPE } from '../../../constants'
import WarehouseField from '../form/WarehouseField'
import SalesOrderField from '../form/SalesOrderField'
import ShipmentStatusField from '../../form/ShipmentStatusField'
import DriverField from '../form/DriverField'
import PickerField from '../form/PickerField'
import PackerField from '../form/PackerField'

const getFieldByType = (fieldType: string, lookUpType:string, name:string, rest: any) => {
  if (fieldType === 'LOOKUP' && lookUpType === 'WAREHOUSE') {
    return (
      <WarehouseField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'SALES_ORDER') {
    return (
      <SalesOrderField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'SHIPMENT_STATUS') {
    return (
      <ShipmentStatusField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'DRIVER') {
    return (
      <DriverField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'PICKER') {
    return (
      <PickerField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'PACKER') {
    return (
      <PackerField {...rest} />
    )
  }
  if (fieldType === FILTER_FIELD_TYPE.DATE_TIME_PICKER) {
    return (
      <DateField
        label="Дата"
        {...rest}
      />
    )
  }
  if (fieldType === FILTER_FIELD_TYPE.NUMBER_BOX) {
    return (
      <TextField
        label="Ценить"
        type="number"
        {...rest}
      />
    )
  }

  return (
    <TextField
      label="Значение поля"
      {...rest}
    />
  )
}

export const ComparatorValueFields = (props: any) => {
  const { item, ...rest } = props
  const fieldType = prop('fieldType', item)
  const lookUpType = prop('lookUpType', item)
  const name = prop('name', item)
  return getFieldByType(fieldType, lookUpType, name, rest)
}
