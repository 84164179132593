import React from 'react'

function MenuIcon (props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={37}
      height={18}
      fill="none"
      {...props}
    >
      <circle cx={4} cy={4} r={4} fill="#1657FD" />
      <circle cx={4} cy={14} r={4} fill="#202020" />
      <rect width={27} height={8} x={10} fill="url(#a)" rx={4} />
      <rect width={21} height={8} x={10} y={10} fill="url(#b)" rx={4} />
      <defs>
        <linearGradient
          id="a"
          x1={10.818}
          x2={34.955}
          y1={4}
          y2={4}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7D7D7" />
          <stop offset={1} stopColor="#D7D7D7" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="b"
          x1={10.636}
          x2={29.409}
          y1={14}
          y2={14}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7D7D7" />
          <stop offset={1} stopColor="#D7D7D7" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default MenuIcon
