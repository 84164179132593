import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import AppLayout from 'components/main/AppLayout'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'context/snackbar'

import { updateReceive, getReceiveDetail, completeReceive } from '../api'
import ReceiveUpdateForm from '../components/ReceiveUpdateForm'
import { receiveCreateSerializer, getReceiveUpdateInitialValues } from '../serializers'

function PurchaseUpdateContainer () {
  const { id } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()

  const receiveUpdate = usePut(updateReceive)
  const receiveDetail = useGetDetail(getReceiveDetail, { params: { receiveId: id } })
  const receiveComplete = usePut(completeReceive)

  const [receiveStatus, setStatusReceive] = useState(false)

  const handleSubmit = useCallback((values: any) => {
    return receiveUpdate.putData({ data: receiveCreateSerializer(values) })
      .then(() => snackbar({ message: 'Приход успешно обновлено' }))
      .then(() => setStatusReceive(true))
  }, [])

  const onCompleteReceive = useCallback((id: number) => {
    return receiveComplete.putData({ params: { id } })
      .then(() => snackbar({ message: 'Приход успешно завершён' }))
      .then(() => navigate(ROUTES.WAREHOUSE_RECEIVE_LIST))
  }, [])

  return (
    <AppLayout>
      {!receiveDetail.loading && (
        <ReceiveUpdateForm
          onSubmit={handleSubmit}
          initialValues={getReceiveUpdateInitialValues(receiveDetail.result)}
          receiveStatus={receiveStatus}
          handleComplete={onCompleteReceive}
          loading={receiveUpdate.loading}
          id={id}
        />
      )}
      {receiveDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default PurchaseUpdateContainer
