import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import FlexBox from 'components/main/FlexBox'
import AddIcon from 'assets/add-icon.svg'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { path, pathOr, prop, propOr } from 'ramda'
import cx from 'classnames'
import { Form, Formik } from 'formik'

import TableAttachHeaderButtons from './TableHeaderButtons'
import { tableAttachStyles } from './styles/TableAttachStyles'
import TableHeaderTabs from './TableHeaderTabs'

import TableFilterPopup from '../table/TableFilterSidePopup'
import TableExportSidePopup from '../table/TableExportSidePopup'
import useAllSearchParams from '../../utils/useAllSearchParams'
import { DEFAULT_PAGE_NUMBER } from '../../hooks/useGetList'
import TextField from '../form/TextField'
import SearchIcon from '../icons/SearchIcon'
import ClearIcon from '../icons/ClearIcon'

interface TableListProps {
  readonly headerName: string
  readonly sortable?: boolean
  readonly field: string
  readonly width?: number | string
  readonly valueGetter?: any
  readonly renderCell?: any
  readonly leftStick?:boolean
  readonly rightStick?:boolean
}

interface Props {
  readonly columns: TableListProps[]
  readonly onAddClick?: () => void
  readonly tabs?: any[]
  readonly className?: string
  readonly title?: ReactNode
  readonly onSearchClick?: (values:any) => void
  readonly checkbox?: boolean
  readonly primaryKey?: string
  readonly withoutBar?: boolean
  readonly actions?: (row: any, index: number) => ReactNode
  readonly dataRequest: any
  readonly filterRequest?:any
  readonly filterNode?: ReactNode
  readonly exportNode?: ReactNode
  readonly moreActions?: (onOpen:() => void) => ReactNode
  readonly selectedRows?: any
  readonly setSelectedRows?: any
  readonly limit?: number
  readonly setLimit?: (limit: number) => void
  readonly status?: string
  readonly setStatus?: (status: string) => void
  readonly setFilter?: (status: any) => void
  readonly onImport?: (values: any) => void
  readonly filter?:any
  readonly selectedList:any
  readonly hasChange:boolean
  readonly stateItems:any[]
  readonly columnNode?:ReactNode
}

function TableAttach (props: Props) {
  const {
    columns,
    onAddClick,
    className,
    onSearchClick,
    withoutBar = false,
    tabs,
    title,
    checkbox,
    primaryKey = 'id',
    actions,
    dataRequest,
    filterRequest,
    filterNode,
    exportNode,
    moreActions,
    selectedRows = [],
    setSelectedRows = () => null,
    status,
    setStatus = () => null,
    limit = 10,
    setLimit = () => null,
    setFilter = () => null,
    onImport,
    filter,
    selectedList,
    hasChange,
    stateItems,
    columnNode
  } = props
  const navigate = useNavigate()
  const searchParams = useAllSearchParams()
  const list = propOr(selectedList, 'list', dataRequest) as any
  const data = prop('result', dataRequest) as any
  const listGet = prop('getList', dataRequest) as any
  const loading = prop('loading', dataRequest) as boolean
  const filterGet = prop('getList', filterRequest) as any
  const [openColumnSettings, setOpenColumnSettings] = useState(false)
  const [openFilterPopup, setOpenFilterPopup] = useState(false)
  const [openExportPopup, setOpenExportPopup] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const [start, setStart] = useState(0)
  const areTabsObjects = tabs?.every(tab => typeof tab === 'object' && tab !== null)
  const page = Math.ceil(start / limit) + 1
  const totalItems = propOr(0, 'count', data) as number || propOr(0, 'totalElements', data) as number
  const currentItemsCount = list.length as number
  const totalPages = Math.ceil(totalItems / limit)
  const hasSearchKey = path(['query', 'searchKey'], dataRequest)
  const nextPageEnable = page !== totalPages
  const previousPageEnabled = page !== 1
  const isHasOrderNumber = columns[0].field === 'orderNumber'
  const isTitleStr = typeof title === 'string'
  const classes = tableAttachStyles()

  useEffect(() => {
    const paramsLimit = prop('limit', searchParams) || limit
    const paramsStart = prop('start', searchParams) || DEFAULT_PAGE_NUMBER
    setLimit(Number(paramsLimit))
    setStart(Number(paramsStart))
  }, [searchParams, limit])

  const onClickSelectRow = useCallback((item: any) => {
    const rowPrimaryKey = prop(primaryKey, item)
    setSelectedRows((prev: any) => {
      const checkedItem = prev.find((item: any) => prop(primaryKey, item) === rowPrimaryKey)
      if (checkedItem) {
        return prev.filter((item: any) => prop(primaryKey, item) !== rowPrimaryKey)
      } else {
        return [...prev, item]
      }
    })
  }, [])

  const onSelectAllRows = useCallback(() => {
    if (list.length === selectedRows.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(list)
    }
  }, [list, selectedRows, primaryKey])

  const columnLength = useMemo(() => {
    let length = columns.length

    if (checkbox) {
      length++
    }

    if (actions) {
      length++
    }

    return length
  }, [])

  const itemsOfPage = useMemo(() => {
    if (page === DEFAULT_PAGE_NUMBER) {
      return `1-${currentItemsCount}`
    } else if (page === totalPages) {
      return `${limit * (page - 1) + 1} - ${totalItems}`
    } else {
      return `${limit * (page - 1) + 1} - ${limit * page}`
    }
  }, [currentItemsCount, limit, totalPages, page])

  const mappedStrings = useMemo(() => {
    const [start, end] = itemsOfPage.split(' - ').map(Number)

    if (isNaN(start) || isNaN(end) || start > end) {
      return []
    }

    return Array.from({ length: end - start + 1 }, (_, index) => String(start + index))
  }, [itemsOfPage])

  useEffect(() => {
    if (filterRequest) {
      filterGet()
        .then((response:any) => {
          const list = propOr([], 'list', response) as any
          const defaultFilter = list.find((filterField: { default: boolean }) => filterField.default)
          const filterId = prop('id', defaultFilter)
          const limit = propOr(10, 'limitRow', defaultFilter) as number
          setStatus(filterId)
          setFilter(defaultFilter)
          setLimit(limit)
        })
    }
  }, [])

  useEffect(() => {
    if (status) {
      if (page && limit) {
        listGet && listGet({ query: { start, limit, filterId:status } })
      }
    } if (!filter) {
      listGet && listGet({ query: { start, limit } })
    }
  }, [start, limit, page, status, filter, hasChange])

  const handleClickPreviousPage = useCallback(() => {
    const previousPage = start - limit
    const filterId = propOr('', 'id', filter)
    setStart(previousPage)
    navigate({
      pathname: location.pathname,
      search: `?start=${previousPage}&limit=${limit}&filterId=${filterId}`
    }, { state: stateItems })
  }, [start, limit, filter])

  const handleClickNextPage = useCallback(() => {
    const nextPage = start + limit
    const filterId = propOr('', 'id', filter)
    setStart(nextPage)
    navigate({
      pathname: location.pathname,
      search: `?start=${nextPage}&limit=${limit}&filterId=${filterId}`
    }, { state: stateItems })
  }, [start, limit, filter])

  const handleReset = (values: any, { setValues }: any) => {
    const updatedValues = { ...values, search: '' }
    setValues(updatedValues)

    if (onSearchClick) {
      onSearchClick(updatedValues)
    }

    setOpenSearch(false)
  }

  const handleCancel = () => {
    setOpenSearch(false)
  }

  const onRefresh = () => {
    setOpenSearch(false)
    listGet()
  }

  const handleChangeLimit = (event: any) => {
    const value = pathOr(10, ['target', 'value'], event)
    const filterId = propOr('', 'id', filter)
    setLimit(value)
    navigate({ pathname: location.pathname, search: `?start=${start}&limit=${value}&filterId=${filterId}` })
  }

  const headerCellStyles = { position: 'sticky', top: 0, background: '#f1f1f1', zIndex: 1 }
  const menuItemsLimit = [10, 25, 50, 100, 500, 1000]
  return (
    <>
      <FlexBox className={className || classes.tableWrapper} direction="column" flex={true}>
        {!withoutBar && (
          <>
            <FlexBox align="center" className={classes.tableBarRoot}>
              <FlexBox gap={15} className={classes.addButtonRoot}>
                {isTitleStr ? (
                  <Typography>
                    {title}
                  </Typography>) : title}
                {onAddClick && (
                  <FlexBox justify="center" align="center" className={classes.addButton}>
                    <img src={AddIcon} alt="logo" onClick={onAddClick} data-cy="create" />
                  </FlexBox>
                )}
              </FlexBox>
              {(openSearch && onSearchClick) &&
                <FlexBox align="center" className={classes.searchBox}>
                  <Formik initialValues={{}} onSubmit={onSearchClick} onReset={handleReset}>
                    {(values:any) => {
                      const search = path(['values', 'search'], values)
                      return (
                        <Form>
                          <FlexBox justify="center" align="center" className={classes.searchInput}>
                            <IconButton type="submit">
                              <SearchIcon />
                            </IconButton>
                            <TextField
                              autoFocus={true}
                              name="search"
                              placeholder="Поиск по названию, номеру, email, контактному лицу"
                            />
                            {search
                              ? <IconButton type={'reset'}>
                                <ClearIcon />
                              </IconButton>
                              : <IconButton type={'button'} onClick={handleCancel}>
                                <ClearIcon />
                              </IconButton>
                            }
                          </FlexBox>
                        </Form>
                      )
                    }}
                  </Formik>
                </FlexBox>
              }
              {(areTabsObjects && !openSearch) && (
                <TableHeaderTabs
                  tabs={tabs}
                  filter={filter}
                  setStatus={setStatus}
                />
              )}
              {!openSearch && (
                <FlexBox justify="flex-end" align="center" flex gap={15}>
                  <TableAttachHeaderButtons
                    setOpenSearch={setOpenSearch}
                    onRefreshClick={onRefresh}
                    onTabSettingsClick={() => setOpenColumnSettings(true)}
                    onFilterClick={() => setOpenFilterPopup(true)}
                    onExportClick={() => setOpenExportPopup(true)}
                    refreshDisabled={loading}
                    moreActions={moreActions}
                  />
                  <Select
                    value={limit}
                    onChange={handleChangeLimit}
                    size="small"
                    variant="standard"
                  >
                    {menuItemsLimit.includes(limit) ? null : (
                      <MenuItem value={limit}>{limit}</MenuItem>
                    )}
                    {menuItemsLimit.map((item:number, index:number) => {
                      return (
                        <MenuItem
                          key={index}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FlexBox align="center" className={classes.paginationRoot}>
                    <Typography>
                      <strong>{itemsOfPage}</strong>{` из ${totalItems}`}
                    </Typography>
                    <FlexBox align="center">
                      <IconButton disabled={!previousPageEnabled} onClick={handleClickPreviousPage}>
                        <NavigateBeforeIcon className={classes.paginationButton} />
                      </IconButton>
                      <Typography>{page}</Typography>
                      <IconButton disabled={!nextPageEnable} onClick={handleClickNextPage}>
                        <NavigateNextIcon className={classes.paginationButton} />
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              )}
            </FlexBox>
            {(openSearch && !loading && list && hasSearchKey && hasSearchKey.length > 0) && (
              <FlexBox className={classes.tableBarRoot}>
                <FlexBox className={classes.addButtonRoot}>
                  <Typography>
                    {`${list.length} Результатов по запросу `}
                  </Typography>
                </FlexBox>
                <FlexBox justify="flex-end" align="center" flex gap={15}>
                  <TableAttachHeaderButtons
                    setOpenSearch={setOpenSearch}
                    onRefreshClick={onRefresh}
                    onTabSettingsClick={() => setOpenColumnSettings(true)}
                    onFilterClick={() => setOpenFilterPopup(true)}
                    onExportClick={() => setOpenExportPopup(true)}
                    refreshDisabled={loading}
                    moreActions={moreActions}
                  />
                  <FlexBox align="center" className={classes.paginationRoot}>
                    <Typography>
                      <strong>{itemsOfPage}</strong>{` of ${totalItems}`}
                    </Typography>
                    <FlexBox align="center">
                      <IconButton disabled={!previousPageEnabled} onClick={handleClickPreviousPage}>
                        <NavigateBeforeIcon className={classes.paginationButton} />
                      </IconButton>
                      <Typography>{page}</Typography>
                      <IconButton disabled={!nextPageEnable} onClick={handleClickNextPage}>
                        <NavigateNextIcon className={classes.paginationButton} />
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
          </>
        )}
        <TableContainer className={classes.tableRoot} component={Paper}>
          <MuiTable sx={{ width: '100%' }} size="small" aria-label="sticky table">
            <TableHead className={classes.tableHeader}>
              <TableRow sx={headerCellStyles}>
                {checkbox && (
                  <TableCell width={45} align="right" sx={headerCellStyles}>
                    <Checkbox
                      className={classes.checkboxHeader}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      data-cy="table-checkbox-all"
                      onChange={onSelectAllRows}
                      checked={list.length === selectedRows.length}
                    />
                  </TableCell>
                )}
                {actions && (
                  <TableCell width={30} sx={headerCellStyles}>
                    <div className={cx(classes.selectedCount, { 'active': selectedRows.length > 0 })}>
                      {selectedRows.length}
                    </div>
                  </TableCell>
                )}
                {isHasOrderNumber && (
                  <TableCell width={30} sx={headerCellStyles}>
                    №
                  </TableCell>
                )}
                {columns.map(column => {
                  const isHasOrderNumber = column.field === 'orderNumber' ? null : column.headerName
                  const leftStick = prop('leftStick', column)
                  const rightStick = prop('rightStick', column)
                  const left = leftStick ? 0 : 'unset'
                  const right = rightStick ? 0 : 'unset'
                  const position = (leftStick || rightStick) ? 'sticky' : 'unset'
                  const styles = { ...headerCellStyles, position, left, right }

                  return (
                    <>
                      {isHasOrderNumber && (
                        <TableCell
                          key={Array.isArray(column.field) ? column.field[1] : column.field}
                          width={column.width}
                          sx={styles}
                        >
                          {isHasOrderNumber}
                        </TableCell>
                      )}
                    </>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {!loading && list.map((item: any, index: number) => {
                const rowPrimaryKey = prop(primaryKey, item)
                const isSelected = selectedRows.find((item: any) => prop(primaryKey, item) === rowPrimaryKey)
                const orderNumber = mappedStrings[index]
                return (
                  <TableRow
                    key={item.id}
                    className={cx({ 'selected': isSelected })}
                  >
                    {checkbox && (
                      <TableCell width={45} align="right">
                        <Checkbox
                          className={classes.checkbox}
                          color="primary"
                          inputProps={{ 'aria-label': 'select all desserts' }}
                          data-cy={`table-checkbox-row-${index}`}
                          onChange={() => onClickSelectRow(item)}
                          checked={Boolean(isSelected)}
                        />
                      </TableCell>
                    )}
                    {actions && (
                      <TableCell width={30}>
                        {actions(item, index)}
                      </TableCell>
                    )}
                    {isHasOrderNumber && orderNumber && (
                      <TableCell width={30}>
                        {orderNumber}
                      </TableCell>
                    )}
                    {columns.map((column, columnIndex) => {
                      const columnValue = prop(column.field, item)
                      const valueGetter = prop('valueGetter', column)
                      const renderCell = prop('renderCell', column)

                      const rowValue = valueGetter ? valueGetter(columnValue) : columnValue
                      const tableCell = renderCell ? renderCell(item) : rowValue
                      const isHasOrderNumber = column.field === 'orderNumber' ? null : tableCell

                      const leftStick = prop('leftStick' as keyof TableListProps, column)
                      const rightStick = prop('rightStick' as keyof TableListProps, column)
                      const position = (leftStick || rightStick) ? 'sticky' : 'unset'
                      const left = leftStick ? 0 : 'unset'
                      const right = rightStick ? 0 : 'unset'
                      const background = (leftStick || rightStick) && '#fff'
                      const styles = { position, left, right, background }

                      return (
                        <TableCell
                          key={Array.isArray(column.field) ? column.field[1] : column.field}
                          width={column.width}
                          data-cy={`table-column-${columnIndex}-row-${index}`}
                          sx={styles}
                          className={cx({ 'selected': isSelected })}
                        >
                          {isHasOrderNumber}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
              {loading && (
                <TableRow>
                  {checkbox && (
                    <TableCell align="right">
                      <Skeleton
                        animation="wave"
                        height={30}
                        width="50%"
                        style={{ marginBottom: 5, marginTop: 5, marginLeft:15 }}
                      />
                    </TableCell>
                  )}
                  {actions && (
                    <TableCell align="center">
                      <Skeleton
                        animation="wave"
                        height={30}
                        width="80%"
                        style={{ marginBottom: 5, marginTop: 5 }}
                      />
                    </TableCell>
                  )}
                  {columns.map((column) => (
                    <TableCell key={Array.isArray(column.field) ? column.field[1] : column.field}>
                      <Skeleton
                        animation="wave"
                        height={30}
                        width="80%"
                        style={{ marginBottom: 5, marginTop: 5 }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              )}
              {!loading && list.length === 0 && (
                <TableRow>
                  <TableCell height={50} colSpan={columnLength} sx={{ textAlign: 'center' }}>No Information</TableCell>
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </FlexBox>
      <TableFilterPopup
        open={openColumnSettings}
        setOpen={setOpenColumnSettings}
        filterNode={columnNode}
      />
      <TableFilterPopup
        open={openFilterPopup}
        setOpen={setOpenFilterPopup}
        filterNode={filterNode}
      />
      <TableExportSidePopup
        initialValues={{ uploadFile: '' }}
        onSubmit={onImport}
        open={openExportPopup}
        setOpen={setOpenExportPopup}
        exportNode={exportNode}
      />
    </>
  )
}

export default TableAttach
