import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'
import { useGetList } from 'hooks/useGetList'
import { usePut } from 'hooks/usePut'
import { prop } from 'ramda'

import FilterCreateForm from './FilterCreateForm'
import { orderFilterSerializer } from './serializer'
import { filterValidationSchema } from './filter-form-values'
import { createFilter, updateFilter, getAvailableColumns } from './api'

interface Props {
  readonly filter:any
  readonly onBack:() => void
  readonly filterList:any
  readonly onDelete:any
  readonly onFilter:any
  readonly onFilterPrev:any
  readonly filterPreviewData:any
}

const FilterListingCreate = ({
  filter,
  onBack,
  filterList,
  onDelete,
  onFilter,
  onFilterPrev,
  filterPreviewData
}:Props) => {
  const snackbar = useSnackbar()
  const filterCreate = usePost(createFilter)
  const filterUpdate = usePut(updateFilter)
  const availableColumns = useGetList(getAvailableColumns, { params: { type: 'CONTACT_LIST_PANEL' } })
  const [isNewFilter, setIsNewFilter] = useState(false)
  const list = prop('list', availableColumns)
  const isHasFilterPreview = Object.keys(filterPreviewData).length > 0

  useEffect(() => {
    availableColumns.getList()
  }, [])

  const onCreate = useCallback((values:any, form:any) => {
    filterCreate.postData({ data:orderFilterSerializer(values) })
      .then(() => onBack())
      .then(() => filterList.getList())
      .then(() => snackbar({ message: 'Фильтр успешно создан' }))
      .then(() => form.resetForm({ type: 'SALES_ORDER_LIST_PANEL', criteria: [] }))
      .then(() => availableColumns.getList())
  }, [])

  const onUpdate = useCallback((values:any) => {
    filterUpdate.putData({ data:orderFilterSerializer(values) })
      .then(() => onBack())
      .then(() => snackbar({ message: 'Фильтр успешно обновлён' }))
  }, [])

  const initialValues = useMemo(() => {
    if (isNewFilter) {
      return { name:'', type: 'CONTACT_LIST_PANEL', criteria: list }
    }
    if (isHasFilterPreview) {
      return filterPreviewData
    } else {
      return filter
    }
  }, [filter, isNewFilter, list, isHasFilterPreview])

  const onSubmit = isNewFilter ? onCreate : onUpdate

  return (
    <>
      <FilterCreateForm
        isHasFilterPreview={isHasFilterPreview}
        onBack={onBack}
        setIsNewFilter={setIsNewFilter}
        filter={initialValues}
        onFilterPrev={onFilterPrev}
        onFilter={onFilter}
        onDelete={onDelete}
        availableColumns={availableColumns}
        filterList={filterList}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={filterValidationSchema}
      />
    </>
  )
}

export default FilterListingCreate
