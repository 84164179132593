import React from 'react'
import { pathOr, propOr } from 'ramda'
import { Grid } from '@mui/material'
import DetailTable from 'components/table/DetailTable'

import SaleOrderStatusRow from './SalesOrderStatusRow'

const columns = [
  {
    headerName: 'Дата заказа',
    field: 'orderDate',
    width: 300
  },
  {
    headerName: 'Номер заказа',
    field: 'soNumber',
    width: 200
  },
  {
    headerName: 'Клиент',
    field: 'contact',
    renderCell: pathOr('', ['contact', 'name'])
  },
  {
    headerName: 'Склад',
    field: 'warehouse',
    renderCell: pathOr('', ['warehouseGroup', 'name'])
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (item: any) => <SaleOrderStatusRow status={item.status} />
  },
  {
    headerName: 'Количество товаров',
    field: 'lineItemCount'
  }
]

interface Props {
  readonly stockHistory: any
}

function SOTableByVisit ({ stockHistory }: Props) {
  const list = propOr([], 'list', stockHistory)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} lg={12}>
        <DetailTable
          list={list}
          loading={stockHistory.loading}
          listGet={stockHistory.getList}
          columns={columns}
        />
      </Grid>
    </Grid>
  )
}

export default SOTableByVisit
