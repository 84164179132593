import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [{ value: 'SALES', name: 'ПРОДАЖИ' }, { value: 'SERVICE', name: 'УСЛУГА' }]

function TerritoryTypeField (props: any) {
  return (
    <SelectField
      name="territoryType"
      label="Tип"
      options={OPTIONS}
      {...props}
    />
  )
}

export default TerritoryTypeField
