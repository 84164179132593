import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'
import { GetListResponse } from 'api/BaseDto'

export const getVisitTypeList = (options?: Options) => {
  return getRequest<GetListResponse<any>>(API.TaskTypeList, options)
}

export const getVisitTypeById = (options?: Options) => {
  return getRequest<GetListResponse<any>>(API.TaskTypeGetById, options)
}

export const createVisitType = (options?: Options) => {
  return postRequest<GetListResponse<any>>(API.TaskTypeCreate, options)
}

export const updateVisitType = (options?: Options) => {
  return putRequest<any>(API.TaskTypeUpdate, options)
}

export const deleteVisitType = (options?: Options) => {
  return deleteRequest<any>(API.TaskTypeDelete, options)
}
