import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import { prop } from 'ramda'
import SearchField from 'components/form/SearchField'
import { useField } from 'formik'
import { getRequest } from 'api/BaseApi'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)
    const code = prop('code', option)

    return { value, name, id: value, code }
  }

  return null
}

function CurrencyField (props: any) {
  const [, meta, field] = useField('currency')

  useEffect(() => {
    if (typeof meta.initialValue === 'string') {
      const code = meta.initialValue
      getRequest(API.CurrencyGetByCode, { params :{ code }, data:{ code } })
        .then((response: any) => {
          field.setValue(getOptionValue(response))
        })
    }
  }, [])

  return (
    <SearchField
      name="currency"
      api={API.CurrencySuggestions}
      getOptionValue={getOptionValue}
      label="Валюта"
      primaryKey="code"
      {...props}
    />
  )
}

export default CurrencyField
