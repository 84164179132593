import React from 'react'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import MoreIcon from 'assets/more-icon.svg'

const useStyles = makeStyles({
  menu: {
    '& ::before': {
      opacity: '0 !important'
    }
  },
  actionButton: {
    transition: '0.3s',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    width: '135px !important',
    height: '30px !important',
    background: '#202020 !important',
    borderRadius: '10px !important',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    '&.disabled': {
      color: '#7C7C7C !important',
      background: '#EFEFEF !important',
      cursor: 'not-allowed',
      '& path': {
        stroke: '#7C7C7C !important'
      }
    }
  },
  arrow: {
    '&.open': {
      transform: 'rotate(180deg)',
      transition: '0.3s'
    }
  }
})

interface Actions {
  onOpenTabs?: () => void;
  onTabSettingsClick?: () => void;
  onExportClick: () => void;
  onSortingClick?:() => void
}

interface Props{
  readonly actions: Actions
  readonly onExportExcel: () => void
  readonly onGetThisPageExcel: () => void
}
export function MoreActionButtons ({ actions, onExportExcel, onGetThisPageExcel }: Props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onImport = () => {
    actions.onExportClick()
    handleClose()
  }

  return (
    <>
      <Tooltip title="Еще">
        <IconButton
          id="table-actions"
          aria-controls={open ? 'table-actions-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <img src={MoreIcon} alt="logo" />
        </IconButton>
      </Tooltip>
      <Menu
        id="table-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'table-actions', }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        className={classes.menu}
      >
        <MenuItem onClick={() => {
          actions.onOpenTabs?.()
          handleClose()
        }}>
          Вкладки
        </MenuItem>
        <MenuItem onClick={() => {
          actions.onTabSettingsClick?.()
          handleClose()
        }}>
          Колонки
        </MenuItem>
        <MenuItem onClick={() => {
          actions.onSortingClick?.()
          handleClose()
        }}>
          Сортировка
        </MenuItem>
        <MenuItem onClick={onImport}>
          Импорт
        </MenuItem>
        <MenuItem onClick={onGetThisPageExcel}>
          Экспорт страницы
        </MenuItem>
        <MenuItem onClick={onExportExcel}>
          Экспорт полного списка товаров
        </MenuItem>
      </Menu>
    </>
  )
}

export default MoreActionButtons
