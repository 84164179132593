import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'

import SupplierPaymentsCreateForm from '../components/SupplierPaymentsCreateForm'
import { createSupplierPayment } from '../api'
import { supplierPaymentsCreateSerializer } from '../serializer'

function SupplierPaymentsCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const supplierPaymentsCreate = usePost(createSupplierPayment)

  const handleSubmit = useCallback((values:any) => {
    supplierPaymentsCreate.postData({ data:supplierPaymentsCreateSerializer(values) })
      .then(() => navigate(ROUTES.FINANCE_SUPPLIER_PAYMENTS_LIST))
      .then(() => snackbar({ message: 'Платежи поставщика успешно создана' }))
  }, [])

  return (
    <AppLayout>
      <SupplierPaymentsCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default SupplierPaymentsCreateContainer
