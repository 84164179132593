import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getConsignmentList = (options?: Options) => {
  return getRequest<any>(API.ConsignmentList, options)
}

export const getByIdConsignment = (options?: Options) => {
  return getRequest<any>(API.ConsignmentDetail, options)
}

export const createConsignment = (options?: Options) => {
  return postRequest<any>(API.ConsignmentCreate, options)
}

export const updateConsignment = (options?: Options) => {
  return putRequest<any>(API.ConsignmentUpdate, options)
}

export const deleteConsignment = (options?: Options) => {
  return deleteRequest<any>(API.ConsignmentDelete, options)
}
