import React, { ReactNode } from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'topSpace' && propName !== 'bottomSpace'
})(({ theme, topSpace, bottomSpace }: any) => ({
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  paddingTop: 10,
  paddingBottom: 20,
  ...(topSpace && ({
    paddingTop: 20
  })),
  ...(bottomSpace && ({
    paddingBottom: 200
  }))
})) as any

interface Props {
  readonly children: ReactNode
  readonly space?: boolean
  readonly bottomSpace?: boolean
  readonly sx?: any
}

function Content ({ children, space = false, bottomSpace = false, sx }: Props) {
  return (
    <RootStyled topSpace={space} bottomSpace={bottomSpace} sx={sx}>
      {children}
    </RootStyled>
  )
}

export default Content
