import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getContactList = (options?: Options) => {
  return getRequest<any>(API.ContactList, options)
}

export const getByIdContact = (options?: Options) => {
  return getRequest<any>(API.ContactGetById, options)
}

export const createContact = (options?: Options) => {
  return postRequest<any>(API.ContactCreate, options)
}

export const updateContact = (options?: Options) => {
  return putRequest<any>(API.ContactUpdate, options)
}

export const deleteContact = (options?: Options) => {
  return patchRequest<any>(API.ContactDelete, options)
}

export const uploadFiles = (options?:Options) => {
  return postRequest<any>(API.AttachmentPublicUpload, options)
}

export const deleteUploadFile = (options?:Options) => {
  return deleteRequest<any>(API.RemoveAttachment, options)
}

export const importExcelContact = (options?:Options) => {
  return postRequest<any>(API.ImportExcelContact, options)
}

export const getCustomFields = (options?: Options) => {
  return getRequest<any>(API.CustomFieldsGetByFormType, options)
}

export const contactFilterPreview = (options?:Options) => {
  return patchRequest<any>(API.ContactFilterPreview, options)
}

export const tabsCounts = (options?:Options) => {
  return getRequest<any>(API.ContactListingTabRecords, options)
}

export const tabDetail = (options?:Options) => {
  return getRequest<any>(API.ListingTabGetById, options)
}

export const defaultImageContact = (options?:Options) => {
  return patchRequest<any>(API.ContactDefaultImage, options)
}

export const getExportContact = (options?:Options) => {
  return getRequest<any>(API.ContactGetFullExport, options)
}
