import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { pathParams } from 'path-params'
import { useNavigate } from 'react-router'
import { prop } from 'ramda'

interface Props {
  readonly item: any
  readonly onDelete: (item: any) => void
  readonly onBook: (values: any) => void
  readonly onProcess: (values: any) => void
}

function SaleOrderRowMenu ({ item, onDelete, onBook, onProcess }: Props) {
  const navigate = useNavigate()
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null)

  const anchorIsOpen = Boolean(optionsAnchorEl)

  const handleOpenOptions = (event: any) => {
    setOptionsAnchorEl(event.currentTarget)
  }

  const handleCloseOptions = () => {
    setOptionsAnchorEl(null)
  }

  const id = prop('id', item)
  const status = prop('status', item)
  const updatedURL = pathParams(ROUTES.SALE_ORDER_UPDATE, { id })

  const isDraft = status === 'DRAFT'
  const isBooked = status === 'BOOKED'
  const isClone = status === 'COMPLETED' || status === 'IN_PROCESS'
  const onCopy = useCallback(() => {
    navigate({ pathname: ROUTES.SALE_ORDER_CREATE, search: `?copyId=${id}` })
  }, [])

  const onReturn = useCallback(() => {
    navigate({ pathname: ROUTES.SALE_ORDER_RETURN_CREATE, search: `?copyId=${id}` })
  }, [])

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={anchorIsOpen ? 'long-menu' : undefined}
        aria-expanded={anchorIsOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenOptions}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="table-item-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={optionsAnchorEl}
        open={anchorIsOpen}
        onClose={handleCloseOptions}
      >
        <MenuItem
          onClick={() => {
            navigate(updatedURL)
            handleCloseOptions()
          }}
        >
          Просмотр
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete(item)
            handleCloseOptions()
          }}
        >
          Удалить
        </MenuItem>
        {(isDraft) && (
          <MenuItem
            onClick={() => {
              onBook({ data: { id } })
              handleCloseOptions()
            }}
          >
            Забронировать
          </MenuItem>
        )}
        {(isBooked) && (
          <MenuItem
            onClick={() => {
              onProcess({ data: { id, status:'IN_PROCESS' } })
              handleCloseOptions()
            }}
          >
            Процесс
          </MenuItem>
        )}
        <MenuItem
          onClick={() => onCopy()}
        >
          Клонировать
        </MenuItem>
        {isClone && (
          <MenuItem
            onClick={() => onReturn()}
          >
            Создать возврат
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  )
}

export default SaleOrderRowMenu
