import React from 'react'
import { useField, useFormikContext } from 'formik'
import { path } from 'ramda'
import Typography from '@mui/material/Typography'
import { Button, Grid } from '@mui/material'
import FlexBox from 'components/main/FlexBox'
import TabSettingsIcon from 'assets/tab-settings-icon.svg'
import TextField from 'components/form/TextField'
import SaveIcon from 'assets/save-icon.svg'
import withForm from 'components/form/withForm'
import useDialog from 'hooks/useDialog'

import FilterColumns from './form/FilterColumns'
import FilterFieldVisibility from './form/FilterFieldVisibility'
import FilterFieldRoles from './form/FilterFieldRoles'
import FilterFieldUsers from './form/FilterFieldUsers'
import ColumnsSettingsEditDialogue from './columns-settings-dialogue/ColumnsSettingsEditDialogue'

import { useStyles } from '../styles/ColumnStyles'

interface Props {
  readonly onBack:() => void
  readonly onFilter:(values:any) => void
  readonly form:any
}

const ColumnUpdateForm = ({ onBack, onFilter, form }:Props) => {
  const classes = useStyles()
  const { values } = useFormikContext()
  const visibilityField = useField('visibleTo')
  const visibilityValue = path(['0', 'value'], visibilityField)
  const isUserAndRoles = visibilityValue === 'SELECTED_USERS_OR_ROLES'
  const columnsSettingsDialogue = useDialog()

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <FlexBox align="center">
              <img src={TabSettingsIcon} alt="AddIcon" />
              <Typography sx={{ marginLeft: '10px' }}>
                Настройка колонок
              </Typography>
            </FlexBox>
          </div>
          <Grid container={true} spacing={1} pt={2}>
            <Grid item={true} lg={9}>
              <TextField
                label="Название колонка"
                placeholder="Название колонка"
                name="name"
                size="small"
              />
            </Grid>
            <Grid item={true} lg={3}>
              <Button
                type="submit"
                className={classes.button}
                fullWidth={true}
              >
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.columnBox}>
          <Grid container={true} spacing={2}>
            <Grid item={true} lg={6}>
              <FilterFieldVisibility />
            </Grid>
            <Grid item={true} lg={6}>
              <Button
                className={classes.button}
                onClick={() => columnsSettingsDialogue.handleOpen()}
              >
                Редактировать вид
              </Button>
            </Grid>
            {isUserAndRoles && (
              <>
                <Grid item={true} lg={6}>
                  <FilterFieldRoles />
                </Grid>
                <Grid item={true} lg={6}>
                  <FilterFieldUsers />
                </Grid>
              </>
            )}
            <Grid item={true} lg={12}>
              <FilterColumns />
            </Grid>
          </Grid>
        </div>
        <div className={classes.actions}>
          <FlexBox align="center" gap="8px">
            <Button
              color="secondary"
              variant="contained"
              className={classes.button}
              onClick={onBack}
            >
              <img src={SaveIcon} alt="save-icon" />
              Отменить
            </Button>
            <Button
              className={classes.button}
              onClick={() => onFilter(values)}
            >
              <img src={SaveIcon} alt="save-icon" />
              Применить
            </Button>
          </FlexBox>
        </div>
      </div>
      {columnsSettingsDialogue.open && (
        <ColumnsSettingsEditDialogue
          open={columnsSettingsDialogue.open}
          onClose={columnsSettingsDialogue.handleClose}
          form={form}
        />
      )}
    </>
  )
}

export default withForm(ColumnUpdateForm)
