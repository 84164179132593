import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import { prop } from 'ramda'
import { useField } from 'formik'
import { getRequest } from 'api/BaseApi'

import AutoSelectField from './AutoSelectField'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value, name, id: value }
  }

  return null
}

function PriceLevelField (props: any) {
  const field = useField(props.name)

  useEffect(() => {
    if (field[0].value) {
      getRequest(API.PriceLevelGetById, { params: { id:field[0].value } })
        .then((response:any) => {
          field[2].setValue(getOptionValue(response))
        })
    }
  }, [])
  return (
    <AutoSelectField
      name="territoryId"
      api={API.PriceLevelSuggestionsList}
      getOptionValue={getOptionValue}
      label="Ценовая политика"
      {...props}
    />
  )
}

export default PriceLevelField
