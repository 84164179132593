import { prop, propOr } from 'ramda'

const translations: { [key: string]: string } = {
  NAME: 'Юр. наименование',
  SHORT_NAME: 'Короткое имя',
  ANTEX_ID: 'Антекс ID',
  TERRITORY: 'Территория',
  INN: 'Статус',
  WEBSITE: 'Веб-сайт',
  MFO: 'МФО',
  RS: 'РС',
  NOTE: 'Примечание',
  CURRENCY: 'Валюта',
  MODIFIED_DATE: 'Дата изменения',
  CREATED_DATE: 'Дата создания',
  CREATED_BY: 'Добавлено',
  MODIFIED_BY: 'Модифицирован',
}

const getFieldMapping: { [key: string]: string } = {
  NAME: 'name',
  SHORT_NAME: 'shortName',
  ANTEX_ID: 'antexId',
  TERRITORY: 'territoryList',
  INN: 'inn',
  WEBSITE: 'website',
  MFO: 'mfo',
  RS: 'rs',
  NOTE: 'note',
  CURRENCY: 'currency',
  MODIFIED_DATE: 'Дата изменения',
  CREATED_DATE: 'Дата создания',
  CREATED_BY: 'Добавлено',
  MODIFIED_BY: 'Модифицирован',
}

const getTranslatedHeaderName = (code: string, name:string): string => {
  return translations[code] || name
}

export const fieldMappingsContacts = (code:string, name:string):string => {
  return getFieldMapping[code] || name
}

const getRenderCell = (name: string) => {
  if (name === 'name') {
    return (item: any) => item.name && item.name.length ? item.name.join(', ') : ''
  }
  if (name === 'territoryList') {
    return (item: any) => item.territoryList && item.territoryList.length
      ? item.territoryList.map((item:any) => item.name).join(', ') : ''
  } else return null
}

export const getColumnsBySetting = (setting: any) => {
  const columns = propOr([], 'columns', setting) as any
  return columns.map((column: any, index: number) => {
    const name = propOr(index, 'name', column) as string
    const code = propOr('', 'code', column) as string
    const freeze = prop('freeze', column)
    const conditions = prop('conditions', column)
    const width = prop('width', column)
    const renderCell = getRenderCell(fieldMappingsContacts(code, name))
    return {
      headerName: getTranslatedHeaderName(code, name),
      leftStick: freeze,
      field: fieldMappingsContacts(code, name),
      conditions,
      width,
      renderCell
    }
  })
}
