import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'PRODUCT', name: 'Продукт' },
  { value: 'ASSET', name: 'Aссет' },
  { value: 'OBJECT', name: 'Оборудование' }
]

function ProductTypeField (props:any) {
  return (
    <SelectField
      name="productType"
      label={props.label}
      options={OPTIONS}
      defaultValue={OPTIONS[0].value}
      data-cy="status"
      {...props}
    />
  )
}

export default ProductTypeField
