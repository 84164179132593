import * as API from 'constants/Api'

import { getRequest, Options, postRequest } from 'api/BaseApi'

import { GetListResponse } from '../../../../api/BaseDto'

export const getChartOfAccountList = (options?: Options) => {
  return postRequest<any>(API.ChartOfAccountList, options)
}

export const createChartOfAccount = (options?: Options) => {
  return postRequest<GetListResponse<any>>(API.ChartOfAccountCreate, options)
}

export const getAccountExcel = (options?: Options) => {
  return getRequest<any>(API.ChartOfAcccountExcel, options)
}
