import { path, prop, propOr } from 'ramda'
import { toPayloadDate } from 'utils/dates'

export const manualEntryCreateSerializer = (values: any) => {
  const date = toPayloadDate(prop('date', values))
  const currencyCode = path(['currencyCode', 'code'], values)
  const items = propOr([], 'items', values) as any
  const changedItems = items.map((item: any) => ({ ...item, accountId: path(['accountId', 'value'], item) }))
  return { ...values, items: changedItems, currencyCode, date }
}

export const manualEntryUpdateInitialSerializer = (values: any) => {
  const items = propOr([], 'items', values) as any
  const changedItems = items.map((item: any) => ({ ...item, accountId: path(['account', 'id'], item) }))
  return { ...values, items: changedItems }
}
