import React, { useState } from 'react'
import Box from '@mui/material/Box'
import FlexBox from 'components/main/FlexBox'
import AntexLogo from 'assets/AntEx.svg'
import AntexLogoLight from 'assets/AntEx-light.svg'
import {
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

import LanguageSelect from '../language-select/LanguageSelect'

const routesHeader = [
  { id: 1, title: 'О нас', link: '/' },
  { id: 2, title: 'Услуги', link: '/' },
  { id: 3, title: 'Новости', link: '/' },
  { id: 4, title: 'База данных', link: '/' },
  { id: 5, title: 'FAQ', link: '/' },
  { id: 6, title: 'Контакты', link: '/' },
]

const useStyles = makeStyles({
  menuItem: {
    fontSize: '14px',
    fontWeight: '400px',
    lineHeight: '16.94px',
    color: '#DBDBDB',
    cursor: 'pointer',
  },
  loginButton: {
    borderRadius: '10px',
    padding: '10px 24px',
    fontSize: '18px',
    lineHeight: '21.78px',
    fontWeight: '500',
  },
  drawerContent: {
    width: '250px',
    padding: '20px',
    backgroundColor: '#020202', // Drawer background color
    height: '100%',
    color: '#DBDBDB',
  },
  drawerMenuItem: {
    padding: '10px 0',
    fontSize: '18px',
    color: '#FFFFFF',
    cursor: 'pointer',
  },
})

interface Props {
  readonly hideLanguage:boolean
}

function LandingHeader ({ hideLanguage }:Props) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Up to lg breakpoint
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open)
  }

  return (
    <Box
      sx={{
        background: isMobile ? '#FFFFFF' : '#202020', // Header background color based on device type
        maxHeight: '60px', // Fixed height
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1 1',
      }}
    >
      <FlexBox
        flex={true}
        align="center"
        style={{
          padding: '0 30px',
          maxWidth: '1440px',
          width: '100%',
        }}
        gap={30}
      >
        {/* Left Side - Logo */}
        <FlexBox align="center" justify="center" style={{ width: '82px' }}>
          <img src={isMobile ? AntexLogoLight : AntexLogo} alt="Logo svg" />
        </FlexBox>

        {/* Right Side - Menu */}
        <FlexBox align="center" justify="flex-end" flex={true}>
          {isMobile ? (
            // Mobile View: Hamburger Menu
            <>
              <IconButton onClick={toggleDrawer(true)}>
                <MenuIcon style={{ color: isMobile ? '#202020' : '#FFFFFF' }} /> {/* Hamburger icon color */}
              </IconButton>

              {/* Drawer for Mobile Menu */}
              <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box className={classes.drawerContent}>
                  <FlexBox justify="flex-end">
                    <IconButton onClick={toggleDrawer(false)}>
                      <CloseIcon style={{ color: '#FFFFFF' }} />
                    </IconButton>
                  </FlexBox>

                  <List>
                    {routesHeader.map((page) => (
                      <ListItem key={page.id} button>
                        <ListItemText>
                          <Typography className={classes.drawerMenuItem}>{page.title}</Typography>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>

                  <FlexBox align={isMobile ? 'flex-start' : 'center'} gap={10} direction="column">
                    {hideLanguage ? null : <LanguageSelect />}
                    <Button
                      className={classes.loginButton}
                      fullWidth={true}
                    >
                      Войти
                    </Button>
                  </FlexBox>
                </Box>
              </Drawer>
            </>
          ) : (
            // Desktop View: Normal Menu
            <FlexBox align="center" justify="space-between" flex={true}>
              <FlexBox align="center" gap={30}>
                {routesHeader.map((page) => (
                  <Typography key={page.id} variant="body2" className={classes.menuItem}>
                    {page.title}
                  </Typography>
                ))}
              </FlexBox>
              <FlexBox align="center" gap={30}>
                {hideLanguage ? null : <LanguageSelect />}
                <Button className={classes.loginButton}>Войти</Button>
              </FlexBox>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </Box>
  )
}

export default LandingHeader
