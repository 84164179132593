import React from 'react'

function SelectIcon (props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
      {...props}
    >
      <g
        className="selectStroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        clipPath="url(#clip0_271_5190)"
      >
        <path d="M32.5 52.5V35L20 22.5 7.5 35v17.5H20m12.5 0H20m12.5 0h20V10A2.5 2.5 0 0050 7.5H25a2.5 2.5 0 00-2.5
         2.5v15M20 52.5v-10M32.5 17.5v.025M42.5 17.5v.025M42.5
          27.5v.025M42.5 37.5v.025" />
      </g>
      <defs>
        <clipPath id="clip0_271_5190">
          <path fill="#fff" d="M0 0H60V60H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SelectIcon
