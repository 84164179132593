import { makeStyles } from '@mui/styles'

export const tableStyles = makeStyles({
  tableRoot: {
    boxShadow: 'none !important',
    maxHeight:'calc(100vh - 120px)',
    WebkitOverflowScrolling: 'touch',
    minWidth:'100vw',
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
      color:'#F1F1F1',
      cursor:'pointer'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#7C7C7C',
      borderRadius:'4px',
    },
    '&::-webkit-scrollbar-horizontal': {
      height: '8px',
    }
  },
  tableHeader: {
    background: '#F1F1F1',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      padding: '15px 5px'
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      transition: '0.23s',
      height:'46px',
      padding: '0 5px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020',
      '&.selected': {
        background: '#F6F8FD!important'
      }
    },
    '& .MuiTableRow-root': {
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA',
      '& .MuiTableCell-root': {
        background: '#FAFAFA !important'
      }
    }
  },
  checkboxHeader:{
    color: '#202020',
    width: '18px',
    height: '18px',
    '&.Mui-checked': {
      color: '#202020',
    }
  },
  checkbox: {
    width: '18px',
    height: '18px',
    color: '#7c7c7c',
    '&.Mui-checked': {
      color: '#202020',
    }
  },
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight:'12.1px',
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    }
  },
  tableBarRoot: {
    background: '#fff',
    paddingRight: '30px',
    height:'58px'
  },

  addButtonRoot: {
    fontSize: '18px',
    lineHeight: '22px',
    color: '#202020',
    padding: '19px 30px'
  },
  addButton: {
    cursor: 'pointer'
  },
  paginationRoot: {
    width:'180px',
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: 500
    }
  },
  paginationButton: {
    cursor: 'pointer'
  },
  selectedCount: {
    width: '28px',
    height: '21px',
    borderRadius: '10px',
    background: '#7C7C7C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    margin: 'auto',
    transition: '0.3s',
    '&.active': {
      background: '#202020'
    }
  },
  searchBox: {
    width:'100%',
    '& form':{
      width:'100%'
    }
  },
  searchInput: {
    '& .MuiButtonBase-root': {
      height: '100%',
      position: 'relative',
    },
    '& .MuiTextField-root':{
      '& ::placeholder': {
        color: '#000000!important',
      },
    },
    '& .MuiOutlinedInput-root .MuiInputBase-input': {
      outline: 'none',
      border: 'none',
    },
    '& .MuiOutlinedInput-root fieldset': {
      outline: 'none',
      border: 'none',
    },
  }
})
