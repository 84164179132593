import { makeStyles } from '@mui/styles'

export const signInContainerStyles = makeStyles({
  root: {
    width: '100vw',
    height: 'auto',
    minHeight: '100vh',
    zIndex: '1',
    padding: '70px 30px 30px',
    position: 'relative',
    background: '#F5F5F5'
  },
  loginForm: {
    boxShadow: '0px -25px 40px rgba(187, 187, 187, 0.15)',
    width: '100%',
    maxWidth: '450px',
    borderRadius: '15px',
    background: '#fff',
    position: 'relative',
    padding: '50px 50px 47px',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '80%',
      height: '100%',
      background: '#fff',
      top: '-20px',
      borderRadius: '15px',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: -1,
      boxShadow: '0px -20px 40px rgba(187, 187, 187, 0.12)'
    }
  },
  forgotPassword: {
    color: '#1557FF',
    fontSize: '15px'
  },
  title: {
    fontWeight: 600,
    lineHeight: '39px',
    fontSize: '32px',
    textAlign: 'center'
  },
  titleBody: {
    lineHeight: '16px',
    fontSize: '16px',
    textAlign: 'center'
  },

  loginFooter: {
    '& *': {
      fontSize: '12px',
      color: '#999999'
    }
  }
})
