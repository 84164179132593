import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  MeasuringStrategy,
} from '@dnd-kit/core'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { pathOr, prop, propOr } from 'ramda'
import { Button } from '@mui/material'
import Container from 'components/container/Container'
import { useNavigate } from 'react-router'

import DndContainer from '../components/DndContainer'
import TableColumnsContainer, { ColumnDndItem } from '../components/TableColumnsContainer'
import useAllSearchParams from '../../../../utils/useAllSearchParams'
import { useGetList } from '../../../../hooks/useGetList'
import { getActiveColumnList, getAllTableColumns, updateTableColumns } from '../api'
import AppLayout from '../../../../components/main/AppLayout'
import Header from '../../../../components/container/Header'
import PageTitle from '../../../../components/main/PageTitle'
import Content from '../../../../components/container/Content'
import { useGetDetail } from '../../../../hooks/useGetDetail'
import { usePut } from '../../../../hooks/usePut'
import { useSnackbar } from '../../../../context/snackbar'

const afterUpdatePath = {
  'PRODUCT_LIST_PANEL': ROUTES.CATALOG_PRODUCT_LIST
}

function ListingUpdateContainer () {
  const params = useAllSearchParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const key = propOr('PRODUCT_LIST_PANEL', 'key', params)
  const id = propOr('', 'id', params)

  const activeColumnList = useGetDetail(getActiveColumnList, { query: { type: key }, params: { id } })
  const allColumnList = useGetList(getAllTableColumns, { params: { type: key } })
  const updateColumns = usePut(updateTableColumns)

  const [items, setItems] = useState<Record<string, any[]>>({
    container1: [],
    container2: [],
  })

  useEffect(() => {
    activeColumnList.getDetail().then((res: any) => {
      const activeColumns = propOr([], 'columns', res) as any
      const newActiveColumns = activeColumns.map((item: any, index: number) => {
        return { ...item, id: index + 1 }
      })
      allColumnList.getList().then((allRes: any) => {
        const notActiveColumns = allRes.filter((item: any) => {
          const isActive = activeColumns.find((active: any) => active.code === item.code)
          return !isActive
        })
        const newNotActiveColumns = notActiveColumns.map((item: any, index: number) => {
          return { ...item, id: activeColumns.length + index + 1 }
        })
        setItems(() => ({ container1: newActiveColumns, container2: newNotActiveColumns }))
      })
    })
  }, [])

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        tolerance: 5,
        delay: 100,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const [activeId, setActiveId] = useState<any | null>(null)
  const recentlyMovedToNewContainer = useRef(false)

  const findContainer = (id: any) => {
    if (id in items) {
      return id
    }

    return Object.keys(items).find((key) => items[key].find((item) => item.id === id))
  }

  const itemsBeforeDrag = useRef<null | Record<string, any[]>>(
    null,
  )

  const handleDragStart = useCallback(
    ({ active }: any) => {
      itemsBeforeDrag.current = {
        container1: [...items.container1],
        container2: [...items.container2],
      }
      setActiveId(active.id)
    },
    [items],
  )

  const handleDragOver = useCallback(
    ({ active, over }: any) => {
      if (!over || active.id in items) {
        return
      }

      const { id: activeId } = active
      const { id: overId } = over

      const activeContainer = findContainer(activeId)
      const overContainer = findContainer(overId)

      if (!overContainer || !activeContainer) {
        return
      }

      if (activeContainer !== overContainer) {
        setItems((items) => {
          const activeItems = items[activeContainer]
          const overItems = items[overContainer]
          const overIndex = overItems.map(e => e.id).indexOf(overId)
          const activeIndex = activeItems.map(e => e.id).indexOf(activeId)

          const newIndex = overIndex >= 0 ? overIndex : overItems.length + 1

          recentlyMovedToNewContainer.current = true

          return {
            ...items,
            [activeContainer]: items[activeContainer].filter(
              (item) => item.id !== active.id,
            ),
            [overContainer]: [
              ...items[overContainer].slice(0, newIndex),
              items[activeContainer][activeIndex],
              ...items[overContainer].slice(
                newIndex,
                items[overContainer].length,
              ),
            ],
          }
        })
      }
    },
    [items, findContainer],
  )

  const handleDragEnd = useCallback(
    ({ active, over }: any) => {
      const activeContainer = findContainer(active.id)
      if (!over || !activeContainer) {
        setActiveId(null)
        return
      }

      const { id: activeId } = active
      const { id: overId } = over

      const overContainer = findContainer(overId)

      if (!overContainer) {
        setActiveId(null)
        return
      }

      const activeIndex = items[activeContainer].map(e => e.id).indexOf(activeId)
      const overIndex = items[overContainer].map(e => e.id).indexOf(overId)

      if (activeIndex !== overIndex) {
        setItems((items) => ({
          ...items,
          [overContainer]: arrayMove(
            items[overContainer],
            activeIndex,
            overIndex,
          ),
        }))
      }
      setActiveId(null)
    },
    [items, findContainer],
  )

  const onDragCancel = useCallback(() => {
    setItems({
      container1: [...(itemsBeforeDrag.current?.container1 ?? [])],
      container2: [...(itemsBeforeDrag.current?.container2 ?? [])],
    })
    itemsBeforeDrag.current = null
    setActiveId(null)
  }, [])

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false
    })
  }, [items])

  const activeItem = useMemo(() => {
    const inFirstContainer = items['container1'].find((item) => item.id === activeId)
    const inSecondContainer = items['container2'].find((item) => item.id === activeId)
    if (inFirstContainer) {
      return {
        container: 'container1',
        item: inFirstContainer
      }
    } else if (inSecondContainer) {
      return {
        container: 'container2',
        item: inSecondContainer
      }
    }
  }, [activeId, items])

  const onSubmit = useCallback(() => {
    const activeResult = prop('result', activeColumnList)
    const activeContainers = propOr([], 'container1', items) as any
    const newColumns = activeContainers.map((item: any, idx: number) => {
      return {
        ...item,
        width: propOr(100, 'width', item),
        order: idx + 1,
        ref: null
      }
    })
    const body = {
      ...activeResult,
      columns: newColumns
    }
    return updateColumns.putData({ data: body })
      .then(() => snackbar({ message: 'Успешно обновлено' }))
      .then(() => {
        const afterUrl = propOr(ROUTES.CATALOG_PRODUCT_LIST as any, key as any, afterUpdatePath) as any
        navigate(afterUrl)
      })
  }, [items, activeColumnList])

  return (
    <AppLayout>
      <Container>
        <Header>
          <PageTitle
            pageTitle="Обновить колонки"
            rightButton={(
              <Button type="button" onClick={onSubmit}>
                Сохранить изменения
              </Button>
            )}
          />
        </Header>
        <Content sx={{ padding: '0 !important' }}>
          <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={onDragCancel}
            onDragOver={handleDragOver}
            measuring={{
              droppable: {
                strategy: MeasuringStrategy.Always,
              },
            }}
          >
            <TableColumnsContainer id="container1" items={items.container1} />
            <DndContainer id="container2" items={items.container2} />

            <DragOverlay>
              {activeId && (
                <ColumnDndItem
                  code={pathOr('', ['item', 'code'], activeItem)}
                  text={pathOr('', ['item', 'name'], activeItem)}
                  isOverlay={true}
                />
              )}
            </DragOverlay>
          </DndContext>
        </Content>
      </Container>
    </AppLayout>
  )
}

export default ListingUpdateContainer
