import React, { useState } from 'react'
import { useField } from 'formik'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import TextFieldWithLabel from 'components/form/TextFieldWithLabel'
import { Box } from '@mui/material'
import useClickOutside from 'hooks/useClickOutside'

import { PasswordStrengthMeter } from '../PasswordStrengthMeter'

export const PasswordField = (props: any) => {
  const [field] = useField(props.name || '')
  const [showPassword, setShowPassword] = useState(false)

  const { ref, isFocused, setIsFocused } = useClickOutside<HTMLDivElement>()

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  return (
    <Box ref={ref} sx={{ position: 'relative', width: '100%' }}>
      <TextFieldWithLabel
        {...field}
        {...props}
        type={showPassword ? 'text' : 'password'}
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onFocus={handleFocus}
      />
      {isFocused && props.strength && field.value && (
        <PasswordStrengthMeter password={field.value} />
      )}
    </Box>
  )
}
