import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import DateField from 'components/form/DateField'
import TextField from 'components/form/TextField'
import { path } from 'ramda'
import useScrollList from 'hooks/useScrollList'

import LineItems from './form/LineItems'
import WarehouseField from './form/WarehouseField'
import OrderField from './form/OrderField'
import ShippingMethodField from './form/ShippingMethodField'
import TerritoryAutoSelect from './form/TerritoryAutoSelect'
import ClientAutoSelect from './form/ClientAutoSelect'
import PriceLevelField from './form/PriceLevelField'
import DriverField from './form/DriverField'
import CountryField from './form/CountryField'
import RegionField from './form/RegionField'
import ContactAddressSelectField from './form/ContactAddressSelectField'
import CurrencyField from './form/CurrencyField'

import { getProductSuggestions, getProductSuggestionsByOrderId } from '../api'

interface Props {
  readonly form:any
}

function SaleOrderReturnCreateForm ({ form }: Props) {
  const orderId = path(['values', 'orderId', 'id'], form)
  const productList = useScrollList(getProductSuggestions)
  const productListByOrderId = useScrollList(getProductSuggestionsByOrderId, { params:{ orderId } })
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать возврат заказ"
          rightButton={(
            <>
              <Button
                type="submit"
              >
                Создать
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали заказа" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <TerritoryAutoSelect />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <OrderField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DateField
                      label="Дата посещения"
                      name="visitDate"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DateField
                      label="Дата возврата"
                      name="returnDate"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DriverField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <WarehouseField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <PriceLevelField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CurrencyField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <ShippingMethodField />
                  </Grid>
                  <Grid item={true} lg={4} />
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Описание"
                      name="notes"
                      minRows={4}
                      multiline
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
            <Card>
              <CardHeader title="Клиент" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={6}>
                    <ClientAutoSelect />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <ContactAddressSelectField />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
            <Card>
              <CardHeader title="Адрес" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Адресс"
                      name="address.addressLine1"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Адрес дополнительный"
                      name="address.addressLine2"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Ориентир"
                      name="address.landmark"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CountryField
                      label="Название страны"
                      name="address.countryId"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <RegionField
                      label="Город"
                      name="address.regionId"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Координаты - лат/лон"
                      name="address.location"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems
              productList={orderId ? productListByOrderId : productList}
            />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(SaleOrderReturnCreateForm)
