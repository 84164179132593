import React, { useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import { makeStyles } from '@mui/styles'

import ColumnsContainer from './components/ColumnsContainer'

export const useStyles = makeStyles({
  dialog:{
    '& .MuiPaper-root':{
      maxWidth:'100vw',
      maxHeight:'90vh',
      height:'100%'
    }
  },
  dialogContent: {
    padding: '16px',
    position: 'relative',
  }
})

interface Props {
  readonly open: boolean
  readonly onClose: () => void
  readonly form:any
}

function ColumnsSettingsDialogue ({ open, onClose, form }: Props) {
  const classes = useStyles()

  const onEscape = useCallback((event: any) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }, [onClose])

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onKeyDown={onEscape}
      className={classes.dialog}
    >
      <ColumnsContainer
        form={form}
        onClose={onClose}
      />
    </Dialog>
  )
}

export default ColumnsSettingsDialogue
