import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import SearchField from 'components/form/SearchField'
import { prop } from 'ramda'
import { useField } from 'formik'
import { useGetList } from 'hooks/useGetList'

import { getAvailableFields } from '../../api'

const getTranslatedColumn = (code:string) => {
  if (code === 'SALES_AMOUNT') {
    return 'Сумма продаж'
  }
  if (code === 'SALES_QTY') {
    return 'Количество продаж'
  }
  if (code === 'PRODUCT_CATEGORY') {
    return 'Продуктовая корзина'
  }
  if (code === 'PRODUCT') {
    return 'Продукт'
  }
  if (code === 'TERRITORY') {
    return 'Территория'
  }
  if (code === 'CONTACT') {
    return 'Контрагент'
  }
  if (code === 'CURRENCY') {
    return 'Валюта'
  }
}

const getOptionValue = (option: any) => {
  if (option) {
    const code = prop('code', option)
    const fieldType = prop('fieldType', option)
    const lookUpType = prop('lookUpType', option)
    return { code, name:getTranslatedColumn(code), id: code, fieldType, lookUpType }
  }

  return null
}

function AvailableColumnsField (props: any) {
  const [, meta, helpers] = useField(props.name)
  const availableFields = useGetList(getAvailableFields, { params: { type: 'SALES' } })

  useEffect(() => {
    if (meta.initialValue) {
      availableFields.getList()
        .then((response:any) => {
          return response?.map((item:any) => {
            if (item.code === meta.initialValue) {
              helpers.setValue(item)
            }
          })
        })
    }
  }, [])

  return (
    <SearchField
      api={API.BonusAvailableFields}
      getOptionValue={getOptionValue}
      param={{ type:'SALES' }}
      label="Доступные колонки"
      {...props}
    />
  )
}

export default AvailableColumnsField
