import React, { useCallback, useState } from 'react'
import { Button } from '@mui/material'
import cx from 'classnames'
import { Form, Formik } from 'formik'
import { prop, propOr } from 'ramda'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import useAllSearchParams from 'utils/useAllSearchParams'
import { useSnackbar } from 'context/snackbar'
import FlexBox from 'components/main/FlexBox'
import ImportIcon from 'assets/import-icon.svg'
import SaveIcon from 'assets/save-icon.svg'
import { usePost } from 'hooks/usePost'

import LoadingDialogue from './LoadingDialogue'

import { importProduct } from '../api'

const useStyles = makeStyles({
  header: {
    padding: '17px 20px',
    background: 'rgba(253, 253, 253, 0.5)',
    backdropFilter: 'blur(15px)',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper: {
    width: '700px',
    background: '#fff',
    minHeight: 'calc(100vh - 60px)',
  },
  addButton: {
    cursor: 'pointer',
  },
  searchBox: {
    padding: '20px',
    borderBottom: '1px solid #f1f1f1',
  },
  menuItem: {
    padding: '10px 20px',
    '&:hover': {
      background: '#F6F8FD',
    },
  },
  menuBox: {
    height: 'calc(100vh - 280px)',
    overflowY: 'auto',
    '& .MuiMenuItem-root': {
      padding: '6px 16px',
    },
  },
  actions: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  selectedCount: {
    width: '28px',
    height: '21px',
    borderRadius: '10px',
    background: '#7C7C7C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    margin: 'auto',
    transition: '0.3s',
    '&.active': {
      background: '#202020',
    },
  },
  button: {
    fontSize: '14px',
    display: 'flex',
    height: '40px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    padding: '9px 20px',
    '&.disabled': {
      background: '#202020 !important',
      opacity: '0.5',
    },
  },
  labelButton: {
    cursor: 'pointer',
    padding: '5px 10px',
    border: 'none',
    background: '#F1F1F1',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    height: '40px!important',
    width: '500px',
  },
  downloadButton: {
    padding: '20px',
  },
  customInput: {
    display: 'none',
  },
})

const handleClick = () => {
  window.location.href =
    'https://s3.antexpert.uz/antex-task-public/2024/05/11/1715423644012_product_template.xlsx'
}

interface Props {
  readonly productList: any
  readonly setOpen?: (value: boolean) => void
}

function ProductListExcelFilter ({ setOpen = () => null, productList }: Props) {
  const snackbar = useSnackbar()
  const searchParams = useAllSearchParams()
  const classes = useStyles()
  const productImport = usePost(importProduct)
  const [disabled, setDisabled] = useState(false)

  const onImportProduct = useCallback(
    (values: any, { setSubmitting }: any) => {
      const data = new FormData()
      data.append('uploadFile', values.uploadFile)
      productImport
        .postData({ data })
        .then(() => snackbar({ message: 'Файл Excel успешно импортирован.' }))
        .then(() => productList.getList({ query: searchParams }))
        .then(() => {
          setOpen(false)
          setSubmitting(false)
        })
        .catch(() => setSubmitting(false))
    },
    [searchParams, productImport, productList, setOpen, snackbar]
  )

  const onCancel = () => {
    setOpen(false)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <FlexBox align="center">
            <img src={ImportIcon} alt="AddIcon" />
            <Typography sx={{ marginLeft: '10px' }}>Импорт</Typography>
          </FlexBox>
        </div>
      </div>
      <div className={classes.searchBox}>
        <Formik
          initialValues={{ uploadFile: {} }}
          onSubmit={onImportProduct}
        >
          {({ values, setFieldValue, isSubmitting }) => {
            const uploadFile = prop('uploadFile', values) as File | undefined
            const fileName = propOr('Выберите файл', 'name', uploadFile) as string
            return (
              <Form>
                <FlexBox align="center" gap="16px">
                  <FlexBox align="center" flex={true} style={{ width: '100%' }}>
                    <label htmlFor="file-upload" className={classes.labelButton}>
                      {fileName}
                    </label>
                    <input
                      id="file-upload"
                      className={classes.customInput}
                      placeholder="Выберите файл"
                      name="uploadFile"
                      type="file"
                      onChange={(event: any) => {
                        setFieldValue('uploadFile', event.target.files[0])
                        setDisabled(true)
                      }}
                    />
                  </FlexBox>
                  <Button
                    type="submit"
                    disabled={isSubmitting || !disabled}
                    className={cx(classes.button, {
                      disabled: isSubmitting || !disabled,
                    })}
                    fullWidth={true}
                  >
                    <img src={SaveIcon} alt="save-icon" />
                    {isSubmitting ? 'Загрузка...' : 'Загрузить'}
                  </Button>
                </FlexBox>
                {isSubmitting && (
                  <LoadingDialogue
                    open={isSubmitting}
                  />
                )}
              </Form>
            )
          }}
        </Formik>
      </div>
      <div className={classes.menuBox}>
        <FlexBox align="center" className={classes.downloadButton}>
          <Button onClick={handleClick}>Скачать файл-шаблон</Button>
        </FlexBox>
      </div>
      <div className={classes.actions}>
        <FlexBox align="center" gap="8px">
          <Button
            color="secondary"
            variant="contained"
            className={classes.button}
            onClick={onCancel}
          >
            <img src={SaveIcon} alt="save-icon" />
            Отменить
          </Button>
        </FlexBox>
      </div>
    </div>
  )
}

export default ProductListExcelFilter
