import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'DRAFT', name: 'Черновик' },
  { value: 'APPROVED', name: 'Одобрено' },
  { value: 'PARTIALLY_PAID', name: 'Частично оплачено' },
  { value: 'PAID', name: 'Оплачено' },
  { value: 'CANCELLED', name: 'Отменено' },
]

function ConsignmentStatusField (props:any) {
  return (
    <SelectField
      name="status"
      label="Статус консигнации"
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default ConsignmentStatusField
