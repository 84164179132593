import * as ROUTES from 'constants/Routes'

import * as Yup from 'yup'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'
import { useUser } from 'context/user'
import AppLayout from 'components/main/AppLayout'

import ChartOfAccountForm from '../components/form/ChartOfAccountForm'
import { createChartOfAccount } from '../api'
import { chartOfAccountSerializer } from '../serializer'

export const validationSchema = Yup.object({
  name:Yup.string().required('Требуется название'),
  type:Yup.string().required('Требуется тип аккаунта'),
  territoryIds: Yup.array().min(1, 'Требуется территория.')
    .required('Обязательный'),
})

function ChartOfAccountCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { user } = useUser()

  const chartOfAccountCreate = usePost(createChartOfAccount)

  const handleSubmit = useCallback((values: any) => {
    const data = { ...values, companyId: user.userCompanyId }
    return chartOfAccountCreate.postData({ data:chartOfAccountSerializer(data) })
      .then(() => {
        snackbar({ message: 'Счет успешно создан' })
        navigate(ROUTES.FINANCE_CHART_OF_ACCOUNT_LISTING)
      })
  }, [user])

  return (
    <AppLayout>
      <ChartOfAccountForm
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={{ name:'', type:'', territoryIds:[] }}
      />
    </AppLayout>
  )
}

export default ChartOfAccountCreateContainer
