import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { useField } from 'formik'
import useDebounce from 'hooks/useDebounce'
import { request } from 'api/BaseApi'
import { makeStyles } from '@mui/styles'
import { propOr } from 'ramda'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import FlexBox from 'components/main/FlexBox'
import useDeepCompareEffect from 'hooks/useDeepCompareEffect'

const defaultGetOptionLabel = (option: any) => option?.name

const defaultGetOptionValue = (value: any) => {
  if (value) {
    const { id = 0, name = '' } = value
    return { id, name }
  }

  return null
}

const useStyles = makeStyles({
  autocomplete: {
    width:'100%',
    '& .MuiAutocomplete-input':{
      padding:'5px 10px 5px 12px!important',
    },
  },
  field: {
    '& .MuiInputBase-root': {
      borderRadius: '5px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#1557FF'
      },
      '& .MuiInputBase-input': {
        padding: '16px',
        fontSize: '13px',
        color: '#202020',
        background: 'white'
      },
      '& ::placeholder': {
        opacity: 1,
        color: '#7c7c7c',
        fontSize: '13px',
        fontWeight: '500'
      }
    },
    '& .MuiChip-root':{
      padding:'5px 6px 5px 8px',
      color:'#202020',
      background:'#E9E9E9',
      gap:'5px',
      '& .MuiChip-label':{
        fontSize:'10px',
        fontWeight:'500',
        lineHeight:'12.1px',
        padding:0,
      },
      '& .MuiChip-deleteIcon':{
        margin:0
      }
    }
  }
})

const listBoxStyle = {
  '& .MuiAutocomplete-listbox':{
    padding:'8px 0 0 0'
  }
}

const defaultGetOptionSelected = (option: any, value: any) => {
  return option.id === value.id
}

const defaultRenderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: any
) => {
  return (
    <Box
      key={`autocomplete-item-${option?.id}-${option?.name}`}
      sx={{
        borderBottom:'1px solid #f1f1f1',
        fontSize:'13px',
        lineHeight:'15.73px',
        color:'#202020',
        [`&.${autocompleteClasses.option}`]: {
          padding:'8px 16px',
        },
      }}
      component="li"
      {...props}
    >
      {option.name}
    </Box>

  )
}

function MultiSelectSearchField (props: any) {
  const {
    name,
    api,
    size = 'small',
    query,
    params,
    disabled,
    onOpenDialog,
    getOptionLabel = defaultGetOptionLabel,
    renderOption = defaultRenderOption,
    getOptionValue,
    ListboxProps,
    getOptionSelected = defaultGetOptionSelected,
    searchText = 'searchKey',
    onAddRoute,
    ...rest
  } = props
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([]) as any
  const [value, setValue] = useState('')
  const [input, meta, helpers] = useField(name)
  const debouncedValue = useDebounce(value)
  const borderColor = meta.initialError ? 'error.main' : 'default' // Use MUI theme colors

  const CustomPaper = (props:any) => {
    const { children } = props
    return (<Paper sx={listBoxStyle} onMouseDown={event => event.preventDefault()}>
      {children}
      <FlexBox
        align="center"
        justify="space-between"
        style={{ padding:'4px 0' }}
      >
        <Button
          color="info"
          variant="text"
          onClick={() => window.open(onAddRoute, '_blank')}
        >
          Добавить
        </Button>
        <Button
          color="info"
          variant="text"
          onClick={() => onOpenDialog()}
        >
          Посмотреть все
        </Button>
      </FlexBox>
    </Paper>
    )
  }

  useDeepCompareEffect(() => {
    let active = true
    if (open) {
      setLoading(true)

      request(api, { query: { [searchText]: value, ...query }, method: 'get', ...params })
        .then((response: any) => {
          const content = propOr([], 'content', response) as any
          const list = propOr([], 'list', response) as any
          const responseList = Array.isArray(response) ? response : []
          const results = [...content, ...list, ...responseList]

          if (active) {
            setOptions(results.map((item: any) => getOptionValue(item)))
            setLoading(false)
          }
        })
    }

    return () => {
      active = false
    }
  }, [open, debouncedValue])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const handleChange = (event: any, value: any) => {
    helpers.setValue(value)
    helpers.setTouched(false)
  }

  return (
    <>
      <Autocomplete
        className={classes.autocomplete}
        open={open}
        disabled={disabled}
        size={size}
        sx={{
          '& .MuiOutlinedInput-notchedOutline':{
            borderColor:borderColor
          }
        }}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        multiple={true}
        disableCloseOnSelect={true}
        disableClearable={true}
        value={input.value || []}
        isOptionEqualToValue={getOptionSelected}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        PaperComponent={(props) => <CustomPaper {...props} />}
        onInputChange={(event, value) => {
          setOptions([])
          setValue(value)
        }}
        options={options.length === 0 && !open ? input.value || [] : options}
        loading={loading}
        filterOptions={item => item}
        onChange={handleChange}
        ListboxProps={ListboxProps}
        renderInput={defaultProps => {
          return (
            <TextField
              className={classes.field}
              {...defaultProps}
              {...rest}
              error={!!(meta.touched && meta.error)}
              helperText={(meta.touched && meta.error) || meta.initialError}
              InputProps={{
                ...defaultProps.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {defaultProps.InputProps.endAdornment}
                  </>
                )
              }}
              FormHelperTextProps={{ style: { position: 'absolute', top: '100%', color:'error.main' } }}
            />
          )
        }}
      />
    </>
  )
}

MultiSelectSearchField.propTypes = {
  api: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  renderOption: PropTypes.func,
  ListboxProps: PropTypes.object
}

MultiSelectSearchField.defaultProps = {
  params: { limit: 1000 },
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
  disabled: false,
  getOptionLabel: defaultGetOptionLabel,
  getOptionValue: defaultGetOptionValue
}

export default MultiSelectSearchField
