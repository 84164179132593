import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { createDiscount } from '../api'
import DiscountCreateForm from '../components/DiscountCreateForm'
import { discountCreateSerializer } from '../serializers'

function DiscountCreateContainer () {
  const discountCreate = usePost(createDiscount)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = useCallback((values: any) => {
    return discountCreate.postData({ data: discountCreateSerializer(values) })
      .then(() => snackbar({ message: 'Скидка успешно создан' }))
      .then(() => navigate(ROUTES.CATALOG_DISCOUNT))
  }, [])

  return (
    <AppLayout>
      <DiscountCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default DiscountCreateContainer
