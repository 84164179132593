import React from 'react'
import { useGetDetail } from 'hooks/useGetDetail'
import { useParams } from 'react-router-dom'

import { SharedContactDetailForm } from '../components/SharedContactDetailForm'
import LandingLayout from '../../components/layout/LandingLayout'
import { sharedContactDetail } from '../api'

function SharedPrivateDetailContainer () {
  const { id } = useParams()
  const detailSharedContact = useGetDetail(sharedContactDetail, { params: { id } })
  return (
    <LandingLayout>
      {!detailSharedContact.loading && (
        <SharedContactDetailForm
          sharedContact={detailSharedContact.result}
        />
      )}
      {detailSharedContact.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </LandingLayout>
  )
}

export default SharedPrivateDetailContainer
