import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getSharedContactCategoryList = (options?: Options) => {
  return getRequest<any>(API.SharedContactCategoryList, options)
}

export const sharedContactCategoryCreate = (options?: Options) => {
  return postRequest<any>(API.SharedContactCategoryCreate, options)
}

export const sharedContactCategoryUpdate = (options?: Options) => {
  return putRequest<any>(API.SharedContactCategoryUpdate, options)
}

export const sharedContactCategoryGetById = (options?: Options) => {
  return getRequest<any>(API.SharedContactCategoryGetById, options)
}

export const sharedContactCategoryDelete = (options?: Options) => {
  return deleteRequest<any>(API.SharedContactCategoryDelete, options)
}
