import React, { useState } from 'react'
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'
import { useParams } from 'react-router'
import Content from 'components/container/Content'
import Container from 'components/container/Container'
import Header from 'components/container/Header'
import PageTitle from 'components/main/PageTitle'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined'

import CategoryLineItems from './CategoryLineItems'
import ContactLineItems from './ContactLineItems'
import PriceLevelLineItems from './PriceLevelLineItems'

export const useStyles = makeStyles({
  list:{
    padding:'0',
    '& .MuiListItemButton-root':{
      borderRadius:'5px',
      '&.active':{
        background:'#f1f1f1'
      }
    },
    '& .MuiListItemIcon-root':{
      minWidth:'30px'
    }
  },
  header:{
    marginTop:'4px',
    height:'40px',
    '& .MuiCardHeader-title':{
      fontWeight:'600',
      fontSize:'18px',
      lineHeight:'21.8px',
      color:'#202020'
    },
    '& .MuiCardHeader-action':{
      marginRight:'0'
    }
  },
  checkboxHeader:{
    color: '#202020',
    width: '18px',
    height: '18px',
    '&.Mui-checked': {
      color: '#202020',
    }
  },
  tabs:{
    border:'1px solid red'
  },
  tableRoot:{
    maxHeight:'460px',
    borderRadius:'7px',
    border:'1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      cursor:'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA'
    }
  },
})

interface Props{
  readonly priceLevelList: any
  readonly priceLevelSave: any
  readonly territoryPriceLevel: any
  readonly contactList: any
  readonly contactSave: any
  readonly territoryContacts: any
  readonly categoryList: any
  readonly categorySave: any
  readonly territoryCategory: any
}

enum TERRITORY_ATTACH_TABS {
  CATEGORY = 'category',
  CONTACT = 'contact',
  PRICE_LEVEL = 'price'
}

const tabs = [
  { icon:<Inventory2OutlinedIcon />, value: TERRITORY_ATTACH_TABS.CATEGORY, label: 'Продуктовая корзина' },
  { icon:<SupportAgentOutlinedIcon />, value: TERRITORY_ATTACH_TABS.CONTACT, label: 'Контрагенты' },
  { icon:<AttachMoneyOutlinedIcon />, value: TERRITORY_ATTACH_TABS.PRICE_LEVEL, label:'Ценовая политика' }
]

const TerritoryBulkEditForm = ({
  territoryCategory,
  contactSave,
  contactList,
  categorySave,
  categoryList,
  territoryContacts,
  priceLevelList,
  priceLevelSave,
  territoryPriceLevel
}:Props) => {
  const { id } = useParams()
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(TERRITORY_ATTACH_TABS.CATEGORY)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Прекрепленное редактирование"
        />
      </Header>
      <Content>
        <Grid container={true} spacing={2}>
          <Grid item lg={3}>
            <List className={classes.list}>
              {tabs.map((tab) => (
                <ListItem key={tab.value} disablePadding onClick={() => setSelectedTab(tab.value)}>
                  <ListItemButton className={cx({ 'active': tab.value === selectedTab })}>
                    <ListItemIcon>
                      {tab.icon}
                    </ListItemIcon>
                    <ListItemText primary={tab.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item={true} lg={9}>
            <Grid container spacing={3}>
              {selectedTab === TERRITORY_ATTACH_TABS.CATEGORY && (
                <Grid item lg={12}>
                  <CategoryLineItems
                    id={id}
                    api={categoryList}
                    initial={territoryCategory}
                    saveApi={categorySave}
                  />
                </Grid>
              )}
              {selectedTab === TERRITORY_ATTACH_TABS.CONTACT && (
                <Grid item lg={12}>
                  <ContactLineItems
                    id={id}
                    api={contactList}
                    initial={territoryContacts}
                    saveApi={contactSave}
                  />
                </Grid>
              )}
              {selectedTab === TERRITORY_ATTACH_TABS.PRICE_LEVEL && (
                <Grid item lg={12}>
                  <PriceLevelLineItems
                    id={id}
                    api={priceLevelList}
                    initial={territoryPriceLevel}
                    saveApi={priceLevelSave}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </Container>

  )
}

export default TerritoryBulkEditForm
