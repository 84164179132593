import React, { useEffect } from 'react'
import SelectField from 'components/form/SelectField'
import { useField } from 'formik'

import { CUSTOM_FIELD_TYPE } from '../CustomFieldType'

export enum PRODUCT_CUSTOM_FIELD_FORM_TYPE {
  PRODUCT = 'PRODUCT',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  CONTACT = 'CONTACT',
  SUPPLIER = 'SUPPLIER',
  WAREHOUSE_GROUP = 'WAREHOUSE_GROUP',
  WAREHOUSE = 'WAREHOUSE',
  TERRITORY = 'TERRITORY',
  PRICE_LEVEL = 'PRICE_LEVEL',
  CURRENCY = 'CURRENCY',
  DISCOUNT = 'DISCOUNT',
  TRANSACTION = 'TRANSACTION',
  ORDER_STATUS = 'ORDER_STATUS',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  UNIT_MEASUREMENT = 'UNIT_MEASUREMENT',
  BRAND = 'BRAND',
  PACKAGE_LINE_ITEM = 'PACKAGE_LINE_ITEM',
  PRODUCT_STATUS = 'PRODUCT_STATUS',
  SHIPMENT_STATUS = 'SHIPMENT_STATUS',
  SALES_ORDER = 'SALES_ORDER',
  PICKER = 'PICKER',
  PACKER = 'PACKER',
  DRIVER = 'DRIVER',
  USER = 'USER',
  TASK = 'TASK',
  TASK_TYPE = 'TASK_TYPE',
  TASK_STATUS = 'TASK_STATUS ',
}

const OPTIONS = [
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.PRODUCT, name: 'Продукт' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.PRODUCT_CATEGORY, name: 'Продуктовая корзина' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.BRAND, name: 'Бренд' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.CONTACT, name: 'Контрагент' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.TASK, name: 'Задача' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.TASK_TYPE, name: 'Тип задачи' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.TASK_STATUS, name: 'Статус задачи' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.WAREHOUSE, name: 'Склад' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.SALES_ORDER, name: 'Заказ' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.SHIPMENT_STATUS, name: 'Статус доставки' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.SUPPLIER, name:'Поставщик' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.PRODUCT_STATUS, name:'Статус продукта' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.PACKER, name:'Упаковщик' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.PICKER, name:'Сборщик' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.DRIVER, name:'Водитель' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.USER, name:'Пользователь' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.UNIT_MEASUREMENT, name:'Единица измерения' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.CURRENCY, name:'Валюта' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.DISCOUNT, name:'Скидка' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.WAREHOUSE_GROUP, name:'Складская группа' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.TRANSACTION, name:'Трансакция' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.TERRITORY, name:'Территория' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.PACKAGE_LINE_ITEM, name:'Упаковка' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.PAYMENT_METHOD, name:'Оплаты доставки' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.PRICE_LEVEL, name:'Ценовая политика' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.ORDER_STATUS, name:'Статус заказа' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.PRODUCT_TYPE, name:'Тип продукта' },
]

function CustomLookupField (props: any) {
  const [fieldTypeValue] = useField('fieldType')
  const [,, lookUpHelpers] = useField('lookUpType')

  useEffect(() => {
    if (fieldTypeValue.value === !CUSTOM_FIELD_TYPE.MULTI_LOOKUP ||
      fieldTypeValue.value === !CUSTOM_FIELD_TYPE.LOOKUP) {
      lookUpHelpers.setValue('')
    }
  }, [fieldTypeValue.value])

  return (
    <SelectField
      name="lookUpType"
      label="Поиск типа"
      options={OPTIONS}
      {...props}
    />
  )
}

export default CustomLookupField
