import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { updateUnitMeasurement, getUnitMeasurementDetail } from '../api'
import UnitMeasurementUpdateForm from '../components/UnitMeasurementUpdateForm'

function UnitMeasurementUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const unitMeasurementUpdate = usePut(updateUnitMeasurement)
  const unitMeasurementDetail = useGetDetail(getUnitMeasurementDetail, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    return unitMeasurementUpdate.putData({ data: { id, ...values } })
      .then(() => snackbar({ message: 'Единица измерения успешно обновлена' }))
      .then(() => navigate(ROUTES.CATALOG_MML))
  }, [id])

  return (
    <AppLayout>
      {!unitMeasurementDetail.loading && (
        <UnitMeasurementUpdateForm
          onSubmit={handleSubmit}
          initialValues={unitMeasurementDetail.result}
          enableReinitialize={true}
        />
      )}
      {unitMeasurementDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default UnitMeasurementUpdateContainer
