import React from 'react'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'
import { useField } from 'formik'

import VisitTypeField from './form/VIsitTypeField'
import VisitStatusField from './form/VisitStatusField'
import CustomerField from './form/CustomerField'
import UserField from './form/UserField'
import PredecessorField from './form/PredecessorField'
import AttachmentField from './form/uploads/AttachmentField'
import CountryField from './form/CountryField'
import RegionField from './form/RegionField'
import ContactAddressSelectField from './form/ContactAddressSelectField'
import TerritoryField from './form/TerritoryField'
import { AvailableCustomField } from './custom-fields/AvailableCustomField'
import CommentField from './form/CommentField'

function VisitCreateForm () {
  const customFieldsField = useField('customFieldValue')
  const customFields = customFieldsField[0].value || []
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать посещение"
          rightButton={(
            <>
              <Button type="submit">Создать</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Название"
                      name="name"
                      data-cy="name"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <VisitTypeField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <VisitStatusField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TerritoryField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CustomerField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <ContactAddressSelectField
                      label="Выбор адреса"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <UserField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <PredecessorField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DateField
                      label="Планируемая дата"
                      name="plannedDate"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Фактические координаты"
                      name="actualCoordinates"
                      type="number"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Продолжительность"
                      name="duration"
                      type="number"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CommentField
                      label="Комментарии"
                      name="comments"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Заметка"
                      name="note"
                      data-cy="note"
                      multiline={true}
                      minRows={3}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
            <Card>
              <CardHeader title="Адрес" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <TextField label="Адресс" name="address.addressLine1" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField label="Адрес дополнительный" name="address.addressLine2" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField label="Ориентир" name="address.landmark" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CountryField label="Название страны" name="address.countryId" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <RegionField
                      label="Город"
                      name="address.regionId"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Координаты - лат/лон"
                      name="address.location"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <AttachmentField name="attachments" />
          </Grid>
          <Grid item={true} lg={12}>
            <AvailableCustomField customFields={customFields} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(VisitCreateForm)
