import React from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid, IconButton,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import Delete from '@mui/icons-material/Delete'
import TextField from 'components/form/TextField'
import CardHeader from 'components/card/CardHeader'
import { FieldArray, useField } from 'formik'
import { useTranslationI18n } from 'i18n/I18nContext'
import FlexBox from 'components/main/FlexBox'

import AccountField from './AccountField'
import ManualEntryTypeField from './ManualEntryTypeField'
import AccountTypeField from './AccountTypeField'

const itemDefault = { accountId: null, amount: 0, transactionType: null }

export function LineItems () {
  const { t } = useTranslationI18n()

  const lineItemsField = useField('items')
  const lineItems = lineItemsField[0].value || []

  return (
    <Card>
      <CardHeader title="Таблица" />
      <CardContent>
        <Grid container={true} spacing={3}>
          <FieldArray name="items">
            {({ push, remove }: any) => {
              return (
                <Grid item={true} lg={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell width="30%">
                            <FlexBox align="center" justify="space-between">
                              {t('Аккаунт')} <AccountTypeField />
                            </FlexBox>
                          </TableCell>
                          <TableCell width="30%">{t('Сумма')}</TableCell>
                          <TableCell width="30%">{t(`Тип`)}</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lineItems.map((item: any, index: number) => {
                          return (
                            <TableRow>
                              <TableCell>
                                <AccountField name={`items[${index}].accountId`} />
                              </TableCell>
                              <TableCell>
                                <TextField name={`items[${index}].amount`} type="number" />
                              </TableCell>
                              <TableCell>
                                <ManualEntryTypeField name={`items[${index}].type`} />
                              </TableCell>
                              <TableCell align="right">
                                <IconButton onClick={() => remove(index)} size="large">
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        <Button sx={{ marginTop: '30px' }} onClick={() => push(itemDefault)}>Добавить</Button>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            }}
          </FieldArray>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default LineItems
