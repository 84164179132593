import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { updateSupplierPayment, getSupplierPaymentDetail } from '../api'
import SupplierPaymentsUpdateForm from '../components/SupplierPaymentsUpdateForm'
import { supplierPaymentsCreateSerializer, supplierPaymentsDetailSerializer } from '../serializer'

function SupplierPaymentsUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const supplierPaymentUpdate = usePut(updateSupplierPayment)
  const supplierPaymentDetail = useGetDetail(getSupplierPaymentDetail, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    return supplierPaymentUpdate.putData({ data: supplierPaymentsCreateSerializer(values) })
      .then(() => snackbar({ message: 'Платежи поставщика успешно обновлена' }))
      .then(() => navigate(ROUTES.CATALOG_BRAND_LIST))
  }, [])

  const initialValues = useMemo(() =>
    supplierPaymentsDetailSerializer(supplierPaymentDetail.result),
  [supplierPaymentDetail])

  return (
    <AppLayout>
      {!supplierPaymentDetail.loading && (
        <SupplierPaymentsUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={false}
        />
      )}
      {supplierPaymentDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default SupplierPaymentsUpdateContainer
