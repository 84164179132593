import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'
import { GetListResponse } from 'api/BaseDto'

export const getCompanyList = (options?: Options) => {
  return getRequest<GetListResponse<any>>(API.CompanyList, options)
}

export const getCompanyById = (options?:Options) => {
  return getRequest<GetListResponse<any>>(API.CompanyGetById, options)
}

export const createCompany = (options?: Options) => {
  return postRequest<GetListResponse<any>>(API.CompanyCreate, options)
}

export const updateCompany = (options?: Options) => {
  return putRequest<GetListResponse<any>>(API.CompanyUpdate, options)
}

export const deleteCompany = (options?: Options) => {
  return deleteRequest<GetListResponse<any>>(API.CompanyDelete, options)
}

export const createUser = (options?: Options) => {
  return postRequest<GetListResponse<any>>(API.EmployeeCreate, options)
}

export const userFileUpload = (options?:Options) => {
  return postRequest<any>(API.UserFileUpload, options)
}

export const deleteAttachment = (options?:Options) => {
  return deleteRequest<any>(API.RemoveUserAttachment, options)
}

export const billingSet = (options?:Options) => {
  return patchRequest<any>(API.CompanyBillingSet, options)
}

export const historyList = (options?:Options) => {
  return getRequest<any>(API.CompanyBillingHistory, options)
}
