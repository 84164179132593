import React from 'react'

export const MapTerritoryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19
        21zm0-2h14V5H5zm7-1q2.425-2.05 3.613-3.812t1.187-3.263q0-2.275-1.45-3.6T12 6 8.65 7.325t-1.45 3.6q0 1.5 1.188
        3.263T12 18m0-5.75q-.525 0-.888-.363T10.75 11t.363-.888T12 9.75t.888.363.362.887-.363.888-.887.362M5 19V5z"
      />
    </svg>
  )
}
