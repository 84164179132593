import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'
import { useNavigate } from 'react-router-dom'

import { createFolder } from '../api'
import FolderCreateForm from '../components/FolderCreateForm'

function FolderCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const folderCreate = usePost(createFolder)

  const handleCreate = useCallback((data:any) => {
    folderCreate.postData({ data })
      .then(() => snackbar({ message:'Папка успешно создана' }))
      .then(() => navigate(ROUTES.SETTINGS_MEDIA_FILES))
  }, [])

  return (
    <FolderCreateForm
      onSubmit={handleCreate}
      initialValues={{}}
    />
  )
}

export default FolderCreateContainer
