import React from 'react'
import Box from '@mui/material/Box'
import FlexBox from 'components/main/FlexBox'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { Avatar } from '@mui/material'
import Checkbox from '@mui/material/Checkbox/Checkbox'

const useStyles = makeStyles({
  userCardWrapper: {
    background: '#fff',
    boxShadow: '0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1))' +
      ' 0 rgba(34, 33, 81, 0.15)',
    borderRadius: 'calc(4px / var(--scale-x, 1))',
    padding: '10px 8px',
    cursor: 'pointer',
  },
  userImageWrapper: {
    width: '70px',
    height: '70px',
    '& .MuiAvatar-root': {
      width: '60px',
      height: '60px',
    },
  },
  image: {
    width: '50px',
    height: '50px',
  },
  subTitle: {
    fontSize: '0.75rem',
    lineHeight: '1.66',
    fontWeight: '500',
  },
})

interface Props {
  readonly user: any;
  readonly toggleUserSelection: (user: any) => void;
}

export const UserCard = ({ user, toggleUserSelection }: Props) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.userCardWrapper}
    >
      <FlexBox align="center" justify="space-between" >
        <FlexBox align="flex-start" gap={8}>
          <FlexBox align="center" justify="center" className={classes.userImageWrapper}>
            <Avatar src={user.picture?.link} alt="photoURL">
              {user.fullName ? user.fullName.charAt(0).toUpperCase() : ''}
            </Avatar>
          </FlexBox>
          <Box>
            <Typography variant="body1">
              {user.fullName || ''}
            </Typography>
            <Box>
              <Typography className={classes.subTitle}>
                {user.territoryRoles?.map((item:any) => item.territoryName).join(', ')}
              </Typography>
              <Typography className={classes.subTitle}>
                Tel: {user.phone || '+998-- --- -- --'}
              </Typography>
            </Box>
          </Box>
        </FlexBox>
        <Checkbox onClick={() => toggleUserSelection(user)} />
      </FlexBox>
    </Box>
  )
}
