import { omit, path, pathOr, prop } from 'ramda'
import { toPayloadDate } from 'utils/dates'

export const taskReturnSerializer = (values:any) => {
  const territory = prop('territoryIds', values)
  const territoryIds = territory && territory.map((territory:any) => territory.id)
  const userId = path(['userId', 'id'], values)
  const customerId = path(['customerId', 'id'], values)
  const timeReminder = prop('timeReminder', values)
  const startDate = pathOr('', ['timeReminder', 'startDate'], values)
  const endDate = pathOr('', ['timeReminder', 'endDate'], values)
  const getDayOfWeeks = path(['timeReminder', 'dayOfWeeks'], values)
  const dayOfWeeks = getDayOfWeeks && getDayOfWeeks.map((day:any) => day.value)
  const newTimeReminder = {
    ...timeReminder,
    startDate:toPayloadDate(startDate),
    endDate:toPayloadDate(endDate),
    dayOfWeeks
  }
  return { ...values, territoryIds, userId, customerId, timeReminder:newTimeReminder }
}

export const taskReturnDetailSerializer = (values:any) => {
  const userId = prop('user', values)
  const customerId = prop('customer', values)

  return { ...values, userId, customerId }
}

export const taskReturnUpdateSerializer = (values:any) => {
  const territory = prop('territoryIds', values)
  const territoryIds = territory && territory.map((territory:any) => territory.id || territory)
  const userId = path(['userId', 'id'], values)
  const customerId = path(['customerId', 'id'], values)
  const result = omit(['user', 'customer'], values)
  return { ...result, userId, customerId, territoryIds }
}
