import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getTerritoriesList = (options?: Options) => {
  return getRequest<any>(API.TerritoriesList, options)
}
export const territoryCreate = (options?: Options) => {
  return postRequest<any>(API.TerritoryCreate, options)
}
export const territoryUpdate = (options?: Options) => {
  return putRequest<any>(API.TerritoryUpdate, options)
}
export const territoryDelete = (options?: Options) => {
  return deleteRequest<any>(API.TerritoryDelete, options)
}
export const getTerritoryDetail = (options?: Options) => {
  return getRequest<any>(API.TerritoryGetDetail, options)
}
export const getPriceLevelList = (options?: Options) => {
  return getRequest<any>(API.PriceLevelSuggestionsList, options)
}
export const saveTerritoryPriceLevel = (options?: Options) => {
  return patchRequest<any>(API.TerritoryPriceLevelSave, options)
}
export const getContactList = (options?: Options) => {
  return getRequest<any>(API.ContactSuggestions, options)
}
export const saveTerritoryContacts = (options?: Options) => {
  return patchRequest<any>(API.TerritoryContactSave, options)
}
export const getCategoryList = (options?: Options) => {
  return getRequest<any>(API.CategorySuggestionList, options)
}
export const saveTerritoryCategories = (options?: Options) => {
  return patchRequest<any>(API.TerritoryCategorySave, options)
}
export const getTerritoryPriceLevel = (options?: Options) => {
  return getRequest<any>(API.TerritoryPriceLevelList, options)
}
export const getTerritoryContact = (options?: Options) => {
  return getRequest<any>(API.TerritoryContactList, options)
}
export const getTerritoryCategory = (options?: Options) => {
  return getRequest<any>(API.TerritoryCategoryList, options)
}

export const importExcelTerritory = (options?: Options) => {
  return postRequest<any>(API.TerritoryExcelImport, options)
}

export const importExcelByProductTerritory = (options?: Options) => {
  return postRequest<any>(API.TerritoryImportByProduct, options)
}
