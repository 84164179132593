import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { useConfirm } from 'context/confirm'
import { propOr } from 'ramda'

import { deleteFolder, getFolderList } from '../api'
import FolderRowMenu from '../components/FolderRowMenu'
import { FolderNameList } from '../components/FolderNameList'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 150
  },
  {
    headerName: 'Название папки',
    field: 'name',
    renderCell:(item:any) => <FolderNameList item={item} />
  }
]

function FolderListContainer () {
  const snackbar = useSnackbar()
  const onConfirm = useConfirm()
  const navigate = useNavigate()
  const folderList = useGetList(getFolderList)
  const folderDelete = useDelete(deleteFolder)

  const onDeleteFolder = useCallback((values: any) => {
    const message = `Вы уверены, что хотите удалить папку ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        folderDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Папка успешно удалена' }))
          .then(() => folderList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = propOr('', 'search', value)
    folderList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Папки"
        dataRequest={folderList}
        onAddClick={() => navigate(ROUTES.SETTINGS_MEDIA_FILES_CREATE)}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row) => (
          <FolderRowMenu
            item={row}
            onDelete={onDeleteFolder}
          />
        )}
      />
    </AppLayout>
  )
}

export default FolderListContainer
