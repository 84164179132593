import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import { prop } from 'ramda'
import SearchField from 'components/form/SearchField'
import { useField } from 'formik'

const apiGet:{ [key: string]: string } = {
  PRODUCT:API.ProductSuggestions,
  PRODUCT_CATEGORY:API.CategorySuggestionList,
  CONTACT:API.ContactSuggestions,
  SUPPLIER:API.ContactSuggestions,
  WAREHOUSE_GROUP:API.WarehouseGroupSuggestions,
  WAREHOUSE:API.WarehouseSuggestions,
  TERRITORY:API.TerritorySuggestions,
  PRICE_LEVEL:API.PriceLevelSuggestionsList,
  CURRENCY:API.CurrencySuggestions,
  DISCOUNT:API.DiscountSuggestions,
  TRANSACTION:API.TransactionList,
  UNIT_MEASUREMENT:API.UnitMeasurementSuggestions,
  BRAND:API.BrandSuggestionList,
  PACKAGE_LINE_ITEM:API.PackageSuggestions,
  SALES_ORDER:API.SalesOrderSuggestions,
  PICKER:API.EmployeeSuggestionList,
  PACKER:API.EmployeeSuggestionList,
  DRIVER:API.EmployeeSuggestionList,
  USER:API.EmployeeSuggestionList,
  TASK:API.VisitSuggestions,
  TASK_TYPE:API.TaskTypeSuggestions,
  TASK_STATUS:API.TaskStatusSuggestions,
}

const translations: { [key: string]: string } = {
  PRODUCT:'Продукт',
  PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
  CONTACT : 'Контрагент',
  SUPPLIER : 'Поставщик',
  WAREHOUSE_GROUP:'Складская группа',
  WAREHOUSE :'Склад',
  TERRITORY : 'Территория',
  PRICE_LEVEL : 'Ценовая политика',
  CURRENCY : 'Валюта',
  DISCOUNT : 'Скидка',
  TRANSACTION: 'Трансакция',
  ORDER_STATUS : 'Статус заказа',
  PAYMENT_METHOD : 'Способ оплаты',
  PRODUCT_TYPE : 'Тип продукта',
  UNIT_MEASUREMENT : 'Единица измерения',
  BRAND : 'Бренд',
  PACKAGE_LINE_ITEM : 'Упаковка',
  PRODUCT_STATUS : 'Статус продукта',
  SHIPMENT_STATUS : 'Статус доставки',
  SALES_ORDER : 'Заказ',
  PICKER : 'Сборщик',
  PACKER : 'Упаковщик',
  DRIVER : 'Водитель',
  USER : 'Пользователь',
  TASK : 'Задача',
  TASK_TYPE : 'Тип задачи',
  TASK_STATUS : 'Статус задачи ',
}

const getTranslation = (status:string) => {
  return translations[status]
}

const getAPI = (status:string) => {
  return apiGet[status]
}

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value:name, id: value, name:name || value }
  }

  return null
}

function SingleSearchField (props: any) {
  const [, meta, helpers] = useField(props.name)

  useEffect(() => {
    if (meta.initialValue) {
      helpers.setValue({ ...meta.initialValue, name:meta.initialValue.value })
    }
  }, [meta.initialValue])

  return (
    <SearchField
      name={props.name}
      api={getAPI(props.status)}
      getOptionValue={getOptionValue}
      label={getTranslation(props.status)}
      {...props}
    />
  )
}

export default SingleSearchField
