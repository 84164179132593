import { dissoc, omit, path, pathOr, prop, propOr } from 'ramda'
import { toNumberFormat } from 'utils/formatMoney'
import { toPayloadDate } from 'utils/dates'

export const preOrderCreateSerializer = (data: any) => {
  const warehouseGroupId = path(['warehouseGroupId', 'value'], data)
  const priceLevelId = path(['priceLevelId', 'value'], data)
  const contactId = path(['contactId', 'value'], data)
  const driverId = path(['driverId', 'id'], data)
  const agentId = path(['agentId', 'id'], data)
  const visitId = path(['visitId', 'id'], data)
  const territoryId = path(['territoryId', 'value'], data)
  const currency = path(['currency', 'code'], data)
  const orderDate = prop('orderDate', data)
  const visitDate = prop('visitDate', data)
  const oldItems = propOr([], 'items', data) as any
  const items = oldItems.map((item: any) => ({
    ...item,
    salesPrice:toNumberFormat(prop('salesPrice', item)),
    taxId: path(['taxId', 'value'], item),
    discountId:path(['discountId', 'id'], item)
  }))
  const addressId = pathOr('', ['addressId', 'id'], data)
  const result = omit(['address'], data)
  return {
    ...result,
    visitId,
    driverId,
    agentId,
    warehouseGroupId,
    orderDate: toPayloadDate(orderDate),
    visitDate: toPayloadDate(visitDate),
    items,
    contactId,
    priceLevelId,
    territoryId,
    currency,
    addressId
  }
}

export const preOrderGetInitialValues = (data: any) => {
  const warehouseGroupId = prop('warehouseGroup', data)
  const agentId = prop('agent', data)
  const driverId = prop('driver', data)
  const visitId = prop('visit', data)
  const contactId = prop('contact', data)
  const items = propOr([], 'items', data) as any
  const territoryId = prop('territory', data)
  const priceLevelId = prop('priceLevel', data)
  const newItems = items.map((item: any) => ({
    productId: path(['product', 'id'], item),
    discountId:path(['discount', 'id'], item),
    ...item
  }))
  const addressId = prop('address', data)
  return {
    ...data,
    visitId,
    driverId,
    agentId,
    items: newItems,
    warehouseGroupId,
    contactId,
    territoryId,
    priceLevelId,
    addressId
  }
}

export const preOrderUpdateSerializer = (data:any) => {
  const fieldsToRemove = [
    'contact',
    'priceLevel',
    'territory',
    'warehouseGroup',
    'address',
    'agent',
    'driver',
    'shippingMethod',
    'search'
  ]
  const result = fieldsToRemove.reduce((acc, field) => dissoc(field, acc), data)
  const visitId = path(['visitId', 'id'], data)
  const agentId = path(['agentId', 'id'], result)
  const driverId = path(['driverId', 'id'], result)
  const warehouseGroupId = path(['warehouseGroupId', 'id'], result)
  const priceLevelId = path(['priceLevelId', 'id'], result)
  const contactId = path(['contactId', 'id'], result)
  const territoryId = path(['territoryId', 'id'], result)
  const currency = path(['currency', 'code'], result)
  const orderDate = prop('orderDate', result)
  const visitDate = prop('visitDate', result)
  const oldItems = propOr([], 'items', result) as any
  const items = oldItems.map((item: any) => {
    const resultItem = omit(['product'], item)
    return ({
      ...resultItem,
      salesPrice: toNumberFormat(prop('salesPrice', item)),
      taxId: path(['taxId', 'id'], item),
      discountId: path(['discountId', 'id'], item)
    })
  })
  const addressId = pathOr('', ['addressId', 'id'], data)
  return {
    ...result,
    visitId,
    driverId,
    agentId,
    warehouseGroupId,
    orderDate: toPayloadDate(orderDate),
    visitDate: toPayloadDate(visitDate),
    items,
    currency,
    contactId,
    priceLevelId,
    territoryId,
    addressId
  }
}

export const preOrderAgentCreateSerialize = (data: any) => {
  const newAddresses = prop('addresses', data)
  const currency = path(['currency', 'code'], data)
  const newTerritories = prop('territories', data) as any
  const addresses = newAddresses && newAddresses.map((address: any) => {
    const countryId = prop('value', address.countryId)
    const regionId = prop('value', address.regionId)
    return { ...address, regionId, countryId }
  })
  const territories = newTerritories && newTerritories.map((ids: any) => prop('value', ids))
  return { ...data, territories, addresses, currency }
}

export const preOrderItemsSerializer = (items:any) => {
  return items && items.map((item:any) => {
    const productId = pathOr(null, ['product', 'id'], item)
    const quantity = propOr(1, 'orderQuantity', item)
    return {
      productId,
      quantity
    }
  })
}
