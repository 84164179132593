import React from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'
import { propOr } from 'ramda'

const useStyles = makeStyles({
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    },
    '&.warning': {
      background: '#ffcc00',
    }
  }
})

interface Props {
  readonly row?: any
}

const getTranslatedStatus = (status: string) => {
  if (status === 'IN') {
    return 'ПРИХОД'
  }
  if (status === 'OUT') {
    return 'РАСХОД'
  }
  if (status === 'BOOKED') {
    return 'ЗАБРОНИРОВАНО'
  }
}

function StockProductStatusRow ({ row }: Props) {
  const classes = useStyles()
  const statusText = propOr('Нет статуса', 'type', row) as string
  return (
    <div className={cx(classes.statusIndicator, { 'active': statusText === 'IN', 'warning':statusText === 'BOOKED' })}>
      {getTranslatedStatus(statusText)}
    </div>
  )
}

export default StockProductStatusRow
