import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button, IconButton, CardHeader
} from '@mui/material'
import { useTranslationI18n } from 'i18n/I18nContext'
import { prop, propOr } from 'ramda'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import TextOverflow from 'components/main/TextOverflow'
import TableSkeletonList from 'components/table/TableSkeletonList'
import { useSnackbar } from 'context/snackbar'
import FlexBox from 'components/main/FlexBox'
import { makeStyles } from '@mui/styles'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import { useNavigate } from 'react-router-dom'

export const useStyles = makeStyles({
  list:{
    padding:'0',
    '& .MuiListItemButton-root':{
      borderRadius:'5px',
      '&.active':{
        background:'#f1f1f1'
      }
    },
    '& .MuiListItemIcon-root':{
      minWidth:'30px'
    }
  },
  header:{
    marginTop:'4px',
    height:'40px',
    '& .MuiCardHeader-title':{
      fontWeight:'600',
      fontSize:'18px',
      lineHeight:'21.8px',
      color:'#202020'
    },
    '& .MuiCardHeader-action':{
      marginRight:'0'
    }
  },
  checkboxHeader:{
    color: '#202020',
    width: '18px',
    height: '18px',
    '&.Mui-checked': {
      color: '#202020',
    }
  },
  tabs:{
    border:'1px solid red'
  },
  tableRoot:{
    maxHeight:'460px',
    borderRadius:'7px',
    border:'1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      cursor:'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly api: any
  readonly saveApi?: any
  readonly id: any
  readonly initial: any
}

export function PriceLevelLineItems ({ api, saveApi, id, initial }: Props) {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const classes = useStyles()
  const { t } = useTranslationI18n()

  const initialList = propOr([], 'list', initial)
  const [selected, setSelected] = useState([] as any)

  const list = propOr([], 'list', api) as any
  const loading = prop('loading', api)

  useEffect(() => {
    api.getList({ query: { limit: 1000 } })
  }, [])

  useEffect(() => {
    setSelected(initialList)
  }, [initialList])

  const onCheck = (checked: boolean, variant: any) => {
    const id = prop('id', variant)
    if (checked) {
      setSelected((prevSelected: any) => prevSelected.filter((select: any) => {
        return prop('id', select) !== id
      }))
    } else {
      setSelected((prevSelected: any) => [...prevSelected, variant])
    }
  }

  const onSelectAllRows = useCallback(() => {
    if (list.length === selected.length) {
      setSelected([])
    } else {
      setSelected(list)
    }
  }, [list, selected])

  const onSave = useCallback(() => {
    saveApi.putData({
      data: {
        priceLevelIds:selected.map((level: any) => level.id),
        territoryIds: [Number(id)]
      }
    }).then(() => snackbar({ message: 'Успешно сохранено' }))
      .then(() => navigate(ROUTES.SETTINGS_TERRITORY_LIST))
  }, [selected])

  return (
    <Card>
      <CardHeader
        className={classes.header}
        title="Ценовая политика"
        action={
          <FlexBox align="center" gap={5}>
            <Button onClick={() => navigate(ROUTES.SETTINGS_PRICE_LEVEL_CREATE)}>
              Создать новое
            </Button>
            <Button onClick={onSave}>
              Прекрепить
            </Button>
          </FlexBox>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <TableContainer component={Paper} className={classes.tableRoot}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell width={30} align="center">
                      <Checkbox
                        className={classes.checkboxHeader}
                        color="primary"
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        data-cy="table-checkbox-all"
                        onChange={onSelectAllRows}
                        checked={list.length === selected.length}
                      />
                    </TableCell>
                    <TableCell>{t('Доступная ценовая политика')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {list && list.map((variant: any) => {
                    const id = prop('id', variant)
                    const name = prop('name', variant)
                    const checked = selected.find((select: any) => prop('id', select) === id)
                    return (
                      <TableRow onClick={() => onCheck(checked, variant)}>
                        <TableCell width={30}>
                          <Checkbox checked={Boolean(checked)} color="secondary" />
                        </TableCell>
                        <TableCell>
                          <TextOverflow lines={1}>
                            {name}
                          </TextOverflow>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {loading && (
                    <TableSkeletonList columnsCount={2} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item lg={6}>
            <TableContainer component={Paper} className={classes.tableRoot}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell>{t('Прикрепленная ценовая политика')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {selected && selected.map((variant: any) => {
                    const id = prop('id', variant)
                    const name = prop('name', variant)
                    const checked = selected.find((select: any) => prop('id', select) === id)
                    return (
                      <TableRow>
                        <TableCell>
                          <TextOverflow lines={1}>
                            <FlexBox
                              align="center"
                              justify="space-between"
                            >
                              {name}
                              <IconButton onClick={() => onCheck(checked, variant)}>
                                <DeleteIcon />
                              </IconButton>
                            </FlexBox>
                          </TextOverflow>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {selected.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        sx={{ textAlign: 'start' }}
                      >
                        No Information
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PriceLevelLineItems
