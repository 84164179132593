import * as ROUTES from 'constants/Routes'

import { path } from 'ramda'
import React, { useCallback } from 'react'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'
import { useLocation, useNavigate } from 'react-router-dom'
import { pathParams } from 'path-params'

import { createDocument } from '../api'
import DocumentCreateForm from '../components/DocumentCreateForm'
import { documentCreateSerializer } from '../serializers'

function DocumentCreateContainer () {
  const snackbar = useSnackbar()
  const location = useLocation()
  const navigate = useNavigate()
  const folderCreate = usePost(createDocument)
  const folderId = path(['state', 'id'], location)
  const updateUrl = pathParams(ROUTES.SETTINGS_MEDIA_FILES_DETAIL, { id: folderId })

  const handleCreate = useCallback((data:any) => {
    folderCreate.postData({ data:documentCreateSerializer(data) })
      .then(() => snackbar({ message:'Документ успешно создан' }))
      .then(() => navigate(updateUrl))
  }, [])

  return (
    <DocumentCreateForm
      onSubmit={handleCreate}
      initialValues={{ folderId }}
    />
  )
}

export default DocumentCreateContainer
