import { dissoc, prop, propOr } from 'ramda'

export const warehouseGroupCreateSerializer = (values: any) => {
  const warehouseListValues = propOr([], 'warehouseLineItems', values) as any
  const warehouseLineItems = warehouseListValues.map((warehouse: any, idx: number) => ({
    orderNumber: idx,
    warehouseId: prop('id', warehouse,)
  }))
  const territoryListValues = propOr([], 'territoryList', values) as any
  const territoryList = territoryListValues.map((territory: any) => territory.value)
  const agentListValues = propOr([], 'agentList', values) as any
  const agentList = agentListValues.map((agent: any) => agent.id)
  return { ...values, warehouseLineItems, territoryList, agentList }
}

export const warehouseGroupDetailSerializer = (values: any) => {
  const warehouseItemList = propOr([], 'warehouseItemList', values) as any
  const warehouseLineItems = warehouseItemList.map((warehouse: any) => prop('warehouse', warehouse))
  return { ...values, warehouseLineItems }
}

export const warehouseGroupUpdateSerializer = (values:any) => {
  const fieldsToRemove = ['warehouseItemList']
  const result = fieldsToRemove.reduce((acc, field) => dissoc(field, acc), values)
  const warehouseListValues = propOr([], 'warehouseLineItems', values) as any
  const warehouseLineItems = warehouseListValues.map((warehouse: any, idx: number) => ({
    orderNumber: idx,
    warehouseId: prop('id', warehouse,)
  }))
  const territoryListValues = propOr([], 'territoryList', values) as any
  const territoryList = territoryListValues.map((territory: any) => territory.id)
  return {
    ...result,
    warehouseLineItems,
    territoryList
  }
}
