import React from 'react'
import { path } from 'ramda'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { FormikFormProps, FormikProps } from 'formik'
import FlexBox from 'components/main/FlexBox'
import TimeField from 'components/form/TimeField'
import DateField from 'components/form/DateField'

import UsersField from './form/UsersField'

interface Props{
  readonly form:FormikProps<FormikFormProps>
}

function TimeSlotUpdateForm ({ form }:Props) {
  const name = path(['values', 'name'], form)

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить временной интервал работы пользователя (${name})`}
          rightButton={(
            <>
              <Button
                type="submit"
                disabled={!form.isValid}
              >
                Сохранить изменения
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Название"
                      name="name"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <DateField
                      label="Эффективная дата"
                      name="effectiveDate"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <DateField
                      label="Дата окончания"
                      name="endDate"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                    <UsersField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Описание"
                      name="description"
                      multiline={true}
                      minRows={4}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Время работы" />
              <CardContent>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <FlexBox align="center" justify="space-between" gap={5}>
                      <Typography variant="body1">
                        Понедельник:
                      </Typography>
                      <FlexBox align="center" gap={10} style={{ width:'300px' }}>
                        <TimeField
                          label="Время начала"
                          name="workTimes.MONDAY.startTime"
                        />
                        <TimeField
                          label="Время окончания"
                          name="workTimes.MONDAY.endTime"
                        />
                      </FlexBox>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={8} />
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <FlexBox align="center" justify="space-between" gap={5}>
                      <Typography variant="body1">
                        Вторник:
                      </Typography>
                      <FlexBox align="center" gap={10} style={{ width:'300px' }}>
                        <TimeField
                          label="Время начала"
                          name="workTimes.TUESDAY.startTime"
                        />
                        <TimeField
                          label="Время окончания"
                          name="workTimes.TUESDAY.endTime"
                        />
                      </FlexBox>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={8} />
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <FlexBox align="center" justify="space-between" gap={5}>
                      <Typography variant="body1">
                        Среда:
                      </Typography>
                      <FlexBox align="center" gap={10} style={{ width:'300px' }}>
                        <TimeField
                          label="Время начала"
                          name="workTimes.WEDNESDAY.startTime"
                        />
                        <TimeField
                          label="Время окончания"
                          name="workTimes.WEDNESDAY.endTime"
                        />
                      </FlexBox>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={8} />
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <FlexBox align="center" justify="space-between" gap={5}>
                      <Typography variant="body1">
                        Четверг:
                      </Typography>
                      <FlexBox align="center" gap={10} style={{ width:'300px' }}>
                        <TimeField
                          label="Время начала"
                          name="workTimes.THURSDAY.startTime"
                        />
                        <TimeField
                          label="Время окончания"
                          name="workTimes.THURSDAY.endTime"
                        />
                      </FlexBox>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={8} />
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <FlexBox align="center" justify="space-between" gap={5}>
                      <Typography variant="body1">
                        Пятница:
                      </Typography>
                      <FlexBox align="center" gap={10} style={{ width:'300px' }}>
                        <TimeField
                          label="Время начала"
                          name="workTimes.FRIDAY.startTime"
                        />
                        <TimeField
                          label="Время окончания"
                          name="workTimes.FRIDAY.endTime"
                        />
                      </FlexBox>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={8} />
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <FlexBox align="center" justify="space-between" gap={5}>
                      <Typography variant="body1">
                        Суббота:
                      </Typography>
                      <FlexBox align="center" gap={10} style={{ width:'300px' }}>
                        <TimeField
                          label="Время начала"
                          name="workTimes.SATURDAY.startTime"
                        />
                        <TimeField
                          label="Время окончания"
                          name="workTimes.SATURDAY.endTime"
                        />
                      </FlexBox>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={8} />
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <FlexBox align="center" justify="space-between" gap={5}>
                      <Typography variant="body1">
                        Воскресенье:
                      </Typography>
                      <FlexBox align="center" gap={10} style={{ width:'300px' }}>
                        <TimeField
                          label="Время начала"
                          name="workTimes.SUNDAY.startTime"
                        />
                        <TimeField
                          label="Время окончания"
                          name="workTimes.SUNDAY.endTime"
                        />
                      </FlexBox>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={8} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(TimeSlotUpdateForm)
