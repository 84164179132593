import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getAgentCategoryList = (options?: Options) => {
  return getRequest<any>(API.ContactCategoryList, options)
}

export const agentCategoryCreate = (options?: Options) => {
  return postRequest<any>(API.ContactCategoryCreate, options)
}

export const agentCategoryUpdate = (options?: Options) => {
  return putRequest<any>(API.ContactCategoryUpdate, options)
}

export const agentCategoryGetById = (options?: Options) => {
  return getRequest<any>(API.ContactCategoryGetById, options)
}

export const agentCategoryDelete = (options?: Options) => {
  return deleteRequest<any>(API.ContactCategoryDelete, options)
}
