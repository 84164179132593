import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { propOr, trim } from 'ramda'

import { getCurrencyList } from '../api'
import CurrencyStatusRow from '../components/CurrencyStatusRow'
import CurrencyRowMenu from '../components/CurrencyRowMenu'
import { useLocales } from '../../../../hooks/useLocales'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 150
  },
  {
    headerName: 'Currency name',
    field: 'displayName',
  },
  {
    headerName: 'Currency code',
    field: 'code',
  },
  {
    headerName: 'Supported',
    field: 'supported',
    renderCell:(row:any) => <CurrencyStatusRow status={row.supported} />
  },
]

function CurrencyListContainer () {
  const { t } = useLocales('currency')
  const currencyList = useGetList(getCurrencyList)

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    currencyList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title={t('Currency')}
        dataRequest={currencyList}
        columns={columns}
        onSearchClick={onTableSearch}
        translationModule="currency"
        searchPlaceholder={t('Search by name or ID')}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row) => (
          <CurrencyRowMenu
            item={row}
          />
        )}
      />
    </AppLayout>
  )
}

export default CurrencyListContainer
