import { getRequest, Options, patchRequest } from '../../../../api/BaseApi'
import * as API from '../../../../constants/Api'

export const getPickList = (options?: Options) => {
  return getRequest<any>(API.PickList, options)
}

export const getPickDetail = (options?: Options) => {
  return getRequest<any>(API.PickDetail, options)
}

export const changePickStatus = (options?: Options) => {
  return patchRequest<any>(API.PickChangeStatus, options)
}

export const changePicklistStatus = (options?:Options) => {
  return patchRequest<any>(API.PicklistStatus, options)
}

export const mergePicklist = (options?:Options) => {
  return patchRequest<any>(API.PicklistMerge, options)
}

export const pdfExport = (options?:Options) => {
  return getRequest<any>(API.PicklistExportPDF, options)
}
