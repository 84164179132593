import TableAttach from 'components/table-attach/TableAttach'
import FlexBox from 'components/main/FlexBox'
import { Button, ButtonGroup } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSnackbar } from 'context/snackbar'
import { path, prop, propOr } from 'ramda'
import { usePut } from 'hooks/usePut'
import { useGetList } from 'hooks/useGetList'
import useAllSearchParams from 'utils/useAllSearchParams'

import Filter from './filter/Filter'
import ColumnSettingList from './column-settings/ColumnSettingList'

import {
  addProductToCategory,
  getFilterList,
  getProductList,
  getProductsByCategoryIds
} from '../../api'
import ListImage from '../../../product/components/ListImage'
import ProductStatusRow from '../../../product/components/ProductStatusRow'
import ProductTypeRow from '../../../product/components/ProductTypeRow'
import { getColumnsList } from '../../../product/api'
import { getColumnsBySetting } from '../../../product/components/ProductListFilterColumn'

enum PRODUCT_TABS {
  AVAILABLE='available',
  SELECTED='selected'
}

const tabs = [
  { id:1, value:PRODUCT_TABS.AVAILABLE, label:'Прекрепление' },
  { id:2, value:PRODUCT_TABS.SELECTED, label:'Доступние' }
]

const columns = [
  {
    headerName: 'Наименование товара',
    leftStick:true,
    field: 'name',
    width: 500,
    renderCell: (item: any) => (
      <ListImage id={item.id} src={path(['image', 'small', 'link'], item)} name={item.name} />
    )
  },
  {
    headerName: 'Штрих-код',
    field: 'barcode',
    renderCell: (item: any) => item.barcode && item.barcode.length ? item.barcode.join(', ') : ''
  },
  {
    headerName:'Оповещение о низком запасе',
    field:'lowStockAlert'
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (item: any) => <ProductStatusRow status={item.status} />
  },
  {
    headerName: 'Тип',
    field: 'productType',
    renderCell: (item: any) => <ProductTypeRow type={item.productType} />
  }
]

interface Props{
  readonly categoryIds:number[]
  readonly categoryItems:any[]
}

export function ProductBulkTable ({ categoryIds, categoryItems }:Props) {
  const snackbar = useSnackbar()
  const searchParams = useAllSearchParams()
  const categoryAddProducts = usePut(addProductToCategory)
  const [filterId, setFilterId] = useState('')
  const [settingColumns, setSettingColumns] = useState([])
  const columnList = useGetList(getColumnsList, { query:{ type:'PRODUCT_LIST_PANEL' } })
  const filterList = useGetList(getFilterList, { query: { type:'PRODUCT_LIST_PANEL' } })
  const productListByCategoryIds = useGetList(getProductsByCategoryIds, { data:categoryIds })
  const [selectedProducts, setSelectedProducts] = useState([]) as any
  const [limit, setLimit] = useState(10)
  const productList = useGetList(getProductList, { query: { limit, filterId } })
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)
  const isAvailable = selectedTab === PRODUCT_TABS.AVAILABLE
  const dataRequest = isAvailable ? selectedProducts : productList

  const getColumns = useMemo(() => {
    const settingId = propOr('', 'id', settingColumns)
    if (settingId) {
      return getColumnsBySetting(settingColumns)
    } else return columns
  }, [settingColumns])

  const productIds = useMemo(() => {
    return selectedRows.map((product: { id: number }) => product.id)
  }, [selectedRows])

  useEffect(() => {
    if (filterId) {
      dataRequest.getList()
    }
  }, [filterId])

  const onSetTab = (tab:any) => {
    setSelectedTab(tab.value)
  }

  const onSetProducts = () => {
    setSelectedProducts((prevSelected: any) => Array.from(new Set([...prevSelected, ...selectedRows])))
    setSelectedRows([])
    setSelectedTab(PRODUCT_TABS.SELECTED)
  }

  const onRemoveSelected = useCallback(() => {
    const filteredSelectedProducts = selectedProducts.filter((product:any) => {
      return !selectedRows.some((row:any) => row.id === product.id)
    })
    setSelectedProducts(filteredSelectedProducts)
    setSelectedRows([])
  }, [selectedRows])

  const onAddProducts = useCallback(() => {
    categoryAddProducts.putData({ data:{ categoryIds, productIds } })
      .then(() => snackbar({ message: 'Выбранные продукты успешно добавлены продуктовую корзину' }))
  }, [productIds])

  const onSearch = useCallback((values:any) => {
    const queryIsThere = values && { query: { ...searchParams, searchKey: prop('search', values) } }
    productList.getList(queryIsThere)
  }, [searchParams])

  const onFilter = useCallback((item: any) => {
    setFilterId(item.id)
    setLimit(item.limitRow || 10)
  }, [])

  const onSetColumn = useCallback((item: any) => {
    setSettingColumns(item)
  }, [])

  return (
    <TableAttach
      title={
        <FlexBox align="center" gap={10}>
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            {tabs.map((tab) => (
              <Button
                color="primary"
                variant={selectedTab === tab.value ? 'contained' : 'outlined'}
                onClick={() => {
                  onSetTab(tab)
                  setSelectedRows([])
                }}
              >
                {tab.label}
              </Button>)
            )}
          </ButtonGroup>
          {isAvailable && selectedRows.length > 0 && (
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                onRemoveSelected()
                onAddProducts()
              }}
            >
              Открепить {selectedRows.length}
            </Button>
          )}
          {!isAvailable && selectedRows.length > 0 && (
            <Button
              onClick={() => {
                onSetProducts()
                onAddProducts()
              }}
            >
              Прекрепить {selectedRows.length}
            </Button>
          )}
        </FlexBox>
      }
      dataRequest={isAvailable ? productListByCategoryIds : dataRequest}
      filterRequest={filterList}
      hasChange={isAvailable}
      selectedList={selectedProducts}
      columns={getColumns}
      stateItems={categoryItems}
      onSearchClick={onSearch}
      tabs={['Все', 'Активы', 'Неактивы']}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      checkbox={true}
      limit={limit}
      setLimit={setLimit}
      filterNode={(
        <Filter
          onFilter={onFilter}
          filterList={filterList}
        />
      )}
      columnNode={(
        <ColumnSettingList
          filterList={columnList}
          onFilter={onSetColumn}
        />
      )}
    />
  )
}
