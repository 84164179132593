import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { useConfirm } from 'context/confirm'
import { propOr, trim } from 'ramda'

import TerritoryRowMenu from '../components/TerritoryRowMenu'
import { getTerritoriesList, territoryDelete } from '../api'
import TerritoryTypeRow from '../components/TerritoryStatusRow'
import MoreActionButtons from '../components/MoreRowMenu'
import TerritoryExcelImport from '../components/TerritoryExcelImport'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 150
  },
  {
    headerName: 'Название территории',
    field: 'territoryName',
    width: 500
  },
  {
    headerName: 'Тип территории',
    field: 'territoryType',
    renderCell:(item:any) => <TerritoryTypeRow territoryType={item.territoryType} />
  },
  {
    headerName: 'Дата создания',
    field: 'createdDate',
  },
  {
    headerName: 'Дата обновления',
    field: 'updatedDate',
  },
]

function TerritoryListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const territoryList = useGetList(getTerritoriesList)
  const deleteTerritory = useDelete(territoryDelete)
  const handleDeleteTerritory = useCallback((values: any) => {
    const message = `Вы уверены, что хотите удалить территория ${values.territoryName}?`

    onConfirm({ message })
      .agree(() => {
        deleteTerritory.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Территория успешно удалена.' }))
          .then(() => territoryList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    territoryList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Территория"
        onAddClick={() => navigate(ROUTES.SETTINGS_TERRITORY_CREATE)}
        dataRequest={territoryList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        searchPlaceholder="Поиск по названию или ID"
        exportNode={(
          <TerritoryExcelImport
            list={territoryList}
          />
        )}
        actions={(row) => (
          <TerritoryRowMenu
            item={row}
            handleDelete={handleDeleteTerritory}
          />
        )}
        moreActions={(actions) => (
          <MoreActionButtons
            onOpen={actions.onExportClick}
          />
        )}
      />
    </AppLayout>
  )
}

export default TerritoryListContainer
