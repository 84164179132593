import { AppMenu } from 'constants/App'

import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import LogoLight from 'assets/logo-light.svg'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import { Link } from 'react-router-dom'
import { Badge, Typography } from '@mui/material'
import NotificationIcon from 'assets/notification-icon.svg'
import { useUser } from 'context/user'

import AppHeaderMenuItem from './AppHeaderMenuItem'
import FlexBox from './FlexBox'
import AccountPopover from './AccountPopover'
import { NewsList } from './NewsList'

import LanguageSelect from '../language-select/LanguageSelect'
import { useNotification } from '../../context/notification'
import RightSideDrawer from '../drawer'

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '60px',
    background: '#202020',
    display: 'flex',
    padding: '12px 30px'
  },
  logo: {
    marginRight: '50px'
  },
  dashboardLink: {
    color: '#fff',
    fontSize: '14px',
    lineHeight:'16.94px'
  },
  dashboardLinkTitle: {
    fontWeight: '600 !important'
  },
  notification: {
    width: '35px',
    height: '35px',
    background: '#474747',
    padding: '8px 7px',
    borderRadius: '50%',
    cursor: 'pointer'
  }
})

function AppHeader () {
  const { user } = useUser()
  const classes = useStyles()
  const { count } = useNotification()
  const [open, setOpen] = useState(false)
  return (
    <div className={classes.root}>
      <FlexBox flex align="center" justify="space-between">
        <FlexBox className={classes.logo}>
          <img src={LogoLight} alt="logo" />
        </FlexBox>
        <FlexBox flex>
          <FlexBox gap={50} flex align="center">
            <Link to="#" className={classes.dashboardLink}>
              <FlexBox gap={18}>
                <DashboardOutlinedIcon />
                <Typography className={classes.dashboardLinkTitle}>Дашборд</Typography>
              </FlexBox>
            </Link>
            <FlexBox gap={20} flex>
              {AppMenu.map(page => (
                <AppHeaderMenuItem key={page.key} page={page} />
              ))}
            </FlexBox>
            <FlexBox align="center">
              <FlexBox align="center" gap={20}>
                <Badge badgeContent={count} color="error" onClick={() => setOpen(true)}>
                  <img src={NotificationIcon} alt="notification" className={classes.notification} />
                </Badge>
                <AccountPopover user={user} />
              </FlexBox>
              <LanguageSelect />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <RightSideDrawer open={open} onClose={() => setOpen(false)}>
        <NewsList />
      </RightSideDrawer>
    </div>
  )
}

export default AppHeader
