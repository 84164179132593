import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { useConfirm } from 'context/confirm'
import { useNavigate } from 'react-router'
import { pathOr, propOr, trim } from 'ramda'

import { getAdjustmentList, deleteAdjustment } from '../api'
import AdjustmentRowMenu from '../components/AdjustmentRowMenu'
import AdjustmentStatusRow from '../components/AdjustmentStatusRow'

const columns = [
  {
    headerName: 'Номер корректировки',
    field: 'number',
    width: 200
  },
  {
    headerName: 'Привязанный номер',
    field: 'refNumber',
    width: 200
  },
  {
    headerName: 'Склад',
    field: 'warehouse',
    renderCell: pathOr('', ['warehouse', 'name'])
  },
  {
    headerName: 'Тип корректировки',
    field: 'type'
  },
  {
    headerName: 'статус',
    field: 'active',
    renderCell: (item: any) => <AdjustmentStatusRow status={item.status} />
  }
]

function AdjustmentListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const adjustmentList = useGetList(getAdjustmentList)
  const adjustmentDelete = useDelete(deleteAdjustment)

  const handleDeleteAdjustment = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить корректировку ${values.adjustmentNumber}`

    onConfirm({ message })
      .agree(() => {
        adjustmentDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Корректировка успешно удалена' }))
          .then(() => adjustmentList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    adjustmentList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Корректировки"
        onAddClick={() => navigate(ROUTES.WAREHOUSE_ADJUSTMENT_CREATE)}
        dataRequest={adjustmentList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row) => (
          <AdjustmentRowMenu
            item={row}
            onDelete={handleDeleteAdjustment}
          />
        )}
      />
    </AppLayout>
  )
}

export default AdjustmentListContainer
