import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { createUnitMeasurement } from '../api'
import UnitMeasurementCreateForm from '../components/UnitMeasurementCreateForm'

function UnitMeasurementCreateContainer () {
  const unitMeasurementCreate = usePost(createUnitMeasurement)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = useCallback((values: any) => {
    return unitMeasurementCreate.postData({ data: values })
      .then(() => snackbar({ message: 'Единица измерения успешно создана' }))
      .then(() => navigate(ROUTES.CATALOG_MML))
  }, [])

  return (
    <AppLayout>
      <UnitMeasurementCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default UnitMeasurementCreateContainer
