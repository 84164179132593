import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import SearchField from 'components/form/SearchField'
import { pathOr, prop } from 'ramda'
import { useField } from 'formik'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value, name, id: value }
  }

  return null
}

function SupplierField (props: any) {
  const [, , contactHelpers] = useField('supplierId')
  const [ field, , ] = useField('territories')
  const territoryValue = pathOr([], ['value'], field)
  const territoryIds = territoryValue.map((territory:any) => territory.id)

  useEffect(() => {
    if (!territoryIds.length) {
      contactHelpers.setValue(null)
    }
  }, [territoryIds.length])

  return (
    <SearchField
      name="supplierId"
      params={{ territoryIds }}
      api={API.ContactList}
      disabled={!territoryIds.length}
      getOptionValue={getOptionValue}
      label="Поставщик"
      {...props}
    />
  )
}

export default SupplierField
