import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import TextField from 'components/form/TextField'
import { path } from 'ramda'

import SupplierField from './form/SupplierField'
import LineItems from './form/LineItems'
import ReceiveTablePO from './ReceiveTablePO'

interface Props {
  readonly productList: any
  readonly receiveList: any
  readonly form: any
}

function PurchaseUpdateForm ({ productList, receiveList, form }: Props) {
  const status = path(['values', 'orderStatus'], form)
  const name = path(['values', 'poNumber'], form)
  const isApproved = status === 'APPROVED'

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить заказ на покупку (${name})`}
          rightButton={(
            <>
              <Button type="submit" disabled={isApproved}>Сохранить изменения</Button>
            </>
          )}
        />
      </Header>

      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={6}>
                    <SupplierField disabled={isApproved} />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <TextField name="referenceNumber" label="Привязанный номер" disabled={isApproved} />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <TextField name="notes" label="Примечания" multiline={true} minRows={4} disabled={isApproved} />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems productList={productList} disabled={isApproved} />
          </Grid>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Полученный список" />
              <CardContent>
                <ReceiveTablePO receiveList={receiveList} />
              </CardContent>
              <Divider />
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(PurchaseUpdateForm)
