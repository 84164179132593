import React, { useEffect, useState } from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import { Button, Grid } from '@mui/material'
import { useField } from 'formik'

import AgentBasic from './create/AgentBasic'
import AttachmentField from './form/AttachmentField'
import ImageUpload from './form/ImageUpload'
import { AvailableCustomField } from './custom-field/AvailableCustomField'

interface Props {
  readonly customFields:any
}

function AgentCreateForm ({ customFields }:Props) {
  const [files, setFiles] = useState([] as any)
  const attachmentsField = useField('attachments')

  const attachmentSetFieldValues = attachmentsField[2]

  const handleFileSet = (value: any) => {
    setFiles((prev: any) => [...prev, value])
  }

  useEffect(() => {
    attachmentSetFieldValues.setValue(files)
  }, [files])
  const handleFileDelete = (id: any) => {
    setFiles((prev: any) => prev.filter((img: any) => img.id !== id))
  }

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать контрагент"
          rightButton={(
            <>
              <Button type="submit">Создать</Button>
            </>
          )}
        />
      </Header>

      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <AgentBasic />
          </Grid>
          <Grid item={true} lg={12}>
            <ImageUpload
              isCreateForm={true}
            />
          </Grid>
          <Grid item={true} lg={12}>
            <AttachmentField
              files={files}
              onFileSet={handleFileSet}
              onFileDelete={handleFileDelete}
            />
          </Grid>
          <Grid item={true} lg={12}>
            <AvailableCustomField customFields={customFields} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(AgentCreateForm)
