import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardContent, Divider, Grid } from '@mui/material'
import { usePost } from 'hooks/usePost'
import CardHeader from 'components/card/CardHeader'
import Dropzone from 'components/upload/Dropzone'
import { DeleteOutlined } from '@mui/icons-material'
import { useField } from 'formik'
import { useDelete } from 'hooks/useDelete'

import { deleteAttachment, userFileUpload } from '../../api'
function ImageUpload () {
  const fileUpload = usePost(userFileUpload)
  const deleteImage = useDelete(deleteAttachment)
  const imageField = useField('pictureId')
  const imageValue = imageField[0].value || {}
  const attachmentSetFieldValues = imageField[2]
  const [image, setImages] = useState(imageValue as any)
  const { id, link, originalName } = imageValue
  useEffect(() => {
    attachmentSetFieldValues.setValue(image)
  }, [image])
  const onDrop = useCallback((values: any = []) => {
    const data = new FormData()
    values.forEach((file: any) => {
      data.append('uploadFile', file)
    })
    fileUpload.postData({ data }).then((response) => {
      setImages(response)
    })
  }, [])
  const handleDelete = useCallback((id: any) => {
    deleteImage.deleteData({ params: { id } }).then(() => {
      setImages('')
    })
  }, [])
  return (
    <Card>
      <CardHeader title="Добавить изображение для сотрудник" />
      <CardContent>
        <Grid container={true} spacing={3}>
          {fileUpload.loading && (
            <Grid item={true} lg={3}>Loading...</Grid>
          )}
          {Object.keys(imageValue).length > 0 && <Grid item={true} lg={2} sx={{ position: 'relative' }}>
            <span
              style={{
                position: 'absolute',
                fontSize: 25,
                color: 'white',
                cursor: 'pointer',
                right: 5
              }}
              onClick={() => handleDelete(id)}
            >
              <DeleteOutlined color="error" />
            </span>
            <img src={link} alt={originalName} style={{ height: 200, objectFit: 'cover' }} />
          </Grid>}
          {Object.keys(imageValue).length <= 0 && <Grid item={true} lg={3}>
            <Dropzone onDrop={onDrop} />
          </Grid>}
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default ImageUpload
