import React, { useCallback, useState } from 'react'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'

import FilterListingUpdate from './FilterListingUpdate'
import FilterListingCreate from './FilterListingCreate'

import { deleteFilter, getFilterList } from '../../api'

interface Props {
  readonly filter:any
  readonly onFilter:(item:any) => void
  readonly setOpen?:(value:boolean) => void
  readonly onFilterPreview:any
  readonly filterPreviewData:any
}

const Filter = ({
  filter,
  onFilter,
  setOpen = () => null,
  onFilterPreview,
  filterPreviewData
}:Props) => {
  const snackbar = useSnackbar()
  const filterList = useGetList(getFilterList, { query:{ type:'SALES_ORDER_LIST_PANEL' } })
  const filterDelete = useDelete(deleteFilter)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)

  const onBack = () => {
    setOpenUpdate(false)
    setOpenCreate(false)
  }

  const onCancel = () => {
    setOpen(false)
  }

  const handleFilter = (item:any) => {
    onFilter(item)
  }

  const onFilterPrev = (item:any) => {
    onFilterPreview(item)
  }

  const onDelete = useCallback((id:number) => {
    filterDelete.deleteData({ params: { id } })
      .then(() => filterList.getList())
      .then(() => snackbar({ message: 'Фильтр успешно удален' }))
  }, [])

  return (
    <>
      {openUpdate && (
        <FilterListingUpdate
          id={4}
          onBack={onBack}
          onFilter={handleFilter}
        />
      )}
      {!openCreate && (
        <FilterListingCreate
          filter={filter}
          onBack={onCancel}
          onDelete={onDelete}
          onFilterPrev={onFilterPrev}
          onFilter={handleFilter}
          filterList={filterList}
          filterPreviewData={filterPreviewData}
        />
      )}
    </>
  )
}

export default Filter
