import React, { useEffect, useMemo } from 'react'
import { Tab, Tabs, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'
import { prop, propOr } from 'ramda'

import FlexBox from '../main/FlexBox'
import { useRoutes } from '../../context/route'

const useStyles = makeStyles({
  tabsWrapper: {
    maxWidth: '920px'
  },
  tabsRoot: {
    height: '100%',
    '& .MuiTabs-flexContainer': {
      height: '100%'
    },
    '& .MuiTabs-indicator': {
      height: '3px'
    }
  },
  tabItem: {
    '& .MuiTypography-root': {
      fontWeight: 700,
      textTransform: 'capitalize',
      fontSize: '14px'
    },
    '&.Mui-selected .tab-counter': {
      background: '#1557FF'
    },
    '&.MuiButtonBase-root': {
      padding: '19px 16px'
    },
    '&:not(:last-child)': {
      position: 'relative',
      '&::after': {
        content: '""',
        width: '1px',
        height: '20px',
        background: '#E1E1E1',
        position: 'absolute',
        left: '100%',
        top: '50%',
      }
    }
  },
  tabItemCounter: {
    padding: '3px 10px',
    color: '#fff',
    borderRadius: '10px',
    fontSize: '12px',
    fontWeight: 700,
    background: '#7C7C7C',
    transition: '0.3s ease'
  },
})

interface Props {
  readonly tabs?: any[]
  readonly filter?: any
  readonly status?: number
  readonly tabId?: number
  readonly listingTableName?: string
  readonly setStatus: (status: any) => void
  readonly setTabId: (status: any) => void
}

function TableHeaderTabs ({
  tabs = [{ title: 'Untitled' }],
  filter,
  status,
  tabId,
  setTabId,
  setStatus,
  listingTableName
}: Props) {
  const classes = useStyles()
  const memoizedTabs = useMemo(() => tabs, [tabs])
  const defaultTabName = tabs.length > 0 ? tabs[0]?.name : ''
  const [selectedTab, setSelectedTab] = React.useState(filter?.name || defaultTabName)
  const { isHasActiveTab, changeExactRoute } = useRoutes()
  const lastActiveTab = isHasActiveTab(listingTableName)
  const tabIds = propOr(0, 'tabId', lastActiveTab) as number

  useEffect(() => {
    if (status || tabId || tabIds) {
      const foundTab = tabs.find((item) => item.id === tabId || item.id === status || item.id === tabIds)
      if (foundTab) {
        setSelectedTab(foundTab.name)
      } else {
        setSelectedTab(defaultTabName)
      }
    }
  }, [status, tabId, tabIds, tabs, defaultTabName])

  const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
    setSelectedTab(newValue)
  }

  return (
    <FlexBox align="center" gap={12} className={classes.tabsWrapper}>
      <Tabs
        value={selectedTab}
        className={classes.tabsRoot}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {memoizedTabs.map((item: any) => {
          const name = propOr(prop('name', item), 'name', item) as string
          const count = propOr(0, 'records', item) as string
          return (
            <Tab
              className={classes.tabItem}
              value={name}
              key={item.id}
              onClick={() => {
                setTabId(item.id)
                changeExactRoute(listingTableName, item.id)
                setStatus(item.id)
              }}
              label={
                <FlexBox gap={10}>
                  <Typography>
                    {name}
                  </Typography>
                  <div
                    className={cx(classes.tabItemCounter, 'tab-counter')}>
                    {count}
                  </div>
                </FlexBox>
              }
            />
          )
        })}
      </Tabs>
    </FlexBox>
  )
}

export default TableHeaderTabs
