import { omit, prop } from 'ramda'

export const sortingCreateSerializer = (data:any) => {
  const result = omit(['availableColumns'], data)
  const getUsers = prop('users', result)
  const users = getUsers && getUsers.map((user:any) => user.id)
  const getRoles = prop('roles', result)
  const roles = getRoles && getRoles.map((role:any) => role.role)
  const getCriteria = prop('columns', data)
  const columns = getCriteria && getCriteria.map((column:any, index:number) => {
    const code = prop('code', column)
    const sortType = prop('sortType', column)
    return { code, sortType, index }
  }).filter((item: any) => (item.sortType) != null)
  return { ...result, users, roles, columns }
}

export const initialSortingValues = (data:any) => {
  return (
    {
      ...data
    }
  )
}
