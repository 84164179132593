import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'
import { pathParams } from 'path-params'
import { path } from 'ramda'

import { documentDetail, updateDocument } from '../api'
import DocumentUpdateForm from '../components/DocumentUpdateForm'
import { documentGetInitialValuesSerializer, documentUpdateSerializer } from '../serializers'

function DocumentUpdateContainer () {
  const { id } = useParams()
  const location = useLocation()
  const folderId = path(['state', 'folderId', 'id'], location)
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const brandUpdate = usePut(updateDocument)
  const brandDetail = useGetDetail(documentDetail, { params: { id } })
  const updateURL = pathParams(ROUTES.SETTINGS_MEDIA_FILES_DETAIL, { id:folderId })

  const handleSubmit = useCallback((values: any) => {
    return brandUpdate.putData({ data: documentUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Документ успешно обновлена' }))
      .then(() => navigate(updateURL))
  }, [])

  const initialValues = useMemo(() => documentGetInitialValuesSerializer(brandDetail.result), [brandDetail])

  return (
    <AppLayout>
      {!brandDetail.loading && (
        <DocumentUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        />
      )}
      {brandDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default DocumentUpdateContainer
