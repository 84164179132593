import * as Yup from 'yup'

export const validationSchemaVisitCreateForm = Yup.object().shape({
  typeId:Yup.object().shape({
    id:Yup.string().notRequired(),
    name:Yup.string().notRequired()
  }).required('Требуется тип посещения'),
  // territoryId:Yup.object().shape({
  //   id:Yup.number().required(),
  //   name:Yup.string().required()
  // }).required('Требуется территория.'),
  status:Yup.object().shape({
    id:Yup.string().notRequired(),
    name:Yup.string().notRequired()
  }).required('Требуется статус'),
  addressId:Yup.object().shape({
    id:Yup.string().notRequired(),
    name:Yup.string().notRequired()
  }).required('Требуется адрес')
})

export const validationSchemaVisitUpdateForm = Yup.object().shape({
  typeId:Yup.object().shape({
    id:Yup.string().notRequired(),
    name:Yup.string().notRequired()
  }).required('Требуется тип посещения'),
  addressId:Yup.object().shape({
    id:Yup.string().notRequired(),
    name:Yup.string().notRequired()
  }).required('Требуется адрес'),
  status:Yup.object().shape({
    id:Yup.string().notRequired(),
    name:Yup.string().notRequired()
  }).required('Требуется статус'),
})

export const visitInitialValues = {
  typeId:{
    id:'',
    name:''
  },
  status:{
    id:'',
    name:'',
    code:''
  },
  territoryId: {
    id:'',
    name:''
  },
  comments:[],
  addressId:null,
}
