import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'SAME_DAY', name: 'Тот же день' },
  { value: 'NEXT_DAY', name: 'Следующий день' }
]

function ShippingMethodField () {
  return (
    <SelectField
      name="shippingMethod"
      label="Тип доставки"
      options={OPTIONS}
      defaultValue={OPTIONS[0].value}
    />
  )
}

export default ShippingMethodField
