import React, { useCallback } from 'react'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'

import ColumnCreateForm from './components/TabCreateForm'
import { tabSerializer } from './serializers'

import { createTab } from '../../api'

interface Props {
  readonly onBack:() => void
  readonly filterList:any
}

const TabsCreate = ({ onBack, filterList }:Props) => {
  const snackbar = useSnackbar()
  const filterCreate = usePost(createTab)

  const onCreate = useCallback((values:any) => {
    filterCreate.postData({ data:tabSerializer(values) })
      .then(() => onBack())
      .then(() => filterList.getList())
      .then(() => snackbar({ message: 'Вкладка успешно создан' }))
  }, [])

  return (
    <>
      <ColumnCreateForm
        onBack={onBack}
        initialValues={{ type:'SHIPMENT_LIST_PANEL' }}
        onSubmit={onCreate}
      />
    </>
  )
}

export default TabsCreate
