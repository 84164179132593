import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'
import useDialog from 'hooks/useDialog'
import { LoadImage } from 'components/load-image'
import FullscreenImageViewer from 'components/fullscreen-image-viewer'

interface Props {
  readonly images?:any
  readonly image:any
}

export const SwiperSlider = ({ images, image }:Props) => {
  const dialog = useDialog()
  const [activeImage, setAvtiveImage] = useState('')
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const openFullscreen = (src:string) => {
    setAvtiveImage(src)
    setIsViewerOpen(true)
  }

  const closeFullscreen = () => {
    setIsViewerOpen(false)
    setAvtiveImage('')
  }
  return (
    <>
      <Swiper pagination={true} modules={[Pagination]} className="mySwiper" style={{ height:'100%' }}>
        {images && images.length > 0 && images.map((image:any, index:number) => {
          return (
            <SwiperSlide key={index}>
              <LoadImage
                src={image.link}
                alt="slider-image"
                style={{
                  width:'100%',
                  height:'100%',
                  objectFit:'contain'
                }}
                onClick={() => {
                  setAvtiveImage(image)
                  dialog.handleOpen()
                }}
              />
            </SwiperSlide>
          )
        })}
        <SwiperSlide>
          <LoadImage
            src={image?.link}
            alt="slider-image"
            style={{
              width:'100%',
              height:'100%',
              objectFit:'contain'
            }}
            onClick={() => openFullscreen(image.link)}
          />
        </SwiperSlide>
      </Swiper>
      {isViewerOpen && <FullscreenImageViewer src={activeImage} onClose={closeFullscreen} /> }
    </>
  )
}
