import React from 'react'
import FlexBox from 'components/main/FlexBox'
import DefaultImage from 'assets/pircture-product.svg'
import Box from '@mui/material/Box'
interface Props {
  readonly src: string
  readonly name?:string
}

function ListImage ({ src, name }: Props) {
  return (
    <FlexBox align="center" gap={10}>
      <Box sx={{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width: '32px',
        height: '32px',
        background:'#F4F4F4',
        borderRadius:'50%'
      }}
      >
        {src ? <img src={src} alt="image" style={{ width:'100', height:'100%', borderRadius:'50%' }} />
          : <img src={DefaultImage} alt="image" style={{ borderRadius:'50%' }} />
        }
      </Box>
      {name}
    </FlexBox>
  )
}

export default ListImage
