import * as API from 'constants/Api'

import React from 'react'
import { useField } from 'formik'

import AutoSelectField from './AutoSelectField'

function TerritoryAutoSelect (props: any) {
  const [ orderField, , ] = useField('orderId')
  return (
    <AutoSelectField
      label="Территория"
      name="territoryId"
      api={API.TerritorySuggestions}
      disabled={orderField.value}
      {...props}
    />
  )
}

export default TerritoryAutoSelect
