import { map, omit, path, pipe, prop, toPairs } from 'ramda'

export const createEmployeeSerializer = (values: any) => {
  const newAddresses = prop('addresses', values)
  const addresses = newAddresses && newAddresses.map((address: any) => {
    const countryId = prop('id', address.countryId)
    const regionId = prop('id', address.regionId)
    return { ...address, regionId, countryId }
  })
  const picture = prop('pictureId', values)
  const pictureId = prop('id', picture)
  const supervisors = prop('supervisorIds', values)
  const supervisorIds = supervisors.map((supervisor:any) => supervisor.id)
  const getTerritoryRoles = prop('territoryRoles', values)
  const territoryRoles = getTerritoryRoles && getTerritoryRoles.map((item: any) => {
    const rolesList = prop('roles', item)
    const roleGetValue = rolesList && rolesList.map((role: any) => role.role)
    const territoryId = path(['territoryId', 'id'], item)
    return {
      ...item,
      territoryId,
      roles: roleGetValue,
    }
  })
  return {
    ...values,
    supervisorIds,
    addresses,
    pictureId,
    territoryRoles
  }
}

export const detailEmployerSerializer = (values: any) => {
  const pictureId = prop('picture', values)
  const newAddresses = prop('addresses', values)
  const supervisorIds = prop('supervisors', values)
  const addresses = newAddresses && newAddresses.map((address: any) => {
    const countryId = prop('id', address.country)
    const regionId = prop('id', address.region)
    return { ...address, regionId, countryId }
  })
  return {
    ...values,
    supervisorIds,
    addresses,
    pictureId
  }
}

export const updateEmployerSerializer = (values: any) => {
  const updatedAddresses = prop('addresses', values)
  const getTerritoryRoles = prop('territoryRoles', values)
  const supervisors = prop('supervisorIds', values)
  const territoryRoles = getTerritoryRoles && getTerritoryRoles.map((item: any) => {
    const rolesList = prop('roles', item)
    const roleGetValue = rolesList && rolesList.map((role: any) => role.role)
    const territoryId = path(['territoryId', 'id'], item)
    return {
      ...item,
      territoryId,
      roles: roleGetValue,
    }
  })
  const addresses = updatedAddresses && updatedAddresses.map((address: any) => {
    const countryId = prop('id', address.countryId)
    const regionId = prop('id', address.regionId)
    return { ...address, regionId, countryId }
  })
  const pictureId = path(['pictureId', 'id'], values)
  const resultValues = omit(['picture', 'supervisorIds'], values)
  return {
    ...resultValues,
    addresses,
    pictureId,
    territoryRoles,
    supervisors
  }
}

type KeyType = 'total' | 'active' | 'inactive'
const translateName = (key: KeyType) => {
  const translations = {
    total: 'Bce',
    active: 'Активный',
    inactive: 'Неактивный'
  }
  return translations[key]
}

const keyValues = (key: KeyType) => {
  const values = {
    total: '',
    active: true,
    inactive: false
  }
  return values[key]
}

export const transformTabs = pipe(
  toPairs, // Convert the object into an array of key-value pairs
  map(([key, value]) =>
    ({ id: keyValues(key as KeyType), name: translateName(key as KeyType), records: value }))
  // Map over the pairs and create the desired objects
)
