import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'

import { getRentDetail, updateRent } from '../api'
import { rentDetailSerializer, rentUpdateSerializer } from '../serializer'
import RentUpdateForm from '../components/RentUpdateForm'

function RentUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const rentCreate = usePut(updateRent, { params: { id } })
  const rentGetDetail = useGetDetail(getRentDetail, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    return rentCreate.putData({ data: rentUpdateSerializer(values) })
      .then(() => {
        snackbar({ message: 'Аренда успешно обновлена' })
        navigate(ROUTES.MARKETING_RENT_LIST)
      })
  }, [])
  const initialValues = useMemo(() => rentDetailSerializer(rentGetDetail.result), [rentGetDetail])
  return (
    <AppLayout>
      {!rentGetDetail.loading && (
        <RentUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        />
      )}
    </AppLayout>
  )
}

export default RentUpdateContainer
