import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { pathOr, propOr, trim } from 'ramda'
import useDialog from 'hooks/useDialog'
import { usePut } from 'hooks/usePut'
import { useGetDetailWithoutCall } from 'hooks/useGetDetailWithoutCall'
import { useRoutes } from 'context/route'
import { usePatchList } from 'hooks/usePatchList'

import {
  getShipmentList,
  completeShipment,
  setShipmentPicker,
  setShipmentPacker,
  bulkProcessShipment,
  bulkCompleteShipment,
  getFilterList,
  getColumnsList,
  getByIdTab,
  tabsCounts,
  shipmentFilterPreview,
  setShipmentDriver,
  setShipmentCollect,
  setShipmentReturn
} from '../api'
import ShipmentRowMenu from '../components/ShipmentRowMenu'
import ShipmentStatusRow from '../components/ShipmentStatusRow'
import ShipmentProcessDialogue from '../components/ShipmentProcessDialogue'
import ShipmentPackerDialogue from '../components/ShipmentPackerDialogue'
import ShipmentPickerDialogue from '../components/ShipmentPickerDialogue'
import TableActionsRow from '../components/TableActionRow'
import Filter from '../components/filter/Filter'
import { getColumnsBySetting } from '../components/ShipmentListFilterColumn'
import ColumnSettingList from '../components/column-settings/ColumnSettingList'
import TabsList from '../components/tabs/TabsList'
import { productFilterPreviewSerializer } from '../components/filter/serializer'
import MoreActionButtons from '../components/MoreRowMenu'
import SortingList from '../components/sorting/SortingList'
import { sortingList } from '../../../catalog/product/components/sorting/api'
import ShipmentDriverDialogue from '../components/ShipmentDriverDialogue'
import { hasRelevantStatus } from '../serializer'

const columns = [
  {
    headerName: 'Номер отслеживания доставки',
    field: 'number'
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (item: any) => <ShipmentStatusRow status={item.status} />
  },
  {
    headerName: 'Клиент',
    field: 'contact',
    renderCell: pathOr('', ['contact', 'name'])
  },
  {
    headerName: 'Склад',
    field: 'warehouse',
    renderCell: pathOr('', ['warehouse', 'name'])
  },
  {
    headerName: 'Упаковщик',
    field: 'packer',
    renderCell: pathOr('', ['packer', 'name'])
  },
  {
    headerName: 'Сборщик',
    field: 'picker',
    renderCell: pathOr('', ['picker', 'name'])
  },
  {
    headerName: 'Водитель',
    field: 'driver',
    renderCell: pathOr('', ['driver', 'name'])
  },
  {
    headerName: 'Номер заказа',
    field: 'order',
    renderCell: pathOr(pathOr('', ['salesReturn', 'name']), ['order', 'name'])
  },
  {
    headerName: 'Номер возврата продаж',
    field: 'salesReturn',
    renderCell: pathOr('', ['salesReturn', 'name'])
  },
  {
    headerName: 'Дата доставки',
    field: 'deliveryDate',
  }
]

function ShipmentListContainer () {
  const snackbar = useSnackbar()
  const { isHasActiveTab } = useRoutes()
  const [shipmentIds, setShipmentIds] = useState([]) as any
  const [selectedRows, setSelectedRows] = useState([])
  const [limit, setLimit] = useState(10)
  const [tabId, setTabId] = useState<any>('') as any
  const [filter, setFilter] = useState({}) as any
  const [filterPreviewData, setFilterPreviewData] = useState({}) as any
  const [settingColumns, setSettingColumns] = useState([])
  const [sorting, setSorting] = useState({}) as any
  const columnList = useGetList(getColumnsList, { query:{ type:'SHIPMENT_LIST_PANEL' } })
  const tabList = useGetList(tabsCounts)
  const filterList = useGetList(getFilterList, { query:{ type:'SHIPMENT_LIST_PANEL' } })
  const shipmentList = useGetList(getShipmentList, { query: { limit } })
  const getSortingList = useGetList(sortingList, { query: { type:'SHIPMENT_LIST_PANEL' } })
  const listingTabDetail = useGetDetailWithoutCall(getByIdTab)
  const listingTabsRecords = useGetDetailWithoutCall(tabsCounts)
  const shipmentProcess = usePut(bulkProcessShipment)
  const shipmentSetPicker = usePut(setShipmentPicker)
  const shipmentSetPacker = usePut(setShipmentPacker)
  const shipmentComplete = usePut(completeShipment)
  const shipmentProcessDialogue = useDialog()
  const shipmentPickerDialogue = useDialog()
  const shipmentPackerDialogue = useDialog()
  const shipmentDriverDialogue = useDialog()
  const shipmentBulkComplete = usePut(bulkCompleteShipment)
  const shipmentDriverAssign = usePut(setShipmentDriver)
  const shipmentCollected = usePut(setShipmentCollect)
  const shipmentReturned = usePut(setShipmentReturn)
  const shipmentFilterPreviewList = usePatchList(
    shipmentFilterPreview,
    { data:productFilterPreviewSerializer(filterPreviewData) })

  const tabs = propOr([], 'result', listingTabsRecords) as any
  const lastActiveTab = isHasActiveTab('shipment')
  const lastActiveTabId = propOr('', 'tabId', lastActiveTab) as number
  const listGet =
    Object.keys(filterPreviewData).length === 0 ? shipmentList : shipmentFilterPreviewList
  const isHasReturnItem = hasRelevantStatus(selectedRows)

  useEffect(() => {
    const updateDetails = (id:number) => {
      listingTabDetail.getDetail({ params: { id } })
        .then((res) => {
          const settings = propOr({}, 'listingColumn', res) as any
          const filter = propOr({}, 'listingFilter', res) as any
          const sorting = propOr({}, 'listingSort', res)
          setFilter(filter)
          setSorting(sorting)
          setSettingColumns(settings)
        })
    }
    if (lastActiveTabId) {
      updateDetails(lastActiveTabId)
    }
  }, [tabId, lastActiveTabId])

  useEffect(() => {
    if (Object.keys(filterPreviewData).length > 0) {
      listGet.getList()
    } if (filterPreviewData.length === 0) {
      listGet.getList()
    }
  }, [filterPreviewData])

  const getColumns = useMemo(() => {
    const settingId = propOr('', 'id', settingColumns)
    if (settingId) {
      return getColumnsBySetting(settingColumns)
    } else return columns
  }, [settingColumns])

  const selectedIds = useMemo(() => selectedRows.map((item: any) => item.id), [selectedRows])

  const onProcessShipment = useCallback((values: any) => {
    const driverId = pathOr(0, ['driverId', 'id'], values)
    const shipmentIds = propOr([], 'shipmentIds', values)
    shipmentProcess.putData({ data: { driverId, shipmentIds } })
      .then(() => snackbar({ message: 'Доставка успешно обновлена, в процессе' }))
      .then(() => shipmentList.getList())
      .then(() => shipmentProcessDialogue.handleClose())
  }, [])

  const onDriverAssign = useCallback((values: any) => {
    const shipmentId = pathOr(0, ['shipmentIds', '0'], values)
    const driverId = pathOr(0, ['driverId', 'id'], values)
    shipmentDriverAssign.putData({ data: { shipmentId, driverId } })
      .then(() => snackbar({ message: 'Доставка успешно назначена водителю' }))
      .then(() => shipmentList.getList())
      .then(() => shipmentDriverDialogue.handleClose())
  }, [])

  const onCollected = useCallback((values: any) => {
    shipmentCollected.putData({ params: { id:values } })
      .then(() => snackbar({ message: 'Доставка успешно собрана' }))
      .then(() => shipmentList.getList())
  }, [])

  const onReturned = useCallback((values: any) => {
    shipmentReturned.putData({ params: { id:values } })
      .then(() => snackbar({ message: 'Доставка успешно возвращена' }))
      .then(() => shipmentList.getList())
  }, [])

  const onComplete = useCallback((id: number) => {
    shipmentComplete.putData({ params: { id } })
      .then(() => snackbar({ message: 'Доставка успешно завершена' }))
      .then(() => shipmentList.getList())
  }, [])

  const onBulkComplete = useCallback(() => {
    shipmentBulkComplete.putData({ data: selectedIds })
      .then(() => snackbar({ message: 'Выбранный доставки успешно завершена' }))
      .then(() => shipmentList.getList())
  }, [selectedIds])

  const onSetPickerShipment = useCallback((values: any) => {
    const pickerId = pathOr(0, ['pickerId', 'id'], values)
    const shipmentIds = propOr([], 'shipmentIds', values)
    shipmentSetPicker.putData({ data: { pickerId, shipmentIds } })
      .then(() => snackbar({ message: 'Доставки успешно обновлены' }))
      .then(() => shipmentList.getList())
      .then(() => setSelectedRows([]))
      .then(() => shipmentPickerDialogue.handleClose())
  }, [])

  const onSetPackerShipment = useCallback((values: any) => {
    const packerId = pathOr(0, ['packerId', 'id'], values)
    const shipmentIds = propOr(0, 'shipmentIds', values)
    shipmentSetPacker.putData({ data: { packerId, shipmentIds } })
      .then(() => snackbar({ message: 'Доставки успешно обновлены' }))
      .then(() => shipmentList.getList())
      .then(() => setSelectedRows([]))
      .then(() => shipmentPackerDialogue.handleClose())
  }, [])

  const onFilter = useCallback((item: any) => {
    setFilter(item)
    setLimit(item.limitRow || 10)
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    shipmentList.getList({ query: { searchKey: search } })
  }, [])

  const onSetColumn = useCallback((item: any) => {
    setSettingColumns(item)
  }, [])

  const onFilterPreview = useCallback((filter:any) => {
    setFilterPreviewData(filter)
  }, [])

  const onSetTab = useCallback((tabId:number) => {
    setTabId(tabId)
  }, [])

  return (
    <AppLayout>
      <Table
        title="Доставка"
        dataRequest={listGet}
        columns={getColumns}
        onSearchClick={onTableSearch}
        tabRequest={listingTabsRecords}
        lastActiveTab={lastActiveTab}
        listingTableName="shipment"
        tabId={tabId}
        setTabId={setTabId}
        checkbox={true}
        actionsBan={isHasReturnItem}
        actionBanWarning={'Пожалуйста, отмените выбор доставку со статусами «Возврат», «Завершено»' +
          'или «Доставлено». У нас нет массовых действий с этим статусом доставки.'}
        tabs={tabs}
        limit={limit}
        setLimit={setLimit}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        actions={(row) => (
          <ShipmentRowMenu
            item={row}
            onReturned={onReturned}
            onDriverAssign={(id) => {
              setShipmentIds([id])
              shipmentDriverDialogue.handleOpen()
            }}
            onCollected={onCollected}
            onProcess={(id) => {
              setShipmentIds([id])
              shipmentProcessDialogue.handleOpen()
            }}
            onSetPacker={(id) => {
              setShipmentIds([id])
              shipmentPackerDialogue.handleOpen()
            }}
            onComplete={onComplete}
          />
        )}
        moreActions={(actions) => (
          <MoreActionButtons
            actions={actions}
          />
        )}
        tableActions={(
          <TableActionsRow
            handleSetPacker={() => {
              setShipmentIds(selectedIds)
              shipmentPackerDialogue.handleOpen()
            }}
            handleProcess={() => {
              setShipmentIds(selectedIds)
              shipmentProcessDialogue.handleOpen()
            }}
            handleBulkComplete={onBulkComplete}
          />
        )}
        filterNode={(
          <Filter
            filter={filter}
            onFilter={onFilter}
            filterList={filterList}
            onFilterPreview={onFilterPreview}
            filterPreviewData={filterPreviewData}
          />
        )}
        columnNode={(
          <ColumnSettingList
            filterList={columnList}
            onFilter={onSetColumn}
          />
        )}
        tabNode={(
          <TabsList
            filterList={tabList}
            onFilter={onSetTab}
          />
        )}
        sortingNode={(
          <SortingList
            sorting={sorting}
            filterList={getSortingList}
          />
        )}
      />
      {shipmentProcessDialogue.open && (
        <ShipmentProcessDialogue
          open={shipmentProcessDialogue.open}
          handleClose={shipmentProcessDialogue.handleClose}
          onSubmit={onProcessShipment}
          initialValues={{ shipmentIds }}
          loading={shipmentProcess.loading}
        />
      )}
      {shipmentPickerDialogue.open && (
        <ShipmentPickerDialogue
          open={shipmentPickerDialogue.open}
          handleClose={shipmentPickerDialogue.handleClose}
          onSubmit={onSetPickerShipment}
          initialValues={{ shipmentIds }}
          loading={shipmentSetPicker.loading}
        />
      )}
      {shipmentPackerDialogue.open && (
        <ShipmentPackerDialogue
          open={shipmentPackerDialogue.open}
          handleClose={shipmentPackerDialogue.handleClose}
          onSubmit={onSetPackerShipment}
          initialValues={{ shipmentIds }}
          loading={shipmentSetPacker.loading}
        />
      )}
      {shipmentDriverDialogue.open && (
        <ShipmentDriverDialogue
          open={shipmentDriverDialogue.open}
          handleClose={shipmentDriverDialogue.handleClose}
          onSubmit={onDriverAssign}
          initialValues={{ shipmentIds }}
          loading={shipmentDriverAssign.loading}
        />
      )}
    </AppLayout>
  )
}

export default ShipmentListContainer
