import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import useScrollList from 'hooks/useScrollList'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { getBonusById, getProductList, updateBonus } from '../api'
import { BonusInitialValuesSerializer, bonusUpdateSerializer } from '../serializer'
import BonusUpdateForm from '../components/BonusUpdateForm'

function BonusUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const bonusDetail = useGetDetail(getBonusById, { params: { id } })
  const bonusUpdate = usePut(updateBonus)
  const productList = useScrollList(getProductList)

  const handleSubmit = useCallback((values: any) => {
    return bonusUpdate.putData({ data: bonusUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Акция успешно обновлена' }))
      .then(() => navigate(ROUTES.MARKETING_BONUS_LIST))
  }, [])
  const initialValues = useMemo(() => BonusInitialValuesSerializer(bonusDetail.result), [bonusDetail.result])
  return (
    <AppLayout>
      {!bonusDetail.loading && (
        <BonusUpdateForm
          productList={productList}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      )}
    </AppLayout>
  )
}

export default BonusUpdateContainer
