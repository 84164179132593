import * as ROUTES from 'constants/Routes'

import { MenuItem } from '@mui/material'
import ReactToPrint from 'react-to-print'
import React, { useMemo } from 'react'
import { ALTER_ERROR, useSnackbar } from 'context/snackbar'
import { useNavigate } from 'react-router-dom'
import { pathParams } from 'path-params'

import { PRODUCT_BULK_EDIT_TABS } from './ProductBulkEditTabs'

interface Props{
  readonly printRef:any
  readonly selectedRows:any
  readonly handleDeleteSelected:() => void
  readonly selectedNoBarcode?:() => void
  readonly onChangeStatus:(status:string) => void
  readonly onGetExcelSelected:() => void
}
function TableActionsRow ({
  printRef, selectedRows,
  handleDeleteSelected,
  selectedNoBarcode,
  onChangeStatus,
  onGetExcelSelected
}:Props) {
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const productItems = useMemo(() => {
    return selectedRows.map((item: { id: number, name:string }) => {
      return { id:item.id, name:item.name }
    })
  }, [selectedRows])

  const updateURL = pathParams(ROUTES.CATALOG_PRODUCT_BULK_UPDATE, { tab:PRODUCT_BULK_EDIT_TABS.GENERAL })

  return (
    <>
      <MenuItem
        onClick={() =>
          navigate(updateURL,
            { state: { productItems } })
        }
      >
        Массовое редактирование
      </MenuItem>
      {selectedNoBarcode && (
        <MenuItem onClick={() => snackbar({ message: 'Выбран товар без штрих кода', type: ALTER_ERROR })}>
          Распечатать штрих-коды
        </MenuItem>
      )}
      {!selectedNoBarcode && (
        <ReactToPrint
          trigger={() => (
            <MenuItem>
              Распечатать штрих-коды
            </MenuItem>
          )}
          content={() => printRef.current}
        />
      )}
      <MenuItem onClick={() => onChangeStatus('ACTIVE')}>
        Активировать выбранное
      </MenuItem>
      <MenuItem onClick={() => onChangeStatus('INACTIVE')}>
        Деактивировать выбранное
      </MenuItem>
      <MenuItem onClick={onGetExcelSelected}>
        Экспортировать выбранные
      </MenuItem>
      <MenuItem onClick={handleDeleteSelected}>
        Удалить выбранное
      </MenuItem>
    </>
  )
}

export default TableActionsRow
