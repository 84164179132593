import React from 'react'
import { path, pathOr, prop, propOr } from 'ramda'

import ProductStatusRow from './ProductStatusRow'
import ProductTypeRow from './ProductTypeRow'
import ListImage from './ListImage'

const fieldMappings: { [key: string]: string } = {
  NAME:'name',
  CODE:'barcode',
  BARCODE:'barcode',
  lowStockAlert: 'Оповещение о низком запасе',
  STATUS: 'status',
  SHORT_NAME: 'shortName',
  SORT_NUMBER: 'sortNumber',
  SKU: 'sku',
  PIECES: 'pieces',
  PRODUCED_IN: 'producedIn',
  DESCRIPTION: 'description',
  WEIGHT: 'weight',
  WEIGHT_BRUTTO: 'weightBrutto',
  HEIGHT: 'height',
  PRODUCT_TYPE: 'productType',
  PACKAGE_LINE_ITEM: 'packageLineItems',
  SUPPLIERS: 'suppliers',
  CATEGORIES: 'categories',
  BRAND: 'brand',
  UNIT_MEASUREMENT: 'unitMeasurement',
  ENABLE_EXPIRY_DATE_TRACKING: 'enableExpiryDateTracking',
  ENABLE_BATCH_NUMBERS: 'enableBatchNumbers',
  MODIFIED_DATE: 'modifiedDate',
  CREATED_DATE: 'createdDate',
  CREATED_BY: 'createdBy',
  MODIFIED_BY: 'modifiedBy',
  SELL_IN_PACKAGES: 'sellInPackages'
}

export const fieldMappingsProducts = (code:string, name:string):string => {
  return fieldMappings[code] || name
}

const getRenderCell = (name: string) => {
  if (name === 'name') {
    return (item: any) => {
      const id = prop('id', item)
      const src = path(['image', 'small', 'link'], item) || ''
      const name = item.name || ''
      return <ListImage id={id} src={src} name={name} />
    }
  } else if (name === 'barcode') {
    return (item: any) => item.barcode && item.barcode.length ? item.barcode.join(', ') : ''
  } else if (name === 'categories') {
    return (item: any) => (item.categories && item.categories.map((category: { name: string }) => category.name)) || ''
  } else if (name === 'suppliers') {
    return (item: any) => (item.suppliers && item.suppliers.map((supplier: { name: string }) => supplier.name)) || ''
  } else if (name === 'packageLineItems') {
    return (item:any) => (item.packageLineItems && item.packageLineItems.map((item:any) => item.quantity)) || ''
  } else if (name === 'status') {
    return (item: any) => <ProductStatusRow status={item.status} />
  } else if (name === 'brand') {
    return pathOr('', ['brand', 'name'])
  } else if (name === 'productType') {
    return (item: any) => <ProductTypeRow type={item.productType} />
  } else if (name === 'unitMeasurement') {
    return pathOr('', ['unitMeasurement', 'name'])
  } else if (name === 'createdBy') {
    return pathOr('', ['createdBy', 'name'])
  } else if (name === 'modifiedBy') {
    return pathOr('', ['createdBy', 'name'])
  } else return null
}

export const getColumnsBySetting = (setting: any) => {
  const columns = propOr([], 'columns', setting) as any
  return columns.map((column: any, index: number) => {
    const name = propOr(index, 'name', column) as string
    const code = propOr(name, 'code', column) as string
    const freeze = prop('freeze', column)
    const conditions = prop('conditions', column)
    const width = prop('width', column)
    const renderCell = getRenderCell(fieldMappingsProducts(code, name))
    return {
      headerName: fieldMappingsProducts(code, name),
      leftStick: freeze,
      field: fieldMappingsProducts(code, name),
      conditions,
      width,
      renderCell
    }
  })
}
