import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import FlexBox from 'components/main/FlexBox'
import TextField from 'components/form/TextField'
import { useGetList } from 'hooks/useGetList'
import { prop } from 'ramda'
import { useField } from 'formik'
import useDebounce from 'hooks/useDebounce'

import { UserCard } from './UserCard'

import { getTrackingUsers } from '../api'

const useStyles = makeStyles({
  sidebarWrapper:{
    padding:'10px'
  },
  header:{
    height:'50px',
  },
  sidebarContent:{
    height:'calc(100vh - 134px)',
    overflowY:'scroll',
    padding:'5px',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888', // You can customize the color
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555', // You can customize the hover color
    },
    scrollBehavior: 'smooth',
  },
})

interface Props {
  readonly setUser: any
}

function SidebarLeftSide ({ setUser }: Props) {
  const classes = useStyles()
  const userList = useGetList(getTrackingUsers)
  const list = prop('list', userList)
  const [, , helpers] = useField('userIds')
  const [searchField] = useField('search')
  const debouncedValue = useDebounce(searchField.value || '')

  // State to track selected users
  const [selectedUsers, setSelectedUsers] = useState<any[]>([])

  useEffect(() => {
    userList.getList({ query: { searchKey: debouncedValue } })
  }, [debouncedValue])

  // Function to toggle user selection
  const toggleUserSelection = (user: any) => {
    const isSelected = selectedUsers.some(selectedUser => selectedUser.profileId === user.profileId)
    const newSelectedUsers = isSelected
      ? selectedUsers.filter(selectedUser => selectedUser.profileId !== user.profileId)
      : [...selectedUsers, user]

    setSelectedUsers(newSelectedUsers)
    helpers.setValue(newSelectedUsers.map(user => user.profileId))
    setUser(newSelectedUsers)
  }

  return (
    <div className={classes.sidebarWrapper}>
      <div className={classes.header}>
        <TextField
          placeholder="Search"
          name="search"
        />
      </div>
      <div className={classes.sidebarContent}>
        <FlexBox direction="column" gap={8}>
          {list && list.map((user: any) => (
            <UserCard
              key={user?.profileId}
              user={user}
              toggleUserSelection={toggleUserSelection}
            />
          ))}
        </FlexBox>
      </div>
    </div>
  )
}

export default SidebarLeftSide
