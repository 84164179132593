import React from 'react'
import { Card, CardContent, Divider, Grid } from '@mui/material'
import { pathOr, propOr } from 'ramda'
import CardHeader from 'components/card/CardHeader'
import { toNumberFormat } from 'utils/formatMoney'

interface Props {
  readonly items: any
}

function SaleOrderSummery ({ items }: Props) {
  const summery = items.reduce((a: any, b: any) => {
    const salesPrice = toNumberFormat(propOr(0, 'salesPrice', b))
    const quantity = propOr(0, 'quantity', b) as number
    return salesPrice * quantity + a
  }, 0) || 0

  const summeryWithDiscount = items.reduce((a: any, b: any) => {
    const discountPercent = pathOr(0, ['discountId', 'rate'], b) as number
    const salesPrice = toNumberFormat(propOr(0, 'salesPrice', b)) as number
    const quantity = propOr(0, 'quantity', b) as number
    const discount = discountPercent ? (discountPercent / 100) : 0
    const priceForOne = salesPrice - salesPrice * discount
    return priceForOne * quantity + a
  }, 0) || 0

  return (
    <Grid item={true} lg={12}>
      <Card>
        <CardHeader title="Суммарно" />
        <CardContent>
          <Grid container={true} spacing={3}>
            <Grid item={true} lg={12}>
              Суммарно с скидкой: {toNumberFormat(summeryWithDiscount)}
            </Grid>
            <Grid item={true} lg={12}>
              Суммарно: {toNumberFormat(summery)}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </Grid>
  )
}

export default SaleOrderSummery
