import React from 'react'
import { path } from 'ramda'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'

import CategoryField from './CategoryField'
import StatusField from './StatusField'
import UnitMeasurementField from './UnitMeasurementField'
import BrandField from './BrandField'
import SupplierField from './SupplierField'
import ProductTypeField from './ProductTypeField'
import PackageField from './PackageField'
import CountryField from './CountryField'

import { FILTER_FIELD_TYPE, FILTER_LOOKUP_TYPE } from '../../constants'

const getFieldByType = (fieldType: string, lookUpType:string, name:string, rest: any) => {
  if (fieldType === FILTER_FIELD_TYPE.LOOKUP && lookUpType === FILTER_LOOKUP_TYPE.UNIT_MEASUREMENT) {
    return <UnitMeasurementField {...rest} />
  }
  if (fieldType === FILTER_FIELD_TYPE.LOOKUP && lookUpType === FILTER_LOOKUP_TYPE.PACKAGE_LINE_ITEM) {
    return <PackageField {...rest} />
  }
  if (fieldType === FILTER_FIELD_TYPE.LOOKUP && lookUpType === FILTER_LOOKUP_TYPE.PRODUCT_CATEGORY) {
    return <CategoryField {...rest} />
  }
  if (fieldType === FILTER_FIELD_TYPE.LOOKUP && lookUpType === FILTER_LOOKUP_TYPE.SUPPLIER) {
    return <SupplierField {...rest} />
  }
  if (fieldType === FILTER_FIELD_TYPE.LOOKUP && lookUpType === FILTER_LOOKUP_TYPE.BRAND) {
    return <BrandField {...rest} />
  }
  if (fieldType === FILTER_FIELD_TYPE.LOOKUP && lookUpType === FILTER_LOOKUP_TYPE.PRODUCT_STATUS) {
    return <StatusField {...rest} />
  }
  if (fieldType === FILTER_FIELD_TYPE.TEXT_BOX && lookUpType === FILTER_LOOKUP_TYPE.PRODUCT_TYPE) {
    return <ProductTypeField {...rest} />
  }
  if (fieldType === FILTER_FIELD_TYPE.TEXT_BOX && name === 'produced_in') {
    return <CountryField {...rest} />
  }
  if (fieldType === FILTER_FIELD_TYPE.DATE_TIME_PICKER) {
    return (
      <DateField
        label="Дата"
        {...rest}
      />
    )
  }
  if (fieldType === FILTER_FIELD_TYPE.NUMBER_BOX) {
    return (
      <TextField
        label="Ценить"
        type="number"
        {...rest}
      />
    )
  }

  return (
    <TextField
      label="Значение поля"
      {...rest}
    />
  )
}

export const FilterComparatorValueField = (props: any) => {
  const { item, ...rest } = props
  const fieldType = path(['code', 'fieldType'], item)
  const lookUpType = path(['code', 'lookUpType'], item)
  const name = path(['code', 'name'], item)
  return getFieldByType(fieldType, lookUpType, name, rest)
}
