import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getReceiveList = (options?: Options) => {
  return getRequest<any>(API.ReceiveList, options)
}

export const receiveCreate = (options?: Options) => {
  return postRequest<any>(API.ReceiveCreate, options)
}

export const deleteReceive = (options?: Options) => {
  return deleteRequest<any>(API.ReceiveDelete, options)
}

export const completeReceive = (options?: Options) => {
  return patchRequest<any>(API.ReceiveComplete, options)
}

export const updateReceive = (options?: Options) => {
  return putRequest<any>(API.ReceiveUpdate, options)
}

export const getReceiveDetail = (options?: Options) => {
  return getRequest<any>(API.ReceiveDetail, options)
}

export const getProductList = (options?: Options) => {
  return getRequest<any>(API.ProductList, options)
}
