import { path, pathOr, propOr } from 'ramda'

export const purchaseCreateSerializer = (values: any) => {
  const contactId = path(['contactId', 'value'], values)
  const toWarehouseId = path(['toWarehouseId', 'value'], values)
  const items = propOr([], 'items', values) as any
  const changedItems = items.map((item: any) => ({ productId: item.id, umId: 0, ...item }))
  return { ...values, items: changedItems, contactId, toWarehouseId }
}

export const purchaseDetailSerializer = (values: any) => {
  const contactId = pathOr(0, ['contact', 'contactId'], values)
  return { contactId, ...values }
}
