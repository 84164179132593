import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'

import { validationSchema } from './TransferCashCreateContainer'

import { transferCashDetail, transferCashUpdate } from '../api'
import TransferCashUpdateForm from '../components/TransferCashUpdateForm'
import { transferCashDetailSerializer, transferCashUpdateSerializer } from '../serializer'

function TransferCashUpdateContainer () {
  const { id } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const transferUpdate = usePut(transferCashUpdate)
  const transferDetail = useGetDetail(transferCashDetail, { params: { id: id } })

  const handleSubmit = useCallback((values: any) =>
    transferUpdate.putData({ data: transferCashUpdateSerializer(values) })
      .then(() => navigate(ROUTES.FINANCE_MOVING_BETWEEN_CASH))
      .then(() => snackbar({ message: 'Перемещение успешно обновлён' }))
  , [])

  const initialValues = useMemo(() => transferCashDetailSerializer(transferDetail.result), [transferDetail])

  return (
    <AppLayout>
      {!transferDetail.loading &&
        <TransferCashUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
        />
      }
    </AppLayout>
  )
}

export default TransferCashUpdateContainer
