import React, { ReactNode } from 'react'
import { IconButton } from '@mui/material'
import RefreshIcon from 'assets/refresh-icon.svg'
import SearchIcon from 'components/icons/SearchIcon'
import FilterIcon from 'assets/filter-icon.svg'
import TabSettingsIcon from 'assets/tab-settings-icon.svg'
import { makeStyles } from '@mui/styles'

import FlexBox from '../main/FlexBox'

const useStyles = makeStyles({
  actionButton: {
    width: '50px',
    position: 'relative',

    '&::after': {
      content: '""',
      width: '1px',
      height: '20px',
      background: '#E1E1E1',
      position: 'absolute',
      left: '100%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },

    '& .MuiButtonBase-root': {
      width: '45px',
      height: '45px'
    }
  },
})

interface Props {
  readonly onRefreshClick?: () => void
  readonly onTabSettingsClick?: () => void
  readonly onFilterClick?: () => void
  readonly onExportClick:() => void
  readonly setOpenSearch: (open:boolean) => void
  readonly refreshDisabled?: boolean
  readonly moreActions?:(onOpen:() => void) => ReactNode
}

function TableAttachHeaderButtons (props: Props) {
  const classes = useStyles()
  const {
    onRefreshClick,
    onFilterClick,
    onExportClick,
    onTabSettingsClick,
    moreActions,
    setOpenSearch,
    refreshDisabled
  } = props
  const handleClick = () => {
    setOpenSearch(true)
  }

  return (
    <FlexBox align="center">
      {onRefreshClick && (
        <FlexBox justify="center" flex className={classes.actionButton}>
          <IconButton onClick={onRefreshClick} disabled={refreshDisabled}>
            <img src={RefreshIcon} alt="refresh" />
          </IconButton>
        </FlexBox>
      )}
      <FlexBox justify="center" flex className={classes.actionButton}>
        <IconButton onClick={handleClick}>
          <SearchIcon />
        </IconButton>
      </FlexBox>
      {onFilterClick && (
        <FlexBox justify="center" flex className={classes.actionButton}>
          <IconButton onClick={onFilterClick}>
            <img src={FilterIcon} alt="logo" />
          </IconButton>
        </FlexBox>
      )}
      {onTabSettingsClick && (
        <FlexBox justify="center" flex className={classes.actionButton}>
          <IconButton onClick={onTabSettingsClick}>
            <img src={TabSettingsIcon} alt="logo" />
          </IconButton>
        </FlexBox>
      )}
      {(onExportClick && moreActions && (
        <FlexBox justify="center" flex className={classes.actionButton}>
          {moreActions && moreActions(onExportClick)}
        </FlexBox>
      ))}
    </FlexBox>
  )
}

export default TableAttachHeaderButtons
