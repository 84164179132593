import * as ROUTES from 'constants/Routes'

import React from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { pathParams } from 'path-params'
import { useNavigate } from 'react-router'
import { propOr } from 'ramda'

interface Props {
  readonly item: any
  readonly onDelete: (id: number) => void
  readonly onApprove: (orderId: number) => void
  readonly onReject: (orderId: number) => void
  readonly onDeliver: (orderId: number) => void
  readonly onProcess: (orderId: number) => void
}

function PurchaseRowMenu ({ item, onDelete, onApprove, onReject, onDeliver, onProcess }: Props) {
  const navigate = useNavigate()
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null)

  const anchorIsOpen = Boolean(optionsAnchorEl)

  const handleOpenOptions = (event: any) => {
    setOptionsAnchorEl(event.currentTarget)
  }

  const handleCloseOptions = () => {
    setOptionsAnchorEl(null)
  }

  const status = propOr('PENDING', 'orderStatus', item) as string
  const isPending = status === 'PENDING'
  const isApproved = status === 'APPROVED'
  const isRejected = status === 'REJECTED'

  const updatePath = pathParams(ROUTES.FINANCE_PURCHASE_UPDATE, { id: item.id })

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={anchorIsOpen ? 'long-menu' : undefined}
        aria-expanded={anchorIsOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenOptions}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="table-item-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={optionsAnchorEl}
        open={anchorIsOpen}
        onClose={handleCloseOptions}
      >
        <MenuItem
          onClick={() => {
            navigate(updatePath)
            handleCloseOptions()
          }}
        >
          Просмотр
        </MenuItem>
        {(isPending || isRejected) && (
          <MenuItem
            onClick={() => {
              onDelete(item)
              handleCloseOptions()
            }}
          >
            Удалить
          </MenuItem>
        )}
        {isPending && (
          <MenuItem
            onClick={() => {
              onApprove(item.id)
              handleCloseOptions()
            }}
          >
            Подтвердить
          </MenuItem>
        )}
        {isApproved && (
          <MenuItem
            onClick={() => {
              onProcess(item.id)
              handleCloseOptions()
            }}
          >
            Получить
          </MenuItem>
        )}
        {isApproved && (
          <MenuItem
            onClick={() => {
              onDeliver(item.id)
              handleCloseOptions()
            }}
          >
            Завершить
          </MenuItem>
        )}
        {isPending && (
          <MenuItem
            onClick={() => {
              onReject(item.id)
              handleCloseOptions()
            }}
          >
            Отклонить
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  )
}

export default PurchaseRowMenu
