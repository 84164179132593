import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getEmployeeList = (options?: Options) => {
  return getRequest<any>(API.EmployeeList, options)
}

export const getEmployeeDetail = (options?: Options) => {
  return getRequest<any>(API.EmployeeDetail, options)
}

export const createEmployee = (options?: Options) => {
  return postRequest<any>(API.EmployeeCreate, options)
}

export const updateEmployee = (options?: Options) => {
  return putRequest<any>(API.EmployeeUpdate, options)
}

export const deleteEmployee = (options?: Options) => {
  return deleteRequest<any>(API.EmployeeDelete, options)
}

export const countEmployee = (options?:Options) => {
  return getRequest<any>(API.EmployeeCount, options)
}

export const userFileUpload = (options?:Options) => {
  return postRequest<any>(API.UserFileUpload, options)
}

export const userGetFileUpload = (options?:Options) => {
  return getRequest<any>(API.GetDetailUserAttachment, options)
}

export const deleteAttachment = (options?:Options) => {
  return deleteRequest<any>(API.RemoveUserAttachment, options)
}

export const statusEmployee = (options?:Options) => {
  return patchRequest<any>(API.EmployeeStatus, options)
}
