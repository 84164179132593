import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { prop, propOr } from 'ramda'
import { useField } from 'formik'

import CurrencyField from './CurrencyField'
import LineItems from './LineItems'
import { TRANSACTION_TYPE } from './ManualEntryTypeField'

import DateField from '../../../../components/form/DateField'
import TextField from '../../../../components/form/TextField'

function ManualEntryCreateForm () {
  const lineItemsField = useField('items')
  const lineItems = lineItemsField[0].value || []

  const creditItems = lineItems.filter((item: any) => prop('transactionType', item) === TRANSACTION_TYPE.CREDIT)
  const debitItems = lineItems.filter((item: any) => prop('transactionType', item) === TRANSACTION_TYPE.DEBIT)
  const creditAmount = creditItems.reduce((a: any, b: any) => propOr(0, 'amount', b) + a, 0)
  const debitAmount = debitItems.reduce((a: any, b: any) => propOr(0, 'amount', b) + a, 0)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Корректировка"
          rightButton={(
            <>
              <Button type="submit">Создать</Button>
            </>
          )}
        />
      </Header>

      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={6}>
                    <DateField name="date" label="Дата корректировки" />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <CurrencyField />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <TextField
                      name="totalDebit"
                      label="Общая сумма дебит"
                      value={debitAmount}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <TextField
                      name="totalCredit"
                      label="Общая сумма кредит"
                      value={creditAmount}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(ManualEntryCreateForm)
