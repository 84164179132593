import React, { useCallback } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import { Button, DialogTitle, Divider, Grid } from '@mui/material'
import FlexBox from 'components/main/FlexBox'
import TextField from 'components/form/TextField'
import { FieldArray, useField } from 'formik'

import ColumnConditionTypeField from './ColumnConditionTypeField'
import ColumnConditionColorPicker from './ColumnConditionColorPicker'

interface Props {
  readonly open: boolean
  readonly name: string
  readonly onClose: () => void
}

function FilterColumnConditionDialog ({ open, name, onClose }: Props) {
  const conditionsField = useField(name)
  const conditionFieldValue = conditionsField[0].value || []
  const conditionFieldSetValue = conditionsField[2].setValue

  const onEscape = useCallback((event: any) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }, [onClose])

  const onCreateNewCondition = useCallback(() => {
    conditionFieldSetValue([...conditionFieldValue, { match: '', type: '', color: '#2c75d6' }])
  }, [conditionFieldValue])

  return (
    <Dialog open={open} fullWidth={true} onKeyDown={onEscape}>
      <DialogTitle sx={{ padding: '16px' }}>Редактировать условия</DialogTitle>
      <DialogContent id="saleOrderVariantTable">
        <FieldArray name={name}>
          {({ remove }) => (
            <Grid container={true} spacing={3} sx={{ paddingTop: '10px' }}>
              {conditionFieldValue.map((condition: any, index: any) => (
                <>
                  <Grid item={true} lg={12}>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} lg={6}>
                        <TextField name={`${name}[${index}].match`} label="Схожесть" />
                      </Grid>
                      <Grid item={true} lg={6}>
                        <ColumnConditionTypeField name={`${name}[${index}].type`} />
                      </Grid>
                      <Grid item={true} lg={12}>
                        <ColumnConditionColorPicker index={index} name={name} />
                      </Grid>
                      <Grid item={true} lg={4}>
                        <Button
                          type="button"
                          onClick={() => remove(index)}
                        >
                          Удалить
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider sx={{ height: '5px', width: '95%', marginLeft: '24px' }} />
                </>
              ))}
              <Grid lg={12} item={true}>
                <Button
                  type="button"
                  onClick={onCreateNewCondition}
                >
                  Добавить
                </Button>
              </Grid>
            </Grid>
          )}
        </FieldArray>
      </DialogContent>
      <DialogActions sx={{ padding:'16px' }}>
        <FlexBox align="center" gap="8px">
          <Button
            data-cy="add"
            variant="contained"
            fullWidth={true}
            onClick={onClose}
          >
            Закрыть
          </Button>
        </FlexBox>
      </DialogActions>
    </Dialog>
  )
}

export default FilterColumnConditionDialog
