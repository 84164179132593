import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { pathOr, propOr, trim } from 'ramda'
import { pathParams } from 'path-params'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { usePut } from 'hooks/usePut'
import { useConfirm } from 'context/confirm'
import { useNavigate } from 'react-router'

import { getSalesOrderReturnList, deleteSalesOrderReturn, salesOrderReturnStatus } from '../api'
import SaleOrderReturnStatusRow from '../components/SaleOrderReturnStatusRow'
import SaleOrderReturnRowMenu from '../components/SaleOrderReturnRowMenu'

const columns = [
  {
    headerName: 'Дата заказа',
    field: 'returnDate',
    width: 300
  },
  {
    headerName: 'Номер заказа',
    field: 'number',
    width: 200
  },
  {
    headerName: 'Склад',
    field: 'warehouse',
    renderCell: pathOr('', ['warehouse', 'name'])
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (item: any) => <SaleOrderReturnStatusRow status={item.status} />
  },
  {
    headerName: 'Клиент',
    field: 'visitId',
    renderCell: pathOr('', ['contact', 'name'])
  }
]

function SaleOrderReturnListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const saleOrderReturnList = useGetList(getSalesOrderReturnList)
  const saleOrderReturnDelete = useDelete(deleteSalesOrderReturn)
  const statusSaleOrderReturn = usePut(salesOrderReturnStatus)

  const handleDeleteSaleOrder = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить заказ ${values.number}?  `

    onConfirm({ message })
      .agree(() => {
        saleOrderReturnDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Возвратный заказ успешно удалён.' }))
          .then(() => saleOrderReturnList.getList())
      })
      .disagree()
  }, [])

  const onStatusChange = useCallback((values:any) => {
    statusSaleOrderReturn.putData(values)
      .then((response:any) => {
        if (response.receiveId) {
          const toReceivePageURL = pathParams(ROUTES.WAREHOUSE_RECEIVE_UPDATE, { id:response.receiveId })
          navigate(toReceivePageURL)
        }
      })
      .then(() => snackbar({ message: 'Статус успешно обновлён' }))
      .then(() => saleOrderReturnList.getList())
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    saleOrderReturnList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Возврат заказа"
        onAddClick={() => navigate(ROUTES.SALE_ORDER_RETURN_CREATE)}
        dataRequest={saleOrderReturnList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row) => (
          <SaleOrderReturnRowMenu
            item={row}
            handleStatus={onStatusChange}
            onDelete={handleDeleteSaleOrder}
          />
        )}
      />
    </AppLayout>
  )
}

export default SaleOrderReturnListContainer
