import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const createCustomField = (options?: Options) => {
  return postRequest<any>(API.CustomFieldCreate, options)
}

export const updateCustomField = (options?: Options) => {
  return putRequest<any>(API.CustomFieldUpdate, options)
}

export const deleteCustomField = (options?: Options) => {
  return deleteRequest<any>(API.CustomFieldDelete, options)
}

export const getCustomFieldsByType = (options?: Options) => {
  return getRequest<any>(API.CustomFieldsGetByFormType, options)
}

export const getCustomFields = (options?: Options) => {
  return getRequest<any>(API.CustomFieldsGetList, options)
}

export const getCustomFieldById = (options?: Options) => {
  return getRequest<any>(API.CustomFieldsById, options)
}

export const bulkDeleteCustomField = (options?:Options) => {
  return deleteRequest<any>(API.CustomFieldDeleteBulk, options)
}
