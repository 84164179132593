import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { useConfirm } from 'context/confirm'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'
import { path, prop, propOr, trim } from 'ramda'

import {
  countWarehouse,
  deleteWarehouse,
  getWarehouseList,
  statusWarehouse,
} from '../api'
import WarehouseStatusRow from '../components/WarehouseStatusRow'
import WarehouseRowMenu from '../components/WarehouseRowMenu'
import TableActionsRow from '../components/TableActionRow'
import WarehouseTypeRow from '../components/WarehouseTypeRow'
import { transformTabs } from '../serializer'
import WarehouseExcelExport from '../components/WarehouseExcelExport'
import MoreActionButtons from '../components/MoreRowMenu'

const columns = [
  {
    headerName: 'Название склада',
    field: 'warehouseName',
    width: 300
  },
  {
    headerName: 'Сотрудники',
    field: 'assignees',
    renderCell:(item:any) => item.assignees.map((item: any) => prop('name', item)).join(', ')
  },
  {
    headerName: 'Водитель',
    field: 'email',
    renderCell:(item:any) => path(['driver', 'name'], item)
  },
  {
    headerName: 'Тип склада',
    field: 'warehouseType',
    renderCell:(item:any) => <WarehouseTypeRow status={item.warehouseType} />
  },
  {
    headerName: 'статус',
    field: 'active',
    renderCell: (item: any) => <WarehouseStatusRow status={item.active} />
  }
]

function WarehouseListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const [status, setStatus] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const warehouseList = useGetList(getWarehouseList, { query: { status:status.toString() } })
  const warehouseDelete = useDelete(deleteWarehouse)
  const warehouseStatus = usePut(statusWarehouse)
  const warehouseCount = useGetDetail(countWarehouse)
  const tabs = transformTabs(warehouseCount.result as any)

  const handleDeleteWarehouse = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить склад ${values.warehouseName}?`

    onConfirm({ message })
      .agree(() => {
        warehouseDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Склад успешно удалён' }))
          .then(() => warehouseList.getList({ query: { status } }))
      })
      .disagree()
  }, [status])

  const handleDeleteSelected = useCallback(async () => {
    const message = `Вы уверены, что хотите удалить ${selectedRows.length} склады?`

    onConfirm({ message })
      .agree(() => {
        for (let i = 0; i < selectedRows.length; i++) {
          const selectedProduct = selectedRows[i]
          const productId = prop('id', selectedProduct)
          warehouseDelete.deleteData({ params: { id: productId } })
            .then(() => snackbar({ message: `${selectedRows.length} склады успешно удалён` }))
            .then(() => warehouseList.getList({ query: { status } }))
            .then(() => setSelectedRows([]))
        }
      })
      .disagree()
  }, [selectedRows, warehouseList])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    warehouseList.getList({ query: { searchKey: search } })
  }, [])

  const onStatusChange = useCallback((id: number, active :boolean) => {
    warehouseStatus.putData({ params: { id }, query: { active }, data: { active } })
      .then(() => snackbar({ message: 'Статус успешно обновлён' }))
      .then(() => warehouseList.getList({ query: { status } }))
      .then(() => warehouseCount.getDetail())
  }, [status])

  return (
    <AppLayout>
      <Table
        title="Склады"
        onAddClick={() => navigate(ROUTES.WAREHOUSE_CREATE)}
        dataRequest={warehouseList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={tabs}
        status={status}
        setStatus={setStatus}
        checkbox={true}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        actions={(row) => (
          <WarehouseRowMenu
            item={row}
            handleStatus={onStatusChange}
            onDelete={handleDeleteWarehouse}
          />
        )}
        moreActions={(actions) => (
          <MoreActionButtons
            onOpen={actions.onExportClick}
          />
        )}
        exportNode={(
          <WarehouseExcelExport
            list={warehouseList}
          />
        )}
        tableActions={(
          <TableActionsRow
            selectedRows={selectedRows}
            handleDeleteSelected={handleDeleteSelected}
          />
        )}
      />
    </AppLayout>
  )
}

export default WarehouseListContainer
