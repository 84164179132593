import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [{ value: 'ACTIVE', name: 'Активный' }, { value: 'INACTIVE', name: 'Не активный' }]

function StatusField (props: any) {
  return (
    <SelectField
      name="status"
      label={props.name}
      options={OPTIONS}
      {...props}
    />
  )
}

export default StatusField
