import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { createEmployee } from '../api'
import EmployeeCreateForm from '../components/EmployeeCreateForm'
import { useUser } from '../../../../context/user'
import { createEmployeeSerializer } from '../serializer'

function BrandCreateContainer () {
  const employeeCreate = usePost(createEmployee)
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { user } = useUser()

  const handleSubmit = useCallback((values: any) => {
    return employeeCreate.postData({ data: createEmployeeSerializer({ ...values, companyId: user.companyId }) })
      .then(() => snackbar({ message: 'Сотрудник успешно создан' }))
      .then(() => navigate(ROUTES.HR_EMPLOYEE_LIST))
  }, [])

  return (
    <AppLayout>
      <EmployeeCreateForm
        onSubmit={handleSubmit}
        initialValues={{ territoryRoles: [] }}
        loading={employeeCreate.loading}
      />
    </AppLayout>
  )
}

export default BrandCreateContainer
