import { prop } from 'ramda'

export const timeSlotCreateSerializer = (values:any) => {
  const getUsers = prop('userIds', values)
  const userIds = getUsers && getUsers.map((user:any) => user?.id)
  return {
    ...values,
    userIds
  }
}

export const timeSlotInitialValues = (values:any) => {
  const userIds = prop('users', values)
  return {
    ...values,
    userIds
  }
}

export const timeSlotUpdateSerializer = (values:any) => {
  const getUsers = prop('userIds', values)
  const userIds = getUsers && getUsers.map((user:any) => user?.id)
  const effectiveDate = prop('effectiveDate', values)
  const endDate = prop('endDate', values)
  return {
    ...values,
    userIds,
    effectiveDate,
    endDate
  }
}
