import React from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles({
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    }
  }
})

interface Props {
  readonly status?: string
}

function TransferStatusRow ({ status }: Props) {
  const classes = useStyles()
  return (
    <div className={cx(classes.statusIndicator, { 'active': status === 'COMPLETED' })}>
      {status === 'COMPLETED' ? 'ЗАВЕРШЕННЫЙ' : 'ЧЕРНОВИК'}
    </div>
  )
}

export default TransferStatusRow
