import * as ROUTES from 'constants/Routes'

import React, { useMemo } from 'react'
import { MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { pathParams } from 'path-params'

import { CATEGORY_TABS } from './CategoryTabs'

interface Props{
  readonly selectedIds:any
  readonly handleDeleteSelected:() => void
}
function TableActionsRow ({ selectedIds, handleDeleteSelected }:Props) {
  const navigate = useNavigate()
  const updatedURL = pathParams(ROUTES.CATALOG_ADD_PRODUCT, { tab:CATEGORY_TABS.PRODUCTS })

  const selectedItems = useMemo(() => {
    return selectedIds.map((row: { id: number, name: string }) => {
      return { id: row.id, name: row.name }
    })
  }, [selectedIds])

  return (
    <>
      <MenuItem onClick={() => navigate(updatedURL, { state: selectedItems })}>
        Прекрепить к продуктовую корзину
      </MenuItem>
      <MenuItem onClick={handleDeleteSelected}>
        Удалить выбранное
      </MenuItem>
    </>
  )
}

export default TableActionsRow
