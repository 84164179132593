import React from 'react'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import LinearWithValueLabel from 'components/linear-progress'

interface Props {
  readonly open: boolean
}

function DownloadingDialogue ({ open }: Props) {
  return (
    <Dialog
      open={open}
      maxWidth="lg"
    >
      <DialogContent sx={{ width:'50vw' }}>
        <LinearWithValueLabel title="Загрузка полного файла экспорта займет немного времени." />
      </DialogContent>
    </Dialog>
  )
}

export default DownloadingDialogue
