import React, { useEffect, useState } from 'react'
import TextField from 'components/form/TextField'
import { useField } from 'formik'
import { path, prop } from 'ramda'
import FlexBox from 'components/main/FlexBox'
import Typography from '@mui/material/Typography'

import { queryPatternSerializer } from '../serializer'

export const FilterQueryPatternField = (props:any) => {
  const criteriaField = useField('criteria')
  const [input,, field] = useField(props.name)
  const criteriaValue = path(['0', 'value'], criteriaField)
  const defaultValue = prop('criteria', queryPatternSerializer(criteriaValue))
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    field.setValue(defaultValue)
  }, [defaultValue])

  return (
    <FlexBox
      gap={5}
      align="flex-end"
      justify="space-between"
    >
      <TextField
        disabled={disabled}
        value={input.value}
        name="queryPattern"
        label="Шаблон запроса"
        {...props}
      />
      <Typography
        variant="caption"
        sx={{
          width:'50px',
          textDecoration:'underline',
          cursor:'pointer'
        }}
        onClick={() => setDisabled(prevState => !prevState)}
      >
        {disabled ? 'Редактировать' : 'Отменить'}
      </Typography>
    </FlexBox>
  )
}
