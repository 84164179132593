import React from 'react'
import { Button, Card, CardContent, Divider, Grid, InputAdornment } from '@mui/material'
import Header from 'components/container/Header'
import PageTitle from 'components/main/PageTitle'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import Container from 'components/container/Container'
import withForm from 'components/form/withForm'

import MultipleTerritoryField from './MultipleTerritoryField'
import ChartOfAccountTypeField from './ChartOfAccountTypeField'

function ChartOfAccountForm () {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать Счет"
          rightButton={(
            <>
              <Button type="submit">Создать</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={6}>
                    <TextField
                      label="Название"
                      name="name"
                      data-cy="name"
                    />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <ChartOfAccountTypeField />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <MultipleTerritoryField />
                  </Grid>
                  <Grid item lg={6}>
                    <TextField
                      label="Баланс CNY"
                      name="balanceCny"
                      data-cy="balanceCny"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">¥</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <TextField
                      label="Баланс GBP"
                      name="balanceGbp"
                      data-cy="balanceGbp"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <TextField
                      label="Баланс KZT"
                      name="balanceKzt"
                      data-cy="balanceKzt"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₸</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <TextField
                      label="Баланс RUB"
                      name="balanceRub"
                      data-cy="balanceRub"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₽</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <TextField
                      label="Баланс USD"
                      name="balanceUsd"
                      data-cy="balanceUsd"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <TextField
                      label="Баланс UZS"
                      name="balanceUzs"
                      data-cy="balanceUzs"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">UZS</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(ChartOfAccountForm)
