import React, { useCallback } from 'react'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'
import { useSnackbar } from 'context/snackbar'

import ColumnUpdateForm from './components/ColumnUpdateForm'
import { columnGetById, updateColumn } from './api'

import { orderFilterSerializer } from '../filter/serializer'

interface Props {
  readonly id:number
  readonly onBack:() => void
  readonly onFilter:(item:any) => void
}

const ColumnSettingUpdate = ({ id, onBack, onFilter }:Props) => {
  const snackbar = useSnackbar()
  const columnDetail = useGetDetail(columnGetById, { params: { id } })
  const columnUpdate = usePut(updateColumn)

  const onUpdate = useCallback((values:any) => {
    columnUpdate.putData({ data:orderFilterSerializer(values) })
      .then(() => onBack())
      .then(() => snackbar({ message: 'Колонок успешно обновлён' }))
  }, [])

  return (
    <>
      {!columnDetail.loading && (
        <ColumnUpdateForm
          onFilter={onFilter}
          onBack={onBack}
          initialValues={columnDetail.result}
          onSubmit={onUpdate}
        />)}
    </>
  )
}

export default ColumnSettingUpdate
