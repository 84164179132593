import { dissoc, omit, path, prop, propOr } from 'ramda'

export const agentCreateSerialize = (data: any) => {
  const newAddresses = prop('addresses', data)
  const currency = path(['currency', 'code'], data)
  const customFieldValue = prop('customFields', data)
  const newTerritories = prop('territories', data) as any
  const addresses = newAddresses && newAddresses.map((address: any) => {
    const countryId = prop('value', address.countryId)
    const regionId = prop('value', address.regionId)
    return { ...address, regionId, countryId }
  })
  const getCategory = prop('categoryIds', data)
  const categoryIds = getCategory && getCategory.map((category:any) => category?.id)
  const territories = newTerritories && newTerritories.map((ids: any) => prop('value', ids))
  return {
    ...data,
    territories,
    addresses,
    currency,
    customFieldValue,
    categoryIds
  }
}

export const agentDetailSerializer = (data:any) => {
  const categoryIds = prop('categories', data)
  const territories = propOr([], 'territoryList', data) as any
  const newAddresses = prop('addressList', data)
  const customFields = prop('customFieldValue', data)
  const addresses = newAddresses && newAddresses.map((address: any) => {
    const countryId = prop('id', address.country)
    const regionId = prop('id', address.region)
    const fieldsRemove = ['country', 'region']
    const addressResult = omit(fieldsRemove, address)
    return { ...addressResult, regionId, countryId }
  })
  return {
    ...data,
    addresses,
    territories,
    customFields,
    categoryIds
  }
}

export const agentUpdateSerializer = (data:any) => {
  const fieldsToRemove = ['territoryList', 'addressList', 'customFields']
  const result = fieldsToRemove.reduce((acc, field) => dissoc(field, acc), data)
  const customFieldValue = prop('customFields', data)
  const newAddresses = prop('addresses', result)
  const newTerritories = prop('territories', result) as any
  const currency = path(['currency', 'code'], result)
  const addresses = newAddresses && newAddresses.map((address: any) => {
    const countryId = prop('id', address.countryId)
    const regionId = prop('id', address.regionId)
    return { ...address, regionId, countryId }
  })
  const getCategory = prop('categoryIds', data)
  const categoryIds = getCategory && getCategory.map((category:any) => category?.id)
  const territories = newTerritories && newTerritories.map((ids: any) => prop('id', ids))
  return {
    ...result,
    addresses,
    currency,
    territories,
    customFieldValue,
    categoryIds
  }
}

export const contactFilterPreviewSerializer = (values:any) => {
  const result = omit(['availableColumns', 'users', 'roles', 'name', 'type', 'visibleTo', 'default'], values)
  const getCriteria = prop('criteria', values)
  const criteria = getCriteria && getCriteria.map((criteria:any) => {
    const value = path(['value', 'id'], criteria) || prop('value', criteria)
    if (value) {
      const code = prop('code', criteria)
      const comparator = prop('comparator', criteria)
      return { comparator, code, value }
    }
  }).filter((item: any) => item != null)
  return { ...result, criteria }
}
