import React, { useCallback, useMemo } from 'react'
import {
  Button,
  Card,
  CardContent, Checkbox,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import CardHeader from 'components/card/CardHeader'
import { useField } from 'formik'
import { useTranslationI18n } from 'i18n/I18nContext'
import { pathOr, prop, propOr } from 'ramda'
import Box from '@mui/material/Box'
import FlexBox from 'components/main/FlexBox'
import { makeStyles } from '@mui/styles'

import LineItemRowMenu from './LineItemRowMenu'

import PickerLineStatusRow from '../PickerLineStatusRow'

const useStyles = makeStyles({
  tableRoot:{
    borderRadius:'7px',
    border:'1px solid #E9E9E9'
  },
  tableHeader: {
    background: '#F1F1F1',
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset'
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      boxShadow: '2px 2px 4px rgba(167, 172, 176, 0.1)',
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly isStatus:boolean
  readonly onPickItems: (items: any) => void
}

export function LineItems ({ isStatus, onPickItems }: Props) {
  const classes = useStyles()
  const { t } = useTranslationI18n()

  const lineItemsField = useField('items')
  const lineItems = lineItemsField[0].value || []
  const lineItemsChange = lineItemsField[2].setValue

  const onCheckRow = useCallback((item: any) => {
    const foundItem = lineItems.find((line: any) => line.id === item.id)
    if (foundItem.checked) {
      const newLineItems = lineItems.map((line: any) => {
        if (line.id === item.id) {
          return { ...line, checked: false }
        } else return line
      })
      lineItemsChange(newLineItems)
    } else {
      const newLineItems = lineItems.map((line: any) => {
        if (line.id === item.id) {
          return { ...line, checked: true }
        } else return line
      })
      lineItemsChange(newLineItems)
    }
  }, [lineItems])

  const checkedItems = useMemo(() => lineItems.filter((line: any) => line.checked), [lineItems])

  const enablePick = useMemo(() => {
    const bookedItems = checkedItems.filter((line: any) => line.status === 'NEW')
    return bookedItems.length === checkedItems.length
  }, [checkedItems])

  return (
    <Card>
      <CardHeader
        title={
          <FlexBox justify="space-between">
            <span>Товары</span>
            <Box>
              <Button
                sx={{ marginRight: '10px' }}
                onClick={() => onPickItems(checkedItems)}
                disabled={!isStatus || !enablePick || checkedItems.length === 0}
              >
                Собрать
              </Button>
            </Box>
          </FlexBox>
        }
      />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <TableContainer className={classes.tableRoot}>
              <Table size="small">
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell width={50} />
                    <TableCell width="20%">{t('Товар')}</TableCell>
                    <TableCell width="10%">{t('Количество')}</TableCell>
                    <TableCell width="10%">{t(`Серийный номер`)}</TableCell>
                    <TableCell width="10%">{t(`Статус`)}</TableCell>
                    <TableCell width="20%">{t('Дата истечения срока действия')}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {lineItems.map((item: any) => {
                    const productName = pathOr('', ['product', 'name'], item)
                    const batchNumber = prop('batchNumber', item)
                    const expiryDate = prop('expiryDate', item)
                    const quantity = propOr(0, 'quantity', item) as number
                    const status = prop('status', item)
                    const checked = prop('checked', item)
                    const checkedItem = lineItems.find((line: any) => line.id === item.id && checked)
                    const isPicked = status === 'PICKED'
                    return (
                      <TableRow>
                        <TableCell width={50}>
                          <Checkbox
                            color="primary"
                            onChange={() => onCheckRow(item)}
                            checked={Boolean(checkedItem)}
                          />
                        </TableCell>
                        <TableCell>{productName}</TableCell>
                        <TableCell>{quantity}</TableCell>
                        <TableCell>{batchNumber}</TableCell>
                        <TableCell><PickerLineStatusRow status={status} /></TableCell>
                        <TableCell>{expiryDate}</TableCell>
                        <TableCell align="right">
                          <LineItemRowMenu
                            isStatus={isStatus}
                            isPicked={isPicked}
                            item={item}
                            onPick={onPickItems}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default LineItems
