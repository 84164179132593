export const getTranslatedColumn = (code: string, name: string): string => {
  const translations: { [key: string]: string } = {
    NAME: 'Юр. наименование',
    SHORT_NAME: 'Короткое имя',
    ANTEX_ID: 'Антекс ID',
    TERRITORY: 'Территория',
    INN: 'ИНН',
    WEBSITE: 'Веб-сайт',
    MFO: 'МФО',
    RS: 'РС',
    NOTE: 'Примечание',
    CURRENCY: 'Валюта',
    MODIFIED_DATE: 'Дата изменения',
    CREATED_DATE: 'Дата создания',
    CREATED_BY: 'Добавлено',
    MODIFIED_BY: 'Модифицирован',
  }

  return translations[code] || name
}
