import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { usePost } from 'hooks/usePost'
import { path, propOr } from 'ramda'

import { getExcelPayment, getPaymentList } from '../api'
import PaymentRowMenu from '../components/PaymentRowMenu'
import PaymentStatusRow from '../components/PaymentStatusRow'
import MoreActionButtons from '../components/MoreRowMenu'
import { formatMoney } from '../../../../utils/formatMoney'
import PaymentMethodRow from '../components/PaymentMethodRow'

const columns = [
  {
    headerName: 'ID',
    field: 'id'
  },
  {
    headerName: 'Контрагент',
    field: 'contact',
    renderCell: path(['contact', 'name'])
  },
  {
    headerName: 'Доставка',
    field: 'shipment',
    renderCell: path(['shipment', 'name'])
  },
  {
    headerName: 'Доставщик',
    field: 'driver',
    renderCell: path(['driver', 'name'])
  },
  {
    headerName: 'Запрошенная сумма',
    field: 'paymentAmount',
    renderCell:(item:any) => formatMoney(item.requestedAmount)
  },
  {
    headerName: 'Сумма',
    field: 'amount',
    renderCell:(item:any) => formatMoney(item.amount)
  },
  {
    headerName: 'Валюта',
    field: 'currency'
  },
  {
    headerName: 'Привязанный номер',
    field: 'refNumber'
  },
  {
    headerName: 'Способ оплаты',
    field: 'paymentMethod',
    renderCell:(item:any) => <PaymentMethodRow status={item.paymentMethod} />
  },
  {
    headerName: 'Дата',
    field: 'date'
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell:(item:any) => <PaymentStatusRow status={item.status} />
  }
]

function PaymentListContainer () {
  const navigate = useNavigate()

  const paymentList = useGetList(getPaymentList)
  const paymentExcel = usePost(getExcelPayment)

  const handleGetExcel = useCallback(() => {
    paymentExcel.postData({ responseType: 'blob' })
      .then((response:any) => {
        const blob = new Blob([response])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', `Payment.xlsx`)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(link.href)
      })
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = propOr('', 'search', value)
    paymentList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Оплаты"
        onAddClick={() => navigate(ROUTES.ORDER_PAYMENT_CREATE)}
        dataRequest={paymentList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row) => (
          <PaymentRowMenu
            item={row}
          />
        )}
        moreActions={() => <MoreActionButtons onExportExcel={handleGetExcel} />}
      />
    </AppLayout>
  )
}

export default PaymentListContainer
