import React, { useEffect, useState } from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { path } from 'ramda'
import { useParams } from 'react-router-dom'
import { useField } from 'formik'
import DateField from 'components/form/DateField'

import DriverField from './form/DriverField'
import LineItems from './LineItems'
import PackerField from './form/PackerField'
import ShipmentStatusField from './form/ShipmentStatusField'
import AttachmentField from './AttachmentField'

import { deliveredShipmentSerializer } from '../serializer'
import CountryField from '../components/form/CountryField'
import RegionField from '../components/form/RegionField'

interface Props {
  readonly onUpdateDriver: any
  readonly onUpdatePacker: any
  readonly onPackItems: (items: any) => void
  readonly form: any
  readonly onPackSet: () => void
  readonly onComplete: (id:number) => void
  readonly onDeliver: (values:any) => void
  readonly onAddAttachment:(values:any) => void
}

function SaleOrderCreateForm (props: Props) {
  const {
    onUpdateDriver,
    onPackItems,
    onUpdatePacker,
    form,
    onPackSet,
    onDeliver,
    onComplete,
    onAddAttachment
  } = props
  const { id } = useParams()
  const name = path(['values', 'number'], form)
  const attachmentsField = useField('attachments')
  const attachmentsFieldValue = attachmentsField[0].value || []
  const attachmentSetFieldValues = attachmentsField[2]
  const [files, setFiles] = useState(attachmentsFieldValue as any[])
  const status = path(['values', 'status'], form)
  const isSalesReturn = path(['values', 'salesReturn'], form)
  const isPicked = status === 'PICKED'
  const isPacked = status === 'PACKED'
  const isShipping = status === 'SHIPPING'
  const isDelivered = status === 'DELIVERED'
  const isCompleted = status === 'COMPLETED'
  const lineItemsField = useField('items')
  const lineItems = lineItemsField[0].value || []
  const handleFileSet = (value: any) => {
    setFiles((prev: any) => [...prev, value])
  }

  useEffect(() => {
    attachmentSetFieldValues.setValue(files)
  }, [files])

  const handleFileDelete = (id: any) => {
    setFiles((prev: any) => prev.filter((img: any) => img.id !== id))
  }

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить доставку (${name})`}
          rightButton={(
            <>
              <Button
                disabled={isCompleted}
                type="button"
                onClick={() => onAddAttachment(files[0])}
              >
                Добавить вложение
              </Button>
              <Button
                disabled={!isPicked}
                type="button"
                onClick={() => onPackSet()}
              >
                Отправить на Упаковку
              </Button>
              {isShipping && (
                <Button
                  disabled={isCompleted}
                  type="button"
                  onClick={() => onDeliver(deliveredShipmentSerializer(Number(id), lineItems))}
                >
                  Доставлен
                </Button>)}
              {isDelivered && (
                <Button
                  disabled={isCompleted}
                  type="button"
                  onClick={() => onComplete(Number(id))}
                >
                  Завершить
                </Button>
              )}
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали доставки" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={3}>
                    <TextField label="Номер" name="number" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <DateField label="Дата посещения" name="visitDate" disabled={isCompleted} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <ShipmentStatusField label="Статус" name="status" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <TextField label="Номер заказа" name="order.name" disabled={true} />
                  </Grid>
                  {isSalesReturn && (
                    <Grid item={true} lg={3}>
                      <TextField label="Номер возврата продаж" name="salesReturn.name" disabled={true} />
                    </Grid>
                  )}
                  <Grid item={true} lg={3}>
                    <TextField label="Контрагент" name="contact.name" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <TextField label="Склад" name="warehouse.name" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <DateField label="Дата доставки" name="deliveryDate" disabled={isCompleted} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <PackerField
                      disabled={!isPicked || isCompleted}
                      onChange={(e: any, value: any) => onUpdatePacker(value)}
                    />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <DriverField
                      disabled={!isPacked || isCompleted}
                      onChange={(e: any, value: any) => onUpdateDriver(value)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
            <Card>
              <CardHeader title="Адрес" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Адресс"
                      name="address.addressLine1"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Адрес дополнительный"
                      name="address.addressLine2"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      disabled={true}
                      label="Ориентир"
                      name="address.landmark"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CountryField
                      label="Название страны"
                      name="address.countryId"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <RegionField
                      label="Город"
                      name="address.regionId"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Координаты - лат/лон"
                      name="address.location"
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems
              isStatus={isShipping}
              disabled={isCompleted}
              onPackItems={onPackItems}
            />
          </Grid>
          <Grid item={true} lg={12}>
            <AttachmentField
              files={files}
              onFileSet={handleFileSet}
              onFileDelete={handleFileDelete}
              disabled={isCompleted}
            />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(SaleOrderCreateForm)
