import * as ROUTES from 'constants/Routes'

import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import MUIDivider from '@mui/material/Divider'
import Tabs from 'components/main/Tabs'

export enum CATEGORY_TABS {
  PRODUCTS = 'products',
  TERRITORY = 'territory',
}

const tabs = [
  { value: CATEGORY_TABS.PRODUCTS, label: 'Продукты' },
  { value: CATEGORY_TABS.TERRITORY, label: 'Территория' },
]

interface Props {
  readonly value: string
  readonly categoryIds:any
}

function CategoryTabs ({ value, categoryIds }: Props) {
  const navigate = useNavigate()

  const onChange = (event: any, tab: any) => {
    const redirect = generatePath(ROUTES.CATALOG_ADD_PRODUCT, { tab })
    navigate(redirect, { replace: true, state: categoryIds })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />

      <MUIDivider sx={{ mb: 3 }} />
    </>
  )
}

export default CategoryTabs
