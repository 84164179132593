import { omit, path, pathOr, prop } from 'ramda'
import { toNumber } from 'utils/formatMoney'

export const billingCreateSerializer = (values:any) => {
  const categoryId = pathOr(null, ['categoryId', 'id'], values)
  const getItems = prop('items', values)
  const getModules = prop('modules', values)
  const modules = getModules && getModules.map((moduleItem:any) => {
    const module = path(['module', 'id'], moduleItem)
    const getChildModule = prop('childModules', moduleItem)
    const childModules = getChildModule && getChildModule.map((childModuleItem:any) => childModuleItem.id)
    return {
      module,
      childModules
    }
  })
  const items = getItems && getItems.map((contact:any) => {
    return {
      ...contact,
      amount:toNumber(contact.amount),
    }
  })
  return {
    ...values,
    categoryId,
    items,
    modules
  }
}

export const billingInitialSerializer = (values:any) => {
  const categoryId = prop('category', values)
  const result = omit(['category'], values)
  return {
    ...result,
    categoryId
  }
}

export const rowSerializer = (values:any) => {
  return values.map((item:any) => item.id)
}
