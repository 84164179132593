import React, { useCallback, useEffect, useState } from 'react'
import { Box, Card, CardContent, Grid, Skeleton, Tooltip } from '@mui/material'
import { prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import ExcelIcon from 'assets/excel-icon.svg'
import WordIcon from 'assets/word-icon.svg'
import PdfIcon from 'assets/pdf-icon.svg'
import PptIcon from 'assets/ppt-icon.svg'
import TextIcon from 'assets/text-icon.svg'
import CardHeader from 'components/card/CardHeader'
import Dropzone from 'components/upload/Dropzone'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import { useField } from 'formik'

import { documentDeleteUpload, documentUpload } from '../../api'
import { usePost } from '../../../../../hooks/usePost'
import { useDelete } from '../../../../../hooks/useDelete'

const getExtension = (filename:string) => {
  return filename.split('.').pop()
}

const imageFiles = (fileType:any) => {
  if (fileType === 'xlsx' || fileType === 'xls') {
    return ExcelIcon
  }
  if (fileType === 'doc' || fileType === 'docx') {
    return WordIcon
  }
  if (fileType === 'pdf') {
    return PdfIcon
  }
  if (fileType === 'ppt' || fileType === 'pptx') {
    return PptIcon
  }
  if (fileType === 'txt') {
    return TextIcon
  }
}

function AttachmentField () {
  const uploadAttachment = usePost(documentUpload)
  const deleteAttachment = useDelete(documentDeleteUpload)
  const attachmentField = useField('attachmentId')
  const attachementValue = attachmentField[0].value || {}
  const attachmentSetFieldValues = attachmentField[2]
  const [attachment, setAttachment] = useState(attachementValue as any)

  useEffect(() => {
    attachmentSetFieldValues.setValue(attachment)
  }, [attachment])

  const onDrop = useCallback((values: any = []) => {
    const data = new FormData()
    values.forEach((file: any) => {
      data.append('uploadFile', file)
    })
    uploadAttachment.postData({ data }).then((response) => {
      setAttachment(response)
    })
  }, [])

  const handleDelete = useCallback((id: any) => {
    deleteAttachment.deleteData({ params: { id } }).then(() => {
      setAttachment(null)
    })
  }, [])
  const id = prop('id', attachementValue)
  const originalName = prop('originalName', attachementValue)
  const link = propOr('', 'link', attachementValue) as string
  const checkFile = getExtension(link)
  const imageLink = imageFiles(checkFile) ? imageFiles(checkFile) : link
  return (
    <Card>
      <CardHeader title="Добавьте файлы." />
      <CardContent>
        <Grid container={true} spacing={3}>
          {Object.keys(attachementValue).length <= 0 && (
            <Grid item={true} lg={2}>
              <Dropzone onDrop={onDrop} />
            </Grid>
          )}
          {Object.keys(attachementValue).length > 0 && (
            <>
              <Grid item={true} lg={2}>
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'flex-end',
                    justifyContent:'center',
                    position: 'relative',
                    width:'200px',
                    height:'200px',
                    background:'#F8F8F8',
                    borderRadius:'8px 8px 0 0',
                  }}
                >
                  <span
                    style={{
                      position: 'absolute',
                      fontSize: 25,
                      color: 'white',
                      cursor: 'pointer',
                      right:'25px',
                      top:'15px'
                    }}
                    onClick={() => handleDelete(id)}
                  >
                    <DeleteIcon />
                  </span>
                  <img
                    src={imageLink}
                    alt={originalName}
                    style={{
                      height: 150,
                      width:150,
                      borderRadius:'8px 8px 0 0',
                      objectFit: 'cover',
                      cursor:'pointer'
                    }}
                  />
                </Box>
                <Tooltip title={originalName}>
                  <Typography
                    noWrap
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '200px',
                      marginTop:'8px'
                    }}
                  >
                    {originalName}
                  </Typography>
                </Tooltip>
              </Grid>
            </>
          )}
          {(uploadAttachment.loading) && (
            <Grid item={true} lg={3}>
              <Box
                sx={{
                  display:'flex',
                  alignItems:'flex-end',
                  justifyContent:'center',
                  width:'200px',
                  height:'200px',
                  background:'#F8F8F8',
                  borderRadius:'8px 8px 0 0',
                }}
              >
                <Skeleton variant="rectangular" width={150} height={150} sx={{ borderRadius:'8px 8px 0 0' }} />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AttachmentField
