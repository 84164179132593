import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { createCurrency } from '../api'
import CurrencyCreateForm from '../components/CurrencyCreateForm'

function CurrencyCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const brandCreate = usePost(createCurrency)

  const handleSubmit = useCallback((values: any) => {
    return brandCreate.postData({ data: values })
      .then(() => snackbar({ message: 'Валюта успешно создана' }))
      .then(() => navigate(ROUTES.SETTINGS_CURRENCY_LIST))
  }, [])

  return (
    <AppLayout>
      <CurrencyCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default CurrencyCreateContainer
