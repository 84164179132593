import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import Table from 'components/table/Table'
import { useGetList } from 'hooks/useGetList'
import { useNavigate } from 'react-router-dom'
import { formatMoney } from 'utils/formatMoney'
import { path } from 'ramda'

import { getTransferCashList, transferCashDelete, transferCashUpdateStatus } from '../api'
import TransferCashStatusRow from '../components/TransferCashStatusRow'
import TransferCashRowMenu from '../components/TransferCashRowMenu'
import { useDelete } from '../../../../hooks/useDelete'
import { useSnackbar } from '../../../../context/snackbar'
import { usePut } from '../../../../hooks/usePut'

const columns = [
  {
    headerName: 'Номер',
    field: 'number'
  },
  {
    headerName: 'Дата',
    field: 'date'
  },
  {
    headerName: 'Валюта',
    field: 'currency'
  },
  {
    headerName: 'Перевод С',
    field: 'fromAccount',
    renderCell:(row:any) => path(['fromAccount', 'name'], row)
  },
  {
    headerName: 'Перевод В',
    field: 'toAccount',
    renderCell:(row:any) => path(['toAccount', 'name'], row)
  },
  {
    headerName: 'Сумма',
    field: 'amount',
    renderCell:(row:any) => `+ ${formatMoney(row.amount)}`
  },
  {
    headerName:'Статус',
    field: 'status',
    renderCell:(row:any) => <TransferCashStatusRow status={row.status} />
  }
]

function TransferCashListContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const transferCashList = useGetList(getTransferCashList)
  const deleteTransferCash = useDelete(transferCashDelete)
  const updateStatusTransferCash = usePut(transferCashUpdateStatus)

  const onDelete = useCallback((id:number) => {
    deleteTransferCash.deleteData({ params:{ id } })
      .then(() => transferCashList.getList())
      .then(() => snackbar({ message:'Перемещение успешно удалено' }))
  }, [])

  const onUpdateStatus = useCallback((values:any) => {
    updateStatusTransferCash.putData({ data:values })
      .then(() => transferCashList.getList())
      .then(() => snackbar({ message:'Перемещение статуса успешно обновлено' }))
  }, [])

  return (
    <AppLayout>
      <Table
        title="Перемещение между кассами"
        onAddClick={() => navigate(ROUTES.FINANCE_MOVING_BETWEEN_CASH_CREATE)}
        dataRequest={transferCashList}
        columns={columns}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(item) => (
          <TransferCashRowMenu
            item={item}
            onDelete={onDelete}
            onUpdateStatus={onUpdateStatus}
          />
        )}
      />
    </AppLayout>
  )
}

export default TransferCashListContainer
