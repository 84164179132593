import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { createBrand } from '../api'
import BrandCreateForm from '../components/BrandCreateForm'

function BrandCreateContainer () {
  const brandCreate = usePost(createBrand)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = useCallback((values: any) => {
    return brandCreate.postData({ data: values })
      .then(() => snackbar({ message: 'Бренд успешно создан' }))
      .then(() => navigate(ROUTES.CATALOG_BRAND_LIST))
  }, [])

  return (
    <AppLayout>
      <BrandCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default BrandCreateContainer
