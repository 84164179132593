import React from 'react'
import { Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import withForm from 'components/form/withForm'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import { path } from 'ramda'
import { useLocales } from 'hooks/useLocales'

import PriceLevelTabs, { PRICE_LEVEL_TABS } from '../PriceLevelTabs'
import PriceLevelUpdateGeneral from '../PriceLevelUpdateGeneral'

function PriceLevelForm ({ form }:any) {
  const { t } = useLocales('priceLevel')
  const { tab } = useParams()
  const name = path(['values', 'name'], form)

  return (
    <>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`${t('Update price level')} (${name})`}
          rightButton={(
            <>
              <Button type="submit">{t('Save changes')}</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <PriceLevelTabs value={tab as string} />
        {tab === PRICE_LEVEL_TABS.DETAIL && (
          <PriceLevelUpdateGeneral />
        )}
      </Content>
    </>
  )
}

export default withForm(PriceLevelForm)
