import { useCallback, useState } from 'react'
import { pathOr, propOr } from 'ramda'

import { GetListResponse } from '../api/BaseDto'
import { Options } from '../api/BaseApi'
import useAllSearchParams from '../utils/useAllSearchParams'
import { ALTER_ERROR, useSnackbar } from '../context/snackbar'

export const useGetDetailWithoutCall =
  <T>(api: (options?: Options, isPrivate?:boolean) =>
    Promise<GetListResponse<T>>, options?: Options, isPrivate?:boolean) => {
    const searchParams = useAllSearchParams()
    const snackbar = useSnackbar()
    const [result, setResult] = useState({} as GetListResponse<T>)
    const [loading, setLoading] = useState(true)

    const getDetail = useCallback((callOptions?: Options) => {
      setLoading(true)
      return api({ ...options, ...callOptions }, isPrivate).then((response) => {
        setResult(response)
        setLoading(false)
        return response
      }).catch((error) => {
        setLoading(false)
        const dataError = pathOr('Oops, Something went wrong', ['data', 'error'], error)
        const userMsg = typeof dataError === 'object'
          ? propOr('Oops, Something went wrong', 'user_msg', dataError)
          : dataError

        snackbar({ message: userMsg, type: ALTER_ERROR })
        throw error
      })
    }, [api, searchParams])

    return { getDetail, result, loading }
  }
