import * as API from 'constants/Api'

import React from 'react'
import SearchField from 'components/form/SearchField'
import { path, prop } from 'ramda'
import { useField } from 'formik'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value, name, id: value }
  }

  return null
}

function ClientField (props: any) {
  const territory = useField('territoryId')
  const territoryId = path(['value', 'id'], territory[0])
  return (
    <SearchField
      disabled={!territoryId}
      name="renterId"
      api={API.ContactGetByTerritory}
      param={{ id:territoryId }}
      getOptionValue={getOptionValue}
      label="Клиент"
      {...props}
    />
  )
}

export default ClientField
