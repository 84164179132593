import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'DEPOSITED', name: 'ПОЛУЧЕНО' },
  { value: 'CANCELLED', name: 'ОТМЕНЕНО' },
  { value: 'SCHEDULED', name: 'ЗАПЛАНИРОВАНО' },
  { value: 'PAID', name: 'ОПЛАЧЕНО' }
]

function StatusField (props:any) {
  return (
    <SelectField
      name="status"
      label="Cтатус"
      options={OPTIONS}
      {...props}
    />
  )
}

export default StatusField
