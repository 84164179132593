import * as ROUTES from 'constants/Routes'

import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import MUIDivider from '@mui/material/Divider'
import Tabs from 'components/main/Tabs'

export enum PRICE_LEVEL_TABS {
  DETAIL = 'details',
  PRODUCT_PRICE = 'product_price',
}

const tabs = [
  { value: PRICE_LEVEL_TABS.DETAIL, label: 'Details' },
  { value: PRICE_LEVEL_TABS.PRODUCT_PRICE, label: 'Product price table' },
]

interface Props {
  readonly value: string
}

function PriceLevelTabs ({ value }: Props) {
  const { id } = useParams()

  const navigate = useNavigate()

  const onChange = (event: any, tab: any) => {
    const redirect = generatePath(ROUTES.SETTINGS_PRICE_LEVEL_EDIT, { id, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
        translationModule="priceLevel"
      />

      <MUIDivider sx={{ mb: 3 }} />
    </>
  )
}

export default PriceLevelTabs
