import React from 'react'
import SelectField from 'components/form/SelectField'
import { path } from 'ramda'

const LOOKUP_OPTIONS = [
  { value: 'EQUALS', name: 'РАВНО' },
  { value: 'NOT_EQUALS', name: 'НЕ РАВНО' },
  { value: 'EMPTY', name: 'ПУСТОЙ' },
  { value: 'NOT_EMPTY', name: 'НЕ ПУСТО' },
]
const DATE_TIME_PICKER_OPTIONS = [
  { value: 'EQUALS', name: 'РАВНО' },
  { value: 'NOT_EQUALS', name: 'НЕ РАВНО' },
  { value: 'AFTER', name: 'ПОСЛЕ' },
  { value: 'BEFORE', name: 'ДО' },
]
const TEXT_BOX_OPTIONS = [
  { value: 'EQUALS', name: 'РАВНО' },
  { value: 'NOT_EQUALS', name: 'НЕ РАВНО' },
  { value: 'EMPTY', name: 'ПУСТОЙ' },
  { value: 'NOT_EMPTY', name: 'НЕ ПУСТО' },
  { value: 'STARTS_WITH', name: 'НАЧИНАЕТСЯ С' },
  { value: 'CONTAINS', name: 'СОДЕРЖИТ' },
  { value: 'DOES_NOT_CONTAINS', name: 'НЕ СОДЕРЖИТ' },
  { value: 'IN', name: 'В' },
  { value: 'NOT_IN', name: 'НЕ В' },
]
const NUMBER_BOX_OPTIONS = [
  { value: 'EQUALS', name: 'РАВНО' },
  { value: 'NOT_EQUALS', name: 'НЕ РАВНО' },
  { value: 'EMPTY', name: 'ПУСТОЙ' },
  { value: 'NOT_EMPTY', name: 'НЕ ПУСТО' },
  { value: 'LESS_THAN', name: 'МЕНЬШЕ, ЧЕМ' },
  { value: 'LESS_THAN_OR_EQUALS', name: 'МЕНЬШЕ ИЛИ РАВНО' },
  { value: 'GREATER_THAN', name: 'БОЛЬШЕ ЧЕМ' },
  { value: 'GREATER_THAN_OR_EQUALS', name: 'БОЛЬШЕ ИЛИ РАВНО' },
]

const getOptions = (fieldType:string) => {
  if (fieldType === 'NUMBER_BOX') {
    return NUMBER_BOX_OPTIONS
  }
  if (fieldType === 'TEXT_BOX') {
    return TEXT_BOX_OPTIONS
  }
  if (fieldType === 'DATE_TIME_PICKER') {
    return DATE_TIME_PICKER_OPTIONS
  }
  if (fieldType === 'LOOKUP') {
    return LOOKUP_OPTIONS
  }
}

function ComparatorSelectField (props:any) {
  const { item, ...rest } = props
  const fieldType = path(['code', 'fieldType'], item)
  return (
    <SelectField
      label="Компаратор"
      options={getOptions(fieldType) || []}
      data-cy="status"
      {...rest}
    />
  )
}

export default ComparatorSelectField
