import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'SALES_TASK', name: 'ПРОДАЖА' },
  { value: 'MARKETING_TASK', name: 'МАРКЕТИНГ' },
  { value: 'DELIVERY_TASK', name: 'ДОСТАВКА' },
  { value: 'PAYMENT_COLLECTION', name: 'ОПЛАТА' },
  { value: 'CHECKING_OF_DUE_TASK', name: 'ПРОВЕРКА ЗАДАЧИ' },
  { value: 'CHECKING_OF_DUE_SHELVES', name: 'ПРОВЕРКА ПОЛОК' },
  { value: 'CUSTOM', name: 'ОБЫЧАЙ' },
]

function TaskReturnTypeField () {
  return (
    <SelectField
      name="type"
      label="Тип таски"
      options={OPTIONS}
      data-cy="status"
    />
  )
}

export default TaskReturnTypeField
