import { prop, omit, path } from 'ramda'

export const transferSerializer = (values: any) => {
  const fromWarehouseId = path(['fromWarehouseId', 'value'], values)
  const toWarehouseIdNew = path(['toWarehouseId', 'value'], values)
  const lineItems = prop('lineItems', values)
  const newLineItems = lineItems.map((item:any) => omit(['product', 'cost'], item))
  return { ...values, fromWarehouseId: fromWarehouseId, toWarehouseId: toWarehouseIdNew, lineItems:newLineItems }
}

export const transferDetailSerializer = (values:any) => {
  const fromWarehouseId = prop('fromWarehouse', values)
  const toWarehouseId = prop('toWarehouse', values)
  return { ...values, toWarehouseId, fromWarehouseId }
}
