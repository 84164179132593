import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getPurchaseList = (options?: Options) => {
  return getRequest<any>(API.PurchaseList, options)
}

export const createPurchase = (options?: Options) => {
  return postRequest<any>(API.PurchaseCreate, options)
}

export const updatePurchase = (options?: Options) => {
  return putRequest<any>(API.PurchaseUpdate, options)
}

export const deletePurchase = (options?: Options) => {
  return deleteRequest<any>(API.PurchaseDelete, options)
}

export const getPurchaseDetail = (options?: Options) => {
  return getRequest<any>(API.PurchaseDetail, options)
}

export const getProductList = (options?: Options) => {
  return getRequest<any>(API.ProductList, options)
}

export const approvePurchase = (options?: Options) => {
  return putRequest<any>(API.PurchaseApprove, options)
}

export const rejectPurchase = (options?: Options) => {
  return putRequest<any>(API.PurchaseRejected, options)
}

export const deliverPurchase = (options?: Options) => {
  return putRequest<any>(API.PurchaseDelivered, options)
}

export const processPurchase = (options?: Options) => {
  return postRequest<any>(API.PurchaseProcess, options)
}

export const getReceiveListPO = (options?:Options) => {
  return getRequest<any>(API.ReceiveListByPOId, options)
}

export const getReceiveDetailById = (options?:Options) => {
  return getRequest<any>(API.ReceiveDetail, options)
}

export const getPurchaseOrderExcel = (options?:Options) => {
  return getRequest<any>(API.PurchaseOrderExcel, options)
}
