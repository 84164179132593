import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { agentCategoryCreate } from '../api'
import AgentCategoryCreateForm from '../components/AgentCategoryCreateForm'
import { categoryCreateSerializer } from '../serializer'

function AgentCategoryCreateContainer () {
  const createAgentCategory = usePost(agentCategoryCreate)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = useCallback((values: any) => {
    return createAgentCategory.postData({ data: categoryCreateSerializer(values) })
      .then(() => snackbar({ message: 'Категория контрагента успешно создана' }))
      .then(() => navigate(ROUTES.FINANCE_AGENT_CATEGORY_LIST))
  }, [])

  return (
    <AppLayout>
      <AgentCategoryCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default AgentCategoryCreateContainer
