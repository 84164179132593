import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getShipmentList = (options?: Options) => {
  return getRequest<any>(API.ShipmentList, options)
}

export const deleteShipment = (options?: Options) => {
  return deleteRequest<any>(API.ShipmentDelete, options)
}

export const processShipment = (options?: Options) => {
  return patchRequest<any>(API.ShipmentProcess, options)
}

export const bulkProcessShipment = (options?: Options) => {
  return patchRequest<any>(API.ShipmentBulkProcess, options)
}

export const bulkCompleteShipment = (options?: Options) => {
  return patchRequest<any>(API.ShipmentBulkComplete, options)
}

export const completeShipment = (options?: Options) => {
  return patchRequest<any>(API.ShipmentComplete, options)
}

export const shipmentFilterPreview = (options?: Options) => {
  return patchRequest<any>(API.ShipmentFilterPreview, options)
}

export const getShipmentDetail = (options?: Options) => {
  return getRequest<any>(API.ShipmentDetail, options)
}

export const setShipmentPicker = (options?: Options) => {
  return patchRequest<any>(API.ShipmentSetPicker, options)
}

export const setShipmentPacker = (options?: Options) => {
  return patchRequest<any>(API.ShipmentSetPacker, options)
}

export const setShipmentStatus = (options?: Options) => {
  return patchRequest<any>(API.ShipmentSetStatus, options)
}

export const setShipmentDriver = (options?: Options) => {
  return patchRequest<any>(API.ShipmentDriverAssign, options)
}

export const setShipmentCollect = (options?: Options) => {
  return patchRequest<any>(API.ShipmentCollect, options)
}

export const setShipmentReturn = (options?: Options) => {
  return patchRequest<any>(API.ShipmentReturned, options)
}

export const tabsCounts = (options?: Options) => {
  return getRequest<any>(API.ShipmentTabsCounts, options)
}

export const setShipmentItemsStatus = (options?: Options) => {
  return patchRequest<any>(API.ShipmentItemSetStatus, options)
}

export const deliverShipment = (options?:Options) => {
  return patchRequest<any>(API.ShipmentDeliverStatus, options)
}

export const uploadFiles = (options?: Options) => {
  return postRequest<any>(API.SalesOrderFileUpload, options)
}

export const deleteUploadFile = (options?: Options) => {
  return deleteRequest<any>(API.SalesOrderFileDelete, options)
}

export const shipmentAttachments = (options?:Options) => {
  return patchRequest<any>(API.ShipmentAttachmentUpdate, options)
}

export const shipmentAttachmentDelete = (options?:Options) => {
  return patchRequest<any>(API.ShipmentAttachmentRemove, options)
}

export const createFilter = (options?: Options) => {
  return postRequest<any>(API.ListingFilterCreate, options)
}

export const updateFilter = (options?: Options) => {
  return putRequest<any>(API.ListingFilterUpdate, options)
}

export const filterGetById = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetById, options)
}

export const getAvailableColumns = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetByType, options)
}

export const getFilterList = (options?: Options) => {
  return getRequest<any>(API.ListingFilterList, options)
}

export const deleteFilter = (options?: Options) => {
  return deleteRequest<any>(API.ListingFilterDelete, options)
}

export const deleteSelectedFilter = (options?: Options) => {
  return deleteRequest<any>(API.ListingFilterDeleteByIds, options)
}

export const shipmentFilterData = (options?: Options) => {
  return getRequest<any>(API.ShipmentFilterCountData, options)
}

export const getColumnsGetByType = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetByType, options)
}

export const getColumnsList = (options?: Options) => {
  return getRequest<any>(API.ColumnsList, options)
}

export const createColumn = (options?: Options) => {
  return postRequest<any>(API.ColumnsCreate, options)
}

export const columnGetById = (options?: Options) => {
  return getRequest<any>(API.ColumnsGetById, options)
}

export const updateColumn = (options?: Options) => {
  return putRequest<any>(API.ColumnsUpdate, options)
}

export const deleteColumn = (options?: Options) => {
  return deleteRequest<any>(API.ColumnsDelete, options)
}

export const deleteColumns = (options?: Options) => {
  return deleteRequest<any>(API.ColumnsDeleteByIds, options)
}

export const getTabList = (options?:Options) => {
  return getRequest<any>(API.ListingTabList, options)
}

export const createTab = (options?:Options) => {
  return postRequest<any>(API.ListingTabCreate, options)
}

export const updateTab = (options?:Options) => {
  return putRequest<any>(API.ListingTabUpdate, options)
}

export const getByIdTab = (options?:Options) => {
  return getRequest<any>(API.ListingTabGetById, options)
}

export const deleteTab = (options?:Options) => {
  return deleteRequest<any>(API.ListingTabDelete, options)
}

export const deleteTabByIds = (options?:Options) => {
  return deleteRequest<any>(API.ListingTabDeleteByIds, options)
}
