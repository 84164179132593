import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Checkbox,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import FlexBox from 'components/main/FlexBox'
import AddIcon from 'assets/add-icon.svg'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { path, pathOr, prop, propOr } from 'ramda'
import cx from 'classnames'
import { Form, Formik } from 'formik'
import Scrollbar from 'components/scrollbar'

import TableHeaderTabs from './TableHeaderTabs'
import TableHeaderButtons from './TableHeaderButtons'
import { tableStyles } from './styles/TableStyles'
import TableFilterPopup from './TableFilterSidePopup'

import useAllSearchParams from '../../utils/useAllSearchParams'
import { DEFAULT_PAGE_NUMBER } from '../../hooks/useGetList'
import TextField from '../form/TextField'
import SearchIcon from '../icons/SearchIcon'
import ClearIcon from '../icons/ClearIcon'
import { truncateText } from '../../utils/truncateText'
import { useRoutes } from '../../context/route'
import { useLocales } from '../../hooks/useLocales'

const getConditionData = (value: string, conditions: any) => {
  return conditions.find((con: any) => con.match === value)
}

const getAllConditions = (columns: any) => {
  const allConditions = columns.map((col: any) => ({
    field: prop('field', col),
    conditions: prop('conditions', col)
  }))
  return allConditions.filter((con: any) => Boolean(prop('conditions', con)))
}

const getRowCondition = (item: any, conditions: any) => {
  const rowConditions = conditions.filter((con: any) => {
    return con.conditions.find((condition: any) => condition.type === 'ROW')
  })
  const foundCondition = rowConditions.find((con: any) => {
    const rowCondition = con.conditions.find((condition: any) => condition.type === 'ROW')
    return propOr('', con.field, item) === prop('match', rowCondition)
  })
  if (foundCondition) {
    return foundCondition.conditions.find((condition: any) => condition.type === 'ROW')
  }
  return null
}

interface TableListProps {
  readonly headerName: string
  readonly sortable?: boolean
  readonly field: string
  readonly width?: number | string
  readonly valueGetter?: any
  readonly renderCell?: any
  readonly leftStick?: boolean
  readonly rightStick?: boolean
  readonly ref?: any
}

export interface Actions {
  onOpenTabs?: () => void;
  onTabSettingsClick?: () => void;
  onExportClick: () => void;
  onSortingClick?:() => void;
}

interface Props {
  readonly columns: TableListProps[]
  readonly onAddClick?: () => void
  readonly tabs?: any[]
  readonly className?: string
  readonly title: string
  readonly onSearchClick?: (values:any) => void
  readonly checkbox?: boolean
  readonly primaryKey?: string
  readonly withoutBar?: boolean
  readonly actions?: (row: any, index: number) => ReactNode
  readonly dataRequest: any
  readonly filterNode?: ReactNode
  readonly exportNode?: ReactNode
  readonly tableActions?: ReactNode,
  readonly sortingNode?:ReactNode,
  readonly columnNode?:ReactNode
  readonly moreActions?: (actions:Actions) => ReactNode
  readonly selectedRows?: any
  readonly setSelectedRows?: any
  readonly limit?: number
  readonly setLimit?: (limit: number) => void
  readonly status?: any
  readonly setStatus?: (status: string) => void
  readonly tabId?: any
  readonly setTabId?: (tabId: number) => void
  readonly filter?:any
  readonly tabNode?:any,
  readonly searchPlaceholder?:string
  readonly actionsBan?:boolean
  readonly actionBanWarning?:string
  readonly tabRequest?:any
  readonly lastActiveTab?:any
  readonly listingTableName?:string
  readonly translationModule?:string
}

function Table (props: Props) {
  const {
    columns,
    onAddClick,
    className,
    onSearchClick,
    withoutBar = false,
    tabs = [],
    title = 'List',
    checkbox,
    primaryKey = 'id',
    actions,
    dataRequest,
    filterNode,
    columnNode,
    exportNode,
    tableActions,
    moreActions,
    selectedRows = [],
    setSelectedRows = () => null,
    status,
    setStatus = () => null,
    tabId,
    setTabId = () => null,
    limit,
    setLimit = () => null,
    filter,
    tabNode,
    sortingNode,
    searchPlaceholder,
    actionsBan,
    actionBanWarning,
    tabRequest,
    lastActiveTab,
    listingTableName,
    translationModule
  } = props
  const navigate = useNavigate()
  const searchParams = useAllSearchParams()
  const { changeExactRoute } = useRoutes()
  const { t } = useLocales(translationModule)
  const tableElement = useRef(null) as any
  const list = propOr([], 'list', dataRequest) as any
  const data = prop('result', dataRequest) as any
  const listGet = prop('getList', dataRequest) as any
  const loading = prop('loading', dataRequest) as boolean
  const [openColumnSettings, setOpenColumnSettings] = useState(false)
  const [openFilterPopup, setOpenFilterPopup] = useState(false)
  const [openExportPopup, setOpenExportPopup] = useState(false)
  const [openTabs, setOpenTabs] = useState(false)
  const [openSorting, setOpenSorting] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const [start, setStart] = useState(Number(prop('start', searchParams)) || 0)
  const [tabLimit, setTabLimit] = useState(10)
  const page = Math.ceil(start / (limit || tabLimit)) + 1
  const totalItems = propOr(0, 'count', data) as number || propOr(0, 'totalElements', data) as number
  const currentItemsCount = list.length as number
  const totalPages = Math.ceil(totalItems / (limit || tabLimit))
  const hasSearchKey = path(['query', 'searchKey'], dataRequest)
  const nextPageEnable = page !== totalPages
  const previousPageEnabled = page !== 1
  const areTabsObjects = tabs.length > 0 && tabs?.every(tab => typeof tab === 'object' && tab !== null)
  const lastTabId = propOr('', 'tabId', lastActiveTab) as any
  const listingNameTab = propOr(listingTableName, 'id', lastActiveTab) as string

  useEffect(() => {
    const paramsLimit = prop('limit', searchParams) || limit || tabLimit
    const paramsStart = prop('start', searchParams) || DEFAULT_PAGE_NUMBER
    setTabLimit(Number(paramsLimit))
    setLimit(Number(paramsLimit))
    setStart(Number(paramsStart))
  }, [searchParams, limit])

  const classes = tableStyles()

  const onClickSelectRow = useCallback((item: any) => {
    const rowPrimaryKey = prop(primaryKey, item)
    setSelectedRows((prev: any) => {
      const checkedItem = prev.find((item: any) => prop(primaryKey, item) === rowPrimaryKey)
      if (checkedItem) {
        return prev.filter((item: any) => prop(primaryKey, item) !== rowPrimaryKey)
      } else {
        return [...prev, item]
      }
    })
  }, [])

  const onSelectAllRows = useCallback(() => {
    if (list.length === selectedRows.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(list)
    }
  }, [list, selectedRows, primaryKey])

  const columnLength = useMemo(() => {
    let length = columns.length

    if (checkbox) {
      length++
    }

    if (actions) {
      length++
    }

    return length
  }, [])

  const itemsOfPage = useMemo(() => {
    if (page === DEFAULT_PAGE_NUMBER) {
      return `1-${currentItemsCount}`
    } else if (page === totalPages) {
      return `${(limit || tabLimit) * (page - 1) + 1} - ${totalItems}`
    } else {
      return `${((limit || tabLimit)) * (page - 1) + 1} - ${((limit || tabLimit)) * page}`
    }
  }, [currentItemsCount, limit, tabLimit, totalPages, page])

  useEffect(() => {
    if (tabRequest) {
      tabRequest.getDetail()
        .then((response:any) => {
          if (!lastTabId) {
            const defaultTab = response[0] as any
            const tabId = prop('id', defaultTab)
            changeExactRoute(listingNameTab, tabId)
          }
        })
    }
  }, [])

  useEffect(() => {
    if (lastTabId) {
      listGet && listGet({ query: { start, limit, tabId:lastTabId } })
    }
    if (!tabRequest || status) {
      listGet && listGet({ query: { start, limit:tabLimit } })
    }
  }, [start, limit, page, tabId, status, tabLimit, lastTabId])

  const handleClickPreviousPage = useCallback(() => {
    const previousPage = start - (limit || tabLimit)
    const isFirstPage = previousPage <= 0

    const searchParams = new URLSearchParams()
    if (!isFirstPage) {
      searchParams.set('start', String(previousPage))
      searchParams.set('limit', String(limit || tabLimit))

      if (lastTabId) {
        searchParams.set('tabId', lastTabId)
      }
    }

    setStart(isFirstPage ? 0 : previousPage)
    navigate({
      pathname: location.pathname,
      search: isFirstPage ? '' : `?${searchParams.toString()}`,
    })
  }, [start, limit, tabLimit, lastTabId])

  const handleClickNextPage = useCallback(() => {
    const nextPage = start + (limit || tabLimit)

    const searchParams = new URLSearchParams()
    searchParams.set('start', String(nextPage))
    searchParams.set('limit', String(limit || tabLimit))

    if (lastTabId) {
      searchParams.set('tabId', lastTabId)
    }

    setStart(nextPage)
    navigate({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    })
  }, [start, limit, tabLimit, lastTabId])

  const handleReset = (values: any, { setValues }: any) => {
    const updatedValues = { ...values, search: '' }
    setValues(updatedValues)

    if (onSearchClick) {
      onSearchClick(updatedValues)
    }

    setOpenSearch(false)
  }

  const handleCancel = () => {
    setOpenSearch(false)
  }

  const onRefresh = () => {
    setOpenSearch(false)
    listGet({ query:{ start, limit, tabId:lastTabId } })
    tabRequest.getDetail()
  }

  const handleChangeLimit = (event: any) => {
    const value = pathOr(10, ['target', 'value'], event)
    setLimit(value)
    setTabLimit(value)
  }

  const headerCellStyles = { position: 'sticky', top: 0, background: '#f1f1f1', zIndex: 1 }
  const menuItemsLimit = [10, 25, 50, 100, 500, 1000]

  const allConditions = getAllConditions(columns)

  return (
    <Scrollbar>
      <FlexBox className={className} direction="column" flex={true}>
        {!withoutBar && (
          <>
            <FlexBox align="center" className={classes.tableBarRoot}>
              <FlexBox gap={15} className={classes.addButtonRoot}>
                <Typography>
                  {title}
                </Typography>
                {onAddClick && (
                  <FlexBox justify="center" align="center" className={classes.addButton}>
                    <img src={AddIcon} alt="logo" onClick={onAddClick} data-cy="create" />
                  </FlexBox>
                )}
              </FlexBox>
              {(openSearch && onSearchClick) &&
                <FlexBox align="center" className={classes.searchBox}>
                  <Formik initialValues={{}} onSubmit={onSearchClick} onReset={handleReset}>
                    {(values:any) => {
                      const search = path(['values', 'search'], values)
                      return (
                        <Form>
                          <FlexBox justify="center" align="center" className={classes.searchInput}>
                            <IconButton type="submit">
                              <SearchIcon />
                            </IconButton>
                            <TextField
                              autoFocus={true}
                              name="search"
                              placeholder={searchPlaceholder || 'Поиск по названию, номеру, email, контактному лицу'}
                            />
                            {search && (
                              <IconButton type="reset">
                                <ClearIcon />
                              </IconButton>
                            )}
                            {!search && (
                              <IconButton type="button" onClick={handleCancel}>
                                <ClearIcon />
                              </IconButton>
                            )}
                          </FlexBox>
                        </Form>
                      )
                    }}
                  </Formik>
                </FlexBox>
              }
              {(areTabsObjects && !openSearch) && (
                <TableHeaderTabs
                  tabs={tabs}
                  status={status}
                  tabId={tabId}
                  filter={filter}
                  setStatus={setStatus}
                  setTabId={setTabId}
                  listingTableName={listingTableName}
                />
              )}
              {!openSearch && (
                <FlexBox justify="flex-end" align="center" flex gap={15}>
                  <TableHeaderButtons
                    actionsBan={actionsBan}
                    actionBanWarning={actionBanWarning}
                    onOpenTabs={() => setOpenTabs(true)}
                    setOpenSearch={setOpenSearch}
                    onRefreshClick={onRefresh}
                    onTabSettingsClick={() => setOpenColumnSettings(true)}
                    onFilterClick={() => setOpenFilterPopup(true)}
                    onExportClick={() => setOpenExportPopup(true)}
                    onSortingClick={() => setOpenSorting(true)}
                    actionDisabled={selectedRows.length === 0}
                    refreshDisabled={loading}
                    tableActions={tableActions}
                    moreActions={moreActions}
                  />
                  <Select
                    value={(limit || tabLimit)}
                    onChange={handleChangeLimit}
                    size="small"
                    variant="standard"
                  >
                    {menuItemsLimit.includes((limit || tabLimit)) ? null : (
                      <MenuItem value={limit}>{limit}</MenuItem>
                    )}
                    {menuItemsLimit.map((item:number, index:number) => {
                      return (
                        <MenuItem
                          key={index}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FlexBox align="center" className={classes.paginationRoot}>
                    <Typography>
                      <strong>{itemsOfPage}</strong>{` из ${totalItems}`}
                    </Typography>
                    <FlexBox align="center">
                      <IconButton disabled={!previousPageEnabled} onClick={handleClickPreviousPage}>
                        <NavigateBeforeIcon className={classes.paginationButton} />
                      </IconButton>
                      <Typography>{page}</Typography>
                      <IconButton disabled={!nextPageEnable} onClick={handleClickNextPage}>
                        <NavigateNextIcon className={classes.paginationButton} />
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              )}
            </FlexBox>
            {(openSearch && !loading && list && hasSearchKey && hasSearchKey.length > 0) && (
              <FlexBox className={classes.tableBarRoot}>
                <FlexBox className={classes.addButtonRoot}>
                  <Typography>
                    {`${list.length} Результатов по запросу `}
                  </Typography>
                </FlexBox>
                <FlexBox justify="flex-end" align="center" flex gap={15}>
                  <TableHeaderButtons
                    setOpenSearch={setOpenSearch}
                    onRefreshClick={onRefresh}
                    onTabSettingsClick={() => setOpenColumnSettings(true)}
                    onFilterClick={() => setOpenFilterPopup(true)}
                    onExportClick={() => setOpenExportPopup(true)}
                    onSortingClick={() => setOpenSorting(true)}
                    actionDisabled={selectedRows.length === 0}
                    refreshDisabled={loading}
                    tableActions={tableActions}
                    moreActions={moreActions}
                  />
                  <FlexBox align="center" className={classes.paginationRoot}>
                    <Typography>
                      <strong>{itemsOfPage}</strong>{` of ${totalItems}`}
                    </Typography>
                    <FlexBox align="center">
                      <IconButton disabled={!previousPageEnabled} onClick={handleClickPreviousPage}>
                        <NavigateBeforeIcon className={classes.paginationButton} />
                      </IconButton>
                      <Typography>{page}</Typography>
                      <IconButton disabled={!nextPageEnable} onClick={handleClickNextPage}>
                        <NavigateNextIcon className={classes.paginationButton} />
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
          </>
        )}
        <Scrollbar>
          <TableContainer className={classes.tableRoot} component={Paper} ref={tableElement}>
            <MuiTable size="small" aria-label="sticky table">
              <TableHead className={classes.tableHeader}>
                <TableRow sx={headerCellStyles}>
                  {checkbox && (
                    <TableCell
                      width={45}
                      align="right"
                      sx={{
                        ...headerCellStyles,
                        maxWidth: '45px',
                        minWidth: '45px'
                      }}
                    >
                      <Checkbox
                        className={classes.checkboxHeader}
                        color="primary"
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        data-cy="table-checkbox-all"
                        onChange={onSelectAllRows}
                        checked={list.length === selectedRows.length}
                      />
                    </TableCell>
                  )}
                  {actions && (
                    <TableCell width={45} sx={{ ...headerCellStyles, maxWidth: '45px', minWidth: '45px' }}>
                      {selectedRows.length > 0 && (
                        <div className={cx(classes.selectedCount, { 'active': selectedRows.length > 0 })}>
                          {selectedRows.length}
                        </div>
                      )}
                    </TableCell>
                  )}
                  {columns.map((column, index:number) => {
                    const leftStick = prop('leftStick', column)
                    const rightStick = prop('rightStick', column)
                    const width = propOr('', 'width', column) as any
                    const left = leftStick ? 0 : 'unset'
                    const right = rightStick ? 0 : 'unset'
                    const position = (leftStick || rightStick) ? 'sticky' : 'unset'
                    const styles = { ...headerCellStyles, position, left, right, minWidth: width, maxWidth: width }

                    return (
                      <TableCell
                        key={Array.isArray(column.field)
                          ? `table-cell-${column.field[1]}-${index}` : `table-cell-${column.field}-${index}`}
                        sx={styles}
                        ref={column.ref}
                        width={width}
                      >
                        <div style={{ position: 'relative' }}>
                          {t(column.headerName)}
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {!loading && list.map((item: any, index: number) => {
                  const rowPrimaryKey = prop(primaryKey, item)
                  const isSelected = selectedRows.find((item: any) => prop(primaryKey, item) === rowPrimaryKey)
                  const rowConditionData = getRowCondition(item, allConditions)
                  const background = rowConditionData ? prop('color', rowConditionData) : '#fff'
                  return (
                    <TableRow
                      key={`table-body-${item.id}-${index}`}
                      className={cx({ 'selected': isSelected })}
                      sx={{ background }}
                    >
                      {checkbox && (
                        <TableCell width={45} align="right">
                          <Checkbox
                            className={classes.checkbox}
                            color="primary"
                            inputProps={{ 'aria-label': 'select all desserts' }}
                            data-cy={`table-checkbox-row-${index}`}
                            onChange={() => onClickSelectRow(item)}
                            checked={Boolean(isSelected)}
                          />
                        </TableCell>
                      )}
                      {actions && (
                        <TableCell width={30}>
                          {actions(item, index)}
                        </TableCell>
                      )}
                      {columns.map((column, columnIndex) => {
                        const columnValue = prop(column.field, item)
                        const conditions = propOr([], 'conditions', column) as any
                        const valueGetter = prop('valueGetter', column)
                        const renderCell = prop('renderCell', column)
                        const rowValue = valueGetter ? valueGetter(columnValue) : columnValue
                        const conditionData = conditions.length ? getConditionData(columnValue, conditions) : null
                        const conditionType = prop('type', conditionData)
                        const conditionColor = prop('color', conditionData)
                        const truncatedValues = truncateText(rowValue, 80)
                        const tableCell = renderCell ? renderCell(item) : truncatedValues
                        const leftStick = prop('leftStick' as keyof TableListProps, column)
                        const rightStick = prop('rightStick' as keyof TableListProps, column)
                        const position = (leftStick || rightStick) ? 'sticky' : 'unset'
                        const left = leftStick ? 0 : 'unset'
                        const right = rightStick ? 0 : 'unset'
                        const background = conditionType === 'CELL' ? conditionColor : 'transparent'
                        const color = conditionType === 'TEXT' ? `${conditionColor} !important` : 'unset'
                        const styles = { position, left, right, background, color }
                        return (
                          <TableCell
                            key={Array.isArray(column.field) ? column.field[1] : column.field}
                            data-cy={`table-column-${columnIndex}-row-${index}`}
                            sx={styles}
                            className={cx({ 'selected': isSelected })}
                          >
                            {truncateText(tableCell, 80)}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
                {loading && (
                  <TableRow>
                    {checkbox && (
                      <TableCell align="right">
                        <Skeleton
                          animation="wave"
                          height={30}
                          width="50%"
                          style={{ marginBottom: 5, marginTop: 5, marginLeft:15 }}
                        />
                      </TableCell>
                    )}
                    {actions && (
                      <TableCell align="center">
                        <Skeleton
                          animation="wave"
                          height={30}
                          width="80%"
                          style={{ marginBottom: 5, marginTop: 5 }}
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => (
                      <TableCell key={Array.isArray(column.field) ? column.field[1] : column.field}>
                        <Skeleton
                          animation="wave"
                          height={30}
                          width="80%"
                          style={{ marginBottom: 5, marginTop: 5 }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
                {!loading && list.length === 0 && (
                  <TableRow>
                    <TableCell
                      height={50}
                      colSpan={columnLength}
                      sx={{ textAlign: 'center' }}
                    >
                      No Information
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Scrollbar>
      </FlexBox>
      <TableFilterPopup
        open={openTabs}
        setOpen={setOpenTabs}
        filterNode={tabNode}
      />
      <TableFilterPopup
        open={openColumnSettings}
        setOpen={setOpenColumnSettings}
        filterNode={columnNode}
      />
      <TableFilterPopup
        open={openFilterPopup}
        setOpen={setOpenFilterPopup}
        filterNode={filterNode}
      />
      <TableFilterPopup
        open={openSorting}
        setOpen={setOpenSorting}
        filterNode={sortingNode}
      />
      <TableFilterPopup
        open={openExportPopup}
        setOpen={setOpenExportPopup}
        filterNode={exportNode}
      />
    </Scrollbar>
  )
}

export default Table
