import * as ROUTES from 'constants/Routes'

import React from 'react'
import Box from '@mui/material/Box'
import FlexBox from 'components/main/FlexBox'
import BlankImage from 'assets/blank-image.svg'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'
import { pathParams } from 'path-params'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles({
  cardWrapper:{
    display:'flex',
    alignItems:'center',
    borderRadius:'5px',
    padding:'5px',
    width:'100%',
    height:'150px',
    boxShadow: '0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), ' +
      '0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15)',
    cursor:'pointer'
  },
  imageWrapper:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  cardImage:{
    width:'120px',
    height:'120px'
  },
  text:{
    lineHeight:'20px',
    fontWeight:'700',
    fontSize:'18px'
  }
})

export const SharedContactCard = ({ result }:any) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const updateURL = pathParams(ROUTES.LANDING_SHARED_CONTACT, { id:result?.antexId })
  const imageUrl = result?.images?.[0]?.link || BlankImage

  return (
    <Box className={classes.cardWrapper} onClick={() => navigate(updateURL, { state:{ contactId: result?.id } })}>
      <FlexBox align="flex-start" gap={10}>
        <div className={classes.imageWrapper}>
          <img
            src={imageUrl}
            alt="shared-contact-image"
            className={classes.cardImage}
          />
        </div>
        <FlexBox align="flex-start" style={{ width:'100%' }}>
          <Grid container={true} spacing={2}>
            <Grid item={true} lg={6}>
              <FlexBox align="center" justify="space-between">
                <Typography variant="subtitle1">
                  Имя:
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  {result?.name || ''}
                </Typography>
              </FlexBox>
            </Grid>
            <Grid item={true} lg={6}>
              <FlexBox align="center" justify="space-between">
                <Typography variant="body1">
                  Антекс ID:
                </Typography>
                <Typography variant="h6" className={classes.text}>
                  {result?.antexId}
                </Typography>
              </FlexBox>
            </Grid>
            <Grid item={true} lg={6}>
              <FlexBox align="center" justify="space-between">
                <Typography variant="body1">
                  Короткое имя контакта:
                </Typography>
                <Typography variant="h6" className={classes.text}>
                  {result?.shortName}
                </Typography>
              </FlexBox>
            </Grid>
            <Grid item={true} lg={6}>
              <FlexBox align="center" justify="space-between">
                <Typography variant="body1">
                  Категория контакт:
                </Typography>
                <Typography variant="h6" className={classes.text}>
                  {result?.categories && result?.categories?.length
                    ? result?.categories?.map((item:any) => item?.name).join(', ') : ''}
                </Typography>
              </FlexBox>
            </Grid>
            <Grid item={true} lg={6}>
              <FlexBox align="center" justify="space-between">
                <Typography variant="body1">
                  Контактный телефон:
                </Typography>
                <Typography variant="h6" className={classes.text}>
                  {result?.number}
                </Typography>
              </FlexBox>
            </Grid>
            <Grid item={true} lg={6}>
              <FlexBox align="center" justify="space-between">
                <Typography variant="body1">
                  ИНН:
                </Typography>
                <Typography variant="h6" className={classes.text}>
                  {result?.inn}
                </Typography>
              </FlexBox>
            </Grid>
          </Grid>
        </FlexBox>
      </FlexBox>
    </Box>
  )
}
