import * as API from 'constants/Api'

import React from 'react'
import SearchField from 'components/form/SearchField'
import { prop } from 'ramda'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value, name, id: value }
  }

  return null
}

function WarehouseField (props: any) {
  return (
    <SearchField
      name="warehouseId"
      api={API.WarehouseSuggestions}
      getOptionValue={getOptionValue}
      label="Склад"
      {...props}
    />
  )
}

export default WarehouseField
