// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

html {
    overflow: hidden;
}

a {
    text-decoration: none;
}

body > iframe {
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.space-container {
  padding: 48px;
}

::placeholder {
  color: #cdcdcd !important;
  opacity: 1;
}

.resize-handle-setting {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 2px solid #DCDCDC !important;
}

.resize-handle-setting:hover {
  border-color: #ccc !important;
}

.resize-handle-setting.active {
  border-color: #517ea5 !important;
}

.resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: -17px;
  z-index: 1;
  border-right: 2px solid transparent !important;
}

.resize-handle:hover {
  border-color: #ccc !important;
}

.resize-handle.active {
  border-color: #517ea5 !important;
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,MAAM;EACN,UAAU;EACV,0CAA0C;AAC5C;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,UAAU;EACV,UAAU;EACV,8CAA8C;AAChD;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n    font-family: 'Inter', sans-serif;\n}\n\nhtml {\n    overflow: hidden;\n}\n\na {\n    text-decoration: none;\n}\n\nbody > iframe {\n  display: none;\n}\n\ninput[type=number]::-webkit-inner-spin-button,\ninput[type=number]::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.space-container {\n  padding: 48px;\n}\n\n::placeholder {\n  color: #cdcdcd !important;\n  opacity: 1;\n}\n\n.resize-handle-setting {\n  display: block;\n  position: absolute;\n  cursor: col-resize;\n  width: 7px;\n  right: 0;\n  top: 0;\n  z-index: 1;\n  border-right: 2px solid #DCDCDC !important;\n}\n\n.resize-handle-setting:hover {\n  border-color: #ccc !important;\n}\n\n.resize-handle-setting.active {\n  border-color: #517ea5 !important;\n}\n\n.resize-handle {\n  display: block;\n  position: absolute;\n  cursor: col-resize;\n  width: 7px;\n  right: 0;\n  top: -17px;\n  z-index: 1;\n  border-right: 2px solid transparent !important;\n}\n\n.resize-handle:hover {\n  border-color: #ccc !important;\n}\n\n.resize-handle.active {\n  border-color: #517ea5 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
