import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'AND', name: 'И' },
  { value: 'OR', name: 'ИЛИ' },
]

function CriteriaTypeSelectField (props:any) {
  return (
    <SelectField
      label="Критерии запроса"
      options={OPTIONS}
      defaultValue={OPTIONS[0].value}
      {...props}
    />
  )
}

export default CriteriaTypeSelectField
