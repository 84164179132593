import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import DateField from 'components/form/DateField'

import ClientField from './form/ClientField'
import TerritoryField from './form/TerritoryField'
import LineItems from './form/LineItems'
import WarehouseField from './form/WarehouseField'

function RentCreateForm () {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Добавить аренду торгового оборудования"
          rightButton={(
            <>
              <Button type="submit">Создать</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <WarehouseField
                      label="Со склада"
                      name="fromWarehouseId"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <WarehouseField
                      label="На склад"
                      name="toWarehouseId"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <DateField
                      label="С даты"
                      name="fromDate"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <DateField
                      label="По дату"
                      name="toDate"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TerritoryField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <ClientField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label="Условия"
                      name="terms"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(RentCreateForm)
