import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import { prop } from 'ramda'
import SearchField from 'components/form/SearchField'
import { useField } from 'formik'
import { getRequest } from 'api/BaseApi'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value, name, id: value }
  }

  return null
}

function ProducedInField (props: any) {
  const { name, form } = props
  const [, meta, field] = useField(name)

  useEffect(() => {
    if (typeof meta.initialValue === 'string') {
      getRequest(API.CountryListSuggestion, { query :{ searchKey:meta.initialValue } })
        .then((response: any) => {
          field.setValue(getOptionValue(response[0]))
        })
        .then(() => form.setStatus(true))
    }
  }, [])

  return (
    <SearchField
      api={API.CountryList}
      getOptionValue={getOptionValue}
      label="Страна"
      primaryKey="name"
      {...props}
    />
  )
}

export default ProducedInField
