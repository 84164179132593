import React from 'react'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'
import DateTimePickerField from 'components/form/DateTimePickerField'

import CustomSelectField from './CustomSelectField'

import { CUSTOM_FIELD_TYPE } from '../../constants'

const getFieldByType = (fieldType: string, values:any, rest: any) => {
  if (fieldType === CUSTOM_FIELD_TYPE.DATE_PICKER) {
    return (
      <DateField
        label="Дата"
        {...rest}
      />
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.DROPDOWN && values) {
    return (
      <CustomSelectField
        label="Дата"
        options={values}
        {...rest}
      />
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.TEXT_AREA) {
    return (
      <TextField
        label="Описание"
        minRows={4}
        multiline
        {...rest}
      />
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.DATE_TIME_PICKER) {
    return (
      <DateTimePickerField
        label="Дата и время"
        {...rest}
      />
    )
  }
  if (fieldType === CUSTOM_FIELD_TYPE.NUMBER_BOX) {
    return (
      <TextField
        label="Ценить"
        type="number"
        {...rest}
      />
    )
  }

  return (
    <TextField
      label="Значение поля"
      {...rest}
    />
  )
}

export const CustomValueField = (props: any) => {
  const { type, values, ...rest } = props
  return getFieldByType(type, values, rest)
}
