import * as API from 'constants/Api'

import React from 'react'
import { useField } from 'formik'
import { path } from 'ramda'

import AutoSelectField from './AutoSelectField'

function ClientAutoSelect (props: any) {
  const [ orderField, , ] = useField('orderId')
  const [ territoryId, , ] = useField('territoryId')
  const id = path(['value', 'id'], territoryId)
  return (
    <AutoSelectField
      label="Клиент"
      name="contactId"
      api={API.ContactGetByTerritory}
      param={{ id }}
      disabled={orderField.value || !id}
      {...props}
    />
  )
}

export default ClientAutoSelect
