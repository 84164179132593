import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { updateWarehouseGroup, getWarehouseGroupDetail } from '../api'
import WarehouseGroupUpdateForm from '../components/WarehouseGroupUpdateForm'
import {
  warehouseGroupDetailSerializer,
  warehouseGroupUpdateSerializer
} from '../serializer'

function WarehouseGroupUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const warehouseGroupUpdate = usePut(updateWarehouseGroup)
  const warehouseGroupDetail = useGetDetail(getWarehouseGroupDetail, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    return warehouseGroupUpdate.putData({ data: warehouseGroupUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Группа была успешно обновлена' }))
      .then(() => navigate(ROUTES.WAREHOUSE_GROUP_LIST))
  }, [])

  const initialValues = useMemo(() => {
    return warehouseGroupDetailSerializer(warehouseGroupDetail.result)
  }, [warehouseGroupDetail.result])

  return (
    <AppLayout>
      {!warehouseGroupDetail.loading && (
        <WarehouseGroupUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        />
      )}
      {warehouseGroupDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default WarehouseGroupUpdateContainer
