export const getTranslatedColumn = (code: string, name:string): string => {
  const translations: { [key: string]: string } = {
    'NUMBER': 'Номер заказа',
    'ORDER': 'Заказ',
    'WAREHOUSE': 'Склад',
    'DRIVER': 'Водитель',
    'PICKER': 'Сборщик',
    'STATUS': 'Статус',
    'PACKER': 'Упаковщик',
    'DELIVERY_DATE': 'Дата доставки',
    'CURRENCY': 'Валюта',
  }

  return translations[code] || name
}
