import { defaultTo, partialRight, pipe, replace } from 'ramda'

export const formatMoney = pipe(
  defaultTo(0),
  Number,
  partialRight(new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format, [])
)

export const parseStringToNumber = pipe(
  replace(/,/g, ''), // Remove commas
  defaultTo('0'), // Default to '0' if the result is falsy
  Number, // Convert to number
)

export const toNumberFormat = (value:string | number):any => {
  if (typeof value === 'string') {
    return parseStringToNumber(value)
  }
  return formatMoney(value)
}

export const toNumber = (value:string | number):any => {
  if (typeof value === 'string') {
    return parseStringToNumber(value)
  }
  return value
}

export const stringFormatMoney = (value:number | string) => {
  if (typeof value === 'number') {
    return formatMoney(value)
  }
  return value
}
