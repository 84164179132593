import * as API from 'constants/Api'

import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { pathOr, prop, propOr } from 'ramda'
import { getRequest } from 'api/BaseApi'

import CustomSelectField from './CustomSelectField'

function ContactAddressSelectField (props:any) {
  const [select, meta, helpers] = useField('addressId')
  const selectValue = prop('value', select)
  const contact = useField('customerId')
  const contactId = pathOr(prop('value', contact[0]), ['0', 'value', 'id'], contact)
  const address = useField('address')
  const setAddress = address[2]
  const [contactAddress, setContactAddress] = useState([])
  const isHasContactAddress = !contactAddress.length ? [{ name:'Нет адреса', value:'' }] : contactAddress

  useEffect(() => {
    if (contactId) {
      getRequest(API.ContactGetAddressByContactId, { params:{ contactId } })
        .then((response:any) => {
          if (meta.initialValue) {
            const matchedValue = response.filter((item: any) => item.id === meta.initialValue.id)
            helpers.setValue(matchedValue[0])
          }
          setContactAddress(response)
        })
    }
  }, [contactId])

  useEffect(() => {
    if (selectValue) {
      const countryId = propOr(address[0]?.value?.countryId, 'country', selectValue)
      const regionId = propOr(address[0]?.value?.regionId, 'region', selectValue)
      setAddress.setValue({
        ...address[0].value,
        countryId,
        regionId,
        addressLine1:selectValue.addressLine1,
        addressLine2:selectValue.addressLine2,
        landmark:selectValue.landmark,
        location:selectValue.lat + ',' + selectValue.lon
      })
    }
  }, [selectValue])

  return (
    <CustomSelectField
      label="Выбор адреса"
      name="addressId"
      options={isHasContactAddress}
      data-cy="status"
      {...props}
    />
  )
}

export default ContactAddressSelectField
