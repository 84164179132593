import * as API from 'constants/Api'

import React from 'react'
import { prop } from 'ramda'

import VisitSearchField from './VisitSearchField'

const translations: { [key: string]: string } = {
  ACTIVE: 'Активный',
  INACTIVE: 'Неактивный',
  INCOMPLETE: 'Незавершенный',
  CANCELLED : 'Отменено',
  INPROGRESS:'В процессе',
  ONHOLD:'Приостановлено',
  DRAFT:'Черновик',
  COMPLETED:'Завершено',
  PLANNED:'Запланировано'
}

const getTranslatedHeaderName = (code: string, name:string): string => {
  return translations[code] || name
}

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('id', value)
    const name = prop('name', value)
    const code = prop('code', value)
    return { id, name:getTranslatedHeaderName(code, name), code }
  }

  return null
}

function VisitStatusField () {
  return (
    <VisitSearchField
      name="status"
      primaryKey="code"
      api={API.TaskStatusSuggestions}
      getOptionValue={getOptionValue}
      label="Статус"
    />
  )
}

export default VisitStatusField
