import { path, prop } from 'ramda'

export const contactListSerializer = (values:any) => {
  const territoryId = path(['territoryId', 'id'], values)
  const getCategoryIds = prop('categoryIds', values)
  const categoryIds = getCategoryIds && getCategoryIds.map((item:any) => item.id)
  return {
    territoryId,
    categoryIds
  }
}
