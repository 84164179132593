import React, { useCallback } from 'react'
import { Box, Card, CardContent, Divider, Grid, IconButton, Skeleton, Tooltip, Typography } from '@mui/material'
import { prop } from 'ramda'
import { usePost } from 'hooks/usePost'
import CardHeader from 'components/card/CardHeader'
import Dropzone from 'components/upload/Dropzone'
import ExcelIcon from 'assets/excel-icon.svg'
import WordIcon from 'assets/word-icon.svg'
import PdfIcon from 'assets/pdf-icon.svg'
import PptIcon from 'assets/ppt-icon.svg'
import TextIcon from 'assets/text-icon.svg'
import ImageIcon from 'assets/image-icon.svg'
import { useDelete } from 'hooks/useDelete'
import FlexBox from 'components/main/FlexBox'
import { DeleteIcon } from 'components/icons/DeleteIcon'

import { uploadFiles, deleteUploadFile } from '../../api'

interface Props {
  readonly onFileSet?: any
  readonly files?: any
  readonly onFileDelete: any
}

function AttachmentField ({ onFileSet, files = [], onFileDelete }: Props) {
  const fileUpload = usePost(uploadFiles)
  const contactDeleteFile = useDelete(deleteUploadFile)

  const onDrop = useCallback((values: any = []) => {
    const data = new FormData()
    values.forEach((file: any) => {
      data.append('uploadFile', file)
    })
    fileUpload.postData({ data }).then((response) => {
      onFileSet(response)
    })
  }, [])

  const handleDelete = useCallback((id: any) => {
    contactDeleteFile.deleteData({ params: { id } }).then(() => {
      onFileDelete(id)
    })
  }, [])

  const getExtension = (filename:string) => {
    return filename.split('.').pop()
  }

  const imageFiles = (fileType:any) => {
    if (fileType === 'xlsx' || fileType === 'xls') {
      return ExcelIcon
    }
    if (fileType === 'doc' || fileType === 'docx') {
      return WordIcon
    }
    if (fileType === 'pdf') {
      return PdfIcon
    }
    if (fileType === 'ppt' || fileType === 'pptx') {
      return PptIcon
    }
    if (fileType === 'txt') {
      return TextIcon
    }

    return ImageIcon
  }

  return (
    <Card>
      <CardHeader title="Добавить вложение для контрагента" />
      <CardContent>
        <Grid container={true} spacing={1}>
          <Grid item lg={12}>
            <FlexBox align="flex-start" gap={24}>
              <Dropzone onDrop={onDrop} />
              <Box>
                {files.length > 0 && (
                  <>
                    {files?.map((item: any) => {
                      const id = prop('id', item)
                      const originalName = prop('originalName', item)
                      const name = originalName.substr(0, 150)
                      const typeField = getExtension(originalName)
                      return (
                        <Box sx={{ width:'100%' }}>
                          <FlexBox align="center" justify="space-between">
                            <FlexBox align="center" gap={8}>
                              <img
                                style={{
                                  height: 25,
                                  width:25,
                                }}
                                src={imageFiles(typeField)}
                                alt="Document"
                              />
                              <Tooltip title={name}>
                                <Typography
                                  noWrap
                                  sx={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    width: '380px'
                                  }}
                                >
                                  {name}
                                </Typography>
                              </Tooltip>
                            </FlexBox>
                            <IconButton
                              onClick={() => handleDelete(id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </FlexBox>
                        </Box>
                      )
                    })}
                    {fileUpload.loading && (
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    )}
                  </>
                )}
              </Box>

            </FlexBox>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default AttachmentField
