import React from 'react'
import { makeStyles } from '@mui/styles'
import FlexBox from 'components/main/FlexBox'
import { Avatar, Button, MenuItem, Tooltip } from '@mui/material'
import DateTimePickerField from 'components/form/DateTimePickerField'
import { formatDate, formatOnlyDate } from 'utils/dates'
import { propOr } from 'ramda'
import cx from 'classnames'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import Accordion from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import SwitchField from 'components/form/SwitchField'
import { useField } from 'formik'
import { calculateTotalDistance } from 'utils/calculateDistances'

import TerritoryField from './form/TerritoryField'
import EventStatusRow from './EventStatusRow'

import { areCoordinatesClose } from '../serializers'

const useStyles = makeStyles({
  sidebarWrapper: {
    padding: '5px'
  },
  header: {
    padding: '5px',
    height: '250px',
  },
  sidebarContent: {
    padding: '5px',
    height: 'calc(100vh - 324px)',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  item: {
    borderRadius: '5px',
    padding: '5px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    '&.active': {
      background: '#f1f1f1'
    },
  },
  userImageWrapper: {
    width: '50px',
    height: '50px',
  },
  image: {
    width: '40px',
    height: '40px'
  },
  title: {
    fontSize: '0.80rem',
    lineHeight: '1.66',
    fontWeight: '500'
  },
  subTitle: {
    fontSize: '0.75rem',
    lineHeight: '1.66',
    fontWeight: '400'
  },
  accordionDetails:{
    '&.MuiAccordionDetails-root':{
      padding:'8px 12px 16px'
    }
  }
})

interface Props {
  readonly users: any
  readonly listTracking: any
  readonly selectedLotLan: any
  readonly setSelectedLotLan: (values: any) => void
}

function SidebarRightSide ({ users, listTracking, selectedLotLan, setSelectedLotLan }: Props) {
  const classes = useStyles()
  const list = propOr([], 'list', listTracking) as any
  const [field] = useField('contact')

  return (
    <div className={classes.sidebarWrapper}>
      <div className={classes.header}>
        <FlexBox align="flex-start" gap={16} direction="column">
          <DateTimePickerField
            label="От"
            name="from"
            disabled={field.value}
          />
          <DateTimePickerField
            label="До"
            name="to"
            disabled={field.value}
          />
          <SwitchField
            label="Показывать контрагентов"
            name="contact"
          />
          <TerritoryField
            disabled={!field.value}
          />
        </FlexBox>
        <Button
          sx={{ marginTop: '8px' }}
          type="submit"
          fullWidth={true}
        >
          Загрузить
        </Button>
      </div>
      <div className={classes.sidebarContent}>
        {list.map((items: any, index: number) => {
          const user = users.find((user: any) => user.profileId === items.userId)
          const trackingList = propOr([], 'trackingList', items) as any
          return (
            <Accordion key={`accordion-user-${user?.profileId}-${index}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel1-content-${user?.profileId}`}
                id={user?.profileId}
              >
                <FlexBox align="center" gap={5}>
                  <FlexBox
                    align="center"
                    justify="center"
                    className={classes.userImageWrapper}
                  >
                    <Avatar className={classes.image} src={user?.picture?.link} alt="photoURL">
                      {user?.fullName ? user.fullName.charAt(0).toUpperCase() : ''}
                    </Avatar>
                  </FlexBox>
                  <Box>
                    <Typography className={classes.title}>
                      {user?.fullName}
                    </Typography>
                    <FlexBox align="center" gap={15}>
                      <Box>
                        <Typography className={classes.subTitle}>
                          {user?.territoryRoles?.map((item:any) => item.territoryName).join(', ')}
                        </Typography>
                        <Typography className={classes.subTitle}>
                          Tel: {user?.phone}
                        </Typography>
                      </Box>
                    </FlexBox>
                  </Box>
                </FlexBox>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {trackingList.map((item: any, trackingIndex: number) => {
                  const getDay = item[0]
                  return (
                    <Accordion key={`tracking-${getDay}-${trackingIndex}`}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel2-content-${getDay.id}`}
                        id={getDay.id}
                      >
                        <FlexBox align="center" justify="space-between" gap={10} flex={true}>
                          {formatOnlyDate(getDay.date)}
                          <FlexBox align="center" gap={5}>
                            <EventStatusRow status={item[0]?.event} />
                            {getDay?.batteryPercentage}%
                          </FlexBox>

                          <Typography className={classes.subTitle}>
                            {calculateTotalDistance(item)} km
                          </Typography>
                        </FlexBox>
                      </AccordionSummary>
                      <AccordionDetails>
                        {item.map((singleItem: any, singleIndex: number) => {
                          const lat = parseFloat(propOr(0, 'latitude', singleItem)) as number
                          const lng = parseFloat(propOr(0, 'longitude', singleItem)) as number
                          const isClose = areCoordinatesClose(selectedLotLan.position, { lat, lng })
                          return (
                            <MenuItem
                              key={`menu-item-${singleIndex}-${lat}-${lng}`}
                              onClick={() => setSelectedLotLan({
                                singleItem,
                                position: { lat, lng }
                              })}
                              className={cx(classes.item, { active: isClose })}
                            >
                              {formatDate(singleItem.date)}
                              <EventStatusRow status={singleItem?.event} />
                              <Tooltip title="Процент заряда батареи">
                                <>
                                  {singleItem?.batteryPercentage}%
                                </>
                              </Tooltip>
                            </MenuItem>
                          )
                        })}
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
                {trackingList.length === 0 && (
                  <Typography variant="body2">
                    Нет данных отслеживания
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

export default SidebarRightSide
