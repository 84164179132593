import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { prop } from 'ramda'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'
import { useSnackbar } from 'context/snackbar'

import {
  getShipmentDetail,
  processShipment,
  setShipmentItemsStatus,
  setShipmentPacker,
  setShipmentStatus,
  completeShipment,
  deliverShipment,
  shipmentAttachments
} from '../api'
import ShipmentUpdateForm from '../components/ShipmentUpdateForm'
import { shipmentDetailInitialSerializer } from '../serializer'

function ShipmentUpdateContainer () {
  const { id } = useParams()
  const shipmentId = Number(id)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const shipmentDetail = useGetDetail(getShipmentDetail, { params: { id } })
  const shipmentSetPacker = usePut(setShipmentPacker)
  const shipmentProcess = usePut(processShipment)
  const shipmentSetStatus = usePut(setShipmentStatus)
  const shipmentItemSetStatus = usePut(setShipmentItemsStatus)
  const shipmentComplete = usePut(completeShipment)
  const shipmentDeliver = usePut(deliverShipment)
  const shipmentAttachment = usePut(shipmentAttachments)
  const onUpdatePacker = useCallback((value: any) => {
    shipmentSetPacker.putData({ data: { shipmentIds: [shipmentId], packerId: prop('id', value) } })
      .then(() => shipmentDetail.getDetail())
      .then(() => snackbar({ message: 'Доставка успешна обновлена.' }))
  }, [])

  const onUpdateDriver = useCallback((value: any) => {
    shipmentProcess.putData({ data: { shipmentId, driverId: prop('id', value) } })
      .then(() => shipmentDetail.getDetail())
      .then(() => snackbar({ message: 'Доставка успешна обновлена.' }))
  }, [])

  const onPackItems = useCallback((items: any) => {
    const itemsId = items.map((line: any) => line.id)
    shipmentItemSetStatus.putData({ data: { shipmentId, ids: itemsId, status: 'PACKED' } })
      .then(() => shipmentDetail.getDetail())
      .then(() => snackbar({ message: 'Доставка успешна обновлена.' }))
  }, [])

  const onPackSet = useCallback(() => {
    shipmentSetStatus.putData({ data: { status: 'PACKED', shipmentId } })
      .then(() => shipmentDetail.getDetail())
      .then(() => snackbar({ message: 'Доставка успешна обновлена.' }))
  }, [])

  const onComplete = useCallback((id: number) => {
    shipmentComplete.putData({ params: { id } })
      .then(() => snackbar({ message: 'Доставка успешно завершена' }))
      .then(() => navigate(ROUTES.SALE_ORDER_SHIPMENT_LIST))
  }, [])

  const onDeliver = useCallback((values:any) => {
    shipmentDeliver.putData({ data: values })
      .then(() => snackbar({ message: 'Доставка успешно доставлена.' }))
  }, [])

  const onAddAttachment = useCallback((values:any) => {
    shipmentAttachment.putData({ params:{ id }, data:values })
      .then(() => navigate(ROUTES.SALE_ORDER_SHIPMENT_LIST))
      .then(() => snackbar({ message: 'Вложение успешно добавлено' }))
  }, [])

  const initialValues = useMemo(() => {
    return shipmentDetailInitialSerializer(shipmentDetail.result)
  }, [shipmentDetail])

  return (
    <AppLayout>
      {!shipmentDetail.loading && (
        <ShipmentUpdateForm
          initialValues={initialValues}
          enableReinitialize={true}
          onAddAttachment={onAddAttachment}
          onUpdatePacker={onUpdatePacker}
          onUpdateDriver={onUpdateDriver}
          onPackItems={onPackItems}
          onComplete={onComplete}
          onDeliver={onDeliver}
          onPackSet={onPackSet}
        />
      )}
      {shipmentDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default ShipmentUpdateContainer
