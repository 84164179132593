import { omit, path, prop } from 'ramda'

export const orderFilterSerializer = (values:any) => {
  const result = omit(['availableColumns'], values)
  const getUsers = prop('users', result)
  const users = getUsers && getUsers.map((user:any) => user.id)
  const getRoles = prop('roles', result)
  const roles = getRoles && getRoles.map((role:any) => role.role)
  const getCriteria = prop('criteria', values)
  const criteria = getCriteria && getCriteria.map((criteria:any) => {
    const code = path(['code', 'code'], criteria)
    const value = path(['value', 'id'], criteria) || prop('value', criteria)
    const omittedValues = omit(['criteriaType'], criteria)
    return { ...omittedValues, code, value }
  })
  return { ...result, users, roles, criteria }
}

export const queryPatternSerializer = (getCriteria: any) => {
  if (!getCriteria || getCriteria.length === 0) {
    return { criteria: '' }
  }

  const groupedCriteria = getCriteria.reduce((acc: any[], criteria: any, index: number) => {
    const criteriaString = `${index + 1}`
    if (index > 0 && criteria.criteriaType === 'AND') {
      // If it's not the first criteria and the criteria type is 'AND', group with the previous criteria
      acc[acc.length - 1] = `(${acc[acc.length - 1]} AND ${criteriaString})`
    } else {
      // Otherwise, add the criteria as is
      acc.push(criteriaString)
    }
    return acc
  }, []).join(' AND ')

  return { criteria: getCriteria.length > 1 ? `(${groupedCriteria})` : groupedCriteria }
}

export const tabSerializer = (values:any) => {
  const listingColumnId = path(['listingColumnId', 'id'], values)
  const listingFilterId = path(['listingFilterId', 'id'], values)
  return { ...values, listingColumnId, listingFilterId }
}

export const tabDetailSerializer = (values:any) => {
  const listingColumnId = prop('listingColumn', values)
  const listingFilterId = prop('listingFilter', values)
  return { ...values, listingColumnId, listingFilterId }
}
