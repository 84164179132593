import * as API from 'constants/Api'

import { deleteRequest, Options, patchRequest, postRequest } from 'api/BaseApi'

export const uploadFile = (options?: Options) => {
  return postRequest<any>(API.FileUpload, options)
}

export const productFileAttach = (options?: Options) => {
  return postRequest<any>(API.ProductFileAttach, options)
}

export const productImageDelete = (options?: Options) => {
  return deleteRequest<any>(API.ProductFileDelete, options)
}

export const attachmentUpload = (options?: Options) => {
  return postRequest<any>(API.AttachmentUpload, options)
}

export const attachmentDelete = (options?: Options) => {
  return deleteRequest<any>(API.AttachmentDelete, options)
}

export const productImagePrimary = (options?: Options) => {
  return patchRequest<any>(API.ProductImagePrimary, options)
}
