import React, { useCallback } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid, IconButton,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import TextField from 'components/form/TextField'
import CardHeader from 'components/card/CardHeader'
import { FieldArray, useField } from 'formik'
import { useTranslationI18n } from 'i18n/I18nContext'
import SkeletonList from 'components/skeleton/SkeletonList'
import { prop } from 'ramda'
import useDialog from 'hooks/useDialog'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { formatMoney } from 'utils/formatMoney'
import { useGetList } from 'hooks/useGetList'

import ShipmentVariantsAddDialog from './ShipmentVariantsAddDialog'

import { getShipmentPaymentList } from '../../api'

export const useStyles = makeStyles({
  tableRoot:{
    borderRadius:'7px',
    border:'1px solid #E9E9E9'
  },
  tableHeader: {
    background: '#F1F1F1',
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset'
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      boxShadow: '2px 2px 4px rgba(167, 172, 176, 0.1)',
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly loading?: boolean
}

export function ShipmentLineItems ({ loading }: Props) {
  const variantAddDialogue = useDialog()
  const { t } = useTranslationI18n()
  const classes = useStyles()
  const contactField = useField('contactId')
  const contactId = contactField[0].value?.id
  const shipmentPaymentList = useGetList(getShipmentPaymentList, { query:{ status:'SCHEDULED', contactId } })
  const lineItemsField = useField('shipmentPaymentIds')
  const searchField = useField('search')
  const setSearchValue = searchField[2]
  const lineItems = lineItemsField[0].value || []
  const lineItemsChange = lineItemsField[2].setValue
  const error = lineItemsField[1]
  const handleVariantsAdd = useCallback((packages: any) => {
    const lineItemPackageIds = new Set(lineItems.map((item: any) => prop('id', item)))

    const uniquePackages = packages.filter((item: any) => {
      const id = prop('id', item)
      return !lineItemPackageIds.has(id)
    })

    const allVariants = uniquePackages.map((item: any) => ({
      ...item,
    }))

    const otherLineItems = lineItems.filter((lineItem: any) => {
      return packages.find((item: any) => prop('id', item) === prop('id', lineItem))
    })

    lineItemsChange([...otherLineItems, ...allVariants])
    setSearchValue.setValue('')
    variantAddDialogue.handleClose()
  }, [])
  return (
    <Card>
      <CardHeader title="Оплата доставки" />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={10}>
            <TextField name="browse" />
          </Grid>
          <Grid item={true} lg={2}>
            <Button
              fullWidth={true}
              onClick={() => variantAddDialogue.handleOpen()}
              disabled={!contactId}
            >
              Посмотреть платежы
            </Button>
          </Grid>
          {variantAddDialogue.open && (
            <ShipmentVariantsAddDialog
              open={variantAddDialogue.open}
              onClose={variantAddDialogue.handleClose}
              onAddVariants={handleVariantsAdd}
              packageList={shipmentPaymentList}
            />
          )}
          <FieldArray name="shipmentPaymentIds">
            {(fields: any) => {
              const renderLineItems = Boolean(!loading && lineItems.length)
              return (
                <Grid item={true} lg={12}>
                  <TableContainer className={classes.tableRoot}>
                    <Table size="small" sx={{ width: '100%' }}>
                      {renderLineItems && (
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            <TableCell width="30%">{t('Номер')}</TableCell>
                            <TableCell width="20%">{t('Запрошенная сумма')}</TableCell>
                            <TableCell width="20%">{t('Сумма к оплате')}</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                      )}
                      <TableBody className={classes.tableBody}>
                        {loading && <SkeletonList columnCount={7} />}
                        {renderLineItems && lineItems.map((item: any, index: number) => {
                          return (
                            <TableRow>
                              <TableCell>{prop('refNumber', item)}</TableCell>
                              <TableCell>{formatMoney(prop('requestedAmount', item))}</TableCell>
                              <TableCell>{formatMoney(prop('amount', item))}</TableCell>
                              <TableCell align="right">
                                <IconButton onClick={() => fields.remove(index)} size="large">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {error.error && <Typography color="error" ml={2} py={2}>{error.error}</Typography>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            }}
          </FieldArray>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default ShipmentLineItems
