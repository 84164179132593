import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import AppLayout from 'components/main/AppLayout'
import useScrollList from 'hooks/useScrollList'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'context/snackbar'

import PurchaseUpdateForm from '../components/PurchaseUpdateForm'
import { getProductList, getPurchaseDetail, getReceiveListPO, updatePurchase } from '../api'
import { purchaseCreateSerializer, purchaseDetailSerializer } from '../serializers'
function PurchaseUpdateContainer () {
  const { id } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const productList = useScrollList(getProductList)
  const receiveList = useGetDetail(getReceiveListPO, { params:{ id } })
  const purchaseUpdate = usePut(updatePurchase)
  const purchaseDetail = useGetDetail(getPurchaseDetail, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    return purchaseUpdate.putData({ data: purchaseCreateSerializer(values) })
      .then(() => snackbar({ message: 'Закупка успешно обнавлена' }))
      .then(() => navigate(ROUTES.FINANCE_PURCHASE_LIST))
  }, [])

  const initialValues = useMemo(() => purchaseDetailSerializer(purchaseDetail.result), [purchaseDetail])

  return (
    <AppLayout>
      {!purchaseDetail.loading && (
        <PurchaseUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          productList={productList}
          receiveList={receiveList.result}
        />
      )}
      {purchaseDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default PurchaseUpdateContainer
