import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getTransferList = (options?: Options) => {
  return getRequest<any>(API.TransferList, options)
}

export const getStockList = (options?: Options) => {
  return getRequest<any>(API.StockAvailable, options)
}

export const getProductList = (options?: Options) => {
  return getRequest<any>(API.ProductList, options)
}

export const getByIdTransfer = (options?: Options) => {
  return getRequest<any>(API.TransferGetById, options)
}

export const createTransfer = (options?: Options) => {
  return postRequest<any>(API.TransferCreate, options)
}

export const updateTransfer = (options?: Options) => {
  return putRequest<any>(API.TransferUpdate, options)
}

export const deleteTransfer = (options?: Options) => {
  return deleteRequest<any>(API.TransferDelete, options)
}

export const updateTransferStatus = (options?:Options) => {
  return patchRequest<any>(API.TransferUpdateStatus, options)
}
