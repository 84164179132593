import * as API from 'constants/Api'

import React from 'react'
import { path, prop } from 'ramda'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'
import { Grid } from '@mui/material'
import SearchField from 'components/form/SearchField'

import TerritoryField from './TerritoryField'
import ClientField from './ClientField'
import CurrencyField from './CurrencyField'

const getOptionProductValue = (option: any) => {
  if (option) {
    const id = prop('id', option)
    const name = prop('productName', option)

    return { id, name }
  }

  return null
}

const getOptionCategoryValue = (option: any) => {
  if (option) {
    const id = prop('id', option)
    const name = prop('name', option)

    return { id, name }
  }

  return null
}

const getFieldByType = (fieldType: string, lookUpType:string, rest: any) => {
  if (fieldType === 'DATE_TIME_PICKER') {
    return (
      <DateField
        label="Дата"
        {...rest}
      />
    )
  }
  if (fieldType === 'NUMBER_BOX') {
    return (
      <TextField
        label="Количество"
        type="number"
        {...rest}
      />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'TERRITORY') {
    return (
      <TerritoryField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'CONTACT') {
    return (
      <ClientField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'CURRENCY') {
    return (
      <CurrencyField {...rest} />
    )
  }
  if (fieldType === 'LOOKUP_WITH_QTY') {
    const isProduct = lookUpType === 'PRODUCT'
    const api = isProduct ? API.ProductDetailList : API.CategorySuggestionList
    const getOptionValue = isProduct ? getOptionProductValue : getOptionCategoryValue
    const label = isProduct ? 'Продукт' : 'Продуктовая корзина'
    const name = isProduct ? 'productId' : 'categoryId'
    return (
      <Grid container spacing={2}>
        <Grid item={true} lg={6}>
          <SearchField
            api={api}
            getOptionValue={getOptionValue}
            label={label}
            name={name}
          />
        </Grid>
        <Grid item={true} lg={6}>
          <TextField
            label="Количество"
            type="number"
            {...rest}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <TextField
      label="Значение поля"
      {...rest}
    />
  )
}

export const ComparatorValueField = (props: any) => {
  const { item, ...rest } = props
  const fieldType = path(['code', 'fieldType'], item)
  const lookUpType = path(['code', 'lookUpType'], item)
  return getFieldByType(fieldType, lookUpType, rest)
}
