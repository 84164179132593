import React from 'react'
import AppLayout from 'components/main/AppLayout'

import CountryCreateForm from '../components/CountryCreateForm'

function CountryCreateContainer () {
  return (
    <AppLayout>
      <CountryCreateForm
        onSubmit={() => null}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default CountryCreateContainer
