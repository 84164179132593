import { path } from 'ramda'

export const PicklistPickerSerializer = (values: any) => {
  const pickerId = path(['pickerId', 'id'], values)

  return { ...values, pickerId }
}

export const getWarehouseIdIfAllItemsAreEqual = (list: any) => {
  const firstWarehouseId = list[0]?.warehouse?.id

  if (firstWarehouseId === undefined) {
    return null
  }

  const allItemsHaveSameWarehouseId = list.every((item: {
    warehouse: { id: any }
  }) => item.warehouse?.id === firstWarehouseId)

  if (allItemsHaveSameWarehouseId) {
    // Return an array of item ids
    return list.map((item: { id: any }) => item.id)
  } else {
    return null
  }
}
