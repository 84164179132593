import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'ADJUSTED', name: 'Скорректировано' },
  { value: 'DRAFT', name: 'Черновик' }
]

function StatusField () {
  return (
    <SelectField
      name="status"
      label="Cтатус"
      options={OPTIONS}
    />
  )
}

export default StatusField
