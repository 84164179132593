import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'DRAFT', name: 'ЧЕРНОВИК' },
  { value: 'PLANNED', name: 'ЗАПЛАНИРОВАНО' },
  { value: 'IN_PROGRESS', name: 'В ПРОЦЕССЕ' },
  { value: 'WAITING', name: 'ОЖИДАНИЕ' },
  { value: 'COMPLETED', name: 'FAILED' },
  { value: 'FAILED', name: 'ЗАВЕРШЕНО' },
]

function StatusField () {
  return (
    <SelectField
      name="status"
      label="Статус"
      options={OPTIONS}
      data-cy="status"
    />
  )
}

export default StatusField
