import { prop } from 'ramda'
import React, { useCallback, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { Form, Formik } from 'formik'
import TextField from 'components/form/TextField'
import SearchIcon from '@mui/icons-material/Search'
import CancelIcon from '@mui/icons-material/Cancel'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { useGetDetailWithoutCall } from 'hooks/useGetDetailWithoutCall'
import FlexBox from 'components/main/FlexBox'
import Box from '@mui/material/Box'

import { contactDetail } from '../api'
import { SharedContactCard } from '../components/shared-contact-card'
import { signInApi, SignInResponse } from '../../auth/api'
import { usePost } from '../../../hooks/usePost'
import LandingLayout2 from '../layout-2'

function LandingSearchContainer () {
  const detailContact = useGetDetailWithoutCall(contactDetail)
  const result = prop('result', detailContact)
  const signIn = usePost(signInApi)
  const token = localStorage.getItem('antex-app-token')

  useEffect(() => {
    if (!token) {
      signIn.postData({ data: { email:'admintest@test.com', password:'Admin123' } })
        .then((response: SignInResponse) => {
          const accessToken = prop('accessToken', response)
          localStorage.setItem('antex-app-token', accessToken)
        })
    }
  }, [])

  const onReset = (resetForm: any) => {
    resetForm({ values: { search: '' } })
  }

  const onSubmit = useCallback(() => {
    detailContact.getDetail({ params: { id:166 } })
  }, [])

  return (
    <LandingLayout2>
      <FlexBox align="center" direction="column" style={{ padding: '80px 150px' }}>
        <Formik onSubmit={onSubmit} initialValues={{ search: '' }}>
          {({ values, resetForm }) => (
            <Form>
              <Typography variant="h4" sx={{ marginBottom: '16px' }}>
                Сведения о контрагентах предприятий и организаций, АНТЕКС ID
              </Typography>
              <TextField
                name="search"
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => onReset(resetForm)}>
                        {values.search && <CancelIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Form>
          )}
        </Formik>
        <Box my={5} sx={{ width: '90%' }}>
          {Object.keys(result).length > 0 && (
            <Box my={2}>
              <Typography>
                {`1 Результатов по запросу `}
              </Typography>
            </Box>
          )}
          {!detailContact.loading && Object.keys(result).length > 0 && (
            <SharedContactCard result={result} />
          )}
        </Box>
      </FlexBox>
    </LandingLayout2>
  )
}

export default LandingSearchContainer
