import home from './home.json'
import product from './product.json'
import productPackage from './product-package.json'
import productCategory from './product-category.json'
import mml from './mml.json'
import brand from './brand.json'
import country from './country.json'
import currency from './currency.json'
import priceLevel from './price-level.json'
export const RU_TRANSLATION = {
  home,
  product,
  productPackage,
  productCategory,
  mml,
  brand,
  country,
  currency,
  priceLevel
}
