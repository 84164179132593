import React from 'react'
export const SectionsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25px"
      height="25px"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M21.5 2h-19a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-19a.5.5 0 00-.5-.5m-13 19H3V9.5h5.5zm6
         0h-5V9.5h5zm6.5 0h-5.5V9.5H21zm0-12.5H3V3h18z"
      />
    </svg>
  )
}
