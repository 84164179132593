import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'STORAGE', name: 'ХРАНИЛИЩЕ' },
  { value: 'DRIVER', name: 'ВОДИТЕЛЬ' },
  { value: 'CONTACT', name: 'КОНТРАГЕНТ' }
]

function WarehouseTypeField () {
  return (
    <SelectField
      name="warehouseType"
      label="Тип склада"
      options={OPTIONS}
      data-cy="status"
    />
  )
}

export default WarehouseTypeField
