import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Grid } from '@mui/material'
import { FormikFormProps, FormikProps } from 'formik'

interface Props{
  readonly form:FormikProps<FormikFormProps>
}

function CommentUpdateForm ({ form }:Props) {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать бренд"
          rightButton={(
            <>
              <Button
                type="submit"
                disabled={!form.isValid}
              >
                Создать
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label="Комментарий"
                      name="name"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(CommentUpdateForm)
