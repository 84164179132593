import React from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles({
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    }
  }
})

interface Props {
  readonly status: string
}

const getStatusColumn = (document:string) => {
  if (document === 'IMG') {
    return 'ИЗОБРАЖЕНИЕ'
  }
  if (document === 'VIDEO') {
    return 'ВИДЕО'
  }
  if (document === 'AUDIO') {
    return 'АУДИО'
  }
  if (document === 'PDF') {
    return 'PDF'
  }
  if (document === 'SHEETS') {
    return 'SHEETS'
  }
  if (document === 'OTHER') {
    return 'OTHER'
  }
}

function DocumentStatusRow ({ status }: Props) {
  const classes = useStyles()
  const isActive = status === 'IMG' || status === 'PDF'
  return (
    <div className={cx(classes.statusIndicator, { 'active': isActive })}>
      {getStatusColumn(status)}
    </div>
  )
}

export default DocumentStatusRow
