import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 10, name: '10' },
  { value: 25, name: '25' },
  { value: 50, name: '50' },
  { value: 100, name: '100' },
  { value: 500, name: '500' },
  { value: 1000, name: '1000' }
]

function LimitSelectField (props:any) {
  return (
    <SelectField
      name="limitRow"
      label="Ограничение кол. строк"
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default LimitSelectField
