import * as ROUTES from 'constants/Routes'
import * as API from 'constants/Api'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'
import useScrollList from 'hooks/useScrollList'
import { getRequest } from 'api/BaseApi'

import { getPreOrderDetail, getProductList, updatePreOrder } from '../api'
import { preOrderGetInitialValues, preOrderUpdateSerializer } from '../serializers'
import PreOrderUpdateForm from '../components/PreOrderUpdateForm'

function PreOrderUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const saleOrderUpdate = usePut(updatePreOrder)
  const saleOrderDetail = useGetDetail(getPreOrderDetail, { params: { id } })
  const productList = useScrollList(getProductList)

  const onSubmit = useCallback((values: any) => {
    return saleOrderUpdate.putData({ data: preOrderUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Пред-заказ успешно обновлён' }))
      .then(() => navigate(ROUTES.SALE_ORDER_PRE_ORDER_LIST))
  }, [])

  const initialValues = useMemo(() => {
    return preOrderGetInitialValues(saleOrderDetail.result)
  }, [saleOrderDetail.result])

  const onGetPriceLevel = useCallback((id: any, products: any) => {
    return getRequest(API.PriceLevelByProductId, { params: { id }, query: { products } })
  }, [])

  return (
    <AppLayout>
      {!saleOrderDetail.loading && (
        <PreOrderUpdateForm
          productList={productList}
          onSubmit={onSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
          handleGetPriceLevel={onGetPriceLevel}
          saleOrderDetail={saleOrderDetail}
        />
      )}
      {saleOrderDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default PreOrderUpdateContainer
