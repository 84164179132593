import { dissoc, prop } from 'ramda'

export const categoryCreateSerialize = (data: any) => {
  const newTerritories = prop('territories', data) as any
  const territories = newTerritories && newTerritories.map((ids: any) => prop('id', ids))
  return { ...data, territories, }
}

export const categoryDetailSerializer = (data:any) => {
  const territories = prop('territoryList', data)
  return { ...data, territories }
}

export const categoryUpdateSerializer = (data:any) => {
  const fieldsToRemove = ['territoryList']
  const result = fieldsToRemove.reduce((acc, field) => dissoc(field, acc), data)
  const newTerritories = prop('territories', result) as any
  const territories = newTerritories && newTerritories.map((ids: any) => prop('id', ids))
  return { ...result, territories }
}

export const selectedRowSerializer = (data: any) => {
  const productId = data && data.map((product: any) => prop('id', product))
  return [...productId]
}
