import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import AppLayout from 'components/main/AppLayout'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'
import { useParams } from 'react-router-dom'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'

import { getByIdConsignment, updateConsignment } from '../api'
import { consignmentDetail, consignmentUpdateSerializer } from '../serializers'
import ConsignmentUpdateForm from '../components/ConsignmentUpdateForm'
import { consignmentValidationSchema } from '../initial-form-values'

function ConsignmentUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const consignment = useGetDetail(getByIdConsignment, { params:{ id } })
  const consignmentUpdate = usePut(updateConsignment)

  const onSubmit = useCallback((values: any) => {
    consignmentUpdate.putData({ data: consignmentUpdateSerializer(values) })
      .then(() => navigate(ROUTES.FINANCE_CONSIGNMENT_LIST))
      .then(() => snackbar({ message: 'Консигнация успешно обновлено' }))
  }, [])

  const initialValues = useMemo(() => consignmentDetail(consignment.result), [consignment])

  return (
    <AppLayout>
      {!consignment.loading && (
        <ConsignmentUpdateForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={consignmentValidationSchema}
        />
      )}
    </AppLayout>
  )
}

export default ConsignmentUpdateContainer
