import * as ROUTES from 'constants/Routes'
import * as API from 'constants/Api'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'
import useScrollList from 'hooks/useScrollList'
import { getRequest } from 'api/BaseApi'

import {
  updateSaleOrder,
  getSaleOrderDetail,
  bookSaleOrder,
  getProductList
} from '../api'
import SaleOrderUpdateForm from '../components/SaleOrderUpdateForm'
import { SaleOrderGetInitialValues, SaleOrderUpdateSerializer } from '../serializers'

function SaleOrderUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const saleOrderUpdate = usePut(updateSaleOrder)
  const saleOrderDetail = useGetDetail(getSaleOrderDetail, { params: { id } })
  const productList = useScrollList(getProductList)
  const saleOrderBook = usePut(bookSaleOrder)

  const handleSubmit = useCallback((values: any) => {
    return saleOrderUpdate.putData({ data: SaleOrderUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Заказ успешно обновлён' }))
      .then(() => navigate(ROUTES.SALE_ORDER_LIST))
  }, [])

  const onBookOrder = useCallback((options: any) => {
    return saleOrderBook.putData(options)
      .then(() => snackbar({ message: 'Заказ успешно забронирован' }))
  }, [])

  const initialValues = useMemo(() => {
    return SaleOrderGetInitialValues(saleOrderDetail.result)
  }, [saleOrderDetail.result])

  const onGetPriceLevel = useCallback((id: any, products: any) => {
    return getRequest(API.PriceLevelByProductId, { params: { id }, query: { products } })
  }, [])

  return (
    <AppLayout>
      {!saleOrderDetail.loading && (
        <SaleOrderUpdateForm
          productList={productList}
          onSubmit={handleSubmit}
          onBook={onBookOrder}
          initialValues={initialValues}
          enableReinitialize={true}
          handleGetPriceLevel={onGetPriceLevel}
        />
      )}
      {saleOrderDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default SaleOrderUpdateContainer
