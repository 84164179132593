import { omit, path, prop, propOr } from 'ramda'
import { toNumberFormat } from 'utils/formatMoney'

export const adjustmentCreateSerializer = (data: any) => {
  const warehouseId = path(['warehouseId', 'value'], data)
  const priceLevelId = path(['priceLevelId', 'value'], data)
  const currency = path(['currency', 'code'], data)
  const items = propOr([], 'items', data) as any
  const newItems = items.map((item:any) => ({
    ...omit(['id', 'product', 'salesPrice'], item),
    price:toNumberFormat(prop('price', item)),
    discountId: path(['discountId', 'id'], item),
  }))
  return { ...data, warehouseId, priceLevelId, currency, items:newItems }
}

export const adjustmentGetInitialValues = (data: any) => {
  const items = propOr([], 'items', data) as any
  const newItems = items.map((item: any) => ({ productId: path(['product', 'id'], item), ...item }))
  const warehouseId = prop('warehouse', data)
  const supplier = prop('supplier', data)
  const priceLevelId = prop('priceLevel', data)
  return { ...data, warehouseId, items: newItems, supplier, priceLevelId }
}

export const adjustmentUpdateValues = (data: any) => {
  const fieldsRemove = ['warehouse', 'priceLevel']
  const result = omit(fieldsRemove, data)
  const priceLevelId = path(['priceLevelId', 'value'], result)
  const currency = path(['currency', 'code'], result)
  const items = propOr([], 'items', result) as any
  const newItems = items.map((item:any) => ({
    ...omit(['id', 'product', 'salesPrice'], item),
    discountId: path(['discountId', 'id'], item),
  }))
  const warehouseId = path(['warehouseId', 'id'], result)
  return { ...result, priceLevelId, warehouseId, currency, items: newItems }
}
