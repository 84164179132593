import * as ROUTES from 'constants/Routes'

import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Grid } from '@mui/material'
import { useNavigate } from 'react-router'

import TerritoryTypeField from './form/TerritoryTypeField'
import MultipleUsersField from './form/UsersField'

export interface TerritoryForm {
  readonly productName: string,
  readonly territoryType: string,
  readonly territoryManagerId: number,
  readonly parentTerritoryId: number,
  readonly clients: number[]
  readonly user:[]
}

function TerritoryCreateForm () {
  const navigate = useNavigate()
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать территорию"
          rightButton={(
            <>
              <Button type="button" onClick={() => navigate(ROUTES.SETTINGS_TERRITORY_LIST)}>Назад</Button>
              <Button type="submit">Создать</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label="Название территории"
                      name="territoryName"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TerritoryTypeField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <MultipleUsersField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Примечания"
                      name="notes"
                      multiline={true}
                      minRows={4}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(TerritoryCreateForm)
