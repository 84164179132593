import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import AppLayout from 'components/main/AppLayout'
import { useParams } from 'react-router'
import { useSnackbar } from 'context/snackbar'
import { useNavigate } from 'react-router-dom'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'
import useDialog from 'hooks/useDialog'

import { getPickDetail, changePickStatus, changePicklistStatus, pdfExport } from '../api'
import PickUpdateForm from '../components/PickUpdateForm'
import { PicklistPickerSerializer } from '../serializer'
import PicklistPickerDialogue from '../components/PicklistPickerDialogue'

function PickUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const pickerDialogue = useDialog()
  const navigate = useNavigate()
  const statusChange = usePut(changePickStatus)
  const paymentDetail = useGetDetail(getPickDetail, { params: { id } })
  const setPickerBulk = usePut(changePicklistStatus)
  const picklistGetPDF = usePut(pdfExport)

  const onPickItems = useCallback((items: any) => {
    const itemsId = items.map((line: any) => line.id)
    statusChange.putData({ data: { picklistId:Number(id), ids: itemsId, status: 'PICKED' } })
      .then(() => paymentDetail.getDetail())
      .then(() => snackbar({ message: 'Статус элементов сборку успешно обновлен.' }))
  }, [])

  const onPickerBulk = useCallback((values:any) => {
    setPickerBulk.putData({ data: PicklistPickerSerializer(values) })
      .then(() => snackbar({ message: 'Пикер успешно выбрал.' }))
      .then(() => pickerDialogue.handleClose())
      .then(() => navigate(ROUTES.ORDER_PICKER_LIST))
  }, [])

  const onPrintPDF = useCallback((id: number) => {
    picklistGetPDF.putData({ params: { id }, data: { id }, responseType: 'blob' })
      .then((response: any) => {
        // Convert response to blob
        const blob = new Blob([response], { type: 'application/pdf' })

        // Create a URL for the blob
        const pdfUrl = window.URL.createObjectURL(blob)

        // Open a new window with the PDF
        const newWindow = window.open(pdfUrl, '_blank')

        // Check if the window opened successfully
        if (newWindow) {
          // Add a print event listener
          newWindow.addEventListener('load', () => {
            newWindow.print()
          })

          // Cleanup
          window.URL.revokeObjectURL(pdfUrl)
        }
      })
  }, [])

  const initialValues = useMemo(() => {
    return paymentDetail.result
  }, [paymentDetail])

  return (
    <AppLayout>
      {!paymentDetail.loading && (
        <PickUpdateForm
          initialValues={initialValues}
          onPrintPDF={onPrintPDF}
          onPickItems={onPickItems}
          onPickerSet={pickerDialogue.handleOpen}
          statusChangeLoading={statusChange.loading}
        />
      )}
      {pickerDialogue.open && (
        <PicklistPickerDialogue
          open={pickerDialogue.open}
          initialValues={{ picklistIds:[Number(id)] }}
          onSubmit={onPickerBulk}
          handleClose={pickerDialogue.handleClose}
        />
      )}
      {paymentDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default PickUpdateContainer
