import React, { useEffect, useState } from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import DateField from 'components/form/DateField'
import { pathOr, path } from 'ramda'
import { useField } from 'formik'
import { useParams } from 'react-router-dom'

import WarehouseField from './form/WarehouseField'
import PaymentMethodField from './form/PaymentMethodField'
import LineItems from './form/LineItems'
import SaleOrderSummery from './SaleOrderSummery'
import ClientField from './form/ClientField'
import TerritoryField from './form/TerritoryField'
import PriceLevelField from './form/PriceLevelField'
import AttachmentField from './form/AttachmentField'
import CurrencyField from './form/CurrencyField'
import AgentField from './form/AgentField'
import DriverField from './form/DriverField'
import VisitField from './form/VisitField'
import CountryField from './form/CountryField'
import RegionField from './form/RegionField'
import ContactAddressSelectField from './form/ContactAddressSelectField'
import VisitDateField from './form/VisitDateField'

interface Props {
  readonly productList: object
  readonly form: any
  readonly handleGetPriceLevel: (price: number, product: any) => Promise<any>
  readonly onBook: (values: any) => void
}

function SaleOrderCreateForm ({ productList, form, onBook, handleGetPriceLevel }: Props) {
  const { id } = useParams()
  const items = pathOr([], ['values', 'items'], form)
  const name = path(['values', 'soNumber'], form)
  const attachmentsField = useField('attachments')
  const statusField = useField('status')
  const status = path(['0', 'value'], statusField)
  const attchmentFieldValues = attachmentsField[0].value || []
  const attachmentSetFieldValues = attachmentsField[2]
  const [files, setFiles] = useState(attchmentFieldValues as any[])

  const isDraft = status === 'DRAFT'
  const isBooked = status === 'BOOKED' || status === 'COMPLETED'

  const handleFileSet = (value: any) => {
    setFiles((prev: any) => [...prev, value])
  }

  useEffect(() => {
    attachmentSetFieldValues.setValue(files)
  }, [files])

  useEffect(() => {
    if (form.dirty && form.status) {
      form.resetForm({ values: form.values })
      form.setStatus(undefined)
    }
  }, [form.dirty, form.status])

  const handleFileDelete = (id: any) => {
    setFiles((prev: any) => prev.filter((img: any) => img.id !== id))
  }

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить заказ (${name})`}
          rightButton={(
            <>
              {(isDraft) &&
                <Button
                  type="button"
                  onClick={() => {
                    onBook({ data: { id } })
                  }}
                  disabled={isBooked}
                >
                  Забронировать
                </Button>
              }
              <Button
                type="submit"
                disabled={isBooked}
              >
                Сохранить изменения
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали заказа" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <TerritoryField
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DateField
                      label="Дата заказа"
                      name="orderDate"
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <WarehouseField
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DriverField
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <PriceLevelField
                      form={form}
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CurrencyField
                      form={form}
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <PaymentMethodField
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      name="deliveryTerms"
                      label="Тип доставки"
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4} />
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Описание"
                      name="notes"
                      disabled={isBooked}
                      minRows={4}
                      multiline
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
            <Card>
              <CardHeader title="Клиент" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={6}>
                    <ClientField
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <ContactAddressSelectField
                      disabled={isBooked}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
            <Card>
              <CardHeader title="Адрес" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Адресс"
                      name="address.addressLine1"
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Адрес дополнительный"
                      name="address.addressLine2"
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Ориентир"
                      name="address.landmark"
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CountryField
                      label="Название страны"
                      name="address.countryId"
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <RegionField
                      label="Город"
                      name="address.regionId"
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Координаты - лат/лон"
                      name="address.location"
                      disabled={isBooked}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
            <Card>
              <CardHeader title="Детали визиты" />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item lg={4}>
                    <VisitField
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <AgentField
                      disabled={isBooked}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <VisitDateField
                      label="Дата посещения"
                      name="visitDate"
                      disabled={isBooked}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems
              disabled={isBooked}
              productList={productList}
              handleGetPriceLevel={handleGetPriceLevel}
            />
          </Grid>
          <SaleOrderSummery items={items} />
          <Grid item={true} lg={12}>
            <AttachmentField
              files={files}
              onFileSet={handleFileSet}
              onFileDelete={handleFileDelete}
            />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(SaleOrderCreateForm)
