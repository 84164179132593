export const optionSerializer = (values:any) => {
  return values && values.map((item: any) => {
    return { value: item, name: item }
  })
}

export const valuesSerialier = (values:any) => {
  return values && values.map((item:any) => {
    return { ...item, name:item.value }
  })
}
