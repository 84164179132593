import * as API from 'constants/Api'

import React, { useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from 'assets/logo.svg'
import FlexBox from 'components/main/FlexBox'
import { Grid } from '@mui/material'
import CallLogo from 'assets/call-icon.svg'
import SelectIcon from 'components/icons/SelectIcon'
import { useTranslationI18n } from 'i18n/I18nContext'
import { path, prop, propOr } from 'ramda'
import { usePost } from 'hooks/usePost'
import { parseJwt } from 'utils/tokenSerializer'
import { useGetDetail } from 'hooks/useGetDetail'
import { getRequest } from 'api/BaseApi'
import { AUTHORIZED_STATUS } from 'App'
import { useUser } from 'context/user'

import { selectCompanyContainerStyles } from './styles/SelectCompanyContainerStyles'

import { AuthGetResponse, signInApi, SignInResponse, userCompanies } from '../api'

interface Props {
  readonly setIsAuthorized:(value: AUTHORIZED_STATUS) => void
}

function SelectCompanyContainer ({ setIsAuthorized }:Props) {
  const { setUser } = useUser()
  const classes = selectCompanyContainerStyles()
  const { t } = useTranslationI18n()
  const location = useLocation()
  const userName = path(['state', 'values', 'email'], location)
  const password = path(['state', 'values', 'password'], location)
  const companiesUser = useGetDetail(userCompanies, { params:{ login:userName } })
  const companies = propOr({}, 'result', companiesUser) as any
  const signIn = usePost(signInApi)

  const onSignIn = useCallback((companyId:number) => {
    signIn.postData({ data: { email:`WEB/${companyId}/${userName}`, password } })
      .then((response: SignInResponse) => {
        const accessToken = prop('accessToken', response)
        const accessDetail = parseJwt(accessToken)
        const id = prop('userId', accessDetail)
        const companyId = prop('userCompanyId', accessDetail)
        localStorage.setItem('antex-token', accessToken)
        localStorage.setItem('antex-token', accessToken)
        getRequest(API.EmployeeGetWithAuthId,
          { headers:{ Authorization:`'Bearer' + ${accessToken}` }, params: { id, companyId } })
          .then((response) => {
            const authResponse = response as AuthGetResponse
            getRequest(API.EmployeeDetail, { params: { id:authResponse.id } }).then((response:any) => {
              setUser(response)
            })
          })
        setIsAuthorized(AUTHORIZED_STATUS.YES)
      })
  }, [setIsAuthorized])

  return (
    <>
      {!companiesUser.loading && (
        <FlexBox justify="center" direction="column" className={classes.root}>
          <FlexBox
            justify="space-between"
            flex={true}
            align="center"
            direction="column"
            className={classes.container}
          >
            <img src={Logo} alt="logo" />
            <FlexBox className={classes.selectBox}>
              <FlexBox direction="column" flex={true}>
                <FlexBox direction="column" gap={10} align="flex-start">
                  <h1 className={classes.title}>{t(`Здравствуйте`)}</h1>
                  <p className={classes.titleBody}>{t('Пожалуйста, выберите вашу компанию')}</p>
                </FlexBox>
                <Grid container={true} spacing={2} className={classes.companyBox}>
                  {companies?.companies.map((company:any) => {
                    return (
                      <Grid item={true} xs={9} sm={5} md={4} lg={4}>
                        <div
                          className={classes.selectIconWrapper}
                          onClick={company.status ? () => onSignIn(company.id) : () => null}
                        >
                          <SelectIcon />
                          <h5 className={classes.companyTitle}>{t('Компания')}</h5>
                          <h4 className={classes.companyName}>{company.name}</h4>
                        </div>
                      </Grid>
                    )
                  })}
                </Grid>
              </FlexBox>
            </FlexBox>
            <FlexBox className={classes.selectFooter} justify="space-between">
              <div className={classes.contactWrapper}>
                <div className={classes.helpBox}>
                  <FlexBox gap="12px" align="center">
                    <img src={CallLogo} alt="helpLogo" />
                    <p className={classes.titleMedium}>
                      {t('Как я')} <br />
                      {t('могу вам помочь?')}
                    </p>
                  </FlexBox>
                </div>
                <div className={classes.helpBox}>
                  <p className={classes.titleFooter}>{t('Горячая линия')}</p>
                  <h6 className={classes.titlePhone}>+998 (78) 100 00-00</h6>
                </div>
              </div>
              <FlexBox gap={31} className={classes.infoBox}>
                <Link to="/">
                  <p className={classes.titleFooter}>{t('Политика конфиденциальности')}</p>
                </Link>
                <p className={classes.titleFooter}>© Antex 2021-2023</p>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
      {companiesUser.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </>
  )
}

export default SelectCompanyContainer
