import React, { useEffect } from 'react'
import { useField } from 'formik'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useTranslationI18n } from 'i18n/I18nContext'
import dayjs from 'dayjs'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  dateField:{
    '&.MuiTextField-root':{
      '& .MuiFormLabel-root': {
        '&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
          transform: 'translate(14px, 9px) scale(1)!important',
        }
      },
    }
  }
})
function DateField ({ size = 'small', label, name, disabled, ...props }: any) {
  const { t } = useTranslationI18n()
  const classes = useStyles()
  const [field, meta, helper] = useField(name || '')
  const translatedLabel = label ? t(label) : ''
  const value = field.value ? dayjs(field.value) : null
  const errorMessage = meta.touched && meta.error ? meta.error : ''

  useEffect(() => {
    if (meta.initialValue) {
      helper.setValue(meta.initialValue)
    }
  }, [])

  return (
    <DatePicker
      className={classes.dateField}
      {...field}
      {...props}
      value={value}
      size={size}
      sx={{ width: '100%' }}
      label={translatedLabel}
      disabled={disabled}
      onChange={(value: any) => helper.setValue(value['$d'])}
      slotProps={{
        textField: {
          helperText: errorMessage,
        },
      }}
    />
  )
}

export default DateField
