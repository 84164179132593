import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getSupplierPaymentsList = (options?: Options) => {
  return getRequest<any>(API.SupplierPaymentsList, options)
}

export const createSupplierPayment = (options?: Options) => {
  return postRequest<any>(API.SupplierPaymentCreate, options)
}

export const updateSupplierPayment = (options?: Options) => {
  return putRequest<any>(API.SupplierPaymentUpdate, options)
}

export const changeStatusSupplierPayment = (options?: Options) => {
  return patchRequest<any>(API.SupplierPaymentChangeStatus, options)
}

export const deleteSupplierPayment = (options?: Options) => {
  return deleteRequest<any>(API.SupplierPaymentDelete, options)
}

export const getSupplierPaymentDetail = (options?: Options) => {
  return getRequest<any>(API.SupplierPaymentGetDetail, options)
}
