import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import SearchField from 'components/form/SearchField'
import { path, pathOr, prop } from 'ramda'
import { useField } from 'formik'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)
    const currencyId = path(['currency', 'id'], option)

    return { value, name, id: value, currencyId }
  }

  return null
}

function PriceLevelField (props: any) {
  const [ , , priceLevelHelpers ] = useField('priceLevelId')
  const [ field, , ] = useField('territories')
  const territoryValue = pathOr([], ['value'], field)
  const territoryIds = territoryValue.map((territory:any) => territory.id)

  useEffect(() => {
    if (!territoryIds.length) {
      priceLevelHelpers.setValue(null)
    }
  }, [territoryIds.length])

  return (
    <SearchField
      name="priceLevelId"
      params={{ territoryIds }}
      api={API.PriceLevelList}
      disabled={!territoryIds.length}
      getOptionValue={getOptionValue}
      label="Ценовая политика"
      {...props}
    />
  )
}

export default PriceLevelField
