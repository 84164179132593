import * as API from 'constants/Api'

import { getRequest, Options } from 'api/BaseApi'

export const getStockList = (options?: Options) => {
  return getRequest<any>(API.StockListReport, options)
}

export const getStockExcel = (options?: Options) => {
  return getRequest<any>(API.StockExcel, options)
}
