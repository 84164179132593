import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { propOr, trim } from 'ramda'

import { getCustomFields, deleteCustomField } from '../api'
import CustomFieldRowMenu from '../components/CustomFieldRowMenu'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 150
  },
  {
    headerName: 'Наименование',
    field: 'name',
    width: 500
  },
  {
    headerName: 'Тип формы',
    field: 'formType',
    width: 150
  },
  {
    headerName: 'Тип поля',
    field: 'fieldType',
    width: 150
  }
]

function CustomFieldListContainer () {
  const navigate = useNavigate()
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const [selectedRows, setSelectedRows] = useState([])

  const fieldList = useGetList(getCustomFields)
  const fieldDelete = useDelete(deleteCustomField)

  const onDelete = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить поле ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        fieldDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Поле успешно удалено' }))
          .then(() => fieldList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    fieldList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Настраиваемые поля"
        onAddClick={() => navigate(ROUTES.SETTINGS_CUSTOM_FIELD_CREATE)}
        dataRequest={fieldList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        actions={(row) => (
          <CustomFieldRowMenu item={row} handleDelete={onDelete} />
        )}
      />
    </AppLayout>
  )
}

export default CustomFieldListContainer
