import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import DateField from 'components/form/DateField'
import { useField } from 'formik'
import { path, pathOr } from 'ramda'
import DoubleFormatField from 'components/form/DoubleFormatField'

import AccountField from './form/AccountField'
import CurrencyField from './form/CurrencyField'
import PaymentMethodField from './form/PaymentMethodField'
import BankChargeAccountField from './form/BankChargeAccountField'
import ShipmentLineItems from './form/ShipmentLineItems'
import PaymentBulkSummery from './form/PaymentBulkSummary'
import ClientField from './form/ClientField'

interface Props{
  readonly form:any
  readonly onDelete:() => void
}

function BulkPaymentUpdateForm ({ form, onDelete }:Props) {
  const name = path(['values', 'number'], form)
  const items = useField('shipmentPaymentIds')
  const shipmentPayments = pathOr([], ['0', 'value'], items)
  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить массовый платеж (${name})`}
          rightButton={(
            <>
              <Button
                type="button"
                onClick={onDelete}
              >
                Удалить
              </Button>
              <Button
                type="submit"
                disabled={true}
              >
                Сохранить изменения
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={3}>
                    <DateField label="Дата" name="date" disabled={true} />
                  </Grid>
                  <Grid item={true}>
                    <ClientField />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <AccountField disabled={true} />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <DoubleFormatField
                      label="Получено"
                      name="totalAmount"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <CurrencyField disabled={true} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <PaymentMethodField disabled={true} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <BankChargeAccountField disabled={true} />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <ShipmentLineItems />
          </Grid>
          <Grid item={true} lg={12}>
            <PaymentBulkSummery items={shipmentPayments} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(BulkPaymentUpdateForm)
