import React, { ReactNode } from 'react'
import { Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useField } from 'formik'
import cx from 'classnames'
import { prop } from 'ramda'

import SaveIcon from '../../assets/save-icon.svg'
import TabFilterIcon from '../../assets/filter-icon.svg'
import { SidePopup } from '../main/SidePopup'
import withForm from '../form/withForm'

const useStyles = makeStyles({
  button: {
    fontSize: '14px',
    display: 'flex',
    height: '40px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    padding: '9px 20px',
    '&.disabled': {
      background: '#202020 !important',
      opacity: '0.5'
    }
  },
  labelButton:{
    cursor:'pointer',
    padding:'5px 10px',
    border:'none',
    background:'#fff',
    display: 'flex',
    alignItems:'center',
    width:'400px!important',
    borderRadius:'5px',
    height:'40px!important'
  },
  customInput:{
    display:'none'
  }
})

interface Props {
  readonly open: boolean
  readonly setOpen: (value: boolean) => void
  readonly exportNode?: ReactNode
}

function TableExportSidePopup ({ open, setOpen, exportNode }: Props) {
  const classes = useStyles()
  const filterNameField = useField('uploadFile')
  const filterNameFieldValue = filterNameField[0].value
  const filterSetValue = filterNameField[2]
  const fileName = prop('name', filterNameFieldValue)

  return (
    <SidePopup
      name="Импорт"
      open={open}
      onClose={() => setOpen(false)}
      icon={<img src={TabFilterIcon} alt="logo" />}
      width={700}
      header={
        <Grid container={true} spacing={2}>
          <Grid item={true} lg={8}>
            <label htmlFor="file-upload" className={classes.labelButton}>
              {fileName || 'Выберите файл'}
            </label>
            <input
              id="file-upload"
              className={classes.customInput}
              placeholder="Выберите файл"
              name="uploadFile"
              type="file"
              onChange={(event:any) => {
                filterSetValue.setValue(event.target.files[0])
              }}
            />
          </Grid>
          <Grid item={true} lg={4}>
            <Button
              type="submit"
              disabled={!filterNameFieldValue}
              className={cx(classes.button, { 'disabled': !filterNameFieldValue })} fullWidth={true}>
              <img src={SaveIcon} alt="save-icon" />
              Загрузить файл
            </Button>
          </Grid>
        </Grid>
      }
    >
      <div style={{ padding: '30px' }}>
        {exportNode && exportNode}
      </div>
    </SidePopup>
  )
}

export default withForm(TableExportSidePopup)
