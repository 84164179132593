import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { BaseTextFieldProps } from '@mui/material'
import MUITextField from '@mui/material/TextField'
import { useTranslationI18n } from 'i18n/I18nContext'
import { path } from 'ramda'

import { getRequest } from '../../api/BaseApi'
import useDebounce from '../../hooks/useDebounce'

interface Props extends BaseTextFieldProps {
  readonly inputVariant?: string
  readonly InputProps?: any
  readonly api:any
}

function ValidateTextField (props: Props) {
  const { t } = useTranslationI18n()
  const [field, meta, helpers] = useField(props.name || '')
  const [checkingError, setCheckingError] = useState<string | null>(null)
  const translatedPlaceholder = props.placeholder ? t(props.placeholder) : ''
  const api = props.api
  const CHARACTER_LIMIT = 1000
  const error = meta.touched && !!meta.error
  const valueLength = field.value?.length || 0
  const helperTextLimit = props.multiline ? `${valueLength}/${CHARACTER_LIMIT}` : ''
  const isNumber = props.type === 'number' ? { inputProps: { min:0 } } : {}
  const isNumberKey = props.type === 'number' ? (event: { key: string; preventDefault: () => void }) => {
    if (event?.key === '-' || event?.key === '+') {
      event.preventDefault()
    }
  } : undefined
  const debounceValue = useDebounce(field.value, 1500)

  useEffect(() => {
    if (meta.initialValue) {
      helpers.setValue(meta.initialValue)
    }
  }, [])

  useEffect(() => {
    if (api && debounceValue) {
      getRequest(api, { query:{ field:field.name, value:debounceValue } })
        .then((response:any) => {
          const statusCode = path(['response', 'status'], response)
          if (statusCode !== 204) {
            setCheckingError(response)
          } else {
            setCheckingError(null)
          }
        })
    }
  }, [debounceValue])

  useEffect(() => {
    if (checkingError) {
      helpers.setError(checkingError)
    }
  }, [checkingError])

  const borderColor = error || meta.initialError ? 'error.main' : 'default' // Use MUI theme colors
  return (
    <MUITextField
      {...field}
      {...props}
      variant="outlined"
      maxRows={props.maxRows || props.minRows}
      inputProps={{ maxLength: CHARACTER_LIMIT }}
      InputProps={isNumber}
      error={error || Boolean(checkingError)}
      size="small"
      helperText={(meta.touched && meta.error) || helperTextLimit || meta.initialError || checkingError}
      onKeyDown={isNumberKey}
      // @ts-ignore
      onWheel={(e) => e.target.blur()}
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline':{
          borderColor:borderColor
        }
      }}
      placeholder={translatedPlaceholder}
    />
  )
}

export default ValidateTextField
