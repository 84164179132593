import React, { ReactNode } from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'
import { Typography } from '@mui/material'

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100%',
    position: 'absolute',
    top: 0,
    pointerEvents: 'none',
    visibility: 'hidden',
    opacity: '0',
    left: 0,
    transition: 'all .15s linear',
    '&.open': {
      pointerEvents: 'all',
      visibility: 'visible',
      opacity: '1',
    }
  },
  overlay: {
    width: '100%',
    height: '100%',
  },
  panel: {
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'rgba(217, 217, 217, 0.2)',
    boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.25)',
    transform: 'translateX(100%)',
    transition: 'all .15s linear',
    backdropFilter: 'blur(15px)',
    zIndex:'9999',
    '&.open': {
      transform: 'translateX(0)'
    }
  },
  content: {
    background: 'white',
    backdropFilter: 'blur(15px)',
    height: 'calc(100% - 127px)'
  },
  header: {
    padding: '20px 30px',
    background: 'rgba(253, 253, 253, 0.5)',
    backdropFilter: 'blur(15px)'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
  }
})

interface Props {
  readonly name: string
  readonly open: boolean
  readonly children: ReactNode
  readonly onClose: () => void
  readonly icon: ReactNode
  readonly width?: number
  readonly header?: ReactNode
}

export function SidePopup (props: Props) {
  const {
    name,
    onClose,
    open,
    children,
    icon,
    width = 400,
    header
  } = props
  const classes = useStyles()

  return (
    <div className={cx(classes.root, { 'open': open })}>
      <div className={classes.overlay} onClick={onClose} />
      <div className={cx(classes.panel, { 'open': open })} style={{ width }}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            {icon}
            <Typography sx={{ fontSize: '18px', marginLeft: '10px' }}>
              {name}
            </Typography>
          </div>
          {header}
        </div>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </div>
  )
}
