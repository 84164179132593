import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import SearchField from 'components/form/SearchField'
import { path, prop } from 'ramda'
import { useField } from 'formik'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value, name, id: value }
  }

  return null
}

function PriceLevelField (props: any) {
  const [, , priceLevelHelpers] = useField('priceLevelId')
  const territory = useField('territoryId')
  const territoryId = path(['0', 'value', 'id'], territory)

  useEffect(() => {
    if (!territoryId) {
      priceLevelHelpers.setValue(null)
    }
  }, [territoryId])

  return (
    <SearchField
      disabled={!territoryId}
      name="priceLevelId"
      api={API.PriceLevelSuggestionsList}
      getOptionValue={getOptionValue}
      params={{ territoryIds: [territoryId] }}
      label="Ценовая политика"
      {...props}
    />
  )
}

export default PriceLevelField
