import { Button, Card, CardContent, IconButton, Typography } from '@mui/material'
import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import CardHeader from 'components/card/CardHeader'
import withForm from 'components/form/withForm'
import FlexBox from 'components/main/FlexBox'
import { tableStyles } from 'components/table/styles/TableStyles'
import { useLocales } from 'hooks/useLocales'

import LineItemsTable from './LineItemsTable'

interface Props {
  readonly editView: boolean,
  readonly onSetEditView: (state: boolean) => void,
  readonly nextPageEnable: boolean,
  readonly previousPageEnabled: boolean
  readonly start: number
  readonly handleClickPreviousPage: () => void
  readonly handleClickNextPage: () => void
  readonly itemsOfPage: number
  readonly totalItems: number
  readonly lineItems:any
  readonly setOpenImport:(value:boolean) => void
  readonly onGetExcel:() => void
}

function PriceLevelLineItems ({
  onSetEditView,
  editView,
  nextPageEnable,
  previousPageEnabled,
  handleClickPreviousPage,
  handleClickNextPage,
  start,
  itemsOfPage,
  totalItems,
  lineItems,
  setOpenImport,
  onGetExcel
}: Props) {
  const classes = tableStyles()
  const isVisible = Boolean(totalItems)
  const { t } = useLocales('priceLevel')
  return (
    <Card>
      <CardHeader
        title={t('Products')}
        action={
          <>
            {(editView && isVisible) && <Button type="submit" sx={{ marginRight:'8px' }}>{t('Save changes')}</Button>}
            {(!editView && isVisible) && (
              <FlexBox align="center" gap={5}>
                <Button
                  type="button"
                  onClick={onGetExcel}
                >
                  {t('Export')}
                </Button>
                <Button
                  type="button"
                  onClick={() => setOpenImport(true)}
                >
                  {t('Import')}
                </Button>
                <Button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault()
                    onSetEditView(true)
                  }}
                  sx={{ marginRight:'8px' }}
                >
                  {t('Edit')}
                </Button>
              </FlexBox>
            )}
          </>
        }
      />
      <CardContent>
        <LineItemsTable editView={editView} lineItems={lineItems} />
        {(!editView && isVisible) && (
          <FlexBox align="center" className={classes.paginationRoot}>
            <Typography>
              <strong>{itemsOfPage}</strong>{` of ${totalItems}`}
            </Typography>
            <FlexBox align="center">
              <IconButton disabled={!previousPageEnabled} onClick={handleClickPreviousPage}>
                <NavigateBeforeIcon className={classes.paginationButton} />
              </IconButton>
              <Typography>{start}</Typography>
              <IconButton disabled={!nextPageEnable} onClick={handleClickNextPage}>
                <NavigateNextIcon className={classes.paginationButton} />
              </IconButton>
            </FlexBox>
          </FlexBox>
        )}
      </CardContent>
    </Card>
  )
}

export default withForm(PriceLevelLineItems)
