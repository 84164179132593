import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'
import { GetListResponse } from 'api/BaseDto'

export const getTaskReturnList = (options?: Options) => {
  return getRequest<GetListResponse<any>>(API.TaskReturnList, options)
}

export const createTaskReturn = (options?: Options) => {
  return postRequest<any>(API.TaskReturnCreate, options)
}

export const deleteTaskReturn = (options?: Options) => {
  return deleteRequest<any>(API.TaskReturnDelete, options)
}

export const getTaskReturnById = (options?: Options) => {
  return getRequest<any>(API.TaskReturnGetById, options)
}

export const updateTaskReturn = (options?: Options) => {
  return putRequest<any>(API.TaskReturnUpdate, options)
}
