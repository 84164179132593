import React from 'react'
import FlexBox from 'components/main/FlexBox'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { Chip, Divider, Grid, IconButton, useMediaQuery } from '@mui/material'
import BlankImage from 'assets/blank-image.svg'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import LocationIcon from 'assets/agent-location-image.svg'
import L, { LatLngExpression } from 'leaflet'
import { useSnackbar } from 'context/snackbar'
import { CopyIcon } from 'components/icons/CopyIcon'
import Content from 'components/container/Content'
import { ExternalLinkIcon } from 'components/icons/ExternalLinkIcon'
import { path } from 'ramda'
import { theme } from 'theme'
import { formatDatePublic } from 'utils/dates'
import { formatPhoneNumber } from 'utils/phoneFormat'

import { SwiperSlider } from './SwiperSlider'

const useStyles = makeStyles({
  title:{
    display:'flex',
    alignItems:'center',
    marginTop:'50px',
    padding:'0 30px',
    '& h2':{
      lineHeight:'38.73px',
      fontWeight:'700',
      fontSize:'32px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      marginTop:'10px',
      '& h2':{
        fontSize:'24px',
        fontWeight:'700',
        lineHeight:'29.05px'
      },
    },
  },
  titleData:{
    fontSize:'24px',
    fontWeight:'700',
    lineHeight:'29.05px'
  },
  titleDataItem:{
    fontSize:'18px',
    fontWeight:'700',
    lineHeight:'21.78px'
  },
  titleDataItemData:{
    fontSize:'18px',
    fontWeight:'400',
    lineHeight:'21.78px'
  }
})

const locationIcon = L.icon({
  iconUrl: LocationIcon,
  iconSize: [50, 50],
  iconAnchor: [12, 41],
  popupAnchor: [2, -40],
})

function DetailForm ({ contactDetail, customFieldsList }:any) {
  const snackbar = useSnackbar()
  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const primaryAddress = path(['addressList', '0'], contactDetail)
  const position:LatLngExpression = (primaryAddress?.lat && primaryAddress?.lon)
    ? [primaryAddress.lat, primaryAddress.lon] : [41.311137, 69.280276]

  const onCopy = (text:string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        snackbar({ message:'Текст скопирован в буфер обмена' })
      }
    )
  }

  const onOpenGoogleMap = (lat?:any, lon?:any) => {
    const url = `https://www.google.com/maps?q=${lat},${lon}`
    window.open(url, '_blank')
  }

  return (
    <Box
      sx={{
        width:'100%',
        maxWidth:'1440px',
      }}
    >
      <FlexBox
        align="flex-start"
        direction="column"
        justify="start"
        flex={true}
        style={{
          width:'100%'
        }}
      >
        <Box className={classes.title} p={1}>
          <Typography variant="h2">
            Информация о компании
          </Typography>
        </Box>
        <Content sx={{
          width:'100%',
          [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
            paddingBottom: theme.spacing(4),
          },
        }}
        >
          <Grid
            container={true}
            spacing={isMobile ? 0 : 2}
          >
            {isMobile ? (
              <Grid
                item={true}
                xs={12}
              >
                <Box
                  sx={{
                    borderRadius: '8px',
                    p: 2,
                    background: '#FAFAFA',
                    width: '100%',
                    height:'300px'
                  }}
                  my={3}
                >
                  {contactDetail?.images && contactDetail?.images.length > 0 ? (
                    <SwiperSlider image={contactDetail?.image} />
                  ) : (
                    <img
                      src={BlankImage}
                      alt="Detail-image"
                      style={{
                        width:'100%',
                        height:'100%',
                        aspectRatio:'16 / 9',
                        objectFit:'contain'
                      }}
                    />
                  )}
                </Box>
              </Grid>
            ) : null}
            <Grid item={true} md={7} lg={7.5}>
              <Box
                sx={{
                  borderRadius: '8px',
                  p: 2,
                  background:'#FAFAFA'
                }}
                my={3}
              >
                <Typography variant="h6" gutterBottom mb={3} className={classes.titleData}>
                  Основные данные
                </Typography>
                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={12} lg={6} alignItems="center">
                    <Typography
                      variant="body2"
                      className={classes.titleDataItem}
                    >
                      Название контрагента
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      gap={2}
                      wrap="nowrap"
                    >
                      <Typography
                        variant="body2"
                        className={classes.titleDataItemData}
                      >
                        {contactDetail?.shortName}
                      </Typography>
                      <IconButton size="small" onClick={() => onCopy(contactDetail?.shortName)}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                  <Grid item xs={12} lg={6}>
                    <Typography variant="body2" className={classes.titleDataItem}>Статус</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container alignItems="center" justifyContent={isMobile ? 'space-between' : 'flex-end'}>
                      <Chip label="АКТИВНЫЙ" color="success" size="small" sx={{ mb:1 }} />
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                  <Grid item xs={12} lg={6}>
                    <Typography
                      variant="body2"
                      className={classes.titleDataItem}
                    >
                      Адрес
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      gap={2}
                      alignItems="center"
                      wrap="nowrap"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}>
                      <Typography
                        variant="body2"
                        className={classes.titleDataItemData}
                      >{primaryAddress?.addressLine1}</Typography>
                      <IconButton size="small" onClick={() => onCopy(primaryAddress?.addressLine1)}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                  <Grid item xs={12} lg={6}>
                    <Typography variant="body2" className={classes.titleDataItem}>Ориентир</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      gap={2}
                      wrap="nowrap"
                    >
                      <Typography
                        variant="body2"
                        className={classes.titleDataItemData}
                      >{primaryAddress?.landmark}</Typography>
                      <IconButton size="small" onClick={() => onCopy(primaryAddress?.landmark)}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                  <Grid item xs={12} lg={6}>
                    <Typography variant="body2" className={classes.titleDataItem}>Телефон организации</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      gap={2}
                      wrap="nowrap"
                    >
                      <Typography
                        variant="body2"
                        className={classes.titleDataItemData}
                      >
                        {formatPhoneNumber(contactDetail?.number)}
                      </Typography>
                      <IconButton size="small" onClick={() => onCopy(`+${contactDetail?.number}`)}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                  <Grid item xs={12} lg={6}>
                    <Typography variant="body2" className={classes.titleDataItem}>Контактное лицо</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      gap={2}
                      wrap="nowrap"
                    >
                      <Typography
                        variant="body2"
                        className={classes.titleDataItemData}
                      >
                        {contactDetail?.primaryPerson?.firstName}
                      </Typography>
                      <IconButton size="small" onClick={() => onCopy(contactDetail?.primaryPerson?.firstName)}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                </Grid>
              </Box>
              <Box
                sx={{
                  borderRadius: '8px',
                  p: 2,
                  background:'#FAFAFA'
                }}
                my={3}
              >
                <Typography variant="h6" gutterBottom mb={3} className={classes.titleData}>
                  Юридические данные
                </Typography>
                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={12} lg={6} alignItems="center">
                    <Typography variant="body2" className={classes.titleDataItem}>Юр. название</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      gap={2}
                      wrap="nowrap"
                    >
                      <Typography variant="body2" className={classes.titleDataItemData}>
                        {contactDetail?.primaryJuridical?.name}
                      </Typography>
                      <IconButton size="small" onClick={() => onCopy(contactDetail?.name)}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography variant="body2" className={classes.titleDataItem}>ИНН / ПИНФЛ</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      wrap="nowrap"
                      gap={2}
                    >
                      <Typography
                        variant="body2"
                        className={classes.titleDataItemData}
                      >
                        {contactDetail?.primaryJuridical?.inn}
                      </Typography>
                      <IconButton size="small" onClick={() => onCopy(contactDetail?.primaryJuridical?.inn)}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                  <Grid item xs={12} lg={6}>
                    <Typography variant="body2" className={classes.titleDataItem}>
                      ОКЭД
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      gap={2}
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      wrap="nowrap"
                    >
                      <Typography
                        variant="body2"
                        className={classes.titleDataItemData}
                      >{contactDetail?.primaryJuridical?.oked}</Typography>
                      <IconButton size="small" onClick={() => onCopy(contactDetail?.primaryJuridical?.oked)}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                  <Grid item xs={12} lg={6}>
                    <Typography variant="body2" className={classes.titleDataItem}>Код НДС</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      gap={2}>
                      <Typography
                        variant="body2"
                        className={classes.titleDataItemData}
                      >{contactDetail?.primaryJuridical?.taxId}</Typography>
                      <IconButton size="small" onClick={() => onCopy(contactDetail?.primaryJuridical?.taxId)}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                </Grid>
              </Box>
              <Box
                sx={{
                  borderRadius: '8px',
                  p: 2,
                  background:'#FAFAFA'
                }}
                my={3}
              >
                <Typography variant="h6" gutterBottom mb={3} className={classes.titleData}>
                  Дополнительные данные
                </Typography>
                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={12} lg={6} alignItems="center">
                    <Typography variant="body2" className={classes.titleDataItem}>AntEx ID</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      gap={2}
                      wrap="nowrap"
                    >
                      <Typography
                        variant="body2"
                        className={classes.titleDataItemData}
                      >
                        {contactDetail?.antexId}
                      </Typography>
                      <IconButton size="small" onClick={() => onCopy(contactDetail?.antexId)}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                  {customFieldsList && customFieldsList.length > 0 && customFieldsList?.map((field:any) => {
                    const isValueMultiple = field.fieldType === 'MULTI_CHECK_BOX' ||
                      field.fieldType === 'MULTI_LOOKUP'
                    const value = contactDetail?.customFieldValue?.[field?.columnCode]
                    return (
                      <React.Fragment key={field.columnCode}>
                        {(field.required && value)
                          ? <>
                            <Grid item xs={12} lg={6}>
                              <Typography variant="body2" className={classes.titleDataItem}>{field.name}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Grid
                                container
                                alignItems="center"
                                justifyContent={isMobile ? 'space-between' : 'flex-end'}
                                gap={2}
                                wrap="nowrap"
                              >
                                <Typography
                                  variant="body2"
                                  className={classes.titleDataItemData}
                                >
                                  {isValueMultiple
                                    ? value?.values?.filter((item:any) => item !== null)
                                      .map((item:any) => item?.value).join(', ') : value?.value?.value}
                                </Typography>
                                <IconButton
                                  size="small"
                                  onClick={() => onCopy(isValueMultiple
                                    ? value?.values?.filter((item:any) => item !== null)
                                      .map((item:any) => item?.value).join(', ') : value?.value?.value)}>
                                  <CopyIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                          </> : null
                        }
                      </React.Fragment>
                    )
                  })}
                  <Grid item xs={12} lg={6}>
                    <Typography
                      variant="body2"
                      className={classes.titleDataItem}
                    >
                      Дата регистрации в AntEx
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      gap={2}
                    >
                      <Typography
                        variant="body2"
                        className={classes.titleDataItemData}
                      >
                        {formatDatePublic(contactDetail?.createdDate)}
                      </Typography>
                      <IconButton size="small" onClick={() => onCopy(formatDatePublic(contactDetail?.createdDate))}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                  <Grid item xs={12} lg={6}>
                    <Typography
                      variant="body2"
                      className={classes.titleDataItem}
                    >
                      Дата посл. обновления
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      gap={2}
                    >
                      <Typography
                        variant="body2"
                        className={classes.titleDataItemData}
                      >
                        {formatDatePublic(contactDetail?.modifiedDate)}
                      </Typography>
                      <IconButton size="small" onClick={() => onCopy(formatDatePublic(contactDetail?.modifiedDate))}>
                        <CopyIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ background:'#DDDDDD', width:'98%', margin:'0 16px' }} />
                </Grid>
              </Box>
            </Grid>
            {isMobile ? null : (
              <Grid
                order={{
                  md: 1,
                  lg: 2
                }}
                item={true}
                md={5}
                lg={4.5}
                xs={12}
              >
                <Box
                  sx={{
                    borderRadius: '8px',
                    p: 2,
                    background: '#FAFAFA',
                    width: '453px',
                    height: '511px'
                  }}
                  my={3}
                >
                  <Typography variant="h6" gutterBottom mb={3} className={classes.titleData}>
                    Фотография
                  </Typography>
                  <Grid container alignItems="center" justifyContent="center">
                    <Box sx={{
                      width: '100%',
                      height: '433px'
                    }}>
                      {contactDetail?.images && contactDetail?.images.length > 0 ? (
                        <SwiperSlider image={contactDetail?.image} />
                      ) : (
                        <img
                          src={BlankImage}
                          alt="Detail-image"
                          style={{
                            width:'100%',
                            height:'100%',
                            aspectRatio:'16 / 9',
                            objectFit:'contain'
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    borderRadius: '8px',
                    background: '#FAFAFA',
                    width: '453px',
                  }}
                  my={2}
                >
                  <FlexBox align="center" justify="space-between" style={{ padding: '8px 16px' }}>
                    <Typography variant="h6" className={classes.titleData}>
                      Местоположение
                    </Typography>
                    <IconButton onClick={() => onOpenGoogleMap(primaryAddress?.lat, primaryAddress?.lon)}>
                      <ExternalLinkIcon />
                    </IconButton>
                  </FlexBox>
                  <Box px={2}>
                    <MapContainer
                      center={position}
                      zoom={14}
                      scrollWheelZoom={false}
                      zoomControl={false}
                      style={{
                        width: '100%',
                        height: '577px'
                      }}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
                    OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {primaryAddress?.lat && primaryAddress.lon && (
                        <Marker
                          position={[
                            primaryAddress?.lat,
                            primaryAddress?.lon
                          ]}
                          icon={locationIcon}
                        >
                          <Popup>
                            {contactDetail?.name}
                          </Popup>
                        </Marker>
                      )}
                    </MapContainer>
                  </Box>
                  <Box p={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '4px 16px',
                        borderRadius: '10px',
                        background: '#F2F2F2'
                      }}
                    >
                      <FlexBox align="center" gap={10}>
                        <Typography>
                          {primaryAddress?.lat}
                        </Typography>
                        <Typography>
                          {primaryAddress?.lon}
                        </Typography>
                      </FlexBox>
                      <IconButton
                        size="small"
                        onClick={() =>
                          onCopy(`${primaryAddress?.lat},${primaryAddress?.lon}`)}
                      >
                        <CopyIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
            {isMobile ? (
              <Box
                sx={{
                  borderRadius: '8px',
                  background: '#FAFAFA',
                  width: '100%'
                }}
                mt={2}
                mb={5}
              >
                <FlexBox align="center" justify="space-between" style={{ padding: '8px 16px' }}>
                  <Typography variant="h6" className={classes.titleData}>
                    Местоположение
                  </Typography>
                  <IconButton onClick={() => onOpenGoogleMap(primaryAddress?.lat, primaryAddress?.lon)}>
                    <ExternalLinkIcon />
                  </IconButton>
                </FlexBox>
                <Box px={2}>
                  <MapContainer
                    center={position}
                    zoom={14}
                    scrollWheelZoom={false}
                    zoomControl={false}
                    style={{
                      width: '100%',
                      height: '350px'
                    }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
                    OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {primaryAddress?.lat && primaryAddress?.lon && (
                      <Marker
                        position={[
                          primaryAddress?.lat,
                          primaryAddress?.lon
                        ]}
                        icon={locationIcon}
                      >
                        <Popup>
                          {contactDetail?.name}
                        </Popup>
                      </Marker>
                    )}
                  </MapContainer>
                </Box>
                <Box p={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '4px 8px',
                      borderRadius: '10px',
                      background: '#F2F2F2'
                    }}
                  >
                    <FlexBox align="center" gap={10} wrap={true}>
                      <Typography>
                        {primaryAddress?.lat}
                      </Typography>
                      <Typography>
                        {primaryAddress?.lon}
                      </Typography>
                    </FlexBox>
                    <IconButton size="small" onClick={() =>
                      onCopy(`${primaryAddress?.lat},${primaryAddress?.lon}`)}>
                      <CopyIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ) : null}
          </Grid>
        </Content>
      </FlexBox>
    </Box>
  )
}

export default DetailForm
