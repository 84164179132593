import * as React from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { Typography } from '@mui/material'
import { useMemo } from 'react'
import { useLocales } from 'hooks/useLocales'

import { getDefaultLanguage } from '../../i18n/i18n'
import FlexBox from '../main/FlexBox'

const options = [
  { title:'Русский', code:'РУС', i18code:'ru' },
  { title:'English', code:'ENG', i18code:'en' },
]

export default function LanguageSelect () {
  const { changeLanguage } = useLocales()
  const currentLanguage = getDefaultLanguage()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const defaultSelected = useMemo(() => {
    return options.find((item:any) => item.i18code === currentLanguage)
  }, [currentLanguage])

  const [selectedLanguage, setSelectedLanguage] = React.useState<any>(defaultSelected)
  const open = Boolean(anchorEl)

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    option: any,
  ) => {
    setSelectedLanguage(option)
    changeLanguage(option.i18code)
    localStorage.setItem('antex-language', option.i18code)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <List
        component="div"
        aria-label="Device settings"
      >
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={handleClickListItem}
          sx={{ color:'#fff!important' }}
        >
          <FlexBox align="center" gap={10}>
            <Typography variant="caption">
              Язык:
            </Typography>
            <Typography variant="caption">
              {selectedLanguage?.title}
            </Typography>
          </FlexBox>
        </ListItemButton>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={`${option.code}-${index}`}
            selected={option.i18code === selectedLanguage.i18code}
            onClick={(event) => handleMenuItemClick(event, option)}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
