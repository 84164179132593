import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useDelete } from 'hooks/useDelete'
import { useNavigate } from 'react-router-dom'

import BillingRowMenu from '../components/BillingRowMenu'
import { deleteBilling, deleteBillings, getBillingList } from '../api'
import BillingStatusRow from '../components/BillingStatusRow'
import { rowSerializer } from '../serializers'
import TableActionsRow from '../components/TableActionsRow'

const columns = [
  {
    headerName: 'Номер',
    field: 'id'
  },
  {
    headerName: 'Название',
    field: 'name'
  },
  {
    headerName: 'Описание',
    field: 'description'
  },
  {
    headerName: 'Сумма',
    field: 'amount'
  },
  {
    headerName: 'Статус',
    field: 'active',
    renderCell:(item:any) => <BillingStatusRow status={item.active} />
  },
  {
    headerName: 'Плата за экспорт контактов',
    field: 'contactExportCharge',
    renderCell:(item:any) => <BillingStatusRow status={item.active} />
  }
]

function BillingPlanListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const billingList = useGetList(getBillingList)
  const billingDelete = useDelete(deleteBilling)
  const billingsDelete = useDelete(deleteBillings)
  const [selectedRows, setSelectedRows] = useState([])

  const onDelete = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить тарифный план ${values.name}`

    onConfirm({ message })
      .agree(() => {
        billingDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Тарифный план успешно удалено' }))
          .then(() => billingList.getList())
      })
      .disagree()
  }, [])

  const onDeleteSelected = useCallback(() => {
    const message = `Вы уверены, что хотите удалить выбранные тарифные планы?`

    onConfirm({ message })
      .agree(() => {
        billingsDelete.deleteData({ data: rowSerializer(selectedRows) })
          .then(() => snackbar({ message: 'Тарифные планы успешно удалено' }))
          .then(() => billingList.getList())
      })
      .disagree()
  }, [selectedRows])

  return (
    <AppLayout>
      <Table
        title="Тарифный план"
        onAddClick={() => navigate(ROUTES.BACKEND_BILLING_CREATE)}
        dataRequest={billingList}
        columns={columns}
        onSearchClick={() => alert('onSearchClick')}
        tabs={['Все', 'Активы', 'Неактивы']}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkbox={true}
        actions={(row, index) => (
          <BillingRowMenu
            row={row}
            index={index}
            onDelete={onDelete}
          />
        )}
        tableActions={(
          <TableActionsRow
            onDeleteSelected={onDeleteSelected}
          />
        )}
      />
    </AppLayout>
  )
}

export default BillingPlanListContainer
