import * as ROUTES from 'constants/Routes'
import * as API from 'constants/Api'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'
import { getRequest } from 'api/BaseApi'
import useScrollList from 'hooks/useScrollList'

import { updateSalesOrderReturn, getByIdSalesOrderReturn, getProductSuggestions } from '../api'
import SaleOrderReturnUpdateForm from '../components/SaleOrderReturnUpdateForm'
import { SaleOrderReturnGetInitialValues, SaleOrderUpdateSerializer } from '../serializer'
import { validationSchema } from '../components/form-values'

function SaleOrderReturnUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const saleOrderReturnUpdate = usePut(updateSalesOrderReturn)
  const saleOrderDetail = useGetDetail(getByIdSalesOrderReturn, { params: { id } })
  const productList = useScrollList(getProductSuggestions)

  const handleSubmit = useCallback((values: any) => {
    return saleOrderReturnUpdate.putData({ data: SaleOrderUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Возвратный заказ успешно обновлён' }))
      .then(() => navigate(ROUTES.SALE_ORDER_RETURN_LIST))
  }, [])

  const initialValues = useMemo(() => {
    return SaleOrderReturnGetInitialValues(saleOrderDetail.result)
  }, [saleOrderDetail.result])

  const onGetPriceLevel = useCallback((id: any, products: any) => {
    return getRequest(API.PriceLevelByProductId, { params: { id }, query: { products } })
  }, [])

  return (
    <AppLayout>
      {!saleOrderDetail.loading && (
        <SaleOrderReturnUpdateForm
          validationSchema={validationSchema}
          productList={productList}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
          handleGetPriceLevel={onGetPriceLevel}
        />
      )}
      {saleOrderDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default SaleOrderReturnUpdateContainer
