import * as ROUTES from 'constants/Routes'

import AppLayout from 'components/main/AppLayout'
import Table from 'components/table/Table'
import { path, propOr, trim } from 'ramda'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePut } from 'hooks/usePut'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useDelete } from 'hooks/useDelete'
import { useGetList } from 'hooks/useGetList'

import { changeRentStatus, deleteRent, getRentList } from '../api'
import RentRowMenu from '../components/RentRowMenu'
import RentStatusRow from '../components/RentStatusRow'

const columns = [
  {
    headerName: 'Номер',
    field: 'number',
  },
  {
    headerName: 'С даты',
    field: 'fromDate',
  },
  {
    headerName: 'По дату',
    field: 'toDate'
  },
  {
    headerName: 'Контакт',
    field: 'renter',
    renderCell: path(['renter', 'name'])
  },
  {
    headerName: 'Территория',
    field: 'territory',
    renderCell: path(['territory', 'name'])
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (item:any) => <RentStatusRow status={item.status} />
  },
  {
    headerName: 'Количество товаров',
    field: 'itemsCount',
  }
]

function RentListContainer () {
  const navigate = useNavigate()
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const rentList = useGetList(getRentList)
  const rentDelete = useDelete(deleteRent)
  const rentChangeStatus = usePut(changeRentStatus)

  const handleDeleteRent = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить аренда ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        rentDelete
          .deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Аренда успешно удалёна' }))
          .then(() => rentList.getList())
      })
      .disagree()
  }, [])

  const handleChangeStatus = useCallback((values: any) => {
    rentChangeStatus.putData({ data:values })
      .then(() => rentList.getList())
      .then(() => snackbar({ message:'Статус аренда успешно обновлен.' }))
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    rentList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Аренда"
        onAddClick={() => navigate(ROUTES.MARKETING_RENT_CREATE)}
        dataRequest={rentList}
        columns={columns}
        onSearchClick={onTableSearch}
        checkbox={true}
        actions={(row) =>
          <RentRowMenu
            item={row}
            handleDelete={handleDeleteRent}
            onChangeStatus={handleChangeStatus}
          />}
      />
    </AppLayout>
  )
}

export default RentListContainer
