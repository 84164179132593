import * as API from 'constants/Api'

import { getRequest, Options } from 'api/BaseApi'

export const sharedContactDetail = (options?:Options, isPrivate?:boolean) => {
  return getRequest<any>(API.SharedContactPublicDetail, options, isPrivate)
}

export const contactDetail = (options?:Options, isPrivate?:boolean) => {
  return getRequest<any>(API.ContactGetById, options, isPrivate)
}

export const contactList = (options?:Options, isPrivate?:boolean) => {
  return getRequest<any>(API.ContactList, options, isPrivate)
}
