import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'true', name: 'АКТИВНЫЙ' },
  { value: 'false', name: 'НЕАКТИВНЫЙ' },
]

function CurrencyStatusField (props:any) {
  return (
    <SelectField
      name="supported"
      label="Поддерживается"
      options={OPTIONS}
      defaultValue={OPTIONS[0].value}
      data-cy="status"
      {...props}
    />
  )
}

export default CurrencyStatusField
