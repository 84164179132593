import { makeStyles } from '@mui/styles'

export const selectCompanyContainerStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
  },
  container:{
    flex: '1 1',
    overflow: 'auto',
    overflowX: 'hidden',
    background: '#FFFFFF',
    position: 'relative',
    padding: '70px 30px 30px',
  },
  selectBox: {
    margin:'50px 0 10px 0',
    boxShadow: '0px -25px 40px rgba(187, 187, 187, 0.15)',
    width: '100%',
    maxWidth: '700px',
    borderRadius: '15px',
    background: '#fff',
    position: 'relative',
    padding: '40px 50px 112px 50px',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '80%',
      height: '100%',
      background: '#fff',
      top: '-20px',
      borderRadius: '15px',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: -1,
      boxShadow: '0px -20px 40px rgba(187, 187, 187, 0.12)'
    },
    '@media(max-width:576px)':{
      padding: '20px 25px 66px 25px',
    }
  },
  selectIconWrapper:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-end',
    alignItems:'flex-start',
    border:'3px solid transparent',
    height:'232px',
    padding:'18px',
    cursor:'pointer',
    borderRadius:'10px',
    background:'linear-gradient(#F7F7F9 0%, rgba(247, 247, 249, 0) 100%)',
    transition:'all ease-in 0.3s',
    '& .selectStroke':{
      stroke:'#000',
      transition:'all ease-in 0.3s',
    },
    '&:hover':{
      border:'3px solid #1F50FF',
      color:'#1F50FF',
      '& .selectStroke':{
        stroke:'#1F50FF'
      }
    }
  },
  companyTitle:{
    fontWeight:600,
    fontSize:'13px',
    lineHeight:'15px',
    marginBottom:'4px',
    marginTop:'26px',
  },
  companyName:{
    fontWeight:600,
    fontSize:'14px',
    lineHeight:'17px'
  },
  companyBox:{
    marginTop:'68px',
    '& a':{
      color:'#000'
    }
  },
  title: {
    fontWeight: 600,
    lineHeight: '39px',
    fontSize: '32px',
    textAlign: 'start'
  },
  titleBody: {
    lineHeight: '19px',
    fontSize: '16px',
    textAlign: 'start',
    fontWeight:400,
    color:'rgba(32, 32, 32, 1)'
  },
  contactWrapper:{
    gap:'5px'
  },
  helpBox:{
    width:'212px',
    height:'72px',
    padding:'16px 18px',
    borderRadius:'10px',
    background:'rgba(247, 247, 249, 1)',
    cursor:'pointer',
    '&:nth-child(1)':{
      marginBottom:'8px'
    }
  },
  selectFooter: {
    width:'100%',
    alignItems:'flex-end',
    gap:'10px',
    '@media(max-width:576px)':{
      flexDirection:'column',
      alignItems:'flex-start',
      gap:'20px'
    }
  },
  titleFooter:{
    fontSize: '12px',
    lineHeight:'14.52px',
    color: '#999999'
  },
  titleMedium:{
    color:'rgba(0, 0, 0, 1)',
    fontSize: '13px',
    lineHeight:'15.73px',
    fontWeight:'500'
  },
  titlePhone:{
    lineHeight: '19px',
    fontSize: '16px',
    fontWeight:600,
    marginTop:'9px'
  },
  infoBox:{
    '& a':{
      textDecoration:'underline',
      color:'#999999'
    }
  }
})
