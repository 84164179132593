import React, { useCallback, useEffect } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid, IconButton,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import TextField from 'components/form/TextField'
import CardHeader from 'components/card/CardHeader'
import { FieldArray, useField } from 'formik'
import { useTranslationI18n } from 'i18n/I18nContext'
import SkeletonList from 'components/skeleton/SkeletonList'
import { pathOr, prop } from 'ramda'
import useDialog from 'hooks/useDialog'
import { formatMoney } from 'utils/formatMoney'
import { makeStyles } from '@mui/styles'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import DoubleFormatField from 'components/form/DoubleFormatField'

import PackageSelectField from './PackageSelectField'

import ReceiveAddDialog from '../ReceiveAddDialog'

const useStyles = makeStyles({
  tableRoot:{
    borderRadius:'7px',
    border:'1px solid #E9E9E9'
  },
  tableHeader: {
    background: '#F1F1F1',
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset'
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      boxShadow: '2px 2px 4px rgba(167, 172, 176, 0.1)',
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly loading?: boolean
  readonly productList: any
}

export function LineItems ({ loading, productList }: Props) {
  const classes = useStyles()
  const variantAddDialogue = useDialog()
  const { t } = useTranslationI18n()

  const lineItemsField = useField('items')
  const lineItems = lineItemsField[0].value || []
  const lineItemsChange = lineItemsField[2].setValue
  const [ field, , ] = useField('territories')
  const territoryValue = pathOr([], ['value'], field)
  const territoryIds = territoryValue.map((territory:any) => territory.id)

  useEffect(() => {
    if (!territoryIds.length) {
      lineItemsChange([])
    }
  }, [territoryIds.length])

  const handleVariantsAdd = useCallback((variants: any) => {
    const lineItemPackageIds = new Set(lineItems.map((item: any) => prop('id', item)))

    const uniquePackages = variants.filter((item: any) => {
      const packageId = prop('id', item)
      return !lineItemPackageIds.has(packageId)
    })

    const allVariants = uniquePackages.map((variant: any) => {
      const productId = prop('id', variant)
      const quantity = prop('quantity', variant)
      const productName = prop('name', variant)
      const packages = prop('packageLineItems', variant)
      return {
        id:productId,
        product: { name: productName },
        productId,
        quantity,
        price: formatMoney(0),
        packages
      }
    })

    const otherLineItems = lineItems.filter((lineItem: any) => {
      return variants.find((item: any) => prop('id', item) === prop('id', lineItem))
    })

    lineItemsChange([...otherLineItems, ...allVariants])
    variantAddDialogue.handleClose()
  }, [lineItems])

  return (
    <Card>
      <CardHeader title="Продукты" />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={10}>
            <TextField name="browse" />
          </Grid>
          <Grid item={true} lg={2}>
            <Button
              disabled={!territoryIds.length}
              fullWidth={true}
              onClick={() => variantAddDialogue.handleOpen()}
            >
              Добавить продукт
            </Button>
          </Grid>
          {variantAddDialogue.open && (
            <ReceiveAddDialog
              open={variantAddDialogue.open}
              onClose={variantAddDialogue.handleClose}
              onAddVariants={handleVariantsAdd}
              productList={productList}
              territoryIds={territoryIds}
            />
          )}
          <FieldArray name="items">
            {(fields: any) => {
              const renderLineItems = Boolean(!loading && lineItems.length)
              return (
                <Grid item={true} lg={12}>
                  <TableContainer className={classes.tableRoot}>
                    <Table size="small">
                      <TableHead className={classes.tableHeader}>
                        <TableRow>
                          <TableCell>{t('Товар')}</TableCell>
                          <TableCell width="15%">{t('Полученное количество')}</TableCell>
                          <TableCell width="20%">{t('Упаковка')}</TableCell>
                          <TableCell width="20%">{t('Цена')}</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBody}>
                        {loading && <SkeletonList columnCount={7} />}
                        {renderLineItems && lineItems.map((item: any, index: number) => {
                          const productName = pathOr('', ['product', 'name'], item)
                          return (
                            <TableRow>
                              <TableCell>{productName}</TableCell>
                              <TableCell>
                                <TextField name={`items[${index}].receivedQuantity`} type="number" />
                              </TableCell>
                              <TableCell>
                                <PackageSelectField
                                  packageLineItemId={item.packages}
                                  name={`items[${index}].packageLineItemId`}
                                />
                              </TableCell>
                              <TableCell>
                                <DoubleFormatField name={`items[${index}].price`} />
                              </TableCell>
                              <TableCell align="right">
                                <IconButton onClick={() => fields.remove(index)} size="large">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            }}
          </FieldArray>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default LineItems
