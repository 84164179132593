import React from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import CancelIcon from '@mui/icons-material/Cancel'
import { makeStyles } from '@mui/styles'

import FlexBox from '../../main/FlexBox'

const useStyles = makeStyles({
  dialog:{
    '& .MuiPaper-root': {
      background:'transparent',
      boxShadow:'none'
    },
    '& .MuiDialogTitle-root':{
      background:'#fff',
      padding:'8px 24px'
    },
    '& .MuiDialogContent-root':{
      background:'#fff',
      paddingBottom:0,
      overflowY:'hidden'
    },
    '& .MuiDialogActions-root':{
      background:'#fff',
      borderBottomLeftRadius:'5px',
      borderBottomRightRadius:'5px'
    }
  },
})

interface Props {
  readonly handleClose: () => void
  readonly open: boolean
  readonly image?:any
  readonly onUpload:() => void
  readonly onClose:() => void
}

export const ImagePreview = ({ handleClose, open, image, onUpload, onClose }: Props) => {
  const classes = useStyles()

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        className={classes.dialog}
      >
        <DialogTitle>
          <FlexBox align="center" justify="space-between">
            <Typography variant="subtitle1">
              Предпросмотр
            </Typography>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </FlexBox>
        </DialogTitle>
        <DialogContent>
          <Grid container={true}>
            <Grid item={true} xs={12} lg={12}>
              <Box sx={{ width:'500px', height:'450px', position:'relative' }} >
                <img
                  src={image}
                  alt="image_preview"
                  style={{
                    width:'100%',
                    height:'100%',
                    borderRadius:'5px',
                    objectFit:'contain'
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding:'8px 24px' }}>
          <FlexBox align="center" gap={5}>
            <Button
              color="secondary"
              variant="contained"
              onClick={onClose}
            >
              Отменить
            </Button>
            <Button
              variant="contained"
              onClick={onUpload}
            >
              Прикрепить
            </Button>
          </FlexBox>
        </DialogActions>
      </Dialog>
    </>
  )
}
