import React, { useEffect, useMemo, useRef } from 'react'
import { Marker, useMapEvents } from 'react-leaflet'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import L from 'leaflet'

interface Props {
  readonly onChange: any
  readonly lat: number
  readonly lng: number
}

function AddMarker ({ onChange, lat, lng }: Props) {
  const provider = new OpenStreetMapProvider()
  const markerRef = useRef(null)

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: provider,
    style: 'bar',
    showMarker: false,
    marker: {
      icon: new L.Icon.Default(),
      draggable: false,
    },
  })

  const map = useMapEvents({
    click: (e) => {
      onChange(e.latlng)
    }
  })

  useEffect(() => {
    map.addControl(searchControl)
    document.getElementsByClassName('reset')[0].remove()
  }, [])

  const searchEventHandler = (result: any) => {
    onChange({ lat: result.location.y, lng: result.location.x, label: result.location.label })
  }

  map.on('geosearch/showlocation', searchEventHandler)
  const eventHandlers = useMemo(
    () => ({
      dragend () {
        const marker: any = markerRef.current
        onChange(marker['_latlng'])
      },
    }),
    []
  )

  if (lat) {
    return (
      <div>
        <Marker
          key="marker-1"
          position={{ lat, lng }}
          draggable={true}
          eventHandlers={eventHandlers}
          ref={markerRef}
        />
      </div>
    )
  }
  return null
}

export default AddMarker
