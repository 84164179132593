import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { propOr, trim } from 'ramda'

import { getBillingPlanCategoryList, billingPLanCategoryDelete } from '../api'
import BillingPlanCategoryRowMenu from '../components/BillingPlanCategoryRowMenu'

const columns = [
  {
    headerName: 'Название',
    field: 'name',
  },
  {
    headerName: 'Дата создания',
    field: 'createdDate',
  }
]

function BillingPLanCategoryListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const billingPlanCategoryList = useGetList(getBillingPlanCategoryList)
  const deleteBillingPLanCategory = useDelete(billingPLanCategoryDelete)

  const handleDelete = useCallback((values:any) => {
    const message = `Вы уверены что хотите удалить категория тарифного плана ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        deleteBillingPLanCategory.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Категория тарифного плана успешно удалена' }))
          .then(() => billingPlanCategoryList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    billingPlanCategoryList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Категория тарифного плана"
        onAddClick={() => navigate(ROUTES.BACKEND_BILLING_CATEGORY_CREATE)}
        dataRequest={billingPlanCategoryList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row:any) => (
          <BillingPlanCategoryRowMenu
            item={row}
            handleDelete={handleDelete}
          />
        )}
      />
    </AppLayout>
  )
}

export default BillingPLanCategoryListContainer
