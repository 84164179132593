import * as ROUTES from 'constants/Routes'

import React from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useNavigate } from 'react-router-dom'
import { pathParams } from 'path-params'

interface Props {
  readonly item: any
  readonly handleDelete: (id: number) => void
}

function SharedContactRowMenu ({ item, handleDelete }: Props) {
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const anchorIsOpen = Boolean(optionsAnchorEl)

  const handleOpenOptions = (event: any) => {
    setOptionsAnchorEl(event.currentTarget)
  }

  const handleCloseOptions = () => {
    setOptionsAnchorEl(null)
  }

  const updatedURL = pathParams(ROUTES.BACKEND_SHARED_CONTACT_UPDATE, { id:item.id })
  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={anchorIsOpen ? 'long-menu' : undefined}
        aria-expanded={anchorIsOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenOptions}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="table-item-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={optionsAnchorEl}
        open={anchorIsOpen}
        onClose={handleCloseOptions}
      >
        <MenuItem onClick={() => {
          navigate(updatedURL)
          handleCloseOptions()
        }}>
          Просмотр
        </MenuItem>
        <MenuItem
          onClick={() => handleDelete(item)}
        >
          Удалить
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default SharedContactRowMenu
