import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { usePut } from 'hooks/usePut'
import { propOr, trim } from 'ramda'

import { deleteTransfer, getTransferList, updateTransferStatus } from '../api'
import TransferStatusRow from '../components/TransferStatusRow'
import TransferRowMenu from '../components/TransferRowMenu'

const columns = [
  {
    headerName: 'Справочный номер',
    field: 'referenceNumber',
    width: 300
  },
  {
    headerName: 'Номер прихода',
    field: 'transferNumber'
  },
  {
    headerName: 'Статус перевода',
    field: 'transferStatus',
    renderCell: (item: any) => <TransferStatusRow status={item.transferStatus} />
  },
  {
    headerName: 'Обновлено',
    field: 'updatedDate'
  }
]

function TransferListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const transferList = useGetList(getTransferList)
  const transferDelete = useDelete(deleteTransfer)
  const transferStatusUpdate = usePut(updateTransferStatus)
  const handleDeleteTransfer = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить приход ${values.transferNumber}`

    onConfirm({ message })
      .agree(() => {
        transferDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Приход успешно удалён' }))
          .then(() => transferList.getList())
      })
      .disagree()
  }, [])

  const handleUpdateTransferStatus = useCallback((id: number, status: string) => {
    return transferStatusUpdate.putData({ params: { id }, query: { status }, data: { status } })
      .then(() => snackbar({ message: 'Статус прихода успешно обновлён' }))
      .then(() => transferList.getList())
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    transferList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Перемешение"
        onAddClick={() => navigate(ROUTES.WAREHOUSE_MOVEMENT_CREATE)}
        dataRequest={transferList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row) => (
          <TransferRowMenu
            item={row}
            handleDelete={handleDeleteTransfer}
            updateStatus={handleUpdateTransferStatus}
          />
        )}
      />
    </AppLayout>
  )
}

export default TransferListContainer
