import * as API from 'constants/Api'

import React from 'react'
import { prop } from 'ramda'
import SearchField from 'components/form/SearchField'

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('id', value)
    const name = prop('name', value)
    return { id, name, value:id }
  }

  return null
}

function VisitTypeField () {
  return (
    <SearchField
      name="typeId"
      primaryKey="id"
      api={API.TaskTypeSuggestions}
      getOptionValue={getOptionValue}
      label="Тип посещение"
    />
  )
}

export default VisitTypeField
