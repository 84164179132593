import React from 'react'
import SelectField from 'components/form/SelectField'

const NUMBER_BOX_OPTIONS = [
  { value: 'EQUALS', name: 'РАВНО' },
  { value: 'NOT_EQUALS', name: 'НЕ РАВНО' },
  { value: 'EMPTY', name: 'ПУСТОЙ' },
  { value: 'NOT_EMPTY', name: 'НЕ ПУСТО' },
  { value: 'LESS_THAN', name: 'МЕНЬШЕ, ЧЕМ' },
  { value: 'LESS_THAN_OR_EQUALS', name: 'МЕНЬШЕ ИЛИ РАВНО' },
  { value: 'GREATER_THAN', name: 'БОЛЬШЕ ЧЕМ' },
  { value: 'GREATER_THAN_OR_EQUALS', name: 'БОЛЬШЕ ИЛИ РАВНО' },
]

const DATE_TIME_PICKER_OPTIONS = [
  { value: 'EQUALS', name: 'РАВНО' },
  { value: 'NOT_EQUALS', name: 'НЕ РАВНО' },
  { value: 'AFTER', name: 'ПОСЛЕ' },
  { value: 'BEFORE', name: 'ДО' },
]

const TEXT_BOX_OPTIONS = [
  { value: 'EQUALS', name: 'РАВНО' },
  { value: 'NOT_EQUALS', name: 'НЕ РАВНО' },
  { value: 'EMPTY', name: 'ПУСТОЙ' },
  { value: 'NOT_EMPTY', name: 'НЕ ПУСТО' },
  { value: 'STARTS_WITH', name: 'НАЧИНАЕТСЯ С' },
  { value: 'CONTAINS', name: 'СОДЕРЖИТ' },
  { value: 'DOES_NOT_CONTAINS', name: 'НЕ СОДЕРЖИТ' },
  { value: 'IN', name: 'В' },
  { value: 'NOT_IN', name: 'НЕ В' },
]

const LOOKUP_OPTIONS = [
  { value: 'EQUALS', name: 'РАВНО' },
  { value: 'NOT_EQUALS', name: 'НЕ РАВНО' },
  { value: 'EMPTY', name: 'ПУСТОЙ' },
  { value: 'NOT_EMPTY', name: 'НЕ ПУСТО' },
]

interface Props {
  readonly index:number
  readonly type:string
}

export const CriteriaTypeSelectField = ({ index, type }:Props) => {
  const getRowMenuByType = (type:string) => {
    if (type === 'NUMBER_BOX') {
      return NUMBER_BOX_OPTIONS
    }
    if (type === 'TEXT_BOX') {
      return TEXT_BOX_OPTIONS
    }
    if (type === 'DATE_TIME_PICKER') {
      return DATE_TIME_PICKER_OPTIONS
    }
    if (type === 'LOOKUP') {
      return LOOKUP_OPTIONS
    }
    return TEXT_BOX_OPTIONS
  }

  const options = getRowMenuByType(type)

  return (
    <SelectField
      name={`criteria[${index}].comparator`}
      label="Компаратор"
      options={options}
    />
  )
}
