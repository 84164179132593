import React from 'react'
import Typography from '@mui/material/Typography'
import { useDropzone } from 'react-dropzone'
import { Box, styled } from '@mui/material'
import UploadIcon from 'assets/upload-icon.svg'

const RootStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'isDragActive'
})({
  border: `1px dashed #A6A6A6`,
  cursor:'pointer',
  padding:'8px',
  borderRadius: 5,
  height: '200px',
  width: '200px',
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  transition: '.25s ease all'
})

interface Props {
  readonly onDrop?: any
  readonly accept?: string
}

function Dropzone ({ onDrop, accept }: Props) {
  const {
    getRootProps,
    getInputProps
  } = useDropzone({ onDrop })

  return (
    <RootStyled {...getRootProps()}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100% !important',
        background:'#F8F8F8',
        color: '#9EA1B6',
        borderRadius:'8px'
      }}>
        <div>
          <input {...getInputProps()} accept={accept} />
          <Box>
            <Box>
              <img src={UploadIcon} alt="Upload_Icon" />
            </Box>
            <Box>
              <Typography align="center" variant="subtitle2" color="inherit" sx={{ display:'inline' }}>
                Перетащите файлы или кликните
              </Typography>
              <Typography
                align="center"
                variant="subtitle2"
                sx={{ display:'inline', marginLeft:'5px', color:'#1557FF' }}
              >
                сюда
              </Typography>
            </Box>
          </Box>
        </div>
      </Box>
    </RootStyled>
  )
}

export default Dropzone
