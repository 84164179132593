import React from 'react'
import SelectField from 'components/form/SelectField'

import { optionSerializer } from './serializer'

function CustomSelectField (props:any) {
  const { options, name, ...rest } = props
  return (
    <SelectField
      name={name}
      label="Тип продукта"
      options={optionSerializer(options)}
      data-cy="status"
      {...rest}
    />
  )
}

export default CustomSelectField
