import React, { useEffect, useState } from 'react'
import { CardContent } from '@mui/material'
import { FieldArray, useField } from 'formik'
import FlexBox from 'components/main/FlexBox'
import { Sortable } from 'components/sortable/Sortable'
import { SortableItem } from 'components/sortable/SortableItem'

import { getTranslatedColumn } from '../../../../translations'

export const ColumnsUpdateField = () => {
  const [field, meta, helpers] = useField('columns')
  const [columnValues, setColumnValues] = useState([]) as any

  useEffect(() => {
    if (meta.initialValue) {
      setColumnValues(meta.initialValue)
    }
  }, [])

  useEffect(() => {
    if (field.value) {
      setColumnValues(field.value)
    }
  }, [field.value])

  useEffect(() => {
    helpers.setValue(columnValues)
  }, [columnValues])

  return (
    <>
      <CardContent>
        <FieldArray name="columns">
          {() => (
            <FlexBox
              align="center"
              direction="column"
              style={{
                gap: '5px',
                height: '100%'
              }}
            >
              <Sortable
                items={columnValues}
                setItems={setColumnValues}
              >
                {Array.isArray(columnValues) && columnValues.map((item: any, index: number) => (
                  <SortableItem
                    index={index}
                    key={item.code}
                    name={getTranslatedColumn(item.code, item.name)}
                    id={item.code}
                  />
                ))}
              </Sortable>
            </FlexBox>
          )}
        </FieldArray>
      </CardContent>
    </>
  )
}
