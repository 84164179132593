import * as ROUTES from 'constants/Routes'

import { propOr, trim } from 'ramda'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { ALTER_INFO, useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { useConfirm } from 'context/confirm'
import { usePut } from 'hooks/usePut'

import { deleteBrand, deleteBrandByIds, getBrandList, updateStatus, updateStatusBulk } from '../api'
import BrandRowMenu from '../components/BrandRowMenu'
import TableActionsRow from '../components/TableActionsRow'
import { selectedRowSerializer } from '../serializer'
import BrandStatusRow from '../components/BrandStatusRow'
import MoreActionButtons from '../components/MoreRowMenu'
import BrandExcelExport from '../components/BrandExcelExport'
import { useLocales } from '../../../../hooks/useLocales'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 150
  },
  {
    headerName: 'Brand name',
    field: 'name',
  },
  {
    headerName: 'Status',
    field:'status',
    renderCell:(item:any) => <BrandStatusRow status={item.status} />
  }
]

function BrandListContainer () {
  const { t } = useLocales('brand')
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const brandList = useGetList(getBrandList)
  const brandDelete = useDelete(deleteBrand)
  const brandDeleteByIds = useDelete(deleteBrandByIds)
  const brandUpdateStatus = usePut(updateStatus)
  const brandUpdateStatusBulk = usePut(updateStatusBulk)
  const [selectedRows, setSelectedRows] = useState<any>([])

  const handleDeleteBrand = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить бренд ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        brandDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Бренд успешно удалён' }))
          .then(() => brandList.getList())
      })
      .disagree()
  }, [])

  const handleDeleteSelected = useCallback(async () => {
    const message = `Вы уверены что хотите удалить выбранные бренды?`

    onConfirm({ message })
      .agree(() => {
        brandDeleteByIds.deleteData({ data: selectedRowSerializer(selectedRows) })
          .then((res:any) => {
            if (res.length === 0) {
              setSelectedRows([])
              snackbar({ message: `${selectedRows.length} бренды успешно удалён` })
            } else {
              const filteredProducts = selectedRows.filter((item: { id: any }) => res.includes(item.id))
              setSelectedRows(filteredProducts)
              snackbar({ message: `${selectedRows.length - res.length} бренда были успешно удалены, но ${res.length}
               бренды не могут быть удалены, поскольку они используются.`,
              type: ALTER_INFO
              })
            }
          })
          .then(() => brandList.getList())
      })
      .disagree()
  }, [selectedRows, brandList])

  const onUpdateStatus = useCallback((brandId:number) => {
    brandUpdateStatus.putData({ params:{ brandId } })
      .then(() => brandList.getList())
      .then(() => snackbar({ message:'Статус бренда успешно обновлен' }))
  }, [])

  const onUpdateStatusBulk = useCallback((status:string) => {
    brandUpdateStatusBulk.putData({ data: { productBrandIds:selectedRowSerializer(selectedRows), status } })
      .then(() => brandList.getList())
      .then(() => setSelectedRows([]))
      .then(() => snackbar({ message:'Статусы выбранных брендов успешно обновлены' }))
  }, [selectedRows, brandList])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    brandList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title={t('Brand')}
        onAddClick={() => navigate(ROUTES.CATALOG_BRAND_CREATE)}
        dataRequest={brandList}
        columns={columns}
        onSearchClick={onTableSearch}
        translationModule="brand"
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchPlaceholder="Поиск по названию или ID"
        exportNode={(
          <BrandExcelExport
            list={brandList}
          />
        )}
        actions={(row) => (
          <BrandRowMenu
            item={row}
            handleDelete={handleDeleteBrand}
            onUpdateStatus={onUpdateStatus}
          />
        )}
        tableActions={(
          <TableActionsRow
            onUpdateStatusBulk={onUpdateStatusBulk}
            handleDeleteSelected={handleDeleteSelected}
          />
        )}
        moreActions={(actions) => (
          <MoreActionButtons
            onOpen={actions.onExportClick}
          />
        )}
      />
    </AppLayout>
  )
}

export default BrandListContainer
