import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { ALTER_INFO, useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { useConfirm } from 'context/confirm'
import { prop, propOr, trim } from 'ramda'
import { useLocales } from 'hooks/useLocales'

import { deleteCategory, deleteCategoryByIds, getCategoryList } from '../api'
import CategoryRowMenu from '../components/CategoryRowMenu'
import TableActionsRow from '../components/TableActionsRow'
import { selectedRowSerializer } from '../../brand/serializer'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 150
  },
  {
    headerName: 'Name',
    field: 'name',
  },
  {
    headerName: 'Territories',
    field: 'territoryList',
    renderCell:(item:any) => item.territoryList.map((item: any) => prop('name', item)).join(', ')
  },
  {
    headerName: 'Description',
    field: 'notes',
  }
]

function CategoryListContainer () {
  const { t } = useLocales('productCategory')
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const categoryList = useGetList(getCategoryList)
  const categoryDelete = useDelete(deleteCategory)
  const categoryDeleteByIds = useDelete(deleteCategoryByIds)
  const [selectedRows, setSelectedRows] = useState<any>([])

  const handleDeleteProduct = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить из продуктовой корзины ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        categoryDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Продуктовая корзина успешно удалена' }))
          .then(() => categoryList.getList())
      })
      .disagree()
  }, [])

  const handleDeleteSelected = useCallback(async () => {
    const message = `Вы уверены что хотите удалить выбранные продуктовые корзины?`

    onConfirm({ message })
      .agree(() => {
        categoryDeleteByIds.deleteData({ data: selectedRowSerializer(selectedRows) })
          .then((res:any) => {
            if (res.length === 0) {
              setSelectedRows([])
              snackbar({ message: `${selectedRows.length} продуктовые корзины успешно удалён` })
            } else {
              const filteredProducts = selectedRows.filter((item: { id: any }) => res.includes(item.id))
              setSelectedRows(filteredProducts)
              snackbar({ message: `${selectedRows.length - res.length} продуктовые корзины были успешно удалены,
               но ${res.length} продуктовые корзины не могут быть удалены, поскольку они используются.`,
              type: ALTER_INFO
              })
            }
          })
          .then(() => categoryList.getList())
      })
      .disagree()
  }, [selectedRows, categoryList])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    categoryList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title={t('Product category')}
        onAddClick={() => navigate(ROUTES.CATALOG_CATEGORY_CREATE)}
        dataRequest={categoryList}
        columns={columns}
        onSearchClick={onTableSearch}
        translationModule="productCategory"
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchPlaceholder={t('Search by name or ID')}
        actions={(row) => (
          <CategoryRowMenu
            item={row}
            handleDelete={handleDeleteProduct}
          />
        )}
        tableActions={(
          <TableActionsRow
            selectedIds={selectedRows}
            handleDeleteSelected={handleDeleteSelected}
          />
        )}
      />
    </AppLayout>
  )
}

export default CategoryListContainer
