import React from 'react'
import { useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useGetDetail } from 'hooks/useGetDetail'

import { countryDetail } from '../api'
import CountryUpdateForm from '../components/CountryUpdateForm'

function CountryUpdateContainer () {
  const { id } = useParams()
  const detailCountry = useGetDetail(countryDetail, { params: { id } })

  return (
    <AppLayout>
      {!detailCountry.loading && (
        <CountryUpdateForm
          onSubmit={() => null}
          initialValues={detailCountry.result}
          enableReinitialize={true}
        />
      )}
      {detailCountry.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default CountryUpdateContainer
