import React, { useRef, useState } from 'react'
import axios from 'axios'
import { map, pipe, propOr } from 'ramda'
import TextField from '@mui/material/TextField'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import useDebounce from 'hooks/useDebounce'
import useDeepCompareEffect from 'hooks/useDeepCompareEffect'
import { request } from 'api/BaseApi'
import { useField } from 'formik'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'

const { CancelToken } = axios
const getCancelToken = () => CancelToken.source()

const defaultGetOptionValue = (option: any) => {
  if (option) {
    const { id, name } = option
    return { value: id, name }
  }

  return null
}

const getItemFromResults = (results: any, id: number, key: string) => {
  return results.find((item: any) => item[key] === id)
}

const useStyles = makeStyles({
  autocomplete: {
    '& .MuiAutocomplete-input':{
      padding:'5px 10px 5px 12px!important'
    },
  },
  field: {
    '& .MuiInputBase-root': {
      borderRadius:'5px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#1557FF',
      },
      '& .MuiInputBase-input': {
        color: '#202020',
        background: 'white'
      },
      '& ::placeholder': {
        opacity: 1,
        color: '#7c7c7c',
        fontSize: '13px',
        fontWeight: '500',
      }
    }
  },
})

const getTranslatedColumn = (code:string, name:string) => {
  if (code === 'NAME') {
    return 'Наименование товара'
  }
  if (code === 'SHORT_NAME') {
    return 'Короткое наименование'
  }
  if (code === 'SORT_NUMBER') {
    return 'Порядковый номер'
  }
  if (code === 'PIECES') {
    return 'Штук'
  }
  if (code === 'CODE') {
    return 'Штрих-код'
  }
  if (code === 'PRODUCED_IN') {
    return 'Страна'
  }
  if (code === 'DESCRIPTION') {
    return 'Описание'
  }
  if (code === 'SKU') {
    return 'Артикул'
  }
  if (code === 'WEIGHT') {
    return 'Вес нетто'
  }
  if (code === 'WEIGHT_BRUTTO') {
    return 'Вес брутто'
  }
  if (code === 'HEIGHT') {
    return 'Высота'
  }
  if (code === 'PRODUCT_TYPE') {
    return 'Тип продукта'
  }
  if (code === 'SUPPLIERS') {
    return 'Контрагенты'
  }
  if (code === 'CATEGORIES') {
    return 'Продуктовая корзина'
  }
  if (code === 'PACKAGE_LINE_ITEM') {
    return 'Упаковки'
  }
  if (code === 'BRAND') {
    return 'Бренд'
  }
  if (code === 'UNIT_MEASUREMENT') {
    return 'Единица измерения'
  }
  if (code === 'ENABLE_EXPIRY_DATE_TRACKING') {
    return 'Отслеживание срока действия'
  }
  if (code === 'ENABLE_BATCH_NUMBERS') {
    return 'Номера пакетов'
  }
  if (code === 'MODIFIED_DATE') {
    return 'Дата изменения'
  }
  if (code === 'CREATED_DATE') {
    return 'Дата создания'
  }
  if (code === 'CREATED_BY') {
    return 'Сделано'
  }
  if (code === 'MODIFIED_BY') {
    return 'Модифицирован'
  }
  if (code === 'SELL_IN_PACKAGES') {
    return 'Продовать только в упаковке'
  }
  if (code === 'STATUS') {
    return 'Статус'
  }
  return name
}

const defaultGetOptionLabel = (value: any) => {
  if (value) {
    const code = propOr('', 'code', value) as string
    const name = propOr('', 'name', value) as string
    return getTranslatedColumn(code, name)
  }

  return ''
}

const defaultRenderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: any
) => {
  return (
    <Box
      sx={{
        fontSize:'13px',
        lineHeight:'15.73px',
        color:'#202020',
        [`&.${autocompleteClasses.option}`]: {
          borderBottom:'1px solid #f1f1f1',
          padding:'8px 16px',
          '&:last-child': {
            borderBottom: 'none',
          }
        },
        '&:lastChild':{
          borderBottom:'none'
        }
      }}
      component="li"
      {...props}
    >
      {getTranslatedColumn(option.code, option.name)}
    </Box>
  )
}

function FilterAvailableColumnSearchField (props: any) {
  const {
    name,
    api,
    InputProps,
    PaperComponent,
    ListboxProps,
    ListboxComponent,
    renderOption = defaultRenderOption,
    searchFieldProps,
    disableCloseOnSelect,
    primaryKey = 'id',
    params = { limit: 30, start: 0 },
    param,
    disabled = false,
    isLoading = false,
    disableClearable = false,
    fullWidth = true,
    size = 'small',
    searchText = 'searchKey',
    prepareOptions = (options: any) => options,
    onChange = () => null,
    onInputChange = () => null,
    onValueChange = () => null,
    getOptionLabel = defaultGetOptionLabel,
    getOptionValue = defaultGetOptionValue,
    ...defaultProps
  } = props
  const classes = useStyles()
  const [input, meta, field] = useField(name)
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([] as any)
  const [searching, setSearching] = useState(false)
  const ref = useRef([]) as any

  useDeepCompareEffect(() => {
    if ((meta.initialValue && isFinite(meta.initialValue))) {
      setLoading(true)
      request(api, { query: { limit: 1000, ...params }, params:param }).then((response) => {
        const content = propOr([], 'content', response) as any
        const list = propOr([], 'list', response) as any
        const responseList = Array.isArray(response) ? response : []
        const results = [...content, ...list, ...responseList]
        const foundItem = results.find((item: any) => item[primaryKey] === meta.initialValue)
        onValueChange(foundItem)
        field.setValue(getOptionValue(foundItem))
        setLoading(false)
      })
    }
  }, [])

  useDeepCompareEffect(() => {
    let active = true

    const { token, cancel } = getCancelToken()

    if (open) {
      setLoading(true)
      onInputChange(debouncedValue)
      const searchValue = searching ? debouncedValue : null
      const query = searchValue ? { [searchText]: searchValue, ...params } : params
      request(api, { query, params:param, cancelToken: token, method: 'get' })
        .then(response => {
          const content = propOr([], 'content', response) as any
          const list = propOr([], 'list', response) as any
          const responseList = Array.isArray(response) ? response : []
          const results = [...content, ...list, ...responseList]
          if (active) {
            const newOptions = pipe(
              prepareOptions,
              map(getOptionValue)
            )(results)
            ref.current = newOptions

            setOptions(newOptions)
            setLoading(false)
          }
        })
    }

    return () => {
      active = false
      cancel()
    }
  }, [open, debouncedValue, searching])

  React.useEffect(() => {
    if (!open) {
      ref.current = []
      setOptions([])
    }
  }, [open])

  const submitError = propOr(null, 'submitError', meta)
  const stringOfSubmitError = submitError ? JSON.stringify(submitError) : ''

  return (
    <Autocomplete
      className={classes.autocomplete}
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      value={input.value}
      isOptionEqualToValue={(option: any, value:any) => option[primaryKey] === value[primaryKey]}
      getOptionLabel={getOptionLabel}
      options={options.length === 0 && !open ? [input.value] : options}
      loading={loading}
      filterOptions={item => item}
      onChange={(event, value:any) => {
        if (value) {
          onChange(event, getItemFromResults(ref.current, value[primaryKey], primaryKey))
        } else {
          onChange(event, null)
        }
        onValueChange(value)
        field.setValue(value)
        setSearching(false)
      }}
      PaperComponent={PaperComponent}
      ListboxComponent={ListboxComponent}
      size={size}
      fullWidth={fullWidth}
      ListboxProps={ListboxProps}
      renderOption={renderOption}
      onInputChange={(event, value) => {
        setValue(value)
        setOptions([])
        onInputChange(value)
      }}
      renderInput={params => {
        return (
          <TextField
            className={classes.field}
            error={meta.error}
            helperText={stringOfSubmitError || meta.error}
            {...params}
            {...defaultProps}
            onBlur={() => setSearching(false)}
            onKeyDown={() => setSearching(true)}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: (
                <>
                  {(isLoading || loading) ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )
      }}
      {...searchFieldProps}
    />
  )
}

export default FilterAvailableColumnSearchField
