export const getTranslatedColumn = (code: string, name:string): string => {
  const columnTranslations: { [key: string]: string } = {
    'NAME': 'Название посещение',
    'TYPE': 'Тип посещения',
    'STATUS': 'Статус',
    'PLANNED_DATE': 'Планируемая дата',
    'ACTUAL_DATE': 'Дата посещения',
    'USER': 'Пользователь',
    'DURATION': 'Продолжительность',
    'CUSTOMER': 'Клиент',
    'NOTE': 'Заметка',
    'DELETED': 'Удалено',
    'PREDECESSOR': 'Предшественник',
    'SUCCESSOR': 'Преемник',
    'TIMES': 'Время посещения',
    'TERRITORIES': 'Территория'
  }
  return columnTranslations[code] || name
}
