import { omit, path, pathOr, prop } from 'ramda'
import { toPayloadDate } from 'utils/dates'

export const rentCreateSerializer = (values:any) => {
  const fromWarehouseId = path(['fromWarehouseId', 'id'], values)
  const toWarehouseId = path(['toWarehouseId', 'id'], values)
  const fromDate = toPayloadDate(prop('fromDate', values))
  const toDate = toPayloadDate(prop('toDate', values))
  const territoryId = path(['territoryId', 'id'], values)
  const renterId = path(['renterId', 'id'], values)
  const lineItems = prop('items', values)
  const items = lineItems && lineItems.map((item:any) => {
    const omitValue = omit(['product'], item)
    return { ...omitValue }
  })

  return { ...values, fromWarehouseId, toWarehouseId, fromDate, toDate, territoryId, renterId, items }
}

export const rentDetailSerializer = (values:any) => {
  const fromWarehouseId = prop('fromWarehouse', values)
  const toWarehouseId = prop('toWarehouse', values)
  const territoryId = prop('territory', values)
  const renterId = prop('renter', values)
  const resultValues = omit(['renter', 'territory'], values)
  return { ...resultValues, territoryId, renterId, fromWarehouseId, toWarehouseId }
}

export const rentUpdateSerializer = (values:any) => {
  const fromWarehouseId = path(['fromWarehouseId', 'id'], values)
  const toWarehouseId = path(['toWarehouseId', 'id'], values)
  const fromDate = toPayloadDate(prop('fromDate', values))
  const toDate = toPayloadDate(prop('toDate', values))
  const territoryId = path(['territoryId', 'id'], values)
  const renterId = path(['renterId', 'id'], values)
  const lineItems = prop('items', values)
  const items = lineItems && lineItems.map((item:any) => {
    const productId = pathOr('productId', ['product', 'id'], item)
    const omitValue = omit(['product'], item)
    return { ...omitValue, productId }
  })
  const result = omit(['fromWarehouse', 'toWarehouse'], values)
  return { ...result, fromWarehouseId, toWarehouseId, fromDate, toDate, territoryId, renterId, items }
}
