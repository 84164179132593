import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest } from 'api/BaseApi'

export const getBulkPaymentList = (options?: Options) => {
  return getRequest<any>(API.PaymentBulkList, options)
}

export const getBulkPaymentDetail = (options?: Options) => {
  return getRequest<any>(API.PaymentBulkGetById, options)
}

export const createBulkPayment = (options?: Options) => {
  return postRequest<any>(API.PaymentBulkCreate, options)
}

export const deleteBulkPayment = (options?: Options) => {
  return deleteRequest<any>(API.PaymentBulkDelete, options)
}

export const getShipmentPaymentList = (options?: Options) => {
  return getRequest<any>(API.PaymentSuggestionsList, options)
}
