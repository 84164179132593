import React from 'react'
import { useField } from 'formik'
import { Radio, FormControlLabel } from '@mui/material'

interface CustomRadioButtonProps {
  readonly name: string
  readonly label: string
  readonly value: string
  readonly index: number
  readonly disabled: boolean
}

const CustomFieldRadioButton: React.FC<CustomRadioButtonProps> = ({
  index,
  name,
  label,
  value,
  disabled
}) => {
  const [field, , helpers] = useField(name)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    helpers.setValue(isChecked ? { id: index, value } : '')
  }

  return (
    <FormControlLabel
      control={
        <Radio
          disabled={disabled}
          checked={field.value?.value === value}
          onChange={handleChange}
          color="primary"
        />
      }
      label={label}
    />
  )
}

export default CustomFieldRadioButton
