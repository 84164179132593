import * as API from 'constants/Api'

import React from 'react'
import { path, prop } from 'ramda'
import SearchField from 'components/form/SearchField'
import { useField } from 'formik'

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('profileId', value)
    const name = prop('fullName', value)
    return { id, name }
  }

  return null
}

function UserField () {
  const [ field, , ] = useField('territoryId')
  const territoryId = path(['value', 'id'], field)
  return (
    <SearchField
      disabled={!territoryId}
      params={{ territoryId }}
      name="userId"
      primaryKey="profileId"
      api={API.EmployeeList}
      getOptionValue={getOptionValue}
      label="Пользователь"
    />
  )
}

export default UserField
