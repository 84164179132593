import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { timeSlotCreate } from '../api'
import TimeSlotCreateForm from '../components/TimeSlotCreateForm'
import { timeSlotCreateSerializer } from '../serializer'

function TimeSlotCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const createTimeSlot = usePost(timeSlotCreate)

  const handleSubmit = useCallback((values: any) => {
    return createTimeSlot.postData({ data: timeSlotCreateSerializer(values) })
      .then(() => snackbar({ message: 'Рабочее время пользователя успешно создано' }))
      .then(() => navigate(ROUTES.SETTINGS_TIMESLOT_LIST))
  }, [])

  return (
    <AppLayout>
      <TimeSlotCreateForm
        initialValues={{}}
        onSubmit={handleSubmit}
      />
    </AppLayout>
  )
}

export default TimeSlotCreateContainer
