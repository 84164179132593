import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { name: 'Проверка', value: 'CHECKING_OF_DUE_TASK' },
  { name: 'Подготовка заказа', value: 'CUSTOM' },
  { name: 'Доставка', value: 'DELIVERY_TASK' },
  { name: 'Маркетинг', value: 'MARKETING_TASK' },
  { name: 'Оплата', value: 'PAYMENT_COLLECTION' },
  { name: 'Оплата с полок', value: 'CHECKING_OF_DUE_SHELVES' },
  { name: 'Посещение продаж', value: 'SALES_TASK' },
]

function VisitTypeField (props: any) {
  return (
    <SelectField
      name="type"
      label="Тип посещения"
      options={OPTIONS}
      {...props}
    />
  )
}

export default VisitTypeField
