import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'
import { GetListResponse } from 'api/BaseDto'

export const getPriceLevelList = (options?: Options) => {
  return getRequest<GetListResponse<any>>(API.PriceLevelList, options)
}

export const getPriceLevelListByProductId = (options?: Options) => {
  return getRequest<GetListResponse<any>>(API.PriceLevelListByProductId, options)
}

export const createPriceLevel = (options?: Options) => {
  return postRequest<GetListResponse<any>>(API.PriceLevelCreate, options)
}

export const deletePriceLevel = (options?: Options) => {
  return deleteRequest<any>(API.PriceLevelDelete, options)
}

export const updatePriceLevel = (options?: Options) => {
  return putRequest<any>(API.PriceLevelUpdate, options)
}

export const getByIdPriceLevel = (options?: Options) => {
  return getRequest<any>(API.PriceLevelGetById, options)
}

export const getLineItemsByPriceId = (options?: Options) => {
  return getRequest<any>(API.PriceLevelLineItemsList, options)
}

export const patchLineItems = (options?: Options) => {
  return patchRequest<any>(API.PriceLevelLineItemsEdit, options)
}

export const importPriceLevel = (options?: Options) => {
  return postRequest<GetListResponse<any>>(API.PriceLevelImportExcel, options)
}

export const exportPriceLevelExcel = (options?: Options) => {
  return getRequest<GetListResponse<any>>(API.PriceLevelExportProducts, options)
}
