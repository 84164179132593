import React, { useCallback } from 'react'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'
import { useGetList } from 'hooks/useGetList'

import ColumnCreateForm from './components/SortingCreateForm'
import { createSorting } from './api'
import { sortingCreateSerializer } from './serializer'
import { sortingInitialValues, sortingValidationSchema } from './validation-form'

import { getAvailableColumns } from '../../api'

interface Props {
  readonly onBack:() => void
  readonly list:any
}

const SortingCreate = ({ onBack, list }:Props) => {
  const snackbar = useSnackbar()
  const sortingCreate = usePost(createSorting)
  const criteria = useGetList(getAvailableColumns, { params: { type: 'SALES_ORDER_LIST_PANEL' } })

  const onCreate = useCallback((values:any) => {
    sortingCreate.postData({ data:sortingCreateSerializer(values) })
      .then(() => onBack())
      .then(() => list.getList())
      .then(() => snackbar({ message: 'Сортировка успешно создана' }))
  }, [])

  return (
    <>
      <ColumnCreateForm
        onBack={onBack}
        sortList={criteria}
        onSubmit={onCreate}
        initialValues={sortingInitialValues}
        validationSchema={sortingValidationSchema}
      />
    </>
  )
}

export default SortingCreate
