import * as Yup from 'yup'

export const preOrderInitialValues = {
  territoryId:null,
  warehouseGroupId:null,
  priceLevelId:null,
  items:[],
  attachments:[],
  currency:null,
  agentId:'',
  driverId:null,
  address:{
    countryId:'',
    regionId:'',
    addressLine1:'',
    addressLine2:'',
    landmark:'',
    lat:'',
    lon:''
  }
}

export const validationSchema = Yup.object({
  territoryId:Yup.object({ id:Yup.number(), name:Yup.string() }).required('Требуются территории'),
  warehouseGroupId:Yup.object().required('Требуются складская группа'),
  driverId:Yup.object().required('Требуется водитель'),
  priceLevelId:Yup.object().required('Требуются ценовая политика'),
  contactId:Yup.object().required('Требуются клиент'),
  agentId:Yup.object().required('Требуются агент'),
  items:Yup.array().min(1, 'Требуются продукт').required('Требуются продукт')
})
