import * as ROUTES from 'constants/Routes'

import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import MUIDivider from '@mui/material/Divider'
import Tabs from 'components/main/Tabs'

export enum PRODUCT_BULK_EDIT_TABS {
  GENERAL = 'general',
  ATTACH = 'attach'
}

const tabs = [
  { value: PRODUCT_BULK_EDIT_TABS.GENERAL, label: 'Редактировать и заменить' },
  { value: PRODUCT_BULK_EDIT_TABS.ATTACH, label: 'Добавьте к тому, что имеется' },
]

interface Props {
  readonly value: string
  readonly productItems:any
}

function ProductBulkEditTabs ({ value, productItems }: Props) {
  const navigate = useNavigate()
  const onChange = (event: any, tab: any) => {
    const redirect = generatePath(ROUTES.CATALOG_PRODUCT_BULK_UPDATE, { tab })
    navigate(redirect, { replace: true, state: { productItems } })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />

      <MUIDivider sx={{ mb: 3 }} />
    </>
  )
}

export default ProductBulkEditTabs
