import React, { useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import { BaseTextFieldProps } from '@mui/material'
import { useField } from 'formik'
import TextField from '@mui/material/TextField'

interface CustomTextFieldProps extends BaseTextFieldProps{
  readonly name: string
  readonly label?: string
  readonly placeholder: string
  readonly fullWidth?: boolean
  readonly InputProps?: any
}

const PasswordTextField: React.FC<CustomTextFieldProps> = ({
  name,
  label,
  placeholder,
  fullWidth = true,
  ...props
}) => {
  const [ field, meta, ] = useField(name || '')
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <TextField
      label={label}
      type={showPassword ? 'text' : 'password'}
      placeholder={placeholder}
      fullWidth={fullWidth}
      {...field}
      {...props}
      variant="outlined"
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      // @ts-ignore
      onWheel={(e) => e.target.blur()}
      sx={{ width: '100%' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

export default PasswordTextField
