import React from 'react'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { path } from 'ramda'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'
import { useParams } from 'react-router-dom'
import FlexBox from 'components/main/FlexBox'

import VisitTypeField from './form/VIsitTypeField'
import VisitStatusField from './form/VisitStatusField'
import CustomerField from './form/CustomerField'
import UserField from './form/UserField'
import AttachmentField from './form/uploads/AttachmentField'
import PredecessorField from './form/PredecessorField'
import CountryField from './form/CountryField'
import RegionField from './form/RegionField'
import TerritoryField from './form/TerritoryField'
import { AvailableCustomField } from './custom-fields/AvailableCustomField'
import VisitTabs, { VISIT_TABS } from './VisitTabs'
import SOTableByVisit from './SOTableByVisit'
import ContactAddressSelectField from './form/ContactAddressSelectField'
import VideoUploadField from './form/uploads/VideoUploadField'
import PhotoUploadField from './form/uploads/PhotoUploadField'
import CommentField from './form/CommentField'

function VisitUpdateForm ({ form, customFields, soList, onComplete }:any) {
  const { tab } = useParams()
  const name = path(['values', 'name'], form)
  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить посещение (${name})`}
          rightButton={(
            <FlexBox align="center" gap={5}>
              <Button
                type="button"
                disabled={tab === VISIT_TABS.SOTable}
                onClick={() => onComplete(form.values)}
              >
                Завершить задачу
              </Button>
              <Button
                type="submit"
                disabled={tab === VISIT_TABS.SOTable}
              >
                Сохранить изменения
              </Button>
            </FlexBox>
          )}
        />
      </Header>
      <Content>
        <VisitTabs value={tab as string} />
        {tab === VISIT_TABS.DETAIL && (
          <Grid container={true} spacing={3}>
            <Grid item={true} lg={12}>
              <Card>
                <CardHeader title="Детали" />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Название"
                        name="name"
                        data-cy="name"
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <VisitTypeField />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <VisitStatusField />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <CustomerField />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <ContactAddressSelectField />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <UserField />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <TerritoryField />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <DateField
                        label="Планируемая дата"
                        name="plannedDate"
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <DateField
                        label="Дата посещения"
                        name="actualDate"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <PredecessorField />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Фактические координаты"
                        name="actualCoordinates"
                        type="number"
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Продолжительность"
                        name="durationStr"
                        type="number"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <CommentField
                        label="Комментарии"
                        name="comments"
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Заметка"
                        name="note"
                        data-cy="note"
                        multiline={true}
                        minRows={4}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
              </Card>
              <Card>
                <CardHeader title="Адрес" />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} lg={4}>
                      <TextField label="Адресс" name="address.addressLine1" />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <TextField label="Адрес дополнительный" name="address.addressLine2" />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <TextField label="Ориентир" name="address.landmark" />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <CountryField label="Название страны" name="address.countryId" />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <RegionField label="Город" name="address.regionId" />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Координаты - лат/лон"
                        name="address.location"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
              </Card>
            </Grid>
            <Grid item={true} lg={12}>
              <PhotoUploadField name="photoActions" />
            </Grid>
            <Grid item={true} lg={12}>
              <VideoUploadField name="videoActions" />
            </Grid>
            <Grid item={true} lg={12}>
              <AttachmentField name="attachments" />
            </Grid>
            <Grid item={true} lg={12}>
              <AvailableCustomField customFields={customFields} />
            </Grid>
          </Grid>
        )}
        {tab === VISIT_TABS.SOTable && (
          <SOTableByVisit stockHistory={soList} />
        )}
      </Content>
    </Container>
  )
}

export default withForm(VisitUpdateForm)
