import * as ROUTES from '../constants/Routes'

export interface SubMenuProps {
  readonly title: string
  readonly path: string
  readonly pro?: boolean
}

export interface MenuProps {
  readonly title?: string
  readonly key: string
  readonly subMenu: SubMenuProps[]
}

export interface AppMenuItemProps {
  readonly title: string
  readonly path: string
  readonly key: string
  readonly subMenu?: SubMenuProps[]
  readonly menu?:MenuProps[]
}

export const AppMenu: AppMenuItemProps[] = [
  {
    key: 'dashboard',
    title: 'Дашборд',
    path: ROUTES.DASHBOARD,
    subMenu: [
      {
        title: 'Дашборд по продажам',
        path: ROUTES.DASHBOARD_SALES
      },
      {
        title: 'Дашборд по финансам',
        path: ROUTES.DASHBOARD_FINANCE
      },
      {
        title: 'Дашборд...',
        path: ROUTES.DASHBOARD_DASHBOARD
      },
      {
        title: 'Дашборд (динамичный)',
        path: ROUTES.DASHBOARD_DYNAMIC
      }
    ]
  },
  {
    key: 'catalog',
    title: 'Справочники',
    path: ROUTES.CATALOG,
    menu: [
      {
        key: 'productColumn1',
        title: 'Продукты',
        subMenu: [
          {
            title: 'Продукты',
            path: ROUTES.CATALOG_PRODUCT_LIST
          },
          {
            title: 'Продуктовые корзины',
            path: ROUTES.CATALOG_CATEGORY_LIST
          },
          {
            title: 'Единицы измерения',
            path: ROUTES.CATALOG_MML
          },
          {
            title: 'Упаковки',
            path: ROUTES.CATALOG_PACKAGE_LIST
          },
          {
            title: 'Бренды',
            path: ROUTES.CATALOG_BRAND_LIST
          },
          {
            title: 'Страны происхождения',
            path: ROUTES.CATALOG_COUNTRY_LIST
          }
        ]
      },
      {
        key: 'productColumn2',
        subMenu: [
          {
            title: 'Валюты',
            path: ROUTES.SETTINGS_CURRENCY_LIST
          },
          {
            title: 'Цены',
            path: ROUTES.SETTINGS_PRICE_LEVEL_LIST
          },
          {
            title: 'Скидки / Наценки',
            path: ROUTES.CATALOG_DISCOUNT
          },
        ]
      },
      {
        key: 'contact',
        title: 'Контрагенты',
        subMenu: [
          {
            title: 'Контрагенты',
            path: ROUTES.FINANCE_AGENT_LIST
          },
          {
            title: 'Категория контрагентов',
            path: ROUTES.FINANCE_AGENT_CATEGORY_LIST
          },
          {
            title: 'Адреса контрагентов (На карте)',
            path: ROUTES.CATALOG_AGENTS_ADDRESSES_MAP
          },
          {
            title: 'Соглашения с контрагентами',
            path: ROUTES.CATALOG_BRAND_LIST
          },
          {
            title: 'Территории',
            path: ROUTES.SETTINGS_TERRITORY_LIST
          },
        ]
      }
    ]
  },
  {
    key: 'warehouse',
    title: 'Склад',
    path: ROUTES.WAREHOUSE,
    subMenu: [
      {
        title: 'Склады',
        path: ROUTES.WAREHOUSE_LIST
      },
      {
        title: 'Группы складов',
        path: ROUTES.WAREHOUSE_GROUP_LIST
      },
      {
        title: 'Приход на склад',
        path: ROUTES.WAREHOUSE_RECEIVE_LIST
      },
      {
        title: 'Возврат поставщику',
        path: ROUTES.WAREHOUSE_RETURN_SUPPLIER
      },
      {
        title: 'Перемещение между складами',
        path: ROUTES.WAREHOUSE_MOVEMENT_LIST
      },
      {
        title: 'Корректировка склада',
        path: ROUTES.WAREHOUSE_ADJUSTMENT_LIST
      },
      {
        title: 'Закупки',
        path: ROUTES.FINANCE_PURCHASE_LIST
      },
    ]
  },
  {
    key: 'visit',
    title: 'Визиты',
    path: ROUTES.VISIT,
    subMenu: [
      {
        title: 'Визиты / Задачи',
        path: ROUTES.CATALOG_VISIT_LIST
      },
      {
        title: 'Планинг визитов',
        path: ROUTES.CRM_RETURN_TASK_LIST
      },
      {
        title: 'Типы вложений к визитам',
        path: ROUTES.VISIT_VISIT_TYPE_LIST
      },
      {
        title: 'Комментарии к визитам',
        path: ROUTES.VISIT_VISIT_COMMENTS_LIST
      }
    ]
  },
  {
    key: 'orders',
    title: 'Заказы',
    path: ROUTES.SALE_ORDER,
    subMenu:[
      {
        title:'Пред-заказы',
        path: ROUTES.SALE_ORDER_PRE_ORDER_LIST
      },
      {
        title:'Заказы',
        path: ROUTES.SALE_ORDER_LIST
      },
      {
        title: 'Сбор заказов',
        path: ROUTES.ORDER_PICKER_LIST
      },
      {
        title: 'Доставка заказов',
        path: ROUTES.SALE_ORDER_SHIPMENT_LIST
      },
      {
        title: 'Возвраты',
        path: ROUTES.SALE_ORDER_RETURN_LIST
      }
    ]
  },
  {
    key: 'finance',
    title: 'Финансы',
    path: ROUTES.FINANCE,
    subMenu: [
      {
        title: 'Кассы',
        path: ROUTES.FINANCE_CHART_OF_ACCOUNT_LISTING
      },
      {
        title: 'Оплаты от контрагентов',
        path: ROUTES.FINANCE_SUPPLIER_PAYMENTS_LIST
      },
      {
        title: 'Оплаты контрагентам',
        path: ROUTES.ORDER_PAYMENT_LIST
      },
      {
        title: 'Перевод между кассами',
        path: ROUTES.FINANCE_MOVING_BETWEEN_CASH
      },
      {
        title: 'Перекидка баланса контрагентов',
        path: ROUTES.FINANCE_MANUAL_ENTRY_LIST
      },
      {
        title: 'Массовый платеж',
        path: ROUTES.ORDER_BULK_PAYMENT_LIST
      },
      {
        title: 'Все транзакции',
        path: ROUTES.FINANCE_TRANSACTION_LIST
      },
      {
        title: 'Инвойсы',
        path: ROUTES.FINANCE_INVOICE_LIST
      },
      {
        title: 'Консигнации',
        path: ROUTES.FINANCE_CONSIGNMENT_LIST
      },
      {
        title: 'Налоги',
        path: ROUTES.FINANCE_TAX_LIST
      },
    ]
  },
  {
    key: 'hr',
    title: 'HR',
    path: ROUTES.HR,
    subMenu: [
      {
        title: 'Роли',
        path: ROUTES.HR_ROLES
      },
      {
        title: 'Права',
        path: ROUTES.HR_RIGHTS
      },
      {
        title: 'Сотрудники',
        path: ROUTES.HR_EMPLOYEE_LIST
      },
      {
        title: 'Трекинг (отслеживание)',
        path: ROUTES.HR_TRACKING_LIST
      },
      {
        title: 'KPI',
        path: ROUTES.HR_KPI
      },
      {
        title: 'Зарплата',
        path: ROUTES.HR_SALARY
      }
    ]
  },
  {
    key: 'marketing',
    title: 'Маркетинг',
    path: ROUTES.MARKETING,
    subMenu: [
      {
        title: 'Акции',
        path: ROUTES.MARKETING_BONUS_LIST
      },
      {
        title: 'Активы',
        path: ROUTES.MARKETING_ASSET_LIST
      },
      {
        title: 'Торговое оборудование',
        path: ROUTES.MARKETING_RENT_LIST
      },

      {
        title: 'Отзывы / Запросы контрагентов',
        path: ROUTES.MARKETING_REVIEW_LIST
      },
    ]
  },
  {
    key: 'settings',
    title: 'Настройки',
    path: ROUTES.SETTINGS,
    subMenu: [
      {
        title: 'Данные компании',
        path: ROUTES.SETTINGS_COMPANY_DETAILS
      },
      {
        title: 'Настройки системы',
        path: ROUTES.SETTINGS_SYSTEM_SETTINGS
      },
      {
        title: 'Настройки мобильной части',
        path: ROUTES.SETTINGS_MOBILE_SETTINGS
      },
      {
        title: 'Настраиваемые поля',
        path: ROUTES.SETTINGS_CUSTOM_FIELD_LIST
      },
      {
        title: 'Удтверждения действий',
        path: ROUTES.SETTINGS_APPROVAL_LIST
      },
      {
        title: 'Лицензии',
        path: ROUTES.SETTINGS_LICENSE
      },
      {
        title: 'Медиа файлы',
        path: ROUTES.SETTINGS_MEDIA_FILES
      },
      {
        title: 'Интервал работы пользователя',
        path: ROUTES.SETTINGS_TIMESLOT_LIST
      }
    ]
  },
  {
    key: 'reports',
    title: 'Отчеты',
    path: ROUTES.CRM,
    subMenu: [
      {
        title: 'Все отчеты',
        path: ROUTES.REPORTS_ALL
      },
      {
        title: 'Остатки на складах',
        path: ROUTES.REPORTS_WAREHOUSE_REMAINS
      },
      {
        title: 'Акт сверка контрагента',
        path: ROUTES.REPORTS_CONTACT_REPORT
      },
      {
        title: 'Акт сверка кассы',
        path: ROUTES.REPORTS_CASH
      },
      {
        title: 'Акт сверка продукта',
        path: ROUTES.REPORTS_PRODUCT
      },
      {
        title: 'Шаблоны отчетов',
        path: ROUTES.REPORTS_TEMPLATES
      },
      {
        title: 'Конструктор отчетов',
        path: ROUTES.REPORTS_CONSTRUCTOR
      },
      {
        title: 'Канструктор печатных форм',
        path: ROUTES.REPORTS_CONSTRUCTOR_PRINT_FORMS
      },
      {
        title: 'Аудит действий',
        path: ROUTES.REPORTS_AUDIT
      },
    ]
  }
]
