import React, { useEffect, useState } from 'react'
import { CardContent } from '@mui/material'
import CardHeader from 'components/card/CardHeader'
import { useField } from 'formik'
import FlexBox from 'components/main/FlexBox'
import { Sortable } from 'components/sortable/Sortable'
import { SortableItem } from 'components/sortable/SortableItem'

import { getTranslatedColumn } from '../../../../translations'

export const ColumnsField = ({ columns }:any) => {
  const columnsField = useField('columns')
  const [items, setItems] = useState(columns || [])

  useEffect(() => {
    setItems(columns)
  }, [])

  useEffect(() => {
    columnsField[2].setValue(items)
  }, [items])

  return (
    <>
      <CardHeader title="Колонки" />
      <CardContent>
        <FlexBox
          align="center"
          direction="column"
          style={{
            gap:'5px',
            height:'100%'
          }}
        >
          <Sortable
            items={items}
            setItems={setItems}
          >
            {items.map((item: any, index: number) => {
              return (
                <SortableItem
                  index={index}
                  key={item.code}
                  name={getTranslatedColumn(item.code, item.name)}
                  id={item.code}
                />
              )
            })}
          </Sortable>
        </FlexBox>
      </CardContent>
    </>
  )
}
