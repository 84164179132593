import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import TextField from 'components/form/TextField'
import { path } from 'ramda'

import MultipleTerritoryField from './form/MultipleTerritoryField'
import StatusField from './form/StatusField'
import CountryField from './form/CountryField'
import RegionField from './form/RegionField'
import WarehouseTypeField from './form/WarehouseTypeField'
import EmployeeField from './form/EmployeeField'
import DriverField from './form/DriverField'

function WarehouseUpdateForm ({ form }:any) {
  const name = path(['values', 'warehouseName'], form)

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить склад (${name})`}
          rightButton={(
            <>
              <Button type="submit">Сохранить изменения</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <TextField label="Название склада" name="warehouseName" />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={4} lg={4}>
                    <WarehouseTypeField />
                  </Grid>
                  <Grid item lg={4}>
                    <EmployeeField />
                  </Grid>
                  <Grid item lg={4}>
                    <DriverField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={4} lg={4}>
                    <MultipleTerritoryField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={4} lg={4}>
                    <StatusField required={true} />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Ориентир" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={12}>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} lg={4}>
                        <CountryField name={`address.countryId`} />
                      </Grid>
                      <Grid item={true} lg={4}>
                        <RegionField name={`address.regionId`} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                          label="Адрес"
                          name={`address.name`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                          label="Ориентир"
                          name={`address.landmark`}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(WarehouseUpdateForm)
