import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'CASH', name: 'НАЛИЧНЫЕ' },
  { value: 'CARD', name: 'КАРТА' },
  { value: 'BANK_TRANSFER', name: 'БАНКОВСКИЙ ПЕРЕВОД' }
]

function PaymentMethodField (props:any) {
  return (
    <SelectField
      name="paymentMethod"
      label="Способ оплаты"
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default PaymentMethodField
