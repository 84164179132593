import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'
import { useUser } from 'context/user'
import * as Yup from 'yup'

import { territoryCreate } from '../api'
import TerritoryCreateForm from '../components/TerritoryCreateForm'
import { territorySerialize } from '../serializer'

export const validationSchema = Yup.object({
  clients:Yup.array().min(1, 'Требуется пользователь.').required('Обязательный'),
})

function TerritoryCreateContainer () {
  const { user } = useUser()
  const createTerritory = usePost(territoryCreate)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = useCallback((values: any) => {
    return createTerritory.postData({ data: territorySerialize(values, user) })
      .then(() => snackbar({ message: 'Территория успешно создана.' }))
      .then(() => navigate(ROUTES.SETTINGS_TERRITORY_LIST))
  }, [])

  return (
    <AppLayout>
      <TerritoryCreateForm
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{ clients:[] }}
      />
    </AppLayout>
  )
}

export default TerritoryCreateContainer
