import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'

import { deleteBulkPayment, getBulkPaymentDetail } from '../api'
import BulkPaymentUpdateForm from '../components/BulkPaymentUpdateForm'
import { useGetDetail } from '../../../../hooks/useGetDetail'
import { useDelete } from '../../../../hooks/useDelete'
import { bulkPaymentDetailSerializer } from '../serializer'

function BulkPaymentCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()
  const bulkPaymentGetDetail = useGetDetail(getBulkPaymentDetail, { params:{ id } })
  const bulkPaymentDelete = useDelete(deleteBulkPayment)

  const handleSubmit = useCallback(() => {
    return bulkPaymentDelete.deleteData({ params: { id } })
      .then(() => snackbar({ message: 'Массовый платеж успешно удалён' }))
      .then(() => navigate(ROUTES.ORDER_BULK_PAYMENT_LIST))
  }, [])

  const initialValues = useMemo(() => bulkPaymentDetailSerializer(bulkPaymentGetDetail.result), [bulkPaymentGetDetail])

  return (
    <AppLayout>
      {!bulkPaymentGetDetail.loading && (
        <BulkPaymentUpdateForm
          onSubmit={handleSubmit}
          onDelete={handleSubmit}
          initialValues={initialValues}
        />
      )}
    </AppLayout>
  )
}

export default BulkPaymentCreateContainer
