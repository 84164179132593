import React from 'react'
export const CopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#1557FF"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8 8V6a4 4 0 014-4h6a4 4 0 014 4v6a4 4 0 01-4 4h-2M8 8H6a4 4 0 00-4 4v6a4 4 0 004 4h6a4 4 0
         004-4v-2M8 8h4a4 4 0 014 4v4"
      />
    </svg>
  )
}
