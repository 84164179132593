import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'
import { useGetList } from 'hooks/useGetList'

import { validationSchema } from './AgentCreateContainer'

import { getByIdContact, getCustomFields, updateContact } from '../api'
import AgentUpdateForm from '../components/AgentUpdateForm'
import { agentDetailSerializer, agentUpdateSerializer } from '../serializer'

function AgentUpdateContainer () {
  const snackbar = useSnackbar()
  const { id } = useParams()
  const navigate = useNavigate()
  const agentDetail = useGetDetail(getByIdContact, { params: { id } })
  const agentUpdate = usePut(updateContact)
  const customFieldsGet = useGetList(getCustomFields)
  const [customFields, setCustomField] = useState([])

  const handleSubmit = useCallback((values: any) => {
    return agentUpdate.putData({ data: agentUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Контрагент успешно обновлён' }))
      .then(() => navigate(ROUTES.FINANCE_AGENT_LIST))
  }, [])

  useEffect(() => {
    customFieldsGet.getList({ params: { formType: 'CONTACT_FORM' } }).then((res: any) => {
      setCustomField(res)
    })
  }, [])

  const initialValues = useMemo(() => agentDetailSerializer(agentDetail.result), [agentDetail])

  return (
    <AppLayout>
      {!agentDetail.loading && (
        <AgentUpdateForm
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          customFields={customFields}
          initialValues={initialValues}
          enableReinitialize={true}
        />
      )}
      {agentDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default AgentUpdateContainer
