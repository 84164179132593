import * as ROUTES from 'constants/Routes'

import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import MUIDivider from '@mui/material/Divider'
import Tabs from 'components/main/Tabs'

export enum PRODUCT_TABS {
  GENERAL = 'general',
  STOCK = 'stock',
  INVENTORY = 'inventory'
}

const tabs = [
  { value: PRODUCT_TABS.GENERAL, label: 'Детали Продукта' },
  { value: PRODUCT_TABS.STOCK, label: 'История Продукта' },
  { value: PRODUCT_TABS.INVENTORY, label:'Таблица информации о запасах' }
]

interface Props {
  readonly value: string
}

function ProductTabs ({ value }: Props) {
  const { id } = useParams()

  const navigate = useNavigate()

  const onChange = (event: any, tab: any) => {
    const redirect = generatePath(ROUTES.CATALOG_PRODUCT_EDIT, { id, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />

      <MUIDivider sx={{ mb: 3 }} />
    </>
  )
}

export default ProductTabs
