import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext, horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { Checkbox, Paper, Table as MuiTable, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { CSS } from '@dnd-kit/utilities'

import { columnsSettingStyles } from '../styles/ColumsSettingStyles'

const minCellWidth = 100

const translations: { [key: string]: string } = {
  'NAME': 'Наименование товара',
  'SHORT_NAME': 'Короткое наименование',
  'SORT_NUMBER': 'Порядковый номер',
  'PIECES': 'Штук',
  'CODE': 'Штрих-код',
  'PRODUCED_IN': 'Страна',
  'DESCRIPTION': 'Описание',
  'SKU': 'Артикул',
  'WEIGHT': 'Вес нетто',
  'WEIGHT_BRUTTO': 'Вес брутто',
  'HEIGHT': 'Высота',
  'PRODUCT_TYPE': 'Тип продукта',
  'SUPPLIERS': 'Контрагенты',
  'CATEGORIES': 'Продуктовая корзина',
  'PACKAGE_LINE_ITEM': 'Упаковки',
  'BRAND': 'Бренд',
  'UNIT_MEASUREMENT': 'Единица измерения',
  'ENABLE_EXPIRY_DATE_TRACKING': 'Отслеживание срока действия',
  'ENABLE_BATCH_NUMBERS': 'Номера пакетов',
  'MODIFIED_DATE': 'Дата изменения',
  'CREATED_DATE': 'Дата создания',
  'CREATED_BY': 'Сделано',
  'MODIFIED_BY': 'Модифицирован',
  'SELL_IN_PACKAGES': 'Продовать только в упаковке',
  'STATUS': 'Статус'
}

const getTranslatedColumn = (code: string, name: string): string => {
  return translations[code] || name
}

export const ColumnDndItem = ({
  code,
  text,
  isDragging = false,
  isOverlay = false
}: any) => {
  return (
    <div
      style={{
        background: isDragging ? 'white' : isOverlay ? 'blue' : '#f1f1f1',
        minWidth: 'max-content',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
      }}
    >
      {getTranslatedColumn(code, text)}
    </div>
  )
}

const SortableItem = ({ id, text, code }: any) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    height: '100%'
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ColumnDndItem
        code={code}
        text={text}
        isDragging={isDragging}
      />
    </div>
  )
}

function TableColumnResize ({ item, mouseDown, activeIndex, idx, items, tableHeight }: any) {
  const { id, ref, name, code, width = 100 } = item
  if (!ref) {
    item.ref = useRef()
  }

  const isLast = idx === items.length - 1

  return (
    <TableCell
      key={id}
      ref={ref}
      sx={{ position: 'relative', padding: '15px 10px' }}
      width={width}
    >
      <SortableItem
        id={id}
        text={name}
        code={code}
      />
      {!isLast && (
        <div
          style={{ height: tableHeight }}
          onMouseDown={() => mouseDown(idx)}
          className={`resize-handle-setting ${activeIndex === idx ? 'active' : 'idle'}`}
        />
      )}
    </TableCell>
  )
}

function TableColumnsContainer ({ items = [], id }: any) {
  const { setNodeRef } = useDroppable({ id: id })
  const tableElement = useRef(null) as any
  const [activeIndex, setActiveIndex] = useState(null)
  const [tableHeight, setTableHeight] = useState('auto')

  const classes = columnsSettingStyles()

  const mouseMove = useCallback((e: any) => {
    items.map((col: any, i: number) => {
      if (i === activeIndex) {
        const width = e.clientX - col.ref.current.offsetLeft

        col.ref.current.style.width = `${width}px`
        col.width = width
      }

      return `${col.ref.current.offsetWidth}px`
    })
  }, [activeIndex, items, minCellWidth])

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove)
    window.removeEventListener('mouseup', removeListeners)
  }, [mouseMove])

  const mouseDown = (index: any) => {
    setActiveIndex(index)
  }

  const mouseUp = useCallback(() => {
    setActiveIndex(null)
    removeListeners()
  }, [setActiveIndex, removeListeners])

  useEffect(() => {
    setTableHeight(tableElement.current.offsetHeight)
  }, [items])

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove)
      window.addEventListener('mouseup', mouseUp)
    }

    return () => {
      removeListeners()
    }
  }, [activeIndex, mouseMove, mouseUp, removeListeners])

  return (
    <TableContainer component={Paper}>
      <MuiTable size="small" aria-label="sticky table" ref={tableElement}>
        <TableHead>
          <TableRow
            ref={setNodeRef}
            style={{ display: 'flex', background: '#f1f1f1', height: '51px', alignItems: 'center' }}
          >
            <TableCell className={classes.checkboxColumn}>
              <Checkbox
                className={classes.checkboxHeader}
                color="primary"
                data-cy="table-checkbox-all"
                checked={false}
              />
            </TableCell>
            <TableCell sx={{ position: 'relative', padding: '0 8px', fontSize: '11px' }} width={45}>
              <div className={classes.selectedCount}>
                0
              </div>
            </TableCell>
            <SortableContext
              items={items}
              strategy={horizontalListSortingStrategy}
              id={id}
            >
              {items.map((item: any, idx: number) => (
                <TableColumnResize
                  item={item}
                  idx={idx}
                  mouseDown={mouseDown}
                  activeIndex={activeIndex}
                  items={items}
                  tableHeight={tableHeight}
                />
              ))}
            </SortableContext>
          </TableRow>
        </TableHead>
      </MuiTable>
    </TableContainer>
  )
}

export default TableColumnsContainer
