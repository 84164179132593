import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { updatePackage, getPackageDetail } from '../api'
import PackageUpdateForm from '../components/PackageUpdateForm'

function PackageUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const packageUpdate = usePut(updatePackage)
  const packageDetail = useGetDetail(getPackageDetail, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    return packageUpdate.putData({ data: values })
      .then(() => snackbar({ message: 'Упаковка успешно обновлена' }))
      .then(() => navigate(ROUTES.CATALOG_PACKAGE_LIST))
  }, [])

  return (
    <AppLayout>
      {!packageDetail.loading && (
        <PackageUpdateForm
          onSubmit={handleSubmit}
          initialValues={packageDetail.result}
          enableReinitialize={true}
        />
      )}
      {packageDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default PackageUpdateContainer
