import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import DateField from 'components/form/DateField'
import { path } from 'ramda'

import CurrencyField from './CurrencyField'
import LineItems from './LineItems'

function ManualEntryUpdateForm ({ form }:any) {
  const name = path(['values', 'number'], form)

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить корректировка (${name})`}
          rightButton={(
            <>
              <Button type="submit">Сохранить изменения</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={6}>
                    <DateField name="date" label="Дата корректировки" />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <CurrencyField />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(ManualEntryUpdateForm)
