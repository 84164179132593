import { omit, prop } from 'ramda'

export const approverCreateSerializer = (values:any) => {
  const getSteps = prop('steps', values)
  const steps = getSteps && getSteps.map((step:any) => {
    const result = omit(['step'], step)
    const getRoleCodes = prop('roleCodes', result)
    const getEmployee = prop('employeeIds', result)
    const employeeIds = getEmployee.map((employee:any) => employee.id)
    const roleCodes = getRoleCodes.map((role:any) => role.role)
    return { ...result, employeeIds, roleCodes }
  })
  return { ...values, steps }
}
