import * as API from 'constants/Api'

import React from 'react'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import TextField from 'components/form/TextField'
import CardHeader from 'components/card/CardHeader'
import PhoneField from 'components/form/PhoneField'
import { FieldArray, useFormikContext } from 'formik'
import { propOr } from 'ramda'
import SwitchField from 'components/form/SwitchField'

import AddressesArray from './AddressesArray'

import TerritoryField from '../form/TerritoryField'
import CurrencyField from '../form/CurrencyField'
import MultipleField from '../form/MultipleField'
import ValidateTextField from '../form/ValidateTextField'
import CategoryField from '../form/CategoryField'
import InnField from '../form/InnField'

function AgentBasic ({ form }:any) {
  const { values } = useFormikContext()
  const persons = propOr([], 'persons', values) as any
  const addresses = propOr([], 'addresses', values) as any
  const financialDetails = propOr([], 'financialDetails', values) as any
  const juridicalList = propOr([], 'juridicalList', values) as any
  return (
    <Card>
      <CardHeader title="Детали" />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} sm={6} md={4} lg={3}>
            <MultipleField
              label="Полное название"
              name="name"
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="Название контрагента"
              name="shortName"
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={3}>
            <PhoneField
              label="Телефон"
              name="number"
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={3}>
            <ValidateTextField
              label="Антекс ID"
              name="antexId"
              api={API.ValidateContactAntexID}
            />
          </Grid>
          <Grid item={true} lg={3}>
            <CategoryField />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="Имя контактного лица"
              name="primaryPerson.firstName"
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={3}>
            <PhoneField
              label="Телефон контактного лица"
              name="primaryPerson.phone"
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="Телеграм контактного лица"
              name="primaryPerson.telegramId"
            />
          </Grid>
          <Grid item={true} lg={3}>
            <CurrencyField form={form} />
          </Grid>
          <Grid item={true} lg={3}>
            <TerritoryField />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="Электронная почта"
              name="email"
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="Веб-сайт"
              name="website"
            />
          </Grid>
          <Grid item={true} lg={3}>
            <TextField
              label="Примечания"
              name="note"
              multiline={true}
              minRows={4}
            />
          </Grid>
          <Grid item={true} lg={3}>
            <SwitchField
              label="Показать юридические данные"
              name="showContactInfo"
            />
          </Grid>
          <Grid item={true} lg={12}>
            <FieldArray name="persons">
              {({ push, remove }: any) => (
                <Grid container={true} spacing={3}>
                  {persons.map((contact: any, index: number) => (
                    <Grid item={true} key={index} lg={12}>
                      <Grid container={true} spacing={3}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            label={'Контактное лицо ' + (index + 1)}
                            name={`persons[${index}].firstName`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            label={'Телеграм ID ' + (index + 1)}
                            name={`persons[${index}].telegramId`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <PhoneField
                            label={'Телефон' + (index + 1)}
                            name={`persons[${index}].phone`}
                          />
                        </Grid>
                        {index >= 0 && (
                          <>
                            <Grid item lg={1}>
                              <Button
                                onClick={() => remove(index)}
                                fullWidth
                              >
                                Удалить
                              </Button>
                            </Grid>
                            {persons.length === index + 1 && (
                              <Grid item={true} lg={2}>
                                <Button
                                  onClick={() => {
                                    push({
                                      firstName: '',
                                      phone: '',
                                      telegramId: ''
                                    })
                                  }}
                                  fullWidth
                                >
                                  Добавить контакт
                                </Button>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  {persons.length <= 0 && (
                    <Grid item={true} lg={3}>
                      <Button
                        onClick={() => {
                          push({
                            firstName: '',
                            phone: '',
                            telegramId: ''
                          })
                        }}
                      >
                        Добавить контакт
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </FieldArray>
          </Grid>
          <Grid item={true} lg={12}>
            <AddressesArray
              addresses={addresses}
            />
          </Grid>
          <Grid item={true} lg={12}>
            <FieldArray name="juridicalList">
              {({ push, remove }: any) => (
                <Grid container={true} spacing={3}>
                  {juridicalList.map((address: any, index: number) => (
                    <Grid item={true} key={index} lg={12}>
                      <Grid container={true} spacing={3}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <InnField
                            label={'ИНН ' + (index + 1)}
                            name={`juridicalList[${index}].inn`}
                            index={index}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            label={'Юридическое имя ' + (index + 1)}
                            name={`juridicalList[${index}].name`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            label={'Налог ID ' + (index + 1)}
                            name={`juridicalList[${index}].taxId`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                          <TextField
                            label={'Окэд ' + (index + 1)}
                            name={`juridicalList[${index}].oked`}
                          />
                        </Grid>
                        {index >= 0 && (
                          <>
                            <Grid item lg={1}>
                              <Button
                                onClick={() => remove(index)}
                                fullWidth
                              >
                                Удалить
                              </Button>
                            </Grid>
                            {juridicalList.length === index + 1 && (
                              <Grid item={true} lg={2}>
                                <Button
                                  onClick={() => {
                                    push({
                                      inn: '',
                                      name: '',
                                      taxId: ''
                                    })
                                  }}
                                  fullWidth
                                >
                                  Добавить юр. лицо
                                </Button>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  {juridicalList.length <= 0 && (
                    <Grid item={true} lg={3}>
                      <Button
                        onClick={() => {
                          push({
                            inn:'',
                            name:'',
                            taxId:'',
                            oked:''
                          })
                        }}
                      >
                        Добавить юр. лицо
                      </Button>
                    </Grid>)}
                </Grid>
              )}
            </FieldArray>
          </Grid>
          <Grid item={true} lg={12}>
            <FieldArray name="financialDetails">
              {({ push, remove }: any) => (
                <Grid container={true} spacing={3}>
                  {financialDetails.map((address: any, index: number) => (
                    <Grid item={true} key={index} lg={12}>
                      <Grid container={true} spacing={3}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            label={'МФО ' + (index + 1)}
                            name={`financialDetails[${index}].mfo`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            label={'Название банка ' + (index + 1)}
                            name={`financialDetails[${index}].bankAccountName`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            label={'Номер банковского счета ' + (index + 1)}
                            name={`financialDetails[${index}].bankAccountNumber`}
                          />
                        </Grid>
                        {index >= 0 && (
                          <>
                            <Grid item lg={1}>
                              <Button
                                onClick={() => remove(index)}
                                fullWidth
                              >
                                Удалить
                              </Button>
                            </Grid>
                            {financialDetails.length === index + 1 && (
                              <Grid item={true} lg={2}>
                                <Button
                                  onClick={() => {
                                    push({
                                      mfo: '',
                                      bankAccountName: '',
                                      bankAccountNumber: '',
                                    })
                                  }}
                                  fullWidth
                                >
                                  Добавить счет
                                </Button>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  {financialDetails.length <= 0 && (
                    <Grid item={true} lg={3}>
                      <Button
                        onClick={() => {
                          push({
                            mfo:'',
                            bankAccountName:'',
                            bankAccountNumber:'',
                            oked:''
                          })
                        }}
                      >
                        Добавить счет
                      </Button>
                    </Grid>)}
                </Grid>
              )}
            </FieldArray>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default AgentBasic
