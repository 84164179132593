import { Button, Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import Header from 'components/container/Header'
import PageTitle from 'components/main/PageTitle'
import Content from 'components/container/Content'
import SwitchField from 'components/form/SwitchField'
import { useParams } from 'react-router-dom'

import ColumnsField from './form/ColumnsField'
import BillingStatusField from './form/BillingStatusField'
import BillingTabs, { BILLING_TABS } from './BillingTabs'
import CategoryLineItems from './form/CategoryLineItems'
import BillingCategoryField from './form/BillingCategoryField'
import { BillingModules } from './BillingModules'

function BillingPlanUpdateForm () {
  const { tab } = useParams()

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Обновить тарифный план"
          rightButton={(
            <>
              <Button type="submit">Сохранить изменение</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <BillingTabs value={tab as string} />
        <Grid container={true} spacing={3}>
          {tab === BILLING_TABS.GENERAL && (
            <Grid item={true} lg={12}>
              <Card>
                <CardHeader title="Деталь" />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Название тарифного плана"
                        name="name"
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Количество"
                        name="amount"
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <BillingCategoryField />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <BillingStatusField />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Описание"
                        name="description"
                        minRows={4}
                        multiline={true}
                      />
                    </Grid>
                    <Grid item={true} lg={4} />
                    <Grid item={true} lg={4}>
                      <SwitchField
                        label="Плата за экспорт контактов"
                        name="contactExportCharge"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {tab === BILLING_TABS.CONTACT_TABLE && (
            <Grid item={true} lg={12}>
              <CategoryLineItems />
            </Grid>
          )}
          {tab === BILLING_TABS.COLUMNS && (
            <Grid item={true} lg={12}>
              <Card>
                <CardHeader title="Столбцы" />
                <CardContent>
                  <Grid container={true} spacing={4}>
                    <ColumnsField />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {tab === BILLING_TABS.MODULES && (
            <Grid item={true} lg={12}>
              <Card>
                <CardHeader title="Модули" />
                <CardContent>
                  <Grid container={true} spacing={4}>
                    <BillingModules />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(BillingPlanUpdateForm)
