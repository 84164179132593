import * as API from 'constants/Api'

import React, { useEffect, useState } from 'react'
import { propOr } from 'ramda'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { getRequest } from 'api/BaseApi'
import { useField } from 'formik'
import { makeStyles } from '@mui/styles'

const defaultGetOptionLabel = (value: any) => {
  if (value) {
    return propOr('', 'name', value)
  }

  return ''
}

const useStyles = makeStyles({
  autocomplete: {
    height: '100%',
    '& .MuiFormControl-root': {
      height: '100%',
      '& .MuiFormLabel-root': {
        '&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
          transform: 'translate(14px, 9px) scale(1)',
        }
      }
    }
  },
  field: {
    '& .MuiInputBase-root': {
      borderRadius:'5px',
      height: '100%',
    },
    '& .MuiOutlinedInput-root': {

      '& fieldset': {
        borderColor: '#202020',
      },

      '&.Mui-focused fieldset': {
        borderColor: '#1557FF',
      },
      '& .MuiInputBase-input': {
        padding: '16px',
        fontSize: '13px',
        color: '#202020',
        background: 'white'
      },
      '& ::placeholder': {
        opacity: 1,
        color: '#7c7c7c',
        fontSize: '15px',
        fontWeight: '500',
      }
    }
  },
})

function CurrencySearchField (props: any) {
  const {
    name,
    disabled,
    InputProps,
    PaperComponent,
    ListboxProps,
    ListboxComponent,
    renderOption,
    searchFieldProps,
    disableCloseOnSelect,
    currency,
    primaryKey = 'id',
    isLoading = false,
    disableClearable = false,
    fullWidth = true,
    size = 'small',
    onInputChange = () => null,
    onValueChange = () => null,
    getOptionLabel = defaultGetOptionLabel,
    ...defaultProps
  } = props
  const classes = useStyles()
  const [, meta, helpers] = useField(name)
  const [newValue, setNewVal] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([] as any)

  useEffect(() => {
    if (currency) {
      getRequest(API.CurrencyGetById, { params: { id:currency } })
        .then((response) => {
          setNewVal(response)
          helpers.setValue(response)
          onValueChange(response)
          setLoading(false)
        })
    }
  }, [currency])

  const submitError = propOr(null, 'submitError', meta)
  const stringOfSubmitError = submitError ? JSON.stringify(submitError) : ''
  return (
    <Autocomplete
      className={classes.autocomplete}
      disabled={disabled}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      value={newValue}
      isOptionEqualToValue={(option: any, value) => option[primaryKey] === value[primaryKey]}
      getOptionLabel={getOptionLabel}
      options={options.length === 0 ? [newValue] : options}
      loading={loading}
      filterOptions={item => item}
      onChange={(event, value) => {
        onValueChange(value)
        helpers.setValue(value)
      }}
      PaperComponent={PaperComponent}
      ListboxComponent={ListboxComponent}
      size={size}
      fullWidth={fullWidth}
      ListboxProps={ListboxProps}
      renderOption={renderOption}
      onInputChange={(event, value) => {
        setOptions([])
        onInputChange(value)
      }}
      renderInput={params => {
        return (
          <TextField
            className={classes.field}
            error={meta.error}
            helperText={stringOfSubmitError || meta.error}
            {...params}
            {...defaultProps}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: (
                <>
                  {(isLoading || loading) ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )
      }}
      {...searchFieldProps}
    />
  )
}

export default CurrencySearchField
