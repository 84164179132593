import React from 'react'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import PhoneField from 'components/form/PhoneField'
import DateField from 'components/form/DateField'
import DoubleFormatField from 'components/form/DoubleFormatField'

import CompanyStatusField from './form/CompanyStatusField'
import MultipleWarehouseField from './form/MultipleWarehouseField'
import BillingPlanField from './form/BillingPLanField'
import { RenewableDateField } from './form/RenewableDateField'

export interface CompanyForm{
  readonly addressLine1: string,
  readonly addressline2: string,
  readonly city: string,
  readonly companyName: string,
  readonly companyPhone: string,
  readonly countryCode: string,
  readonly countryName: string,
  readonly referrer: string,
  readonly stateCode: string | any,
  readonly stateName: string,
  readonly zip: number
}
function CompanyCreateForm () {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать компанию"
          rightButton={(
            <>
              <Button type="submit">Создать</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Название компании"
                      name="companyName"
                      data-cy="companyName"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Краткое название"
                      name="shortName"
                      data-cy="shortName"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Адресс 1"
                      name="addressLine1"
                      data-cy="addressLine1"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Адресс 2"
                      name="addressLine2"
                      data-cy="addressLine2"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Электронная почта"
                      name="companyEmail"
                      data-cy="companyEmail"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <PhoneField
                      label="Номер телефона"
                      name="companyPhone"
                      data-cy="companyPhone"
                      value="998"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CompanyStatusField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label="Тип подписки"
                      name="subscriptionType"
                      data-cy="subscriptionType"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <MultipleWarehouseField />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали подписки" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={3}>
                    <DoubleFormatField
                      label="Баланс"
                      name="balance"
                    />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <BillingPlanField
                      label="Следующий тарифный план"
                      name="nextBillingPlan"
                    />
                  </Grid>
                  <Grid item={true} lg={6} />
                  <Grid item={true} lg={3}>
                    <BillingPlanField
                      label="Текущий тарифный план"
                      name="billingPlan"
                    />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <DateField
                      label="Дата активации"
                      name="activationDate"
                    />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <RenewableDateField
                      label="Дата продления"
                      name="renewalDate"
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(CompanyCreateForm)
