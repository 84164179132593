import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'

import RentCreateForm from '../components/RentCreateForm'
import { createRent } from '../api'
import { rentCreateSerializer } from '../serializer'

function RentCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const rentCreate = usePost(createRent)

  const handleSubmit = useCallback((values: any) => {
    return rentCreate.postData({ data: rentCreateSerializer(values) })
      .then(() => {
        snackbar({ message: 'Аренда успешно создана' })
        navigate(ROUTES.MARKETING_RENT_LIST)
      })
  }, [])

  return (
    <AppLayout>
      <RentCreateForm
        onSubmit={handleSubmit}
        initialValues={{ }}
        enableReinitialize={true}
      />
    </AppLayout>
  )
}

export default RentCreateContainer
