import * as API from 'constants/Api'

import React, { useCallback } from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import SwitchField from 'components/form/SwitchField'
import ValidateTextField from 'components/form/ValidateTextField'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { useField } from 'formik'
import { generateBarcode } from 'utils/barcode'
import { useLocales } from 'hooks/useLocales'

import CategoryField from './form/CategoryField'
import BrandField from './form/BrandField'
import UnitMeasurementField from './form/UnitMeasurementField'
import BarcodeField from './form/BarcodeField'
import PackageLineItems from './form/PackageLineItems'
import MultipleSupplierField from './form/MultipleSupplierField'
import CountryField from './form/CountryField'
import ProductTypeField from './form/ProductTypeField'
import ImagesUpload from './form/ImagesUpload'
import { AvailableCustomField } from './custom-field/AvailableCustomField'
import ProducedInField from './ProducedInField'

interface PackageItems{
  readonly productId:number,
  readonly packageId:number,
  readonly quantity:number,
  readonly isDefault:boolean
}
export interface ProductForm {
  readonly name: string,
  readonly barcode: string,
  readonly description: string,
  readonly enableBatchNumbers: true,
  readonly enableExpiryDateTracking: true,
  readonly height: number,
  readonly length: number,
  readonly lowStockAlert: number,
  readonly sku: string,
  readonly supplierIds: number[],
  readonly volumetricSize: number,
  readonly weight: number,
  readonly width: number,
  readonly categoryId: number,
  readonly brandId: number,
  packageLineItems: PackageItems[] | string
}

interface Props {
  readonly packageList: any
}

function ProductCreateForm ({ packageList }: Props) {
  const { t } = useLocales('product')
  const barcodeField = useField('barcode')
  const barcodeValue = barcodeField[0].value || []
  const barcodeChange = barcodeField[2].setValue

  const customFieldsField = useField('customFieldValue')
  const customFields = customFieldsField[0].value || []

  const onGenerateBarcode = useCallback(() => {
    const newBarcode = generateBarcode()
    barcodeChange([...barcodeValue, newBarcode])
  }, [barcodeValue])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={t('Create product')}
          rightButton={(
            <>
              <Button type="submit">{t('Create')}</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title={t('Detail')} />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <ValidateTextField
                      label={t('Name')}
                      name="name"
                      api={API.ProductCheck}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <ValidateTextField
                      label={t('Short name')}
                      name="shortName"
                      api={API.ProductCheck}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <BarcodeField
                      label={t('Barcode')}
                      name="barcode"
                      buttonTitle={t('Generate')}
                      onGenerate={onGenerateBarcode}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label={t('Carton barcode')}
                      name="cartonBarcode"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <ValidateTextField
                      label={t('Sku')}
                      name="sku"
                      api={API.ProductCheck}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <ValidateTextField
                      label={t('Sort number')}
                      name="sortNumber"
                      type="number"
                      api={API.ProductCheck}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label={t('Pieces')}
                      name="pieces"
                      type="number"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <CategoryField
                      label={t('Categories')}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <BrandField
                      label={t('Brand')}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <UnitMeasurementField
                      label={t('Unit measurement')}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <MultipleSupplierField
                      label={t('Suppliers')}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <CountryField
                      label={t('Country')}
                      name="countryId" />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <ProducedInField
                      label={t('Produced in')}
                      name="producedIn"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <ProductTypeField
                      label={t('Product type')}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label={t('Description')}
                      name="description"
                      multiline={true}
                      minRows={4}
                    />
                  </Grid>
                  <Grid item={true} lg={12}>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                        <SwitchField
                          name="enableExpiryDateTracking"
                          label={t('Enable expiry date tracking')}
                        />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                        <SwitchField
                          name="enableBatchNumbers"
                          label={t('Enable batch numbers')}
                        />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                        <SwitchField
                          name="sellInPackages"
                          label={t('Sell in packages')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <ImagesUpload />
          </Grid>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title={t('Product size')} />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label={t('Weight')}
                      name="weight"
                      type="number"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label={t('Weight brutto')}
                      name="weightBrutto"
                      type="number"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label={t('Height')}
                      name="height"
                      type="number"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label={t('Length')}
                      name="length"
                      type="number"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label={t('Width')}
                      name="width"
                      type="number"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label={t('Volumetric size')}
                      name="volumetricSize"
                      type="number"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <PackageLineItems packageList={packageList} />
          </Grid>
          <Grid item={true} lg={12}>
            <AvailableCustomField customFields={customFields} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(ProductCreateForm)
