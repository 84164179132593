import React from 'react'
import { MenuItem } from '@mui/material'

interface Props{
  readonly onPickerBulk:() => void
  readonly onMerge:() => void
  readonly hasMerge:boolean
}
function TableActionsRow ({ onPickerBulk, onMerge, hasMerge }:Props) {
  return (
    <>
      <MenuItem onClick={onPickerBulk}>
        Выберите сборщик
      </MenuItem>
      {hasMerge && <MenuItem onClick={onMerge}>
        Объединить
      </MenuItem>}
    </>
  )
}

export default TableActionsRow
