import { prop } from 'ramda'
import { toPayloadDate } from 'utils/dates'

export const shipmentDetailInitialSerializer = (values: any) => {
  const pickerId = prop('picker', values)
  const packerId = prop('packer', values)
  const driverId = prop('driver', values)
  const address = prop('address', values)
  const countryId = prop('country', address)
  const regionId = prop('region', address)
  const lat = prop('lat', address)
  const lon = prop('lon', address)
  const location = lat + ', ' + lon
  return {
    ...values,
    pickerId,
    packerId,
    driverId,
    address:{
      ...address,
      countryId,
      regionId,
      location
    }
  }
}

export const deliveredShipmentSerializer = (id:number, data:any) => {
  const items = data && data.map((line:any) => {
    const deliveredQuantity = prop('deliveredQuantity', line)
    const shipmentItemId = prop('id', line)
    return { deliveredQuantity, shipmentItemId }
  })
  const date = new Date().toString()
  const deliveryDate = toPayloadDate(date)
  return { shipmentId:id, deliveryDate, items }
}

export const onGetTab = (values:any) => {
  if (Array.isArray(values)) {
    return values && values.map((tab: any) => {
      const id = prop('id', tab)
      const name = prop('name', tab)
      return { id, title:name, status: id, count:name.toLowerCase() }
    })
  }
}

export function transformList (list:any): { [key: string]: number } {
  const transformedObject: { [key: string]: number } = {}

  if (Array.isArray(list)) {
    list.forEach((item) => {
      const lowercaseName: string = item.name.toLowerCase()
      transformedObject[lowercaseName] = item.records
    })
  }

  return transformedObject
}

export function hasRelevantStatus (values: any): boolean {
  if (values.length > 0) {
    return values.some((shipment: any) =>
      ['RETURNED', 'DELIVERED', 'COMPLETED', 'SHIPPING'].includes(shipment.status) ||
      (shipment.salesReturn && shipment.salesReturn.id && shipment.salesReturn.name)
    )
  }
  return false
}
