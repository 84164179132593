import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import L from 'leaflet'

interface AutoFocusMapProps {
  contactList: any[]
}

const AutoFocusMap = ({ contactList }: AutoFocusMapProps) => {
  const map = useMap()

  useEffect(() => {
    if (contactList.length > 0) {
      const allPoints: L.LatLngTuple[] = []

      contactList.forEach((item) => {
        item.addressList.forEach((task: any) => {
          const lat = task?.lat
          const lon = task?.lon

          if (lat !== undefined && lon !== undefined) {
            const latNum = Number(lat)
            const lonNum = Number(lon)

            if (!isNaN(latNum) && !isNaN(lonNum)) {
              allPoints.push([latNum, lonNum])
            }
          }
        })
      })

      if (allPoints.length > 0) {
        const bounds = L.latLngBounds(allPoints)
        map.fitBounds(bounds)
      }
    }
  }, [contactList, map])

  return null
}

export default AutoFocusMap
