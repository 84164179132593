import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { path } from 'ramda'

import { getCustomFields, ProductBulkEdit, ProductBulkCategory } from '../api'
import ProductBulkEditForm from '../components/ProductBulkEditForm'
import { useGetList } from '../../../../hooks/useGetList'
import { productBulkAddSerializer } from '../serializer'

function ProductBulkUpdateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()
  const productItems = path(['state', 'productItems'], location)
  const [selected, setSelected] = useState([])
  const [customFields, setCustomField] = useState([])
  const productBulkUpdate = usePut(ProductBulkEdit)
  const productBulkCategory = usePut(ProductBulkCategory)
  const customFieldsGet = useGetList(getCustomFields)
  const selectedIds = useMemo(() => selected.map((item:{ id:number }) => item.id), [selected])

  const productNames = useMemo(() => {
    if (productItems.length <= 5) {
      return productItems.map((item: { id: number, name:string }) => item.name).join(', ')
    } else {
      const firstFiveNames = productItems.slice(0, 5).map((item: { id: number, name:string }) => item.name).join(', ')
      return `${firstFiveNames}...`
    }
  }, [productItems])

  const productIds = useMemo(() => {
    return productItems.map((item: { id: number, name:string }) => item.id)
  }, [productItems])

  const handleSubmit = useCallback((values: any) => {
    return productBulkUpdate.putData({ data: productBulkAddSerializer({ productIds, values }) })
      .then(() => snackbar({ message: 'Новое значение успешно добавлено' }))
      .then(() => navigate(ROUTES.CATALOG_PRODUCT_LIST))
  }, [productIds])

  const onAdd = useCallback(() => {
    productBulkCategory.putData({ data:{ productIds, categoryIds:selectedIds } })
      .then(() => snackbar({ message: 'Новое значение успешно добавлено' }))
      .then(() => navigate(ROUTES.CATALOG_PRODUCT_LIST))
  }, [selectedIds])

  useEffect(() => {
    customFieldsGet.getList({ params: { formType: 'PRODUCT_FORM' } }).then((res: any) => {
      setCustomField(res)
    })
  }, [])

  return (
    <AppLayout>
      {customFieldsGet.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
      {!customFieldsGet.loading && (
        <ProductBulkEditForm
          onAdd={onAdd}
          productItems={productItems}
          productNames={productNames}
          selected={selected}
          setSelected={setSelected}
          onSubmit={handleSubmit}
          initialValues={{
            customFieldValue: customFields,
          }}
        />
      )}
    </AppLayout>
  )
}

export default ProductBulkUpdateContainer
