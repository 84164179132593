import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useGetDetail } from 'hooks/useGetDetail'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'

import { getTaskReturnById, updateTaskReturn } from '../api'
import TaskReturnUpdateForm from '../components/TaskReturnUpdateForm'
import { taskReturnDetailSerializer, taskReturnUpdateSerializer } from '../serializer'

function TaskReturnUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const taskReturnDetails = useGetDetail(getTaskReturnById, { params: { id } })
  const taskReturnUpdate = usePut(updateTaskReturn)

  const handleSubmit = useCallback((values:any) => {
    return taskReturnUpdate.putData({ data: taskReturnUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Посещение успешно обновлён' }))
      .then(() => navigate(ROUTES.CRM_RETURN_TASK_LIST))
  }, [])

  const initialValues = useMemo(() => taskReturnDetailSerializer(taskReturnDetails.result), [taskReturnDetails])

  return (
    <AppLayout>
      {!taskReturnDetails.loading && (
        <TaskReturnUpdateForm
          onSubmit={handleSubmit}
          readOnly={true}
          initialValues={initialValues}
          enableReinitialize={true}
        />
      )}
      {taskReturnDetails.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default TaskReturnUpdateContainer
