import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button'
import withForm from 'components/form/withForm'
import DateField from 'components/form/DateField'
import SwitchField from 'components/form/SwitchField'

import BillingPlanField from './form/BillingPLanField'

interface Props {
  readonly open: boolean
  readonly handleClose: () => void
  readonly loading: boolean
  readonly form: any
}

function BillingSetDialogue ({ open, handleClose, loading, form }: Props) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Установить тарифный план</DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <BillingPlanField
              label="Тарифный план"
              name="billingPlanId"
            />
          </Grid>
          <Grid item={true} lg={12}>
            <DateField
              label="Дата активации"
              name="activationDate"
            />
          </Grid>
          <Grid item={true} lg={12}>
            <SwitchField
              label="Разрешить оплату за экспорт контактов"
              name="contactExportCharge"
            />
          </Grid>
          <Grid item={true} lg={12}>
            <SwitchField
              label="Для следующего периода"
              name="nextPeriod"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ px:3 }}>
        <Button onClick={handleClose} variant="outlined" fullWidth={true}>
          Отменить
        </Button>
        <Button onClick={form.handleSubmit} variant="contained" fullWidth={true} disabled={loading}>
          Установить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withForm(BillingSetDialogue)
