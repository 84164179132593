import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { propOr, trim } from 'ramda'

import { timeSlotDelete, getTimeSlotList } from '../api'
import TimeSlotRowMenu from '../components/TimeSlotRowMenu'

const columns = [
  {
    headerName: 'Наименование',
    field: 'name',
  },
  {
    headerName:'Эффективная дата',
    field: 'effectiveDate'
  },
  {
    headerName: 'Дата окончания',
    field: 'endDate'
  }
]

function TimeSlotListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const timeSlotList = useGetList(getTimeSlotList)
  const deleteTimeSlot = useDelete(timeSlotDelete)

  const onDeleteTimeSlot = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить рабочее время пользователя ${values.name}`

    onConfirm({ message })
      .agree(() => {
        deleteTimeSlot.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Рабочее время пользователя успешно удалено' }))
          .then(() => timeSlotList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    timeSlotList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Временной интервал работы пользователя"
        onAddClick={() => navigate(ROUTES.SETTINGS_TIMESLOT_CREATE)}
        dataRequest={timeSlotList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={[]}
        checkbox={true}
        searchPlaceholder="Поиск по названию, телефону, короткому имени, ИНН или ID"
        actions={(row) => (
          <TimeSlotRowMenu
            item={row}
            handleDelete={onDeleteTimeSlot}
          />
        )}
      />
    </AppLayout>
  )
}

export default TimeSlotListContainer
