import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { path, propOr } from 'ramda'
import { formatMoney } from 'utils/formatMoney'

import { getBulkPaymentList } from '../api'
import BulkPaymentMethodRow from '../components/BulkPaymentMethodRow.tsx'
import BulkPaymentRowMenu from '../components/BulkPaymentRow'

const columns = [
  {
    headerName: 'Номер',
    field: 'number'
  },
  {
    headerName: 'Пользователь',
    field: 'account',
    renderCell: path(['account', 'name'])
  },
  {
    headerName: 'Банковский счет',
    field: 'bankChargeAccount',
    renderCell: path(['bankChargeAccount', 'name'])
  },
  {
    headerName: 'Общая сумма',
    field: 'totalAmount',
    renderCell:(item:any) => formatMoney(item.totalAmount)
  },
  {
    headerName: 'Валюта',
    field: 'currency'
  },
  {
    headerName: 'Способ оплаты',
    field: 'paymentMethod',
    renderCell:(item:any) => <BulkPaymentMethodRow status={item.paymentMethod} />
  },
  {
    headerName: 'Дата',
    field: 'date'
  },
]

function BulkPaymentListContainer () {
  const navigate = useNavigate()
  const bulkPaymentList = useGetList(getBulkPaymentList)
  const [selectedRows, setSelectedRows] = useState<any>([])

  const onTableSearch = useCallback((value: any) => {
    const search = propOr('', 'search', value)
    bulkPaymentList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Массовый платеж"
        onAddClick={() => navigate(ROUTES.ORDER_BULK_PAYMENT_CREATE)}
        dataRequest={bulkPaymentList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        actions={(row) => (
          <BulkPaymentRowMenu
            item={row}
          />
        )}
      />
    </AppLayout>
  )
}

export default BulkPaymentListContainer
