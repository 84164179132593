import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useGetDetail } from 'hooks/useGetDetail'
import { ALTER_ERROR, useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetList } from 'hooks/useGetList'

import { getCustomFields, getVisitById, soListGet, updateVisit, updateVisitStatus } from '../api'
import { isOnComplete, visitInitialValuesSerializer, visitUpdateSerializer } from '../serializer'
import VisitUpdateForm from '../components/VisitUpdateForm'
import { validationSchemaVisitUpdateForm } from '../components/validationSchema'

function VisitUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const [customFields, setCustomField] = useState([])
  const visitDetails = useGetDetail(getVisitById, { params: { id } })
  const visitUpdate = usePut(updateVisit)
  const customFieldsGet = useGetList(getCustomFields)
  const onCompleteTask = usePut(updateVisitStatus)
  const soListByVisitId = useGetList(soListGet, { query: { visitId:id } })

  const handleSubmit = useCallback((values:any) => {
    return visitUpdate.putData({ data: visitUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Посещение успешно обновлён' }))
      .then(() => navigate(ROUTES.CATALOG_VISIT_LIST))
  }, [])

  const onComplete = useCallback((values:any) => {
    const { isEqual, notEqualFields } = isOnComplete(values)
    if (isEqual) {
      onCompleteTask.putData({ data: { id, status: 'COMPLETED' } })
        .then(() => snackbar({ message: 'Задача завершена успешно' }))
        .then(() => navigate(ROUTES.CATALOG_VISIT_LIST))
    } else {
      const requiredFields = notEqualFields.join(', ')
      snackbar({
        message: `Тип посещения не соответствует, завершение невозможно, пожалуйста,
        заполните это поле ${requiredFields}`,
        type:ALTER_ERROR
      })
    }
  }, [])

  useEffect(() => {
    customFieldsGet.getList({ params: { formType: 'TASK_FORM' } }).then((res: any) => {
      setCustomField(res)
    })
  }, [])

  const initialValues = useMemo(() => visitInitialValuesSerializer(visitDetails.result), [visitDetails])

  return (
    <AppLayout>
      {!visitDetails.loading && (
        <VisitUpdateForm
          readOnly={true}
          onComplete={onComplete}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          customFields={customFields}
          initialValues={initialValues}
          soList={soListByVisitId}
          validationSchema={validationSchemaVisitUpdateForm}
        />
      )}
      {visitDetails.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default VisitUpdateContainer
