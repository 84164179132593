const translations: { [key: string]: string } = {
  'NAME': 'Наименование товара',
  'SHORT_NAME': 'Короткое наименование',
  'SORT_NUMBER': 'Порядковый номер',
  'PIECES': 'Штук',
  'BARCODE': 'Штрих-код',
  'PRODUCED_IN': 'Страна',
  'DESCRIPTION': 'Описание',
  'SKU': 'Артикул',
  'WEIGHT': 'Вес нетто',
  'WEIGHT_BRUTTO': 'Вес брутто',
  'HEIGHT': 'Высота',
  'PRODUCT_TYPE': 'Тип продукта',
  'SUPPLIERS': 'Контрагенты',
  'CATEGORIES': 'Продуктовая корзина',
  'PACKAGE_LINE_ITEM': 'Упаковки',
  'BRAND': 'Бренд',
  'UNIT_MEASUREMENT': 'Единица измерения',
  'ENABLE_EXPIRY_DATE_TRACKING': 'Отслеживание срока действия',
  'ENABLE_BATCH_NUMBERS': 'Номера пакетов',
  'MODIFIED_DATE': 'Дата изменения',
  'CREATED_DATE': 'Дата создания',
  'CREATED_BY': 'Сделано',
  'MODIFIED_BY': 'Модифицирован',
  'SELL_IN_PACKAGES': 'Продовать только в упаковке',
  'STATUS': 'Статус'
}

export const getTranslatedColumn = (code: string, name: string): string => {
  return translations[code] || name
}
