import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { DEFAULT_PAGE_SIZE, useGetList } from 'hooks/useGetList'
import { prop, propOr } from 'ramda'
import { useNavigate, useParams } from 'react-router-dom'
import useAllSearchParams from 'utils/useAllSearchParams'
import Content from 'components/container/Content'
import * as Yup from 'yup'
import { toNumber } from 'utils/formatMoney'
import { useGetDetail } from 'hooks/useGetDetail'

import PriceLevelLineItems from './form/PriceLevelLineItems'

import { exportPriceLevelExcel, getLineItemsByPriceId } from '../api'
import { priceLevelLineItemsInitialValuesSerializer } from '../serializer'

interface Props {
  readonly lineItems:any
  readonly setOpenImport:(value:boolean) => void
  readonly setLineItems:(lineItems:any) => void
}

interface LineItem {
  minPrice: number | string
  maxPrice: number | string
  value: number | string
  onDynamic: boolean
}

export const validationSchema = Yup.object({
  lineItemDTOs: Yup.array().of(
    Yup.object().shape({
      minPrice: Yup.string().required('Требуется минимальная цена'),
      maxPrice: Yup.string().required('Требуется максимальная цена'),
      value: Yup.string().required('Требуется значение'),
      onDynamic: Yup.boolean().required(),
    })
  ).test(
    'price-validation',
    'Validation failed',
    function (lineItems:LineItem[] | any) {
      for (let index = 0; index < lineItems.length; index++) {
        const { minPrice, maxPrice, value, onDynamic } = lineItems[index]
        const minPriceNumber = toNumber(minPrice)
        const maxPriceNumber = toNumber(maxPrice)
        const valueNumber = toNumber(value)
        if (onDynamic) {
          if (minPriceNumber > maxPriceNumber) {
            return this.createError({
              path: `lineItemDTOs[${index}].minPrice`,
              message: 'Мин. цена не должна превышать макс. цену.',
            })
          }
          if (minPriceNumber === maxPriceNumber) {
            return this.createError({
              path: `lineItemDTOs[${index}].minPrice`,
              message: 'Мин. и макс. цена не должны быть равными',
            })
          }
          if (valueNumber < minPriceNumber) {
            return this.createError({
              path: `lineItemDTOs[${index}].value`,
              message: 'Значение не должно быть меньше мин. цены.',
            })
          }
          if (valueNumber >= maxPriceNumber) {
            return this.createError({
              path: `lineItemDTOs[${index}].value`,
              message: 'Значение не должно превышать макс. цену.',
            })
          }
        } else {
          if (minPriceNumber !== maxPriceNumber || minPriceNumber !== valueNumber || maxPriceNumber !== valueNumber) {
            return false
          }
        }
      }
      return true
    }
  )
})

function ProductsPriceTable ({ lineItems, setLineItems, setOpenImport }:Props) {
  const navigate = useNavigate()
  const searchParams = useAllSearchParams()
  const { id } = useParams()

  const [lineItemsDTO, setLineItemsDTO] = useState([])
  const [editLineItemsView, setEditLineItemsView] = useState(false)
  const [size, setLimit] = useState(0)
  const [page, setStart] = useState(1)

  const getListQuery = { priceId : Number(id), size, page }
  const priceLevelGetLineItems = useGetList(getLineItemsByPriceId, { query: getListQuery })
  const productsPriceLevelExcel = useGetDetail(exportPriceLevelExcel, { query:{ priceId:id } })

  const handlePriceLevelLineItemsEdit = useCallback((values: any) => {
    setLineItems((prev: any) => ({ ...prev, [`page-${page}`]: values.lineItemDTOs }))
    setLineItemsDTO(values.lineItemDTOs)
    setEditLineItemsView(false)
  }, [page, lineItems])

  const onGetExcel = useCallback(() => {
    productsPriceLevelExcel.getDetail({ responseType: 'blob' })
      .then((response:any) => {
        // Convert response to blob
        const blob = new Blob([response])

        // Create a download link
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', `Products-Prices.xlsx`)
        document.body.appendChild(link)
        link.click()

        // Cleanup
        window.URL.revokeObjectURL(link.href)
      })
  }, [])

  const data = prop('result', priceLevelGetLineItems) as any
  const listGet = prop('getList', priceLevelGetLineItems) as any
  const loading = prop('loading', priceLevelGetLineItems) as boolean
  const totalItems = propOr(0, 'totalElements', data) as number
  const currentItemsCount = propOr(0, 'numberOfElements', data) as number
  const totalPages = propOr(0, 'totalPages', data) as number

  const nextPageEnable = page !== totalPages
  const previousPageEnabled = page !== 1

  useEffect(() => {
    const paramsLimit = prop('size', searchParams) || DEFAULT_PAGE_SIZE
    const paramsStart = prop('page', searchParams) || 1
    setLimit(Number(paramsLimit))
    setStart(Number(paramsStart))
  }, [searchParams])

  const itemsOfPage = useMemo(() => {
    if (page === 1) {
      return `1-${currentItemsCount}`
    } else if (page === totalPages) {
      return `${size * (page - 1)} - ${totalItems}`
    } else {
      return `${size * (page - 1)} - ${size * page}`
    }
  }, [currentItemsCount, size, totalPages, page])

  useEffect(() => {
    if (page && size) {
      listGet && listGet({ query: { size, page, priceId: id } })
    }
  }, [page, size])

  const handleClickPreviousPage = useCallback(() => {
    const previousPage = page - 1
    setStart(previousPage)
    navigate({ pathname: location.pathname, search: `?page=${previousPage}&size=${size}` })
  }, [page, size])

  const handleClickNextPage = useCallback(() => {
    const nextPage = page + 1
    setStart(nextPage)
    navigate({ pathname: location.pathname, search: `?page=${nextPage}&size=${size}` })
  }, [page, size])

  const tableInitialValues = useMemo(() => {
    if (lineItems[`page-${page}` as any]) {
      return { lineItemDTOs: lineItems[`page-${page}` as any] }
    } else {
      return priceLevelLineItemsInitialValuesSerializer(data)
    }
  }, [lineItems, data, page])

  return (
    <Content>
      <Grid container={true}>
        <Grid item={true} lg={12}>
          {!loading && (
            <PriceLevelLineItems
              onGetExcel={onGetExcel}
              setOpenImport={setOpenImport}
              lineItems={lineItemsDTO}
              editView={editLineItemsView}
              onSetEditView={(state: boolean) => setEditLineItemsView(state)}
              validationSchema={validationSchema}
              onSubmit={handlePriceLevelLineItemsEdit}
              nextPageEnable={nextPageEnable}
              previousPageEnabled={previousPageEnabled}
              handleClickPreviousPage={handleClickPreviousPage}
              handleClickNextPage={handleClickNextPage}
              start={page}
              totalItems={totalItems}
              itemsOfPage={itemsOfPage}
              initialValues={tableInitialValues}
            />
          )}
        </Grid>
      </Grid>
    </Content>
  )
}

export default ProductsPriceTable
