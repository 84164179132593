import React from 'react'
import { Sticky } from 'react-sticky'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'isSticky'
})(({ theme, isSticky }: any) => ({
  display:'flex',
  alignItems:'center',
  height:'60px',
  zIndex: 4,
  borderBottom:'2.5px solid #F1F1F1',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  ...(isSticky && {
    borderTop: `1.5px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  })
})) as any

function Header ({ children }: { children: any }) {
  return (
    <Sticky>
      {({ style, isSticky }) => (
        <RootStyled
          style={style}
          isSticky={isSticky}
        >
          {React.cloneElement(children, { isSticky })}
        </RootStyled>
      )}
    </Sticky>
  )
}

export default Header
