import React from 'react'
import { makeStyles } from '@mui/styles'

import AppHeader from './AppHeader'
import FlexBox from './FlexBox'

interface Props {
  readonly children: React.ReactNode
  readonly containerClass?: string
}

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh'
  },
  container: {
    flex: '1 1',
    overflow: 'auto',
    overflowX: 'hidden',
    background: '#FFFFFF',
    position: 'relative'
  }
})

function AppLayout ({ children, containerClass = '' }:Props) {
  const classes = useStyles()

  return (
    <FlexBox direction="column" className={classes.root}>
      <AppHeader />
      <div className={`${classes.container} ${containerClass}`}>
        {children}
      </div>
    </FlexBox>
  )
}

export default AppLayout
