import * as API from 'constants/Api'

import React from 'react'
import { prop } from 'ramda'
import SearchField from 'components/form/SearchField'
import { useField } from 'formik'
import useDeepCompareEffect from 'hooks/useDeepCompareEffect'
import { getRequest } from 'api/BaseApi'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value, name, id: value }
  }

  return null
}

const getTerritoryName = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('territoryName', option)

    return { value, name, id: value }
  }

  return null
}

function TerritoryField (props: any) {
  const [, meta, helpers] = useField(props.name)

  useDeepCompareEffect(() => {
    if (meta.initialValue) {
      getRequest(API.TerritoryGetDetail, { params: { id:meta.initialValue } }).then((response) => {
        helpers.setValue(getTerritoryName(response))
      })
    }
  }, [])

  return (
    <SearchField
      api={API.TerritorySuggestions}
      getOptionValue={getOptionValue}
      label="Территории"
      {...props}
    />
  )
}

export default TerritoryField
