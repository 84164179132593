import * as ROUTES from 'constants/Routes'

import AppLayout from 'components/main/AppLayout'
import Table from 'components/table/Table'
import { path, propOr, trim } from 'ramda'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useDelete } from 'hooks/useDelete'
import { useGetList } from 'hooks/useGetList'
import { useLocales } from 'hooks/useLocales'

import { deletePriceLevel, getPriceLevelList } from '../api'
import PriceLevelRowMenu from '../components/PriceLevelRowMenu'

const columns = [
  {
    headerName: 'Price level name',
    field: 'name',
  },
  {
    headerName: 'Territories',
    field: 'territories',
    renderCell: (item: any) =>
      item.territories && item.territories.length && item.territories.map((i: any) => i.name).join(', ')
  },
  {
    headerName: 'Created by',
    field: 'createdBy',
    renderCell: path(['createdBy', 'name'])
  },
  {
    headerName: 'Modified date',
    field: 'modifiedDate'
  }
]

function PriceLevelListContainer () {
  const { t } = useLocales('priceLevel')
  const navigate = useNavigate()
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const priceLevelList = useGetList(getPriceLevelList)
  const priceDelete = useDelete(deletePriceLevel)

  const handleDeleteProduct = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить ценовую политику ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        priceDelete
          .deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Ценовая политика успешно удалёна' }))
          .then(() => priceLevelList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    priceLevelList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title={t('Price level')}
        onAddClick={() => navigate(ROUTES.SETTINGS_PRICE_LEVEL_CREATE)}
        dataRequest={priceLevelList}
        columns={columns}
        onSearchClick={onTableSearch}
        translationModule="priceLevel"
        checkbox={true}
        actions={(row) => <PriceLevelRowMenu item={row} handleDelete={handleDeleteProduct} />}
      />
    </AppLayout>
  )
}

export default PriceLevelListContainer
