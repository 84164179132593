import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [{ value: true, name: 'Активный' }, { value: false, name: 'Не активный' }]

function BillingStatusField () {
  return (
    <SelectField
      name="active"
      label="Статус"
      options={OPTIONS}
      data-cy="status"
    />
  )
}

export default BillingStatusField
