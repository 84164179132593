import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import DateField from 'components/form/DateField'
import { useField } from 'formik'
import { path } from 'ramda'

import MultipleTerritoryField from './form/TerritoryField'
import UsersField from './form/UserField'
import ClientField from './form/ClientField'
import TaskReturnTypeField from './form/TypeField'
import TimeTypeField from './form/TimeTypeField'
import DataTypeField from './form/DataTypeField'
import DayOfWeeksField from './form/DayOfWeeksField'
import MonthSelectField from './form/MonthSelectField'
import YearlyRepeatTypeField from './form/YearlyRepeatTypeField'
import StatusField from './form/StatusField'
import DayOfWeekField from './form/DayOfWeekField'

function TaskReturnUpdateForm ({ form }:any) {
  const name = path(['values', 'name'], form)
  const reminderType = useField('timeReminder.type')
  const dataType = useField('timeReminder.dueDateType')
  const yearlyType = useField('timeReminder.yearlyRepeatType')
  const yearType = path([0, 'value'], yearlyType)
  const type = path([0, 'value'], reminderType)
  const dueType = path([0, 'value'], dataType)
  const isDay = yearType === 'DAY'
  const isUntil = dueType === 'UNTIL'
  const isAfter = dueType === 'AFTER'
  const isHourly = type === 'HOURLY'
  const isDaily = type === 'DAILY'
  const isEvenDaily = type === 'EVEN_DAILY'
  const isOddDaily = type === 'ODD_DAILY'
  const isWeekly = type === 'WEEKLY'
  const isMonthly = type === 'MONTHLY'
  const isYearly = type === 'YEARLY'
  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить (${name})`}
          rightButton={(
            <>
              <Button type="submit">Сохранить изменения</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label="Повторяющееся название таски"
                      name="name"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <UsersField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <ClientField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <MultipleTerritoryField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TaskReturnTypeField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <StatusField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TextField label="Лат." name="latitude" />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TextField label="Лон." name="longitude" />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Напоминание о времени" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TimeTypeField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={9} />
                  {(isHourly || isDaily) && (
                    <>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <TextField label="Интервал" name="timeReminder.interval" required />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <DateField label="Дата начала" name="timeReminder.startDate" required />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <DataTypeField label="Тип срока сдачи" name="timeReminder.dueDateType" required />
                      </Grid>
                      {isUntil && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <DateField label="Дата окончания" name="timeReminder.endDate" required />
                        </Grid>
                      )}
                      {isAfter && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <TextField label="Повторить счет" name="timeReminder.repeatCount" required />
                        </Grid>
                      )}
                    </>
                  )}
                  {(isEvenDaily || isOddDaily) && (
                    <>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <DateField label="Дата начала" name="timeReminder.startDate" required />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <DataTypeField label="Тип срока сдачи" name="timeReminder.dueDateType" required />
                      </Grid>
                      {isUntil && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <DateField label="Дата окончания" name="timeReminder.endDate" required />
                        </Grid>
                      )}
                      {isAfter && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <TextField label="Повторить счет" name="timeReminder.repeatCount" required />
                        </Grid>
                      )}
                    </>
                  )}
                  {isWeekly && (
                    <>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <DateField label="Дата начала" name="timeReminder.startDate" required />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <DayOfWeeksField label="Дни недели" name="timeReminder.dayOfWeeks" />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <DataTypeField label="Тип срока сдачи" name="timeReminder.dueDateType" required />
                      </Grid>
                      {isUntil && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <DateField label="Дата окончания" name="timeReminder.endDate" required />
                        </Grid>
                      )}
                      {isAfter && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <TextField label="Повторить счет" name="timeReminder.repeatCount" required />
                        </Grid>
                      )}
                    </>
                  )}
                  {isMonthly && (
                    <>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <TextField label="Интервал" name="timeReminder.interval" required />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <DateField label="Дата начала" name="timeReminder.startDate" required />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <TextField label="День" name="timeReminder.day" required />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <DataTypeField label="Тип срока сдачи" name="timeReminder.dueDateType" required />
                      </Grid>
                      {isUntil && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <DateField label="Дата окончания" name="timeReminder.endDate" required />
                        </Grid>
                      )}
                      {isAfter && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <TextField label="Повторить счет" name="timeReminder.repeatCount" required />
                        </Grid>
                      )}
                    </>
                  )}
                  {isYearly && (
                    <>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <DateField label="Дата начала" name="timeReminder.startDate" required />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <MonthSelectField label="Месяц" name="timeReminder.month" required />
                      </Grid>
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <YearlyRepeatTypeField
                          label="Тип ежегодного повторения"
                          name="timeReminder.yearlyRepeatType"
                          required
                        />
                      </Grid>
                      {isDay && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <TextField label="День" name="timeReminder.day" required />
                        </Grid>
                      )}
                      {!isDay && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <DayOfWeekField label="Дни недели" name="timeReminder.dayOfWeek" required />
                        </Grid>
                      )}
                      <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                        <DataTypeField label="Тип срока сдачи" name="timeReminder.dueDateType" required />
                      </Grid>
                      {isUntil && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <DateField label="Дата окончания" name="timeReminder.endDate" required />
                        </Grid>
                      )}
                      {isAfter && (
                        <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                          <TextField label="Повторить счет" name="timeReminder.repeatCount" required />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(TaskReturnUpdateForm)
