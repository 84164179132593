export const getTranslatedColumn = (code: string, name: string): string => {
  const translations: { [key: string]: string } = {
    NAME: 'Юр. наименование',
    SHORT_NAME: 'Короткое имя',
    PRIMARY_CONTACT_PERSON:'Данные основного контактного лица',
    CONTACT_PERSONS:'Контактные лица',
    ANTEX_ID: 'Антекс ID',
    TERRITORY: 'Территория',
    FINANCIAL_INFO:'Финансовая информация',
    ADDRESSES:'Адреса',
    IMAGES:'Изображений',
    ATTACHMENTS:'Вложения',
    INN: 'ИНН',
    WEBSITE: 'Веб-сайт',
    MFO: 'МФО',
    RS: 'РС',
    NOTE: 'Примечание',
    CURRENCY: 'Валюта',
    MODIFIED_DATE: 'Дата изменения',
    CREATED_DATE: 'Дата создания',
    CREATED_BY: 'Добавлено',
    MODIFIED_BY: 'Модифицирован',
    NUMBER:'Номер',
    CONTACT_PERSON_NAME:'Имя контактного лица',
    CONTACT_PERSON_TELEGRAM_ID:'Телеграмма контактного лица ID',
    CONTACT_PERSON_EMAIL:'Электронная почта контактного лица',
    CONTACT_PERSON_PHONE:'Телефон контактного лица',
    FINANCIAL_MFO:'Финансовый НФО',
    FINANCIAL_BANK_ACCOUNT_NAME:'Название счета в финансовом банке',
    FINANCIAL_BANK_ACCOUNT_NUMBER:'Номер финансового банковского счета',
  }

  return translations[code] || name
}
