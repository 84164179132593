import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getBonusList = (options?: Options) => {
  return getRequest<any>(API.BonusListing, options)
}

export const createBonus = (options?: Options) => {
  return postRequest<any>(API.BonusCreate, options)
}

export const updateBonus = (options?: Options) => {
  return putRequest<any>(API.BonusUpdate, options)
}

export const getBonusById = (options?: Options) => {
  return getRequest<any>(API.GetBonusById, options)
}

export const deleteBonus = (options?: Options) => {
  return deleteRequest<any>(API.BonusDelete, options)
}

export const getProductList = (options?: Options) => {
  return getRequest<any>(API.ProductList, options)
}

export const getAvailableFields = (options?:Options) => {
  return getRequest<any>(API.BonusAvailableFields, options)
}
