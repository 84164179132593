import React, { useCallback } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid, IconButton,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import TextField from 'components/form/TextField'
import CardHeader from 'components/card/CardHeader'
import { FieldArray, useField } from 'formik'
import { useTranslationI18n } from 'i18n/I18nContext'
import SkeletonList from 'components/skeleton/SkeletonList'
import { path, prop } from 'ramda'
import useDialog from 'hooks/useDialog'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { useGetList } from 'hooks/useGetList'
import DoubleFormatField from 'components/form/DoubleFormatField'

import CategoryAddDialog from '../CategoryAddDialog'
import { sharedContactCategoryList } from '../../api'

export const useStyles = makeStyles({
  tableRoot:{
    maxHeight:'460px',
    borderRadius:'7px',
    border:'1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      cursor:'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA'
    }
  },
})

export function CategoryLineItems () {
  const variantAddDialogue = useDialog()
  const { t } = useTranslationI18n()
  const packageList = useGetList(sharedContactCategoryList)
  const loading = prop('loading', packageList)
  const classes = useStyles()
  const lineItemsField = useField('items')
  const searchField = useField('search')
  const setSearchValue = searchField[2]
  const lineItems = lineItemsField[0].value || [] as any
  const lineItemsChange = lineItemsField[2].setValue
  const error = lineItemsField[1]

  const handleVariantsAdd = useCallback((packages: any) => {
    const lineItemPackageIds = new Set(lineItems.map((item: any) => path(['category', 'id'], item)))

    const uniquePackages = packages.filter((item: any) => {
      const packageId = path(['category', 'id'], item)
      return !lineItemPackageIds.has(packageId)
    })

    const otherLineItems = lineItems.filter((lineItem: any) => {
      return packages.find((item: any) => path(['category', 'id'], item) === path(['category', 'id'], lineItem))
    })

    lineItemsChange([...otherLineItems, ...uniquePackages])
    setSearchValue.setValue('')
    variantAddDialogue.handleClose()
  }, [])

  return (
    <Card>
      <CardHeader title="Категория контактов" />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={10}>
            <TextField name="browse" />
          </Grid>
          <Grid item={true} lg={2}>
            <Button
              fullWidth={true}
              onClick={() => variantAddDialogue.handleOpen()}
            >
              Посмотреть
            </Button>
          </Grid>
          {variantAddDialogue.open && (
            <CategoryAddDialog
              open={variantAddDialogue.open}
              onClose={variantAddDialogue.handleClose}
              onAddVariants={handleVariantsAdd}
              packageList={packageList}
            />
          )}
          <FieldArray name="items">
            {(fields: any) => {
              const renderLineItems = Boolean(!loading && lineItems.length)
              return (
                <Grid item={true} lg={12}>
                  <TableContainer className={classes.tableRoot}>
                    <Table size="small" sx={{ width: '100%' }} stickyHeader aria-label="sticky table">
                      {renderLineItems && (
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            <TableCell width="30%">{t('Название')}</TableCell>
                            <TableCell width="30%">{t('Сумма')}</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                      )}
                      <TableBody className={classes.tableBody}>
                        {loading && <SkeletonList columnCount={7} />}
                        {renderLineItems && lineItems.map((item: any, index: number) => {
                          const name = path(['category', 'name'], item)
                          return (
                            <TableRow key={index}>
                              <TableCell>{name}</TableCell>
                              <TableCell>
                                <DoubleFormatField name={`items[${index}].amount`} />
                              </TableCell>
                              <TableCell align="right">
                                <IconButton onClick={() => fields.remove(index)} size="large">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {error.error && <Typography color="error" ml={2} py={2}>{error.error}</Typography>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            }}
          </FieldArray>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default CategoryLineItems
