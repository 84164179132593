import * as Yup from 'yup'
export const sortingInitialValues = {
  type:'PRODUCT_LIST_PANEL',
  columns:[],
  roles:[],
  visibleTo:''
}

export const sortingValidationSchema = Yup.object().shape({
  visibleTo:Yup.string().required('Поле видимости обязательно')
})
