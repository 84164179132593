import React from 'react'
import { Box, Typography, styled } from '@mui/material'

const PasswordStrengthContainer = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  padding: '16px',
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  maxWidth: '360px',
  marginTop: '8px',
  right:'-380px',
  top:'-40px',
  '&>p':{
    fontSize:'15px',
    lineHeight:'18.15px',
    fontWeight:'500',
    marginBottom:'8px'
  }
}))

const StrengthBarContainer = styled(Box)(() => ({
  display: 'flex',
  marginBottom: '8px',
  gap:'9px',
  alignItems:'center'
}))

const StrengthBar = styled(Box)<{ color: string }>(({ color }) => ({
  height: '9px',
  width: '36px',
  backgroundColor: color,
  borderRadius: '4px',
  '&:not(:last-child)': {
    marginRight: '4px',
  },
}))

export const PasswordStrengthMeter = ({ password }: { password: string }) => {
  const getStrength = () => {
    let strength = 0
    if (password.length >= 8) strength += 1
    if (/[A-Z]/.test(password)) strength += 1
    if (/[a-z]/.test(password)) strength += 1
    if (/\d/.test(password)) strength += 1
    return strength
  }

  const strength = getStrength()

  const strengthColors = ['#DB8C2F', '#DB8C2F', '#DB8C2F', '#DB8C2F']
  const barColors = strengthColors.slice(0, strength).concat(Array(4 - strength).fill('#F0F0F0'))

  return (
    <PasswordStrengthContainer>
      <Typography variant="body2">
        Рекомендации
      </Typography>
      <StrengthBarContainer>
        {barColors.map((color, index) => (
          <StrengthBar key={index} color={color} />
        ))}
        <Typography variant="body2" color="textSecondary" sx={{
          fontSize:'13px',
          lineHeight:'20px',
          color:'#DB8C2F'
        }}>
          {strength === 0 ? 'Слабый' : strength === 4 ? 'Сильный' : 'Средний сложности'}
        </Typography>
      </StrengthBarContainer>
      <Typography variant="caption" color="textSecondary">
        Пароль должен содержать от 8 до 32 символов, включать
        хотя бы одну заглавную латинскую букву, одну строчную и одну цифру.
      </Typography>
    </PasswordStrengthContainer>
  )
}
