import React from 'react'
import { MenuItem } from '@mui/material'

interface Props{
  readonly onDeleteSelected:() => void
}
function TableActionsRow ({
  onDeleteSelected,
}:Props) {
  return (
    <>
      <MenuItem onClick={onDeleteSelected}>
        Удалить выбранное
      </MenuItem>
    </>
  )
}

export default TableActionsRow
