import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'

import WarehouseField from './form/WarehouseField'
import SupplierField from './form/SupplierField'
import MultipleTerritoryField from './form/MultipleTerritoryField'
import SaleOrderField from './form/SaleOrderField'
import LineItems from './form/LineItems'
import CurrencyField from './form/CurrencyField'
import PriceLevelField from './form/PriceLevelField'

interface Props {
  readonly productList:any
  readonly onComplete:(values:any) => void
  readonly onSave:(values:any) => void
  readonly form:any
}
function ReceiveCreateForm ({ productList, form, onComplete, onSave }:Props) {
  const handleSave = () => {
    onSave(form.values)
  }
  const handleComplete = () => {
    onComplete(form.values)
  }

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать приход"
          rightButton={(
            <>
              <Button type="button" onClick={handleComplete}>Завершить</Button>
              <Button type="button" onClick={handleSave}>Создать</Button>
            </>
          )}
        />
      </Header>

      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <MultipleTerritoryField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <SaleOrderField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <SupplierField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <WarehouseField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <PriceLevelField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CurrencyField />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DateField name="expectedDate" label="Дата ожидания" />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DateField name="receivedDate" label="Дата получения" />
                  </Grid>
                  <Grid item={true} lg={4} >
                    <TextField name="notes" label="Примечания" multiline={true} minRows={4} />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems productList={productList} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(ReceiveCreateForm)
