import { makeStyles } from '@mui/styles'

export const tableAttachStyles = makeStyles({
  tableWrapper:{
    maxHeight:'calc(100vh - 120px)',
    border:'1px solid #F1F1F1',
    padding:'6px 10px'
  },
  tableRoot: {
    border:'1px solid #f1f1f1',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px', /* Adjust the width as needed */
      color:'#F1F1F1',
      cursor:'pointer'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#7C7C7C', /* Color of the thumb */
      borderRadius:'4px', /* Border radius of the thumb */
    },
    '&::-webkit-scrollbar-horizontal': {
      height: '8px', /* Height of the horizontal scrollbar */
    }
  },
  tableHeader: {
    background: '#F1F1F1',
    '& .MuiTableCell-root': {
      padding:'15px 5px',
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset'
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      transition: '0.23s',
      height:'46px',
      padding: '0 5px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020',
      '&.selected': {
        background: '#F6F8FD!important'
      }
    },
    '& .MuiTableRow-root': {
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA',
      '& .MuiTableCell-root': {
        background: '#FAFAFA !important'
      }
    }
  },
  checkboxHeader:{
    color: '#202020',
    width: '18px',
    height: '18px',
    '&.Mui-checked': {
      color: '#202020',
    }
  },
  checkbox: {
    width: '18px',
    height: '18px',
    color: '#7c7c7c',
    '&.Mui-checked': {
      color: '#202020',
    }
  },
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight:'12.1px',
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    }
  },
  tableBarRoot: {
    background: '#fff',
    height:'58px'
  },

  addButtonRoot: {
    fontSize: '18px',
    lineHeight: '22px',
    color: '#202020',
  },
  addButton: {
    cursor: 'pointer'
  },
  paginationRoot: {
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: 500
    }
  },
  paginationButton: {
    cursor: 'pointer'
  },
  selectedCount: {
    width: '28px',
    height: '21px',
    borderRadius: '10px',
    background: '#7C7C7C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    margin: 'auto',
    transition: '0.3s',
    '&.active': {
      background: '#202020'
    }
  },
  searchBox: {
    width:'100%',
    '& form':{
      width:'100%'
    }
  },
  searchInput: {
    '& .MuiButtonBase-root':{
      height:'100%',
      position:'relative'
    },
    '& .MuiOutlinedInput-root .MuiInputBase-input':{
      outline:'none',
      border:'none'
    },
    '& .MuiOutlinedInput-root fieldset':{
      outline:'none',
      border:'none'
    }
  }
})
