import React, { useEffect, useState } from 'react'
import DateField from 'components/form/DateField'
import { useField } from 'formik'
import dayjs from 'dayjs'

export const RenewableDateField = (props:any) => {
  const [activationDate] = useField('activationDate')
  const [, meta, helpers] = useField(props.name)
  const [renewableDate, setRenewableDate] = useState(null) as any

  useEffect(() => {
    if (meta.initialValue) {
      helpers.setValue(meta.initialValue)
    }
  }, [])

  useEffect(() => {
    const calculateRenewalDate = () => {
      if (!activationDate.value || !dayjs(activationDate.value).isValid()) {
        setRenewableDate('')
        return
      }

      const activation = dayjs(activationDate.value)
      let renewal = activation.add(1, 'month')

      if (activation.date() > renewal.date()) {
        renewal = renewal.endOf('month')
      }

      setRenewableDate(renewal.format('YYYY-MM-DD'))
    }

    calculateRenewalDate()
  }, [activationDate.value])

  useEffect(() => {
    if (renewableDate) {
      helpers.setValue(renewableDate)
    }
  }, [renewableDate])

  return (
    <DateField
      {...props}
    />
  )
}
