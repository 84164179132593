import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'
import { useUser } from 'context/user'
import { useGetList } from 'hooks/useGetList'

import { validationSchema } from './TerritoryCreateContainer'

import {
  getPriceLevelList,
  getTerritoryDetail,
  saveTerritoryPriceLevel,
  territoryUpdate,
  getContactList,
  saveTerritoryContacts,
  getCategoryList,
  saveTerritoryCategories,
  getTerritoryPriceLevel,
  getTerritoryContact,
  getTerritoryCategory
} from '../api'
import { detailSerializer, territorySerialize } from '../serializer'
import TerritoryUpdateForm from '../components/TerritoryUpdateForm'

function TerritoryUpdateContainer () {
  const { user } = useUser()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()

  const updateTerritory = usePut(territoryUpdate)
  const territoryDetail = useGetDetail(getTerritoryDetail, { params: { id } })
  const territoryDetailSerializer = detailSerializer(territoryDetail.result)
  const priceLevelList = useGetList(getPriceLevelList)
  const priceLevelSave = usePut(saveTerritoryPriceLevel)
  const territoryPriceLevel = useGetList(getTerritoryPriceLevel, { query: { territoryId: id } })
  const contactList = useGetList(getContactList)
  const contactSave = usePut(saveTerritoryContacts)
  const territoryContacts = useGetList(getTerritoryContact, { params: { id } })
  const categoryList = useGetList(getCategoryList)
  const categorySave = usePut(saveTerritoryCategories)
  const territoryCategory = useGetList(getTerritoryCategory, { query: { territoryId: id } })

  useEffect(() => {
    territoryPriceLevel.getList({ query: { limit: 1000 } })
    territoryCategory.getList({ query: { limit: 1000 } })
    territoryContacts.getList({ query: { limit: 1000 } })
  }, [])

  const handleSubmit = useCallback((values: any) => {
    return updateTerritory.putData({ data: territorySerialize(values, user) })
      .then(() => snackbar({ message: 'Территория успешно обновлена.' }))
      .then(() => navigate(ROUTES.SETTINGS_TERRITORY_LIST))
  }, [])

  return (
    <AppLayout>
      {!territoryDetail.loading && (
        <TerritoryUpdateForm
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={territoryDetailSerializer}
          enableReinitialize={true}
          priceLevelList={priceLevelList}
          priceLevelSave={priceLevelSave}
          contactList={contactList}
          contactSave={contactSave}
          categoryList={categoryList}
          categorySave={categorySave}
          territoryPriceLevel={territoryPriceLevel}
          territoryContacts={territoryContacts}
          territoryCategory={territoryCategory}
        />
      )}
      {territoryDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default TerritoryUpdateContainer
