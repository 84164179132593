import React from 'react'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext, rectSwappingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Typography from '@mui/material/Typography'

const translations: { [key: string]: string } = {
  'NAME': 'Наименование товара',
  'SHORT_NAME': 'Короткое наименование',
  'SORT_NUMBER': 'Порядковый номер',
  'PIECES': 'Штук',
  'CODE': 'Штрих-код',
  'PRODUCED_IN': 'Страна',
  'DESCRIPTION': 'Описание',
  'SKU': 'Артикул',
  'WEIGHT': 'Вес нетто',
  'WEIGHT_BRUTTO': 'Вес брутто',
  'HEIGHT': 'Высота',
  'PRODUCT_TYPE': 'Тип продукта',
  'SUPPLIERS': 'Контрагенты',
  'CATEGORIES': 'Продуктовая корзина',
  'PACKAGE_LINE_ITEM': 'Упаковки',
  'BRAND': 'Бренд',
  'UNIT_MEASUREMENT': 'Единица измерения',
  'ENABLE_EXPIRY_DATE_TRACKING': 'Отслеживание срока действия',
  'ENABLE_BATCH_NUMBERS': 'Номера пакетов',
  'MODIFIED_DATE': 'Дата изменения',
  'CREATED_DATE': 'Дата создания',
  'CREATED_BY': 'Сделано',
  'MODIFIED_BY': 'Модифицирован',
  'SELL_IN_PACKAGES': 'Продовать только в упаковке',
  'STATUS': 'Статус'
}

const getTranslatedColumn = (code: string, name: string): string => {
  return translations[code] || name
}

export const RectDndItem = ({ code, text, isDragging = false, isOverlay = false }: any) => {
  return (
    <div
      style={{
        padding: '10px',
        border: '1px solid #7888f5',
        borderRadius: '5px',
        margin: '5px',
        background: isDragging ? 'white' : isOverlay ? '#ffb8b8' : '#b8c1ff',
        width:'285px',
        overflow: 'hidden',
        cursor: 'pointer'
      }}
    >
      <Typography variant="subtitle2">
        {getTranslatedColumn(code, text)}
      </Typography>
    </div>
  )
}

const SortableItem = ({ id, code, text }: any) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <RectDndItem
        code={code}
        text={text}
        isDragging={isDragging}
      />
    </div>
  )
}

const DndContainer = ({ items = [], id }: any) => {
  const { setNodeRef } = useDroppable({
    id: id,
  })

  return (
    <div style={{ paddingTop: '50px' }}>
      <SortableContext
        items={items}
        strategy={rectSwappingStrategy}
        id={id}
      >
        <div
          ref={setNodeRef}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            background: '#ededed',
            minHeight: '100px',
            gap:'10px',
            padding:'10px'
          }}
        >
          {items.map(({ id, name, code }: any) => (
            <SortableItem
              key={id}
              id={id}
              text={name}
              code={code}
            />
          ))}
        </div>
      </SortableContext>
    </div>
  )
}

export default DndContainer
