import * as API from 'constants/Api'

import React from 'react'
import SearchField from 'components/form/SearchField'
import { path, prop } from 'ramda'
import { useField } from 'formik'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)
    const currencyId = path(['currency', 'id'], option)

    return { value, name, id: value, currencyId }
  }

  return null
}

function PriceLevelField (props: any) {
  const territory = useField('territoryId')
  const territoryId = path(['0', 'value', 'id'], territory)
  return (
    <SearchField
      disabled={!territoryId}
      name="priceLevelId"
      api={API.PriceLevelSuggestionsList}
      getOptionValue={getOptionValue}
      params={{ territoryId }}
      label="Ценовая политика"
      {...props}
    />
  )
}

export default PriceLevelField
