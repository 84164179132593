import * as ROUTES from 'constants/Routes'

import { path, propOr } from 'ramda'
import React, { useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { useConfirm } from 'context/confirm'

import { documentDelete, getDocumentList } from '../api'
import DocumentRowMenu from '../components/DocumentRowMenu'
import { DocumentLinkAndName } from '../components/DocumentLinkAndName'
import DocumentStatusRow from '../components/DocumentStatusRow'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 150
  },
  {
    headerName: 'Название документа',
    field: 'name',
    renderCell:(item:any) => <DocumentLinkAndName item={item} />
  },
  {
    headerName: 'Путь',
    field: 'folder',
    renderCell: (item:any) => (
      <Link to={(`${path(['attachment', 'link'], item)}`)}>
        {path(['attachment', 'link'], item)}
      </Link>)
  },
  {
    headerName: 'Тип документа',
    field: 'documentType',
    renderCell:(item:any) => <DocumentStatusRow status={item.documentType} />
  }
]

function DocumentListContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const onConfirm = useConfirm()
  const navigate = useNavigate()
  const documentList = useGetList(getDocumentList, { query:{ folderId:id } })
  const deleteDocument = useDelete(documentDelete)

  const onDeleteDocument = useCallback((values: any) => {
    const message = `Вы уверены, что хотите удалить документ ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        deleteDocument.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Документ успешно удален' }))
          .then(() => documentList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = propOr('', 'search', value)
    documentList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Документы"
        dataRequest={documentList}
        onAddClick={() => navigate(ROUTES.SETTINGS_MEDIA_FILES_DOCUMENT_CREATE, { state:{ id } })}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row) => (
          <DocumentRowMenu
            item={row}
            onDelete={onDeleteDocument}
          />
        )}
      />
    </AppLayout>
  )
}

export default DocumentListContainer
