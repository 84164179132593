import * as Yup from 'yup'

export const filterValidationSchema = Yup.object().shape({
  criteria: Yup.array().of(
    Yup.object().shape({
      comparator: Yup.string().when('value', (value, schema) =>
        value ? schema.required('Требуется компаратор') : schema
      ),
      code: Yup.string().required('Code is required'),
      value: Yup.string().required('Требуестя поле'),
    })
  ).required('Criteria is required').min(1, 'At least one criterion is required'),
})
