import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'NEVER', name: 'НИКОГДА' },
  { value: 'AFTER', name: 'ПОСЛЕ' },
  { value: 'UNTIL', name: 'НЕ РАНЬШЕ' }
]

function DataTypeField (props:any) {
  return (
    <SelectField
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default DataTypeField
