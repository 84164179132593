import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { getBillingDetail, updateBilling } from '../api'
import BillingPlanUpdateForm from '../components/BillingPlanUpdateForm'
import { billingCreateSerializer, billingInitialSerializer } from '../serializers'

function BillingPlanUpdateContainer () {
  const snackbar = useSnackbar()
  const { id } = useParams()
  const navigate = useNavigate()
  const billingPlanDetail = useGetDetail(getBillingDetail, { params: { id } })
  const billingPlanUpdate = usePut(updateBilling)

  const handleSubmit = useCallback((values: any) => {
    return billingPlanUpdate.putData({ data: billingCreateSerializer(values) })
      .then(() => snackbar({ message: 'Тарифный план успешно обновлён' }))
      .then(() => navigate(ROUTES.BACKEND_BILLING_LIST))
  }, [])

  const initialValues = useMemo(() => billingInitialSerializer(billingPlanDetail.result), [billingPlanDetail])

  return (
    <AppLayout>
      {!billingPlanDetail.loading && (
        <BillingPlanUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        />
      )}
      {billingPlanDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default BillingPlanUpdateContainer
