import * as ROUTES from 'constants/Routes'

import React from 'react'
import Box from '@mui/material/Box'
import { prop } from 'ramda'
import { Link } from 'react-router-dom'
import FlexBox from 'components/main/FlexBox'
import { pathParams } from 'path-params'
import FolderIcon from 'assets/folder-icon.svg'

export const FolderNameList = ({ item }:any) => {
  const name = prop('name', item)
  const updateUrl = pathParams(ROUTES.SETTINGS_MEDIA_FILES_DETAIL, { id: item.id })

  return (
    <Link to={updateUrl}>
      <FlexBox
        align="center"
        gap={10}
      >
        <Box sx={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          width: '28px',
          height: '28px',
        }}
        >
          <img src={FolderIcon} alt="image" style={{ width:'100', height:'100%' }} />
        </Box>
        {name}
      </FlexBox>
    </Link>
  )
}
