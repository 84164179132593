import * as API from 'constants/Api'

import { getRequest, Options, postRequest } from 'api/BaseApi'

import { SignInResponse } from '../../auth/api'

export const contactList = (options?:Options, isPrivate?:boolean) => {
  return getRequest<any>(API.ContactList, options, isPrivate)
}

export const signInApi = (options?: Options) => {
  return postRequest<SignInResponse>(API.SignIn, options)
}

export const getCustomFields = (options?: Options) => {
  return getRequest<any>(API.CustomFieldsGetByFormType, options)
}

export const getContactByAntexId = (options?:Options) => {
  return getRequest<any>(API.ContactGetByAntexId, options)
}
