import * as API from 'constants/Api'
import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import MultiSelectSearchField from 'components/form/MultiSelectSearchField'
import { prop } from 'ramda'
import useDialog from 'hooks/useDialog'
import { useGetList } from 'hooks/useGetList'
import { useField } from 'formik'

import MultipleCategoryAddDialog from './MultipleCategoryAddDialog'

import { getCategorySuggestions } from '../../api'

const getOptionValue = (option: any) => {
  if (option) {
    const id = prop('id', option)
    const name = prop('name', option)

    return { value: id, name, id }
  }

  return null
}

function CategoryField (props: any) {
  const tableDialog = useDialog()
  const tableRequest = useGetList(getCategorySuggestions)
  const [field, , helpers] = useField('categoryIds')
  const handleVariantsAdd = useCallback((packages: any) => {
    const lineItemPackageIds = new Set(field.value.map((item: any) => prop('id', item)))

    const uniquePackages = packages.filter((item: any) => {
      const packageId = prop('id', item)
      return !lineItemPackageIds.has(packageId)
    })

    const otherLineItems = field.value.filter((lineItem: any) => {
      return packages.find((item: any) => prop('id', item) === prop('id', lineItem))
    })

    helpers.setValue([...otherLineItems, ...uniquePackages])
    // setSearchValue.setValue('')
    tableDialog.handleClose()
  }, [])
  return (
    <>
      <MultiSelectSearchField
        name="categoryIds"
        onAddRoute={ROUTES.CATALOG_CATEGORY_CREATE}
        api={API.CategorySuggestionList}
        onOpenDialog={tableDialog.handleOpen}
        getOptionValue={getOptionValue}
        label={props.label}
        searchText={'searchKey'}
        {...props}
      />
      {tableDialog.open && (
        <MultipleCategoryAddDialog
          name="categoryIds"
          packageList={tableRequest}
          onAddVariants={handleVariantsAdd}
          open={tableDialog.open}
          onClose={tableDialog.handleClose}
        />
      )}
    </>

  )
}

export default CategoryField
