import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import withForm from 'components/form/withForm'
import { IconButton } from '@mui/material'
import cx from 'classnames'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

import SidebarRightSide from './SidebarRightSide'
import SidebarLeftSide from './SidebarLeftSide'
import MapContent from './MapContent'

const useStyles = makeStyles({
  wrapper:{
    display:'flex',
    alignItems:'flex-start',
    gap:'7px',
    width:'100%'
  },
  sidebarLeftContent:{
    position:'relative',
    maxWidth:'380px',
    width:'100%',
    '&.leftSideExpand':{
      maxWidth:'10px',
    }
  },
  mapContent:{
    padding:'10px 0',
    width:'100%',
    height:'calc(100vh - 64px)',
  },
  sidebarRightContent:{
    position:'relative',
    maxWidth:'400px',
    width:'100%',
    '&.rightSideExpand':{
      maxWidth:'10px',
    }
  },
  absoluteIconButton: {
    padding:'0',
    background:'#f1f1f1',
    position: 'absolute',
    top: '50%',
    right:'-10px',
    transition: '0.3s ease',
    '&.leftSideExpand':{
      transform: 'rotate(-180deg)'
    }
  },
  absoluteIconButtonRight: {
    padding:'0',
    background:'#f1f1f1',
    position: 'absolute',
    top: '50%',
    left:'-10px',
    transform:'rotate(-180deg)',
    transition: '0.3s ease',
    '&.rightSideExpand':{
      transform: 'rotate(0deg)'
    }
  },
})

interface Props {
  readonly listTracking:any
  readonly taskListing:any
  readonly contactListTracking:any
}

function TrackingListForm ({ listTracking, taskListing, contactListTracking }:Props) {
  const classes = useStyles()
  const [users, setUsers] = useState('')
  const [leftSideIsExpanded, setLeftSideIsExpanded] = useState(false)
  const [rightSideIsExpanded, setRightSideIsExpanded] = useState(false)
  const [selectedLotLan, setSelectedLotLan] = useState({ date:0, position:{ lat:0, lng:0 } })
  const onExpandLeftSide = () => {
    setLeftSideIsExpanded(prevState => !prevState)
  }

  const onExpandRightSide = () => {
    setRightSideIsExpanded(prevState => !prevState)
  }

  return (
    <div className={classes.wrapper}>
      <div className={cx(classes.sidebarLeftContent, { 'leftSideExpand': leftSideIsExpanded })}>
        <SidebarLeftSide
          setUser={setUsers}
        />
        <IconButton
          type="button"
          onClick={onExpandLeftSide}
          className={cx(classes.absoluteIconButton, { 'leftSideExpand':leftSideIsExpanded })}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
      </div>
      <div className={classes.mapContent}>
        <MapContent
          taskListing={taskListing}
          listTracking={listTracking}
          selectedLotLan={selectedLotLan}
          setSelectedLotLan={setSelectedLotLan}
          contactListTracking={contactListTracking}
        />
      </div>
      {users && (
        <div className={cx(classes.sidebarRightContent, { 'rightSideExpand': rightSideIsExpanded })}>
          <SidebarRightSide
            users={users}
            listTracking={listTracking}
            selectedLotLan={selectedLotLan}
            setSelectedLotLan={setSelectedLotLan}
          />
          <IconButton
            type="button"
            onClick={onExpandRightSide}
            className={cx(classes.absoluteIconButtonRight, { 'rightSideExpand':rightSideIsExpanded })}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default withForm(TrackingListForm)
