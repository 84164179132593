import { makeStyles } from '@mui/styles'

export const columnsSettingStyles = makeStyles({
  selectedCount: {
    width: '28px',
    height: '21px',
    borderRadius: '10px',
    background: '#7C7C7C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    margin: 'auto',
    transition: '0.3s',
    '&.active': {
      background: '#202020'
    }
  },
  checkboxHeader:{
    color: '#202020',
    width: '18px',
    height: '18px',
    '&.Mui-checked': {
      color: '#202020',
    }
  },
  checkboxColumn: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    maxWidth: '45px',
    padding: '10px 5px',
    width: '45px',
    minWidth: '45px',
    textAlign: 'right'
  }
})
