import React, { useEffect } from 'react'
import JsBarcode from 'jsbarcode'
import Barcode from 'react-barcode'
import Box from '@mui/material/Box'
import { prop, propOr } from 'ramda'

interface Props {
  readonly barcodeList: any
}

const BarcodePrint = React.forwardRef(({ barcodeList }: Props, ref: any) => {
  useEffect(() => {
    JsBarcode('.barcode').init()
  }, [barcodeList])

  return (
    <div ref={ref} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {barcodeList.reverse().map((item: any, index: number) => {
        return (
          <Box key={index} mb={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Barcode value={prop('barcode', item)} width={4} />
            <span>{propOr('', 'name', item)}</span>
          </Box>
        )
      })}
    </div>
  )
})

export default BarcodePrint
