import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getWarehouseList = (options?: Options) => {
  return getRequest<any>(API.WarehouseList, options)
}

export const getWarehouseDetail = (options?: Options) => {
  return getRequest<any>(API.WarehouseDetail, options)
}

export const createWarehouse = (options?: Options) => {
  return postRequest<any>(API.WarehouseCreate, options)
}

export const updateWarehouse = (options?: Options) => {
  return putRequest<any>(API.WarehouseUpdate, options)
}

export const deleteWarehouse = (options?: Options) => {
  return deleteRequest<any>(API.WarehouseDelete, options)
}

export const statusWarehouse = (options?: Options) => {
  return patchRequest<any>(API.WarehouseStatus, options)
}

export const countWarehouse = (options?: Options) => {
  return getRequest<any>(API.WarehouseCount, options)
}

export const warehouseAddAssignee = (options?: Options) => {
  return postRequest<any>(API.WarehouseAddAssignee, options)
}

export const warehouseImportExcel = (options?:Options) => {
  return postRequest<any>(API.WarehouseImport, options)
}
