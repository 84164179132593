import React, { ReactNode, useCallback, useContext, useState } from 'react'
import { pathOr } from 'ramda'

import Context from './context'

type TabItem = {
  id: string;
  tabId: number;
}

export interface UserRoutesProps {
  readonly routes?:TabItem[]
}

const getUserFromStorage = () => {
  const storageUser = localStorage.getItem('antex-user-routes')
  if (storageUser) {
    return JSON.parse(storageUser) as UserRoutesProps
  } else return { routes: [{}] as TabItem[] }
}

const initialState = {
  routes: getUserFromStorage() as UserRoutesProps,
}

export const useRoutes = () => {
  const { setRoutes, routes, isHasActiveTab, changeExactRoute } = useContext(Context) as any
  return {
    setRoutes,
    routes,
    isHasActiveTab,
    changeExactRoute
  } as {
    routes: UserRoutesProps,
    setRoutes: (values: UserRoutesProps) => void,
    isHasActiveTab:(value?:string) => {id:string, tabId:number},
    changeExactRoute:(id?:string, tabId?:number) => void
  }
}

export function Provider ({ children }: { children: ReactNode }) {
  const [state, setState] = useState(initialState)
  const routes = pathOr([], ['routes', 'routes'], state)
  const setRoutes = useCallback((values: UserRoutesProps) => {
    localStorage.setItem('antex-user-routes', JSON.stringify(values))
    setState({ routes: values })
  }, [])

  const isHasActiveTab = useCallback((currentListing:any) => {
    if (routes.length > 0) {
      return routes.find((item:any) => item.id === currentListing)
    } return null
  }, [routes])

  const changeExactRoute = useCallback((id: string, tabId: number) => {
    let updated = false
    const updatedRoutes = routes.map((item: TabItem) => {
      if (item.id === id) {
        updated = true
        return { ...item, tabId }
      }
      return item
    })

    if (!updated) {
      updatedRoutes.push({ id, tabId })
    }

    setRoutes({ routes: updatedRoutes })
  }, [routes, setRoutes])

  return (
    <Context.Provider value={{ routes: state.routes, setRoutes, isHasActiveTab, changeExactRoute }}>
      {children}
    </Context.Provider>
  )
}
export default Provider
