import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'

import { createConsignment } from '../api'
import { consignmentCreateSerializer } from '../serializers'
import ConsignmentCreateForm from '../components/ConsignmentCreateForm'
import { consignmentInitialValues, consignmentValidationSchema } from '../initial-form-values'

function ConsignmentCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const consignmentCreate = usePost(createConsignment)

  const onSubmit = useCallback((values: any) => {
    consignmentCreate.postData({ data: consignmentCreateSerializer(values) })
      .then(() => navigate(ROUTES.FINANCE_CONSIGNMENT_LIST))
      .then(() => snackbar({ message: 'Консигнация успешно создана' }))
  }, [])

  return (
    <AppLayout>
      <ConsignmentCreateForm
        onSubmit={onSubmit}
        initialValues={consignmentInitialValues}
        validationSchema={consignmentValidationSchema}
      />
    </AppLayout>
  )
}

export default ConsignmentCreateContainer
