import React, { useCallback } from 'react'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useField } from 'formik'
import useDialog from 'hooks/useDialog'

import ParametersAddDialog from './filter-popups/ParametersAddDialog'

const useStyles = makeStyles({
  item: {
    padding: '5px',
  },
  button: {
    textTransform: 'initial',
    display: 'flex',
  },
})

interface Props {
  readonly filterList: any
}

export const FilterParameters = ({ filterList }: Props) => {
  const parametersDialog = useDialog()
  const [field, , helpers] = useField('criteria')
  const classes = useStyles()

  const handleVariantsAdd = useCallback((packages: any) => {
    const fieldValue = field.value || []
    const addedPackages = [...fieldValue, ...packages]
    helpers.setValue(addedPackages)
    parametersDialog.handleClose()
  }, [field.value, helpers, parametersDialog])

  const onOpenDialog = () => {
    parametersDialog.handleOpen()
  }

  return (
    <React.Fragment>
      <Button
        onClick={onOpenDialog}
        variant="outlined"
        color="secondary"
        className={classes.button}
      >
        Добавить параметры
      </Button>
      {parametersDialog.open && (
        <ParametersAddDialog
          open={parametersDialog.open}
          onClose={parametersDialog.handleClose}
          onAddVariants={handleVariantsAdd}
          packageList={filterList}
        />
      )}
    </React.Fragment>
  )
}
