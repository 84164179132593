import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { useDelete } from 'hooks/useDelete'
import { usePut } from 'hooks/usePut'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { path, propOr, trim } from 'ramda'
import { useNavigate } from 'react-router-dom'

import { completeReceive, deleteReceive, getReceiveList } from '../api'
import ReceiveRowMenu from '../components/ReceiveRowMenu'
import ReceiveStatusRow from '../components/ReceiveStatusRow'

const columns = [
  {
    headerName: 'Номер получения',
    field: 'number'
  },
  {
    headerName: 'Поставщик',
    field: 'supplier',
    renderCell: path(['supplier', 'name'])
  },
  {
    headerName: 'Ожидаемая дата',
    field: 'expectedDate'
  },
  {
    headerName: 'Дата получения',
    field: 'receivedDate'
  },
  {
    headerName: 'Склад',
    field: 'warehouse',
    renderCell: path(['warehouse', 'name'])
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (item: any) => <ReceiveStatusRow row={item} />
  }
]

function ReceiveListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const receiveList = useGetList(getReceiveList)
  const receiveDelete = useDelete(deleteReceive)
  const receiveComplete = usePut(completeReceive)

  const onDeleteReceive = useCallback((id: any) => {
    const message = `Вы уверены что хотите удалить получение ${id}`

    onConfirm({ message })
      .agree(() => {
        receiveDelete.deleteData({ params: { id } })
          .then(() => snackbar({ message: 'Приход успешно удалён' }))
          .then(() => receiveList.getList())
      })
      .disagree()
  }, [])

  const onCompleteReceive = useCallback((id: number) => {
    return receiveComplete.putData({ params: { id } })
      .then(() => snackbar({ message: 'Приход успешно завершён' }))
      .then(() => receiveList.getList())
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    receiveList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Приход"
        onAddClick={() => navigate(ROUTES.WAREHOUSE_RECEIVE_CREATE)}
        dataRequest={receiveList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row, index) => (
          <ReceiveRowMenu
            handleDelete={onDeleteReceive}
            handleComplete={onCompleteReceive}
            row={row}
            index={index}
          />
        )}
      />
    </AppLayout>
  )
}

export default ReceiveListContainer
