import * as ROUTES from 'constants/Routes'

import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import MUIDivider from '@mui/material/Divider'
import Tabs from 'components/main/Tabs'

export enum BILLING_TABS {
  GENERAL = 'general',
  CONTACT_TABLE = 'contact',
  COLUMNS = 'column',
  MODULES = 'modules'
}

const tabs = [
  { value: BILLING_TABS.GENERAL, label: 'Детали' },
  { value: BILLING_TABS.CONTACT_TABLE, label: 'Категории контакты' },
  { value: BILLING_TABS.COLUMNS, label:'Колонки' },
  { value: BILLING_TABS.MODULES, label:'Модули' }
]

interface Props {
  readonly value: string
}

function BillingTabs ({ value }: Props) {
  const { id } = useParams()

  const navigate = useNavigate()

  const onChange = (event: any, tab: any) => {
    const redirect = generatePath(ROUTES.BACKEND_BILLING_UPDATE, { id, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />

      <MUIDivider sx={{ mb: 3 }} />
    </>
  )
}

export default BillingTabs
