import * as API from 'constants/Api'

import React from 'react'
import MultiSelectSearchField from 'components/form/MultiSelectSearchField'
import { prop } from 'ramda'

const getOptionValue = (value:any) => {
  if (value) {
    const id = prop('id', value)
    const name = prop('name', value)

    return { value: id, name, id }
  }

  return null
}

function MultipleUsersField (props: any) {
  return (
    <MultiSelectSearchField
      name="clients"
      api={API.ContactSuggestions}
      getOptionValue={getOptionValue}
      label="Клиент"
      searchText="searchKey"
      {...props}
    />
  )
}

export default MultipleUsersField
