import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useNavigate } from 'react-router-dom'
import { path, pathOr, prop, propOr, trim } from 'ramda'
import { useSnackbar } from 'context/snackbar'
import { useConfirm } from 'context/confirm'
import { useDelete } from 'hooks/useDelete'
import { useGetList } from 'hooks/useGetList'
import { useGetDetailWithoutCall } from 'hooks/useGetDetailWithoutCall'
import { usePut } from 'hooks/usePut'
import useDialog from 'hooks/useDialog'
import { useRoutes } from 'context/route'

import VisitRowMenu from '../components/VisitRowMenu'
import VisitStatusRow from '../components/VisitStatusRow'
import Filter from '../components/filter/Filter'
import { getColumnsBySetting } from '../components/VisitListFilteredColumn'
import ColumnSettingList from '../components/column-settings/ColumnSettingList'
import TabsList from '../components/tabs/TabsList'
import {
  deleteVisit,
  getColumnsList,
  getFilterList,
  getVisitList,
  getByIdTab,
  changeVisitStatus,
  listingCounts
} from '../api'
import ChangeStatusDialogue from '../components/ChangeStatusDialogue'
import { changeStatusSerializer } from '../serializer'
import MoreActionButtons from '../components/MoreRowMenu'
import SortingList from '../components/sorting/SortingList'
import { sortingList } from '../components/sorting/api'

const columns = [
  {
    headerName: 'Название посещение',
    field: 'name'
  },
  {
    headerName: 'Запланированная дата',
    field: 'plannedDate'
  },
  {
    headerName: 'Пользователь',
    field: 'user',
    renderCell:path(['user', 'name'])
  },
  {
    headerName: 'Клиент',
    field: 'customer',
    renderCell:path(['customer', 'name'])
  },
  {
    headerName: 'Дата посешения',
    field: 'actualDate'
  },
  {
    headerName: 'Територия',
    field: 'territories',
    renderCell: (item: any) => pathOr('', ['territory', 'name'], item)
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell:(item:any) => <VisitStatusRow status={item.status} />
  },
]

function VisitListContainer () {
  const navigate = useNavigate()
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const { isHasActiveTab } = useRoutes()
  const changeStatusDialogue = useDialog()
  const [tabId, setTabId] = useState<any>('') as any
  const [limit, setLimit] = useState(10)
  const [filter, setFilter] = useState({})
  const [settingColumns, setSettingColumns] = useState([])
  const [sorting, setSorting] = useState({}) as any
  const [visitId, setVisitId] = useState<number>(0)
  const [filterPreviewData, setFilterPreviewData] = useState({}) as any
  const filterList = useGetList(getFilterList, { query:{ type:'TASK_LIST_PANEL' } })
  const columnList = useGetList(getColumnsList, { query:{ type:'TASK_LIST_PANEL' } })
  const getSortingList = useGetList(sortingList, { query: { type:'TASK_LIST_PANEL' } })
  const tabList = useGetList(listingCounts)
  const visitList = useGetList(getVisitList, { query:{ limit, tabId } })
  const listingTabDetail = useGetDetailWithoutCall(getByIdTab)
  const listingTabsRecords = useGetDetailWithoutCall(listingCounts)
  const visitDelete = useDelete(deleteVisit)
  const visitStatus = usePut(changeVisitStatus)

  const tabs = prop('result', listingTabsRecords) as any
  const lastActiveTab = isHasActiveTab('visit')
  const lastActiveTabId = propOr('', 'tabId', lastActiveTab) as number

  useEffect(() => {
    const updateDetails = (id:number) => {
      listingTabDetail.getDetail({ params: { id } })
        .then((res) => {
          const settings = propOr({}, 'listingColumn', res) as any
          const filter = propOr({}, 'listingFilter', res) as any
          const sorting = propOr({}, 'listingSort', res)
          setFilter(filter)
          setSorting(sorting)
          setSettingColumns(settings)
        })
    }
    if (lastActiveTabId) {
      updateDetails(lastActiveTabId)
    }
  }, [tabId, lastActiveTabId])

  const getColumns = useMemo(() => {
    const settingId = propOr('', 'id', settingColumns)
    if (settingId) {
      return getColumnsBySetting(settingColumns)
    } else return columns
  }, [settingColumns])

  const handleDeleteVisit = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить посещение ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        visitDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Посещение успешно удалено.' }))
          .then(() => visitList.getList())
      })
      .disagree()
  }, [])

  const onChangeStatus = useCallback((values:any) => {
    visitStatus.putData({ data:changeStatusSerializer(values) })
      .then(() => changeStatusDialogue.handleClose())
      .then(() => snackbar({ message: 'Статус посещения успешно обновлен' }))
      .then(() => visitList.getList())
  }, [])

  const onOpenDialogue = useCallback((id:number) => {
    setVisitId(id)
    changeStatusDialogue.handleOpen()
  }, [])

  const onFilter = useCallback((item: any) => {
    setFilter(item)
    setLimit(item.limitRow || 10)
  }, [])

  const onFilterPreview = useCallback((filter:any) => {
    setFilterPreviewData(filter)
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    visitList.getList({ query: { searchKey: search } })
  }, [])

  const onSetColumn = useCallback((item: any) => {
    setSettingColumns(item)
  }, [])

  const onSetTab = useCallback((tabId:number) => {
    setTabId(tabId)
  }, [])

  return (
    <AppLayout>
      <Table
        title="Посещение"
        onAddClick={() => navigate(ROUTES.CATALOG_VISIT_CREATE)}
        dataRequest={visitList}
        tabRequest={listingTabsRecords}
        lastActiveTab={lastActiveTab}
        listingTableName="visit"
        columns={getColumns}
        onSearchClick={onTableSearch}
        checkbox={true}
        limit={limit}
        setLimit={setLimit}
        tabId={tabId}
        setTabId={setTabId}
        filter={filter}
        tabs={tabs}
        actions={(row) => (
          <VisitRowMenu
            item={row}
            handleDelete={handleDeleteVisit}
            onChangeStatus={onOpenDialogue}
          />
        )}
        moreActions={(actions) => (
          <MoreActionButtons
            actions={actions}
          />
        )}
        filterNode={(
          <Filter
            filter={filter}
            onFilter={onFilter}
            filterList={filterList}
            onFilterPreview={onFilterPreview}
            filterPreviewData={filterPreviewData}
          />
        )}
        columnNode={(
          <ColumnSettingList
            filterList={columnList}
            onFilter={onSetColumn}
          />
        )}
        tabNode={(
          <TabsList
            filterList={tabList}
            onFilter={onSetTab}
          />
        )}
        sortingNode={(
          <SortingList
            sorting={sorting}
            filterList={getSortingList}
          />
        )}
      />
      {changeStatusDialogue.open && (
        <ChangeStatusDialogue
          initialValues={{ id:visitId, status:'' }}
          onSubmit={onChangeStatus}
          open={changeStatusDialogue.open}
          handleClose={changeStatusDialogue.handleClose}
        />
      )}
    </AppLayout>
  )
}

export default VisitListContainer
