import React from 'react'
import { useField } from 'formik'
import { BaseTextFieldProps, Box, FormControlLabel } from '@mui/material'
import MUITextField from '@mui/material/TextField'

interface Props extends BaseTextFieldProps {
  readonly inputVariant?: string
  readonly InputProps?: any
  readonly name?: string
  readonly label?: string
  readonly placeholder: string
}

function TextFieldWithLabel ({ name, label, placeholder, InputProps, ...props }: Props) {
  const [ field, meta, ] = useField(name || '')

  return (
    <Box sx={{ width:'100%' }}>
      <FormControlLabel
        control={
          <MUITextField
            {...props}
            {...field}
            variant="outlined"
            placeholder={placeholder}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            // @ts-ignore
            onWheel={(e) => e.target.blur()}
            sx={{ width: '100%' }}
            InputProps={{ ...InputProps }}
          />
        }
        label={label}
        labelPlacement="top"
        sx={{
          width: '100%',
          '&.MuiFormControlLabel-root': {
            alignItems: 'flex-start',
            margin:'0 0'
          },
          '& .MuiFormControlLabel-label':{
            fontSize:'12px',
            lineHeight:'14.52px',
            marginBottom:'5px',
            alignItems:'flex-start'
          }
        }}
      />
    </Box>
  )
}

export default TextFieldWithLabel
