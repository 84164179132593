import { map, path, pipe, prop, propOr, toPairs } from 'ramda'

export const warehouseCreateSerializer = (warehouse: any) => {
  const address = prop('address', warehouse)
  const countryId = path(['countryId', 'id'], address)
  const regionId = path(['regionId', 'id'], address)
  const driverId = path(['driverId', 'id'], warehouse)
  const getAssignee = prop('assigneesIds', warehouse)
  const assigneesIds = getAssignee && getAssignee.map((item: any) => item.id)
  const newAddress = {
    ...address, countryId, regionId
  }
  const getTerritoryList = propOr([], 'territories', warehouse) as any
  const territories = getTerritoryList.map((territory: any) => territory.id)

  return { ...warehouse, driverId, assigneesIds, territories, address: newAddress }
}

export const warehouseDetailSerializer = (warehouse: any) => {
  const address = prop('address', warehouse)
  const countryId = prop('country', address)
  const regionId = prop('region', address)
  const driverId = prop('driver', warehouse)
  const assigneesIds = prop('assignees', warehouse)
  const newAddress = {
    ...address, countryId, regionId
  }
  return { ...warehouse, driverId, assigneesIds, address: newAddress }
}

export const warehouseUpdateSerializer = (warehouse: any) => {
  const address = prop('address', warehouse)
  const countryId = path(['countryId', 'id'], address)
  const regionId = path(['regionId', 'id'], address)
  const driverId = path(['driverId', 'id'], warehouse)
  const getAssignee = prop('assigneesIds', warehouse)
  const assigneesIds = getAssignee && getAssignee.map((item: any) => item.id)
  const newAddress = {
    ...address, countryId, regionId
  }
  const getTerritoryList = propOr([], 'territories', warehouse) as any
  const territories = getTerritoryList.map((territory: any) => territory.id)

  return { ...warehouse, territories, driverId, assigneesIds, address: newAddress }
}
type KeyType = 'total' | 'active' | 'inactive'
const translateName = (key: KeyType) => {
  const translations = {
    total: 'Bce',
    active: 'Активный',
    inactive: 'Неактивный'
  }
  return translations[key]
}

const keyValues = (key:KeyType) => {
  const values = {
    total: '',
    active: true,
    inactive: false
  }
  return values[key]
}

export const transformTabs = pipe(
  toPairs, // Convert the object into an array of key-value pairs
  map(([key, value]) =>
    ({ id: keyValues(key as KeyType), name: translateName(key as KeyType), records: value }))
  // Map over the pairs and create the desired objects
)
