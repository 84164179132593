import React, { useEffect } from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import { Button } from '@mui/material'
import { useField } from 'formik'
import { useParams } from 'react-router-dom'
import { useGetList } from 'hooks/useGetList'

import ProductBulkEditTabs, { PRODUCT_BULK_EDIT_TABS } from './ProductBulkEditTabs'
import { BulkEditGeneral } from './BulkEditGeneral'

import { getPackageList } from '../api'

interface Props {
  readonly productItems:any
  readonly productNames:string
  readonly form:any
}
function ProductBulkEditForm ({ productItems, productNames, form }:Props) {
  const { tab } = useParams()
  const customFieldsField = useField('customFieldValue')
  const customFields = customFieldsField[0].value || []
  const packageList = useGetList(getPackageList)
  const isGeneral = tab === PRODUCT_BULK_EDIT_TABS.GENERAL

  useEffect(() => {
    form.resetForm({ values: { reset:isGeneral } })
  }, [tab])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={`Массовое редактирование (${productNames})`}
          rightButton={(
            <>
              <Button type="submit">Сохранить изменения</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <ProductBulkEditTabs value={tab as string} productItems={productItems} />
        {tab === PRODUCT_BULK_EDIT_TABS.GENERAL && (
          <BulkEditGeneral
            customFields={customFields}
            packageList={packageList}
            isGeneral={isGeneral}
          />
        )}
        {tab === PRODUCT_BULK_EDIT_TABS.ATTACH && (
          <BulkEditGeneral
            customFields={customFields}
            packageList={packageList}
            isGeneral={isGeneral}
          />
        )}
      </Content>
    </Container>
  )
}

export default withForm(ProductBulkEditForm)
