import * as ROUTES from 'constants/Routes'

import * as Yup from 'yup'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'

import { createContact, getCustomFields } from '../api'
import { agentCreateSerialize } from '../serializer'
import AgentCreateForm from '../components/AgentCreateForm'

export const validationSchema = Yup.object({
  shortName:Yup.string().required('Наименование контрагентa обязательно')
})

function AgentCreateContainer () {
  const agentCreate = usePost(createContact)
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const customFieldsGet = useGetList(getCustomFields)
  const [customFields, setCustomField] = useState([])

  const handleSubmit = useCallback((values: any) => {
    return agentCreate.postData({ data: agentCreateSerialize(values) })
      .then(() => snackbar({ message: 'Контрагент успешно создан' }))
      .then(() => navigate(ROUTES.FINANCE_AGENT_LIST))
  }, [])

  useEffect(() => {
    customFieldsGet.getList({ params: { formType: 'CONTACT_FORM' } }).then((res: any) => {
      setCustomField(res)
    })
  }, [])

  return (
    <AppLayout>
      <AgentCreateForm
        initialValues={{
          shortName:'',
          territories:[],
          juridicalList:[],
          customFieldValue: customFields
        }}
        customFields={customFields}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      />
    </AppLayout>
  )
}

export default AgentCreateContainer
