import * as API from 'constants/Api'

import React from 'react'
import { prop } from 'ramda'
import SearchField from 'components/form/SearchField'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)
    const code = prop('code', option)

    return { value, name, id: value, code }
  }

  return null
}

function CurrencyField (props: any) {
  return (
    <SearchField
      name="currencyCode"
      api={API.CurrencySuggestions}
      getOptionValue={getOptionValue}
      label="Валюта"
      primaryKey="code"
      {...props}
    />
  )
}

export default CurrencyField
