import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import SearchField from 'components/form/SearchField'
import { path, prop } from 'ramda'
import { useField } from 'formik'
import { getRequest } from 'api/BaseApi'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)
    const code = prop('code', option)
    return { value, name, id: value, code }
  }

  return null
}

function OrderField (props: any) {
  const [ orderField, meta, ] = useField('orderId')
  const [,, territoryHelpers] = useField('territoryId')
  const [,, contactHelpers] = useField('contactId')
  const orderId = path(['value', 'id'], orderField)

  useEffect(() => {
    if (!meta.initialValue && orderId) {
      getRequest(API.SaleOrderDetail, { params: { id: orderId } }).then((response:any) => {
        const territoryId = prop('territory', response)
        const contactId = prop('contact', response)
        contactHelpers.setValue(contactId)
        territoryHelpers.setValue(territoryId)
      })
    }
  }, [orderId])

  return (
    <SearchField
      name="orderId"
      api={API.SalesOrderSuggestions}
      getOptionValue={getOptionValue}
      label="Заказ"
      {...props}
    />
  )
}

export default OrderField
