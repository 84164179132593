import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getManualEntryList = (options?: Options) => {
  return getRequest<any>(API.ManualEntryList, options)
}

export const createManualEntry = (options?: Options) => {
  return postRequest<any>(API.ManualEntryCreate, options)
}

export const updateManualEntry = (options?: Options) => {
  return putRequest<any>(API.ManualEntryUpdate, options)
}

export const approveManualEntry = (options?: Options) => {
  return patchRequest<any>(API.ManualEntryApprove, options)
}

export const deleteManualEntry = (options?: Options) => {
  return deleteRequest<any>(API.ManualEntryDelete, options)
}

export const getManualEntryDetail = (options?: Options) => {
  return getRequest<any>(API.ManualEntryDetail, options)
}

export const importManualEntry = (options?:Options) => {
  return postRequest(API.ManualEntryImport, options)
}
