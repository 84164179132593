import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'

import { contactListTracking } from '../api'
import AgentsAddressesForm from '../components/AgentsAddressesForm'
import { contactListSerializer } from '../serializers'

function AgentsAddressesListContainer () {
  const contactListing = useGetList(contactListTracking)

  const onSubmit = useCallback((values:any) => {
    contactListing.getList({ query: contactListSerializer(values) })
  }, [])

  return (
    <AppLayout>
      <AgentsAddressesForm
        initialValues={{}}
        onSubmit={onSubmit}
        contactListTracking={contactListing}
      />
    </AppLayout>
  )
}

export default AgentsAddressesListContainer
