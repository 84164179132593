import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'CASH', name: 'Наличными' },
  { value: 'CARD', name: 'Картой' },
  { value: 'BANK_TRANSFER', name: 'Банковский перевод' }
]

function PaymentMethodField (props:any) {
  return (
    <SelectField
      name="paymentMethod"
      label="Тип оплаты"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PaymentMethodField
