import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import { prop } from 'ramda'
import SearchField from 'components/form/SearchField'
import { useField } from 'formik'
import { getRequest } from 'api/BaseApi'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)
    const code = prop('code', option)
    return { value, name, id: value, code }
  }

  return null
}

function CurrencyField (props: any) {
  const [, meta, helpers] = useField(props.name)

  useEffect(() => {
    if (meta.initialValue) {
      getRequest(API.CurrencyGetByCode, { params: { code: meta.initialValue } })
        .then((res) => helpers.setValue(getOptionValue(res)))
    }
  }, [])

  return (
    <SearchField
      api={API.CurrencySuggestions}
      getOptionValue={getOptionValue}
      label="Валюта"
      searchText="searchKey"
      {...props}
    />
  )
}

export default CurrencyField
