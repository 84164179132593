import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getCategoryList = (options?: Options) => {
  return getRequest<any>(API.CategoryList, options)
}

export const getCategoryDetail = (options?: Options) => {
  return getRequest<any>(API.CategoryDetail, options)
}

export const createCategory = (options?: Options) => {
  return postRequest<any>(API.CategoryCreate, options)
}

export const updateCategory = (options?: Options) => {
  return putRequest<any>(API.CategoryUpdate, options)
}

export const deleteCategory = (options?: Options) => {
  return deleteRequest<any>(API.CategoryDelete, options)
}

export const deleteCategoryByIds = (options?: Options) => {
  return deleteRequest<any>(API.CategoryDeleteByIds, options)
}

export const addProductToCategory = (options?:Options) => {
  return patchRequest(API.AssignProductsToCategory, options)
}

export const addTerritoriesToCategory = (options?:Options) => {
  return patchRequest<any>(API.TerritoryCategorySave, options)
}

export const getProductList = (options?:Options) => {
  return getRequest<any>(API.ProductList, options)
}

export const territoryListSuggestions = (options?:Options) => {
  return getRequest<any>(API.TerritorySuggestions, options)
}

export const getTerritoryList = (options?:Options) => {
  return getRequest<any>(API.TerritoriesList, options)
}

export const getProductsByCategoryIds = (options?:Options) => {
  return postRequest<any>(API.ProductByCategoryIds, options)
}

export const createFilter = (options?: Options) => {
  return postRequest<any>(API.ListingFilterCreate, options)
}

export const updateFilter = (options?: Options) => {
  return putRequest<any>(API.ListingFilterUpdate, options)
}

export const filterGetById = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetById, options)
}

export const getAvailableColumns = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetByType, options)
}

export const getFilterList = (options?: Options) => {
  return getRequest<any>(API.ListingFilterList, options)
}

export const deleteFilter = (options?: Options) => {
  return deleteRequest<any>(API.ListingFilterDelete, options)
}

export const deleteSelectedFilter = (options?: Options) => {
  return deleteRequest<any>(API.ListingFilterDeleteByIds, options)
}

export const getColumnsList = (options?: Options) => {
  return getRequest<any>(API.ColumnsList, options)
}

export const createColumn = (options?: Options) => {
  return postRequest<any>(API.ColumnsCreate, options)
}

export const columnGetById = (options?: Options) => {
  return getRequest<any>(API.ColumnsGetById, options)
}

export const updateColumn = (options?: Options) => {
  return putRequest<any>(API.ColumnsUpdate, options)
}

export const deleteColumn = (options?: Options) => {
  return deleteRequest<any>(API.ColumnsDelete, options)
}

export const deleteColumns = (options?: Options) => {
  return deleteRequest<any>(API.ColumnsDeleteByIds, options)
}
