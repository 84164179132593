import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { getCompanyById, updateCompany } from '../api'
import CompanyUpdateForm from '../components/CompanyUpdateForm'
import { companyUpdateSerializer } from '../serializer'

function CompanyUpdateContainer () {
  const snackbar = useSnackbar()
  const { id } = useParams()
  const navigate = useNavigate()
  const companyDetail = useGetDetail(getCompanyById, { params: { publicId:id } })
  const companyUpdate = usePut(updateCompany)

  const handleSubmit = useCallback((values: any) => {
    return companyUpdate.putData({ data: companyUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Компания успешно обновлён' }))
      .then(() => navigate(ROUTES.BACKEND_COMPANY_LIST))
  }, [])

  return (
    <AppLayout>
      {!companyDetail.loading && (
        <CompanyUpdateForm
          onSubmit={handleSubmit}
          initialValues={companyDetail.result}
          enableReinitialize={true}
        />
      )}
      {companyDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default CompanyUpdateContainer
