import React from 'react'
import {
  Button,
  Card,
  CardContent,
  Grid, IconButton,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import CardHeader from 'components/card/CardHeader'
import { FieldArray, useField } from 'formik'
import { useTranslationI18n } from 'i18n/I18nContext'
import DateField from 'components/form/DateField'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import DoubleFormatField from 'components/form/DoubleFormatField'

import ConsignmentItemStatusField from './form/ConsignmentItemStatusField'

const itemDefault = { status: '', amount: 0, dueDate: null }

export function LineItems () {
  const { t } = useTranslationI18n()

  const lineItemsField = useField('items')
  const lineItems = lineItemsField[0].value || []

  return (
    <Card>
      <CardHeader title="Таблица" />
      <CardContent>
        <Grid container={true} spacing={3}>
          <FieldArray name="items">
            {({ push, remove }: any) => {
              return (
                <Grid item={true} lg={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell width="30%">{t('Сумма')}</TableCell>
                          <TableCell width="30%">
                            {t('Статуc')}
                          </TableCell>
                          <TableCell width="30%">{t(`Срок оплаты`)}</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lineItems.map((item: any, index: number) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <DoubleFormatField name={`items[${index}].amount`} />
                              </TableCell>
                              <TableCell>
                                <ConsignmentItemStatusField name={`items[${index}].status`} />
                              </TableCell>
                              <TableCell>
                                <DateField name={`items[${index}].dueDate`} />
                              </TableCell>
                              <TableCell align="right">
                                <IconButton onClick={() => remove(index)} size="large">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        <Button sx={{ marginTop: '30px' }} onClick={() => push(itemDefault)}>Добавить</Button>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            }}
          </FieldArray>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default LineItems
