import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePut } from 'hooks/usePut'
import { useGetList } from 'hooks/useGetList'

import {
  getPriceLevelList,
  saveTerritoryPriceLevel,
  getContactList,
  saveTerritoryContacts,
  getCategoryList,
  saveTerritoryCategories,
  getTerritoryPriceLevel,
  getTerritoryContact,
  getTerritoryCategory
} from '../api'
import TerritoryBulkEditForm from '../components/TerritoryBulkEditForm'

function TerritoryBulkContainer () {
  const { id } = useParams()

  const priceLevelList = useGetList(getPriceLevelList)
  const priceLevelSave = usePut(saveTerritoryPriceLevel)
  const territoryPriceLevel = useGetList(getTerritoryPriceLevel, { query: { territoryId: id } })
  const contactList = useGetList(getContactList)
  const contactSave = usePut(saveTerritoryContacts)
  const territoryContacts = useGetList(getTerritoryContact, { params: { id } })
  const categoryList = useGetList(getCategoryList)
  const categorySave = usePut(saveTerritoryCategories)
  const territoryCategory = useGetList(getTerritoryCategory, { query: { territoryId: id } })

  useEffect(() => {
    territoryPriceLevel.getList({ query: { limit: 1000 } })
    territoryCategory.getList({ query: { limit: 1000 } })
    territoryContacts.getList({ query: { limit: 1000 } })
  }, [])

  return (
    <AppLayout>
      <TerritoryBulkEditForm
        priceLevelSave={priceLevelSave}
        categorySave={categorySave}
        contactSave={contactSave}
        contactList={contactList}
        categoryList={categoryList}
        priceLevelList={priceLevelList}
        territoryPriceLevel={territoryPriceLevel}
        territoryContacts={territoryContacts}
        territoryCategory={territoryCategory}
      />
    </AppLayout>
  )
}

export default TerritoryBulkContainer
