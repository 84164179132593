import * as API from 'constants/Api'

import React from 'react'
import SearchField from 'components/form/SearchField'
import { prop } from 'ramda'

const getOptionValue = (option: any) => {
  if (option) {
    const id = prop('id', option)
    const code = prop('code', option)
    const name = prop('name', option)

    return { name, id, code }
  }

  return null
}

function ClientCurrencyField (props: any) {
  return (
    <SearchField
      label="Валюта"
      name="currency"
      api={API.CurrencySuggestions}
      getOptionValue={getOptionValue}
      primaryKey="code"
      searchText="searchKey"
      {...props}
    />
  )
}

export default ClientCurrencyField
