import { useCallback, useState } from 'react'
import { propOr } from 'ramda'

import { useGetList } from './useGetList'
import useDeepCompareEffect from './useDeepCompareEffect'

function useScrollList (api: any, searchParams?: any, limit = 10) {
  const [results, setResults] = useState([] as any)
  const [start, setStart] = useState(0)
  const { getList, ...state } = useGetList(api, searchParams)

  useDeepCompareEffect(() => {
    const nextStart = results.length
    setStart(nextStart)
  }, [results])

  const loadList = useCallback(
    (params: any) =>
      getList({ start, limit, ...params })
        .then((data) => {
          const list = propOr(data, 'list', data) as any[]
          setResults((prevData: any) => [...prevData, ...list])
          return list
        }),
    [getList, start, limit]
  )

  const reset = useCallback(() => {
    setStart(0)
    setResults([])
  }, [])

  return { ...state, results, getList: loadList, reset }
}

export default useScrollList
