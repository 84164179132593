import React from 'react'
import { path } from 'ramda'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import DoubleFormatField from 'components/form/DoubleFormatField'
import DateField from 'components/form/DateField'
import TextField from 'components/form/TextField'

import ContactField from './form/ContactField'
import AccountField from './form/AccountField'
import CurrencyField from './form/CurrencyField'
import PaymentMethodField from './form/PaymentMethodField'

function SupplierPaymentsUpdateForm ({ form }:any) {
  const name = path(['values', 'number'], form)

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить платежи поставщика (${name})`}
          rightButton={(
            <>
              <Button type="submit" disabled={true}>Сохранить изменения</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <ContactField disabled />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <AccountField disabled />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <DoubleFormatField
                      label="Сумма"
                      name="amount"
                      disabled
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <CurrencyField disabled />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <PaymentMethodField disabled />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <DateField
                      label="Дата"
                      name="date"
                      disabled
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label="Описание"
                      name="note"
                      minRows={4}
                      multiline
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(SupplierPaymentsUpdateForm)
