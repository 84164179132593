import { path, prop, propOr } from 'ramda'
import { parseStringToNumber } from 'utils/formatMoney'

export const paymentCreateSerializer = (values: any) => {
  const paymentAmount = parseStringToNumber(prop('paymentAmount', values))
  return { ...values, paymentAmount }
}

export const paymentInitialValuesSerializer = (values: any) => {
  const customerId = prop('contact', values)
  const accountSelection = prop('account', values)
  const paymentDate = prop('date', values)
  const paymentReferenceNumber = prop('refNumber', values)
  return { ...values, customerId, paymentDate, paymentReferenceNumber, accountSelection }
}

export const paymentCollectSerializer = (values: any, id: any) => {
  const accountId = path(['account', 'id'], values)
  const bankChargeAccountId = path(['bankChargeAccount', 'id'], values)
  const amount = propOr(0, 'amount', values)
  return { accountId, status: 'PAID', paymentId: Number(id), amount: Number(amount), bankChargeAccountId }
}

export const paymentDepositSerializer = (values: any, id: any) => {
  const depositAccountId = path(['depositAccount', 'id'], values)
  return { status: 'DEPOSITED', paymentId: Number(id), depositAccountId }
}
