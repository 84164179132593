import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'BOOKED', name:'ЗАБРОНИРОВАНО' },
  { value: 'PICKED', name:'ВЫБРАНО' },
  { value: 'PACKED', name:'УПАКОВАНО' },
  { value: 'SHIPPING', name:'ДОСТАВКА' },
  { value: 'DELIVERED', name:'ДОСТАВЛЕНО' },
  { value: 'PARTIAL_DELIVERED', name:'ЧАСТИЧНО' },
  { value: 'COMPLETED', name:'ЗАВЕРШЕНО' },
  { value: 'COLLECTED', name:'ВОЗВРАТ СОБРАН' },
  { value: 'DRIVER_ASSIGNED', name:'ВОДИТЕЛЬ НАЗНАЧЕН' },
  { value: 'RETURNED', name:'ВОЗВРАЩЕННЫЙ' }
]

function ShipmentStatusField (props:any) {
  return (
    <SelectField
      name="status"
      label="Статус"
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default ShipmentStatusField
