import * as React from 'react'
import { ReactNode } from 'react'
import Drawer from '@mui/material/Drawer'

interface Props {
  readonly open: boolean
  readonly children: ReactNode
  readonly onClose: () => void
}

export default function RightSideDrawer ({ open, onClose, children }: Props) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      {children}
    </Drawer>
  )
}
