import React from 'react'
import { Table as MuiTable, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import FlexBox from 'components/main/FlexBox'

export const StockTable = ({ stock }:any) => {
  return (
    <FlexBox align="center">
      {stock && stock.map((item: any) => (
        <MuiTable sx={{ width:'250px' }}>
          <>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">{item.warehouse.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    background: '#F1F1F1',
                    height:'36px',
                    color: '#202020',
                    fontSize: '11px',
                    fontWeight: 600
                  }}
                >
                  Количество в наличии
                </TableCell>
                <TableCell
                  sx={{
                    background: '#F1F1F1',
                    height:'36px',
                    color: '#202020',
                    fontSize: '11px',
                    fontWeight: 600
                  }}
                >
                  Забронированное количество
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={item.warehouse.name}>
                <TableCell align="center">{item.quantityOnHand}</TableCell>
                <TableCell align="center">{item.bookedQuantity}</TableCell>
              </TableRow>
            </TableBody>
          </>
        </MuiTable>
      ))}
    </FlexBox>
  )
}
