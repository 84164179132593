import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import { FieldArray, FormikFormProps, FormikProps, useFormikContext } from 'formik'
import { propOr } from 'ramda'
import FlexBox from 'components/main/FlexBox'

import AccountField from './form/EmployeeField'
import RoleField from './form/RoleField'
import ApproveByTypeField from './form/ApproveByType'
import ApproveTypeField from './form/ApproveTypeField'

interface Props{
  readonly form:FormikProps<FormikFormProps>
}

function ApprovalCreateForm ({ form }:Props) {
  const { values } = useFormikContext()
  const steps = propOr([], 'steps', values) as any
  const type = propOr(undefined, 'type', values) as string
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать одобрение"
          rightButton={(
            <>
              <Button
                type="submit"
                disabled={!form.isValid}
              >
                Создать
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <ApproveTypeField />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          {type !== undefined && (
            <Grid item={true} lg={12}>
              <Card>
                <CardHeader title="Процесс утверждения шага" />
                <FieldArray name="steps">
                  {({ push, remove }: any) => {
                    return (<CardContent>
                      <Grid container={true} spacing={3}>
                        {steps.map((steps: any, index: number) => {
                          const type = propOr('', 'approverType', steps) as any
                          const isUser = type === 'user'
                          return (
                            <Grid item={true} key={index} lg={12}>
                              <Grid container={true} spacing={2}>
                                <Grid item={true} lg={2}>
                                  <FlexBox align="center" gap={16}>
                                    <Typography variant="body1">
                                      {index + 1 + '.'}
                                    </Typography>
                                    <ApproveByTypeField
                                      name={`steps[${index}].approverType`}
                                    />
                                  </FlexBox>
                                </Grid>
                                {isUser && (
                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <AccountField
                                      name={`steps[${index}].employeeIds`}
                                    />
                                  </Grid>
                                )}
                                {!isUser && (
                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <RoleField
                                      name={`steps[${index}].roleCodes`}
                                    />
                                  </Grid>
                                )}
                                {index >= 0 && (
                                  <Grid item xs={8} lg={2}>
                                    <Button onClick={() => remove(index)}>Удалить</Button>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          )
                        })}
                        <Grid item={true} lg={6}>
                          <Button
                            onClick={() => {
                              push({
                                employeeIds: [],
                                roleCodes: []
                              })
                            }}
                          >
                            Добавить шаг
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>)
                  }}
                </FieldArray>
              </Card>
            </Grid>
          )}
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(ApprovalCreateForm)
