import React from 'react'
import AppLayout from 'components/main/AppLayout'
import { useGetDetail } from 'hooks/useGetDetail'
import { useParams } from 'react-router-dom'

import { getReceiveDetailById } from '../api'
import ReceivePODetailForm from '../components/ReceivePODetailForm'

function ReceivePODetailContainer () {
  const { id } = useParams()
  const receiveDetail = useGetDetail(getReceiveDetailById, { params: { receiveId: id } })

  return (
    <AppLayout>
      {!receiveDetail.loading && (
        <ReceivePODetailForm
          initialValues={receiveDetail.result}
        />
      )}
      {receiveDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default ReceivePODetailContainer
