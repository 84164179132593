import * as ROUTES from 'constants/Routes'

import React from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useNavigate } from 'react-router-dom'
import { pathParams } from 'path-params'

import { COMPANY_EDIT_TABS } from './CompanyEditTabs'

interface Props {
  readonly row: any
  readonly handleDeleteCompany: (values: any) => void
  readonly index: number
  readonly onOpenDialogue:any
  readonly setCompanyId:(id:number) => void
}

function CompanyRowMenu ({ row, handleDeleteCompany, index, onOpenDialogue, setCompanyId }: Props) {
  const navigate = useNavigate()
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null)

  const anchorIsOpen = Boolean(optionsAnchorEl)

  const handleOpenOptions = (event: any) => {
    setOptionsAnchorEl(event.currentTarget)
  }

  const handleCloseOptions = () => {
    setOptionsAnchorEl(null)
  }

  const updatedURL = pathParams(ROUTES.BACKEND_COMPANY_EDIT, { tab:COMPANY_EDIT_TABS.GENERAL, id: row.id })
  const addUserURL = pathParams(
    ROUTES.BACKEND_COMPANY_ADD_USER,
    { state:{ companyId:row.id, companyName:row.companyName } })
  return (
    <React.Fragment>
      <IconButton
        data-cy={`table-action-row-${index}`}
        aria-label="more"
        id="long-button"
        aria-controls={anchorIsOpen ? 'long-menu' : undefined}
        aria-expanded={anchorIsOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenOptions}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="table-item-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={optionsAnchorEl}
        open={anchorIsOpen}
        onClose={handleCloseOptions}
      >
        <MenuItem
          data-cy="edit"
          onClick={() => navigate(updatedURL)}
        >
          Детали
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteCompany(row)
            handleCloseOptions()
          }}
          data-cy="delete"
        >
          Удалить
        </MenuItem>
        <MenuItem onClick={() => {
          setCompanyId(row.id)
          onOpenDialogue()
        }}>
          Установить тарифный план
        </MenuItem>
        <MenuItem
          data-cy="user-add"
          onClick={() => navigate(addUserURL)}
        >
          Добавить пользователя
        </MenuItem>
        <MenuItem
          onClick={handleCloseOptions}
        >
          Активировать
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default CompanyRowMenu
