import React, { useCallback } from 'react'
import { Box, Card, CardContent, Divider, Grid } from '@mui/material'
import { prop } from 'ramda'
import { usePost } from 'hooks/usePost'
import CardHeader from 'components/card/CardHeader'
import Dropzone from 'components/upload/Dropzone'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import { useDelete } from 'hooks/useDelete'

import { uploadFiles, deleteUploadFile } from '../../api'

interface Props {
  readonly onFileSet?: any
  readonly files?: any
  readonly onFileDelete: any
}

function AttachmentField ({ onFileSet, files = [], onFileDelete }: Props) {
  const fileUpload = usePost(uploadFiles)
  const contactDeleteFile = useDelete(deleteUploadFile)

  const onDrop = useCallback((values: any = []) => {
    const data = new FormData()
    values.forEach((file: any) => {
      data.append('uploadFile', file)
    })
    fileUpload.postData({ data }).then((response) => {
      onFileSet(response)
    })
  }, [])

  const handleDelete = useCallback((id: any) => {
    contactDeleteFile.deleteData({ params: { id } }).then(() => {
      onFileDelete(id)
    })
  }, [])
  return (
    <Card>
      <CardHeader title="Добавить вложение для заказ" />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={2}>
            <Dropzone onDrop={onDrop} />
          </Grid>
          {fileUpload.loading && (
            <Grid item={true} lg={2}>Loading...</Grid>
          )}
          {files?.map((item: any) => {
            const link = prop('link', item)
            const id = prop('id', item)
            const originalName = prop('originalName', item)
            return (
              <Grid item={true} lg={2} sx={{ position: 'relative' }}>
                <span
                  style={{
                    position: 'absolute',
                    fontSize: 25,
                    color: 'white',
                    cursor: 'pointer',
                    right:'45px',
                    top:'35px'
                  }}
                  onClick={() => handleDelete(id)}
                >
                  <DeleteIcon />
                </span>
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'flex-end',
                    justifyContent:'center',
                    width:'200px',
                    height:'200px',
                    background:'#F8F8F8',
                    borderRadius:'8px 8px 0 0',
                  }}
                >
                  <img
                    src={link}
                    alt={originalName}
                    style={{
                      height: 150,
                      width:150,
                      borderRadius:'8px 8px 0 0',
                      objectFit: 'cover',
                      cursor:'pointer'
                    }} />
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default AttachmentField
