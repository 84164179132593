import { MenuItem } from '@mui/material'
import React from 'react'

interface Props{
  readonly handleSetPacker: () => void
  readonly handleProcess: () => void
  readonly handleBulkComplete: () => void
}
function TableActionsRow ({
  handleSetPacker,
  handleProcess,
  handleBulkComplete
}:Props) {
  return (
    <>
      <MenuItem onClick={handleSetPacker}>
        Задать упаковщика
      </MenuItem>
      <MenuItem onClick={handleProcess}>
        Передать водителью
      </MenuItem>
      <MenuItem onClick={handleBulkComplete}>
        Завершить выбранное
      </MenuItem>
    </>
  )
}

export default TableActionsRow
