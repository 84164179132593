import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { prop } from 'ramda'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import SidebarLayout from 'components/main/AttachLayout'
import FlexBox from 'components/main/FlexBox'
import { ProductsIcon } from 'components/icons/ProductsIcon'
import { MapTerritoryIcon } from 'components/icons/MapTerritoryIcon'

import { ProductBulkTable } from './bulk-form/ProductBulkTable'
import { TerritoryBulkTable } from './bulk-form/TerritorBulkTable'

enum CATEGORY_TABS {
  PRODUCTS = 'products',
  TERRITORY = 'territory',
}

const menuList = [
  { id:1, value: CATEGORY_TABS.PRODUCTS, label: 'Продукты', icon:<ProductsIcon /> },
  { id:2, value: CATEGORY_TABS.TERRITORY, label: 'Территория', icon:<MapTerritoryIcon /> },
]

function CategoryAddProductForm () {
  const location = useLocation()
  const categoryItems = prop('state', location)
  const [selectedMenu, setSelectedMenu] = useState<string>(CATEGORY_TABS.PRODUCTS)

  const categoryIds = useMemo(() => {
    return categoryItems.map((item:{ id:number, name:string }) => item.id)
  }, [categoryItems])

  const categoryNames = useMemo(() => {
    return categoryItems.map((item:{ id:number, name:string }) => item.name).join(', ')
  }, [categoryItems])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={`Добавить прекрепить в продуктовую корзину (${categoryNames})`}
        />
      </Header>
      <Content>
        <SidebarLayout
          menuList={menuList}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
        >
          <FlexBox direction="column" style={{ height:'calc(100vh - 150px)' }}>
            {selectedMenu === CATEGORY_TABS.PRODUCTS && (
              <ProductBulkTable
                categoryIds={categoryIds}
                categoryItems={categoryItems}
              />
            )}
            {selectedMenu === CATEGORY_TABS.TERRITORY && (
              <TerritoryBulkTable
                categoryIds={categoryIds}
                categoryItems={categoryItems}
              />
            )}
          </FlexBox>
        </SidebarLayout>
      </Content>
    </Container>
  )
}

export default CategoryAddProductForm
