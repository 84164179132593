import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import { prop } from 'ramda'
import { useField } from 'formik'
import { getRequest } from 'api/BaseApi'

import AutoSelectField from './AutoSelectField'

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('profileId', value)
    const name = prop('fullName', value)
    return { id, name }
  }

  return null
}

function UserField (props:any) {
  const field = useField(props.name)
  useEffect(() => {
    if (field[0].value) {
      getRequest(API.EmployeeDetail, { params: { id:field[0].value } })
        .then((response:any) => {
          field[2].setValue(getOptionValue(response))
        })
    }
  }, [])
  return (
    <AutoSelectField
      name="userId"
      primaryKey="profileId"
      api={API.EmployeeList}
      getOptionValue={getOptionValue}
      label="Пользователь"
      {...props}
    />
  )
}

export default UserField
