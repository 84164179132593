import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { validationSchema } from './WarehouseCreateContainer'

import { updateWarehouse, getWarehouseDetail } from '../api'
import WarehouseUpdateForm from '../components/WarehouseUpdateForm'
import {
  warehouseDetailSerializer, warehouseUpdateSerializer
} from '../serializer'

function WarehouseUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const warehouseUpdate = usePut(updateWarehouse)
  const warehouseDetail = useGetDetail(getWarehouseDetail, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    return warehouseUpdate.putData({ data: warehouseUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Склад успешно обновлен' }))
      .then(() => navigate(ROUTES.WAREHOUSE_LIST))
  }, [])

  const initialValues = useMemo(() => {
    return warehouseDetailSerializer(warehouseDetail.result)
  }, [warehouseDetail.result])

  return (
    <AppLayout>
      {!warehouseDetail.loading && (
        <WarehouseUpdateForm
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        />
      )}
      {warehouseDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default WarehouseUpdateContainer
