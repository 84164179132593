import React from 'react'
import { makeStyles } from '@mui/styles'
import { useField } from 'formik'
import { path } from 'ramda'
import Typography from '@mui/material/Typography'
import { Button, Grid } from '@mui/material'
import cx from 'classnames'
import FlexBox from 'components/main/FlexBox'
import TabSettingsIcon from 'assets/tab-settings-icon.svg'
import TextField from 'components/form/TextField'
import SaveIcon from 'assets/save-icon.svg'
import withForm from 'components/form/withForm'
import useDialog from 'hooks/useDialog'

import FilterFieldVisibility from './form/FilterFieldVisibility'
import FilterFieldRoles from './form/FilterFieldRoles'
import FilterFieldUsers from './form/FilterFieldUsers'
import NewViewCoumns from './form/NewViewColumns'
import ColumnsSettingsDialogue from './columns-settings-dialogue/ColumnsSettingsDialogue'

export const useStyles = makeStyles({
  header: {
    padding: '17px 20px',
    background: 'rgba(253, 253, 253, 0.5)',
    backdropFilter: 'blur(15px)',
    borderBottom:'1px solid #f1f1f1'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-between'
  },
  wrapper:{
    width:'600px',
    background:'#fff',
    minHeight: 'calc(100vh - 60px)'
  },
  addButton: {
    cursor: 'pointer'
  },
  searchBox:{
    padding:'20px',
    borderBottom:'1px solid #f1f1f1'
  },
  columnBox:{
    padding:'20px',
    height: 'calc(100vh - 256px)',
    overflowY:'auto'
  },
  menuItem:{
    padding:'10px 20px',
    '&:hover':{
      background:'#F6F8FD'
    }
  },
  actions:{
    padding:'20px',
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end'
  },
  button: {
    fontSize: '13px',
    display: 'flex',
    alignItems:'center',
    gap:'5px',
    height: '40px',
    borderRadius: '5px',
    padding: '9px 15px',
    textTransform:'capitalize',
    '&.disabled': {
      background: '#202020 !important',
      opacity: '0.5'
    }
  }
})

interface Props {
  readonly form:any
  readonly onBack:() => void
}

const ColumnCreateForm = ({ onBack, form }:Props) => {
  const classes = useStyles()
  const visibilityField = useField('visibleTo')
  const visibilityValue = path(['0', 'value'], visibilityField)
  const isUserAndRoles = visibilityValue === 'SELECTED_USERS_OR_ROLES'
  const columnsSettingsDialogue = useDialog()

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <FlexBox align="center">
              <img src={TabSettingsIcon} alt="AddIcon" />
              <Typography sx={{ marginLeft: '10px' }}>
                Настройка колонок
              </Typography>
            </FlexBox>
          </div>
          <Grid container={true} spacing={1} pt={2}>
            <Grid item={true} lg={9}>
              <TextField
                label="Название колонка"
                placeholder="Название колонка"
                name="name"
                size="small"
                inputVariant="outlined"
              />
            </Grid>
            <Grid item={true} lg={3}>
              <Button type="submit" className={cx(classes.button, { 'disabled': '' })} fullWidth={true}>
                Создать
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.columnBox}>
          <Grid container={true} spacing={2}>
            <Grid item={true} lg={6}>
              <FilterFieldVisibility />
            </Grid>
            <Grid item={true} lg={6}>
              <Button
                className={classes.button}
                onClick={() => columnsSettingsDialogue.handleOpen()}
              >
                Редактировать вид
              </Button>
            </Grid>
            {isUserAndRoles && (
              <>
                <Grid item={true} lg={6}>
                  <FilterFieldRoles />
                </Grid>
                <Grid item={true} lg={6}>
                  <FilterFieldUsers />
                </Grid>
              </>
            )}
            <Grid item={true} lg={12}>
              <NewViewCoumns />
            </Grid>
          </Grid>
        </div>
        <div className={classes.actions}>
          <FlexBox align="center" gap="8px">
            <Button
              color="secondary"
              variant="contained"
              className={classes.button}
              onClick={onBack}
            >
              <img src={SaveIcon} alt="save-icon" />
              Отменить
            </Button>
          </FlexBox>
        </div>
      </div>
      {columnsSettingsDialogue.open && (
        <ColumnsSettingsDialogue
          open={columnsSettingsDialogue.open}
          onClose={columnsSettingsDialogue.handleClose}
          form={form}
        />
      )}
    </>

  )
}

export default withForm(ColumnCreateForm)
