import * as ROUTES from 'constants/Routes'

import React from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useNavigate } from 'react-router-dom'
import { pathParams } from 'path-params'
import { prop } from 'ramda'

interface Props {
  readonly item: any
}

function CountryRowMenu ({ item }: Props) {
  const navigate = useNavigate()
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null)

  const anchorIsOpen = Boolean(optionsAnchorEl)

  const handleOpenOptions = (event: any) => {
    setOptionsAnchorEl(event.currentTarget)
  }

  const handleCloseOptions = () => {
    setOptionsAnchorEl(null)
  }

  const status = prop('status', item)
  const isActive = status === 'ACTIVE'

  const updateUrl = pathParams(ROUTES.CATALOG_COUNTRY_UPDATE, { id: item.id })

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={anchorIsOpen ? 'long-menu' : undefined}
        aria-expanded={anchorIsOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenOptions}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="table-item-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={optionsAnchorEl}
        open={anchorIsOpen}
        onClose={handleCloseOptions}
      >
        <MenuItem onClick={() => navigate(updateUrl)}>
          Просмотр
        </MenuItem>
        <MenuItem onClick={() => null}>
          {isActive ? 'Деактивировать' : 'Активировать'}
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default CountryRowMenu
