import React, { ReactNode } from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'lines'
})(({ lines }: any) => ({
  WebkitLineClamp: `${lines}`,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  overflow: 'hidden',
  maxWidth: '100%'
})) as any

interface Props {
  readonly children: ReactNode
  readonly lines: number
}

function TextOverflow (props: Props) {
  const {
    children,
    lines = 2,
    ...rest
  } = props

  return (
    <RootStyled lines={lines} {...rest}>
      <span>{children}</span>
    </RootStyled>
  )
}

export default TextOverflow
