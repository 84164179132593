import * as Yup from 'yup'
import { toNumber } from 'utils/formatMoney'

export const consignmentInitialValues = {
  date:'',
  status:'',
  currencyCode:'',
  contactId:'',
  totalAmount:'',
  items:[]
}

export const consignmentValidationSchema = Yup.object().shape({
  total: Yup.string().required().test(
    'is-sum-correct',
    'Общая сумма должна быть равна сумме сумм в таблице',
    function (value) {
      const { items } = this.parent
      if (!items || items.length === 0) return true // No items to validate against
      const sumOfItems = items.reduce((sum: number, item: { amount: string | number; }) => {
        return toNumber(sum) + toNumber(item.amount)
      }, 0)
      return toNumber(value) === sumOfItems
    }
  )
})
