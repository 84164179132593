import * as API from 'constants/Api'

import React, { useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Chip, IconButton } from '@mui/material'
import { getRequest, patchRequest } from 'api/BaseApi'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import DoneIcon from '@mui/icons-material/Done'
import { useLocales } from 'hooks/useLocales'

import FlexBox from './FlexBox'

import { useNotification } from '../../context/notification'

const NewsCard = ({ news, onReadNews }: any) => {
  const { currentLanguage } = useLocales()
  const { nameEn, nameRu, nameUz, descriptionEn, descriptionRu, descriptionUz, createdDate, read } = news

  const name = currentLanguage === 'en' ? nameEn : currentLanguage === 'ru' ? nameRu : nameUz
  const description = currentLanguage === 'en'
    ? descriptionEn : currentLanguage === 'ru' ? descriptionRu : descriptionUz

  return (
    <Box
      sx={{
        width: '100%',
        padding: '8px',
        background: read ? '#f9f9f9' : '#f0f0f0',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
    >
      <FlexBox align="center" justify="space-between">
        <Typography variant="h6">
          {name}
        </Typography>
        <IconButton onClick={() => onReadNews(news.id)}>
          <DoneIcon />
        </IconButton>
      </FlexBox>
      <Box my={1}>
        <Typography variant="body2" mb={1}>
          {description}
        </Typography>
        <FlexBox align="center" justify="space-between">
          <Typography variant="caption">
            {new Date(createdDate).toLocaleDateString(currentLanguage, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </Typography>
          <Box>
            <Chip
              label={read ? 'Прочитано' : 'Новый'}
              color={read ? 'success' : 'error'}
              size="small"
            />
          </Box>
        </FlexBox>
      </Box>
    </Box>
  )
}

export const NewsList = () => {
  const { fetchNotificationCount } = useNotification()
  const [news, setNews] = useState<any>([])

  const onReadAll = useCallback(() => {
    patchRequest(API.ReadAllNotification)
      .then(() => getRequest(API.NotificationNewsList, { params:{ start:0, limit:20 } })
        .then((res:any) => setNews(res))
        .then(() => fetchNotificationCount())
      )
  }, [])

  const onReadNews = useCallback((id:number) => {
    getRequest(API.ReadNotification, { params:{ newsId: id } })
      .then(() => getRequest(API.NotificationNewsList, { params:{ start:0, limit:20 } })
        .then((res:any) => setNews(res))
      )
  }, [])

  useEffect(() => {
    getRequest(API.NotificationNewsList, { params:{ start:0, limit:20 } })
      .then((res:any) => setNews(res))
  }, [])

  return (
    <Box sx={{
      width:'350px',
      padding:'10px'
    }}>
      <FlexBox align="center" justify="space-between">
        <Typography variant="h6">
          Уведомления
        </Typography>
        <IconButton onClick={onReadAll}>
          <DoneAllIcon />
        </IconButton>
      </FlexBox>
      <FlexBox
        align="flex-start"
        direction="column"
        gap={5}
        style={{
          margin:'10px 0'
        }}
      >
        {news?.map((newsItem:any) => {
          return (
            <NewsCard
              key={newsItem?.id}
              news={newsItem}
              onReadNews={onReadNews}
            />
          )
        })}
      </FlexBox>
    </Box>
  )
}
