import React from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { useLocales } from 'hooks/useLocales'

import CurrencyField from './form/CurrencyField'
import MultipleTerritoryField from './form/MultipleTerritoryField'
import PaymentMethodField from './form/PaymentMethodField'

function PriceLevelUpdateGeneral () {
  const { t } = useLocales('priceLevel')
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} lg={12}>
        <Card>
          <CardHeader title={t('Details')} />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={4}>
                <TextField
                  label={t('Price level name')}
                  name="name"
                  data-cy="name"
                />
              </Grid>
              <Grid item={true} lg={4}>
                <CurrencyField
                  label={t('Currency')}
                  name="currency"
                  ata-cy="currency"
                  disabled={true}
                />
              </Grid>
              <Grid item={true} lg={4}>
                <PaymentMethodField
                  label={t('Payment type')}
                />
              </Grid>
              <Grid item={true} lg={4}>
                <MultipleTerritoryField
                  label={t('Territories')}
                />
              </Grid>
              <Grid item={true} lg={4}>
                <TextField
                  label={t('Description')}
                  name="note"
                  multiline
                  minRows={4}
                  data-cy="name"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PriceLevelUpdateGeneral
