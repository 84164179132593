import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'JANUARY', name: 'ЯНВАРЬ' },
  { value: 'FEBRUARY', name: 'ФЕВРАЛЬ' },
  { value: 'MARCH', name: 'МАРТ' },
  { value: 'APRIL', name: 'АПРЕЛЬ' },
  { value: 'MAY', name: 'МАЙ' },
  { value: 'JUNE', name: 'ИЮНЬ' },
  { value: 'JULY', name: 'ИЮЛЬ' },
  { value: 'AUGUST', name: 'АВГУСТ' },
  { value: 'SEPTEMBER', name: 'СЕНТЯБРЬ' },
  { value: 'OCTOBER', name: 'ОКТЯБРЬ' },
  { value: 'NOVEMBER', name: 'НОЯБРЬ' },
  { value: 'DECEMBER', name: 'ДЕКАБРЬ' },
]

function MonthSelectField (props:any) {
  return (
    <SelectField
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default MonthSelectField
