import { omit, path, prop, propOr } from 'ramda'
import { toPayloadDate } from 'utils/dates'

export const bonusCreateSerializer = (values:any) => {
  const fromDate = toPayloadDate(prop('fromDate', values))
  const toDate = toPayloadDate(prop('toDate', values))
  const discountType = prop('discountType', values)
  const getTerritory = prop('territoryIds', values)
  const territoryIds = getTerritory && getTerritory.map((territory:any) => territory.id)
  const items = propOr(null, 'items', values) as [] | null
  const productId = path(['productId', 'id'], values) ?? ''
  const categoryId = path(['categoryId', 'id'], values) ?? ''
  const getCriteria = prop('criteria', values)
  const criteria = getCriteria && getCriteria.map((item:any) => {
    const code = path(['code', 'code'], item)
    const value = path(['value', 'name'], item) || prop('value', item)
    if (code === 'PRODUCT_CATEGORY') {
      item.value = `${categoryId}@@${item.value}`
    }
    if (code === 'PRODUCT') {
      item.value = `${productId}@@${item.value}`
    }
    return { ...item, code, value }
  })
  const getItems = items && items.map((item:any) => {
    if (discountType === 'ALL') {
      return null
    }
    if (discountType === 'BYPRODUCT') {
      return { entityId:item.productId, rate:item.rate }
    }
    return { entityId:item.productId, qty:item.quantity }
  })
  const result = omit(['discountType', 'productId', 'categoryId'], values)
  return {
    ...result,
    items:getItems,
    criteria,
    fromDate,
    toDate,
    territoryIds
  }
}

export const queryPatternSerializer = (getCriteria: any) => {
  if (!getCriteria || getCriteria.length === 0) {
    return { criteria: '' }
  }

  const groupedCriteria = getCriteria.reduce((acc: any[], criteria: any, index: number) => {
    const criteriaString = `${index + 1}`
    if (index > 0 && criteria.criteriaType === 'AND') {
      // If it's not the first criteria and the criteria type is 'AND', group with the previous criteria
      acc[acc.length - 1] = `(${acc[acc.length - 1]} AND ${criteriaString})`
    } else {
      // Otherwise, add the criteria as is
      acc.push(criteriaString)
    }
    return acc
  }, []).join(' AND ')

  return { criteria: getCriteria.length > 1 ? `(${groupedCriteria})` : groupedCriteria }
}

export const BonusInitialValuesSerializer = (values:any) => {
  let discountType = ''
  const territoryIds = prop('territories', values)
  const items = propOr([], 'items', values) as any
  if (items.length > 0) {
    discountType = 'BYPRODUCT'
  } else {
    discountType = 'ALL'
  }

  return {
    ...values,
    discountType,
    territoryIds
  }
}

export const bonusUpdateSerializer = (values:any) => {
  const fromDate = toPayloadDate(prop('fromDate', values))
  const toDate = toPayloadDate(prop('toDate', values))
  const discountType = prop('discountType', values)
  const getTerritory = prop('territoryIds', values)
  const territoryIds = getTerritory && getTerritory.map((territory:any) => territory.id)
  const items = propOr(null, 'items', values) as [] | null
  const productId = path(['productId', 'id'], values) ?? ''
  const categoryId = path(['categoryId', 'id'], values) ?? ''
  const getCriteria = prop('criteria', values)
  const criteria = getCriteria && getCriteria.map((item:any) => {
    const code = path(['code', 'code'], item)
    const value = path(['value', 'name'], item) || prop('value', item)
    if (code === 'PRODUCT_CATEGORY') {
      item.value = `${categoryId}@@${item.value}`
    }
    if (code === 'PRODUCT') {
      item.value = `${productId}@@${item.value}`
    }
    return { ...item, code, value }
  })
  const getItems = items && items.map((item:any) => {
    if (discountType === 'ALL') {
      return null
    }
    if (discountType === 'BYPRODUCT') {
      return { entityId:item.productId || item.entityId?.id, rate:item.rate }
    }
    return { entityId:item.productId || item.entityId?.id, qty:item.quantity }
  })
  const result = omit(['discountType', 'productId', 'categoryId'], values)
  return {
    ...result,
    items:getItems,
    criteria,
    fromDate,
    toDate,
    territoryIds
  }
}
