import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getUnitMeasurementList = (options?: Options) => {
  return getRequest<any>(API.UnitMeasurementList, options)
}

export const getUnitMeasurementDetail = (options?: Options) => {
  return getRequest<any>(API.UnitMeasurementGetById, options)
}

export const createUnitMeasurement = (options?: Options) => {
  return postRequest<any>(API.UnitMeasurementCreate, options)
}

export const updateUnitMeasurement = (options?: Options) => {
  return putRequest<any>(API.UnitMeasurementUpdate, options)
}

export const deleteUnitMeasurement = (options?: Options) => {
  return deleteRequest<any>(API.UnitMeasurementDelete, options)
}

export const updateStatus = (options?:Options) => {
  return patchRequest<any>(API.UnitMeasurementUpdateStatus, options)
}

export const updateStatusBulk = (options?:Options) => {
  return patchRequest<any>(API.UnitMeasurementUpdateStatusBulk, options)
}

export const unitImportMeasurement = (options?:Options) => {
  return postRequest<any>(API.UnitMeasurementImport, options)
}
