import * as ROUTES from 'constants/Routes'

import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import MUIDivider from '@mui/material/Divider'
import Tabs from 'components/main/Tabs'

export enum COMPANY_EDIT_TABS {
  GENERAL = 'detail',
  HISTORY = 'history'
}

const tabs = [
  { value: COMPANY_EDIT_TABS.GENERAL, label: 'Деталь' },
  { value: COMPANY_EDIT_TABS.HISTORY, label: 'История платежей' },
]

interface Props {
  readonly value: string
}

function CompanyEditTabs ({ value }: Props) {
  const { id } = useParams()
  const navigate = useNavigate()

  const onChange = (event: any, tab: any) => {
    const redirect = generatePath(ROUTES.BACKEND_COMPANY_EDIT, { id, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />

      <MUIDivider sx={{ mb: 3 }} />
    </>
  )
}

export default CompanyEditTabs
