import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'
import { prop } from 'ramda'
import useAllSearchParams from 'utils/useAllSearchParams'
import { useGetDetail } from 'hooks/useGetDetail'

import { initialValues, validationSchema } from '../components/form-values'
import { createSalesOrderReturn } from '../api'
import SaleOrderReturnCreateForm from '../components/SaleOrderReturnCreateForm'
import {
  SaleReturnCreateSerializer,
  SaleReturnCopyInitialSerializer,
  SaleReturnCopyCreateSerializer
} from '../serializer'
import { getSaleOrderDetail } from '../../sale-order/api'

function SaleOrderReturnCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const searchParams = useAllSearchParams()
  const copyId = prop('copyId', searchParams)

  const saleReturnCreate = usePost(createSalesOrderReturn)
  const saleOrderDetail = copyId ? useGetDetail(getSaleOrderDetail, { params: { id: copyId } }) : null as any

  const handleSubmit = useCallback((data: any) => {
    saleReturnCreate.postData({ data: copyId ? SaleReturnCopyCreateSerializer(data)
      : SaleReturnCreateSerializer(data) })
      .then(() => snackbar({ message: 'Возврат успешно создан' }))
      .then(() => navigate(ROUTES.SALE_ORDER_RETURN_LIST))
  }, [])

  const initialCopyValues = useMemo(() => {
    if (copyId) {
      return SaleReturnCopyInitialSerializer(saleOrderDetail.result)
    }
  }, [saleOrderDetail, copyId])

  return (
    <AppLayout>
      {copyId && !saleOrderDetail.loading && (
        <SaleOrderReturnCreateForm
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialCopyValues}
        />
      )}
      {!copyId && (
        <SaleOrderReturnCreateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        />
      )}
    </AppLayout>
  )
}

export default SaleOrderReturnCreateContainer
