import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'
import useScrollList from 'hooks/useScrollList'

import { createBonus, getProductList } from '../api'
import BonusCreateForm from '../components/BonusCreateForm'
import { bonusCreateSerializer } from '../serializer'

function BonusCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const brandCreate = usePost(createBonus)
  const productList = useScrollList(getProductList)

  const handleSubmit = useCallback((values: any) => {
    return brandCreate.postData({ data: bonusCreateSerializer(values) })
      .then(() => snackbar({ message: 'Акция успешно создана' }))
      .then(() => navigate(ROUTES.MARKETING_BONUS_LIST))
  }, [])

  return (
    <AppLayout>
      <BonusCreateForm
        productList={productList}
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default BonusCreateContainer
