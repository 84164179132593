import { dissoc, omit, path, prop, propOr } from 'ramda'

export const agentCreateSerialize = (data: any) => {
  const newAddresses = prop('addresses', data)
  const currency = path(['currency', 'code'], data)
  const customFieldValue = prop('customFields', data)
  const getCategories = prop('categoryIds', data) as any
  const addresses = newAddresses && newAddresses.map((address: any) => {
    const countryId = prop('value', address.countryId)
    const regionId = prop('value', address.regionId)
    return { ...address, regionId, countryId }
  })
  const categoryIds = getCategories && getCategories.map((ids: any) => prop('value', ids))
  return {
    ...data,
    categoryIds,
    addresses,
    currency,
    customFieldValue
  }
}

export const agentDetailSerializer = (data:any) => {
  const categoryIds = propOr([], 'categories', data) as any
  const newAddresses = prop('addressList', data)
  const customFields = prop('customFieldValue', data)
  const addresses = newAddresses && newAddresses.map((address: any) => {
    const countryId = prop('id', address.country)
    const regionId = prop('id', address.region)
    const fieldsRemove = ['country', 'region']
    const addressResult = omit(fieldsRemove, address)
    return { ...addressResult, regionId, countryId }
  })
  return { ...data, addresses, categoryIds, customFields }
}

export const agentUpdateSerializer = (data:any) => {
  const fieldsToRemove = ['territoryList', 'addressList']
  const result = fieldsToRemove.reduce((acc, field) => dissoc(field, acc), data)
  const customFieldValue = prop('customFields', data)
  const newAddresses = prop('addresses', result)
  const getCategories = prop('categoryIds', result) as any
  const currency = path(['currency', 'code'], result)
  const addresses = newAddresses && newAddresses.map((address: any) => {
    const countryId = prop('id', address.countryId)
    const regionId = prop('id', address.regionId)
    return { ...address, regionId, countryId }
  })
  const categoryIds = getCategories && getCategories.map((ids: any) => prop('id', ids))
  return {
    ...result,
    addresses,
    currency,
    categoryIds,
    customFieldValue
  }
}
