import React, { useCallback, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import { prop, propOr } from 'ramda'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, DialogTitle, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useField } from 'formik'
import TableHead from '@mui/material/TableHead'
import InfinityScrollSearch from 'components/form/InfinityScrollSearch'
import { useTranslationI18n } from 'i18n/I18nContext'
import TextOverflow from 'components/main/TextOverflow'
import TableSkeletonList from 'components/table/TableSkeletonList'
import FlexBox from 'components/main/FlexBox'

import { useStyles } from './ShipmentLineItems'

import LineItemsStatusRow from '../LineItemsStatusRow'

const TableRowStyled = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    background: '#0000000A'
  }
})

const DialogContentStyled = styled(DialogContent)({
  padding: '16px',
  position: 'relative',
  height:'760px'
})

interface Props {
  readonly open: boolean
  readonly onClose: () => void
  readonly onAddVariants: (value: any) => void
  readonly packageList: any
}

function ShipmentVariantsAddDialog ({ open, onClose, onAddVariants, packageList }: Props) {
  const { t } = useTranslationI18n()
  const classes = useStyles()
  const lineItemsField = useField('shipmentPaymentIds')
  const lineItemsFieldValue = lineItemsField[0].value || []

  const [selected, setSelected] = useState(lineItemsFieldValue as any)

  const results = propOr([], 'list', packageList) as any
  const count = prop('count', packageList) as number
  const loading = prop('loading', packageList) as boolean

  const searchField = useField('search')
  const setSearchValue = searchField[2]

  const onEscape = useCallback((event: any) => {
    if (event.code === 'Escape') {
      setSearchValue.setValue('')
      onClose()
    }
  }, [onClose])

  const handleCancel = () => {
    setSearchValue.setValue('')
    onClose()
  }
  const handleCheck = (checked: boolean, variant: any) => {
    const id = prop('id', variant)
    if (checked) {
      setSelected((prevSelected: any) => prevSelected.filter((select: any) => {
        return prop('id', select) !== id
      }))
    } else {
      setSelected((prevSelected: any) => [...prevSelected, variant])
    }
  }

  return (
    <Dialog open={open} fullWidth={true} onKeyDown={onEscape}>
      <DialogTitle>
        <Typography component="h2" variant="h6">
          {`${t('Просмотр упаковок')} - ${t('Отмечено')} (${selected.length})`}
        </Typography>
        <Box sx={{ padding: '8px 0' }}>
          <InfinityScrollSearch
            search="search"
            getList={packageList.getList}
          />
        </Box>
      </DialogTitle>
      <DialogContentStyled id="saleOrderVariantTable">
        <InfiniteScroll
          dataLength={results.length}
          hasMore={count > results.length}
          loader=""
          next={() => packageList.getList()}
          scrollableTarget="saleOrderVariantTable"
        >
          <TableContainer className={classes.tableRoot}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell width={100}>{t('Пометить')}</TableCell>
                  <TableCell width={250}>{t('Название')}</TableCell>
                  <TableCell width={250}>{t('Статус')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {results.map((variant: any, index: number) => {
                  const id = prop('id', variant)
                  const checked = selected.find((select: any) => prop('id', select) === id)
                  return (
                    <TableRowStyled onClick={() => handleCheck(checked, variant)}>
                      <TableCell data-cy={`variant-${index}`}>
                        <Checkbox checked={Boolean(checked)} color="secondary" />
                      </TableCell>
                      <TableCell>
                        <TextOverflow lines={1}>
                          {prop('refNumber', variant)}
                        </TextOverflow>
                      </TableCell>
                      <TableCell>
                        <TextOverflow lines={1}>
                          <LineItemsStatusRow status={prop('status', variant)} />
                        </TextOverflow>
                      </TableCell>
                    </TableRowStyled>
                  )
                })}
                {packageList.loading && (
                  <TableSkeletonList columnsCount={2} />
                )}
                {!loading && results.length === 0 && (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>No Information</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </DialogContentStyled>
      <DialogActions >
        <FlexBox align="center" gap="5px">
          <Button
            color="secondary"
            variant="contained"
            onClick={handleCancel}
            fullWidth={true}
          >
            Отменить
          </Button>
          <Button
            data-cy="add"
            variant="contained"
            fullWidth={true}
            onClick={() => onAddVariants(selected)}
          >
            Добавить
          </Button>
        </FlexBox>
      </DialogActions>
    </Dialog>
  )
}

export default ShipmentVariantsAddDialog
