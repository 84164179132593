import * as API from 'constants/Api'

import React, { useCallback } from 'react'
import FlexBox from 'components/main/FlexBox'
import TextField from 'components/form/TextField'
import { Button } from '@mui/material'
import { useField } from 'formik'
import { getRequest } from 'api/BaseApi'
import { path } from 'ramda'

function InnField (props: any) {
  const [ field, , ] = useField(props.name)
  const [,, setNameField] = useField(`juridicalList[${props.index}].name`)
  const [,, setTaxIdField] = useField(`juridicalList[${props.index}].taxId`)
  const [,, setFianancialDeatilsBankAccountName] = useField(`financialDetails[${props.index}].bankAccountName`)
  const [,, setFianancialDeatilsMfo] = useField(`financialDetails[${props.index}].mfo`)
  const [,, setFianancialDeatilsBankAccountNumber] = useField(`financialDetails[${props.index}].bankAccountNumber`)
  const [,, setFianancialDeatilsOked] = useField(`financialDetails[${props.index}].oked`)
  const onGetDetail = useCallback(() => {
    getRequest(API.ContactOrganizationByINN, { params:{ inn:field.value } })
      .then((response:any) => {
        const bankAccountName = path(['Accounts', '0', 'BankName'], response) as any
        const mfo = path(['Accounts', '0', 'BankMfo'], response) as any
        const bankAccount = path(['Accounts', '0', 'AccountCode'], response) as any
        setNameField.setValue(response?.CompanyName)
        setTaxIdField.setValue(response?.VatCode)
        setFianancialDeatilsBankAccountName.setValue(bankAccountName)
        setFianancialDeatilsMfo.setValue(mfo)
        setFianancialDeatilsBankAccountNumber.setValue(bankAccount)
        setFianancialDeatilsOked.setValue(response?.Oked)
      })
  }, [field.value])

  return (
    <FlexBox align="center" gap={8}>
      <TextField
        {...props}
      />
      <Button
        type="button"
        onClick={onGetDetail}
        sx={{
          height:'40px',
          padding:'6px 10px',
          fontSize:'13px'
        }}
      >
        Проверять
      </Button>
    </FlexBox>
  )
}

export default InnField
