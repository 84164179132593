import * as API from 'constants/Api'
import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { prop } from 'ramda'
import MultiSelectSearchField from 'components/form/MultiSelectSearchField'
import useDialog from 'hooks/useDialog'
import { useGetList } from 'hooks/useGetList'
import { useField } from 'formik'

import MultipleTerritoryAddDialog from './MultipleTerritoryDialog'

import { territoryListSuggestions } from '../../api'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value, name, id: value }
  }

  return null
}

function MultipleTerritoryField (props: any) {
  const tableDialog = useDialog()
  const tableRequest = useGetList(territoryListSuggestions)
  const [field, , helpers] = useField('territories')
  const handleVariantsAdd = useCallback((packages: any) => {
    const lineItemPackageIds = new Set(field.value.map((item: any) => prop('id', item)))

    const uniquePackages = packages.filter((item: any) => {
      const packageId = prop('id', item)
      return !lineItemPackageIds.has(packageId)
    })

    const otherLineItems = field.value.filter((lineItem: any) => {
      return packages.find((item: any) => prop('id', item) === prop('id', lineItem))
    })

    helpers.setValue([...otherLineItems, ...uniquePackages])
    // setSearchValue.setValue('')
    tableDialog.handleClose()
  }, [])

  return (
    <>
      <MultiSelectSearchField
        name="territories"
        api={API.TerritorySuggestions}
        getOptionValue={getOptionValue}
        onOpenDialog={tableDialog.handleOpen}
        onAddRoute={ROUTES.SETTINGS_TERRITORY_CREATE}
        label={props.label}
        {...props}
      />
      {tableDialog.open && (
        <MultipleTerritoryAddDialog
          packageList={tableRequest}
          onAddVariants={handleVariantsAdd}
          open={tableDialog.open}
          onClose={tableDialog.handleClose}
        />
      )}
    </>

  )
}

export default MultipleTerritoryField
