import * as ROUTES from 'constants/Routes'

import * as Yup from 'yup'
import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import useScrollList from 'hooks/useScrollList'
import { usePost } from 'hooks/usePost'
import { useNavigate } from 'react-router'
import { useUser } from 'context/user'
import { useSnackbar } from 'context/snackbar'

import TransferCreateForm from '../components/TransferCreateForm'
import { createTransfer, getStockList } from '../api'
import { transferSerializer } from '../serializer'

export const validationSchema = Yup.object().shape({
  fromWarehouseId: Yup.object({
    id: Yup.number(),
    name: Yup.string()
  }).required('Требуется со склада'),
  toWarehouseId: Yup.object({
    id: Yup.number().nullable(),
    name:Yup.string()
  }).required('Требуется на складе')
    .test('not-same-warehouse', 'Тот же склад не рекомендуется, пожалуйста, выберите другой склад',
      function (value) {
        const fromWarehouseId:Record<string, number> = this.resolve(Yup.ref('fromWarehouseId'))
        const toWarehouseId = value

        return !(fromWarehouseId && toWarehouseId && fromWarehouseId.id === toWarehouseId.id)
      })
})

function TransferCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const productList = useScrollList(getStockList)
  const transferCreate = usePost(createTransfer)
  const { user } = useUser()

  const handleSubmit = useCallback((values: any) =>
    transferCreate.postData({ data: transferSerializer({ ...values, companyId: user.userCompanyId }) })
      .then(() => navigate(ROUTES.WAREHOUSE_MOVEMENT_LIST))
      .then(() => snackbar({ message: 'Приход успешно создан' }))
  , [])

  return (
    <AppLayout>
      <TransferCreateForm
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{ fromWarehouseId:null, toWarehouseId:null }}
        productList={productList}
      />
    </AppLayout>
  )
}

export default TransferCreateContainer
