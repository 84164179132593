import React, { useEffect, useState } from 'react'
import { prop } from 'ramda'
import { FieldArray, useField } from 'formik'
import { useGetList } from 'hooks/useGetList'
import { Grid } from '@mui/material'
import SingleRow from 'components/row/Row'
import FlexBox from 'components/main/FlexBox'

import { getAvailableColumns } from '../../../../api'
import { getTranslatedColumn } from '../../../../translations'

function FilterColumns () {
  const api = useGetList(getAvailableColumns, { params: { type: 'SHIPMENT_LIST_PANEL' } })
  const [columns, setColumns] = useState([] as any)
  const loading = prop('loading', api)
  const [field, meta] = useField('columns')

  useEffect(() => {
    api.getList()
      .then((response: any) => {
        if (meta.initialValue) {
          const uniqueColumns = response.filter((col: any) =>
            !meta.initialValue.some((existingCol: any) => existingCol.code === col.code))
          setColumns([...meta.initialValue, ...uniqueColumns])
        }
      })
  }, [])

  useEffect(() => {
    if (field.value) {
      const uniqueColumns = columns.filter((col: any) =>
        !field.value.some((existingCol: any) => existingCol.code === col.code))
      setColumns([...field.value, ...uniqueColumns])
    }
  }, [field.value])

  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} lg={12}>
        <FieldArray name="columns">
          {() => (
            <FlexBox direction="column" gap={5}>
              {!loading && columns.map((column: any, index: number) => (
                <SingleRow
                  column={column}
                  index={index}
                  toggleFieldName={column.code}
                  rowTitle={getTranslatedColumn(column.code, column.name)}
                />
              ))}
            </FlexBox>
          )}
        </FieldArray>
      </Grid>
    </Grid>
  )
}

export default FilterColumns
