import React from 'react'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import withForm, { WithFormProps } from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import PhoneField from 'components/form/PhoneField'
import { FieldArray, useFormikContext } from 'formik'
import { propOr } from 'ramda'

import UserStatusField from './form/UserStatusField'
import ImageUpload from './form/UserImageUpload'
import CountryField from './form/CountryField'
import RegionField from './form/RegionField'
import TerritoryField from './form/TerritoryField'
import EmployeeRoleField from './form/EmployeeRoleField'

interface Props extends WithFormProps {
  readonly form?: any
}

function CompanyCreateUserForm ({ form }:Props) {
  const { values } = useFormikContext()
  const addresses = propOr([], 'addresses', values) as any
  const territoryRoles = propOr([], 'territoryRoles', values) as any
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={`Добавить пользователя для компании ${form.initialValues.companyName}`}
          rightButton={(
            <>
              <Button
                type="submit"
              >
                Создать
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Сведения о пользователе" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={3}>
                    <TextField label="Имя" name="fullName" />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <TextField
                      label="Электронная почта"
                      name="email"
                      type="email"
                      data-cy="email"
                    />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <TextField
                      label="Пароль"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      data-cy="password"
                    />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <PhoneField
                      name="phone"
                      value="998"
                      data-cy="phone"
                    />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <TextField label="Телеграм ID" name="telegramId" />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <UserStatusField />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <TextField label="Реферер" name="referrer" />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Территория и роли" />
              <FieldArray name="territoryRoles">
                {({ push, remove }: any) => (
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      {territoryRoles.map((territory: any, index: number) => (
                        <Grid item={true} key={index} lg={12}>
                          <Grid container={true} spacing={3}>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TerritoryField
                                name={`territoryRoles[${index}].territoryId`}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <EmployeeRoleField
                                form={form}
                                name={`territoryRoles[${index}].roles`}
                              />
                            </Grid>
                            {index > 0 && (
                              <Grid item xs={8} lg={2}>
                                <Button onClick={() => remove(index)}>Удалить</Button>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {territoryRoles.length === 0 && (
                        <Grid item={true} lg={6}>
                          <Button
                            onClick={() => {
                              push({
                                territoryId: '',
                                roles: ''
                              })
                            }}
                          >
                            Добавить территорию
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                )}
              </FieldArray>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Адрес" />
              <FieldArray name="addresses">
                {({ push, remove }: any) => (
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      {addresses.map((contact: any, index: number) => (
                        <Grid item={true} key={index} lg={12}>
                          <Grid container={true} spacing={3}>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <CountryField
                                label="Страна"
                                name={`addresses[${index}].countryId`}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <RegionField
                                label="Область"
                                name={`addresses[${index}].regionId`}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                label="Адрес"
                                name={`addresses[${index}].name`}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                label="Ориентир"
                                name={`addresses[${index}].landmark`}
                              />
                            </Grid>
                            {index > 0 && (
                              <Grid item xs={8} lg={2}>
                                <Button onClick={() => remove(index)}>Удалить</Button>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      <Grid item={true} lg={6}>
                        <Button
                          onClick={() => {
                            push({
                              name:'',
                              landmark:'',
                              countryId:'',
                              regionId:''
                            })
                          }}
                        >
                          Добавить адрес
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                )}
              </FieldArray>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <ImageUpload />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(CompanyCreateUserForm)
