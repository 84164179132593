import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Grid } from '@mui/material'

import EmployeeField from './form/EmployeeField'

interface Props {
  readonly warehouseNames:string
}

function WarehouseBulkForm ({ warehouseNames }:Props) {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={`Добавить прекрепить (${warehouseNames})`}
          rightButton={(
            <>
              <Button type="submit">Добавить</Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item lg={4}>
                    <EmployeeField />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(WarehouseBulkForm)
