import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'sales_order', name: 'Заказ' },
  { value: 'shipment', name: 'Доставка' },
]

function ApproveTypeField (props:any) {
  return (
    <SelectField
      name="type"
      label="Тип одобрения"
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default ApproveTypeField
