import React, { useCallback } from 'react'
import { Box, Card, CardContent, Divider, Grid, IconButton, Skeleton, Tooltip } from '@mui/material'
import { useParams } from 'react-router-dom'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import { usePost } from 'hooks/usePost'
import CardHeader from 'components/card/CardHeader'
import Dropzone from 'components/upload/Dropzone'
import { useDelete } from 'hooks/useDelete'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import ExcelIcon from 'assets/excel-icon.svg'
import WordIcon from 'assets/word-icon.svg'
import PdfIcon from 'assets/pdf-icon.svg'
import PptIcon from 'assets/ppt-icon.svg'
import TextIcon from 'assets/text-icon.svg'

import { shipmentAttachmentDelete, uploadFiles } from '../api'

interface Props {
  readonly onFileSet?: any
  readonly files?: any
  readonly onFileDelete: any
  readonly disabled:boolean
}

const getExtension = (filename:string) => {
  return filename.split('.').pop()
}

const imageFiles = (fileType:any) => {
  if (fileType === 'xlsx' || fileType === 'xls') {
    return ExcelIcon
  }
  if (fileType === 'doc' || fileType === 'docx') {
    return WordIcon
  }
  if (fileType === 'pdf') {
    return PdfIcon
  }
  if (fileType === 'ppt' || fileType === 'pptx') {
    return PptIcon
  }
  if (fileType === 'txt') {
    return TextIcon
  }
}

function AttachmentField ({ onFileSet, files = [], onFileDelete, disabled }: Props) {
  const { id } = useParams()
  const fileUpload = usePost(uploadFiles)
  const contactDeleteFile = useDelete(shipmentAttachmentDelete)
  const onDrop = useCallback((values: any = []) => {
    const data = new FormData()
    values.forEach((file: any) => {
      data.append('uploadFile', file)
    })
    fileUpload.postData({ data }).then((response) => {
      onFileSet(response)
    })
  }, [])

  const handleDelete = useCallback((attachmentId: any) => {
    contactDeleteFile.deleteData({ params: { id, attachmentId } }).then(() => {
      onFileDelete(attachmentId)
    })
  }, [])
  return (
    <Card>
      <CardHeader title="Добавить вложение для доставки" />
      <CardContent>
        <Grid container={true} spacing={1}>
          <Grid item={true} lg={2}>
            <Dropzone onDrop={onDrop} />
          </Grid>
          {files?.map((item: any) => {
            const originalName = prop('originalName', item)
            const link = prop('link', item) as string
            const checkFile = getExtension(link)
            const imageLink = imageFiles(checkFile) ? imageFiles(checkFile) : link
            const id = prop('id', item)
            return (
              <Grid item={true} lg={2}>
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'flex-end',
                    justifyContent:'center',
                    position: 'relative',
                    width:'200px',
                    height:'200px',
                    background:'#F8F8F8',
                    borderRadius:'8px 8px 0 0',
                  }}
                >
                  <IconButton
                    sx={{
                      position: 'absolute',
                      fontSize: 25,
                      color: 'white',
                      cursor: 'pointer',
                      right:'25px',
                      top:'15px'
                    }}
                    onClick={() => handleDelete(id)}
                    disabled={disabled}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <img
                    src={imageLink}
                    alt={originalName}
                    style={{
                      height: 150,
                      width:150,
                      borderRadius:'8px 8px 0 0',
                      objectFit: 'cover',
                      cursor:'pointer'
                    }}
                  />
                </Box>
                <Tooltip title={originalName}>
                  <Typography
                    noWrap
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '200px',
                      marginTop:'8px'
                    }}
                  >
                    {originalName}
                  </Typography>
                </Tooltip>
              </Grid>
            )
          })}
          {fileUpload.loading && (
            <Grid item={true} lg={3}>
              <Box
                sx={{
                  display:'flex',
                  alignItems:'flex-end',
                  justifyContent:'center',
                  width:'200px',
                  height:'200px',
                  background:'#F8F8F8',
                  borderRadius:'8px 8px 0 0',
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={150}
                  sx={{
                    borderRadius:'8px 8px 0 0'
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default AttachmentField
