import React, { useEffect } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useField } from 'formik'
import { path } from 'ramda'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles({
  labels:{
    // width:'98px',
    '&.MuiFormControlLabel-root':{
      '& .MuiTypography-root':{
        fontSize:'13px',
        lineHeight:'15.73px',
      },
      color:'#7C7C7C',
      marginRight:'0',
      marginLeft:0
    },
  },
  activeLabelRoot:{
    color:'#202020 !important'
  },
  switchToggle: {
    '&.MuiSwitch-root': {
      width: '44px',
      height: '24px',
      padding: '0',
      '&.withLabel':{
        marginLeft:'15px',
      }
    },
    '& .MuiSwitch-switchBase': {
      padding: '0',
      margin: '4px',
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#1557FF',
          opacity: '1',
          border: '0'
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: '0.5'
        }
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#C3C3C3'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity:'0.5',
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: '16px',
      height: '16px',
      position:'relative',
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        width: 1,
        height: 8,
        backgroundColor: '#C3C3C3',
        transform: 'translateY(-50%)',
      },
      '&::before': {
        left: 6,
      },
      '&::after': {
        right: 6,
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#C3C3C3',
      opacity: '1',
    }
  }
})

function SwitchField ({ name, label, ...props }: any) {
  const field = useField(name)
  const fieldValue = path([0, 'value'], field)
  const fieldSetValue = path([2, 'setValue'], field)
  const classes = useStyles()

  useEffect(() => {
    if (!fieldValue) {
      fieldSetValue(false)
    }
    if (props.checked === false) {
      fieldSetValue(false)
    }
  }, [fieldValue, props.checked])

  return (
    <FormControlLabel
      className={`${classes.labels} ${fieldValue ? classes.activeLabelRoot : ''}`}
      control={
        <Switch
          className={cx(classes.switchToggle, { 'withLabel' : label })}
          checked={fieldValue}
          onChange={() => fieldSetValue(!fieldValue)}
          inputProps={{ 'aria-label': 'controlled' }}
          {...props}
        />
      }
      label={label}
      labelPlacement="start"
      {...props} />
  )
}

export default SwitchField
