import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Grid } from '@mui/material'
import { FormikFormProps, FormikProps } from 'formik'
import { useLocales } from 'hooks/useLocales'

import StatusField from './form/StatusField'

interface Props{
  readonly form:FormikProps<FormikFormProps>
}

function CountryCreateForm ({ form }:Props) {
  const { t } = useLocales('country')
  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={t('Create country')}
          rightButton={(
            <>
              <Button
                type="submit"
                disabled={true}
              >
                {t('Create')}
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title={t('Details')} />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label={t('Country name')}
                      name="name"
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <StatusField
                      label={t('Status')}
                    />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField
                      label={t('Description')}
                      name="notes"
                      multiline={true}
                      minRows={4}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(CountryCreateForm)
