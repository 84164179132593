import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getTransferCashList = (options?: Options) => {
  return getRequest<any>(API.TransferCashList, options)
}

export const transferCashDetail = (options?:Options) => {
  return getRequest<any>(API.TransferCashDetail, options)
}

export const transferCashCreate = (options?:Options) => {
  return postRequest<any>(API.TransferCashCreate, options)
}

export const transferCashDelete = (options?:Options) => {
  return deleteRequest<any>(API.TransferCashDelete, options)
}

export const transferCashUpdate = (options?:Options) => {
  return putRequest<any>(API.TransferCashUpdate, options)
}

export const transferCashUpdateStatus = (options?:Options) => {
  return patchRequest<any>(API.TransferCashUpdateStatus, options)
}
