import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { validationSchema } from './SharedContactCreateContainer'

import { getByIdContact, updateContact } from '../api'
import SharedContactUpdateForm from '../components/SharedContactUpdateForm'
import { agentDetailSerializer, agentUpdateSerializer } from '../serializer'

function SharedContactUpdateContainer () {
  const snackbar = useSnackbar()
  const { id } = useParams()
  const navigate = useNavigate()
  const agentDetail = useGetDetail(getByIdContact, { params: { id } })
  const agentUpdate = usePut(updateContact)

  const handleSubmit = useCallback((values: any) => {
    return agentUpdate.putData({ data: agentUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Поделенный контакт успешно обновлён' }))
      .then(() => navigate(ROUTES.BACKEND_SHARED_CONTACT_LIST))
  }, [])

  const initialValues = useMemo(() => agentDetailSerializer(agentDetail.result), [agentDetail])

  return (
    <AppLayout>
      {!agentDetail.loading && (
        <SharedContactUpdateForm
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        />
      )}
      {agentDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default SharedContactUpdateContainer
