import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'
import { useConfirm } from 'context/confirm'
import { propOr, trim } from 'ramda'

import { countEmployee, deleteEmployee, getEmployeeList, statusEmployee } from '../api'
import EmployeeRowMenu from '../components/EmployeeRowMenu'
import EmployeeStatusRow from '../components/EmployeeStatusRow'
import { transformTabs } from '../serializer'

const columns = [
  {
    headerName: 'Имя',
    field: 'fullName',
  },
  {
    headerName: 'Контактный телефон',
    field: 'phone',
  },
  {
    headerName: 'Контактная почта',
    field: 'email',
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell:(item:any) => <EmployeeStatusRow status={item.active} />
  },
  {
    headerName: 'Последний дата изменения',
    field: 'updatedDate',
  },
  {
    headerName: 'Последний дата изменения сотрундиком',
    field: 'updatedBy',
  }
]

function EmployeeListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const [status, setStatus] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const employeeList = useGetList(getEmployeeList, { query: { status } })
  const employeeDelete = useDelete(deleteEmployee)
  const employeeCount = useGetDetail(countEmployee)
  const employeeStatusChange = usePut(statusEmployee)
  const tabs = transformTabs(employeeCount.result as any)
  const handleEmployeeProduct = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить сотрудника ${values.firstName} ${values.lastName}`

    onConfirm({ message })
      .agree(() => {
        employeeDelete.deleteData({ params: { id: values.profileId } })
          .then(() => snackbar({ message: 'Сотрудник успешно удалён' }))
          .then(() => employeeCount.getDetail())
          .then(() => employeeList.getList({ query: { status } }))
      })
      .disagree()
  }, [status])

  const onStatusChange = useCallback((item: any) => {
    employeeStatusChange.putData({ params: { userId: item.profileId } })
      .then(() => snackbar({ message: 'Статус успешно обновлён' }))
      .then(() => employeeCount.getDetail())
      .then(() => employeeList.getList({ query: { status } }))
  }, [status])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    employeeList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Сотрудники"
        onAddClick={() => navigate(ROUTES.HR_EMPLOYEE_CREATE)}
        dataRequest={employeeList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={tabs}
        status={status}
        setStatus={setStatus}
        checkbox={true}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        actions={(row) => (
          <EmployeeRowMenu
            item={row}
            handleStatus={onStatusChange}
            handleDelete={handleEmployeeProduct}
          />
        )}
        primaryKey={'profileId'}
      />
    </AppLayout>
  )
}

export default EmployeeListContainer
