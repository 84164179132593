import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'

import { billingPLanCategoryUpdate, billingPLanCategoryGetById } from '../api'
import BillingPlanCategoryUpdateForm from '../components/BillingPlanCategoryUpdateForm'

function BillingPlanCategoryUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const sharedContactCategoryById = useGetDetail(billingPLanCategoryGetById, { params: { id } })
  const updateSharedContactCategory = usePut(billingPLanCategoryUpdate)

  const handleSubmit = useCallback((values: any) => {
    return updateSharedContactCategory.putData({ data: values })
      .then(() => snackbar({ message: 'Категория тарифного плана успешно обновлена' }))
      .then(() => navigate(ROUTES.BACKEND_SHARED_CONTACT_CATEGORY_LIST))
  }, [])

  return (
    <AppLayout>
      {!sharedContactCategoryById.loading && (
        <BillingPlanCategoryUpdateForm
          onSubmit={handleSubmit}
          initialValues={sharedContactCategoryById.result}
        />
      )}
    </AppLayout>
  )
}

export default BillingPlanCategoryUpdateContainer
