import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { propOr, trim } from 'ramda'
import { useGetDetailWithoutCall } from 'hooks/useGetDetailWithoutCall'
import { useRoutes } from 'context/route'
import { usePatchList } from 'hooks/usePatchList'
import { usePost } from 'hooks/usePost'

import { contactFilterPreview, deleteContact, getContactList, getExportContact, tabDetail, tabsCounts } from '../api'
import AgentRowMenu from '../components/AgentRowMenu'
import MoreActionButtons from '../components/MoreRowMenu'
import AgentExcelImport from '../components/AgentExcelImport'
import Filter from '../components/filter/Filter'
import ColumnSettingList from '../components/column-settings/ColumnSettingList'
import TabsList from '../components/tabs/TabsList'
import SortingList from '../components/sorting/SortingList'
import { contactFilterPreviewSerializer } from '../serializer'
import { getColumnsBySetting } from '../components/AgentFilterListColumn'
import DownloadingDialogue from '../components/DownloadingDialogue'

const columns = [
  {
    headerName: 'Юр. наименование',
    field: 'name',
    renderCell: (item: any) => item.name && item.name.length ? item.name.join(', ') : ''
  },
  {
    headerName:'Короткое имя',
    field: 'shortName'
  },
  {
    headerName: 'Телефон',
    field: 'number'
  },
  {
    headerName: 'Обновлено',
    field: 'updatedDate'
  }
]

function SupplierListContainer () {
  const { isHasActiveTab } = useRoutes()
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const agentList = useGetList(getContactList)
  const agentDelete = useDelete(deleteContact)
  const listingTabsRecords = useGetDetailWithoutCall(tabsCounts)
  const listingTabDetail = useGetDetailWithoutCall(tabDetail)
  const contactExport = usePost(getExportContact)
  const [tabId, setTabId] = useState('') as any
  const [limit, setLimit] = useState(10)
  const [filter, setFilter] = useState({})
  const [filterPreviewData, setFilterPreviewData] = useState({}) as any
  const [settingColumns, setSettingColumns] = useState([])
  const [sorting, setSorting] = useState({}) as any
  const agentFilterPreview = usePatchList(contactFilterPreview, {
    data: contactFilterPreviewSerializer(filterPreviewData)
  })
  const lastActiveTab = isHasActiveTab('agent')
  const lastActiveTabId = propOr('', 'tabId', lastActiveTab) as number
  const listGet = Object.keys(filterPreviewData).length === 0 ? agentList : agentFilterPreview
  const tabs = propOr([], 'result', listingTabsRecords) as any

  useEffect(() => {
    const updateDetails = (id:number) => {
      listingTabDetail.getDetail({ params: { id } })
        .then((res) => {
          const settings = propOr({}, 'listingColumn', res) as any
          const filter = propOr({}, 'listingFilter', res) as any
          const sorting = propOr({}, 'listingSort', res)
          setFilter(filter)
          setSorting(sorting)
          setSettingColumns(settings)
        })
    }
    if (lastActiveTabId) {
      updateDetails(lastActiveTabId)
    }
  }, [tabId, lastActiveTabId])

  const getColumns = useMemo(() => {
    const settingId = propOr('', 'id', settingColumns)
    if (settingId) {
      return getColumnsBySetting(settingColumns)
    } else return columns
  }, [settingColumns])

  useEffect(() => {
    if (Object.keys(filterPreviewData).length > 0) {
      listGet.getList()
    } if (filterPreviewData.length === 0) {
      listGet.getList({ query:{ tabId:lastActiveTabId } })
    }
  }, [filterPreviewData])

  const handleDeleteAgent = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить контрагент ${values.name}`

    onConfirm({ message })
      .agree(() => {
        agentDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Поставщик успешно удалён' }))
          .then(() => agentList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    agentList.getList({ query: { searchKey: search } })
  }, [])

  const onFilter = useCallback((item: any) => {
    const limit = propOr(10, 'limitRow', item) as number
    setLimit(limit)
    setTabId(item.id)
    setFilter(item)
  }, [])

  const onExport = useCallback(() => {
    const title = 'Предупреждение: Полный экспорт с включением медиафайлов'
    const message = `Вы собираетесь выполнить полный экспорт данных, включая все
    медиафайлы. Эта операция может включать большой объем данных и занять некоторое время.
    Вы уверены, что хотите продолжить?
    `
    const confirmNoMessage = 'Отмена'
    const confirmYesMessage = 'Да, продолжить экспорт'
    onConfirm({ title, message, confirmNoMessage, confirmYesMessage })
      .agree(() => {
        contactExport.postData({ responseType: 'blob' })
          .then((response: any) => {
            // Convert response to blob
            const blob = new Blob([response])

            // Create a download link
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.setAttribute('download', `Contact.xlsx`)
            document.body.appendChild(link)
            link.click()

            // Cleanup
            window.URL.revokeObjectURL(link.href)
          })
      })
      .disagree()
  }, [])

  const onSetColumn = useCallback((item: any) => {
    setSettingColumns(item)
  }, [])

  const onFilterPreview = useCallback((filter:any) => {
    setFilterPreviewData(filter)
  }, [])

  const onSetTab = useCallback((tabId:number) => {
    setTabId(tabId)
  }, [])

  return (
    <AppLayout>
      <Table
        title="Контрагенты"
        onAddClick={() => navigate(ROUTES.FINANCE_AGENT_CREATE)}
        dataRequest={listGet}
        columns={getColumns}
        listingTableName="agent"
        tabRequest={listingTabsRecords}
        onSearchClick={onTableSearch}
        lastActiveTab={lastActiveTab}
        tabId={tabId}
        setTabId={setTabId}
        tabs={tabs}
        checkbox={true}
        limit={limit}
        setLimit={setLimit}
        searchPlaceholder="Поиск по названию, телефону, короткому имени, ИНН или ID"
        exportNode={(
          <AgentExcelImport
            list={agentList}
          />
        )}
        actions={(row) => (
          <AgentRowMenu
            item={row}
            handleDelete={handleDeleteAgent}
          />
        )}
        moreActions={(actions) => (
          <MoreActionButtons
            actions={actions}
            onExport={onExport}
          />
        )}
        filterNode={(
          <Filter
            filter={filter}
            onFilter={onFilter}
            onFilterPreview={onFilterPreview}
            filterPreviewData={filterPreviewData}
          />
        )}
        columnNode={(
          <ColumnSettingList
            onFilter={onSetColumn}
          />
        )}
        tabNode={(
          <TabsList
            onFilter={onSetTab}
          />
        )}
        sortingNode={(
          <SortingList
            sorting={sorting}
          />
        )}
      />
      {contactExport.loading && (
        <DownloadingDialogue open={contactExport.loading} />
      )}
    </AppLayout>
  )
}

export default SupplierListContainer
