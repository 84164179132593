import React, { ReactNode } from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles({
  root: {
    zIndex: 2,
    width: '100vw',
    height: '100%',
    position: 'absolute',
    top: 0,
    pointerEvents: 'none',
    visibility: 'hidden',
    opacity: '0',
    left: 0,
    transition: 'all .15s linear',
    overflow:'hidden',
    '&.open': {
      pointerEvents: 'all',
      visibility: 'visible',
      opacity: '1',
    }
  },
  overlay: {
    width: '100%',
    height: '100%',
    overflow:'hidden'
  },
  panel: {
    height: '100%',
    maxWidth:'100%',
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'rgba(217, 217, 217, 0.2)',
    boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.25)',
    transform: 'translateX(100%)',
    transition: 'all .15s linear',
    backdropFilter: 'blur(15px)',
    '&.open': {
      transform: 'translateX(0)'
    }
  },
  header: {
    padding: '20px 30px',
    background: 'rgba(253, 253, 253, 0.5)',
    backdropFilter: 'blur(15px)'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
  }
})

interface Props {
  readonly open: boolean
  readonly children: ReactNode
  readonly onClose: () => void
}

export function FilterPopup (props: Props) {
  const {
    open,
    children,
    onClose
  } = props
  const classes = useStyles()

  return (
    <div className={cx(classes.root, { 'open': open })}>
      <div className={classes.overlay} onClick={onClose} />
      <div className={cx(classes.panel, { 'open': open })}>
        {children}
      </div>
    </div>
  )
}
