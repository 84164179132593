import * as ROUTES from 'constants/Routes'

import React from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { pathParams } from 'path-params'
import { useNavigate } from 'react-router'
import { prop } from 'ramda'

interface Props {
  readonly item: any
  readonly onReturned:(values:number) => void
  readonly onCollected:(values:number) => void
  readonly onDriverAssign:(values:any) => void
  readonly onProcess: (id: number) => void
  readonly onComplete: (id: number) => void
  readonly onSetPacker: (id: number) => void
}

function WarehouseGroupRowMenu ({
  item,
  onProcess,
  onComplete,
  onSetPacker,
  onDriverAssign,
  onCollected,
  onReturned
}: Props) {
  const navigate = useNavigate()
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null)

  const anchorIsOpen = Boolean(optionsAnchorEl)

  const handleOpenOptions = (event: any) => {
    setOptionsAnchorEl(event.currentTarget)
  }

  const handleCloseOptions = () => {
    setOptionsAnchorEl(null)
  }

  const id = prop('id', item)
  const status = prop('status', item)
  const updatedURL = pathParams(ROUTES.SALE_ORDER_SHIPMENT_UPDATE, { id })
  const isHasSalesReturn = prop('salesReturn', item)
  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={anchorIsOpen ? 'long-menu' : undefined}
        aria-expanded={anchorIsOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenOptions}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="table-item-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={optionsAnchorEl}
        open={anchorIsOpen}
        onClose={handleCloseOptions}
      >
        <MenuItem
          onClick={() => {
            navigate(updatedURL)
            handleCloseOptions()
          }}
        >
          Просмотр
        </MenuItem>
        {(status === 'BOOKED' && isHasSalesReturn) && (
          <MenuItem
            onClick={() => {
              onDriverAssign(item.id)
              handleCloseOptions()
            }}
          >
            Назначить водителя
          </MenuItem>
        )}
        {(status === 'DRIVER_ASSIGNED' && isHasSalesReturn) && (
          <MenuItem
            onClick={() => {
              onCollected(item.id)
              handleCloseOptions()
            }}
          >
            Собрать
          </MenuItem>
        )}
        {(status === 'COLLECTED' && isHasSalesReturn) && (
          <MenuItem
            onClick={() => {
              onReturned(item.id)
              handleCloseOptions()
            }}
          >
            Вернуть
          </MenuItem>
        )}
        {(status === 'PACKED' || status === 'SHIPPING') && !isHasSalesReturn && (
          <MenuItem
            onClick={() => {
              onProcess(item.id)
              handleCloseOptions()
            }}
          >
            Передать Водителю
          </MenuItem>
        )}
        {(status === 'PICKED' || status === 'PACKED') && isHasSalesReturn && (
          <MenuItem
            onClick={() => {
              onSetPacker(item.id)
              handleCloseOptions()
            }}
          >
            Задать упаковщика
          </MenuItem>
        )}
        {status !== 'COMPLETED' && !isHasSalesReturn && (
          <MenuItem
            onClick={() => {
              onComplete(item.id)
              handleCloseOptions()
            }}
          >
            Завершить
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  )
}

export default WarehouseGroupRowMenu
