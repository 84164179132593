import { prop } from 'ramda'

export const discountCreateSerializer = (values:any) => {
  const getTerritories = prop('territoryIds', values)
  const territoryIds = getTerritories && getTerritories.map((item:any) => item.id)
  return {
    ...values,
    territoryIds
  }
}

export const discountDetailSerializer = (values:any) => {
  const territoryIds = prop('territories', values)
  return {
    ...values,
    territoryIds
  }
}

export const discountUpdateSerializer = (values:any) => {
  const getTerritories = prop('territoryIds', values)
  const territoryIds = getTerritories && getTerritories.map((item:any) => item.id)
  return {
    ...values,
    territoryIds
  }
}
