import { createTheme } from '@mui/material'

export const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        textPrimary:{
          backgroundColor: '#1F50FF',
          color: 'white',
          fontSize: '14px',
          lineHeight:'16px',
          padding:'12px 15px',
          fontWeight: 500,
          textTransform: 'unset',
          borderRadius: '5px',
          '&:hover': {
            backgroundColor: '#1f48d6',
          },
          '&:disabled':{
            background:'rgba(136,169,254, 0.7)',
            color:'#fff'
          },
        },
        containedSecondary:{
          background:'#202020!important',
          color:'#fff!important',
          fontSize: '13px',
          lineHeight:'16px',
          fontWeight:500,
          padding:'12px 15px'
        },
        containedPrimary:{
          fontSize: '13px',
          lineHeight:'16px',
          fontWeight:500,
          padding:'12px 15px'
        },
        textInfo:{
          fontSize: '13px',
          lineHeight:'16px',
          fontWeight:500,
          padding:'8px 16px'
        }
      },
    },
    MuiIconButton:{
      styleOverrides:{
        root:{
          padding:'5px'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF', // change the background color of the menu
          borderRadius: '8px', // add a border radius to the menu
          boxShadow: '0 0 45px rgba(0, 0, 0, 0.09)', // add a box shadow to the menu
          minWidth: '172px !important',
          transition: 'box-shadow 0.3s ease-in-out',
          overflow: 'visible !important',
        },
        list: {
          padding: '0 20px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '13px', // change the font size of the menu items
          lineHeight:'15.73px',
          textTransform: 'unset',
          textAlign: 'left',
          padding: '12px 0',
          transition: 'color 0.3s easy',
          '&:hover': {
            color: '#1557FF',
            background: '#fff'
          },
        },
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary:{
          color:'#797979',
          '&.Mui-checked':{
            color:'#202020'
          }
        },
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: 18, // set the font size of the check icon
          },
        },
      },
    },
    MuiGrid:{
      styleOverrides: {
        item:{
          '& .MuiPaper-root':{
            boxShadow:'none'
          }
        }
      }
    },
    MuiCardContent:{
      styleOverrides:{
        root:{
          padding:'16px 0 32px 0'
        }
      }
    },
    MuiCardHeader:{
      styleOverrides:{
        root:{
          marginTop:'24px',
          padding:0
        }
      }
    },
    MuiTextField:{
      styleOverrides:{
        root:{
          fontSize:'13px',
          lineHeight:'16px',
          minHeight:'40px',
          '& .MuiOutlinedInput-root':{
            fontSize:'13px',
            lineHeight:'16px',
            minHeight:'40px',
          },
          '& .MuiInputBase-multiline':{
            padding:'8px 4px'
          },
          '& .MuiInputBase-input':{
            padding:'11px 10px 11px 18px'
          },
          '& .MuiFormLabel-root': {
            fontSize: '13px',
            lineHeight:'20px',
          },
          '& .MuiFormLabel-root.MuiInputLabel-sizeSmall': {
            fontSize: '13px',
            lineHeight:'20px'
          },
          '& .MuiFormHelperText-sizeSmall':{
            height:'5px',
            fontSize:'11px'
          }
        }
      }
    },
    MuiSelect:{
      styleOverrides:{
        select:{
          fontSize:'12px'
        }
      }
    }
  }
})
