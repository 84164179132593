import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { IconButton, Typography } from '@mui/material'
import cx from 'classnames'

import FlexBox from '../main/FlexBox'
import ToggleButton from '../form/ToggleButton'
import FilterColumnConditionDialog
  from '../../modules/catalog/product/components/column-settings/components/form/FilterColumnConditionDialog'
import useDialog from '../../hooks/useDialog'
import { DragDropIcon } from '../icons/DragDropIcon'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '48px',
    backgroundColor: '#fff',
    boxShadow: '0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), ' +
      '0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15)',
    borderRadius: 'calc(4px / var(--scale-x, 1))',
    padding: '0 10px',
    margin:'0 4px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-h6': {
      fontSize: '13px',
      color: '#7C7C7C',
      '&:last-child': {
        color: '#C7C7C7',
      }
    },
  },
  activeRoot: {
    background: '#FFFFFF',
  },
  activeTypography: {
    color: '#000000 !important'
  },
  toggleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  button: {
    border: '1px solid #C3C3C3',
    padding: '5px',
    borderRadius: '4px',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    '&.active': {
      border: '1px solid #1557FF',
      cursor: 'pointer',
    }
  },
  masterCardShape: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '18.42px',
    height: '12px',
  },
  orangeCircle: {
    height: '12px',
    width: '12px',
    position: 'absolute',
    right: '0px',
    opacity: 0.8,
    background: '#F1DEC7',
    borderRadius: '50%',
  },
  activeOrangeCircle: {
    background: '#FF9922'
  },
  blueCircle: {
    height: '12px',
    width: '12px',
    position: 'absolute',
    right: '6.42px',
    opacity: 0.6,
    background: '#C5D1F1',
    borderRadius: '50%',
  },
  activeBlueCircle: {
    background: '#1557FF'
  },
  handleDragDropButton: {
    cursor: 'grab',
    display: 'flex',
    width: '15px',
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    touchAction: 'none',
    borderRadius: '5px',
    border: 'none',
    outline: 'none',
    appearance: 'none',
    backgroundColor: 'transparent',
    WebkitTapHighlightColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    '&:focus-visible': {
      boxShadow: '0 0px 0px 2px #4c9ffe'
    },
    '& svg': {
      flex: '0 0 auto',
      margin: 'auto',
      height: '100%',
      overflow: 'visible',
      fill: '#919eab'
    }
  }
})

interface Props {
  index:number,
  column: any,
  rowTitle: string,
  toggleFieldName: string
}

function SingleRow ({ index, column, rowTitle, toggleFieldName }: Props) {
  const conditionDialog = useDialog()
  const [activeRow, setActiveRow] = useState(false)
  const classes = useStyles()

  return (
    <div className={`${classes.root} ${activeRow && classes.activeRoot}`}>
      <FlexBox flex align="center" justify="space-between">
        <Typography variant="h6" className={`${activeRow && classes.activeTypography}`}>
          {rowTitle}
        </Typography>
        <div className={classes.toggleBox}>
          <ToggleButton
            column={column}
            name={toggleFieldName}
            setActiveRow={setActiveRow}
          />
          <IconButton
            disabled={!activeRow}
            className={cx(classes.button, { 'active': activeRow })}
            onClick={conditionDialog.handleOpen}
          >
            <div className={classes.masterCardShape} >
              <div className={`${classes.orangeCircle} ${activeRow && classes.activeOrangeCircle}`} />
              <div className={`${classes.blueCircle} ${activeRow && classes.activeBlueCircle}`} />
            </div>
            <Typography variant="h6" className={`${activeRow && classes.activeTypography}`}>
              Параметры
            </Typography>
          </IconButton>
          <IconButton
            className={classes.handleDragDropButton}
          >
            <DragDropIcon />
          </IconButton>
        </div>
        {conditionDialog.open && (
          <FilterColumnConditionDialog
            open={conditionDialog.open}
            onClose={conditionDialog.handleClose}
            name={`columns[${index}].conditions`}
          />
        )}
      </FlexBox>
    </div>
  )
}

export default SingleRow
