export enum CUSTOM_FIELD_TYPE {
  TEXT_BOX = 'TEXT_BOX',
  NUMBER_BOX = 'NUMBER_BOX',
  TEXT_AREA = 'TEXT_AREA',
  DROPDOWN = 'DROPDOWN',
  DATE_PICKER = 'DATE_PICKER',
  DATE_TIME_PICKER = 'DATE_TIME_PICKER',
  CHECK_BOX = 'CHECK_BOX',
  MULTI_CHECK_BOX = 'MULTI_CHECK_BOX',
  RADIO_BUTTON = 'RADIO_BUTTON',
  LOOKUP = 'LOOKUP',
  MULTI_LOOKUP = 'MULTI_LOOKUP',
}

export enum FILTER_FIELD_TYPE {
  TEXT_BOX = 'TEXT_BOX',
  NUMBER_BOX = 'NUMBER_BOX',
  DATE_TIME_PICKER = 'DATE_TIME_PICKER',
  LOOKUP = 'LOOKUP',
}

export enum FILTER_LOOKUP_TYPE {
  PRODUCT_STATUS = 'PRODUCT_STATUS',
  UNIT_MEASUREMENT = 'UNIT_MEASUREMENT',
  BRAND = 'BRAND',
  PACKAGE_LINE_ITEM = 'PACKAGE_LINE_ITEM',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  SUPPLIER = 'SUPPLIER',
  PRODUCT_TYPE = 'PRODUCT_TYPE'
}
