import { curry, evolve, pipe } from 'ramda'
import dayjs from 'dayjs'

export const toPayloadDate = (date: string) => {
  if (date) {
    return new Date(date).toISOString()
  }
}

export const toQueryDate = (date:string) => {
  if (date) {
    return new Date(date).toISOString().split('T')[0]
  }
}

const pad = curry((length, number) => number.toString().padStart(length, '0'))
const getDateComponents = () => {
  const date = new Date()
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1, // Months are zero-indexed
    day: date.getDate(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
    milliseconds: date.getMilliseconds()
  }
}

export const getCurrentDate = pipe(
  getDateComponents,
  evolve({
    month: pad(2),
    day: pad(2),
    hours: pad(2),
    minutes: pad(2),
    seconds: pad(2),
    milliseconds: pad(3)
  }),
  ({ year, month, day, hours, minutes, seconds, milliseconds }) =>
    `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`
)

export const formattedTime = (date:string) => {
  return dayjs(date).format('HH:mm:ss')
}

export function formatDate (inputDate:string) {
  const date = dayjs(inputDate)

  return date.format('YYYY-MM-DD HH:mm:ss')
}

export function formatDatePublic (inputDate: string | null | undefined) {
  const date = inputDate ? dayjs(inputDate) : dayjs()

  if (!date.isValid()) {
    return ''
  }

  const adjustedDate = date.add(5, 'hour')

  return adjustedDate.format('DD.MM.YY HH:mm')
}

export const formatOnlyDate = (inputDate:string) => {
  const date = dayjs(inputDate)
  return date.format('YYYY-MM-DD')
}
