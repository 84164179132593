import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { updateDiscount, getDiscountDetail } from '../api'
import DiscountUpdateForm from '../components/DiscountUpdateForm'
import { discountDetailSerializer, discountUpdateSerializer } from '../serializers'

function DiscountUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const discountUpdate = usePut(updateDiscount)
  const discountDetail = useGetDetail(getDiscountDetail, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    return discountUpdate.putData({ data: discountUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Скидка успешно обновлена' }))
      .then(() => navigate(ROUTES.CATALOG_DISCOUNT))
  }, [])

  const initialValues = useMemo(() => discountDetailSerializer(discountDetail.result), [discountDetail])

  return (
    <AppLayout>
      {!discountDetail.loading && (
        <DiscountUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        />
      )}
      {discountDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default DiscountUpdateContainer
