import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { EN_TRANSLATION } from './en'
import { RU_TRANSLATION } from './ru'

export const getDefaultLanguage = () => {
  const storedLanguage = localStorage.getItem('antex-language')
  return storedLanguage || 'ru'
}

i18n
  .use(initReactI18next)
  .init({
    fallbackLng:  'ru',
    lng:getDefaultLanguage(),
    debug: false,
    interpolation: {
      escapeValue: false
    },
    resources: {
      en: EN_TRANSLATION,
      ru: RU_TRANSLATION
    },
    ns: ['translations'],
    defaultNS: 'translations'
  })

export default i18n
