import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [{ value: 'ACTIVE', name: 'Активный' }, { value: 'INACTIVE', name: 'Не активный' }]

function CompanyStatusField () {
  return (
    <SelectField
      name="companyStatus"
      label="Статус"
      options={OPTIONS}
      data-cy="companyStatus"
    />
  )
}

export default CompanyStatusField
