import React, { useEffect, useState } from 'react'
import { path } from 'ramda'
import { useField } from 'formik'

import CurrencySearchField from './CurrencySearchField'

function CurrencyField (props: any) {
  const [ priceField, , ] = useField('priceLevelId')
  const [currency, setCurrency] = useState<any>()
  const currencyId = path(['value', 'currencyId'], priceField)

  useEffect(() => {
    setCurrency(currencyId)
  }, [currencyId])

  return (
    <CurrencySearchField
      label="Валюта"
      name="currency"
      disabled={true}
      currency={currency}
      {...props}
    />
  )
}

export default CurrencyField
