import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'

import { newsGetById, newsUpdate } from '../api'
import NewsUpdateForm from '../components/NewsUpdateForm'

function NewsUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const newsById = useGetDetail(newsGetById, { params: { id } })
  const updateNews = usePut(newsUpdate)

  const handleSubmit = useCallback((values: any) => {
    return updateNews.putData({ data: values })
      .then(() => snackbar({ message: 'Новости успешно обновлен' }))
      .then(() => navigate(ROUTES.BACKEND_NEWS_LIST))
  }, [])

  return (
    <AppLayout>
      {!newsById.loading && (
        <NewsUpdateForm
          onSubmit={handleSubmit}
          initialValues={newsById.result}
        />
      )}
    </AppLayout>
  )
}

export default NewsUpdateContainer
