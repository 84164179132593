import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Divider, Grid, IconButton } from '@mui/material'
import { FieldArray, FormikFormProps, FormikProps, useField } from 'formik'
import DateField from 'components/form/DateField'
import { path } from 'ramda'
import { DeleteIcon } from 'components/icons/DeleteIcon'

import TypeField from './form/TypeField'
import ActionTypeField from './form/ActionTypeField'
import LineItems from './form/LineItems'
import DiscountTypeField from './form/DiscountTypeField'
import AvailableColumnsField from './form/AvailableColumns'
import ComparatorSelectField from './form/ComparatorSelectField'
import { ComparatorValueField } from './form/ComparatorValueField'
import CriteriaTypeSelectField from './form/CriteriaTypeSelectField'
import { QueryPatternField } from './form/QueryPatternField'
import MultipleTerritoryField from './form/MultipleTerritoryField'

interface Props{
  readonly form:FormikProps<FormikFormProps>
  readonly productList:any
}

function BonusCreateForm ({ form, productList }:Props) {
  const actionType = path(['values', 'actionType'], form)
  const discountType = path(['values', 'discountType'], form)
  const isDiscount = actionType === 'DISCOUNT'
  const isProduct = actionType === 'PRODUCT'
  const isByProduct = discountType === 'BYPRODUCT'
  const isAll = discountType === 'ALL'
  const criteriaField = useField('criteria')
  const criteriaValue = path(['0', 'value'], criteriaField)
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать акции"
          rightButton={(
            <>
              <Button
                type="submit"
                disabled={!form.isValid}
              >
                Создать
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label="Название акции"
                      name="name"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <DateField
                      label="От даты"
                      name="fromDate"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <DateField
                      label="До даты"
                      name="toDate"
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <MultipleTerritoryField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <TypeField />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                    <ActionTypeField />
                  </Grid>
                  {isDiscount && (
                    <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                      <DiscountTypeField />
                    </Grid>
                  )}
                  {isAll && (
                    <Grid item={true} xs={12} sm={6} md={6} lg={4}>
                      <TextField label="Скидка (%)" name="rate" />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          {(isByProduct || isProduct) && (
            <>
              <Grid item={true} lg={12}>
                <LineItems productList={productList} isProduct={isByProduct} />
              </Grid>
            </>
          )}
          <Grid item={true} lg={12} spacing={2}>
            <CardHeader title="Компаратор" />
            <FieldArray name="criteria">
              {({ push, remove }: any) => (
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item lg={4}>
                      <QueryPatternField label="Шаблон запроса" name="queryPattern" />
                    </Grid>
                    <>
                      {criteriaValue && criteriaValue.map((item: any, index: number) => (
                        <Grid item={true} key={index} lg={12}>
                          <Grid container={true} spacing={3}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <AvailableColumnsField
                                name={`criteria[${index}].code`}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <ComparatorSelectField
                                item={item}
                                name={`criteria[${index}].comparator`}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <ComparatorValueField
                                item={item}
                                name={`criteria[${index}].value`}
                              />
                            </Grid>
                            {criteriaValue.length > 1 && (
                              <Grid item lg={1}>
                                <CriteriaTypeSelectField
                                  name={`criteria[${index}].criteriaType`}
                                />
                              </Grid>
                            )}
                            {index >= 0 && (
                              <Grid item xs={8} lg={1}>
                                <IconButton onClick={() => remove(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                    </>
                    <Grid item={true} lg={6}>
                      <Button
                        onClick={() => {
                          push({
                            code:'',
                            comparator:'',
                            value:'',
                          })
                        }}
                      >
                        Добавить компаратор
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </FieldArray>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(BonusCreateForm)
