import React, { useEffect } from 'react'
import { useField } from 'formik'

import { ComparatorValueFields } from './ComparatorValueFields'

const columnTranslations: { [key: string]: string } = {
  'ORDER_NUMBER': 'Номер заказа',
  'ORDER_DATE': 'Дата заказа',
  'WAREHOUSE_GROUP': 'Группа складов',
  'CLIENT': 'Клиент',
  'TERRITORY': 'Территория',
  'ORDER_STATUS': 'Статус',
  'PAYMENT_AMOUNT': 'Сумма',
  'PRICE_LEVEL': 'Ценовая политика',
  'DELIVERY_TERMS': 'Условия',
  'CURRENCY': 'Валюта',
  'PAYMENT_METHOD': 'Способ оплаты'
}

const getTranslatedColumn = (code: string, name:string): string | undefined => {
  return columnTranslations[code] || name
}

export const FilterSearchField = (props:any) => {
  const { item, index, ...rest } = props
  const criteriaCodeType = useField(`criteria[${index}].code`)
  const criteriaCodeTypeSetField = criteriaCodeType[2].setValue

  useEffect(() => {
    criteriaCodeTypeSetField(item?.code)
  }, [])

  return (
    <ComparatorValueFields
      label={getTranslatedColumn(item?.code, item?.name)}
      name={`criteria[${index}].value`}
      item={item}
      {...rest}
    />
  )
}
