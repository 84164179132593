import * as ROUTES from 'constants/Routes'
import * as API from 'constants/Api'

import * as Yup from 'yup'
import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import useScrollList from 'hooks/useScrollList'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'
import { getRequest } from 'api/BaseApi'

import AdjustmentCreateForm from '../components/AdjustmentCreateForm'
import { createAdjustment, getProductList } from '../api'
import { adjustmentCreateSerializer } from '../serializer'

function AdjustmentCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const adjustmentCreate = usePost(createAdjustment)
  const productList = useScrollList(getProductList)

  const handleSubmit = useCallback((data: any) => {
    return adjustmentCreate.postData({ data: adjustmentCreateSerializer(data) })
      .then(() => navigate(ROUTES.WAREHOUSE_ADJUSTMENT_LIST))
      .then(() => snackbar({ message: 'Корректировка успешно создана' }))
  }, [])

  const onGetPriceLevel = useCallback((id: any, products: any) => {
    return getRequest(API.PriceLevelByProductId, { params: { id }, query: { products } })
  }, [])

  const validationSchema = Yup.object({
    supplier: Yup.object({ id:Yup.number(), name:Yup.string() }).required('Обязательный'),
  })

  return (
    <AppLayout>
      <AdjustmentCreateForm
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={{ supplier:null }}
        productList={productList}
        handleGetPriceLevel={onGetPriceLevel}
      />
    </AppLayout>
  )
}

export default AdjustmentCreateContainer
