import React, { useEffect, useState } from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import { Button, Grid } from '@mui/material'
import { useField } from 'formik'
import { path } from 'ramda'

import ImageUpload from './form/ImageUpload'
import AttachmentField from './form/AttachmentField'
import AgentBasic from './create/AgentBasic'
import { AvailableCustomField } from './custom-field/AvailableCustomField'

function AgentUpdateForm ({ form, customFields }:any) {
  const name = path(['values', 'name'], form)
  const attachmentsField = useField('attachments')
  const attchmentFieldValues = attachmentsField[0].value || []
  const attachmentSetFieldValues = attachmentsField[2]
  const [files, setFiles] = useState(attchmentFieldValues as any[])
  const handleFileSet = (value: any) => {
    setFiles((prev: any) => [...prev, value])
  }
  useEffect(() => {
    attachmentSetFieldValues.setValue(files)
  }, [files])

  useEffect(() => {
    if (form.dirty && form.status) {
      form.resetForm({ values: form.values })
      form.setStatus(undefined)
    }
  }, [form.dirty, form.status])

  const handleFileDelete = (id: any) => {
    setFiles((prev: any) => prev.filter((img: any) => img.id !== id))
  }
  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить контрагент (${name})`}
          rightButton={(
            <>
              <Button type="submit">Сохранить изменения</Button>
            </>
          )}
        />
      </Header>

      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <AgentBasic form={form} />
          </Grid>
          <Grid item={true} lg={12}>
            <ImageUpload />
          </Grid>
          <Grid item={true} lg={12}>
            <AttachmentField
              files={files}
              onFileSet={handleFileSet}
              onFileDelete={handleFileDelete}
            />
          </Grid>
          <Grid item={true} lg={12}>
            <AvailableCustomField customFields={customFields} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(AgentUpdateForm)
