import React, { useCallback } from 'react'
import * as Yup from 'yup'

import CompanyCreateForm from '../components/CompanyCreateForm'
import { usePost } from '../../../../hooks/usePost'
import { signUp } from '../api'
import { useSnackbar } from '../../../../context/snackbar'
import { companyCreateSerializer } from '../serializers'

const validationSchema = Yup.object().shape({
  companyName: Yup
    .string()
    .required('Название компании обязательно')
    .min(2, 'Название компании должно содержать не менее 2 символов'),
  companyEmail: Yup
    .string()
    .email('Введите действительный электронный адрес')
    .required('Электронная почта обязательна'),
  companyPhone: Yup
    .string()
    .matches(/^[0-9]+$/, 'Номер телефона должен содержать только цифры')
    .min(7, 'Номер телефона должен содержать не менее 7 цифр')
    .required('Номер телефона обязателен'),
  countryCode: Yup
    .string()
    .required('Код страны обязателен')
    .matches(/^[A-Z]{2,3}$/, 'Код страны должен состоять из 2 или 3 заглавных букв'),
  password: Yup
    .string()
    .required('Пароль обязателен')
    .min(8, 'Пароль должен содержать не менее 8 символов')
    .max(32, 'Пароль не должен превышать 32 символов')
    .matches(/[A-Z]/, 'Пароль должен содержать хотя бы одну заглавную букву')
    .matches(/[a-z]/, 'Пароль должен содержать хотя бы одну строчную букву')
    .matches(/\d/, 'Пароль должен содержать хотя бы одну цифру'),
  passwordCheck: Yup
    .string()
    .oneOf([Yup.ref('password'), undefined], 'Пароли должны совпадать')
    .required('Повторите пароль'),
})

const PublicCompanyCreateContainer = () => {
  const snackbar = useSnackbar()
  const createCompany = usePost(signUp)

  const onSubmit = useCallback((values:any) => {
    createCompany.postData({ data:companyCreateSerializer(values) })
      .then(() => snackbar({ message: 'Компания успешно создана' }))
  }, [])

  return (
    <CompanyCreateForm
      onSubmit={onSubmit}
      initialValues={{}}
      validationSchema={validationSchema}
    />
  )
}

export default PublicCompanyCreateContainer
