import React from 'react'
import { path } from 'ramda'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'

import ClientField from './field/ClientField'
import TerritoryField from './field/TerritoryField'
import PredecessorField from './field/PredecessorField'
import SuccessorField from './field/SuccessorField'
import UserField from './field/UserField'
import VisitTypeField from './field/VisitTypeField'
import VisitStatusField from './field/VisitStatusField'

const getFieldByType = (fieldType: string, lookUpType:string, code:string, rest: any) => {
  if (fieldType === 'LOOKUP' && lookUpType === 'TASK_TYPE') {
    return <VisitTypeField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'TASK_STATUS') {
    return <VisitStatusField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'TERRITORY') {
    return <TerritoryField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'USER') {
    return <UserField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'CONTACT') {
    return <ClientField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'TASK' && code === 'SUCCESSOR') {
    return <SuccessorField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'TASK' && code === 'PREDECESSOR') {
    return <PredecessorField {...rest} />
  }
  if (fieldType === 'DATE_TIME_PICKER') {
    return (
      <DateField
        label="Дата"
        {...rest}
      />
    )
  }
  if (fieldType === 'NUMBER_BOX') {
    return (
      <TextField
        label="Ценить"
        type="number"
        {...rest}
      />
    )
  }

  return (
    <TextField
      label="Значение поля"
      {...rest}
    />
  )
}

export const FilterComparatorValueField = (props: any) => {
  const { item, ...rest } = props
  const fieldType = path(['code', 'fieldType'], item)
  const lookUpType = path(['code', 'lookUpType'], item)
  const code = path(['code', 'code'], item)
  return getFieldByType(fieldType, lookUpType, code, rest)
}
