import React, { useEffect } from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import { Button, Grid } from '@mui/material'
import { path } from 'ramda'

import ImageUpload from './form/ImageUpload'
import AttachmentField from './form/AttachmentField'
import AgentBasic from './create/AgentBasic'

function SharedContactUpdateForm ({ form }:any) {
  const name = path(['values', 'name'], form)

  useEffect(() => {
    if (form.dirty && form.status) {
      form.resetForm({ values: form.values })
      form.setStatus(undefined)
    }
  }, [form.dirty, form.status])

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить поделенный контакт (${name})`}
          rightButton={(
            <>
              <Button type="submit">Сохранить изменения</Button>
            </>
          )}
        />
      </Header>

      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <AgentBasic form={form} />
          </Grid>
          <Grid item={true} lg={12}>
            <ImageUpload />
          </Grid>
          <Grid item={true} lg={12}>
            <AttachmentField name="attachments" />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(SharedContactUpdateForm)
