import React from 'react'

interface Props {
  readonly type: string
}

const getStatusTranslate = (type: string) => {
  if (type === 'PRODUCT') {
    return 'Продукт'
  }
  if (type === 'ASSET') {
    return 'Aссет'
  }
  if (type === 'OBJECT') {
    return 'Оборудование'
  }
}

function ProductTypeRow ({ type }: Props) {
  return (
    <div>
      {getStatusTranslate(type)}
    </div>
  )
}

export default ProductTypeRow
