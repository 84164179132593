import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest } from 'api/BaseApi'

export const getCountryList = (options?: Options) => {
  return getRequest<any>(API.CountryList, options)
}

export const countryDetail = (options?:Options) => {
  return getRequest<any>(API.CountryGetDetail, options)
}

export const countryCreate = (options?:Options) => {
  return postRequest<any>(API.CountryCreate, options)
}

export const countryDeleteBulk = (options?:Options) => {
  return deleteRequest<any>(API.CountryDeleteBulk, options)
}

export const countryUpdateStatusBulk = (options?:Options) => {
  return patchRequest<any>(API.CountryUpdateStatusBulk, options)
}
