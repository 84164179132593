import * as API from 'constants/Api'

import React from 'react'
import SearchField from 'components/form/SearchField'
import { path, prop } from 'ramda'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)
    const currencyId = path(['currency', 'id'], option)
    return { value, name, id: value, currencyId }
  }

  return null
}

function PriceLevelField (props: any) {
  return (
    <SearchField
      name="priceLevelId"
      api={API.PriceLevelList}
      getOptionValue={getOptionValue}
      label="Ценовая политика"
      {...props}
    />
  )
}

export default PriceLevelField
