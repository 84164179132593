import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'SALES', name: 'ПРОДАЖИ' },
]

function TypeField () {
  return (
    <SelectField
      name="type"
      label="Тип акции"
      options={OPTIONS}
      defaultValue={OPTIONS[0].value}
      data-cy="status"
    />
  )
}

export default TypeField
