import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'DAY', name: 'ДЕНЬ' },
  { value: 'FIRST_WEEK', name: 'ПЕРВАЯ НЕДЕЛЯ' },
  { value: 'SECOND_WEEK', name: 'ВТОРАЯ НЕДЕЛЯ' },
  { value: 'THIRD_WEEK', name: 'ТРЕТЬЯ НЕДЕЛЯ' },
  { value: 'FOUR_WEEK', name: 'ЧЕТЫРЕ НЕДЕЛИ' },
  { value: 'LAST_WEEK', name: 'НА ПРОШЛОЙ НЕДЕЛЕ' },
]

function YearlyRepeatTypeField (props:any) {
  return (
    <SelectField
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default YearlyRepeatTypeField
