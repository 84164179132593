import React, { useCallback, useEffect, useState } from 'react'
import { Box, Card, CardContent, Divider, Grid, IconButton, Skeleton } from '@mui/material'
import { prop } from 'ramda'
import { usePost } from 'hooks/usePost'
import useDialog from 'hooks/useDialog'
import CardHeader from 'components/card/CardHeader'
import Dropzone from 'components/upload/Dropzone'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import CheckboxField from 'components/form/CheckboxField'
import { ImgCarousel } from 'components/upload/img-carousel/ImgCarousel'
import { ImagePreview } from 'components/upload/image-preview/ImagePreview'
import { useSnackbar } from 'context/snackbar'
import { useField } from 'formik'

import { uploadFileProduct, productImageDelete } from '../../api'

function ImagesUpload () {
  const carouselImageDialogue = useDialog()
  const imagePreviewDialogue = useDialog()
  const snackbar = useSnackbar()
  const fileUpload = usePost(uploadFileProduct)
  const visitDeleteFile = usePost(productImageDelete)
  const imageField = useField('images')
  const imageValue = imageField[0].value || []
  const defaultImageValue = imageValue[0]
  const imagesSetFieldValues = imageField[2]
  const [choosedImage, setChoosedImage] = useState(null)
  const [images, setImages] = useState(imageValue)
  const [image, setImage] = useState('')
  const [data, setData] = useState(null) as any
  const [checkedImageId, setCheckedImageId] = useState(defaultImageValue?.id || null) as any

  useEffect(() => {
    if (defaultImageValue?.id) {
      setCheckedImageId(defaultImageValue?.id)
    }
    imagesSetFieldValues.setValue(images)
  }, [defaultImageValue, images])

  const onDrop = useCallback((values: any = []) => {
    const data = new FormData()
    values.forEach((file: any) => {
      data.append('uploadFile', file)
    })
    setImage(URL.createObjectURL(values[0]))
    setData(data)
    imagePreviewDialogue.handleOpen()
  }, [])

  const handleUpload = useCallback(() => {
    fileUpload.postData({ data }).then((response) => {
      setImages((prev: any) => [...prev, response])
    })
      .then(() => imagePreviewDialogue.handleClose())
      .then(() => setData(null))
  }, [data])

  const handleClosePreview = () => {
    setData(null)
    setImage('')
    imagePreviewDialogue.handleClose()
  }

  const handleDelete = useCallback((id: any) => {
    visitDeleteFile.postData({ params: { id } }).then(() => {
      setImages((prev: any) => prev.filter((img: any) => img.id !== id))
    })
  }, [])

  const handleClick = (item:any) => {
    setChoosedImage(item)
    carouselImageDialogue.handleOpen()
  }

  const onPrimary = useCallback((image:any) => {
    const imageIndex = images.findIndex((img: { id: any }) => img.id === image.id)
    if (imageIndex !== -1) {
      const reorderedImages = [...images]
      const selectedImage = reorderedImages.splice(imageIndex, 1)[0]
      reorderedImages.unshift(selectedImage)
      snackbar({ message: 'Выбранное изображение успешно обновлено в качестве основного' })
      setCheckedImageId(image.id)
      setImages(reorderedImages)
    }
  }, [images])

  return (
    <Card>
      <CardHeader title="Добавьте изображения для продукта." />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={2}>
            <Dropzone onDrop={onDrop} />
          </Grid>
          {images?.map((item: any, index:number) => {
            const link = prop('link', item)
            const id = prop('id', item)
            const originalName = prop('originalName', item)
            return (
              <Grid item={true} lg={2} sx={{ position: 'relative' }} key={index}>
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'flex-end',
                    justifyContent:'center',
                    width:'200px',
                    height:'200px',
                    background:'#F8F8F8',
                    borderRadius:'8px 8px 0 0',
                    position: 'relative'
                  }}
                >
                  <img
                    src={link}
                    alt={originalName}
                    onClick={() => handleClick(item)}
                    style={{
                      height: 150,
                      width:150,
                      borderRadius:'8px 8px 0 0',
                      objectFit: 'cover',
                      cursor:'pointer'
                    }}
                  />
                  <Box
                    sx={{
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'flex-end',
                      gap:'20px',
                      position:'absolute',
                      right:'25px',
                      top:'15px',
                      width:'150px',
                      height:'30px'
                    }}
                  >
                    <CheckboxField
                      color="white"
                      name="checked"
                      label="Основной"
                      checked={checkedImageId === id}
                      onClick={() => onPrimary(item)}
                    />
                    <IconButton onClick={() => handleDelete(id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            )
          })}
          {fileUpload.loading && (
            <Grid item={true} lg={3}>
              <Box
                sx={{
                  display:'flex',
                  alignItems:'flex-end',
                  justifyContent:'center',
                  width:'200px',
                  height:'200px',
                  background:'#F8F8F8',
                  borderRadius:'8px 8px 0 0',
                }}
              >
                <Skeleton variant="rectangular" width={150} height={150} sx={{ borderRadius:'8px 8px 0 0' }} />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Divider />
      {carouselImageDialogue.open && (
        <ImgCarousel
          image={choosedImage}
          images={images}
          onDelete={handleDelete}
          open={carouselImageDialogue.open}
          handleClose={carouselImageDialogue.handleClose}
          defaultImageValue={defaultImageValue}
          onDefaultImage={onPrimary}
        />
      )}
      {imagePreviewDialogue.open && (
        <ImagePreview
          image={image}
          onUpload={handleUpload}
          onClose={handleClosePreview}
          open={imagePreviewDialogue.open}
          handleClose={imagePreviewDialogue.handleClose}
        />
      )}
    </Card>
  )
}

export default ImagesUpload
