import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'
import { useGetList } from 'hooks/useGetList'
import { usePut } from 'hooks/usePut'
import { prop } from 'ramda'

import FilterCreateForm from './FilterCreateForm'
import { orderFilterSerializer } from './serializer'

import { createFilter, getAvailableColumns, updateFilter } from '../../api'
import { filterValidationSchema } from '../../../../catalog/product/components/filter/filter-form-values'

export const useStyles = makeStyles({
  header: {
    padding: '17px 20px',
    background: 'rgba(253, 253, 253, 0.5)',
    backdropFilter: 'blur(15px)',
    borderBottom:'1px solid #f1f1f1'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-between'
  },
  wrapper:{
    width:'800px',
    background:'#fff',
    minHeight: 'calc(100vh - 60px)'
  },
  addButton: {
    cursor: 'pointer'
  },
  searchBox:{
    padding:'20px',
    borderBottom:'1px solid #f1f1f1'
  },
  columnBox:{
    padding:'20px',
    height: 'calc(100vh - 256px)',
    overFlowY:'auto'
  },
  menuItem:{
    padding:'10px 20px',
    '&:hover':{
      background:'#F6F8FD'
    }
  },
  actions:{
    padding:'20px',
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end'
  },
  button: {
    fontSize: '13px',
    display: 'flex',
    gap:'5px',
    height: '40px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    padding: '9px 20px',
    textTransform:'capitalize',
    '&.disabled': {
      background: '#202020 !important',
      opacity: '0.5'
    }
  }
})

interface Props {
  readonly onBack:() => void
  readonly filterList:any
  readonly onDelete:any
  readonly onFilter:any
  readonly onFilterPrev:any
  readonly filter:any
  readonly filterPreviewData:any
}

const FilterListingCreate = ({
  onBack,
  filterList,
  onDelete,
  onFilter,
  onFilterPrev,
  filter,
  filterPreviewData
}:Props) => {
  const snackbar = useSnackbar()
  const filterCreate = usePost(createFilter)
  const availableColumns = useGetList(getAvailableColumns, { params: { type: 'SHIPMENT_LIST_PANEL' } })
  const [isNewFilter, setIsNewFilter] = useState(false)
  const list = prop('list', availableColumns)
  const filterUpdate = usePut(updateFilter)
  const isHasFilterPreview = Object.keys(filterPreviewData).length > 0

  useEffect(() => {
    availableColumns.getList()
  }, [])

  const onUpdate = useCallback((values:any) => {
    filterUpdate.putData({ data:orderFilterSerializer(values) })
      .then(() => onBack())
      .then(() => snackbar({ message: 'Фильтр успешно обновлён' }))
  }, [])

  const onCreate = useCallback((values:any, form:any) => {
    filterCreate.postData({ data:orderFilterSerializer(values) })
      .then(() => onBack())
      .then(() => filterList.getList())
      .then(() => snackbar({ message: 'Фильтр успешно создан' }))
      .then(() => availableColumns.getList())
      .then(() => form.resetForm({ type: 'SHIPMENT_LIST_PANEL', criteria: [] }))
  }, [])

  const initialValues = useMemo(() => {
    if (isNewFilter) {
      return { name:'', type: 'SHIPMENT_LIST_PANEL', criteria: list }
    }
    if (isHasFilterPreview) {
      return filterPreviewData
    } else {
      return filter
    }
  }, [filter, isNewFilter, list, isHasFilterPreview])

  const onSubmit = isNewFilter ? onCreate : onUpdate

  return (
    <>
      <FilterCreateForm
        isHasFilterPreview={isHasFilterPreview}
        onBack={onBack}
        setIsNewFilter={setIsNewFilter}
        filter={initialValues}
        onFilterPrev={onFilterPrev}
        onFilter={onFilter}
        onDelete={onDelete}
        availableColumns={availableColumns}
        filterList={filterList}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={filterValidationSchema}
      />
    </>
  )
}

export default FilterListingCreate
