import Container from 'components/container/Container'
import Header from 'components/container/Header'
import PageTitle from 'components/main/PageTitle'
import { Button, Card, CardContent, Grid } from '@mui/material'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import React from 'react'
import withForm from 'components/form/withForm'
import { path } from 'ramda'

import AttachmentField from './form/AttachmentField'

function DocumentUpdateForm ({ form }:any) {
  const name = path(['values', 'name'], form)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={`Обновить документ (${name})`}
          rightButton={(
            <>
              <Button
                type="submit"
              >
                Сохранить изменения
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label="Название папки"
                      name="name"
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <AttachmentField />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(DocumentUpdateForm)
