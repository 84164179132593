import React, { useCallback, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import { prop, propOr } from 'ramda'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, DialogTitle } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useField } from 'formik'
import TableHead from '@mui/material/TableHead'
import InfinityScrollSearch from 'components/form/InfinityScrollSearch'
import TextOverflow from 'components/main/TextOverflow'
import TableSkeletonList from 'components/table/TableSkeletonList'
import FlexBox from 'components/main/FlexBox'
import cx from 'classnames'
import { makeStyles } from '@mui/styles'
import { useLocales } from 'hooks/useLocales'

export const useStyles = makeStyles({
  dialogContent:{
    padding: '16px',
    position: 'relative',
    maxHeight:'700px'
  },
  tableRoot:{
    height:'460px',
    borderRadius:'7px',
    border:'1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      cursor:'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly open: boolean
  readonly onClose: () => void
  readonly onAddVariants: (value: any) => void
  readonly packageList: any
}

function PackageAddDialog ({ open, onClose, onAddVariants, packageList }: Props) {
  const { t } = useLocales('product')
  const classes = useStyles()
  const lineItemsField = useField('packageLineItems')
  const lineItemsFieldValue = lineItemsField[0].value || []

  const [selected, setSelected] = useState(lineItemsFieldValue as any)

  const results = propOr([], 'list', packageList) as any
  const count = prop('count', packageList) as number

  const searchField = useField('search')
  const searchFieldValue = searchField[0].value
  const setSearchValue = searchField[2]
  const onEscape = useCallback((event: any) => {
    if (event.code === 'Escape') {
      setSearchValue.setValue('')
      onClose()
    }
  }, [onClose])

  const handleCancel = () => {
    setSearchValue.setValue('')
    onClose()
  }
  const handleCheck = (checked: boolean, variant: any) => {
    const packageId = prop('id', variant)
    const name = prop('name', variant)
    if (checked) {
      setSelected((prevSelected: any) => prevSelected.filter((select: any) => {
        return prop('packageId', select) !== packageId
      }))
    } else {
      setSelected((prevSelected: any) => [...prevSelected, { packageId, name }])
    }
  }
  return (
    <Dialog open={open} fullWidth={true} onKeyDown={onEscape}>
      <DialogTitle sx={{ padding:'16px' }}>
        <Typography component="h2" variant="body1" sx={{ fontWeight:'700', fontSize:'20px' }}>
          {`${t('View packages')} - ${t('Selected')} (${selected.length})`}
        </Typography>
        <Box sx={{ padding: '8px 0' }}>
          <InfinityScrollSearch getList={packageList.getList} />
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} id="saleOrderVariantTable">
        <InfiniteScroll
          dataLength={results.length}
          hasMore={count > results.length}
          loader=""
          next={() => packageList.getList({ query: { searchKey: searchFieldValue } })}
          scrollableTarget="saleOrderVariantTable"
        >
          <TableContainer className={classes.tableRoot}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell width={100}>{t('Marks')}</TableCell>
                  <TableCell width={250}>{t('Name')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {results.map((variant: any, index: number) => {
                  const id = prop('id', variant)
                  const checked = selected.find((select: any) => prop('packageId', select) === id)
                  return (
                    <TableRow
                      className={cx({ 'selected': checked })}
                      onClick={() => handleCheck(checked, variant)}
                    >
                      <TableCell data-cy={`variant-${index}`}>
                        <Checkbox checked={Boolean(checked)} color="secondary" />
                      </TableCell>
                      <TableCell>
                        <TextOverflow lines={1}>
                          {prop('name', variant)}
                        </TextOverflow>
                      </TableCell>
                    </TableRow>
                  )
                })}
                {packageList.loading && (
                  <TableSkeletonList columnsCount={2} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </DialogContent>
      <DialogActions sx={{ padding:'16px' }}>
        <FlexBox align="center" gap="8px">
          <Button
            color="secondary"
            variant="contained"
            onClick={handleCancel}
            fullWidth={true}
          >
            {t('Cancel')}
          </Button>
          <Button
            data-cy="add"
            variant="contained"
            fullWidth={true}
            onClick={() => onAddVariants(selected)}
          >
            {t('Add')}
          </Button>
        </FlexBox>
      </DialogActions>
    </Dialog>
  )
}

export default PackageAddDialog
