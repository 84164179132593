import * as ROUTES from 'constants/Routes'

import * as Yup from 'yup'
import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'

import WarehouseCreateForm from '../components/WarehouseCreateForm'
import { createWarehouse } from '../api'
import { warehouseCreateSerializer } from '../serializer'

export const validationSchema = Yup.object({
  territories: Yup.array().min(1, 'Требуется территория')
    .required('Обязательный'),
  warehouseType:Yup.string().required('Требуется тип склада')
})

const initialError = {
  warehouseName:'Пожалуйста, заполните обязательные поля!',
  territories:'Пожалуйста, заполните обязательные поля',
  warehouseType:'Пожалуйста, заполните обязательные поля'
}

function WarehouseCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const warehouseCreate = usePost(createWarehouse)

  const handleSubmit = useCallback((data: any) => {
    return warehouseCreate.postData({ data: warehouseCreateSerializer(data) })
      .then(() => snackbar({ message: 'Склад успешно создан' }))
      .then(() => navigate(ROUTES.WAREHOUSE_LIST))
  }, [])

  return (
    <AppLayout>
      <WarehouseCreateForm
        onSubmit={handleSubmit}
        initialErrors={initialError}
        validationSchema={validationSchema}
        initialValues={{ territories:[], warehouseType:null }}
      />
    </AppLayout>
  )
}

export default WarehouseCreateContainer
