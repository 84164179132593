import * as ROUTES from 'constants/Routes'

import * as Yup from 'yup'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'

import PriceLevelForm from '../components/form/PriceLevelCreateForm'
import { createPriceLevel } from '../api'
import { priceLevelCreateSerializer } from '../serializer'

export const validationSchema = Yup.object({
  currency:Yup.object().required('Обязательный'),
})

function PriceLevelCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const priceLevelCreate = usePost(createPriceLevel)

  const handleSubmit = useCallback((values: any) => {
    return priceLevelCreate.postData(
      { data: priceLevelCreateSerializer(values) })
      .then(() => {
        snackbar({ message: 'Ценовая политика успешно создана' })
        navigate(ROUTES.SETTINGS_PRICE_LEVEL_LIST)
      })
  }, [])

  return (
    <AppLayout>
      <PriceLevelForm
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{ currencyId:null, territoryIds:[] }}
        enableReinitialize={true}
      />
    </AppLayout>
  )
}

export default PriceLevelCreateContainer
