import React from 'react'
import MUITab from '@mui/material/Tab'
import MUITabs from '@mui/material/Tabs'
import { is } from 'ramda'
import { useLocales } from 'hooks/useLocales'

export interface TabsProps {
  readonly label: string
  readonly value: string
}

interface Props {
  readonly tabs: TabsProps[]
  readonly onChange: any
  readonly value?: string
  readonly translationModule?:string
}

function Tabs ({ tabs, value, translationModule, onChange }: Props) {
  const { t } = useLocales(translationModule)

  return (
    <MUITabs
      onChange={onChange}
      scrollButtons="auto"
      value={value}
      indicatorColor="primary"
      textColor="inherit"
    >
      {tabs.map((tab, index) => (
        <MUITab
          data-cy={`tab-${tab.value}`}
          key={index}
          label={is(String, tab.label) ? t(tab.label) : tab.label}
          value={tab.value}
        />
      ))}
    </MUITabs>
  )
}

export default Tabs
