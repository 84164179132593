import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'
import useAllSearchParams from 'utils/useAllSearchParams'
import { prop } from 'ramda'
import { useGetDetail } from 'hooks/useGetDetail'
import { useGetList } from 'hooks/useGetList'

import VisitCreateForm from '../components/VisitCreateForm'
import { createVisit, getVisitById, getCustomFields } from '../api'
import { visitCopySerializer, visitCreateSerializer } from '../serializer'
import { validationSchemaVisitCreateForm, visitInitialValues } from '../components/validationSchema'

function VisitCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const searchParams = useAllSearchParams()
  const copyId = prop('copyId', searchParams)
  const [customFields, setCustomField] = useState([])
  const visitCreate = usePost(createVisit)
  const visitDetail = copyId ? useGetDetail(getVisitById, { params: { id: copyId } }) : null as any
  const customFieldsGet = useGetList(getCustomFields)

  const initialCopyValues = useMemo(() => {
    if (copyId) {
      return visitCopySerializer(visitDetail.result)
    }
  }, [visitDetail, copyId])

  const handleSubmit = useCallback((values: any) => {
    return visitCreate.postData(
      { data: visitCreateSerializer(values) })
      .then(() => {
        snackbar({ message: 'Посещение успешно создано' })
        navigate(ROUTES.CATALOG_VISIT_LIST)
      })
  }, [])

  useEffect(() => {
    customFieldsGet.getList({ params: { formType: 'TASK_FORM' } }).then((res: any) => {
      setCustomField(res)
    })
  }, [])

  return (
    <AppLayout>
      {customFieldsGet.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
      {copyId && !visitDetail.loading && !customFieldsGet.loading && (
        <VisitCreateForm
          onSubmit={handleSubmit}
          enableReinitialize={true}
          initialValues={initialCopyValues}
          validationSchema={validationSchemaVisitCreateForm}
        />
      )}
      {!copyId && !customFieldsGet.loading && (
        <VisitCreateForm
          onSubmit={handleSubmit}
          enableReinitialize={true}
          initialValues={{ ...visitInitialValues, customFieldValue: customFields, }}
          validationSchema={validationSchemaVisitCreateForm}
        />
      )}
    </AppLayout>
  )
}

export default VisitCreateContainer
