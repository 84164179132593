import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getPreOrderList = (options?: Options) => {
  return getRequest<any>(API.PreOrderList, options)
}

export const getPreOrderDetail = (options?: Options) => {
  return getRequest<any>(API.PreOrderDetail, options)
}

export const changePreOrderStatus = (options?: Options) => {
  return patchRequest<any>(API.PreOrderChangeStatus, options)
}

export const deletePreOrder = (options?:Options) => {
  return deleteRequest<any>(API.PreOrderDelete, options)
}

export const updatePreOrder = (options?:Options) => {
  return putRequest<any>(API.PreOrderUpdate, options)
}

export const createPreOrder = (options?:Options) => {
  return postRequest<any>(API.PreOrderCreate, options)
}

export const convertPreOrder = (options?:Options) => {
  return patchRequest<any>(API.PreOrderConvertOrder, options)
}

export const getProductList = (options?: Options) => {
  return getRequest<any>(API.ProductDetailList, options)
}

export const createContact = (options?: Options) => {
  return postRequest<any>(API.ContactCreate, options)
}
