import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'

import { sharedContactCategoryGetById, sharedContactCategoryUpdate } from '../api'
import SharedContactCategoryUpdateForm from '../components/SharedContactCategoryUpdateForm'

function SharedContactCategoryUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const sharedContactCategoryById = useGetDetail(sharedContactCategoryGetById, { params: { id } })
  const updateSharedContactCategory = usePut(sharedContactCategoryUpdate)

  const handleSubmit = useCallback((values: any) => {
    return updateSharedContactCategory.putData({ data: values })
      .then(() => snackbar({ message: 'Поделенная категория контакта успешно обновлена' }))
      .then(() => navigate(ROUTES.BACKEND_SHARED_CONTACT_CATEGORY_LIST))
  }, [])

  return (
    <AppLayout>
      {!sharedContactCategoryById.loading && (
        <SharedContactCategoryUpdateForm
          onSubmit={handleSubmit}
          initialValues={sharedContactCategoryById.result}
        />
      )}
    </AppLayout>
  )
}

export default SharedContactCategoryUpdateContainer
