import React, { useState } from 'react'
import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import { pathOr, prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import { formatDate } from 'utils/dates'
import FlexBox from 'components/main/FlexBox'
import { useField } from 'formik'
import MarkerClusterGroup from 'react-leaflet-cluster'

import AutoFocusMap from './AutoFocusMap'
import TaskStatusRow from './TaskStatusRow'
import { iconFinder } from './MapIcons'

import {
  getEndItems,
  getStartItems,
  processTrackingDatas
} from '../serializers'

L.Marker.prototype.options.icon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
})

const startIcon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [2, -40],
})

interface Props {
  readonly trackingData: any;
  readonly taskListings: any;
  readonly contactTracking: any;
  readonly selectedLotLan: any;
  readonly setSelectedLotLan: any;
}

const MapCard = ({
  taskListings,
  trackingData = [],
  contactTracking = [],
  selectedLotLan,
  setSelectedLotLan,
}: Props) => {
  const [contactField] = useField('contact')
  const [selectedPolyline, setSelectedPolyline] = useState<number | null>(null)
  const handlePolylineClick = (index: number, tra:any) => {
    setSelectedPolyline(index)
    setSelectedLotLan({ indexTrajectory: index, position: { ...tra } })
  }

  const isValidLatLngArray = (arr: any[]) => {
    return Array.isArray(arr) && arr.every(point => point.lat !== undefined && point.lng !== undefined)
  }

  const startedLines = getStartItems(trackingData)
  const endLinesMarker = getEndItems(trackingData)
  const newTracking = processTrackingDatas(trackingData)

  return (
    <MapContainer
      className="Map"
      center={{ lat: 41.3123363, lng: 69.2787079 }}
      zoom={15}
      scrollWheelZoom={false}
      style={{ width: '100%', height: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup>
        {contactField.value && contactTracking.map((item: any) => {
          const name = prop('name', item)
          const shortName = prop('shortName', item)
          const lastUpdated = prop('updatedDate', item)
          const addressList = propOr([], 'addressList', item) as any

          return addressList.map((addressItem: any) => {
            const lat = prop('lat', addressItem)
            const lng = prop('lon', addressItem)
            return (
              <Marker key={`${lat}-${lng}`} position={{ lat, lng }} icon={startIcon}>
                <Popup>
                  <FlexBox align="center" direction="column" gap={4}>
                    <Typography variant="caption">Клиент: {name}</Typography>
                    <Typography variant="caption">Краткое имя клиента: {shortName}</Typography>
                    <Typography variant="caption">Дата изменения : {formatDate(lastUpdated)}</Typography>
                  </FlexBox>
                </Popup>
              </Marker>
            )
          })
        })}
      </MarkerClusterGroup>
      {newTracking.map((tracks: any, index: number) => {
        const trajectory = prop('processedTracking', tracks)
        return (
          <React.Fragment key={index}>
            {trajectory.map((item: any) => (
              item.map((tra: any, indexTrajectory: number) => {
                if (!isValidLatLngArray(tra)) return null
                const isSelected = selectedPolyline === indexTrajectory
                return (
                  <Polyline
                    key={`polyline-${indexTrajectory}-${tra.lat}-${tra.lng}`}
                    positions={tra}
                    color={isSelected ? '#000' : 'blue'}
                    weight={isSelected ? 5 : 3}
                    eventHandlers={{
                      click: (e) => {
                        tra.map((item:any, index:number) => {
                          return handlePolylineClick(index, e.latlng)
                        })
                      }
                    }}
                  />
                )
              })
            ))}
          </React.Fragment>
        )
      })}
      {startedLines.map((positions: any, index: number) => (
        <React.Fragment key={`position-${index}`}>
          {positions.map((points: any, posIndex: number) => (
            <React.Fragment key={`points-${posIndex}`}>
              {points.map((point: any, index:number) => {
                const markerKey = `starting-point-marker-${point.latitude}-${point.longitude}-${index}`
                return (
                  <Marker
                    key={markerKey}
                    position={{ lat: point.latitude || 0, lng: point.longitude || 0 }}
                    icon={startIcon}
                  >
                    <Popup>
                      <FlexBox align="center" direction="column" gap={4}>
                        <Typography variant="caption">Начальная точка</Typography>
                        <Typography variant="caption">{formatDate(point.date)}</Typography>
                      </FlexBox>
                    </Popup>
                  </Marker>
                )
              })}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      {endLinesMarker.map((positions: any, index: number) => (
        <React.Fragment key={`position-${index}}`}>
          {positions.map((points: any, posIndex: number) => (
            <React.Fragment key={`points-${posIndex}`}>
              {points.map((point: any, index:number) => {
                const markerKey = `end-point-marker-${point.latitude}-${point.longitude}-${index}`
                return (
                  <Marker
                    key={markerKey}
                    position={{ lat: point.latitude || 0, lng: point.longitude || 0 }}
                    icon={startIcon}
                  >
                    <Popup>
                      <FlexBox align="center" direction="column" gap={4}>
                        <Typography variant="caption">
                          Конечная точка
                        </Typography>
                        <Typography variant="caption">{formatDate(point.date)}</Typography>
                      </FlexBox>
                    </Popup>
                  </Marker>
                )
              })}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      {selectedLotLan?.position && (
        <Marker position={selectedLotLan.position} icon={startIcon}>
          <Popup>
            <FlexBox align="center" direction="column" gap={4}>
              <Typography variant="caption">
                {formatDate(selectedLotLan?.singleItem?.date)}
              </Typography>
            </FlexBox>
          </Popup>
        </Marker>
      )}
      {taskListings.map((tasks: any) => {
        return tasks.map((task: any, index: number) => {
          const taskName = prop('taskName', task)
          const lon = Number(pathOr(0, ['taskAddress', 'lon'], task))
          const lat = Number(pathOr(0, ['taskAddress', 'lat'], task))
          const address = { lat, lng: lon }
          const status = prop('taskStatus', task)
          const customer = prop('taskAgent', task)
          const taskType = prop('taskType', task)
          if (!lat || !lon) {
            return null // Skip this task if coordinates are invalid
          }
          return (
            <Marker
              key={`marker-task-${index}`}
              position={address}
              icon={iconFinder(status)}
            >
              <Popup>
                <FlexBox align="center" direction="column" gap={4}>
                  <Typography variant="caption">Задача: {taskName}</Typography>
                  <Typography variant="caption">
                    <FlexBox align="center">Статус: {<TaskStatusRow status={status} />}</FlexBox>
                  </Typography>
                  <Typography variant="caption">Контрагент: {customer?.name}</Typography>
                  <Typography variant="caption">Тип задачи: {taskType?.name}</Typography>
                </FlexBox>
              </Popup>
            </Marker>
          )
        })
      })}
      <AutoFocusMap
        trackingData={trackingData}
        taskListings={taskListings}
      />
    </MapContainer>
  )
}

export default MapCard
