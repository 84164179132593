import React from 'react'
import Box from '@mui/material/Box'
import { pathOr, prop } from 'ramda'
import { Link } from 'react-router-dom'
import FlexBox from 'components/main/FlexBox'
import ExcelIcon from 'assets/excel-icon.svg'
import WordIcon from 'assets/word-icon.svg'
import PdfIcon from 'assets/pdf-icon.svg'
import PptIcon from 'assets/ppt-icon.svg'
import TextIcon from 'assets/text-icon.svg'
import DefaultImage from 'assets/pircture-product.svg'

const getExtension = (filename:string) => {
  return filename.split('.').pop()
}

const imageFiles = (fileType:any) => {
  if (fileType === 'xlsx' || fileType === 'xls') {
    return ExcelIcon
  }
  if (fileType === 'doc' || fileType === 'docx') {
    return WordIcon
  }
  if (fileType === 'pdf') {
    return PdfIcon
  }
  if (fileType === 'ppt' || fileType === 'pptx') {
    return PptIcon
  }
  if (fileType === 'txt') {
    return TextIcon
  }
}

export const DocumentLinkAndName = ({ item }:any) => {
  const name = prop('name', item)
  const link = pathOr('', ['attachment', 'link'], item)
  const checkFile = getExtension(link)
  const src = imageFiles(checkFile) ? imageFiles(checkFile) : link

  return (
    <Link to={link}>
      <FlexBox
        align="center"
        gap={10}
      >
        <Box sx={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          width: '32px',
          height: '32px',
          background:'#F4F4F4',
          borderRadius:'50%'
        }}
        >
          {src ? <img src={src} alt="image" style={{ width:'32px', height:'32px', borderRadius:'50%' }} />
            : <img src={DefaultImage} alt="image" style={{ borderRadius:'50%' }} />
          }
        </Box>
        {name}
      </FlexBox>
    </Link>
  )
}
