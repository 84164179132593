import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getAdjustmentList = (options?: Options) => {
  return getRequest<any>(API.AdjustmentList, options)
}

export const getAdjustmentDetail = (options?: Options) => {
  return getRequest<any>(API.AdjustmentDetail, options)
}

export const createAdjustment = (options?: Options) => {
  return postRequest<any>(API.AdjustmentCreate, options)
}

export const updateAdjustment = (options?: Options) => {
  return putRequest<any>(API.AdjustmentUpdate, options)
}

export const deleteAdjustment = (options?: Options) => {
  return deleteRequest<any>(API.AdjustmentDelete, options)
}

export const getProductList = (options?: Options) => {
  return getRequest<any>(API.ProductList, options)
}
