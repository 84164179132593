import { useSortable } from '@dnd-kit/sortable'
import { IconButton } from '@mui/material'
import React from 'react'
import { CSS } from '@dnd-kit/utilities'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

import { DragDropIcon } from '../icons/DragDropIcon'
import SortingSortType from '../../modules/catalog/product/components/sorting/components/form/SortinSortType'
import FlexBox from '../main/FlexBox'

const useStyles = makeStyles({
  menuItem: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    height:'50px',
    alignItems: 'center',
    padding: '8px 10px',
    backgroundColor: '#fff',
    boxShadow: '0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / ' +
      'var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15)',
    borderRadius: 'calc(4px / var(--scale-x, 1))',
    boxSizing: 'border-box',
    listStyle: 'none',
    color: '#333',
    justifyContent: 'space-between',
    gap: '5px',
  },
  typography:{
    fontSize:'13px',
    lineHeight:'1.6',
    fontWeight:'500'
  },
  handleDragDropButton: {
    cursor: 'grab',
    display: 'flex',
    width: '12px',
    padding: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    touchAction: 'none',
    borderRadius: '5px',
    border: 'none',
    outline: 'none',
    appearance: 'none',
    backgroundColor: 'transparent',
    WebkitTapHighlightColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    '&:focus-visible': {
      boxShadow: '0 0px 0px 2px #4c9ffe'
    },
    '& svg': {
      flex: '0 0 auto',
      margin: 'auto',
      height: '100%',
      overflow: 'visible',
      fill: '#919eab'
    }
  }
})

export const SortableItem = (props: any) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id })
  const classes = useStyles()
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <div
      className={classes.menuItem}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <Typography className={classes.typography}>
        {props.name}
      </Typography>
      <FlexBox
        align="center"
        gap={5}
      >
        <SortingSortType
          name={`columns[${props.index}].sortType`}
        />
        <IconButton
          className={classes.handleDragDropButton}
        >
          <DragDropIcon />
        </IconButton>
      </FlexBox>
    </div>
  )
}
