import React from 'react'
import Container from '@mui/material/Container'
import { Card, CardContent, Grid, Tooltip } from '@mui/material'
import CardHeader from 'components/card/CardHeader'
import Content from 'components/container/Content'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FlexBox from 'components/main/FlexBox'

const useStyles = makeStyles({
  sharedContactImage:{
    width:'100px',
    height:'100px',
    borderRadius:'8px',
    '& img':{
      width:'100%',
      height:'100%'
    }
  },
  text:{
    lineHeight:'20px',
    fontWeight:'700',
    fontSize:'18px'
  }
})

export const SharedContactDetailForm = ({ sharedContact }:any) => {
  const classes = useStyles()

  const nameSubString = (name:string) => {
    return name.substring(0, 40)
  }

  return (
    <Container>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card sx={{ background:'#f9f9f9', padding:'0 24px' }}>
              <CardHeader title="Деталь" />
              <CardContent>
                <Grid container={true} spacing={2}>
                  <Grid item={true} lg={12}>
                    <FlexBox align="center" gap={10}>
                      {sharedContact?.images?.map((item:any, index:number) => (
                        <Box key={`image-${index}`} className={classes.sharedContactImage}>
                          <img src={item?.link} alt="shared-contact-image" />
                        </Box>
                      ))}
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="subtitle1">
                        Имя:
                      </Typography>
                      <Typography variant="body1" className={classes.text}>
                        {sharedContact?.name || ''}
                      </Typography>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        Антекс ID:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.antexId}
                      </Typography>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        Короткое имя контакта:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.shortName}
                      </Typography>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        Категория контакт:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.categories && sharedContact?.categories?.length
                          ? sharedContact?.categories?.map((item:any) => item?.name).join(', ') : ''}
                      </Typography>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        Контактный телефон:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.number}
                      </Typography>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        ИНН:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.inn}
                      </Typography>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        Веб-сайт:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.website}
                      </Typography>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6} className={classes.text}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        РС:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.rs}
                      </Typography>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        МФО:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.mfo}
                      </Typography>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        Валюта:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.currency}
                      </Typography>
                    </FlexBox>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card sx={{ background:'#f9f9f9', padding:'0 24px' }}>
              <CardHeader title="Контакты" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        Контактное лицо:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.primaryPerson?.firstName}
                      </Typography>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        Телеграм ID:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.primaryPerson?.telegramId}
                      </Typography>
                    </FlexBox>
                  </Grid>
                  <Grid item={true} lg={6}>
                    <FlexBox align="center" justify="space-between">
                      <Typography variant="body1">
                        Телефон:
                      </Typography>
                      <Typography variant="h6" className={classes.text}>
                        {sharedContact?.primaryPerson?.phone}
                      </Typography>
                    </FlexBox>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card sx={{ background:'#f9f9f9', padding:'0 24px' }}>
              <CardHeader title="Контакты дополнительные" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={12}>
                    {sharedContact?.persons?.map((person:any, index:number) => (
                      <Grid key={`${person.id}-person-${index}`} container={true} spacing={3}>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Контактное лицо {index + 1}:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {person?.firstName}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Телеграм ID {index + 1}:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {person?.telegramId}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Телефон {index + 1}:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {person?.phone}
                            </Typography>
                          </FlexBox>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card sx={{ background:'#f9f9f9', padding:'0 24px' }}>
              <CardHeader title="Финансовые детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={12}>
                    {sharedContact?.financialDetails?.map((finance:any, index:number) => (
                      <Grid key={`${finance.id}-finance-${index}`} container={true} spacing={3}>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Имя банковского счета:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {finance?.bankAccountName}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Номер банковского счета:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {finance?.bankAccountNumber}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              МФО:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {finance?.mfo}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Подробности:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {finance?.details}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Налог ID:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {finance?.taxId}
                            </Typography>
                          </FlexBox>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card sx={{ background:'#f9f9f9', padding:'0 24px' }}>
              <CardHeader title="Адрес" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={12}>
                    {sharedContact?.addressList?.map((address:any, index:number) => (
                      <Grid key={`${address.id}-address-${index}`} container={true} spacing={3}>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Имя адреса:
                            </Typography>
                            <Tooltip title={address?.name}>
                              <Typography variant="h6" className={classes.text}>
                                {nameSubString(address?.name)}...
                              </Typography>
                            </Tooltip>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Адрес:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {address?.addressLine1}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Адрес дополнительный:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {address?.addressLine2}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Ориентир:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {address?.landmark}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Название страны:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {address?.country?.name}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Город:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {address?.region?.name}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Лат/Лон:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {address?.lat}/{address?.lon}
                            </Typography>
                          </FlexBox>
                        </Grid>
                        <Grid item={true} lg={6}>
                          <FlexBox align="center" justify="space-between">
                            <Typography variant="body1">
                              Название страны:
                            </Typography>
                            <Typography variant="h6" className={classes.text}>
                              {address?.country?.name}
                            </Typography>
                          </FlexBox>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}
