import React from 'react'
import { makeStyles } from '@mui/styles'
import FlexBox from 'components/main/FlexBox'

import AppNavbarPublic from '../components/app-navbar'
import { Footer } from '../components/footer'

interface Props {
  readonly children: React.ReactNode
  readonly containerClass?: string
}

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh'
  },
  container: {
    flex: '1 1',
    overflow: 'auto',
    overflowX: 'hidden',
    background: '#FFFFFF',
    position: 'relative'
  }
})

function LandingLayout2 ({ children, containerClass = '' }:Props) {
  const classes = useStyles()

  return (
    <FlexBox direction="column" className={classes.root}>
      <AppNavbarPublic />
      <div className={`${classes.container} ${containerClass}`}>
        {children}
      </div>
      <Footer />
    </FlexBox>
  )
}

export default LandingLayout2
