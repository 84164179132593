import React from 'react'
import { prop } from 'ramda'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'

import CategoryField from '../form/CategoryField'
import StatusField from '../form/StatusField'
import UnitMeasurementField from '../form/UnitMeasurementField'
import BrandField from '../form/BrandField'
import SupplierField from '../form/SupplierField'
import ProductTypeField from '../form/ProductTypeField'
import PackageField from '../form/PackageField'
import CountryField from '../form/CountryField'
import { FILTER_CODE, FILTER_FIELD_TYPE } from '../../../../constants'

const getFieldByType = (fieldType:string, code:string, rest: any) => {
  if (code === FILTER_CODE.UNIT_MEASUREMENT) {
    return <UnitMeasurementField {...rest} />
  }
  if (code === FILTER_CODE.PACKAGE_LINE_ITEM) {
    return <PackageField {...rest} />
  }
  if (code === FILTER_CODE.PRODUCT_CATEGORY) {
    return <CategoryField {...rest} />
  }
  if (code === FILTER_CODE.SUPPLIER) {
    return <SupplierField {...rest} />
  }
  if (code === FILTER_CODE.BRAND) {
    return <BrandField {...rest} />
  }
  if (code === FILTER_CODE.PRODUCT_STATUS) {
    return <StatusField {...rest} />
  }
  if (code === FILTER_CODE.PRODUCT_TYPE) {
    return <ProductTypeField {...rest} />
  }
  if (code === FILTER_CODE.PRODUCE_IN) {
    return <CountryField {...rest} />
  }

  if (fieldType === FILTER_FIELD_TYPE.DATE_TIME_PICKER) {
    return (
      <DateField
        label="Дата"
        {...rest}
      />
    )
  }
  if (fieldType === FILTER_FIELD_TYPE.NUMBER_BOX) {
    return (
      <TextField
        label="Ценить"
        type="number"
        {...rest}
      />
    )
  }

  return (
    <TextField
      label="Значение поля"
      {...rest}
    />
  )
}

export const ComparatorValueFields = (props: any) => {
  const { item, ...rest } = props
  const code = prop('code', item)
  const fieldType = prop('fieldType', item)
  return getFieldByType(fieldType, code, rest)
}
