import { map, omit, path, pathOr, prop } from 'ramda'
import { formatDate, toPayloadDate } from 'utils/dates'

export const getListSerializer = (values:any) => {
  const omittedResult = omit(['search', 'contact', 'territoryId'], values)
  const from = formatDate(prop('from', omittedResult))
  const to = formatDate(prop('to', omittedResult))
  return {
    ...omittedResult,
    from,
    to
  }
}

export const getListContactTracking = (values:any) => {
  const territoryId = path(['territoryId', 'id'], values)
  return {
    territoryId
  }
}

export const getListTaskTracking = (values:any) => {
  const userIds = prop('userIds', values)
  const territoryId = path(['territoryId', 'id'], values)
  const plannedStartDate = toPayloadDate(prop('from', values))
  const plannedEndDate = toPayloadDate(prop('to', values))
  return {
    plannedStartDate,
    plannedEndDate,
    territoryId,
    userIds
  }
}

export const getTaskListSerializer = (values:any) => {
  const plannedStartDate = toPayloadDate(prop('from', values))
  const plannedEndDate = toPayloadDate(prop('to', values))
  const omittedResult = omit(['search', 'from', 'to', 'contact', 'territoryId'], values)
  return {
    ...omittedResult,
    plannedStartDate,
    plannedEndDate
  }
}

export const getVisitListAddress = (values:any) => {
  return values && values.map((item: any) => {
    const taskName = prop('name', item)
    const taskAddress = prop('address', item)
    const taskStatus = prop('status', item)
    const taskType = prop('type', item)
    const taskAgent = prop('customer', item)
    return {
      taskAgent,
      taskType,
      taskName,
      taskStatus,
      taskAddress
    }
  })
}

export function filterTasksByStatus (tasks:any, status:string) {
  return tasks.filter((task:any) => task.taskStatus === status)
}

interface Coordinates {
  lat: number;
  lng: number;
}

export const areCoordinatesClose = (coord1: Coordinates, coord2: Coordinates, tolerance = 0.0001): boolean => {
  if (coord1.lat && coord2.lat && coord1.lng && coord2.lng) {
    const latDiff = Math.abs(coord1.lat - coord2.lat)
    const lngDiff = Math.abs(coord1.lng - coord2.lng)
    return latDiff < tolerance && lngDiff < tolerance
  }
  return false
}

export const processTrackingData = (trackingData: any[]) => {
  return map((data: any) => {
    const trackingList = pathOr([], ['trackingList'], data)
    return map((track: any) =>
      map((item: any) => ({
        lat: parseFloat(item.latitude),
        lng: parseFloat(item.longitude),
      }), track), trackingList)
  }, trackingData)
}

export const getStartItems = (trackingData:any) => {
  return trackingData.map((data:any) => {
    const trackingList = data.trackingList || []
    return trackingList.map((track: any) => {
      return track.map((item: any) => {
        if (item.event === 'START') {
          return item
        } else return null
      }).filter((item: any) => item !== null)
    })
  })
}

export const getEndItems = (trackingData:any) => {
  return trackingData.map((data:any) => {
    const trackingList = data.trackingList || []
    return trackingList.map((track: any) => {
      return track.map((item: any) => {
        if (item.event === 'END') {
          return item
        } else return null
      }).filter((item: any) => item !== null)
    })
  })
}

type TrackingItem = {
  id: string;
  companyId: number;
  date: string;
  createdDate: string;
  userId: number;
  latitude: string;
  longitude: string;
  entity: any;
  event: string;
  batteryPercentage: number;
};

export function processTrackingList (trackingList: TrackingItem[][]): any {
  if (trackingList) {
    return trackingList.map(subList => {
      const result: TrackingItem[][] = []
      let currentGroup: any[] = []

      for (const item of subList) {
        if (item.event === 'START') {
          if (currentGroup.length) {
            result.push(currentGroup)
          }
          currentGroup = [{ date:item.date, lat:parseFloat(item.latitude), lng:parseFloat(item.longitude) }]
        } else if (item.event === 'MOVEMENT') {
          currentGroup.push({ date:item.date, lat:parseFloat(item.latitude), lng:parseFloat(item.longitude) })
        }
      }

      if (currentGroup.length) {
        result.push(currentGroup)
      }

      return result
    })
  } else return []
}

export function processTrackingDatas (data: any[]): any[] {
  return data.map(user => {
    const userId = user.userId
    const processedTracking = processTrackingList(user.trackingList)
    return { userId, processedTracking }
  })
}
