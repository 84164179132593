import React, { ReactNode } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import RefreshIcon from 'assets/refresh-icon.svg'
import SearchIcon from 'components/icons/SearchIcon'
import FilterIcon from 'assets/filter-icon.svg'
import { makeStyles } from '@mui/styles'

import TableActionButtons from './TableActionButtons'
import { Actions } from './Table'

import FlexBox from '../main/FlexBox'

const useStyles = makeStyles({
  actionButton: {
    width: '50px',
    position: 'relative',

    '&::after': {
      content: '""',
      width: '1px',
      height: '20px',
      background: '#E1E1E1',
      position: 'absolute',
      left: '100%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },

    '&:first-child::before': {
      content: '""',
      width: '1px',
      height: '20px',
      background: '#E1E1E1',
      position: 'absolute',
      left: '0',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },

    '& .MuiButtonBase-root': {
      width: '45px',
      height: '45px'
    }
  },
})

interface Props {
  readonly actionsBan?:boolean
  readonly actionBanWarning?:string
  readonly onRefreshClick?: () => void
  readonly onTabSettingsClick?: () => void
  readonly onFilterClick?: () => void
  readonly onExportClick:() => void
  readonly setOpenSearch: (open:boolean) => void
  readonly actionDisabled?: boolean
  readonly refreshDisabled?: boolean
  readonly tableActions?: ReactNode
  readonly onOpenTabs?:() => void
  readonly moreActions?:(action:Actions) => ReactNode
  readonly onSortingClick?: () => void
}

function TableHeaderButtons (props: Props) {
  const classes = useStyles()
  const {
    onRefreshClick,
    onFilterClick,
    onExportClick,
    onTabSettingsClick,
    onSortingClick,
    actionDisabled,
    refreshDisabled,
    tableActions,
    moreActions,
    setOpenSearch,
    onOpenTabs,
    actionsBan,
    actionBanWarning
  } = props
  const handleClick = () => {
    setOpenSearch(true)
  }

  return (
    <FlexBox align="center">
      {!actionDisabled && (
        <TableActionButtons
          actionsBan={actionsBan}
          actionBanWarning={actionBanWarning}
          disabled={actionDisabled}
          tableActions={tableActions}
        />
      )}
      {onRefreshClick && (
        <FlexBox justify="center" flex className={classes.actionButton}>
          <Tooltip title="Обновить">
            <IconButton onClick={onRefreshClick} disabled={refreshDisabled}>
              <img src={RefreshIcon} alt="refresh" />
            </IconButton>
          </Tooltip>
        </FlexBox>
      )}
      <FlexBox justify="center" flex className={classes.actionButton}>
        <Tooltip title="Поиск">
          <IconButton onClick={handleClick}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </FlexBox>
      {onFilterClick && (
        <FlexBox justify="center" flex className={classes.actionButton}>
          <Tooltip title="Фильтр">
            <IconButton onClick={onFilterClick}>
              <img src={FilterIcon} alt="logo" />
            </IconButton>
          </Tooltip>
        </FlexBox>
      )}
      {(moreActions && (
        <FlexBox justify="center" flex className={classes.actionButton}>
          {moreActions && moreActions({
            onExportClick,
            onTabSettingsClick,
            onOpenTabs,
            onSortingClick
          })}
        </FlexBox>
      ))}
    </FlexBox>
  )
}

export default TableHeaderButtons
