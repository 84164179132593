import * as API from 'constants/Api'

import React from 'react'
import SearchField from 'components/form/SearchField'
import { path, prop } from 'ramda'
import { useField } from 'formik'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value, name, id: value }
  }

  return null
}

function WarehouseField (props: any) {
  const [ territoryId, , ] = useField('territoryId')
  const id = path(['value', 'id'], territoryId)
  return (
    <SearchField
      disabled={!id}
      name="warehouseId"
      api={API.WarehouseSuggestions}
      params={{ territoryId:id }}
      getOptionValue={getOptionValue}
      label="Склад"
      {...props}
    />
  )
}

export default WarehouseField
