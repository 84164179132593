import React from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles({
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    },
    '&.warning': {
      background: '#ffcc00'
    }
  }
})

interface Props {
  readonly status: string
}

const statusTranslations: { [key: string]: string } = {
  BOOKED: 'ЗАБРОНИРОВАНО',
  DELIVERED: 'ДОСТАВЛЕНО',
  PACKED: 'УПАКОВАНО',
  PICKED: 'ВЫБРАНО',
  PARTIAL_DELIVERED: 'ЧАСТИЧНО',
  COMPLETED: 'ЗАВЕРШЕНО',
  RETURNED:'ВОЗВРАЩЕННЫЙ',
  SHIPPING:'ДОСТАВКА',
  DRIVER_ASSIGNED:'ВОДИТЕЛЬ НАЗНАЧЕН',
  COLLECTED:'ВОЗВРАТ СОБРАН'
}

const getTranslatedStatus = (status: string) => {
  return statusTranslations[status]
}
function ShipmentStatusRow ({ status }: Props) {
  const isDelivered = status === 'DELIVERED' || status === 'COMPLETED'
  const isWarning = status === 'SHIPPING' || status === 'PACKED' || status === 'PICKED' ||
                              status === 'PARTIAL_DELIVERED'
  const classes = useStyles()
  return (
    <div className={cx(classes.statusIndicator, { 'active': isDelivered, 'warning': isWarning })}>
      {getTranslatedStatus(status)}
    </div>
  )
}

export default ShipmentStatusRow
