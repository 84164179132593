import * as API from 'constants/Api'

import React from 'react'
import SearchField from 'components/form/SearchField'
import { prop } from 'ramda'

const getOptionValue = (option: any) => {
  if (option) {
    const id = prop('id', option)
    const name = prop('name', option)

    return { name, id }
  }

  return null
}

function SupplierField (props: any) {
  return (
    <SearchField
      name="supplier"
      api={API.ContactSuggestions}
      getOptionValue={getOptionValue}
      label="Поставщик"
      {...props}
    />
  )
}

export default SupplierField
