import React from 'react'
import SelectField from 'components/form/SelectField'

export enum CUSTOM_FIELD_TYPE {
  TEXT_BOX = 'TEXT_BOX',
  NUMBER_BOX = 'NUMBER_BOX',
  TEXT_AREA = 'TEXT_AREA',
  DROPDOWN = 'DROPDOWN',
  DATE_PICKER = 'DATE_PICKER',
  DATE_TIME_PICKER = 'DATE_TIME_PICKER',
  CHECK_BOX = 'CHECK_BOX',
  MULTI_CHECK_BOX = 'MULTI_CHECK_BOX',
  RADIO_BUTTON = 'RADIO_BUTTON',
  LOOKUP = 'LOOKUP',
  MULTI_LOOKUP = 'MULTI_LOOKUP',
}

const OPTIONS = [
  { value: CUSTOM_FIELD_TYPE.TEXT_BOX, name: 'Текстовый' },
  { value: CUSTOM_FIELD_TYPE.NUMBER_BOX, name: 'Номерной' },
  { value: CUSTOM_FIELD_TYPE.TEXT_AREA, name: 'Поле' },
  { value: CUSTOM_FIELD_TYPE.DROPDOWN, name: 'Раскрывающийся ' },
  { value: CUSTOM_FIELD_TYPE.DATE_PICKER, name: 'Дата' },
  { value: CUSTOM_FIELD_TYPE.DATE_TIME_PICKER, name: 'Дата и время' },
  { value: CUSTOM_FIELD_TYPE.CHECK_BOX, name: 'Флажок' },
  { value: CUSTOM_FIELD_TYPE.MULTI_CHECK_BOX, name: 'Несколько флажков' },
  { value: CUSTOM_FIELD_TYPE.RADIO_BUTTON, name: 'Переключатель' },
  { value: CUSTOM_FIELD_TYPE.LOOKUP, name: 'Поиск' },
  { value: CUSTOM_FIELD_TYPE.MULTI_LOOKUP, name: 'Мультипросмотр' },
]

function CustomFieldType (props: any) {
  return (
    <SelectField
      name="fieldType"
      label="Тип поля"
      options={OPTIONS}
      {...props}
    />
  )
}

export default CustomFieldType
