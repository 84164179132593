import * as ROUTES from 'constants/Routes'

import * as Yup from 'yup'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { createContact } from '../api'
import { agentCreateSerialize } from '../serializer'
import SharedContactCreateForm from '../components/SharedContactCreateForm'

export const validationSchema = Yup.object({
  shortName:Yup.string().required('Наименование контрагентa обязательно')
})

function SharedContactCreateContainer () {
  const agentCreate = usePost(createContact)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = useCallback((values: any) => {
    return agentCreate.postData({ data: agentCreateSerialize(values) })
      .then(() => snackbar({ message: 'Поделенный контакт успешно создан' }))
      .then(() => navigate(ROUTES.BACKEND_SHARED_CONTACT_LIST))
  }, [])

  return (
    <AppLayout>
      <SharedContactCreateForm
        initialValues={{
          shortName:'',
          territories:[],
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      />
    </AppLayout>
  )
}

export default SharedContactCreateContainer
