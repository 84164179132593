import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'ACTIVE', name: 'АКТИВНЫЙ' },
  { value: 'INACTIVE', name: 'НЕАКТИВНЫЙ' },
]

function StatusField (props:any) {
  return (
    <SelectField
      name="productType"
      label="Cтатус"
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default StatusField
