export const I18nRussian: any = {
  'Login': 'Войти',
  'Manage your company in one click': 'Управляйте компанией в один клик',
  'Phone number or email': 'Номер телефона или электронная почта',
  'Password': 'Пароль',
  'Forgot password?' : 'Забыли пароль?',
  'Actions': 'Действия',
  'Order Number' : 'Номер заказа',
  'Order Date' : 'Дата заказа',
  'Warehouse Group' : 'Группа складов',
  'Client' : 'Клиент',
  'Territory' : 'Территория',
  'Status' : 'Статус',
  'Amount' : 'Сумма',
  'Price Level' : 'Ценовая политика',
  'Terms' : 'Условия',
  'Currency' : 'Валюта'
}
