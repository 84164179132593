import React, { useCallback } from 'react'
import { Box, Card, CardContent, Grid, Skeleton, Tooltip } from '@mui/material'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import ExcelIcon from 'assets/excel-icon.svg'
import WordIcon from 'assets/word-icon.svg'
import PdfIcon from 'assets/pdf-icon.svg'
import PptIcon from 'assets/ppt-icon.svg'
import TextIcon from 'assets/text-icon.svg'

import Dropzone from './Dropzone'
import { attachmentUpload, attachmentDelete } from './api'

import CardHeader from '../card/CardHeader'
import { usePost } from '../../hooks/usePost'
import { DeleteIcon } from '../icons/DeleteIcon'

const getExtension = (filename:string) => {
  return filename.split('.').pop()
}

const imageFiles = (fileType:any) => {
  if (fileType === 'xlsx' || fileType === 'xls') {
    return ExcelIcon
  }
  if (fileType === 'doc' || fileType === 'docx') {
    return WordIcon
  }
  if (fileType === 'pdf') {
    return PdfIcon
  }
  if (fileType === 'ppt' || fileType === 'pptx') {
    return PptIcon
  }
  if (fileType === 'txt') {
    return TextIcon
  }
}

interface Props {
  readonly onAddAttachment?: any
  readonly attachments?: any
  readonly onDeleteAttachment?: any
}

function AttachmentUpload ({ onAddAttachment, attachments = [], onDeleteAttachment }: Props) {
  const uploadAttachment = usePost(attachmentUpload)
  const deleteAttachment = usePost(attachmentDelete)

  const onDrop = useCallback((values: any = []) => {
    const data = new FormData()
    values.forEach((file: any) => {
      data.append('uploadFile', file)
    })
    uploadAttachment.postData({ data }).then((response) => {
      onAddAttachment(response)
    })
  }, [])

  const handleDelete = useCallback((id: any) => {
    deleteAttachment.postData({ params: { id } }).then(() => {
      onDeleteAttachment(id)
    })
  }, [])

  return (
    <Card>
      <CardHeader title="Добавьте файлы." />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={2}>
            <Dropzone onDrop={onDrop} />
          </Grid>
          {attachments.map((item: any) => {
            const id = prop('id', item)
            const originalName = prop('originalName', item)
            const link = prop('link', item) as string
            const checkFile = getExtension(link)
            const imageLink = imageFiles(checkFile) ? imageFiles(checkFile) : link
            return (
              <Grid item={true} lg={2}>
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'flex-end',
                    justifyContent:'center',
                    position: 'relative',
                    width:'200px',
                    height:'200px',
                    background:'#F8F8F8',
                    borderRadius:'8px 8px 0 0',
                  }}
                >
                  <span
                    style={{
                      position: 'absolute',
                      fontSize: 25,
                      color: 'white',
                      cursor: 'pointer',
                      right:'25px',
                      top:'15px'
                    }}
                    onClick={() => handleDelete(id)}
                  >
                    <DeleteIcon />
                  </span>
                  <img
                    src={imageLink}
                    alt={originalName}
                    style={{
                      height: 150,
                      width:150,
                      borderRadius:'8px 8px 0 0',
                      objectFit: 'cover',
                      cursor:'pointer'
                    }}
                  />
                </Box>
                <Tooltip title={originalName}>
                  <Typography
                    noWrap
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '200px',
                      marginTop:'8px'
                    }}
                  >
                    {originalName}
                  </Typography>
                </Tooltip>
              </Grid>
            )
          })}
          {(uploadAttachment.loading) && (
            <Grid item={true} lg={3}>
              <Box
                sx={{
                  display:'flex',
                  alignItems:'flex-end',
                  justifyContent:'center',
                  width:'200px',
                  height:'200px',
                  background:'#F8F8F8',
                  borderRadius:'8px 8px 0 0',
                }}
              >
                <Skeleton variant="rectangular" width={150} height={150} sx={{ borderRadius:'8px 8px 0 0' }} />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AttachmentUpload
