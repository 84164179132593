import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { name: 'UZS', value: 'UZS' },
  { name: 'USD', value: 'USD' },
  { name: 'GPB', value: 'GPB' },
  { name: 'KZS', value: 'KZS' },
  { name: 'RUB', value: 'RUB' },
  { name: 'CNY', value: 'CNY' }
]

function CurrencyField (props: any) {
  return (
    <SelectField
      name="currency"
      label="Валюта"
      options={OPTIONS}
      {...props}
    />
  )
}

export default CurrencyField
