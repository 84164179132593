import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { ALTER_INFO, useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { usePut } from 'hooks/usePut'
import { useConfirm } from 'context/confirm'
import { path, prop, propOr, trim } from 'ramda'
import useAllSearchParams from 'utils/useAllSearchParams'
import { usePost } from 'hooks/usePost'
import { useGetDetailWithoutCall } from 'hooks/useGetDetailWithoutCall'
import { usePatchList } from 'hooks/usePatchList'
import { useRoutes } from 'context/route'
import FlexBox from 'components/main/FlexBox'
import { useLocales } from 'hooks/useLocales'

import {
  deleteProduct,
  deleteProductByIds,
  getByIdTab,
  getColumnsList,
  getFilterList,
  getProductList,
  productExportExcel,
  productFilterPreview,
  statusBulkChangeProduct,
  statusChangeProduct,
  tabsCounts
} from '../api'
import ProductRowMenu from '../components/ProductRowMenu'
import ProductStatusRow from '../components/ProductStatusRow'
import ProductTypeRow from '../components/ProductTypeRow'
import BarcodePrint from '../components/BarcodePrint'
import TableActionsRow from '../components/TableActionsRow'
import { selectedRowSerializer } from '../serializer'
import ListImage from '../components/ListImage'
import MoreActionButtons from '../components/MoreRowMenu'
import ProductListExcelFilter from '../components/ProductListExcelFilter'
import Filter from '../components/filter/Filter'
import { getColumnsBySetting } from '../components/ProductListFilterColumn'
import ColumnSettingList from '../components/column-settings/ColumnSettingList'
import TabsList from '../components/tabs/TabsList'
import { productFilterPreviewSerializer } from '../components/filter/serializer'
import SortingList from '../components/sorting/SortingList'

const columns = [
  {
    headerName: 'Наименование товара',
    leftStick: true,
    field: 'name',
    renderCell: (item: any) => (
      <ListImage id={item.id} src={path(['image', 'small', 'link'], item)} name={item.name} />
    )
  },
  {
    headerName: 'Штрих-код',
    field: 'barcode',
    renderCell: (item: any) => item.barcode && item.barcode.length ? item.barcode.join(', ') : ''
  },
  {
    headerName: 'Оповещение о низком запасе',
    field: 'lowStockAlert'
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (item: any) => <ProductStatusRow status={item.status} />
  },
  {
    headerName: 'Тип',
    field: 'productType',
    renderCell: (item: any) => <ProductTypeRow type={item.productType} />
  }
]

function ProductListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const searchParams = useAllSearchParams()
  const printRef = useRef(null) as any
  const { isHasActiveTab } = useRoutes()
  const { t } = useLocales('product')
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [settingColumns, setSettingColumns] = useState({})
  const [sorting, setSorting] = useState({}) as any
  const [limit, setLimit] = useState(10)
  const [filter, setFilter] = useState({}) as any
  const [filterPreviewData, setFilterPreviewData] = useState({}) as any
  const [tabId, setTabId] = useState('') as any
  const [pinFilter, setPinFilter] = useState<boolean>(false)
  const productList = useGetList(getProductList, { query: { limit, tabId } })
  const filterList = useGetList(getFilterList, { query: { type: 'PRODUCT_LIST_PANEL' } })
  const columnList = useGetList(getColumnsList, { query: { type: 'PRODUCT_LIST_PANEL' } })
  const tabList = useGetList(tabsCounts)
  const productDelete = useDelete(deleteProduct)
  const productStatusChange = usePut(statusChangeProduct)
  const productDeleteByIds = useDelete(deleteProductByIds)
  const productExport = usePost(productExportExcel)
  const productsChangeStatus = usePut(statusBulkChangeProduct)
  const listingTabsRecords = useGetDetailWithoutCall(tabsCounts)
  const listingTabDetail = useGetDetailWithoutCall(getByIdTab)
  const filterPreviewList = usePatchList(
    productFilterPreview,
    { data: productFilterPreviewSerializer(filterPreviewData) }
  )
  const lastActiveTab = isHasActiveTab('product')
  const listGet = Object.keys(filterPreviewData).length === 0 ? productList : filterPreviewList

  const list = propOr([], 'list', productList)
  const tabs = propOr([], 'result', listingTabsRecords) as any
  const lastActiveTabId = propOr('', 'tabId', lastActiveTab) as number

  useEffect(() => {
    const updateDetails = (id: number) => {
      listingTabDetail.getDetail({ params: { id } })
        .then((res) => {
          const settings = propOr({}, 'listingColumn', res) as any
          const filter = propOr({}, 'listingFilter', res)
          const sorting = propOr({}, 'listingSort', res)
          setFilter(filter)
          setSorting(sorting)
          setSettingColumns(settings)
        })
    }
    if (lastActiveTabId) {
      updateDetails(lastActiveTabId)
    }
  }, [tabId, lastActiveTabId])

  useEffect(() => {
    if (Object.keys(filterPreviewData).length > 0) {
      listGet.getList()
    }
    if (filterPreviewData.length === 0) {
      listGet.getList({ query: { tabId: lastActiveTabId } })
    }
  }, [filterPreviewData])

  const getColumns = useMemo(() => {
    const settingId = propOr('', 'id', settingColumns)
    if (settingId) {
      return getColumnsBySetting(settingColumns)
    } else return columns
  }, [settingColumns])

  const handleDeleteProduct = useCallback((values: any) => {
    const message = `Вы уверены, что хотите удалить продукт ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        productDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Продукт успешно удалён' }))
          .then(() => productList.getList({ query: searchParams }))
      })
      .disagree()
  }, [searchParams])

  const onStatusChange = useCallback((item: any) => {
    productStatusChange.putData({ params: { id: item.id } })
      .then(() => snackbar({ message: 'Статус успешно обновлён' }))
      .then(() => productList.getList({ query: searchParams }))
  }, [searchParams])

  const selectedBarcodeList = useMemo(() => {
    return selectedRows.reduce((a: any, b: any) => {
      const barcodes = propOr([], 'barcode', b) as any
      const barcodeList = barcodes.map((bar: any) => ({ barcode: bar, name: prop('name', b) }))
      return [...barcodeList as any, ...a]
    }, [])
  }, [selectedRows])

  const handleDeleteSelected = useCallback(async () => {
    const message = `Вы уверены что хотите удалить выбранные продукты?`

    onConfirm({ message })
      .agree(() => {
        productDeleteByIds.deleteData({ data: selectedRowSerializer(selectedRows) })
          .then((res: any) => {
            if (res.length === 0) {
              setSelectedRows([])
              snackbar({ message: `${selectedRows.length} продукты успешно удалён` })
            } else {
              const filteredProducts = selectedRows.filter((product: { id: any }) => res.includes(product.id))
              setSelectedRows(filteredProducts)
              snackbar({
                message: `${selectedRows.length - res.length} продукта были успешно удалены, но ${res.length}
               элементы не могут быть удалены, поскольку они используются.`,
                type: ALTER_INFO
              })
            }
          })
          .then(() => productList.getList({ query: searchParams }))
      })
      .disagree()
  }, [selectedRows, productList, searchParams])

  const handleSearch = useCallback((values: any) => {
    const queryIsThere = values && { query: { ...searchParams, searchKey: trim(prop('search', values)) } }
    productList.getList(queryIsThere)
  }, [searchParams])

  const selectedNoBarcode = useMemo(() => {
    return selectedRows.find((item: any) => item?.barcode?.length === 0)
  }, [selectedRows])

  const handleChangeStatusProducts = useCallback((status: string) => {
    productsChangeStatus.putData({ data: { productIds: selectedRowSerializer(selectedRows), productStatus: status } })
      .then(() => productList.getList({ query: searchParams }))
      .then(() => setSelectedRows([]))
      .then(() => snackbar({ message: 'Статус успешно обновлён' }))
  }, [selectedRows, searchParams])

  const onGetExcelSelected = useCallback(() => {
    productExport.postData({ query: { productIds: selectedRowSerializer(selectedRows) }, responseType: 'blob' })
      .then((response: any) => {
        // Convert response to blob
        const blob = new Blob([response])

        // Create a download link
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', `Selected-Products.xlsx`)
        document.body.appendChild(link)
        link.click()

        // Cleanup
        window.URL.revokeObjectURL(link.href)
      })
      .then(() => setSelectedRows([]))
  }, [selectedRows])

  const onGetThisPageExcel = useCallback(() => {
    productExport.postData({ query: { productIds: selectedRowSerializer(list) }, responseType: 'blob' })
      .then((response: any) => {
        // Convert response to blob
        const blob = new Blob([response])

        // Create a download link
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', `This-Page-Products.xlsx`)
        document.body.appendChild(link)
        link.click()

        // Cleanup
        window.URL.revokeObjectURL(link.href)
      })
      .then(() => setSelectedRows([]))
  }, [list])

  const onGetExcel = useCallback(() => {
    productExport.postData({ responseType: 'blob' })
      .then((response: any) => {
        // Convert response to blob
        const blob = new Blob([response])

        // Create a download link
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', `Products.xlsx`)
        document.body.appendChild(link)
        link.click()

        // Cleanup
        window.URL.revokeObjectURL(link.href)
      })
  }, [])

  const onFilter = useCallback((item: any) => {
    setFilter(item)
    setLimit(item.limitRow || 10)
  }, [])

  const onSetColumn = useCallback((item: any) => {
    setSettingColumns(item)
  }, [])

  const onFilterPreview = (filter: any) => {
    setFilterPreviewData(filter)
  }

  const onSetTab = useCallback((tabId: number) => {
    setTabId(tabId)
  }, [])

  const filterNode = pinFilter ? null : (
    <Filter
      filter={filter}
      onFilter={onFilter}
      filterList={filterList}
      setPinFilter={() => setPinFilter(prevState => !prevState)}
      onFilterPreview={onFilterPreview}
      filterPreviewData={filterPreviewData}
    />
  )

  return (
    <AppLayout>
      <FlexBox align="flex-start">
        <Table
          title={t('Products')}
          onAddClick={() => navigate(ROUTES.CATALOG_PRODUCT_CREATE)}
          dataRequest={listGet}
          tabRequest={listingTabsRecords}
          lastActiveTab={lastActiveTab}
          translationModule="product"
          listingTableName="product"
          columns={getColumns}
          onSearchClick={handleSearch}
          limit={limit}
          setLimit={setLimit}
          tabId={tabId}
          setTabId={setTabId}
          filter={filter}
          tabs={tabs}
          checkbox={true}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          searchPlaceholder={t('Search by name, short name, barcode, box barcode, SKU or ID')}
          actions={(row) => (
            <ProductRowMenu
              item={row}
              handleDelete={handleDeleteProduct}
              handleStatus={onStatusChange}
            />
          )}
          tableActions={(
            <TableActionsRow
              printRef={printRef}
              selectedRows={selectedRows}
              selectedNoBarcode={selectedNoBarcode}
              onGetExcelSelected={onGetExcelSelected}
              onChangeStatus={handleChangeStatusProducts}
              handleDeleteSelected={handleDeleteSelected}
            />
          )}
          exportNode={(
            <ProductListExcelFilter
              productList={productList} />
          )}
          moreActions={(actions) => (
            <MoreActionButtons
              actions={actions}
              onExportExcel={onGetExcel}
              onGetThisPageExcel={onGetThisPageExcel}
            />
          )}
          filterNode={filterNode}
          columnNode={(
            <ColumnSettingList
              filterList={columnList}
              onFilter={onSetColumn}
              settingColumns={settingColumns}
            />
          )}
          tabNode={(
            <TabsList
              filterList={tabList}
              onFilter={onSetTab}
              listGet={listGet}
              activeTab={tabId || lastActiveTabId}
              listingTabsRecords={listingTabsRecords}
            />
          )}
          sortingNode={(
            <SortingList
              activeSortingId={sorting?.id}
            />
          )}
        />
        <div style={{ position: 'absolute', zIndex: -1 }}>
          <BarcodePrint
            ref={printRef}
            barcodeList={selectedBarcodeList}
          />
        </div>
        {pinFilter && (
          <Filter
            filter={filter}
            onFilter={onFilter}
            filterList={filterList}
            setPinFilter={() => setPinFilter(prevState => !prevState)}
            onFilterPreview={onFilterPreview}
            filterPreviewData={filterPreviewData}
          />
        )}
      </FlexBox>
    </AppLayout>
  )
}

export default ProductListContainer
