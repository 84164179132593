import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { path, propOr, trim } from 'ramda'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { formatMoney } from 'utils/formatMoney'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'

import { changeStatusSupplierPayment, deleteSupplierPayment, getSupplierPaymentsList } from '../api'
import SupplierPaymentsStatusRow from '../components/SupplierPaymentsStatusRow'
import SupplierPaymentsMethodRow from '../components/SupplierPaymentsMethodRow'
import SupplierPaymentRowMenu from '../components/SupplierPaymentsRowMenu'

const columns = [
  {
    headerName: 'Номер',
    field: 'number'
  },
  {
    headerName: 'Название счета',
    field: 'account',
    renderCell: path(['account', 'name'])
  },
  {
    headerName: 'Название контрагента',
    field: 'contact',
    renderCell: path(['contact', 'name'])
  },
  {
    headerName: 'Способ оплаты',
    field: 'paymentMethod',
    renderCell: (item:any) => <SupplierPaymentsMethodRow status={item.paymentMethod} />
  },
  {
    headerName: 'Валюта',
    field: 'currency'
  },
  {
    headerName: 'Сумма',
    field: 'amount',
    renderCell:(item:any) => formatMoney(item.amount)
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell:(item:any) => <SupplierPaymentsStatusRow status={item.status} />
  },
  {
    headerName: 'Дата',
    field: 'date'
  },
]

function SupplierPaymentsListContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const supplierPaymentsList = useGetList(getSupplierPaymentsList)
  const supplierPaymentsDelete = useDelete(deleteSupplierPayment)
  const supplierPaymentChangeStatus = usePut(changeStatusSupplierPayment)

  const handleDelete = useCallback((id:number) => {
    supplierPaymentsDelete.deleteData({ params:{ paymentId:id } })
      .then(() => supplierPaymentsList.getList())
      .then(() => snackbar({ message: 'Платежи поставщика успешно удален' }))
  }, [])

  const changeStatus = useCallback((values:any) => {
    supplierPaymentChangeStatus.putData({ data: values })
      .then(() => supplierPaymentsList.getList())
      .then(() => snackbar({ message: 'Статус платежи поставщика успешно обновлен' }))
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    supplierPaymentsList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Платежи поставщика"
        onAddClick={() => navigate(ROUTES.FINANCE_SUPPLIER_PAYMENTS_CREATE)}
        dataRequest={supplierPaymentsList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row:any) =>
          <SupplierPaymentRowMenu
            item={row}
            onDelete={handleDelete}
            onChangeStatus={changeStatus}
          />
        }
      />
    </AppLayout>
  )
}

export default SupplierPaymentsListContainer
