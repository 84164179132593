import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { useNavigate } from 'react-router'
import { usePut } from 'hooks/usePut'
import { usePost } from 'hooks/usePost'
import { pathOr, propOr } from 'ramda'

import {
  approvePurchase,
  processPurchase,
  deletePurchase,
  deliverPurchase,
  getPurchaseList,
  rejectPurchase, getPurchaseOrderExcel
} from '../api'
import PurchaseRowMenu from '../components/PurchaseRowMenu'
import PurchaseStatusRow from '../components/PurchaseStatusRow'
import MoreActionButtons from '../components/MoreRowMenu'

const columns = [
  {
    headerName: 'Название поставщик',
    field: 'name',
    width: 200,
    renderCell: (item: any) => pathOr('', ['contact', 'name'], item)
  },
  {
    headerName: 'Номер заказа',
    field: 'poNumber'
  },
  {
    headerName: 'Дата создания',
    field: 'createdDate'
  },
  {
    headerName: 'Дата последнего изменения',
    field: 'updatedDate'
  },
  {
    headerName: 'Привязанный номер',
    field: 'referenceNumber'
  },
  {
    headerName: 'Статус',
    field: 'orderStatus',
    renderCell: (item: any) => <PurchaseStatusRow status={item.orderStatus} />
  }
]

function PurchaseOrderListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const purchaseList = useGetList(getPurchaseList)
  const purchaseDelete = useDelete(deletePurchase)
  const purchaseApprove = usePut(approvePurchase)
  const purchaseReject = usePut(rejectPurchase)
  const purchaseDeliver = usePut(deliverPurchase)
  const purchaseProcess = usePost(processPurchase)
  const purchaseExcel = usePost(getPurchaseOrderExcel)

  const handleApprovePurchase = useCallback((orderId: number) => {
    return purchaseApprove.putData({ params: { orderId } })
      .then(() => snackbar({ message: 'Успешно обновлён' }))
      .then(() => purchaseList.getList())
  }, [])

  const handleRejectPurchase = useCallback((orderId: number) => {
    return purchaseReject.putData({ params: { orderId } })
      .then(() => snackbar({ message: 'Успешно обновлён' }))
      .then(() => purchaseList.getList())
  }, [])

  const handleDeliverPurchase = useCallback((orderId: number) => {
    return purchaseDeliver.putData({ params: { orderId } })
      .then(() => snackbar({ message: 'Успешно обновлён' }))
      .then(() => purchaseList.getList())
  }, [])

  const handleProcessPurchase = useCallback((orderId: number) => {
    return purchaseProcess.postData({ params: { orderId } })
      .then(() => snackbar({ message: 'Успешно создан' }))
      .then(() => purchaseList.getList())
  }, [])

  const handleDeleteSupplier = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить закупку ${values.poNumber}`

    onConfirm({ message })
      .agree(() => {
        purchaseDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Успешно удалён' }))
          .then(() => purchaseList.getList())
      })
      .disagree()
  }, [])

  const onExport = useCallback(() => {
    purchaseExcel.postData({ responseType: 'blob' })
      .then((response:any) => {
        // Convert response to blob
        const blob = new Blob([response])

        // Create a download link
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', `Purchase-order.xlsx`)
        document.body.appendChild(link)
        link.click()

        // Cleanup
        window.URL.revokeObjectURL(link.href)
      })
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = propOr('', 'search', value)
    purchaseList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Закупки"
        onAddClick={() => navigate(ROUTES.FINANCE_PURCHASE_CREATE)}
        dataRequest={purchaseList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row) => (
          <PurchaseRowMenu
            item={row}
            onDelete={handleDeleteSupplier}
            onApprove={handleApprovePurchase}
            onReject={handleRejectPurchase}
            onDeliver={handleDeliverPurchase}
            onProcess={handleProcessPurchase}
          />
        )}
        moreActions={() => <MoreActionButtons onExport={onExport} />}
      />
    </AppLayout>
  )
}

export default PurchaseOrderListContainer
