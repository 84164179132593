export const SignIn = 'auth/public/api/v1/signin'
export const AuthRoles = '/auth/api/v1/list'

export const PublicSignUp = '/public/api/v1/sign-up'
export const UserCompanies = '/public/api/v1/user-companies/:login'

export const FileUpload = '/product/api/v1/attachment/public-upload'
export const DeleteFileUpload = '/product/api/v1/attachment/:id'
export const ProductFileAttach = '/product/api/v1/product-image/create'
export const ProductFileDelete = '/product/api/v1/product-image/:id'
export const ProductImagePrimary = '/product/api/v1/product-image/primary/:imageId'
export const AttachmentUpload = '/product/api/v1/attachment/upload'
export const AttachmentDelete = '/product/api/v1/attachment/:id'
export const CustomFieldCreate = '/api/v1/custom-field/create'
export const CustomFieldUpdate = '/api/v1/custom-field/update'
export const CustomFieldDelete = '/api/v1/custom-field/:id'
export const CustomFieldsGetList = '/api/v1/custom-field/list'
export const CustomFieldsById = '/api/v1/custom-field/:id'
export const CustomFieldsGetByFormType = '/api/v1/custom-field/by-form-type/:formType'
export const CustomFieldDeleteBulk = '/api/v1/custom-field/delete-by-ids'

// Backend
export const CompanyList = 'api/v1/companies/list'
export const CompanyCreate = 'api/v1/companies'
export const CompanyUpdate = 'api/v1/companies'
export const CompanyGetById = 'api/v1/companies/:publicId'
export const CompanyDelete = 'api/v1/companies/:publicId'
export const CompanyBillingSet = 'api/v1/companies/set-billing-plan'
export const CompanyBillingHistory = 'api/v1/companies/billing-plan/history-list'

export const NewsList = 'api/v1/news/list'
export const NewsCreate = 'api/v1/news/create'
export const NewsUpdate = 'api/v1/news/update'
export const NewsGetById = 'api/v1/news/:id'
export const NewsDelete = 'api/v1/news/:id'
export const NewsDeleteByIds = 'api/v1/news/delete-by-ids'

export const TimeSlotList = 'api/v1/time-slot/list'
export const TimeSlotCreate = '/api/v1/time-slot/create'
export const TimeSlotUpdate = '/api/v1/time-slot/update'
export const TimeSlotGetById = '/api/v1/time-slot/:id'
export const TimeSlotDelete = '/api/v1/time-slot/:id'

export const BillingPlanList = 'api/v1/billing-plan/list'
export const BillingPlanCreate = 'api/v1/billing-plan/create'
export const BillingPlanUpdate = 'api/v1/billing-plan/update'
export const BillingPlanDetail = 'api/v1/billing-plan/:id'
export const BillingPlanDelete = 'api/v1/billing-plan/:id'
export const BillingPlanSuggestions = 'api/v1/billing-plan/suggestion'
export const BillingPlanDeleteByIds = 'api/v1/billing-plan/delete-by-ids'

export const BillingPlanCategoryList = 'api/v1/billing-plan-category/list'
export const BillingPlanCategoryCreate = 'api/v1/billing-plan-category/create'
export const BillingPlanCategoryUpdate = 'api/v1/billing-plan-category/update'
export const BillingPlanCategoryDetail = 'api/v1/billing-plan-category/:id'
export const BillingPlanCategoryDelete = 'api/v1/billing-plan-category/:id'
export const BillingPlanCategorySuggestions = 'api/v1/billing-plan-category/suggestion'
export const BillingPlanCategoryDeleteByIds = 'api/v1/billing-plan-category/delete-by-ids'

export const WarehouseList = '/warehouse/api/v1/warehouses/list'
export const WarehouseDetail = '/warehouse/api/v1/warehouses/:id'
export const WarehouseCreate = '/warehouse/api/v1/warehouses'
export const WarehouseUpdate = '/warehouse/api/v1/warehouses'
export const WarehouseDelete = '/warehouse/api/v1/warehouses/:id'
export const WarehouseSuggestions = '/warehouse/api/v1/warehouses/lookup'
export const WarehouseStatus = '/warehouse/api/v1/warehouses/:id'
export const WarehouseCount = '/warehouse/api/v1/warehouses/list-count'
export const WarehouseAddAssignee = '/warehouse/api/v1/warehouses/add-assignees'
export const WarehouseImport = '/warehouse/api/v1/import/excel/warehouse'

export const WarehouseGroupList = '/warehouse/api/v1/groups/list'
export const WarehouseGroupDetail = '/warehouse/api/v1/groups/get/:id'
export const WarehouseGroupCreate = '/warehouse/api/v1/groups/create'
export const WarehouseGroupUpdate = '/warehouse/api/v1/groups/update'
export const WarehouseGroupDelete = '/warehouse/api/v1/groups/delete/:id'
export const WarehouseGroupSuggestions = '/warehouse/api/v1/groups/suggestion'

export const ShipmentList = '/so/api/v1/shipment/list'
export const ShipmentDetail = '/so/api/v1/shipment/:id'
export const ShipmentDelete = '/so/api/v1/shipment/delete/:id'
export const ShipmentProcess = '/so/api/v1/shipment/shipping'
export const ShipmentComplete = '/so/api/v1/shipment/complete/:id'
export const ShipmentSetPicker = '/so/api/v1/shipment/set-picker'
export const ShipmentSetPacker = '/so/api/v1/shipment/set-packer'
export const ShipmentSetStatus = '/so/api/v1/shipment/change-status'
export const ShipmentItemSetStatus = '/so/api/v1/shipment/item/change-status'
export const ShipmentDeliverStatus = '/so/api/v1/shipment/deliver'
export const ShipmentBulkProcess = '/so/api/v1/shipment/bulk-shipping'
export const ShipmentBulkComplete = '/so/api/v1/shipment/bulk-complete'
export const ShipmentAttachmentUpdate = '/so/api/v1/shipment/:id/update-attachment'
export const ShipmentAttachmentRemove = '/so/api/v1/shipment/:id/remove-attachment/:attachmentId'
export const ShipmentFilterCountData = '/so/api/v1/shipment/listing-filter/data'
export const ShipmentTabsCounts = '/so/api/v1/shipment/listing-tab/records'
export const ShipmentFilterPreview = '/so/api/v1/shipment/filter-preview'
export const ShipmentDriverAssign = '/so/api/v1/shipment/driver-assign'
export const ShipmentCollect = '/so/api/v1/shipment/collected/:id'
export const ShipmentReturned = '/so/api/v1/shipment/returned/:id'

export const AdjustmentList = '/stock/api/v1/adjustment/list'
export const AdjustmentDetail = '/stock/api/v1/adjustment/:id'
export const AdjustmentCreate = '/stock/api/v1/adjustment'
export const AdjustmentUpdate = '/stock/api/v1/adjustment/update'
export const AdjustmentDelete = '/stock/api/v1/adjustment/:id'

export const SaleOrderList = '/so/api/v1/sales-order/list'
export const SaleOrderListingCount = '/so/api/v1/sales-order/listing-filter/data'
export const SaleOrderDetail = '/so/api/v1/sales-order/:id'
export const SaleOrderCreate = '/so/api/v1/sales-order/create'
export const SaleOrderUpdate = '/so/api/v1/sales-order/update'
export const SaleOrderDelete = '/so/api/v1/sales-order/:id'
export const SaleOrderBook = '/so/api/v1/sales-order/book'
export const SaleOrderComplete = '/so/api/v1/sales-order/complete/:id'
export const SaleOrderInProcess = '/so/api/v1/sales-order/approve-process'
export const SaleOrderReturn = '/so/api/v1/sales-order/returned/:id'
export const SalesOrderSuggestions = '/so/api/v1/sales-order/suggestions'
export const SalesOrderCheckBonus = '/so/api/v1/sales-order/checking-bonus'
export const SalesOrderFileUpload = '/so/api/v1/attachment/public-upload'
export const SalesOrderFileDelete = '/so/api/v1/attachment/:id'
export const SalesOrderProductByOrderId = '/so/api/v1/sales-order/:orderId/list-product'
export const SalesOrderListingCounts = '/so/api/v1/sales-order/listing-tab/records'
export const SalesOrderFilterPreview = '/so/api/v1/sales-order/filter-preview'

export const SalesOrderReturnCreate = '/so/api/v1/sales-return/create'
export const SalesOrderReturnUpdate = '/so/api/v1/sales-return/update'
export const SalesOrderReturnStatus = '/so/api/v1/sales-return/change/status'
export const SalesOrderReturnGetById = '/so/api/v1/sales-return/:id'
export const SalesOrderReturnDelete = '/so/api/v1/sales-return/:id'
export const SalesOrderReturnList = '/so/api/v1/sales-return/list'

export const RentList = '/so/api/v1/rent/list'
export const RentCreate = '/so/api/v1/rent/create'
export const RentById = '/so/api/v1/rent/:id'
export const RentUpdate = '/so/api/v1/rent/:id'
export const RentDelete = '/so/api/v1/rent/:id'
export const RentChangeStatus = '/so/api/v1/rent/change/status'
export const RentListSuggestions = '/so/api/v1/rent/suggestions'

export const ReceiveList = '/receive/api/v1/receive/list'
export const ReceiveDetail = '/receive/api/v1/receive/get/:receiveId'
export const ReceiveCreate = '/receive-proxy/api/v1/receive/create'
export const ReceiveUpdate = '/receive-proxy/api/v1/receive/update'
export const ReceiveDelete = '/receive-proxy/api/v1/receive/:id'
export const ReceiveComplete = '/receive-proxy/api/v1/receive/completed/:id'
export const ReceiveListByPOId = '/receive/api/v1/receive/purchase-order/:id'

export const SupplierSuggestions = '/contact/api/v1/contact/suggestions'
export const SupplierGetById = '/contact/api/v1/contact/:id'

export const StockList = '/stock/api/v1/stock/list'
export const StockListReport = '/stock/api/v1/stock/stock-report'
export const StockExcel = '/stock/api/v1/export-excel/stock/list'
export const StockAvailable = '/stock/api/v1/stock/list/available-stocks'

export const StockHistoryList = '/stock/api/v1/stock/history'
export const StockHistoryListExcel = '/stock/api/v1/export-excel/stock-history'

export const ProductDetailList = '/product/api/v1/product/detail-suggestions'
export const ProductList = '/product/api/v1/product/list'
export const ProductGetById = '/product/api/v1/product/:id'
export const ProductCreate = '/product/api/v1/product/create'
export const ProductUpdate = '/product/api/v1/product/update'
export const ProductDelete = '/product/api/v1/product/:id'
export const ProductBulkUpdate = '/product/api/v1/product/bulk-update/fields'
export const ProductBulkCategory = '/product/api/v1/product/assign-categories'
export const ProductCategorySuggestions = '/product/api/v1/product-category/suggestions'
export const ProductChangeStatusBulk = '/product/api/v1/product/update-status/products'
export const ProductChangeStatus = '/product/api/v1/product/update-status/:id'
export const ProductStockHistory = '/stock/api/v1/stock/history'
export const ProductDeleteByIds = '/product/api/v1/product/delete-by-ids'
export const ProductCount = '/product/api/v1/product/list-count'
export const ProductListingFilterData = '/product/api/v1/product/listing-filter/data'
export const ProductExportExcel = '/product/api/v1/excel/products'
export const ProductImportExcel = '/product/api/v1/import/excel/product'
export const ProductCheck = '/product/api/v1/product/check'
export const ProductByCategoryIds = '/product/api/v1/product/get-by-categoryIds'
export const ProductListingTabRecords = '/product/api/v1/product/listing-tab/records'
export const ProductFilterPreview = '/product/api/v1/product/filter-preview'
export const AssignProductsToCategory = '/product/api/v1/product/assign-categories'
export const ProductSuggestions = '/product/api/v1/product/suggestions'

export const CategoryList = '/product/api/v1/product-category/list'
export const CategoryDetail = '/product/api/v1/product-category/:id'
export const CategoryCreate = '/product/api/v1/product-category/create'
export const CategoryUpdate = '/product/api/v1/product-category/update'
export const CategoryDelete = '/product/api/v1/product-category/delete/:id'
export const CategoryDeleteByIds = '/product/api/v1/product-category/delete-by-ids'
export const CategorySuggestionList = '/product/api/v1/product-category/suggestions'

export const BrandList = '/product/api/v1/product-brand/list'
export const BrandDetail = '/product/api/v1/product-brand/:id'
export const BrandCreate = '/product/api/v1/product-brand/create'
export const BrandUpdate = '/product/api/v1/product-brand/update'
export const BrandDelete = '/product/api/v1/product-brand/delete/:id'
export const BrandDeleteByIds = '/product/api/v1/product-brand/delete-by-ids'
export const BrandSuggestionList = '/product/api/v1/product-brand/suggestion'
export const BrandUpdateStatus = '/product/api/v1/product-brand/update-status/:brandId'
export const BrandUpdateStatusBulk = '/product/api/v1/product-brand/batch/update-status'
export const BrandImport = '/product/api/v1/import/excel/product_brand'

export const PriceLevelList = '/product/api/v1/price_level/list'
export const PriceLevelSuggestionsList = '/product/api/v1/price_level/suggestions'
export const PriceLevelListByProductId = '/product/api/v1/price_level/by_product'
export const PriceLevelCreate = '/product/api/v1/price_level/create'
export const PriceLevelDelete = '/product/api/v1/price_level/delete/:id'
export const PriceLevelUpdate = '/product/api/v1/price_level/update'
export const PriceLevelGetById = '/product/api/v1/price_level/:id'
export const PriceLevelLineItemsList = '/product/api/v1/price_level/by_price'
export const PriceLevelGetByTerritoryList = '/product/api/v1/price_level/by_territory'
export const PriceLevelLineItemsEdit = '/product/api/v1/price_level/patch'
export const PriceLevelByProductId = '/product/api/v1/price_level/products/:id'
export const PriceLevelImportExcel = '/product/api/v1/import/excel/update-product-prices/:priceId'
export const PriceLevelExportProducts = '/product/api/v1/excel/products-by-price-level'

export const VisitCreate = '/task/api/v1/task/create'
export const VisitUpdate = '/task/api/v1/task/update'
export const VisitList = '/task/api/v1/task/list'
export const VisitSuggestions = '/task/api/v1/task/suggestions'
export const VisitGetById = '/task/api/v1/task/:id'
export const VisitDelete = '/task/api/v1/task/:id'
export const VisitStatus = '/task/api/v1/task/change-status'
export const VisitUpload = '/task/public-upload'
export const VisitUploadDelete = '/task/:id'
export const VisitCommentsSuggestions = '/task/api/v1/task/suggestions-comments'
export const VisitListingCounts = '/task/api/v1/task/listing-tab/records'
export const VisitListByUserId = '/task/api/v1/task/list-by-user/:userId'
export const VisitListByUsersIds = '/task/api/v1/task/list-by-users'
export const VisitGetListCount = '/task/api/v1/task/listing-filter/data'
export const VisitVideoUpload = '/task/api/v1/task/action/upload-video'
export const VisitVideoURemove = '/task/api/v1/task/action/remove-video'
export const VisitPhotoUpload = '/task/api/v1/task/action/upload-photo'
export const VisitPhotoRemove = '/task/api/v1/task/action/remove-photo'

export const TaskReturnList = '/task/api/v1/recurring-task/list'
export const TaskReturnCreate = '/task/api/v1/recurring-task/create'
export const TaskReturnUpdate = '/task/api/v1/recurring-task/update'
export const TaskReturnDelete = '/task/api/v1/recurring-task/:id'
export const TaskReturnGetById = '/task/api/v1/recurring-task/:id'

export const TaskTypeList = '/task/api/v1/task-type/list'
export const TaskTypeCreate = '/task/api/v1/task-type/create'
export const TaskTypeUpdate = '/task/api/v1/task-type/update'
export const TaskTypeSuggestions = '/task/api/v1/task-type/suggestions'
export const TaskTypeGetById = '/task/api/v1/task-type/:id'
export const TaskTypeDelete = '/task/api/v1/task-type/:id'
export const TaskStatusSuggestions = '/task/api/v1/task/status-list'

export const TaskCommentsList = '/task/api/v1/comment/list'
export const TaskCommentsCreate = '/task/api/v1/comment/create'
export const TaskCommentsUpdate = '/task/api/v1/comment/update'
export const TaskCommentsSuggestions = '/task/api/v1/comment/suggestions'
export const TaskCommentsDetail = '/task/api/v1/comment/:id'

export const PurchaseList = '/po/api/v1/purchase-order/list'
export const PurchaseDetail = '/po/api/v1/purchase-order/:id'
export const PurchaseCreate = '/po/api/v1/purchase-order/create'
export const PurchaseUpdate = '/po/api/v1/purchase-order/update'
export const PurchaseDelete = '/po/api/v1/purchase-order/:id'
export const PurchaseApprove = '/po/api/v1/purchase-order/approved/:orderId'
export const PurchaseRejected = '/po/api/v1/purchase-order/rejected/:orderId'
export const PurchaseDelivered = '/po/api/v1/purchase-order/delivered/:orderId'
export const PurchaseProcess = '/po/api/v1/purchase-order/process-receive/:orderId'
export const PurchaseOrderSuggestions = '/po/api/v1/purchase-order/suggestions'
export const PurchaseOrderExcel = '/po/api/v1/export-excel/po/list'

export const ManualEntryList = '/transaction/api/v1/manual-entry/list'
export const ManualEntryDetail = '/transaction/api/v1/manual-entry/:id'
export const ManualEntryCreate = '/transaction/api/v1/manual-entry/create'
export const ManualEntryUpdate = '/transaction/api/v1/manual-entry/update'
export const ManualEntryApprove = '/transaction/api/v1/manual-entry/approve/:id'
export const ManualEntryDelete = '/transaction/api/v1/manual-entry/:id'
export const ManualEntryImport = '/transaction/api/v1/import/excel/manualEntry'

export const TransactionList = '/transaction/api/v1/transaction/item/list'
export const ExportTransactions = '/transaction/api/v1/excel/transactions'

export const ChartOfAccountList = '/transaction/api/v1/chart-of-account/list'
export const ChartOfAccountCreate = '/transaction/api/v1/chart-of-account/save'
export const ChartOfAccountSuggestion = '/transaction/api/v1/chart-of-account/suggestions'
export const ChartOfAcccountExcel = '/transaction/api/v1/excel/chart-of-transactions'

export const SupplierPaymentsList = '/transaction/api/v1/supplier-payment/list'
export const SupplierPaymentCreate = '/transaction/api/v1/supplier-payment/create'
export const SupplierPaymentUpdate = '/transaction/api/v1/supplier-payment/update'
export const SupplierPaymentDelete = '/transaction/api/v1/supplier-payment/delete/:paymentId'
export const SupplierPaymentGetDetail = '/transaction/api/v1/supplier-payment/:id'
export const SupplierPaymentChangeStatus = '/transaction/api/v1/supplier-payment/change/status'

export const TransferList = '/stock/api/v1/transfer/list'
export const TransferGetById = '/stock/api/v1/transfer/get/:id'
export const TransferCreate = '/stock/api/v1/transfer/create'
export const TransferUpdate = '/stock/api/v1/transfer/update'
export const TransferUpdateStatus = '/stock/api/v1/transfer/update/:id'
export const TransferDelete = '/stock/api/v1/transfer/:id'

export const EmployeeSuggestionList = '/api/v1/users/suggestions'
export const EmployeeList = '/api/v1/users'
export const EmployeeDetail = '/api/v1/users/:id'
export const EmployeeCreate = '/api/v1/users'
export const EmployeeUpdate = '/api/v1/users'
export const EmployeeDelete = '/api/v1/users/:id'
export const EmployeeCount = '/api/v1/users/count'
export const EmployeeStatus = '/api/v1/users/update-status/:userId'
export const EmployeeGetWithAuthId = '/api/v1/users/auth/:id/:companyId'
export const EmployeesTracking = '/api/v1/users/list/for-tracking'
export const EmployeeCurrentGetDetail = '/api/v1/users/current-user/detail'

export const UserFileUpload = '/api/v1/attachment/public-upload'
export const RemoveUserAttachment = '/api/v1/attachment/:id'
export const GetDetailUserAttachment = '/api/v1/attachment/:id'
export const PaymentList = '/so/api/v1/payment/list'
export const PaymentDetail = '/so/api/v1/payment/:id'
export const PaymentCreate = '/so/api/v1/payment/create'
export const PaymentUpdate = '/so/api/v1/payment'
export const PaymentDelete = '/so/api/v1/payment/delete/:id'
export const PaymentListExcel = '/so/api/v1/export-excel/payment/list'
export const PaymentReceiverList = '/transaction/api/v1/chart-of-account/suggestions'
export const PaymentChangeStatus = '/so/api/v1/payment/change/status'
export const PaymentSuggestionsList = '/so/api/v1/payment/shipment/suggestion'

export const PaymentBulkCreate = '/so/api/v1/receive-payment/create'
export const PaymentBulkGetById = '/so/api/v1/receive-payment/:id'
export const PaymentBulkList = '/so/api/v1/receive-payment/list'
export const PaymentBulkDelete = '/so/api/v1/receive-payment/delete/:id'

export const PickList = '/so/api/v1/picklist/list'
export const PickDetail = '/so/api/v1/picklist/:id'
export const PickChangeStatus = '/so/api/v1/picklist/item/change-status'
export const PicklistStatus = '/so/api/v1/picklist/set-picker'
export const PicklistMerge = '/so/api/v1/picklist/merge'
export const PicklistExportPDF = '/so/api/v1/pdf/picklist-details/:id'

export const PreOrderCreate = '/so/api/v1/preorder/create'
export const PreOrderUpdate = '/so/api/v1/preorder/update'
export const PreOrderDetail = '/so/api/v1/preorder/:id'
export const PreOrderSuggestions = '/so/api/v1/preorder/suggestions'
export const PreOrderList = '/so/api/v1/preorder/list'
export const PreOrderDelete = '/so/api/v1/preorder/:id'
export const PreOrderChangeStatus = '/so/api/v1/preorder/change/status'
export const PreOrderConvertOrder = '/so/api/v1/preorder/convert-to-order'

export const ShippingAddressList = '/so/api/v1/address/list'
export const ShippingAddressCreate = '/so/api/v1/address/create'

export const TaxList = '/so/api/v1/tax/list'
export const TaxCreate = '/so/api/v1/tax/create'

export const TerritoriesList = '/territory/api/v1/territory/list'
export const TerritoryCreate = '/territory/api/v1/territory/create'
export const TerritoryUpdate = '/territory/api/v1/territory/update'
export const TerritoryDelete = '/territory/api/v1/territory/:id'
export const TerritoryGetDetail = '/territory/api/v1/territory/:id'
export const TerritorySuggestions = '/territory/api/v1/territory/suggestions'
export const TerritoryGetUserList = '/territory/api/v1/territory/user-territories/:companyId/:userId'
export const TerritoryExcelImport = '/territory/api/v1/import/excel/territory'
export const TerritoryDefaulCompany = '/territory/api/v1/territory/default'

export const TerritoryImportByProduct = '/product/api/v1/import/excel/user-territory-fields'
export const TerritoryPriceLevelSave = '/product/api/v1/price_level/set_territories'
export const TerritoryContactSave = '/contact/api/v1/contact/set_territories'
export const TerritoryCategorySave = '/product/api/v1/product-category/territory_for_category'
export const TerritoryPriceLevelList = '/product/api/v1/price_level/by_territory'
export const TerritoryContactList = '/contact/api/v1/contact/territory/:id'
export const TerritoryCategoryList = '/product/api/v1/product-category/list-by-territory'

export const ContactList = '/contact/api/v1/contact/list'
export const ContactCreate = '/contact/api/v1/contact/create'
export const ContactGetById = '/contact/api/v1/contact/:id'
export const ContactUpdate = '/contact/api/v1/contact/update'
export const ContactDelete = '/contact/api/v1/contact/archive/:id'
export const ContactSuggestions = '/contact/api/v1/contact/suggestions'
export const ContactGetByTerritory = '/contact/api/v1/contact/territory/:id'
export const ContactGetAddressByContactId = '/contact/api/v1/contact/addresses/:contactId'
export const ContactListTracking = '/contact/api/v1/contact/list-for-tracking'
export const ValidateContactAntexID = '/contact/api/v1/contact/validate-antex-id'
export const ContactFilterPreview = '/contact/api/v1/contact/filter-preview'
export const ContactsGetByIds = '/contact/api/v1/contact/get-by-ids'
export const ContactDefaultImage = '/contact/api/v1/contact/primary/:contactId/:imageId'
export const ContactValidateINN = '/contact/api/v1/contact/validate-inn'
export const ContactGetFullExport = '/contact/api/v1/export-excel/contact/list'
export const ContactOrganizationByINN = '/contact/api/v1/organization/info/:inn'
export const ContactGetByAntexId = '/contact/api/v1/contact/info-by-antex/:antexId'

export const SharedContactList = '/contact/api/v1/shared-contact/list'
export const SharedContactCreate = '/contact/api/v1/shared-contact/create'
export const SharedContactGetById = '/contact/api/v1/shared-contact/:id'
export const SharedContactUpdate = '/contact/api/v1/shared-contact/update'
export const SharedContactDelete = '/contact/api/v1/shared-contact/archive/:id'
export const SharedContactSuggestions = '/contact/api/v1/shared-contact/suggestions'
export const SharedContactGetByTerritory = '/contact/api/v1/shared-contact/territory/:id'
export const SharedContactGetAddressByContactId = '/contact/api/v1/contact/addresses/:contactId'
export const SharedContactListTracking = '/contact/api/v1/contact/list-for-tracking'
export const SharedValidateContactAntexID = '/contact/api/v1/contact/validate-antex-id'
export const SharedContactFilterPreview = '/contact/api/v1/contact/filter-preview'
export const SharedContactsGetByIds = '/contact/api/v1/contact/get-by-ids'
export const SharedContactDefaultImage = '/contact/api/v1/contact/primary/:contactId/:imageId'
export const SharedAttachmentPublicUpload = '/contact/api/v1/attachment/public-upload'
export const SharedRemoveAttachment = '/contact/api/v1/attachment/:id'

export const ContactCategoryList = '/contact/api/v1/contact-category/list'
export const ContactCategoryCreate = '/contact/api/v1/contact-category/create'
export const ContactCategoryUpdate = '/contact/api/v1/contact-category/update'
export const ContactCategoryGetById = '/contact/api/v1/contact-category/:id'
export const ContactCategoryDelete = '/contact/api/v1/contact-category/delete/:id'
export const ContactCategorySuggestions = '/contact/api/v1/contact-category/suggestions'
export const ContactListingTabRecords = '/contact/api/v1/contact/listing-tab/records'

export const SharedContactCategoryList = '/contact/api/v1/shared-contact-category/list'
export const SharedContactCategoryCreate = '/contact/api/v1/shared-contact-category/create'
export const SharedContactCategoryUpdate = '/contact/api/v1/shared-contact-category/update'
export const SharedContactCategoryGetById = '/contact/api/v1/shared-contact-category/:id'
export const SharedContactCategoryDelete = '/contact/api/v1/shared-contact-category/delete/:id'
export const SharedContactCategorySuggestions = '/contact/api/v1/shared-contact-category/suggestions'
export const SharedContactPublicDetail = '/contact/api/v1/shared-contact/info/by-antex/:id'

export const UnitMeasurementList = '/api/v1/unit-measurement/list'
export const UnitMeasurementCreate = '/api/v1/unit-measurement/create'
export const UnitMeasurementGetById = '/api/v1/unit-measurement/:id'
export const UnitMeasurementUpdate = '/api/v1/unit-measurement/update'
export const UnitMeasurementDelete = '/api/v1/unit-measurement/:id'
export const UnitMeasurementSuggestions = '/api/v1/unit-measurement/suggestions'
export const UnitMeasurementUpdateStatus = '/api/v1/unit-measurement/update-status/:unitMeasurementId'
export const UnitMeasurementUpdateStatusBulk = '/api/v1/unit-measurement/batch/update-status'
export const UnitMeasurementImport = '/api/v1/import/excel/unit_measurement'

export const PackageList = '/product/api/v1/package/list'
export const PackageCreate = '/product/api/v1/package/create'
export const PackageGetDetail = '/product/api/v1/package/:id'
export const PackageUpdate = '/product/api/v1/package/update'
export const PackageDelete = '/product/api/v1/package/:id'
export const PackageDeleteByIds = '/product/api/v1/package/delete-by-ids'
export const PackageSuggestions = '/product/api/v1/package/suggestions'

export const CountryList = '/api/v1/country/list'
export const CountryListSuggestion = '/api/v1/country/suggestion'
export const CountryGetDetail = '/api/v1/country/:id'
export const CountryCreate = '/api/v1/country/create'
export const CountryDeleteBulk = '/api/v1/country/delete-by-ids'
export const CountryUpdateStatusBulk = '/api/v1/country/batch/update-status'
export const RegionField = '/api/v1/country/region/list'

export const AttachmentPublicUpload = '/contact/api/v1/attachment/public-upload'
export const RemoveAttachment = '/contact/api/v1/attachment/:id'
export const ImportExcelContact = '/contact/api/v1/import/excel/contact'

export const CurrencyCreate = '/api/v1/currency/create'
export const CurrencyUpdate = '/api/v1/currency/rename'
export const CurrencyList = '/api/v1/currency/list'
export const CurrencySuggestions = '/api/v1/currency/suggestion'
export const CurrencyGetById = '/api/v1/currency/:id'
export const CurrencyGetByCode = '/api/v1/currency/by-code/:code'

export const DiscountSuggestions = '/api/v1/discount/suggestions'
export const DiscountList = '/api/v1/discount/list'
export const DiscountCreate = '/api/v1/discount/create'
export const DiscountUpdate = '/api/v1/discount/update'
export const DiscountGetById = '/api/v1/discount/:id'
export const DiscountDelete = '/api/v1/discount/:id'
export const DiscountGetByTerritoryIds = '/api/v1/discount/get_by_territory_ids'
export const DiscountGetByNames = '/api/v1/discount/get-by-names'
export const DiscountGetByIds = '/api/v1/discount/get-by-ids'
export const DiscountSetTerritories = '/api/v1/discount/set_territories'

export const ListingFilterCreate = '/api/v1/listing-filter/create'
export const ListingFilterUpdate = '/api/v1/listing-filter/update'
export const ListingFilterDelete = '/api/v1/listing-filter/:id'
export const ListingFilterGetById = '/api/v1/listing-filter/:id'
export const ListingFilterList = '/api/v1/listing-filter/list'
export const ListingFilterGetByType = '/api/v1/listing/available-columns/:type'
export const ListingFilterDeleteByIds = '/api/v1/listing-filter/delete-by-ids'
export const ColumnsGetByType = '/api/v1/short-listing-column-config/:type'
export const ApproveProcessCreate = '/api/v1/approval-process/create'

export const BonusListing = '/api/v1/bonus/list'
export const BonusCreate = '/api/v1/bonus/create'
export const BonusUpdate = '/api/v1/bonus/update'
export const GetBonusById = '/api/v1/bonus/:id'
export const BonusDelete = '/api/v1/bonus/:id'
export const BonusAvailableFields = '/api/v1/bonus/available-fields/:type'
export const BonusDeleteBulk = '/api/v1/bonus/delete-by-ids'

export const FolderCreate = '/document/api/v1/folder/create'
export const FolderUpdate = '/document/api/v1/folder/update'
export const FolderGetByIds = '/document/api/v1/folder/get-by-ids'
export const FolderDetail = '/document/api/v1/folder/:id'
export const FolderDelete = '/document/api/v1/folder/:id'
export const FolderList = '/document/api/v1/folder/list'

export const DocumentCreate = '/document/api/v1/document/create'
export const DocumentUpdate = '/document/api/v1/document/update'
export const DocumentGetByIds = '/document/api/v1/document/get-by-ids'
export const DocumentDetail = '/document/api/v1/document/:id'
export const DocumentStorageInfo = '/document/api/v1/document/storage-info'
export const DocumentDelete = '/document/api/v1/document/:id'
export const DocumentList = '/document/api/v1/document/list'

export const DocumentAttachmentUpload = '/document/api/v1/attachment/upload'
export const DocumentAttachmentPublicUpload = '/document/api/v1/attachment/public-upload'
export const DocumentAttachmentDetail = '/document/api/v1/attachment/:id'
export const DocumentAttachmentDelete = '/document/api/v1/attachment/:id'
export const DocumentAttachmentList = '/document/api/v1/attachment/list'

export const TransferCashList = '/transaction/api/v1/transfer/list'
export const TransferCashCreate = '/transaction/api/v1/transfer/create'
export const TransferCashUpdate = '/transaction/api/v1/transfer/update'
export const TransferCashDelete = '/transaction/api/v1/transfer/delete/:id'
export const TransferCashDetail = '/transaction/api/v1/transfer/:id'
export const TransferCashUpdateStatus = '/transaction/api/v1/transfer/change/status'

export const ColumnsList = '/api/v1/listing-column/list'
export const ColumnsCreate = '/api/v1/listing-column/create'
export const ColumnsUpdate = '/api/v1/listing-column/update'
export const ColumnsGetById = '/api/v1/listing-column/:id'
export const ColumnsDelete = '/api/v1/listing-column/:id'
export const ColumnsDeleteByIds = '/api/v1/listing-column/delete-by-ids'
export const ColumnsSetDefault = '/api/v1/listing-column/set-default/:id'

export const ListingTabList = '/api/v1/listing-tab/list'
export const ListingTabCreate = '/api/v1/listing-tab/create'
export const ListingTabUpdate = '/api/v1/listing-tab/update'
export const ListingTabGetById = '/api/v1/listing-tab/:id'
export const ListingTabDelete = '/api/v1/listing-tab/:id'
export const ListingTabDeleteByIds = '/api/v1/listing-tab/delete-by-ids'
export const ListingTabSetDefault = '/api/v1/listing-tab/set-default/:id'

export const SortingList = '/api/v1/listing-sort/list'
export const SortingDetail = '/api/v1/listing-sort/:id'
export const SortingCreate = '/api/v1/listing-sort/create'
export const SortingUpdate = '/api/v1/listing-sort/update'
export const SortingDelete = '/api/v1/listing-sort/:id'
export const SortingSuggestions = '/api/v1/listing-sort/suggestion'
export const SortingSetDefault = '/api/v1/listing-sort/set-default/:id'
export const SortingDeleteBulk = '/api/v1/listing-sort/delete-by-ids'

export const TrackingListByUser = '/tracking/api/v1/tracking/list-by-user'
export const TrackingCreate = '/api/v1/tracking/create'
export const TrackingUpdate = '/api/v1/tracking/update'
export const TrackingDetail = '/api/v1/tracking/:id'
export const TrackingDelete = '/api/v1/tracking/:id'
export const TrackingListByUsers = '/tracking/api/v1/tracking/list-by-users'
export const TrackingListDaily = '/tracking/api/v1/tracking/daily-list-by-users'

export const ConsignmentList = '/transaction/api/v1/consignment/list'
export const ConsignmentCreate = '/transaction/api/v1/consignment/create'
export const ConsignmentUpdate = '/transaction/api/v1/consignment/update'
export const ConsignmentDelete = '/transaction/api/v1/consignment/:id'
export const ConsignmentDetail = '/transaction/api/v1/consignment/:id'
export const ConsignmentItemList = '/transaction/api/v1/consignment/item-list'
export const ConsignmentGetByIds = '/transaction/api/v1/consignment/get-by-ids'

export const NotificationCount = '/api/v1/notification/unread-count'
export const NotificationNewsList = 'api/v1/notification/list'
export const ReadAllNotification = 'api/v1/notification/read-all'
export const ReadNotification = 'api/v1/notification/info-read/:newsId'

export const PermissionModule = '/permission/api/v1/module/list'
export const PermissionModuleAvailableModule = '/permission/api/v1/module/available-by-code/:code'
