import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { prop, propOr, trim } from 'ramda'
import { useNavigate } from 'react-router-dom'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useDelete } from 'hooks/useDelete'

import { deleteDiscount, getDiscountList } from '../api'
import DiscountStatusRow from '../components/DiscountStatusRow'
import DiscountRowMenu from '../components/DiscountRowMenu'

const columns = [
  {
    headerName: 'Название скидки',
    field: 'name',
  },
  {
    headerName: 'Ставки скидки',
    field: 'rate'
  },
  {
    headerName: 'Территории',
    field: 'territories',
    renderCell:(item:any) => item.territories.map((item: any) => prop('name', item)).join(', ')
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell:(item:any) => <DiscountStatusRow status={item.status} />
  },
]

function DiscountListContainer () {
  const snackbar = useSnackbar()
  const onConfirm = useConfirm()
  const navigate = useNavigate()
  const discountList = useGetList(getDiscountList)
  const discountDelete = useDelete(deleteDiscount)

  const onDelete = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить скидку ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        discountDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Скидка успешно удалён' }))
          .then(() => discountList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    discountList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Скидки"
        onAddClick={() => navigate(ROUTES.CATALOG_DISCOUNT_CREATE)}
        dataRequest={discountList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        searchPlaceholder="Поиск по названию или ID"
        actions={(row) => (
          <DiscountRowMenu
            item={row}
            handleDelete={onDelete}
          />
        )}
      />
    </AppLayout>
  )
}

export default DiscountListContainer
