import React from 'react'
import ReactDOM from 'react-dom/client'
import { CssBaseline } from '@mui/material'

import './i18n/i18n'
import App from './App'
import './global.css'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
  <>
    <CssBaseline />
    <App />
  </>
)
