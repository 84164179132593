import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { name: 'КАРТА', value: 'CARD' },
  { name: 'БАНК', value: 'BANK' },
  { name: 'РАСХОД', value: 'EXPENSE' }
]

function ChartOfAccountTypeField (props: any) {
  return (
    <SelectField
      name="type"
      label="Тип плана бухгалтерского учета"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ChartOfAccountTypeField
