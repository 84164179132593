import React from 'react'
import { MenuItem } from '@mui/material'

interface Props {
  readonly onDeleteBulk:() => void
  readonly onUpdateBulkStatus:(status:string) => void
}

function TableActionsRow ({ onDeleteBulk, onUpdateBulkStatus }:Props) {
  return (
    <>
      <MenuItem onClick={onDeleteBulk}>
        Удалить выбранное
      </MenuItem>
      <MenuItem onClick={() => onUpdateBulkStatus('ACTIVE')}>
        Активировать выбранное
      </MenuItem>
      <MenuItem onClick={() => onUpdateBulkStatus('INACTIVE')}>
        Деактивировать выбранное
      </MenuItem>
    </>
  )
}

export default TableActionsRow
