import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'BYPRODUCT', name: 'Скидка по продукту' },
  { value: 'ALL', name: 'Скидка общий' },
]

function DiscountTypeField () {
  return (
    <SelectField
      name="discountType"
      label="Тип скидки"
      options={OPTIONS}
      data-cy="status"
    />
  )
}

export default DiscountTypeField
