import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Skeleton from '@mui/material/Skeleton'

interface Props {
  readonly columnsCount?: number
}

function TableSkeletonList ({ columnsCount = 2 }: Props) {
  return (
    <TableRow sx={{ height: '75px' }}>
      {Array.from({ length: columnsCount }).map(() => (
        <TableCell><Skeleton /></TableCell>
      ))}
    </TableRow>
  )
}

export default TableSkeletonList
