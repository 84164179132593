import * as ROUTES from 'constants/Routes'

import React from 'react'
import DefaultImage from 'assets/pircture-product.svg'
import Box from '@mui/material/Box'
import { pathParams } from 'path-params'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router'

import { PRODUCT_TABS } from './ProductTabs'

const useStyles = makeStyles({
  wrapper:{
    display:'flex',
    alignItems:'center',
    gap:'10px',
    cursor:'pointer'
  }
})

interface Props {
  readonly id:number
  readonly src: string
  readonly name?:string
}

function ListImage ({ id, src, name }: Props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const updatedURL = pathParams(ROUTES.CATALOG_PRODUCT_EDIT, { id, tab: PRODUCT_TABS.GENERAL })
  return (
    <Box
      className={classes.wrapper}
      onClick={() => navigate(updatedURL)}
    >
      <Box sx={{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width: '32px',
        height: '32px',
        background:'#F4F4F4',
        borderRadius:'50%'
      }}
      >
        {src ? <img src={src} alt="image" style={{ width:'100%', height:'100%', borderRadius:'50%' }} />
          : <img src={DefaultImage} alt="image" style={{ borderRadius:'50%' }} />
        }
      </Box>
      {name}
    </Box>
  )
}

export default ListImage
