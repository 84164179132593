import React from 'react'
import { prop } from 'ramda'

import MapCard from './MapCard'

import { getVisitListAddress } from '../serializers'

interface Props {
  readonly listTracking: any
  readonly taskListing:any
  readonly selectedLotLan:any
  readonly setSelectedLotLan:(values:any) => void
  readonly contactListTracking:any
}

const MapContent = ({ listTracking, taskListing, selectedLotLan, setSelectedLotLan, contactListTracking }: Props) => {
  const list = prop('list', listTracking) || []
  const taskListings = prop('list', taskListing)
  const contactTracking = prop('list', contactListTracking)

  const mappedTasks = taskListings && taskListings.map((tasks:any) => {
    return getVisitListAddress(prop('tasks', tasks))
  })

  return (
    <MapCard
      taskListings={mappedTasks}
      trackingData={list}
      contactTracking={contactTracking}
      selectedLotLan={selectedLotLan}
      setSelectedLotLan={setSelectedLotLan}
    />
  )
}

export default MapContent
