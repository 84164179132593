import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value:'DRAFT', name:'ЧЕРНОВИК' },
  { value: 'BOOKED', name:'ЗАБРОНИРОВАНО' },
  { value: 'SUBMITTED', name:'ОТПРАВЛЕНО' },
  { value: 'IN_PROCESS', name:'В ПРОЦЕССЕ' },
  { value: 'REJECTED', name:'ОТКЛОНЕНО' },
  { value: 'RETURNED', name:'ВОЗВРАЩЕНО' },
  { value: 'PARTIAL_COMPLETED', name:'ЧАСТИЧНО ЗАВЕРШЕНО' },
  { value: 'COMPLETED', name:'ЗАВЕРШЕНО' },
  { value:'CANCELLED', name:'ОТМЕНЕНО' }
]

function OrderStatusField (props:any) {
  return (
    <SelectField
      name="status"
      label="Статус"
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default OrderStatusField
