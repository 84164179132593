import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'BOOKED', name: 'ЗАПЛАНИРОВАНО' },
  { value: 'IN_PROCESS', name: 'В ПРОЦЕССЕ' },
  { value: 'COMPLETED', name: 'ЗАВЕРШЕНО' },
]

function PickerStatusField (props:any) {
  return (
    <SelectField
      name="status"
      label="Cтатус"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PickerStatusField
