import { MenuItem } from '@mui/material'
import React from 'react'

interface Props {
  readonly onUpdateStatusBulk:(status:string) => void
}

function TableActionsRow ({ onUpdateStatusBulk }:Props) {
  return (
    <>
      <MenuItem onClick={() => null}>
        Удалить выбранное
      </MenuItem>
      <MenuItem onClick={() => onUpdateStatusBulk('ACTIVE')}>
        Активировать выбранное
      </MenuItem>
      <MenuItem onClick={() => onUpdateStatusBulk('INACTIVE')}>
        Деактивировать выбранное
      </MenuItem>
    </>
  )
}

export default TableActionsRow
