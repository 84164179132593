export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false
  }
  const decoded = parseJwt(accessToken)

  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}
