import React from 'react'
import SelectField from 'components/form/SelectField'

export enum PRODUCT_CUSTOM_FIELD_FORM_TYPE {
  PRODUCT_FORM = 'PRODUCT_FORM',
  PRODUCT_CATEGORY_FORM = 'PRODUCT_CATEGORY_FORM',
  PRODUCT_BRAND_FORM = 'PRODUCT_BRAND_FORM',
  CONTACT_FORM = 'CONTACT_FORM',
  TASK_FORM = 'TASK_FORM',
  MANUAL_ENTRY_FORM = 'MANUAL_ENTRY_FORM',
  STOCK_FORM = 'STOCK_FORM',
  WAREHOUSE_FORM = 'WAREHOUSE_FORM',
  SALES_ORDER_FORM = 'SALES_ORDER_FORM',
  SHIPMENT_FORM = 'SHIPMENT_FORM',
  SHIPMENT_PAYMENT_FORM = 'SHIPMENT_PAYMENT_FORM'
}

const OPTIONS = [
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.PRODUCT_FORM, name: 'Продукт' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.PRODUCT_CATEGORY_FORM, name: 'Продуктовая корзина' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.PRODUCT_BRAND_FORM, name: 'Бренд' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.CONTACT_FORM, name: 'Контрагент' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.TASK_FORM, name: 'Задача' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.MANUAL_ENTRY_FORM, name: 'Корректировка баланса' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.STOCK_FORM, name: 'Запас' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.WAREHOUSE_FORM, name: 'Склад' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.SALES_ORDER_FORM, name: 'Заказ' },
  { value: PRODUCT_CUSTOM_FIELD_FORM_TYPE.SHIPMENT_FORM, name: 'Доставка' },
  { value:PRODUCT_CUSTOM_FIELD_FORM_TYPE.SHIPMENT_PAYMENT_FORM, name:'Оплаты доставки' }
]

function CustomFieldFormType (props: any) {
  return (
    <SelectField
      name="formType"
      label="Форма"
      options={OPTIONS}
      {...props}
    />
  )
}

export default CustomFieldFormType
