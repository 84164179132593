import React from 'react'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import LinearWithValueLabel from 'components/linear-progress'

interface Props {
  readonly open: boolean
}

function LoadingDialogue ({ open }: Props) {
  return (
    <Dialog
      open={open}
      maxWidth="lg"
    >
      <DialogContent sx={{ width:'50vw' }}>
        <LinearWithValueLabel />
      </DialogContent>
    </Dialog>
  )
}

export default LoadingDialogue
