import * as API from 'constants/Api'

import { getRequest, Options } from 'api/BaseApi'

export const getTransactionList = (options?: Options) => {
  return getRequest<any>(API.TransactionList, options)
}

export const getExcelTransactions = (options?:Options) => {
  return getRequest<any>(API.ExportTransactions, options)
}
