import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import { prop } from 'ramda'
import { useField } from 'formik'
import { getRequest } from 'api/BaseApi'

import AutoSelectField from './AutoSelectField'

const getOptionValue = (value:any) => {
  if (value) {
    const id = prop('id', value)
    const name = prop('name', value)

    return { value: id, name, id }
  }

  return null
}

function PackageField (props: any) {
  const field = useField(props.name)

  useEffect(() => {
    if (field[0].value) {
      getRequest(API.PackageGetDetail, { params: { id:field[0].value } })
        .then((response:any) => {
          field[2].setValue(getOptionValue(response))
        })
    }
  }, [])
  return (
    <AutoSelectField
      name="supplierIds"
      api={API.PackageSuggestions}
      getOptionValue={getOptionValue}
      label="Упаковка"
      {...props}
    />
  )
}

export default PackageField
