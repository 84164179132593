import React, { useEffect } from 'react'
import SelectField from 'components/form/SelectField'
import { useField } from 'formik'
import { path } from 'ramda'

const OPTIONS = [
  { value: 'HOURLY', name: 'ЕЖЕЧАСНО' },
  { value: 'DAILY', name: 'ЕЖЕДНЕВНО' },
  { value: 'EVEN_DAILY', name: 'ЧЕТНЫЙ ДЕНЬ' },
  { value: 'ODD_DAILY', name: 'НЕЧЕТНЫЙ ДЕНЬ' },
  { value: 'WEEKLY', name: 'ЕЖЕНЕДЕЛЬНО' },
  { value: 'MONTHLY', name: 'ЕЖЕМЕСЯЧНО' },
  { value: 'YEARLY', name: 'ЕЖЕГОДНО' }
]

interface CheckTypeProps {
  readonly type:string
  readonly setValue:({ type }: { type: string }) => void
}

const checkTypeValue = ({ type, setValue }:CheckTypeProps) => {
  if (type === 'HOURLY') {
    setValue({ type:'HOURLY' })
  }
  if (type === 'DAILY') {
    setValue({ type:'DAILY' })
  }
  if (type === 'EVEN_DAILY') {
    setValue({ type:'EVEN_DAILY' })
  }
  if (type === 'ODD_DAILY') {
    setValue({ type:'ODD_DAILY' })
  }
  if (type === 'WEEKLY') {
    setValue({ type:'WEEKLY' })
  }
  if (type === 'MONTHLY') {
    setValue({ type:'MONTHLY' })
  }
  if (type === 'YEARLY') {
    setValue({ type:'YEARLY' })
  }
}

function TimeTypeField () {
  const reminderType = useField('timeReminder.type')
  const type = path([0, 'value'], reminderType)
  const reminder = useField('timeReminder')
  const setValue = reminder[2].setValue

  useEffect(() => {
    checkTypeValue({ type, setValue })
  }, [type])

  return (
    <SelectField
      name="timeReminder.type"
      label="Тип напоминания"
      options={OPTIONS}
      data-cy="status"
    />
  )
}

export default TimeTypeField
