import { Button, Card, CardContent, Grid } from '@mui/material'
import React, { useState } from 'react'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import Header from 'components/container/Header'
import PageTitle from 'components/main/PageTitle'
import Content from 'components/container/Content'
import SwitchField from 'components/form/SwitchField'
import Stepper from 'components/stepper/Stepper'

import ColumnsField from './form/ColumnsField'
import BillingStatusField from './form/BillingStatusField'
import CategoryLineItems from './form/CategoryLineItems'
import BillingCategoryField from './form/BillingCategoryField'
import { BillingModules } from './BillingModules'

const steps = [
  { id:1, label:'Детали' },
  { id:2, label:'Выберите категории контактов' },
  { id:3, label:'Выберите колонок' },
  { id:4, label:'Модули' }
]

function BillingPlanCreateForm () {
  const [activeStep, setActiveStep] = useState(steps[0].id)

  const onNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const onBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать тарифный план"
          rightButton={(
            <>
              {activeStep === 1 && (
                <Button
                  type="button"
                  onClick={onNext}
                >
                  Следующий
                </Button>
              )}
              {activeStep === 2 && (
                <>
                  <Button
                    type="button"
                    onClick={onBack}
                  >
                    Назад
                  </Button>
                  <Button
                    type="button"
                    onClick={onNext}
                  >
                    Следующий
                  </Button>
                </>
              )}
              {activeStep === 3 && (
                <>
                  <Button
                    type="button"
                    onClick={onBack}
                  >
                    Назад
                  </Button>
                  <Button
                    type="button"
                    onClick={onNext}
                  >
                    Следующий
                  </Button>
                </>
              )}
              {activeStep === 4 && (
                <>
                  <Button
                    type="button"
                    onClick={onBack}
                  >
                    Назад
                  </Button>
                  <Button
                    type="submit"
                  >
                    Создать
                  </Button>
                </>
              )}
            </>
          )}
        />
      </Header>
      <Content>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
        <Grid container={true} spacing={3}>
          {activeStep === 1 && (
            <Grid item={true} lg={12}>
              <Card>
                <CardHeader title="Деталь" />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Название тарифного плана"
                        name="name"
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Количество"
                        name="amount"
                      />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <BillingCategoryField />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <BillingStatusField />
                    </Grid>
                    <Grid item={true} lg={4}>
                      <TextField
                        label="Описание"
                        name="description"
                        minRows={4}
                        multiline={true}
                      />
                    </Grid>
                    <Grid item={true} lg={4} />
                    <Grid item={true} lg={4}>
                      <SwitchField
                        label="Плата за экспорт контактов"
                        name="contactExportCharge"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid item={true} lg={12}>
              <CategoryLineItems />
            </Grid>
          )}
          {activeStep === 3 && (
            <Grid item={true} lg={12}>
              <Card>
                <CardHeader title="Столбцы" />
                <CardContent>
                  <Grid container={true} spacing={4}>
                    <ColumnsField />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {activeStep === 4 && (
            <Grid item={true} lg={12}>
              <Card>
                <CardHeader title="Модули" />
                <CardContent>
                  <Grid container={true} spacing={4}>
                    <BillingModules />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(BillingPlanCreateForm)
