import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import { Grid, IconButton, Typography } from '@mui/material'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { makeStyles } from '@mui/styles'
import { path, pathOr, prop } from 'ramda'
import cx from 'classnames'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import FlexBox from '../../main/FlexBox'
import { DeleteIcon } from '../../icons/DeleteIcon'
import CheckboxField from '../../form/CheckboxField'

const useStyles = makeStyles({
  dialog:{
    '& .MuiPaper-root': {
      background:'transparent',
      boxShadow:'none'
    },
    '& .MuiDialogTitle-root':{
      background:'#fff',
      padding:'8px 24px'
    },
    '& .MuiDialogContent-root':{
      background:'#fff',
      paddingBottom:0,
      overflowY:'hidden'
    },
    '& .MuiDialogActions-root':{
      background:'#fff',
      borderBottomLeftRadius:'5px',
      borderBottomRightRadius:'5px'
    }
  },
  actions:{
    height:'36px'
  },
  imageWrapperBox:{
    marginTop:'16px',
    overflow: 'hidden',
    '& > div':{
      display: 'flex',
      transition: 'transform 0.3s ease'
    }
  },
  imageBox:{
    transition: 'border-color 0.3s ease',
    border:'1.5px solid transparent',
    cursor:'pointer',
    borderRadius:'4px',
    padding:'5px',
    height:'70px',
    width:'70px',
    background:'#fff',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    '&.active':{
      width:'71.5px',
      height:'71.5px',
      border:'1.5px solid #1557FF',
    },
    '& img':{
      borderRadius:'5px',
      width:'100%',
      height:'100%'
    }
  },
  sliderWrapper: {
    display: 'flex',
    transition: 'transform 0.3s ease', // Smooth sliding transition
    overflow: 'hidden',
  },
  absoluteButtons:{
    position:'absolute',
    transform:'translate(0%, -50%)',
    top:'50%',
    width:'100%',

    '& .MuiIconButton-root':{
      width:'36px',
      height:'36px',
      background:'#202020',
      color:'#fff'
    },
    '& .MuiIconButton-root.Mui-disabled':{
      background:'rgba(38,42,44, 0.5)'
    }
  }
})

interface Props {
  readonly handleClose: () => void
  readonly open: boolean
  readonly images:any
  readonly image:any
  readonly onDelete:(id:number) => void
  readonly defaultImageValue?:any
  readonly onDefaultImage?:(image:any) => void
}

export const ImgCarousel = ({
  handleClose,
  open,
  images,
  image,
  onDelete,
  defaultImageValue,
  onDefaultImage = () => null }: Props) => {
  const classes = useStyles()
  const defaultLink = prop('link', image)
  const initialURL = pathOr(defaultLink, ['original', 'link'], image)
  const [imageURL, setImageURL] = useState(initialURL)
  const [activeId, setActiveId] = useState(image.id)
  const [currentIndex, setCurrentIndex] = useState(images.findIndex((img: { id: number }) => img.id === activeId))
  const [currenctImage, setCurrentImage] = useState(image)
  const handleClick = (item:any) => {
    const activeImage = pathOr(prop('link', item), ['original', 'link'], item)
    setImageURL(activeImage)
    setActiveId(item.id)
    setCurrentImage(item)
  }

  const handleDownload = () => {
    // Add logic to trigger download for the active image
    const activeImage = images.find((image:any) => image.id === activeId)
    const downloadLink = document.createElement('a')
    downloadLink.href = path(['original', 'link'], activeImage)
    downloadLink.download = 'download.jpg' // Set the desired file name
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    handleClose()
  }

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % images.length // Calculate the next index with looping
    const nextImage = images[newIndex]
    if (nextImage) {
      setImageURL(pathOr(prop('link', nextImage), ['original', 'link'], nextImage))
      setCurrentImage(nextImage)
      setActiveId(nextImage.id)
      setCurrentIndex(newIndex)
    }
  }

  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length // Calculate the previous index with looping
    const prevImage = images[newIndex]
    if (prevImage) {
      setCurrentImage(prevImage)
      setImageURL(pathOr(prop('link', prevImage), ['original', 'link'], prevImage))
      setActiveId(prevImage.id)
      setCurrentIndex(newIndex)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        className={classes.dialog}
      >
        <DialogTitle>
          <FlexBox align="center" justify="space-between">
            <Typography variant="subtitle1">
              Предпросмотр
            </Typography>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </FlexBox>
        </DialogTitle>
        <DialogContent>
          <Grid container={true}>
            <Grid item={true} xs={12} lg={12}>
              <Box sx={{ width:'500px', height:'450px', position:'relative' }} >
                <img
                  src={imageURL}
                  alt="carousel_image"
                  style={{ width:'100%', height:'100%', borderRadius:'5px' }}
                />
                <FlexBox flex={true} align="center" justify="space-between" className={classes.absoluteButtons}>
                  <IconButton
                    onClick={handlePrev}
                    disabled={currentIndex === 0}
                    aria-disabled={currentIndex === 0}
                  >
                    <ArrowBackIcon sx={{ height:'20px' }} />
                  </IconButton>
                  <IconButton
                    onClick={handleNext}
                    disabled={currentIndex === images.length - 1}
                    aria-disabled={currentIndex === images.length - 1}
                  >
                    <ArrowForwardIcon sx={{ height:'20px' }} />
                  </IconButton>
                </FlexBox>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding:'8px 24px' }}>
          <FlexBox align="center" justify="space-between" flex={true} className={classes.actions}>
            {defaultImageValue?.id === activeId ? (
              <CheckboxField
                color="white"
                name="checked"
                label="Основной"
                checked={defaultImageValue.id === activeId}
              />)
              : (
                <Typography
                  variant="subtitle2"
                  color="primary"
                  sx={{ cursor:'pointer' }}
                  onClick={() => onDefaultImage(currenctImage)}
                >
                  Отметить как основной
                </Typography>
              )
            }
            <FlexBox align="center" gap={5}>
              <IconButton onClick={handleDownload}>
                <SaveAltIcon sx={{ color:'#202020', height:'20px' }} />
              </IconButton>
              <IconButton onClick={() => onDelete(activeId)}>
                <DeleteIcon />
              </IconButton>
            </FlexBox>
          </FlexBox>
        </DialogActions>
        <FlexBox align="center" justify="center" className={classes.imageWrapperBox}>
          <FlexBox align="center" justify="center" gap={8}>
            {images.map((image:any, index:number) => {
              const defaultLink = prop('link', image)
              const mediumSize = pathOr(defaultLink, ['original', 'link'], image)
              return (
                <Box
                  key={index}
                  className={cx(classes.imageBox,
                    { 'active': activeId === images[index].id })
                  }
                  onClick={() => handleClick(image)}
                >
                  <img
                    src={mediumSize}
                    alt="carousel_image"
                  />
                </Box>
              )
            })}
          </FlexBox>
        </FlexBox>
      </Dialog>
    </>
  )
}
