import React from 'react'
import { useField } from 'formik'

const COLORS = [
  '#2c75d6',
  '#f1ac3f',
  '#9cc937',
  '#815091',
  '#e53a2e',
  '#5ab17a',
  '#2390ae',
  '#d0665a',
  '#306699',
  '#499644',
  '#d00da9',
  '#ad81bf',
  '#1ee195'
]

interface Props {
  readonly index: number
  readonly name: string
}

function ColumnConditionColorPicker ({ index, name }: Props) {
  const conditionColor = useField(`${name}[${index}].color`)
  const conditionColorValue = conditionColor[0].value
  const conditionColorSetValue = conditionColor[2].setValue

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {COLORS.map((color) => {
        const active = conditionColorValue === color
        return (
          <div style={{ padding: '10px' }}>
            <div
              style={{
                background: color,
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                cursor: 'pointer',
                border:  active ? '1px solid black' : 'unset',
                boxShadow: active ? 'rgb(0 0 0 / 17%) 1px 0px 10px 6px' : 'unset',
                transition: '0.3s'
              }}
              onClick={() => conditionColorSetValue(color)}
            />
          </div>
        )
      })}
    </div>
  )
}

export default ColumnConditionColorPicker
