import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const createTab = (options?:Options) => {
  return postRequest<any>(API.ListingTabCreate, options)
}

export const updateTab = (options?:Options) => {
  return putRequest<any>(API.ListingTabUpdate, options)
}

export const getByIdTab = (options?:Options) => {
  return getRequest<any>(API.ListingTabGetById, options)
}

export const getTabsList = (options?:Options) => {
  return getRequest<any>(API.ListingTabList, options)
}

export const deleteTab = (options?:Options) => {
  return deleteRequest<any>(API.ListingTabDelete, options)
}

export const deleteTabByIds = (options?:Options) => {
  return deleteRequest<any>(API.ListingTabDeleteByIds, options)
}
