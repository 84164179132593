import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import AppLayout from 'components/main/AppLayout'
import { useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import PaymentUpdateForm from '../components/PaymentUpdateForm'
import { getPaymentDetail, changeStatus, getCustomFields } from '../api'
import { paymentCollectSerializer, paymentInitialValuesSerializer, paymentDepositSerializer } from '../serializer'
import { useGetList } from '../../../../hooks/useGetList'

function PaymentUpdateContainer () {
  const { id } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const statusChange = usePut(changeStatus)
  const paymentDetail = useGetDetail(getPaymentDetail, { params: { id } })
  const [customFields, setCustomField] = useState([])
  const customFieldsGet = useGetList(getCustomFields)

  const handleCollect = useCallback((values: any) => {
    const data = paymentCollectSerializer(values, id)
    statusChange.putData({ data })
      .then(() => navigate(ROUTES.ORDER_PAYMENT_LIST))
      .then(() => snackbar({ message: 'Олпата успешно обновлена' }))
  }, [id, statusChange])

  const handleDeposit = useCallback((values: any) => {
    const data = paymentDepositSerializer(values, id)
    statusChange.putData({ data })
      .then(() => navigate(ROUTES.ORDER_PAYMENT_LIST))
      .then(() => snackbar({ message: 'Олпата успешно обновлена' }))
  }, [id, statusChange])

  const initialValues = useMemo(() => {
    return paymentInitialValuesSerializer(paymentDetail.result)
  }, [paymentDetail])

  useEffect(() => {
    customFieldsGet.getList({ params: { formType: 'SHIPMENT_PAYMENT_FORM' } }).then((res: any) => {
      setCustomField(res)
    })
  }, [])

  return (
    <AppLayout>
      {!paymentDetail.loading && (
        <PaymentUpdateForm
          initialValues={initialValues}
          onCollect={handleCollect}
          onDeposit={handleDeposit}
          customFields={customFields}
          statusChangeLoading={statusChange.loading}
        />
      )}
      {paymentDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default PaymentUpdateContainer
