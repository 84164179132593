import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import useScrollList from 'hooks/useScrollList'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'

import { getByIdTransfer, getStockList, updateTransfer } from '../api'
import { transferDetailSerializer, transferSerializer } from '../serializer'
import TransferUpdateForm from '../components/TransferUpdateForm'

function TransferUpdateContainer () {
  const { id } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const productList = useScrollList(getStockList)
  const transferUpdate = usePut(updateTransfer)
  const transferDetail = useGetDetail(getByIdTransfer, { params: { id: id } })

  const handleSubmit = useCallback((values: any) =>
    transferUpdate.putData({ data: transferSerializer(values) })
      .then(() => navigate(ROUTES.WAREHOUSE_MOVEMENT_LIST))
      .then(() => snackbar({ message: 'Приход успешно обновлён' }))
  , [])

  const initialValues = useMemo(() => transferDetailSerializer(transferDetail.result), [transferDetail])

  return (
    <AppLayout>
      {!transferDetail.loading &&
        <TransferUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          productList={productList}
        />
      }
    </AppLayout>
  )
}

export default TransferUpdateContainer
