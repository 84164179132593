import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { useNavigate, useParams } from 'react-router-dom'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { updateCustomField, getCustomFieldById } from '../api'
import CustomFieldUpdateForm from '../components/CustomFieldUpdateForm'

function ProductCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()

  const customFieldUpdate = usePut(updateCustomField)
  const customFieldDetail = useGetDetail(getCustomFieldById, { params: { id } })

  const onSaveCustomField = useCallback((values: any) => {
    return customFieldUpdate.putData({ data: values })
      .then(() => snackbar({ message: 'Поле успешно обновлено' }))
      .then(() => navigate(ROUTES.SETTINGS_CUSTOM_FIELD_LIST))
  }, [])

  return (
    <AppLayout>
      {!customFieldDetail.loading && (
        <CustomFieldUpdateForm
          initialValues={customFieldDetail.result}
          onSubmit={onSaveCustomField}
        />
      )}
      {customFieldDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default ProductCreateContainer
