import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'

import PaymentCreateForm from '../components/PaymentCreateForm'
import { createPayment } from '../api'
import { paymentCreateSerializer } from '../serializer'

function PaymentCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const paymentCreate = usePost(createPayment)

  const handleSubmit = useCallback((data: any) => {
    return paymentCreate.postData({ data: paymentCreateSerializer(data) })
      .then(() => navigate(ROUTES.ORDER_PAYMENT_LIST))
      .then(() => snackbar({ message: 'Оплата успешно создана' }))
  }, [])

  return (
    <AppLayout>
      <PaymentCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default PaymentCreateContainer
