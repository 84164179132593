import React, { useEffect } from 'react'
import TextField from 'components/form/TextField'
import { useField } from 'formik'
import { path, prop } from 'ramda'

import { queryPatternSerializer } from '../../serializer'

export const QueryPatternField = (props:any) => {
  const criteriaField = useField('criteria')
  const [input,, field] = useField(props.name)
  const criteriaValue = path(['0', 'value'], criteriaField)
  const defaultValue = prop('criteria', queryPatternSerializer(criteriaValue))

  useEffect(() => {
    field.setValue(defaultValue)
  }, [defaultValue])

  return (
    <TextField
      value={input.value}
      name="queryPattern"
      label="Шаблон запроса"
      {...props}
    />
  )
}
