import * as API from 'constants/Api'

import React from 'react'
import { pathOr, prop } from 'ramda'
import MultiSelectSearchField from 'components/form/MultiSelectSearchField'
import { useField } from 'formik'

const getOptionValue = (option: any) => {
  if (option) {
    const id = prop('id', option)
    const name = prop('name', option)

    return { name, id }
  }

  return null
}

function MultipleWarehouseField (props: any) {
  const [ field, , ] = useField('territoryList')
  const territoryValue = pathOr([], ['value'], field)
  const territoryIds = territoryValue.map((territory:any) => territory.id)

  return (
    <MultiSelectSearchField
      disabled={!territoryIds.length}
      name="warehouseLineItems"
      api={API.WarehouseSuggestions}
      getOptionValue={getOptionValue}
      query={{ territoryIds }}
      label="Склады"
      {...props}
    />
  )
}

export default MultipleWarehouseField
