import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { useConfirm } from 'context/confirm'
import { propOr, trim } from 'ramda'
import { usePut } from 'hooks/usePut'

import {
  deleteUnitMeasurement,
  getUnitMeasurementList,
  updateStatus,
  updateStatusBulk
} from '../api'
import UnitMeasurementRowMenu from '../components/UnitMeasurementRowMenu'
import TableActionsRow from '../components/TableActionRow'
import UnitMeasurementStatusRow from '../components/UnitMeasurementStatusRow'
import { selectedRowSerializer } from '../serializer'
import MoreActionButtons from '../../brand/components/MoreRowMenu'
import UnitMeasurementExcel from '../components/UnitMeasurementExcel'
import { useLocales } from '../../../../hooks/useLocales'

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 150
  },
  {
    headerName: 'Unit measurement name',
    field: 'name',
  },
  {
    headerName: 'Unit measurement shortname',
    field: 'shortName',
  },
  {
    headerName: 'Status',
    field: 'status',
    renderCell:(row:any) => <UnitMeasurementStatusRow status={row.status} />
  }
]

function UnitMeasurementListContainer () {
  const { t } = useLocales('mml')
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const unitMeasurementList = useGetList(getUnitMeasurementList)
  const unitMeasurementDelete = useDelete(deleteUnitMeasurement)
  const unitMeasurementUpdateStatus = usePut(updateStatus)
  const unitMeasurementUpdateStatusBulk = usePut(updateStatusBulk)
  const [selectedRows, setSelectedRows] = useState([])

  const handleDeleteProduct = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить единицe измерения ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        unitMeasurementDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Единица измерения успешно удалена' }))
          .then(() => unitMeasurementList.getList())
      })
      .disagree()
  }, [])

  const onUpdateStatus = useCallback((id:number) => {
    unitMeasurementUpdateStatus.putData({ params:{ unitMeasurementId:id } })
      .then(() => unitMeasurementList.getList())
      .then(() => snackbar({ message:'Статус единицы измерения успешно обновлен' }))
  }, [])

  const onUpdateStatusBulk = useCallback((status:string) => {
    unitMeasurementUpdateStatusBulk.putData({
      data: { unitMeasurementIds:selectedRowSerializer(selectedRows), status }
    })
      .then(() => setSelectedRows([]))
      .then(() => unitMeasurementList.getList())
      .then(() => snackbar({ message:'Статусы выбранных измерений успешно обновлены' }))
  }, [selectedRows])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    unitMeasurementList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title={t('Unit measurement')}
        onAddClick={() => navigate(ROUTES.CATALOG_MML_CREATE)}
        dataRequest={unitMeasurementList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        translationModule="mml"
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkbox={true}
        searchPlaceholder={t('Search by name or ID')}
        exportNode={(
          <UnitMeasurementExcel
            list={unitMeasurementList}
          />
        )}
        actions={(row) => (
          <UnitMeasurementRowMenu
            item={row}
            handleDelete={handleDeleteProduct}
            onUpdateStatus={onUpdateStatus}
          />
        )}
        tableActions={(
          <TableActionsRow
            onUpdateStatusBulk={onUpdateStatusBulk}
          />
        )}
        moreActions={(actions) => (
          <MoreActionButtons
            onOpen={actions.onExportClick}
          />
        )}
      />
    </AppLayout>
  )
}

export default UnitMeasurementListContainer
