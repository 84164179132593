import React, { useState, useRef, useEffect, FC } from 'react'
import { makeStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles({
  fullscreenContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    overflow: 'hidden',
  },
  fullscreenImage: {
    maxWidth: '100%',
    maxHeight: '90%',
    transition: 'transform 0.3s ease',
    cursor: 'zoom-in',
  },
  zoomed: {
    cursor: 'grab',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#fff',
    zIndex: 1001,
  },
})

interface FullscreenImageViewerProps {
  src: string
  onClose: () => void
}

const FullscreenImageViewer: FC<FullscreenImageViewerProps> = ({ src, onClose }) => {
  const classes = useStyles()
  const [isZoomed, setIsZoomed] = useState(false)
  const [tapTimeout, setTapTimeout] = useState<NodeJS.Timeout | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const [startPosition, setStartPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const imageRef = useRef<HTMLImageElement | null>(null)

  useEffect(() => {
    const imgElement = imageRef.current

    if (!imgElement) return

    const handleTouchStart = (e: TouchEvent) => {
      if (!isZoomed) return
      setIsDragging(true)
      const startX = e.touches[0].clientX
      const startY = e.touches[0].clientY
      setStartPosition({ x: startX - position.x, y: startY - position.y })
    }

    const handleTouchMove = (e: TouchEvent) => {
      if (!isDragging) return
      e.preventDefault() // Prevent scrolling when dragging
      const currentX = e.touches[0].clientX
      const currentY = e.touches[0].clientY
      const newX = currentX - startPosition.x
      const newY = currentY - startPosition.y
      setPosition({ x: newX, y: newY })
    }

    const handleTouchEnd = () => {
      setIsDragging(false)
    }

    imgElement.addEventListener('touchstart', handleTouchStart, { passive: false })
    imgElement.addEventListener('touchmove', handleTouchMove, { passive: false })
    imgElement.addEventListener('touchend', handleTouchEnd)

    return () => {
      imgElement.removeEventListener('touchstart', handleTouchStart)
      imgElement.removeEventListener('touchmove', handleTouchMove)
      imgElement.removeEventListener('touchend', handleTouchEnd)
    }
  }, [isZoomed, isDragging, position, startPosition])

  const handleDoubleTap = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation()
    if (tapTimeout) {
      clearTimeout(tapTimeout)
      setTapTimeout(null)
      if (isZoomed) {
        setPosition({ x: 0, y: 0 })
        setIsZoomed(false)
      } else {
        setIsZoomed(true)
      }
    } else {
      setTapTimeout(setTimeout(() => setTapTimeout(null), 300))
    }
  }

  return (
    <div className={classes.fullscreenContainer}>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>

      <img
        src={src}
        alt="Fullscreen"
        className={`${classes.fullscreenImage} ${isZoomed ? classes.zoomed : ''}`}
        onClick={handleDoubleTap}
        style={{
          transform: `translate(${position.x}px, ${position.y}px) scale(${isZoomed ? 2 : 1})`
        }}
        ref={imageRef}
      />
    </div>
  )
}

export default FullscreenImageViewer
