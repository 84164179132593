import React from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { prop } from 'ramda'

interface Props {
  readonly item: any
  readonly onUpdate: (id: number) => void
  readonly onDelete:(id:number) => void
}

function ColumnRowMenu ({ item, onUpdate, onDelete }: Props) {
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null)

  const anchorIsOpen = Boolean(optionsAnchorEl)

  const handleOpenOptions = (event: any) => {
    setOptionsAnchorEl(event.currentTarget)
  }

  const handleCloseOptions = () => {
    setOptionsAnchorEl(null)
  }

  const id = prop('id', item)

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={anchorIsOpen ? 'long-menu' : undefined}
        aria-expanded={anchorIsOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenOptions}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="table-item-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={optionsAnchorEl}
        open={anchorIsOpen}
        onClose={handleCloseOptions}
      >
        <MenuItem
          onClick={() => {
            onUpdate(id)
            handleCloseOptions()
          }}
        >
          Редактировать
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete(id)
            handleCloseOptions()
          }}
        >
          Удалить
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default ColumnRowMenu
