import React from 'react'
import { pathOr, prop, propOr } from 'ramda'

import VisitStatusRow from './VisitStatusRow'

const translations: { [key: string]: string } = {
  NAME: 'Название посещение',
  TYPE: 'Тип посещения',
  PLANNED_DATE: 'Планируемая Дата',
  USER: 'Пользователь',
  CUSTOMER: 'Клиент',
  ACTUAL_DATE: 'Фактическая Дата',
  TERRITORIES: 'Территория',
  STATUS: 'Статус',
  DURATION: 'Продолжительность',
  NOTE: 'Заметка',
  SUCCESSOR: 'Преемник',
  PREDECESSOR: 'Предшественник',
  TIMES: 'Предшественник'
}

const fieldMappings: { [key: string]: string } = {
  NAME: 'name',
  TYPE: 'type',
  PLANNED_DATE: 'plannedDate',
  USER: 'user',
  CUSTOMER: 'customer',
  ACTUAL_DATE: 'actualDate',
  TERRITORIES: 'territories',
  STATUS: 'status',
  DURATION: 'duration',
  NOTE: 'note',
  SUCCESSOR: 'successor',
  PREDECESSOR: 'predecessor',
  TIMES: 'times'
}

const getTranslatedHeaderName = (code: string, name:string): string => {
  return translations[code] || name
}

export const fieldMappingsVisits = (code:string, name:string):string => {
  return fieldMappings[code] || name
}

const getRenderCell = (name: string) => {
  if (name === 'status') {
    return (item: any) => <VisitStatusRow status={item.status} />
  }
  if (name === 'user') {
    return (item: any) => pathOr('', ['user', 'name'], item)
  }
  if (name === 'customer') {
    return (item: any) => pathOr('', ['customer', 'name'], item)
  }
  if (name === 'predecessor') {
    return (item: any) => pathOr('', ['predecessor', 'name'], item)
  }
  if (name === 'successor') {
    return (item: any) => pathOr('', ['successor', 'name'], item)
  }
  if (name === 'territories') {
    return (item: any) => pathOr('', ['territory', 'name'], item)
  }
  if (name === 'type') {
    return (item:any) => pathOr('', ['type', 'name'], item)
  }
  if (name === 'times') {
    return (item:any) => item.times && item.times.map((time: any) => JSON.stringify(time)).join(', ')
  } else return null
}

export const getColumnsBySetting = (setting: any) => {
  const columns = propOr([], 'columns', setting) as any
  return columns.map((column: any, index: number) => {
    const code = prop('code', column)
    const name = propOr(index, 'name', column) as string
    const freeze = prop('freeze', column)
    const conditions = prop('conditions', column)
    const width = prop('width', column)
    const renderCell = getRenderCell(fieldMappingsVisits(code, name))
    return {
      headerName: getTranslatedHeaderName(code, name),
      leftStick: freeze,
      field: fieldMappingsVisits(code, name),
      conditions,
      width,
      renderCell
    }
  })
}
