import React from 'react'
import { makeStyles } from '@mui/styles'
import FlexBox from 'components/main/FlexBox'
import { Avatar, Button, MenuItem } from '@mui/material'
import { prop, propOr } from 'ramda'
import cx from 'classnames'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import Accordion from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import TerritoryField from './form/TerritoryField'
import CategoryField from './form/CategoryField'

const useStyles = makeStyles({
  sidebarWrapper:{
    padding:'5px'
  },
  header:{
    padding:'5px',
    height:'100px',
  },
  sidebarContent:{
    padding:'5px',
    height:'calc(100vh - 174px)',
    overflowY:'scroll',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  item:{
    borderRadius:'5px',
    padding:'5px',
    cursor:'pointer',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    gap:'10px',
    '&.active':{
      background:'#f1f1f1'
    },
  },
  userImageWrapper:{
    width:'50px',
    height:'50px',
  },
  image:{
    width:'40px',
    height:'40px'
  },
  title:{
    fontSize: '0.80rem',
    lineHeight: '1.66',
    fontWeight:'500'
  },
  subTitle:{
    fontSize: '0.75rem',
    lineHeight: '1.66',
    fontWeight:'400'
  }
})

interface Props {
  readonly contactList:any
  readonly setSelectedLotLan:(values:any) => void
}

function SidebarRightSide ({ setSelectedLotLan, contactList }:Props) {
  const classes = useStyles()
  const contactGetList = propOr([], 'list', contactList) as any
  return (
    <div className={classes.sidebarWrapper}>
      <div className={classes.header}>
        <FlexBox align="flex-start" gap={16} direction="column">
          <TerritoryField />
          <CategoryField />
        </FlexBox>
        <Button
          sx={{ marginTop:'8px' }}
          type="submit"
          fullWidth={true}
        >
          Загрузить
        </Button>
      </div>
      <div className={classes.sidebarContent}>
        {contactGetList && contactGetList.map((items:any) => {
          const addressList = prop('addressList', items)
          return (
            <Accordion key={items?.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id={items?.id}
              >
                <FlexBox align="center" gap={5}>
                  <FlexBox
                    align="center"
                    justify="center"
                    className={classes.userImageWrapper}
                  >
                    <Avatar className={classes.image} src={items?.picture?.link} alt="photoURL">
                      {items?.name ? items?.name.charAt(0).toUpperCase() : ''}
                    </Avatar>
                  </FlexBox>
                  <Box>
                    <Typography className={classes.title}>
                      {items?.name || items?.addressLine1 || items?.addressLine2}
                    </Typography>
                  </Box>
                </FlexBox>
              </AccordionSummary>
              <AccordionDetails>
                {addressList && addressList?.map((item:any, index:number) => {
                  const isClose = true
                  return (
                    <FlexBox align="center">
                      <Typography variant="body2">
                        {index + 1}.
                      </Typography>
                      <MenuItem
                        key={index}
                        onClick={() => setSelectedLotLan(item)}
                        className={cx(classes.item, { active:isClose })}
                      >
                        <Typography variant="body2" noWrap={false}>
                          {item.name}
                        </Typography>
                      </MenuItem>
                    </FlexBox>
                  )
                })}
                {contactGetList.length === 0 && (
                  <Typography variant="body2">
                    Нет данных отслеживания
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

export default SidebarRightSide
