import * as ROUTES from 'constants/Routes'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetList } from 'hooks/useGetList'
import { useGetDetail } from 'hooks/useGetDetail'

import { validationSchema } from './ProductCreateContainer'

import {
  getByIdProduct,
  updateProduct,
  getProductStockHistory,
  getPackageList, getCustomFields
} from '../api'
import { productDetailSerializer, productUpdateSerializer } from '../serializer'
import ProductUpdateForm from '../components/ProductUpdateForm'
function ProductUpdateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()
  const [customFields, setCustomField] = useState([])

  const productUpdate = usePut(updateProduct)
  const packageList = useGetList(getPackageList)
  const productDetail = useGetDetail(getByIdProduct, { params: { id } })
  const productStockHistory = useGetList(getProductStockHistory, { query: { productId: id } })
  const customFieldsGet = useGetList(getCustomFields)

  const handleSubmit = useCallback((values: any) => {
    return productUpdate.putData({ data: productUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Продукт успешно обновлён' }))
      .then(() => navigate(ROUTES.CATALOG_PRODUCT_LIST))
  }, [])

  useEffect(() => {
    customFieldsGet.getList({ params: { formType: 'PRODUCT_FORM' } }).then((res: any) => {
      setCustomField(res)
    })
  }, [])

  const initialValues = useMemo(() => productDetailSerializer(productDetail.result), [productDetail])

  return (
    <AppLayout>
      {!productDetail.loading && (
        <ProductUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          stockHistory={productStockHistory}
          packageList={packageList}
          customFields={customFields}
        />
      )}
      {productDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default ProductUpdateContainer
