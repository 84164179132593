import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [{ value: 'CELL', name: 'Ячейка' }, { value: 'ROW', name: 'Строка' }, { value: 'TEXT', name: 'Текс' }]

function ColumnConditionTypeField (props: any) {
  return (
    <SelectField
      label="Тип"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ColumnConditionTypeField
