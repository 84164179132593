import * as API from 'constants/Api'

import React from 'react'
import { path, prop } from 'ramda'
import SearchField from 'components/form/SearchField'
import { useField } from 'formik'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)
    return { value, id:value, name }
  }

  return null
}

function CustomerField () {
  const [ field, , ] = useField('territoryId')
  const territoryId = path(['value', 'id'], field)
  return (
    <SearchField
      disabled={!territoryId}
      params={{ territoryId }}
      name="customerId"
      api={API.ContactSuggestions}
      getOptionValue={getOptionValue}
      label="Клиент"
    />
  )
}

export default CustomerField
