import { dissoc, omit, path, pathOr, prop, propOr } from 'ramda'
import { toPayloadDate } from 'utils/dates'

export const SaleReturnCreateSerializer = (data: any) => {
  const warehouseId = path(['warehouseId', 'value'], data)
  const driverId = path(['driverId', 'id'], data)
  const contactId = path(['contactId', 'id'], data)
  const agentId = path(['agentId', 'id'], data)
  const priceLevelId = path(['priceLevelId', 'id'], data)
  const orderId = path(['orderId', 'id'], data)
  const territoryId = path(['territoryId', 'id'], data)
  const returnDate = prop('returnDate', data)
  const visitDate = prop('visitDate', data)
  const shippingAddressId = path(['shippingAddressId', 'value'], data)
  const oldItems = propOr([], 'items', data) as any
  const items = oldItems.map((item: any) => {
    const newItem = omit(['product'], item)
    return { ...newItem }
  })
  const addressId = pathOr('', ['addressId', 'id'], data)
  return {
    ...data,
    driverId,
    agentId,
    priceLevelId,
    warehouseId,
    returnDate: toPayloadDate(returnDate),
    visitDate: toPayloadDate(visitDate),
    shippingAddressId,
    items,
    orderId,
    contactId,
    territoryId,
    addressId
  }
}

export const SaleOrderReturnGetInitialValues = (data: any) => {
  const warehouseId = prop('warehouse', data)
  const agentId = prop('agent', data)
  const driverId = prop('driver', data)
  const items = propOr([], 'items', data) as any
  const shippingAddressId = prop('shippingAddress', data)
  const orderId = prop('order', data)
  const priceLevelId = prop('priceLevel', data)
  const territoryId = prop('territory', data)
  const contactId = prop('contact', data)
  const getShippingMethod = prop('shippingMethod', data)
  const shippingMethod = getShippingMethod || 'SAME_DAY'
  const newItems = items.map((item: any) => ({
    productId: path(['product', 'id'], item),
    ...item
  }))
  const addressId = prop('address', data)
  return {
    ...data,
    agentId,
    driverId,
    shippingMethod,
    shippingAddressId,
    territoryId,
    contactId,
    orderId,
    items: newItems,
    warehouseId,
    priceLevelId,
    addressId
  }
}

export const SaleOrderUpdateSerializer = (data:any) => {
  const fieldsToRemove = ['contact', 'order', 'companyId', 'currency', 'territory', 'warehouse']
  const result = fieldsToRemove.reduce((acc, field) => dissoc(field, acc), data)
  const driverId = path(['driverId', 'id'], result)
  const agentId = path(['agentId', 'id'], data)
  const priceLevelId = path(['priceLevelId', 'id'], result)
  const warehouseId = path(['warehouseId', 'id'], result)
  const orderId = path(['orderId', 'id'], result)
  const contactId = path(['contactId', 'id'], result)
  const territoryId = path(['territoryId', 'id'], result)
  const returnDate = prop('returnDate', result)
  const visitDate = prop('visitDate', result)
  const shippingAddressId = path(['shippingAddressId', 'value'], result)
  const oldItems = propOr([], 'items', result) as any
  const items = oldItems.map((item: any) => {
    const newItem = omit(['product'], item)
    return { ...newItem }
  })
  const addressId = pathOr('', ['addressId', 'id'], data)
  return {
    ...result,
    agentId,
    driverId,
    priceLevelId,
    warehouseId,
    returnDate: toPayloadDate(returnDate),
    visitDate: toPayloadDate(visitDate),
    shippingAddressId,
    items,
    contactId,
    orderId,
    territoryId,
    addressId
  }
}

export const SaleReturnCopyCreateSerializer = (data: any) => {
  const warehouseId = path(['warehouseId', 'id'], data)
  const contactId = path(['contactId', 'id'], data)
  const priceLevelId = path(['priceLevelId', 'id'], data)
  const agentId = path(['agentId', 'id'], data)
  const orderId = path(['orderId', 'id'], data)
  const territoryId = path(['territoryId', 'id'], data)
  const returnDate = prop('returnDate', data)
  const visitDate = prop('visitDate', data)
  const oldItems = propOr([], 'items', data) as any
  const items = oldItems.map((item: any) => {
    const productId = path(['product', 'id'], item)
    const newItem = omit(['product'], item)
    return { ...newItem, productId }
  })
  const addressId = pathOr('', ['addressId', 'id'], data)
  const driverId = path(['driverId', 'id'], data)
  const result = omit(['address', 'agent', 'contact', 'contact', 'priceLevel', 'territory'])
  return {
    ...result,
    priceLevelId,
    agentId,
    warehouseId,
    returnDate: toPayloadDate(returnDate),
    visitDate: toPayloadDate(visitDate),
    items,
    orderId,
    contactId,
    territoryId,
    addressId,
    driverId
  }
}

export const SaleReturnCopyInitialSerializer = (data: any) => {
  const priceLevelId = prop('priceLevel', data)
  const orderNumber = prop('soNumber', data)
  const id = prop('id', data)
  const orderId = { value: orderNumber, name: orderNumber, id }
  const warehouseId = prop('warehouseGroup', data)
  const territoryId = prop('territory', data)
  const contactId = prop('contact', data)
  return {
    ...data,
    orderId,
    warehouseId,
    priceLevelId,
    territoryId,
    contactId
  }
}
