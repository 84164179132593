import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { updateCategory, getCategoryDetail } from '../api'
import CategoryUpdateForm from '../components/CategoryUpdateForm'
import { categoryDetailSerializer, categoryUpdateSerializer } from '../serializer'

function CategoryUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const categoryUpdate = usePut(updateCategory)
  const categoryDetail = useGetDetail(getCategoryDetail, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    return categoryUpdate.putData({ data: categoryUpdateSerializer(values) })
      .then(() => snackbar({ message: 'Продуктовая корзина успешно обновлена' }))
      .then(() => navigate(ROUTES.CATALOG_CATEGORY_LIST))
  }, [])

  const initialValues = useMemo(() => categoryDetailSerializer(categoryDetail.result), [categoryDetail])

  return (
    <AppLayout>
      {!categoryDetail.loading && (
        <CategoryUpdateForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        />
      )}
      {categoryDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default CategoryUpdateContainer
