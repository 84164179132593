import * as ROUTES from 'constants/Routes'

import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePost } from 'hooks/usePost'
import { path, prop } from 'ramda'

import { warehouseAddAssignee } from '../api'
import WarehouseBulkForm from '../components/WarehouseBulkForm'

function WarehouseBulkContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()
  const warehouseItems = path(['state', 'selectedItems'], location)
  const categoryUpdate = usePost(warehouseAddAssignee)

  const warehouseNames = useMemo(() => {
    return warehouseItems.map((item: { id:number, name:string}) => item.name).join(', ')
  }, [warehouseItems])

  const warehouseIds = useMemo(() => {
    return warehouseItems.map((item: { id:number, name:string}) => item.id)
  }, [warehouseItems])

  const handleSubmit = useCallback((values: any) => {
    const getAssignee = prop('assigneesIds', values)
    const assigneeIds = getAssignee && getAssignee.map((item:any) => item.id)
    return categoryUpdate.postData({ query: { warehouseIds, assigneeIds } })
      .then(() => snackbar({ message: 'Прекрепит успешно добавлен' }))
      .then(() => navigate(ROUTES.WAREHOUSE_LIST))
  }, [])

  return (
    <AppLayout>
      <WarehouseBulkForm
        initialValues={{}}
        onSubmit={handleSubmit}
        warehouseNames={warehouseNames}
        enableReinitialize={true}
      />
    </AppLayout>
  )
}

export default WarehouseBulkContainer
