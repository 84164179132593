import * as React from 'react'
import Box from '@mui/material/Box'
import MUIStepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  step:{
    '& .MuiStepIcon-root':{
      '&.Mui-active':{
        color:'#202020'
      },
      '&.Mui-completed':{
        color:'#202020'
      }
    }
  }
})
interface Props {
  readonly activeStep:number
  readonly steps:any
  readonly setActiveStep:(activeStep:number) => void
}

function Stepper ({ activeStep, steps = [] }:Props) {
  const classes = useStyles()
  return (
    <Box sx={{ width: '100%' }}>
      <MUIStepper activeStep={activeStep}>
        {steps.map((step:any) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          return (
            <Step
              key={step.id}
              className={classes.step}
              {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          )
        })}
      </MUIStepper>
    </Box>
  )
}

export default Stepper
