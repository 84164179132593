import React, { useCallback, useEffect, useState } from 'react'
import { Box, Card, CardContent, Divider, Grid } from '@mui/material'
import { prop } from 'ramda'
import { usePost } from 'hooks/usePost'
import CardHeader from 'components/card/CardHeader'
import Dropzone from 'components/upload/Dropzone'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import { useField } from 'formik'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom'

import { uploadFiles, deleteUploadFile, taskUploadVideo, taskRemoveVideo } from '../../../api'
import { useDelete } from '../../../../../../hooks/useDelete'
import { useSnackbar } from '../../../../../../context/snackbar'

interface Props {
  readonly name:string
}

function VideoUploadField ({ name }:Props) {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const uploadTaskVideo = usePost(taskUploadVideo)
  const deleteTaskVideo = useDelete(taskRemoveVideo)
  const fileUpload = usePost(uploadFiles)
  const visitDeleteFile = usePost(deleteUploadFile)
  const attachmentsField = useField(name)
  const attachmentFieldValues = attachmentsField[0].value || []
  const isError = attachmentsField[1].error
  const attachmentSetFieldValues = attachmentsField && attachmentsField[2]
  const [files, setFiles] = useState(attachmentFieldValues as any[])

  const onFileSet = (value: any) => {
    setFiles((prev: any) => [...prev, value])
  }

  useEffect(() => {
    attachmentSetFieldValues.setValue(files)
  }, [files])

  const onFileDelete = (id: any) => {
    setFiles((prev: any) => prev.filter((img: any) => img.id !== id))
  }
  const onDrop = useCallback((values: any = []) => {
    const data = new FormData()
    values.forEach((file: any) => {
      data.append('uploadFile', file)
    })
    fileUpload.postData({ data }).then((response) => {
      uploadTaskVideo.postData({ data: { id, attachments:[response] } })
        .then(() => onFileSet(response))
        .then(() => snackbar({ message:'Видео успешно загружено' }))
    })
  }, [])

  const handleDelete = useCallback((attachmentId: number) => {
    visitDeleteFile.postData({ params: { id:attachmentId } }).then(() => {
      deleteTaskVideo.deleteData({ data: { id, attachmentId } })
        .then(() => snackbar({ message:'Видео успешно удалена' }))
        .then(() => onFileDelete(attachmentId))
    })
  }, [])

  return (
    <Card>
      <CardHeader title="Добавить видео для посещения" />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={2}>
            <Dropzone onDrop={onDrop} />
            {Boolean(isError) && <Typography color="error" py={1}>{isError}</Typography>}
          </Grid>
          {fileUpload.loading && (
            <Grid item={true} lg={2}>Loading...</Grid>
          )}
          {files?.map((item: any) => {
            const link = prop('link', item)
            const id = prop('id', item)
            const originalName = prop('originalName', item)
            return (
              <Grid item={true} lg={2} sx={{ position: 'relative' }}>
                <span
                  style={{
                    position: 'absolute',
                    fontSize: 25,
                    color: 'white',
                    cursor: 'pointer',
                    right:'45px',
                    top:'35px'
                  }}
                  onClick={() => handleDelete(id)}
                >
                  <DeleteIcon />
                </span>
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'flex-end',
                    justifyContent:'center',
                    width:'200px',
                    height:'200px',
                    background:'#F8F8F8',
                    borderRadius:'8px 8px 0 0',
                  }}
                >
                  <img
                    src={link}
                    alt={originalName}
                    style={{
                      height: 150,
                      width:150,
                      borderRadius:'8px 8px 0 0',
                      objectFit: 'cover',
                      cursor:'pointer'
                    }}
                  />
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default VideoUploadField
