import { useCallback, useState } from 'react'
import { prop } from 'ramda'

import { GetListResponse } from '../api/BaseDto'
import { Options } from '../api/BaseApi'
import useAllSearchParams from '../utils/useAllSearchParams'

export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_PAGE_NUMBER = 0

export const usePostList = <T>(api: (options?: Options) => Promise<GetListResponse<T>>, listOptions?: Options) => {
  const searchParams = useAllSearchParams()
  const [result, setResult] = useState({} as GetListResponse<T>)
  const [list, setList] = useState([] as T[])
  const [loading, setLoading] = useState(false)

  const getList = useCallback((options?: Options) => {
    const limit = prop('limit', searchParams) || DEFAULT_PAGE_SIZE
    const start = prop('start', searchParams) || DEFAULT_PAGE_NUMBER
    const defaultGetQuery = { data: { start: Number(start), limit: Number(limit), ...listOptions?.data }
    }

    setLoading(true)
    return api({ ...listOptions, ...options, ...defaultGetQuery }).then((response) => {
      setResult(response)
      const list = prop('list', response)
      if (list) {
        setList(response.list)
      } else {
        setList(response.content)
      }
      setLoading(false)
      return response
    }).catch((error) => {
      setLoading(false)
      throw error
    })
  }, [api, searchParams])

  return { getList, result, loading, list }
}
