import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { pathOr, propOr, trim } from 'ramda'
import { useNavigate } from 'react-router'

import { getCommentList } from '../api'
import CommentRowMenu from '../components/CommentRowMenu'

const columns = [
  {
    headerName: 'Комментарий',
    field: 'name',
  },
  {
    headerName: 'Добавлено',
    field: 'createdBy',
    renderCell:(item:any) => pathOr('', ['createdBy', 'name'], item)
  },
  {
    headerName: 'Модифицирован',
    field: 'modifiedBy',
    renderCell:(item:any) => pathOr('', ['modifiedBy', 'name'], item)
  }
]

function VisitCommentListContainer () {
  const navigate = useNavigate()
  const stockHistoryList = useGetList(getCommentList)
  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    stockHistoryList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="История остатков"
        dataRequest={stockHistoryList}
        onAddClick={() => navigate(ROUTES.VISIT_VISIT_COMMENTS_CREATE)}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(item) => (
          <CommentRowMenu item={item} />
        )}
      />
    </AppLayout>
  )
}

export default VisitCommentListContainer
