import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getRentList = (options?: Options) => {
  return getRequest<any>(API.RentList, options)
}

export const getRentDetail = (options?: Options) => {
  return getRequest<any>(API.RentById, options)
}

export const createRent = (options?: Options) => {
  return postRequest<any>(API.RentCreate, options)
}

export const updateRent = (options?: Options) => {
  return putRequest<any>(API.RentUpdate, options)
}

export const deleteRent = (options?: Options) => {
  return deleteRequest<any>(API.RentDelete, options)
}

export const changeRentStatus = (options?: Options) => {
  return patchRequest<any>(API.RentChangeStatus, options)
}

export const getProductList = (options?: Options) => {
  return getRequest<any>(API.ProductDetailList, options)
}
