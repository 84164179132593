import React, { useEffect } from 'react'
import Select from '@mui/material/Select'
import { MenuItem, styled } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import { useField } from 'formik'
import { makeStyles } from '@mui/styles'
import { prop } from 'ramda'

const InputLabelStyled = styled(InputLabel)({
  background: 'white',
  fontSize:'13px',
  lineHeight:'20px'
})

const SelectStyled = styled(Select)({
  borderRadius:'5px',
  '&.Mui-focused fieldset': {
    borderColor: '#1557FF',
  },

  '& .MuiInputBase-input': {
    color: '#202020',
    background: 'white'
  },

  '& ::placeholder': {
    opacity: 1,
    color: '#7c7c7c',
    fontSize: '15px',
    fontWeight: '500',
  }
})

const useStyles = makeStyles({
  menu: {
    '& .MuiPaper-root': {
      '&::before': {
        opacity: '0 !important'
      },
      '& .MuiList-root': {
        padding: '0',
        '& .MuiMenuItem-root' : {
          justifyContent: 'center'
        }
      }
    }
  }
})

function CustomSelectField (props: any) {
  const {
    label,
    variant,
    options,
    name,
    size = 'small',
    disabled,
    onChange,
    className,
    required,
    defaultValue,
    ...defaultProps
  } = props
  const classes = useStyles()
  const [input, meta, field] = useField(name) as any

  useEffect(() => {
    if (defaultValue !== undefined) {
      field.setValue(defaultValue)
    }
  }, [defaultValue, field])

  return (
    <FormControl
      variant={variant}
      className={className}
      error={meta.touched && meta.error}
      sx={{ minWidth: 120, width: '100%' }}
    >
      <InputLabelStyled id={`${name}-label`} size={size}>
        {required ? label + ' *' : label}
      </InputLabelStyled>
      <SelectStyled
        size={size}
        labelId={`${name}-label`}
        id={name}
        displayEmpty={true}
        value={input.value}
        disabled={disabled}
        onChange={(event) => {
          field.setValue(event.target.value)
          onChange && onChange(event.target.value)
        }}
        fullWidth={true}
        MenuProps={{ classes: { root: classes.menu } }}
        autoWidth={true}
        {...defaultProps}
      >
        {options?.map((item: any) => {
          return (
            <MenuItem key={item.id || item.value} value={item}>
              {prop('name', item)}
            </MenuItem>
          )
        })}
      </SelectStyled>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  )
}

export default CustomSelectField
