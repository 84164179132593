import React from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles({
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    },
    '&.warning': {
      background: '#ffcc00',
    },
    '&.error': {
      background: '#EB4C42',
      color: '#fff'
    }
  }
})

interface Props {
  readonly status: string
}

const getTranslatedStatus = (status: string) => {
  if (status === 'SCHEDULED') {
    return 'ЗАПЛАНИРОВАНО'
  }
  if (status === 'PAID') {
    return 'ОПЛАЧЕНО'
  }
  if (status === 'DEPOSITED') {
    return 'ПОЛУЧЕНО'
  }
  if (status === 'CANCELLED') {
    return 'ОТМЕНЕНО'
  }
}

function LineItemsStatusRow ({ status }: Props) {
  const isCompleted = status === 'PAID'
  const isWarning = status === 'DEPOSITED'
  const isError = status === 'CANCELLED'
  const classes = useStyles()
  return (
    <div className={cx(classes.statusIndicator, { 'active': isCompleted, 'warning':isWarning, 'error':isError })}>
      {getTranslatedStatus(status)}
    </div>
  )
}

export default LineItemsStatusRow
