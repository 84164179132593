import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getBillingPlanCategoryList = (options?: Options) => {
  return getRequest<any>(API.BillingPlanCategoryList, options)
}

export const billingPLanCategoryCreate = (options?: Options) => {
  return postRequest<any>(API.BillingPlanCategoryCreate, options)
}

export const billingPLanCategoryUpdate = (options?: Options) => {
  return putRequest<any>(API.BillingPlanCategoryUpdate, options)
}

export const billingPLanCategoryGetById = (options?: Options) => {
  return getRequest<any>(API.BillingPlanCategoryDetail, options)
}

export const billingPLanCategoryDelete = (options?: Options) => {
  return deleteRequest<any>(API.BillingPlanCategoryDelete, options)
}
