import * as ROUTES from 'constants/Routes'

import React from 'react'
import { path, prop } from 'ramda'
import { styled, Table, TableBody, TableContainer, TableHead } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { pathParams } from 'path-params'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

const TableRowStyled = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    background: '#0000000A'
  }
})

const useStyles = makeStyles({
  tableRoot:{
    borderRadius:'7px',
    border:'1px solid #E9E9E9'
  },
  tableHeader: {
    background: '#F1F1F1',
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset'
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      boxShadow: '2px 2px 4px rgba(167, 172, 176, 0.1)',
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly stockList:any
}
function ProductStockTable ({ stockList }:Props) {
  const navigate = useNavigate()
  const classes = useStyles()
  const handleClick = (id: number) => {
    const updatedURL = pathParams(ROUTES.FINANCE_PURCHASE_RECEIVE_DETAIL, { id: id })
    navigate(updatedURL)
  }
  return (
    <TableContainer className={classes.tableRoot}>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>
              Склад
            </TableCell>
            <TableCell>
              Текущий запас
            </TableCell>
            <TableCell>
              Количество в наличии
            </TableCell>
            <TableCell>
              Забронированное количество
            </TableCell>
            <TableCell>
              Серийный номер
            </TableCell>
            <TableCell>
              Дата получения
            </TableCell>
          </TableRow>
        </TableHead>
        {stockList.length > 0 ? (
          <TableBody>
            {Array.isArray(stockList) && stockList.map((item: any) => {
              return (
                <TableRowStyled
                  key={item.id}
                  onClick={() => handleClick(item.id)}
                >
                  <TableCell>
                    {path(['warehouse', 'name'], item)}
                  </TableCell>
                  <TableCell>
                    {prop('currentStock', item)}
                  </TableCell>
                  <TableCell>
                    {prop('quantityOnHand', item)}
                  </TableCell>
                  <TableCell>
                    {prop('bookedQuantity', item)}
                  </TableCell>
                  <TableCell>
                    {prop('batchNumber', item)}
                  </TableCell>
                  <TableCell>
                    {prop('receivedDate', item)}
                  </TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        )
          : <TableBody>
            <TableCell>Для этого продукта список запасах пуст.</TableCell>
          </TableBody>
        }
      </Table>
    </TableContainer>
  )
}

export default ProductStockTable
