import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import SearchField from 'components/form/SearchField'
import { prop } from 'ramda'
import { useField } from 'formik'
import { getRequest } from 'api/BaseApi'

const getOptionValue = (option: any) => {
  if (option) {
    const id = prop('id', option)
    const code = prop('code', option)
    const name = prop('name', option)

    return { name, id, code }
  }

  return null
}

function CurrencyField (props: any) {
  const { form } = props
  const [, meta, field] = useField('currencyCode')

  useEffect(() => {
    if (typeof meta.initialValue === 'string') {
      const code = meta.initialValue
      getRequest(API.CurrencyGetByCode, { params :{ code }, data:{ code } })
        .then((response: any) => {
          field.setValue(response)
        })
        .then(() => form.setStatus(true))
    }
  }, [])

  return (
    <SearchField
      label="Валюта"
      name="currencyCode"
      api={API.CurrencySuggestions}
      getOptionValue={getOptionValue}
      primaryKey="code"
      searchText="searchKey"
      {...props}
    />
  )
}

export default CurrencyField
