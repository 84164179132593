import React from 'react'
import { pathOr, prop, propOr } from 'ramda'

import SaleOrderStatusRow from './SaleOrderStatusRow'
import SaleOrderPaymentRow from './SalesOrderPaymentRow'

const translations: { [key: string]: string } = {
  orderDate: 'Дата заказа',
  soNumber: 'Номер заказа',
  contact: 'Клиент',
  warehouseGroup: 'Складская группа',
  status: 'Статус',
  lineItemCount: 'Количество товаров',
  territory: 'Территория',
  paymentMethod: 'Способ оплаты',
  deliveryTerms: 'Условия доставки',
  currency: 'Валюта',
  paymentAmount: 'Сумма платежа',
  priceLevel: 'Ценовая политика'
}

const getTranslatedHeaderName = (name: string): string => {
  return translations[name] || name
}
const getRenderCell = (name: string) => {
  if (name === 'status') {
    return (item: any) => <SaleOrderStatusRow status={item.status} />
  }
  if (name === 'contact') {
    return (item:any) => pathOr('', ['contact', 'name'], item)
  }
  if (name === 'warehouseGroup') {
    return (item:any) => pathOr('', ['warehouseGroup', 'name'], item)
  }
  if (name === 'territory') {
    return (item:any) => pathOr('', ['territory', 'name'], item)
  } else if (name === 'priceLevel') {
    return (item:any) => pathOr('', ['priceLevel', 'name'], item)
  } else if (name === 'paymentMethod') {
    return (item: any) => <SaleOrderPaymentRow status={item.paymentMethod} />
  } else return null
}

export const getColumnsBySetting = (setting: any) => {
  const columns = propOr([], 'columns', setting) as any
  return columns.map((column: any, index: number) => {
    const name = propOr(index, 'name', column) as string
    const freeze = prop('freeze', column)
    const conditions = prop('conditions', column)
    const width = prop('width', column)
    const renderCell = getRenderCell(name)
    return {
      headerName: getTranslatedHeaderName(name),
      leftStick: freeze,
      field: name,
      conditions,
      width,
      renderCell
    }
  })
}
