import * as Yup from 'yup'

export const initialValues = {
  warehouseId:null,
  priceLevelId:null,
  territoryId:null,
  orderId:null,
  contactId:null,
  agentId:null,
  address:{
    countryId:'',
    regionId:'',
    addressLine1:'',
    addressLine2:'',
    landmark:'',
    lat:'',
    lon:''
  }
}
export const validationSchema = Yup.object({
  contactId:Yup.object().required('Требуется клиент'),
  priceLevelId:Yup.object().required('Требуется ценовая политика'),
  driverId:Yup.object().required('Требуется водитель'),
  warehouseId:Yup.object().required('Требуется склад'),
  territoryId:Yup.object().required('Требуется территория'),
})
