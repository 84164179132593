import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import { path, prop, propOr } from 'ramda'
import { useField } from 'formik'
import { getRequest } from 'api/BaseApi'

import AutoSelectField from './AutoSelectField'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)
    const code = prop('code', option)

    return { value, name, id: value, code }
  }

  return null
}

function CurrencyField (props: any) {
  const { form } = props
  const [ priceField, , ] = useField('priceLevelId')
  const [, , currencyHelpers] = useField('currency')
  const priceLevelId = path(['value', 'id'], priceField)

  useEffect(() => {
    if (priceLevelId) {
      getRequest(API.PriceLevelGetById, { params: { id: priceLevelId } })
        .then((response:any) => {
          const code = propOr('', 'currency', response)
          getRequest(API.CurrencyGetByCode, { params:{ code } })
            .then((res) => currencyHelpers.setValue(getOptionValue(res)))
        })
        .then(() => form.setStatus(true))
    }
  }, [priceLevelId])

  return (
    <AutoSelectField
      label="Валюта"
      name="currency"
      disabled={true}
      {...props}
    />
  )
}

export default CurrencyField
