import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'DISCOUNT', name: 'СКИДКА' },
  { value: 'PRODUCT', name: 'ПРОДУКТ' },
]

function ActionTypeField () {
  return (
    <SelectField
      name="actionType"
      label="Тип действия"
      options={OPTIONS}
      data-cy="status"
    />
  )
}

export default ActionTypeField
