import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button'
import withForm from 'components/form/withForm'

import VisitStatusField from './form/VisitStatusField'

interface Props {
  readonly open: boolean
  readonly handleClose: () => void
  readonly loading: boolean
  readonly form: any
}

function ChangeStatusDialogue ({ open, handleClose, loading, form }: Props) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Выбирите статус</DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={3} mt={1}>
          <Grid item={true} xs={12} sm={6} md={4} lg={12}>
            <VisitStatusField />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding:'16px 24px' }}>
        <Button onClick={handleClose} variant="outlined" fullWidth={true}>
          Отменить
        </Button>
        <Button onClick={form.handleSubmit} variant="contained" fullWidth={true} disabled={loading}>
          Подтвердить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withForm(ChangeStatusDialogue)
