export const formatPhoneNumber = (value: string): string => {
  if (!value) return ''

  let formattedValue = value.startsWith('+') ? value : `+${value}`

  formattedValue = formattedValue.replace(/\s+/g, '')

  formattedValue = formattedValue.replace(
    /(\+\d{3})(\d{2})(\d{7})/,
    '$1 $2 $3'
  )

  return formattedValue
}
