import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { useField } from 'formik'
import { path } from 'ramda'
import Typography from '@mui/material/Typography'
import { Button, Grid, IconButton, Tooltip } from '@mui/material'
import cx from 'classnames'
import FlexBox from 'components/main/FlexBox'
import FilterIcon from 'assets/filter-icon.svg'
import TextField from 'components/form/TextField'
import SaveIcon from 'assets/save-icon.svg'
import withForm from 'components/form/withForm'
import RefreshIcon from 'assets/refresh-icon.svg'

import FilterFieldVisibility from './form/FilterFieldVisibility'
import FilterFieldRoles from './form/FilterFieldRoles'
import FilterFieldUsers from './form/FilterFieldUsers'
import { FilterQueryPatternField } from './form/FilterQueryPatternField'
import { Filters } from './Filters'
import { FilterParameters } from './FilterParameters'
import { CriteriaFields } from './CriteriaFilters'

export const useStyles = makeStyles({
  header: {
    padding: '17px 20px',
    background: 'rgba(253, 253, 253, 0.5)',
    backdropFilter: 'blur(15px)',
    borderBottom:'1px solid #f1f1f1'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-between'
  },
  wrapper:{
    width:'800px',
    background:'#fff',
    minHeight: 'calc(100vh - 60px)'
  },
  addButton: {
    cursor: 'pointer'
  },
  searchBox:{
    padding:'20px',
    borderBottom:'1px solid #f1f1f1'
  },
  columnBox:{
    padding:'20px',
    height: 'calc(100vh - 270px)',
    overflowY:'auto'
  },
  menuItem:{
    padding:'10px 20px',
    '&:hover':{
      background:'#F6F8FD'
    }
  },
  actions:{
    padding:'20px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between'
  },
  button: {
    fontSize: '13px',
    display: 'flex',
    alignItems:'center',
    gap:'5px',
    height: '40px',
    borderRadius: '5px',
    padding: '9px 15px',
    textTransform:'capitalize',
    '&.Mui-disabled': {
      background: '#202020 !important',
      opacity: '0.5'
    }
  }
})

interface Props {
  readonly onBack:any
  readonly availableColumns:any
  readonly filterList:any
  readonly onDelete:any
  readonly onFilter:any
  readonly form:any,
  readonly onFilterPrev:any
  readonly filter:any
  readonly setIsNewFilter:(value:boolean) => void,
  readonly isHasFilterPreview:boolean
}

const FilterCreateForm = ({
  availableColumns,
  filterList,
  onDelete,
  onFilter,
  form,
  onFilterPrev,
  filter,
  setIsNewFilter,
  onBack,
  isHasFilterPreview
}:Props) => {
  const classes = useStyles()
  const visibilityField = useField('visibleTo')
  const visibilityValue = path(['0', 'value'], visibilityField)
  const isUserAndRoles = visibilityValue === 'SELECTED_USERS_OR_ROLES'
  const activeFilter = path(['values', 'id'], form)
  const name = path(['values', 'name'], form)
  const isDefaultFilter = name === 'Все'

  useEffect(() => {
    form.resetForm({ values: filter })
  }, [filter])

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <FlexBox align="center">
            <img src={FilterIcon} alt="AddIcon" />
            <Typography sx={{ marginLeft: '10px' }}>
              Фильтр
            </Typography>
          </FlexBox>
          <FlexBox align="center" gap={5}>
            <Tooltip title="Сбросить параметры">
              <IconButton onClick={() => setIsNewFilter(true)}>
                <img src={RefreshIcon} alt="refresh" />
              </IconButton>
            </Tooltip>
            <FilterParameters
              filterList={availableColumns}
            />
            <Filters
              activeFilter={activeFilter}
              filterList={filterList}
              onDelete={onDelete}
              onFilter={onFilter}
              onFilterPrev={onFilterPrev}
            />
          </FlexBox>
        </div>
        <Grid container={true} spacing={1} pt={2}>
          <Grid item={true} lg={9}>
            <TextField
              disabled={isDefaultFilter}
              label="Название фильтра"
              placeholder="Название фильтра"
              name="name"
              size="small"
              inputVariant="outlined"
            />
          </Grid>
          <Grid item={true} lg={3}>
            <Button
              type="submit"
              className={cx(classes.button, { 'disabled': '' })}
              fullWidth={true}
              disabled={isDefaultFilter}
            >
              <img src={SaveIcon} alt="save-icon" />
              Сохранить фильтр
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className={classes.columnBox}>
        <Grid container={true} spacing={2}>
          <Grid item={true} lg={6}>
            <FilterFieldVisibility />
          </Grid>
          {isUserAndRoles && (
            <>
              <Grid item={true} lg={6}>
                <FilterFieldRoles />
              </Grid>
              <Grid item={true} lg={6}>
                <FilterFieldUsers />
              </Grid>
            </>
          )}
          <Grid item={true} lg={12}>
            <CriteriaFields />
          </Grid>
          <Grid item lg={12}>
            <Grid item lg={8}>
              <FilterQueryPatternField
                label="Шаблон запроса"
                name="queryPattern"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.actions}>
        <Button
          color="secondary"
          variant="contained"
          className={classes.button}
          onClick={onBack}
        >
          Закрыть
        </Button>
        <FlexBox align="center" gap="8px">
          <Button
            disabled={!isHasFilterPreview}
            color="secondary"
            variant="contained"
            className={classes.button}
            onClick={() => {
              setIsNewFilter(false)
              onFilterPrev([])
            }}
          >
            Отменить
          </Button>
          <Button
            type="button"
            className={classes.button}
            onClick={() => onFilterPrev(form.values)}
          >
            Отфильтровать
          </Button>
        </FlexBox>
      </div>
    </div>
  )
}

export default withForm(FilterCreateForm)
