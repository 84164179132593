import React from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid, IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { FieldArray } from 'formik'
import CardHeader from 'components/card/CardHeader'
import SkeletonList from 'components/skeleton/SkeletonList'
import TextField from 'components/form/TextField'
import { useTranslationI18n } from 'i18n/I18nContext'
import { pathOr } from 'ramda'
import { makeStyles } from '@mui/styles'
import { DeleteIcon } from 'components/icons/DeleteIcon'

import OrderField from './OrderField'
import AccountField from './AccountField'

const useStyles = makeStyles({
  tableRoot:{
    borderRadius:'7px',
    border:'1px solid #E9E9E9'
  },
  tableHeader: {
    background: '#F1F1F1',
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset'
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      boxShadow: '2px 2px 4px rgba(167, 172, 176, 0.1)',
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly loading: boolean
}

function PaymentLineItems ({ loading }: Props) {
  const classes = useStyles()
  const { t } = useTranslationI18n()
  return (
    <Card>
      <CardHeader title="Оплаты за" />
      <CardContent>
        <FieldArray name="lineItems">
          {({ form, push, remove }: any) => {
            const lineItems = pathOr([], ['values', 'lineItems'], form)
            return (
              <Grid container={true} spacing={3}>
                <Grid item={true} lg={12}>
                  <TableContainer className={classes.tableRoot}>
                    <Table size="small">
                      <TableHead className={classes.tableHeader}>
                        <TableRow>
                          <TableCell width="20%">{t('Заказа')}</TableCell>
                          <TableCell width="20%">{t('Надлежающая сумма')}</TableCell>
                          <TableCell width="20%">{t('Сумма к оплате')}</TableCell>
                          <TableCell width="20%">{t('Аккаунт получателя')}</TableCell>
                          <TableCell width="20%">{t('Примечание')}</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBody}>
                        {loading && <SkeletonList columnCount={7} />}
                        {lineItems.map((item: any, index: number) => {
                          return (
                            <TableRow>
                              <TableCell>
                                <OrderField name={`items[${index}].order`} placeholder="Заказ" />
                              </TableCell>
                              <TableCell>
                                <TextField name={`items[${index}].dueAmount`} type="number" placeholder="100" />
                              </TableCell>
                              <TableCell>
                                <TextField name={`items[${index}].paymentAmount`} type="number" placeholder="100" />
                              </TableCell>
                              <TableCell>
                                <AccountField name={`items[${index}].account`} placeholder="Аккаунт" />
                              </TableCell>
                              <TableCell>
                                <TextField name={`items[${index}].notes`} placeholder="Примечание" />
                              </TableCell>
                              <TableCell align="right">
                                <IconButton onClick={() => remove(index)} size="large">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item={true} lg={12}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} lg={10} />
                    <Grid item={true} lg={2}>
                      <Button type="button" fullWidth={true} onClick={() => push({})}>Добавить</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          }}
        </FieldArray>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default PaymentLineItems
