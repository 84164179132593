import React from 'react'
import withForm from 'components/form/withForm'
import FlexBox from 'components/main/FlexBox'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import CompanySignUpBanner from 'assets/company-banner.svg'
import Typography from '@mui/material/Typography'
import SelectIcon from 'components/icons/SelectIcon'
import { Button } from '@mui/material'
import TextFieldWithLabel from 'components/form/TextFieldWithLabel'
import { path } from 'ramda'

import { PasswordField } from './form/PasswordField'

const useStyles = makeStyles({
  root:{
    background:'#F5F5F5',
    minHeight:'100vh'
  },
  formWrapper:{
    width:'45%',
    height:'100%',
    padding:'20px 40px'
  },
  formTitle:{
    '& .selectStroke':{
      stroke:'#1557FF',
      transition:'all ease-in 0.3s',
    },
  },
  imageWrapper:{
    width:'55%',
    height:'100%',
    borderTopRightRadius:'16px',
    borderBottomRightRadius:'16px',
    '& img':{
      width:'100%',
      height:'100%',
      borderRadius:'0 16px 16px 0'
    }
  }
})

const CompanyCreateForm = ({ form }:any) => {
  const classes = useStyles()
  const name = path(['values', 'companyName'], form)

  return (
    <FlexBox
      align="center"
      justify="center"
      className={classes.root}
    >
      <Box sx={{
        width:'916px',
        height:'732px',
        borderRadius:'16px',
        background:'#fff'
      }}>
        <FlexBox style={{ height:'100%' }}>
          <Box className={classes.formWrapper}>
            <Box mb={6}>
              <FlexBox align="center" className={classes.formTitle} gap={20}>
                <SelectIcon />
                <Typography variant="h5">
                  {name || 'Моя компания'}
                </Typography>
              </FlexBox>
              <Typography variant="body2" sx={{ fontSize:'15px', lineHeight:'18.15px' }}>
                Самое время настроить Вашу компанию
              </Typography>
            </Box>
            <FlexBox align="flex-start" justify="space-around" direction="column" gap={18}>
              <TextFieldWithLabel
                label="Название компании *"
                name="companyName"
                placeholder="AIF LLC"
              />
              <TextFieldWithLabel
                label="Электронная почта *"
                name="companyEmail"
                placeholder="example@company.com"
              />
              <TextFieldWithLabel
                label="Номер телефона *"
                name="companyPhone"
                placeholder="AIF LLC"
              />
              <TextFieldWithLabel
                label="Код страны *"
                name="countryCode"
                placeholder="UZ"
              />
              <PasswordField
                autoComplete="current-password"
                label="Придумайте пароль"
                name="password"
                placeholder="*********"
                data-cy="password"
                strength={true}
              />
              <PasswordField
                autoComplete="current-password"
                label="Еще раз введите пароль"
                name="passwordCheck"
                placeholder="*********"
                data-cy="password"
                strength={false}
              />
              <FlexBox direction="column" align="center" gap={5} flex={true} style={{ width:'100%' }}>
                <Button type="submit" fullWidth={true}>
                  Создать
                </Button>
                <Typography variant="body2" sx={{ fontSize:'14px', lineHeight:'20px' }}>
                  Нажав «Создать», вы принимаете
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color:'#1557FF',
                    cursor:'pointer',
                    fontSize:'14px',
                    lineHeight:'20px'
                  }}
                >
                  Пользовательское соглашение
                </Typography>
              </FlexBox>
            </FlexBox>
          </Box>
          <Box className={classes.imageWrapper}>
            <img src={CompanySignUpBanner}
              alt="Company banner" />
          </Box>
        </FlexBox>
      </Box>
    </FlexBox>
  )
}

export default withForm(CompanyCreateForm)
