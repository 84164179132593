import React, { useEffect, useState } from 'react'
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import FlexBox from 'components/main/FlexBox'
import { prop } from 'ramda'

import { tableStyles } from './styles/TableStyles'

import useAllSearchParams from '../../utils/useAllSearchParams'
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from '../../hooks/useGetList'

interface TableListProps {
  readonly headerName: string
  readonly sortable?: boolean
  readonly field: string
  readonly width?: number | string
  readonly valueGetter?: any
  readonly renderCell?: any
}

interface Props {
  readonly columns: TableListProps[]
  readonly className?: string
  readonly list: any
  readonly listGet: any
  readonly loading: boolean
}

function DetailTable (props: Props) {
  const {
    columns,
    className,
    list,
    listGet,
    loading
  } = props
  const searchParams = useAllSearchParams()

  const [limit, setLimit] = useState(10)
  const [start, setStart] = useState(0)
  const page = start ? start === 10 ? 2 : start / limit : 1

  useEffect(() => {
    const paramsLimit = prop('limit', searchParams) || DEFAULT_PAGE_SIZE
    const paramsStart = prop('start', searchParams) || DEFAULT_PAGE_NUMBER
    setLimit(Number(paramsLimit))
    setStart(Number(paramsStart))
  }, [searchParams])

  const classes = tableStyles()

  useEffect(() => {
    if (page && limit) {
      listGet && listGet({ query: { start, limit } })
    }
  }, [start, limit, page])

  return (
    <>
      <FlexBox className={className} direction="column" flex>
        <TableContainer className={classes.tableRoot} component={Paper}>
          <MuiTable sx={{ width: '100%' }} aria-label="simple table">
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={Array.isArray(column.field) ? column.field[1] : column.field} width={column.width}>
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {!loading && list.map((item: any, index: number) => (
                <TableRow key={item.id}>
                  {columns.map((column, columnIndex) => {
                    const columnValue = prop(column.field, item)
                    const valueGetter = prop('valueGetter', column)
                    const renderCell = prop('renderCell', column)

                    const rowValue = valueGetter ? valueGetter(columnValue) : columnValue
                    const tableCell = renderCell ? renderCell(item) : rowValue
                    return (
                      <TableCell
                        key={Array.isArray(column.field) ? column.field[1] : column.field}
                        width={column.width}
                        data-cy={`table-column-${columnIndex}-row-${index}`}
                      >
                        {tableCell}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
              {loading && (
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={Array.isArray(column.field) ? column.field[1] : column.field}>
                      <Skeleton
                        animation="wave"
                        height={30}
                        width="80%"
                        style={{ marginBottom: 5, marginTop: 5 }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              )}
              {!loading && list.length === 0 && (
                <TableRow>
                  <TableCell
                    height={50}
                    colSpan={columns.length}
                    sx={{ textAlign: 'center' }}
                  >
                    No Information
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </FlexBox>
    </>
  )
}

export default DetailTable
