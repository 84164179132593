import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { useNavigate } from 'react-router-dom'
import { path, propOr } from 'ramda'

import { deleteTaskReturn, getTaskReturnList } from '../api'
import TaskReturnStatusRow from '../components/TaskReturnStatusRow'
import TaskReturnTypeRow from '../components/TaskReturnTypeRow'
import TaskReturnRowMenu from '../components/TaskReturnRowMenu'
import { useDelete } from '../../../../hooks/useDelete'
import { useSnackbar } from '../../../../context/snackbar'

const columns = [
  {
    headerName: 'Название посещение',
    field: 'name'
  },
  {
    headerName: 'Клиент',
    field: 'customer',
    renderCell:path(['customer', 'name'])
  },
  {
    headerName: 'Пользователь',
    field: 'user',
    renderCell:path(['user', 'name'])
  },
  {
    headerName: 'Тип',
    field: 'type',
    renderCell:(item:any) => <TaskReturnTypeRow status={item.type} />
  },
  {
    headerName: 'Територия',
    field: 'territoryIds',
    renderCell: (item: any) =>
      item.territories && item.territories.length && item.territories.map((i: any) => i.name).join(', ')
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell:(item:any) => <TaskReturnStatusRow status={item.status} />
  },
]

function TaskReturnListContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const taskReturnList = useGetList(getTaskReturnList)
  const taskReturnDelete = useDelete(deleteTaskReturn)

  const handleDelete = useCallback((id:number) => {
    taskReturnDelete.deleteData({ params:{ id } })
      .then(() => taskReturnList.getList())
      .then(() => snackbar({ message: 'Повторяющееся таски успешно удалено' }))
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = propOr('', 'search', value)
    taskReturnList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        onAddClick={() => navigate(ROUTES.CRM_RETURN_TASK_CREATE)}
        title="Повторяющееся таски"
        dataRequest={taskReturnList}
        columns={columns}
        onSearchClick={onTableSearch}
        checkbox={true}
        actions={(row) => (
          <TaskReturnRowMenu
            item={row}
            onDelete={handleDelete}
          />
        )}
      />
    </AppLayout>
  )
}

export default TaskReturnListContainer
