import * as ROUTES from 'constants/Routes'

import { MenuItem } from '@mui/material'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props{
  readonly selectedRows:any
  readonly handleDeleteSelected:() => void
}
function TableActionsRow ({ selectedRows, handleDeleteSelected }:Props) {
  const navigate = useNavigate()

  const selectedItems = useMemo(() => {
    return selectedRows.map((row: { id: number, warehouseName:string }) => {
      return { id:row.id, name:row.warehouseName }
    })
  }, [selectedRows])

  return (
    <>
      <MenuItem onClick={() => navigate(ROUTES.WAREHOUSE_BULK, { state:{ selectedItems } })}>
        Прекрепить выбранный склад
      </MenuItem>
      <MenuItem onClick={handleDeleteSelected}>
        Удалить выбранное
      </MenuItem>
    </>
  )
}

export default TableActionsRow
