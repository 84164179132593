import React from 'react'
import { prop } from 'ramda'
import TextField from 'components/form/TextField'
import DateField from 'components/form/DateField'

import { FILTER_FIELD_TYPE } from '../../../constants'
import ClientField from '../form/ClientField'
import WarehouseGroupField from '../form/WarehouseGroupField'
import TerritoryField from '../form/TerritoryField'
import OrderStatusField from '../form/OrderStatusField'
import PriceLevelField from '../form/PriceLevelField'
import CurrencyField from '../form/CurrencyField'

const getFieldByType = (fieldType: string, lookUpType:string, name:string, rest: any) => {
  if (fieldType === 'LOOKUP' && lookUpType === 'CONTACT') {
    return <ClientField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'WAREHOUSE_GROUP') {
    return <WarehouseGroupField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'TERRITORY') {
    return <TerritoryField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'ORDER_STATUS') {
    return <OrderStatusField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'PRICE_LEVEL') {
    return <PriceLevelField {...rest} />
  }
  if (fieldType === 'LOOKUP' && lookUpType === 'CURRENCY') {
    return <CurrencyField {...rest} />
  }
  if (fieldType === FILTER_FIELD_TYPE.DATE_TIME_PICKER) {
    return (
      <DateField
        label="Дата"
        {...rest}
      />
    )
  }
  if (fieldType === FILTER_FIELD_TYPE.NUMBER_BOX) {
    return (
      <TextField
        label="Ценить"
        type="number"
        {...rest}
      />
    )
  }

  return (
    <TextField
      label="Значение поля"
      {...rest}
    />
  )
}

export const ComparatorValueFields = (props: any) => {
  const { item, ...rest } = props
  const fieldType = prop('fieldType', item)
  const lookUpType = prop('lookUpType', item)
  const name = prop('name', item)
  return getFieldByType(fieldType, lookUpType, name, rest)
}
