import TableAttach from 'components/table-attach/TableAttach'
import FlexBox from 'components/main/FlexBox'
import { Button, ButtonGroup } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetList } from 'hooks/useGetList'

import {
  addTerritoriesToCategory,
  getTerritoryList
} from '../../api'
import TerritoryTypeRow from '../../../../settings/territory/components/TerritoryStatusRow'

enum PRODUCT_TABS {
  AVAILABLE='available',
  SELECTED='selected'
}

const tabs = [
  { id:1, value:PRODUCT_TABS.AVAILABLE, label:'Прекрепление' },
  { id:2, value:PRODUCT_TABS.SELECTED, label:'Доступние' }
]

const columns = [
  {
    headerName: 'ID',
    field: 'id',
    width: 150
  },
  {
    headerName: 'Название территории',
    field: 'territoryName',
    width: 500
  },
  {
    headerName: 'Тип территории',
    field: 'territoryType',
    renderCell:(item:any) => <TerritoryTypeRow territoryType={item.territoryType} />
  },
  {
    headerName: 'Дата создания',
    field: 'createdDate',
  },
  {
    headerName: 'Дата обновления',
    field: 'updatedDate',
  },
]

interface Props {
  readonly categoryIds:number[]
  readonly categoryItems:any[]
}

export function TerritoryBulkTable ({ categoryIds, categoryItems }:Props) {
  const snackbar = useSnackbar()
  const categoryAddTerritories = usePut(addTerritoriesToCategory)
  const territoryList = useGetList(getTerritoryList)
  const [selectedProducts, setSelectedProducts] = useState([]) as any
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)
  const isAvailable = selectedTab === PRODUCT_TABS.AVAILABLE
  const dataRequest = isAvailable ? selectedProducts : territoryList

  const territoryIds = useMemo(() => {
    return selectedRows.map((territory:{ id: number }) => territory.id)
  }, [selectedRows])

  const onSetTab = (tab:any) => {
    setSelectedTab(tab.value)
  }

  const onSetTerritories = () => {
    setSelectedProducts((prevSelected: any) => Array.from(new Set([...prevSelected, ...selectedRows])))
    setSelectedRows([])
    setSelectedTab(PRODUCT_TABS.SELECTED)
  }

  const onRemoveSelected = useCallback(() => {
    const filteredSelectedProducts = selectedProducts.filter((product:any) => {
      return !selectedRows.some((row:any) => row.id === product.id)
    })
    setSelectedProducts(filteredSelectedProducts)
    setSelectedRows([])
  }, [selectedRows])

  const onAddTerritories = useCallback(() => {
    categoryAddTerritories.putData({ data:{ categoryIds, territoryIds } })
      .then(() => snackbar({ message: 'Выбранные территории успешно добавлены продуктовую корзину' }))
  }, [territoryIds])

  return (
    <TableAttach
      title={
        <FlexBox align="center" gap={10}>
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            {tabs.map((tab) => (
              <Button
                variant={selectedTab === tab.value ? 'contained' : 'outlined'}
                onClick={() => onSetTab(tab)}
              >
                {tab.label}
              </Button>)
            )}
          </ButtonGroup>
          {isAvailable && selectedRows.length > 0 && (
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                onRemoveSelected()
                onAddTerritories()
              }}
            >
              Открепить {selectedRows.length}
            </Button>
          )}
          {!isAvailable && selectedRows.length > 0 && (
            <Button
              onClick={() => {
                onSetTerritories()
                onAddTerritories()
              }}
            >
              Прекрепить {selectedRows.length}
            </Button>
          )}
        </FlexBox>
      }
      dataRequest={dataRequest}
      hasChange={isAvailable}
      stateItems={categoryItems}
      selectedList={selectedProducts}
      columns={columns}
      onSearchClick={() => null}
      tabs={['Все', 'Активы', 'Неактивы']}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      checkbox={true}
    />
  )
}
