import React from 'react'
import { path, pathOr, prop, propOr } from 'ramda'

import ShipmentStatusRow from './ShipmentStatusRow'

const translations: { [key: string]: string } = {
  number: 'Номер отслеживания доставки',
  contact: 'Клиент',
  status: 'Статус',
  warehouse: 'Склад',
  packer: 'Упаковщик',
  picker: 'Сборщик',
  driver: 'Водитель',
  order: 'Номер заказа',
  salesReturn: 'Номер возврата продаж',
  deliveryDate: 'Дата доставки',
  currency: 'Валюта',
  payment: 'Оплата'
}

const getTranslatedHeaderName = (name: string): string => {
  return translations[name] || name
}

const getRenderCell = (name: string) => {
  if (name === 'status') {
    return (item:any) => <ShipmentStatusRow status={item.status} />
  }
  if (name === 'packer') {
    return (item:any) => pathOr('', ['packer', 'name'], item)
  }
  if (name === 'picker') {
    return (item:any) => pathOr('', ['picker', 'name'], item)
  }
  if (name === 'driver') {
    return (item:any) => pathOr('', ['driver', 'name'], item)
  }
  if (name === 'warehouse') {
    return (item:any) => pathOr('', ['warehouse', 'name'], item)
  }
  if (name === 'contact') {
    return (item:any) => pathOr('', ['contact', 'name'], item)
  }
  if (name === 'order') {
    return (item:any) => path(['order', 'name'], item) || path(['salesReturn', 'name'], item)
  }
  if (name === 'salesReturn') {
    return (item:any) => pathOr('', ['salesReturn', 'name'], item)
  } else return null
}

export const getColumnsBySetting = (setting: any) => {
  const columns = propOr([], 'columns', setting) as any
  return columns.map((column: any, index: number) => {
    const name = propOr(index, 'name', column) as string
    const freeze = prop('freeze', column)
    const conditions = prop('conditions', column)
    const width = prop('width', column)
    const renderCell = getRenderCell(name)
    return {
      headerName: getTranslatedHeaderName(name),
      leftStick: freeze,
      field: name,
      conditions,
      width,
      renderCell
    }
  })
}
