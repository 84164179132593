import { convertDistance, getDistance } from 'geolib'

export const calculateDistances = (trackingData:any) => {
  return trackingData.map((track:any) => {
    if (track.length > 1) {
      let distance = 0
      for (let i = 1; i < track.length; i++) {
        distance += getDistance(
          { latitude: track[i - 1].lat, longitude: track[i - 1].lng },
          { latitude: track[i].lat, longitude: track[i].lng }
        )
      }
      return { track, distance: parseFloat(convertDistance(distance, 'km').toFixed(2)) }
    }
    return { track, distance: 0 }
  })
}

export function calculateTotalDistance (coordinates: any) {
  if (!Array.isArray(coordinates)) {
    throw new Error('Coordinates must be an array.')
  }

  if (coordinates.length < 2) {
    return 0
  }

  let totalDistance = 0

  for (let i = 0; i < coordinates.length - 1; i++) {
    const pointA = coordinates[i]
    const pointB = coordinates[i + 1]

    if (!pointA.latitude || !pointA.longitude || !pointB.latitude || !pointB.longitude) {
      throw new Error('Each coordinate must have latitude and longitude properties.')
    }

    totalDistance += getDistance(pointA, pointB)
  }

  return parseFloat(convertDistance(totalDistance, 'km').toFixed(2))
}
