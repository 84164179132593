import { prop } from 'ramda'

export const categoryCreateSerializer = (values:any) => {
  const getCategories = prop('parentCategoryIds', values)
  const parentCategoryIds = getCategories && getCategories.map((category:any) => category.id)
  return {
    ...values,
    parentCategoryIds
  }
}

export const categoryInitialValues = (values:any) => {
  const parentCategoryIds = prop('parentCategory', values)
  return {
    ...values,
    parentCategoryIds
  }
}
