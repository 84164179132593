import React from 'react'

import CustomSelectField from './CustomSelectField'

function PackageSelectField (props:any) {
  const packageLineItemId = props.packageLineItemId

  return (
    <CustomSelectField
      options={packageLineItemId || []}
      data-cy="status"
      {...props}
    />
  )
}

export default PackageSelectField
