import React, { useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import { makeStyles } from '@mui/styles'

import ColumnsContainer from './components/ColumnsContainer'

export const useStyles = makeStyles({
  dialog:{
    '& .MuiPaper-root':{
      maxWidth:'100vw',
      maxHeight:'90vh',
      height:'100%'
    }
  },
  dialogContent: {
    padding: '16px',
    position: 'relative',
  },
  tableRoot: {
    height: '460px',
    borderRadius: '7px',
    border: '1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height: '36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    },
  },
  tableBody: {
    '& .MuiTableCell-root': {
      height: '46px',
      padding: '2px 16px',
      fontSize: '13px',
      lineHeight: '15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color: '#020020',
    },
    '& .MuiTableRow-root': {
      cursor: 'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important',
      },
    },
    '& .MuiTableRow-root:hover': {
      background: '#FAFAFA',
    },
  },
})

interface Props {
  readonly open: boolean
  readonly onClose: () => void
  readonly form:any
}

function ColumnsSettingsDialogue ({ open, onClose, form }: Props) {
  const classes = useStyles()

  const onEscape = useCallback((event: any) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }, [onClose])

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onKeyDown={onEscape}
      className={classes.dialog}
    >
      <ColumnsContainer
        form={form}
        onClose={onClose}
      />
    </Dialog>
  )
}

export default ColumnsSettingsDialogue
