import React, { useEffect, useRef, useState } from 'react'
import { map, pipe, prop, propOr } from 'ramda'
import axios from 'axios'
import TextField from '@mui/material/TextField'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { useField } from 'formik'
import { makeStyles } from '@mui/styles'
import useDeepCompareEffect from 'hooks/useDeepCompareEffect'
import { request } from 'api/BaseApi'
import useDebounce from 'hooks/useDebounce'
import Box from '@mui/material/Box'

const { CancelToken } = axios
const getCancelToken = () => CancelToken.source()

const defaultGetOptionValue = (option:any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value, name, id: value }
  }

  return null
}

const defaultGetOptionLabel = (value: any) => {
  if (value) {
    return propOr('', 'name', value)
  }

  return ''
}

const useStyles = makeStyles({
  autocomplete: {
    height:'40px',
    '& .MuiFormControl-root': {
      height: '100%',
      '& .MuiFormLabel-root': {
        '&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
          transform: 'translate(14px, 9px) scale(1)',
        }
      }
    }
  },
  field: {
    '& .MuiInputBase-root': {
      borderRadius:'5px',
      height: '100%',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#1557FF',
      },
      '& .MuiInputBase-input': {
        fontSize: '13px',
        color: '#202020',
        background: 'white'
      },
      '& ::placeholder': {
        opacity: 1,
        color: '#7c7c7c',
        fontSize: '15px',
        fontWeight: '500',
      }
    }
  },
})

const defaultRenderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: any
) => {
  return (
    <Box
      sx={{
        fontSize:'13px',
        lineHeight:'15.73px',
        color:'#202020',
        [`&.${autocompleteClasses.option}`]: {
          borderBottom:'1px solid #f1f1f1',
          padding:'8px 16px',
          '&:last-child': {
            borderBottom: 'none',
          }
        },
        '&:lastChild':{
          borderBottom:'none'
        }
      }}
      component="li"
      {...props}
    >
      {option.name}
    </Box>
  )
}

function AutoSelectField (props: any) {
  const {
    name,
    disabled,
    InputProps,
    PaperComponent,
    ListboxProps,
    ListboxComponent,
    searchFieldProps,
    disableCloseOnSelect,
    primaryKey = 'id',
    isLoading = false,
    disableClearable = false,
    fullWidth = true,
    size = 'small',
    api,
    params = { limit: 30, start: 0 },
    param,
    searchText = 'searchKey',
    renderOption = defaultRenderOption,
    prepareOptions = (options: any) => options,
    onInputChange = () => null,
    onValueChange = () => null,
    getOptionLabel = defaultGetOptionLabel,
    getOptionValue = defaultGetOptionValue,
    ...defaultProps
  } = props
  const classes = useStyles()
  const [input, meta, helpers] = useField(name)
  const [value, setValue] = useState('')
  const [newValue, setNewVal] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([] as any)
  const [open, setOpen] = useState(false)
  const [searching, setSearching] = useState(false)
  const debouncedValue = useDebounce(value)
  const ref = useRef([]) as any

  useEffect(() => {
    if (input.value) {
      setNewVal(input.value)
      helpers.setValue(input.value)
      onValueChange(input.value)
      setLoading(false)
    }
    if (!input.value) {
      setNewVal({})
    }
  }, [input.value])

  useDeepCompareEffect(() => {
    let active = true

    const { token, cancel } = getCancelToken()
    if (open) {
      setLoading(true)
      onInputChange(debouncedValue)
      const searchValue = searching ? debouncedValue : null
      const query = searchValue ? { [searchText]: searchValue, ...params } : params
      request(api, { query, params:param, cancelToken: token, method: 'get' })
        .then(response => {
          const content = propOr([], 'content', response) as any
          const list = propOr([], 'list', response) as any
          const responseList = Array.isArray(response) ? response : []
          const results = [...content, ...list, ...responseList]
          if (active) {
            const newOptions = pipe(
              prepareOptions,
              map(getOptionValue)
            )(results)
            ref.current = newOptions

            setOptions(newOptions)
            setLoading(false)
          }
        })
    }

    return () => {
      active = false
      cancel()
    }
  }, [open, debouncedValue, searching])

  React.useEffect(() => {
    if (!open) {
      ref.current = []
      setOptions([])
    }
  }, [open])

  const submitError = propOr(null, 'submitError', meta)
  const stringOfSubmitError = submitError ? JSON.stringify(submitError) : ''
  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      className={classes.autocomplete}
      disabled={disabled}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      value={newValue}
      isOptionEqualToValue={(option: any, value) => option[primaryKey] === value[primaryKey]}
      getOptionLabel={getOptionLabel}
      options={options.length === 0 ? [newValue] : options}
      loading={loading}
      filterOptions={item => item}
      onChange={(event, value) => {
        onValueChange(value)
        helpers.setValue(value)
        setSearching(false)
      }}
      PaperComponent={PaperComponent}
      ListboxComponent={ListboxComponent}
      size={size}
      fullWidth={fullWidth}
      ListboxProps={ListboxProps}
      renderOption={renderOption}
      onInputChange={(event, value) => {
        setValue(value)
        setOptions([])
        onInputChange(value)
      }}
      renderInput={params => {
        return (
          <TextField
            className={classes.field}
            error={meta.error}
            helperText={stringOfSubmitError || meta.error}
            onBlur={() => setSearching(false)}
            onKeyDown={() => setSearching(true)}
            {...params}
            {...defaultProps}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: (
                <>
                  {(isLoading || loading) ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )
      }}
      {...searchFieldProps}
    />
  )
}

export default AutoSelectField
