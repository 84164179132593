import { dissoc, omit, path, prop, propOr } from 'ramda'

export const productCreateSerialize = ({ customFields, ...data }: any) => {
  const newSupplierIds = prop('supplierIds', data) as any
  const newCategories = prop('categoryIds', data)
  const supplierIds = newSupplierIds && newSupplierIds.map((ids: any) => prop('value', ids))
  const categoryIds = newCategories && newCategories.map((ids: any) => prop('value', ids))
  const brandId = path(['brandId', 'value'], data)
  const unitMeasurementId = path(['unitMeasurementId', 'value'], data)
  const producedIn = path(['producedIn', 'name'], data)
  const countryId = path(['countryId', 'id'], data)
  const images = propOr([], 'images', data) as any
  const newImages = images.map((img: any) => ({
    original: prop('id', img)
  }))
  const result = omit(['search'], data)
  return {
    ...result,
    supplierIds,
    categoryIds,
    brandId,
    countryId,
    unitMeasurementId,
    producedIn,
    images: newImages,
    customFieldValue: customFields
  }
}

export const productDetailSerializer = (data: any) => {
  const newSupplierIds = prop('suppliers', data) as any
  const newCategories = prop('categories', data)
  const supplierIds = newSupplierIds && newSupplierIds.map((ids: any) => {
    const id = prop('id', ids)
    const name = prop('name', ids)
    return { id, name }
  })
  const categoryIds = newCategories && newCategories.map((ids: any) => {
    const id = prop('id', ids)
    const name = prop('name', ids)
    return { id, name }
  })
  const countryId = prop('country', data)
  const brandId = prop('brand', data)
  const unitMeasurementId = prop('unitMeasurement', data)
  const packageLineItemsProp = propOr([], 'packageLineItems', data) as any
  const customFields = propOr({}, 'customFieldValue', data) as any
  const packageLineItems = packageLineItemsProp.map((item: any) => ({
    name: path(['productPackage', 'name'], item),
    default: prop('default', item),
    packageId: path(['productPackage', 'id'], item),
    productId: path(['product', 'id'], item),
    quantity: propOr(0, 'quantity', item)
  }))
  return {
    ...data,
    countryId,
    checked:false,
    packageLineItems,
    customFields,
    supplierIds,
    categoryIds,
    brandId,
    unitMeasurementId,
  }
}

export const productUpdateSerializer = ({ customFields, ...data }:any) => {
  const fieldsToRemove = ['brand', 'categories', 'suppliers', 'unitMeasurement', 'search', 'country']
  const result = fieldsToRemove.reduce((acc, field) => dissoc(field, acc), data)
  const getDefaultImage = prop('image', data)
  const newSupplierIds = prop('supplierIds', result) as any
  const newCategories = prop('categoryIds', result)
  const supplierIds = newSupplierIds && newSupplierIds.map((ids: any) => prop('id', ids))
  const categoryIds = newCategories && newCategories.map((ids: any) => prop('id', ids))
  const brandId = path(['brandId', 'id'], result)
  const unitMeasurementId = path(['unitMeasurementId', 'id'], result)
  const countryId = path(['countryId', 'id'], result)
  const producedIn = path(['producedIn', 'name'], result)
  const images = propOr([], 'images', result) as any
  const newImages = images.map((img: any) => ({
    id: prop('id', img),
    large: path(['large', 'id'], img),
    original: path(['original', 'id'], img),
    small: path(['small', 'id'], img),
    medium: path(['medium', 'id'], img)
  }))
  const image = images.length === 0 ? null : getDefaultImage
  return {
    ...result,
    image,
    countryId,
    supplierIds,
    categoryIds,
    brandId,
    unitMeasurementId,
    producedIn,
    images: newImages,
    customFieldValue: customFields
  }
}

export const selectedRowSerializer = (data: any) => {
  const productId = data && data.map((product: any) => prop('id', product))
  return [...productId]
}

export const orderFilterSerializer = (values:any) => {
  const result = omit(['availableColumns'], values)
  const getUsers = prop('users', result)
  const users = getUsers && getUsers.map((user:any) => user.id)
  const getRoles = prop('roles', result)
  const roles = getRoles && getRoles.map((role:any) => role.role)
  return { ...result, users, roles }
}

export const priceLevelCreateSerializer = (values: any) => {
  const territories = propOr([], 'territoryIds', values) as any
  const newTerritories = territories.map((territory: any) => territory.id)
  const currencyId = path(['currencyId', 'id'], values)
  return { ...values, territoryIds: newTerritories, currencyId }
}

export const productBulkAddSerializer = ({ productIds, values }:any) => {
  const { reset, ...props } = values
  return {
    reset,
    productIds,
    product:productCreateSerialize(props)
  }
}
