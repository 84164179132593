import { makeStyles } from '@mui/styles'
import {
  Button, Checkbox,
  IconButton, MenuItem,
  Tooltip
} from '@mui/material'
import { prop } from 'ramda'
import React, { useCallback, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import AddIcon from 'assets/add-icon.svg'
import SearchIcon from '@mui/icons-material/Search'
import TabSettingsIcon from 'assets/tab-settings-icon.svg'
import TextField from 'components/form/TextField'
import withForm from 'components/form/withForm'
import FlexBox from 'components/main/FlexBox'
import SaveIcon from 'assets/save-icon.svg'
import { useField } from 'formik'
import useDebounce from 'hooks/useDebounce'
import cx from 'classnames'

import ColumnRowMenu from './ColumnRowMenu'

export const useStyles = makeStyles({
  tableRoot:{
    maxHeight:'460px',
    borderRadius:'7px',
    border:'1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 8px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      cursor:'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA'
    }
  },
  header: {
    padding: '17px 20px',
    background: 'rgba(253, 253, 253, 0.5)',
    backdropFilter: 'blur(15px)'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-between'
  },
  wrapper: {
    width:'400px',
    background:'#fff',
    minHeight: 'calc(100vh - 60px)'
  },
  addButton: {
    cursor: 'pointer'
  },
  searchBox:{
    padding:'20px',
    borderBottom:'1px solid #f1f1f1'
  },
  menuItem:{
    padding:'10px 20px',
    '&:hover':{
      background:'#F1F1F1'
    }
  },
  menuBox:{
    height: 'calc(100vh - 280px)',
    overflowY:'auto',
    '& .MuiMenuItem-root':{
      padding:'6px 16px'
    }
  },
  actions:{
    padding:'20px',
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end'
  },
  button: {
    fontSize: '13px',
    height: '40px',
    borderRadius: '5px',
    padding: '9px 20px',
    textTransform:'capitalize',
    background:'none',
    color:'#1557FF',
    '&:hover': {
      background:'none',
      color:'#1557FF',
    }
  },
  buttonDelete:{
    fontSize: '13px',
    height: '40px',
    borderRadius: '5px',
    padding: '9px 20px',
    textTransform:'capitalize',
  },
  checkboxHeader:{
    color: '#202020',
    width: '18px',
    height: '18px',
    '&.Mui-checked': {
      color: '#202020',
    }
  },
  selectedCount: {
    width: '28px',
    height: '21px',
    borderRadius: '10px',
    background: '#7C7C7C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    margin: 'auto',
    transition: '0.3s',
    '&.active': {
      background: '#202020'
    }
  },
  menuItemBackground:{
    '&.active': {
      borderTopLeftRadius:'10px',
      borderBottomLeftRadius:'10px',
      background: '#F1F1F1'
    }
  }
})

interface Props {
  readonly dataRequest: any
  readonly onUpdate: (id:number) => void
  readonly onCreate: () => void
  readonly onFilter: (item:any) => void
  readonly onDelete: (id:number) => void
  readonly setSelectedRows: (row:any) => void
  readonly selectedRows: any
  readonly onDeleteSelected:() => void
  readonly onClose: (value:boolean) => void
  readonly activeColumnId:number
}

const ColumnListing = ({
  dataRequest,
  onUpdate,
  onCreate,
  onFilter,
  onDelete,
  selectedRows,
  setSelectedRows,
  onDeleteSelected,
  onClose,
  activeColumnId
}:Props) => {
  const classes = useStyles()
  const list = prop('list', dataRequest)
  const search = useField('search')
  const searchValue = search[0].value
  const debouncedValue = useDebounce(searchValue)

  useEffect(() => {
    dataRequest.getList({ query:{ searchKey:debouncedValue } })
  }, [debouncedValue])

  const onClickSelectRow = useCallback((item: any) => {
    const rowPrimaryKey = prop('id', item)
    setSelectedRows((prev: any) => {
      const checkedItem = prev.find((item: any) => prop('id', item) === rowPrimaryKey)
      if (checkedItem) {
        return prev.filter((item: any) => prop('id', item) !== rowPrimaryKey)
      } else {
        return [...prev, item]
      }
    })
  }, [])

  const onCancel = () => {
    onClose(false)
    setSelectedRows([])
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <FlexBox align="center">
            <img src={TabSettingsIcon} alt="AddIcon" />
            <Typography sx={{ marginLeft: '10px' }}>
              Настройка колонок
            </Typography>
          </FlexBox>
          <FlexBox
            justify="center"
            align="center"
            className={classes.addButton}
          >
            <img src={AddIcon} alt="AddIcon" onClick={onCreate} />
          </FlexBox>
        </div>
      </div>
      <div className={classes.searchBox}>
        <TextField
          data-cy="searchVariant"
          name="search"
          placeholder="Поиск колонок"
          size="small"
          variant="outlined"
          autoFocus={true}
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <Tooltip title="Search">
                <IconButton
                  edge="start"
                  size="medium"
                  sx={{ margin: 0, padding: '5px' }}
                >
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            )
          }}
        />
      </div>
      <div className={classes.menuBox}>
        {list && list.map((item:any) => {
          const id = prop('id', item)
          const isSelected = selectedRows.find((item: any) => prop('id', item) === id)
          const isActive = activeColumnId === id
          return (
            <MenuItem key={item.id}>
              <FlexBox
                align="center"
                justify="space-between"
                flex={true}
                className={cx(classes.menuItemBackground, { 'active': isActive })}
              >
                <FlexBox align="center" gap="5px">
                  <Checkbox
                    checked={Boolean(isSelected)}
                    color="secondary"
                    onClick={() => onClickSelectRow(item)}
                  />
                  <ColumnRowMenu
                    item={item}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                  />
                  {item.name}
                </FlexBox>
                <Button
                  variant="text"
                  className={classes.button}
                  onClick={() => onFilter(item)}
                >
                  Применить
                </Button>
              </FlexBox>
            </MenuItem>
          )
        })}
      </div>
      <div className={classes.actions}>
        <FlexBox align="center" gap="8px">
          <Button
            color="secondary"
            variant="contained"
            className={classes.button}
            onClick={onCancel}
          >
            <img src={SaveIcon} alt="save-icon" />
            Отменить
          </Button>
          <Button
            color="error"
            variant="contained"
            disabled={!(selectedRows.length > 0)}
            className={classes.buttonDelete}
            onClick={onDeleteSelected}
          >
            Удалить выбранное
          </Button>
        </FlexBox>
      </div>
    </div>

  )
}

export default withForm(ColumnListing)
