import * as API from 'constants/Api'

import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useField } from 'formik'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import FlexBox from 'components/main/FlexBox'
import useDebounce from 'hooks/useDebounce'
import { getRequest } from 'api/BaseApi'
import { path, propOr } from 'ramda'

const useStyles = makeStyles({
  autocomplete: {
    width:'100%',
    '& .MuiAutocomplete-input':{
      padding:'5px 10px 5px 18px!important'
    },
    '& .MuiAutocomplete-root .MuiOutlinedInput-root':{
      paddingRight:0
    },
  },
  field: {
    '& .MuiInputBase-root': {
      borderRadius: '5px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#1557FF'
      },
      '& .MuiInputBase-input': {
        color: '#202020',
        background: 'white'
      },
      '& ::placeholder': {
        opacity: 1,
        color: '#7c7c7c',
        fontWeight: '500'
      }
    },
  }
})

interface Props {
  name:string,
  disabled?:boolean,
  size?:'medium' | 'small',
  label:string,
  onGenerate:() => void,
  buttonTitle:string
}
const BarcodeField = ({ name, label, disabled, size = 'small', buttonTitle, onGenerate, ...props }: Props) => {
  const [input, meta, helpers] = useField(name)
  const classes = useStyles()
  const [tags, setTags] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')
  const [checkValue, setCheckValue] = useState('')
  const [checkingError, setCheckingError] = useState<string | null>(null)
  const debounceValue = useDebounce(checkValue, 1500)
  const handleInputChange = (event:any, value: string) => {
    setInputValue(value)
    setCheckValue(value)
    if (value.length >= 12) {
      event.preventDefault()
      helpers.setValue([...tags, value.trim()])
      setTags([...tags, value.trim()])
      setInputValue('')
    }
  }

  useEffect(() => {
    if (debounceValue) {
      getRequest(API.ProductCheck, { query: { field:input.name, value:debounceValue } })
        .then((response:any) => {
          const statusCode = path(['response', 'status'], response)
          if (statusCode !== 204) {
            setCheckingError(response)
          } else {
            setCheckingError(null)
          }
        })
    }
  }, [debounceValue])

  const submitError = propOr(null, 'submitError', meta)
  const stringOfSubmitError = submitError ? JSON.stringify(submitError) : ''

  return (
    <FlexBox align="center" gap="8px" flex={true}>
      <Autocomplete
        className={classes.autocomplete}
        multiple
        disabled={disabled}
        options={tags}
        size={size}
        value={input.value || tags}
        onChange={(event, value) => {
          helpers.setValue(value)
        }}
        inputValue={inputValue}
        onInputChange={(event, value) => {
          handleInputChange(event, value)
        }}
        freeSolo
        renderInput={(params) => (
          <TextField
            className={classes.field}
            label={label}
            variant="outlined"
            error={!!meta.error || Boolean(checkingError)}
            helperText={stringOfSubmitError || meta.error || checkingError}
            {...props}
            {...params}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                if (inputValue.trim() !== '') {
                  setTags([...tags, inputValue.trim()])
                  setInputValue('')
                }
              }
            }}
          />
        )}
      />
      <Button type="button" onClick={onGenerate} sx={{ height:'40px', padding:'6px 10px', fontSize:'13px' }}>
        {buttonTitle}
      </Button>
    </FlexBox>

  )
}

export default BarcodeField
