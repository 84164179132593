import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { createApprovalProcess } from '../api'
import ApprovalCreateForm from '../components/ApprovalCreateForm'
import { approverCreateSerializer } from '../serializer'

function ApprovalProcessCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const approverCreate = usePost(createApprovalProcess)

  const handleSubmit = useCallback((values: any) => {
    return approverCreate.postData({ data: approverCreateSerializer(values) })
      .then(() => snackbar({ message: 'Утверждающий успешно создан' }))
      .then(() => navigate(ROUTES.CATALOG_PRODUCT_LIST))
  }, [])

  return (
    <AppLayout>
      <ApprovalCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default ApprovalProcessCreateContainer
