import React from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles({
  statusIndicator: {
    padding: '6px 14px',
    background: '#D9D9D9',
    borderRadius: '36px',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    width: 'fit-content',
    '&.active': {
      background: '#DEF1E1',
      color: '#0CA925'
    },
    '&.movement': {
      background: '#F4CA16',
      color: '#020020'
    },
    '&.end': {
      background: '#EB4C42',
      color: '#fff'
    }
  }
})

interface Props {
  readonly status?: string
}

const getTranslatedStatus = (status?: string): string | undefined => {
  const statusTranslations: { [key: string]: string } = {
    'START': 'СТАРТ',
    'MOVEMENT': 'ДВИЖЕНИЕ',
    'END': 'КОНЕЦ',
  }

  return statusTranslations[status || '']
}

function EventStatusRow ({ status }: Props) {
  const classes = useStyles()
  const start = status === 'START'
  const movement = status === 'MOVEMENT'
  const end = status === 'END'

  return (
    <div className={cx(classes.statusIndicator, { 'start': start, 'movement': movement, end:end })}>
      {getTranslatedStatus(status)}
    </div>
  )
}

export default EventStatusRow
