import * as Yup from 'yup'
import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'

import TrackingListForm from '../components/TrackingListForm'
import { contactListTracking, taskListByUsersIds, trackingDataDaily } from '../api'
import { getListContactTracking, getListSerializer, getListTaskTracking, getTaskListSerializer } from '../serializers'

const validationSchema = Yup.object().shape({
  from:Yup.string().required('От даты требуется'),
  to:Yup.string().required('До даты требуется')
})

function TrackingListContainer () {
  const contactListing = useGetList(contactListTracking)
  const taskListing = useGetList(taskListByUsersIds)
  const listTrackingDaily = useGetList(trackingDataDaily)

  const onSubmit = useCallback((values:any) => {
    if (values.contact) {
      contactListing.getList({ query:getListContactTracking(values) })
      taskListing.getList({ query:getListTaskTracking(values) })
    } else {
      listTrackingDaily.getList({ query: getListSerializer(values) })
      taskListing.getList({ query: getTaskListSerializer(values) })
    }
  }, [])

  return (
    <AppLayout>
      <TrackingListForm
        initialValues={{ userIds:[], from:'', to:'' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        listTracking={listTrackingDaily}
        taskListing={taskListing}
        contactListTracking={contactListing}
      />
    </AppLayout>
  )
}

export default TrackingListContainer
