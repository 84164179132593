import * as API from 'constants/Api'

import React from 'react'
import { prop } from 'ramda'
import SearchField from 'components/form/SearchField'

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('id', value)
    const name = prop('name', value)

    return { id, name }
  }

  return null
}

function DepositAccountField (props: any) {
  return (
    <SearchField
      name="depositAccount"
      api={API.PaymentReceiverList}
      getOptionValue={getOptionValue}
      label="Аккаунт депозита"
      {...props}
    />
  )
}

export default DepositAccountField
