import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { useConfirm } from 'context/confirm'
import { useNavigate } from 'react-router'
import { prop, trim } from 'ramda'

import { deleteWarehouseGroup, getWarehouseGroupList } from '../api'
import WarehouseGroupRowMenu from '../components/WarehouseGroupRowMenu'
import TableActionsRow from '../components/TableActionoRow'

const columns = [
  {
    headerName: 'ID',
    field: 'id'
  },
  {
    headerName: 'Название склада',
    field: 'name'
  }
]

function WarehouseGroupListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState([])
  const warehouseGroupList = useGetList(getWarehouseGroupList)
  const warehouseGroupDelete = useDelete(deleteWarehouseGroup)

  const handleDeleteWarehouse = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить группу ${values.name}`

    onConfirm({ message })
      .agree(() => {
        warehouseGroupDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Группа успешно удалена' }))
          .then(() => warehouseGroupList.getList())
      })
      .disagree()
  }, [])

  const handleDeleteSelected = useCallback(async () => {
    const message = `Вы уверены, что хотите удалить ${selectedRows.length} продукты `

    onConfirm({ message })
      .agree(() => {
        for (let i = 0; i < selectedRows.length; i++) {
          const selectedProduct = selectedRows[i]
          const productId = prop('id', selectedProduct)
          warehouseGroupDelete.deleteData({ params: { id: productId } })
            .then(() => snackbar({ message: `${selectedRows.length} продукты успешно удалён` }))
            .then(() => warehouseGroupList.getList())
            .then(() => setSelectedRows([]))
        }
      })
      .disagree()
  }, [selectedRows, warehouseGroupList])

  const handleSearch = useCallback((values:any) => {
    const queryIsThere = values && { query: { searchKey: trim(prop('search', values)) } }
    warehouseGroupList.getList(queryIsThere)
  }, [warehouseGroupList.getList])

  return (
    <AppLayout>
      <Table
        title="Группы складов"
        onAddClick={() => navigate(ROUTES.WAREHOUSE_GROUP_CREATE)}
        dataRequest={warehouseGroupList}
        columns={columns}
        onSearchClick={handleSearch}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        actions={(row) => (
          <WarehouseGroupRowMenu
            item={row}
            onDelete={handleDeleteWarehouse}
          />
        )}
        tableActions={(
          <TableActionsRow
            handleDeleteSelected={handleDeleteSelected}
          />
        )}
      />
    </AppLayout>
  )
}

export default WarehouseGroupListContainer
