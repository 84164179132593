import * as API from 'constants/Api'

import React, { useEffect } from 'react'
import MultiSelectSearchField from 'components/form/MultiSelectSearchField'
import { path, prop } from 'ramda'
import { useField } from 'formik'
import { useUser } from 'context/user'

const getOptionValue = (option: any) => {
  if (option) {
    const value = prop('id', option)
    const name = prop('name', option)

    return { value, name, id: value }
  }

  return null
}

function FilterFieldUsers (props: any) {
  const { user } = useUser()
  const visibilityField = useField('visibleTo')
  const visibilityValue = path(['0', 'value'], visibilityField)
  const usersField = useField('users')
  const usersSetValue = path(['2', 'setValue'], usersField)
  const type = visibilityValue === 'ONLY_ME' || visibilityValue === 'EVERYONE'

  useEffect(() => {
    if (visibilityValue === 'ONLY_ME') {
      usersSetValue([{ id:user.userId, name:user.userFullName }])
    }
  }, [visibilityValue])

  return (
    <MultiSelectSearchField
      name="users"
      disabled={type}
      api={API.EmployeeSuggestionList}
      getOptionValue={getOptionValue}
      label="Пользователи"
      {...props}
    />
  )
}

export default FilterFieldUsers
