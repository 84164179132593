import * as API from 'constants/Api'

import React from 'react'
import { path, prop, propOr } from 'ramda'
import SearchField from 'components/form/SearchField'
import { useField } from 'formik'

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('id', value)
    const name = propOr('', 'name', value)
    const plannedDate = propOr('', 'code', value)
    return { id, name, plannedDate }
  }

  return null
}

function VisitField ({ ...props }: any) {
  const contact = useField('contactId')
  const territory = useField('territoryId')
  const territoryId = path(['0', 'value', 'id'], territory)
  const contactId = path(['0', 'value', 'id'], contact)
  return (
    <SearchField
      disabled={!territoryId || !contactId}
      name="visitId"
      api={API.VisitSuggestions}
      getOptionValue={getOptionValue}
      params={{ territoryId, contactId }}
      label="Визиты"
      {...props}
    />
  )
}

export default VisitField
