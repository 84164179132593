import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import L from 'leaflet'

interface AutoFocusMapProps {
  trackingData: any[]
  taskListings: any[]
}

const AutoFocusMap = ({ trackingData, taskListings }: AutoFocusMapProps) => {
  const map = useMap()

  useEffect(() => {
    if (taskListings.length > 0 || trackingData?.length > 0) {
      const allPoints: L.LatLngTuple[] = []
      trackingData?.forEach((tasks) => {
        tasks?.trackingList?.map((item:any) => {
          item?.map((it:any) => {
            const lat = Number(it.latitude)
            const lon = Number(it.longitude)
            if (!isNaN(lat) && !isNaN(lon)) {
              allPoints.push([lat, lon])
            }
          })
        })
      })
      taskListings?.forEach((tasks) => {
        tasks?.forEach((task: any) => {
          const lat = Number(task.taskAddress.lat)
          const lon = Number(task.taskAddress.lon)
          if (!isNaN(lat) && !isNaN(lon)) {
            allPoints.push([lat, lon])
          }
        })
      })

      if (allPoints.length > 0) {
        const bounds = L.latLngBounds(allPoints)
        map.fitBounds(bounds)
      }
    }
  }, [trackingData, taskListings, map])

  return null
}

export default AutoFocusMap
