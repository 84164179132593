import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useDelete } from 'hooks/useDelete'
import { useNavigate } from 'react-router-dom'
import useDialog from 'hooks/useDialog'
import { usePut } from 'hooks/usePut'

import { billingSet, deleteCompany, getCompanyList } from '../api'
import CompanyRowMenu from '../components/CompanyRowMenu'
import CompanyStatusRow from '../components/CompanyStatusRow'
import BillingSetDialogue from '../components/BillingSetDialogue'
import { billingSetSerializer } from '../serializer'
import MoreActionButtons from '../components/MoreRowMenu'

const columns = [
  {
    headerName: 'Название компании',
    field: 'companyName'
  },
  {
    headerName: 'Номер телефона',
    field: 'companyPhone'
  },
  {
    headerName: 'Почта',
    field: 'companyEmail'
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (item: any) => <CompanyStatusRow status={item.companyStatus === 'ACTIVE'} />
  },
  {
    headerName: 'Дата регистрации',
    field: 'createdDate'
  },
  {
    headerName: 'Последний день входа',
    field: 'updatedDate'
  }
]

function CompanyListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const billingDialogue = useDialog()
  const navigate = useNavigate()
  const companyList = useGetList(getCompanyList)
  const companyDelete = useDelete(deleteCompany)
  const setBilling = usePut(billingSet)
  const [companyId, setCompanyId] = useState<any>(null)

  const onSetBillingPlan = useCallback((values:any) => {
    setBilling.putData({ data:billingSetSerializer({ ...values, companyId }) })
      .then(() => companyList.getList())
      .then(() => billingDialogue.handleClose())
      .then(() => snackbar({ message:'Тарифный план успешно установлен' }))
  }, [companyId])

  const onDeleteCompany = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить компанию ${values.companyName}`

    onConfirm({ message })
      .agree(() => {
        companyDelete.deleteData({ params: { publicId: values.publicId } })
          .then(() => snackbar({ message: 'Успешно удалено' }))
          .then(() => companyList.getList())
      })
      .disagree()
  }, [])

  const onExport = useCallback(() => {
    const title = 'Предупреждение: Полный экспорт с включением медиафайлов'
    const message = `Вы собираетесь выполнить полный экспорт данных, включая все
    медиафайлы. Эта операция может включать большой объем данных и занять некоторое время.
    Вы уверены, что хотите продолжить?
    `
    const confirmNoMessage = 'Отмена'
    const confirmYesMessage = 'Да, продолжить экспорт'
    onConfirm({ title, message, confirmNoMessage, confirmYesMessage })
      .agree(() => {
        alert('Message successfully download!')
      })
      .disagree()
  }, [])

  return (
    <AppLayout>
      <Table
        title="Компании"
        onAddClick={() => navigate(ROUTES.BACKEND_COMPANY_CREATE)}
        dataRequest={companyList}
        columns={columns}
        onSearchClick={() => alert('onSearchClick')}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row, index) => (
          <CompanyRowMenu
            row={row}
            index={index}
            setCompanyId={setCompanyId}
            handleDeleteCompany={onDeleteCompany}
            onOpenDialogue={billingDialogue.handleOpen}
          />
        )}
        moreActions={() => (
          <MoreActionButtons
            onExport={onExport}
          />
        )}
      />
      {billingDialogue.open && (
        <BillingSetDialogue
          initialValues={{}}
          open={billingDialogue.open}
          handleClose={billingDialogue.handleClose}
          loading={false}
          onSubmit={onSetBillingPlan}
        />
      )}
    </AppLayout>
  )
}

export default CompanyListContainer
