import * as API from 'constants/Api'

import React from 'react'
import { prop } from 'ramda'
import SearchField from 'components/form/SearchField'

const getOptionValue = (value:any) => {
  if (value) {
    const id = prop('id', value)
    const name = prop('name', value)

    return { value: id, name, id }
  }

  return null
}

function UsersField (props: any) {
  return (
    <SearchField
      name="userId"
      api={API.EmployeeSuggestionList}
      getOptionValue={getOptionValue}
      label="Пользователи"
      searchText="searchKey"
      {...props}
    />
  )
}

export default UsersField
