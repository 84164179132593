import React, { useCallback } from 'react'
import { makeStyles } from '@mui/styles'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'
import { useSnackbar } from 'context/snackbar'

import FilterUpdateForm from './components/FilterUpdateForm'
import { orderFilterEditSerializer } from './serializer'

import { filterGetById, updateFilter } from '../../api'

export const useStyles = makeStyles({
  tableRoot:{
    maxHeight:'460px',
    borderRadius:'7px',
    border:'1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      cursor:'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA'
    }
  },
  header: {
    padding: '17px 20px',
    background: 'rgba(253, 253, 253, 0.5)',
    backdropFilter: 'blur(15px)',
    borderBottom:'1px solid #f1f1f1'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-between'
  },
  wrapper:{
    width:'800px',
    background:'#fff',
    minHeight: 'calc(100vh - 60px)'
  },
  addButton: {
    cursor: 'pointer'
  },
  searchBox:{
    padding:'20px',
    borderBottom:'1px solid #f1f1f1'
  },
  columnBox:{
    padding:'20px',
    height: 'calc(100vh - 270px)',
    overflowY:'auto'
  },
  menuItem:{
    padding:'10px 20px',
    '&:hover':{
      background:'#F6F8FD'
    }
  },
  actions:{
    padding:'20px',
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end'
  },
  button: {
    fontSize: '13px',
    display: 'flex',
    gap:'5px',
    height: '40px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    padding: '9px 20px',
    textTransform:'capitalize',
    '&.disabled': {
      background: '#202020 !important',
      opacity: '0.5'
    }
  }
})

interface Props {
  readonly id:number
  readonly onBack:() => void
  readonly onFilter:(item:any) => void
}

const FilterListingUpdate = ({ id, onBack, onFilter }:Props) => {
  const snackbar = useSnackbar()
  const filterDetail = useGetDetail(filterGetById, { params: { id } })
  const filterUpdate = usePut(updateFilter)

  const onUpdate = useCallback((values:any) => {
    filterUpdate.putData({ data:orderFilterEditSerializer(values) })
      .then(() => onBack())
      .then(() => snackbar({ message: 'Фильтр успешно обновлён' }))
  }, [])
  // const initialValues = useMemo(() => filter || filterDetail.result, [filterDetail])
  return (
    <>
      {!filterDetail.loading && (
        <FilterUpdateForm
          onFilter={onFilter}
          onBack={onBack}
          initialValues={filterDetail.result}
          onSubmit={onUpdate}
        />
      )}
    </>
  )
}

export default FilterListingUpdate
