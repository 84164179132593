import React from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import L from 'leaflet'

import AddMarker from './AddMarker'

L.Marker.prototype.options.icon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png'
})

interface Props {
  readonly onChange: any
  readonly lat: number
  readonly lng: number
}

function AgentMapCard ({ onChange, lat, lng }: Props) {
  return (
    <MapContainer
      className="Map"
      center={{ lat: lat || 41.3123363, lng: lng || 69.2787079 }}
      zoom={15}
      scrollWheelZoom={false}
      style={{ height: '400px' }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <AddMarker onChange={onChange} lat={lat} lng={lng} />
    </MapContainer>
  )
}

export default AgentMapCard
