import React from 'react'
import SelectField from 'components/form/SelectField'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  customSelect:{
    width:'200px',
  },
})

const OPTIONS = [
  { value: '', name: 'Все' },
  { value: 'EMPLOYEE', name: 'Сотрудник' },
  { value: 'CONTACT', name: 'Агент' },
  { value: 'CASHIER', name: 'Кассир' }
]

function AccountTypeField () {
  const classes = useStyles()
  return (
    <SelectField
      className={classes.customSelect}
      name="type"
      label="Тип аккаунта"
      options={OPTIONS}
    />
  )
}

export default AccountTypeField
