import * as API from 'constants/Api'

import React from 'react'
import { path, prop, propOr } from 'ramda'
import SearchField from 'components/form/SearchField'
import { useField } from 'formik'

const getOptionValue = (value: any) => {
  if (value) {
    const id = prop('id', value)
    const name = propOr('', 'name', value)
    return { id, name }
  }

  return null
}

function AgentField ({ ...props }: any) {
  const contact = useField('contactId')
  const territory = useField('territoryId')
  const territoryId = path(['0', 'value', 'id'], territory)
  const contactId = path(['0', 'value', 'id'], contact)
  return (
    <SearchField
      disabled={!territoryId || !contactId}
      name="agentId"
      api={API.EmployeeSuggestionList}
      getOptionValue={getOptionValue}
      label="Агент"
      {...props}
    />
  )
}

export default AgentField
