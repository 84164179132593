import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'
import { useNavigate } from 'react-router-dom'

import { createCustomField } from '../api'
import CustomFieldCreateForm from '../components/CustomFieldCreateForm'
import { CUSTOM_FIELD_TYPE } from '../components/CustomFieldType'

const defaultCustomValue = {
  name: '',
  fieldType: CUSTOM_FIELD_TYPE.TEXT_BOX,
  required: false,
  showInListing: false,
  disabled: false
}
function ProductCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const customFieldCreate = usePost(createCustomField)

  const onSaveCustomField = useCallback((values: any) => {
    return customFieldCreate.postData({ data: values })
      .then(() => snackbar({ message: 'Поле успешно создано' }))
      .then(() => navigate(ROUTES.SETTINGS_CUSTOM_FIELD_LIST))
  }, [])

  return (
    <AppLayout>
      <CustomFieldCreateForm
        initialValues={defaultCustomValue}
        onSubmit={onSaveCustomField}
      />
    </AppLayout>
  )
}

export default ProductCreateContainer
