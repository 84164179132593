import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'
import { GetListResponse } from 'api/BaseDto'

export const getProductList = (options?: Options) => {
  return getRequest<any>(API.ProductList, options)
}

export const getByIdProduct = (options?: Options) => {
  return getRequest<any>(API.ProductGetById, options)
}

export const createProduct = (options?: Options) => {
  return postRequest<any>(API.ProductCreate, options)
}

export const updateProduct = (options?: Options) => {
  return putRequest<any>(API.ProductUpdate, options)
}

export const deleteProduct = (options?: Options) => {
  return deleteRequest<any>(API.ProductDelete, options)
}

export const getStockListByProductId = (options?: Options) => {
  return getRequest<any>(API.StockList, options)
}

export const getProductStockHistory = (options?: Options) => {
  return getRequest<any>(API.ProductStockHistory, options)
}

export const statusChangeProduct = (options?: Options) => {
  return patchRequest<any>(API.ProductChangeStatus, options)
}

export const statusBulkChangeProduct = (options?: Options) => {
  return patchRequest<any>(API.ProductChangeStatusBulk, options)
}

export const getPackageList = (options?: Options) => {
  return getRequest<any>(API.PackageList, options)
}

export const getCustomFields = (options?: Options) => {
  return getRequest<any>(API.CustomFieldsGetByFormType, options)
}

export const deleteProductByIds = (options?: Options) => {
  return deleteRequest<any>(API.ProductDeleteByIds, options)
}

export const countProduct = (options?:Options) => {
  return getRequest<any>(API.ProductCount, options)
}

export const uploadFileProduct = (options?: Options) => {
  return postRequest<any>(API.FileUpload, options)
}

export const productImageDelete = (options?: Options) => {
  return deleteRequest<any>(API.DeleteFileUpload, options)
}

export const productImagePrimary = (options?: Options) => {
  return patchRequest<any>(API.ProductImagePrimary, options)
}

export const productExportExcel = (options?:Options) => {
  return getRequest<any>(API.ProductExportExcel, options)
}

export const importProduct = (options?:Options) => {
  return postRequest<any>(API.ProductImportExcel, options)
}

export const createPriceLevel = (options?: Options) => {
  return postRequest<GetListResponse<any>>(API.PriceLevelCreate, options)
}

export const createFilter = (options?: Options) => {
  return postRequest<any>(API.ListingFilterCreate, options)
}

export const updateFilter = (options?: Options) => {
  return putRequest<any>(API.ListingFilterUpdate, options)
}

export const filterGetById = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetById, options)
}

export const getAvailableColumns = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetByType, options)
}

export const getFilterList = (options?: Options) => {
  return getRequest<any>(API.ListingFilterList, options)
}

export const deleteFilter = (options?: Options) => {
  return deleteRequest<any>(API.ListingFilterDelete, options)
}

export const deleteSelectedFilter = (options?: Options) => {
  return deleteRequest<any>(API.ListingFilterDeleteByIds, options)
}

export const productListingFilterData = (options?:Options) => {
  return getRequest<any>(API.ProductListingFilterData, options)
}

export const ProductBulkEdit = (options?:Options) => {
  return patchRequest(API.ProductBulkUpdate, options)
}

export const ProductBulkCategory = (options?:Options) => {
  return patchRequest(API.ProductBulkCategory, options)
}

export const getCategorySuggestions = (options?: Options) => {
  return getRequest<any>(API.ProductCategorySuggestions, options)
}

export const getSupplierSuggestions = (options?: Options) => {
  return getRequest<any>(API.SupplierSuggestions, options)
}

export const getColumnsList = (options?: Options) => {
  return getRequest<any>(API.ColumnsList, options)
}

export const createColumn = (options?: Options) => {
  return postRequest<any>(API.ColumnsCreate, options)
}

export const columnGetById = (options?: Options) => {
  return getRequest<any>(API.ColumnsGetById, options)
}

export const updateColumn = (options?: Options) => {
  return putRequest<any>(API.ColumnsUpdate, options)
}

export const deleteColumn = (options?: Options) => {
  return deleteRequest<any>(API.ColumnsDelete, options)
}

export const deleteColumns = (options?: Options) => {
  return deleteRequest<any>(API.ColumnsDeleteByIds, options)
}

export const getTabList = (options?:Options) => {
  return getRequest<any>(API.ListingTabList, options)
}

export const createTab = (options?:Options) => {
  return postRequest<any>(API.ListingTabCreate, options)
}

export const updateTab = (options?:Options) => {
  return putRequest<any>(API.ListingTabUpdate, options)
}

export const getByIdTab = (options?:Options) => {
  return getRequest<any>(API.ListingTabGetById, options)
}

export const deleteTab = (options?:Options) => {
  return deleteRequest<any>(API.ListingTabDelete, options)
}

export const deleteTabByIds = (options?:Options) => {
  return deleteRequest<any>(API.ListingTabDeleteByIds, options)
}

export const tabsCounts = (options?:Options) => {
  return getRequest<any>(API.ProductListingTabRecords, options)
}

export const productFilterPreview = (options?:Options) => {
  return patchRequest<any>(API.ProductFilterPreview, options)
}
