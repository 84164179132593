import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getSaleOrderList = (options?: Options) => {
  return getRequest<any>(API.SaleOrderList, options)
}

export const getSaleOrderDetail = (options?: Options) => {
  return getRequest<any>(API.SaleOrderDetail, options)
}

export const getProductList = (options?: Options) => {
  return getRequest<any>(API.ProductDetailList, options)
}

export const createSaleOrder = (options?: Options) => {
  return postRequest<any>(API.SaleOrderCreate, options)
}

export const createTax = (options?: Options) => {
  return postRequest<any>(API.TaxCreate, options)
}

export const createShippingAddress = (options?: Options) => {
  return postRequest<any>(API.ShippingAddressCreate, options)
}

export const updateSaleOrder = (options?: Options) => {
  return putRequest<any>(API.SaleOrderUpdate, options)
}

export const deleteSaleOrder = (options?: Options) => {
  return deleteRequest<any>(API.SaleOrderDelete, options)
}

export const tabsCounts = (options?: Options) => {
  return getRequest<any>(API.SalesOrderListingCounts, options)
}

export const bookSaleOrder = (options?: Options) => {
  return patchRequest<any>(API.SaleOrderBook, options)
}

export const completeSaleOrder = (options?: Options) => {
  return putRequest<any>(API.SaleOrderComplete, options)
}

export const processSaleOrder = (options?: Options) => {
  return patchRequest<any>(API.SaleOrderInProcess, options)
}

export const saleOrderCheckBonus = (options?:Options) => {
  return patchRequest(API.SalesOrderCheckBonus, options)
}

export const saleOrderFilterPreview = (options?:Options) => {
  return patchRequest<any>(API.SalesOrderFilterPreview, options)
}

export const returnSaleOrder = (options?: Options) => {
  return putRequest<any>(API.SaleOrderReturn, options)
}

export const uploadFiles = (options?: Options) => {
  return postRequest<any>(API.SalesOrderFileUpload, options)
}

export const deleteUploadFile = (options?: Options) => {
  return deleteRequest<any>(API.SalesOrderFileDelete, options)
}

export const getContactInformation = (options?: Options) => {
  return getRequest<any>(API.SupplierGetById, options)
}

export const createContact = (options?: Options) => {
  return postRequest<any>(API.ContactCreate, options)
}

export const createFilter = (options?: Options) => {
  return postRequest<any>(API.ListingFilterCreate, options)
}

export const updateFilter = (options?: Options) => {
  return putRequest<any>(API.ListingFilterUpdate, options)
}

export const filterGetById = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetById, options)
}

export const getAvailableColumns = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetByType, options)
}

export const getFilterList = (options?: Options) => {
  return getRequest<any>(API.ListingFilterList, options)
}

export const deleteFilter = (options?: Options) => {
  return deleteRequest<any>(API.ListingFilterDelete, options)
}

export const deleteSelectedFilter = (options?: Options) => {
  return deleteRequest<any>(API.ListingFilterDeleteByIds, options)
}

export const getSaleOrderListCount = (options?: Options) => {
  return getRequest<any>(API.SaleOrderListingCount, options)
}

export const getColumnsGetByType = (options?: Options) => {
  return getRequest<any>(API.ListingFilterGetByType, options)
}

export const getColumnsList = (options?: Options) => {
  return getRequest<any>(API.ColumnsList, options)
}

export const createColumn = (options?: Options) => {
  return postRequest<any>(API.ColumnsCreate, options)
}

export const columnGetById = (options?: Options) => {
  return getRequest<any>(API.ColumnsGetById, options)
}

export const updateColumn = (options?: Options) => {
  return putRequest<any>(API.ColumnsUpdate, options)
}

export const deleteColumn = (options?: Options) => {
  return deleteRequest<any>(API.ColumnsDelete, options)
}

export const deleteColumns = (options?: Options) => {
  return deleteRequest<any>(API.ColumnsDeleteByIds, options)
}

export const getTabList = (options?:Options) => {
  return getRequest<any>(API.ListingTabList, options)
}

export const createTab = (options?:Options) => {
  return postRequest<any>(API.ListingTabCreate, options)
}

export const updateTab = (options?:Options) => {
  return putRequest<any>(API.ListingTabUpdate, options)
}

export const getByIdTab = (options?:Options) => {
  return getRequest<any>(API.ListingTabGetById, options)
}

export const deleteTab = (options?:Options) => {
  return deleteRequest<any>(API.ListingTabDelete, options)
}

export const deleteTabByIds = (options?:Options) => {
  return deleteRequest<any>(API.ListingTabDeleteByIds, options)
}
