import React, { useEffect } from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFormikContext } from 'formik'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles({
  labels: {
    width: '98px',
    '&.MuiFormControlLabel-root': {
      color: '#7C7C7C',
      marginRight: '0',
    },
  },
  activeLabelRoot: {
    color: '#1557FF !important'
  },
  switchToggle: {
    '&.MuiSwitch-root': {
      width: '44px',
      height: '24px',
      padding: '0',
      marginLeft: '15px',
    },
    '& .MuiSwitch-switchBase': {
      padding: '0',
      margin: '4px',
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#1557FF',
          opacity: '1',
          border: '0'
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: '0.5'
        }
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#C3C3C3'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: '0.5',
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: '16px',
      height: '16px',
      position: 'relative',
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        width: 1,
        height: 12,
        backgroundColor: '#C3C3C3',
        transform: 'translateY(-50%)',
      },
      '&::before': {
        left: 6,
      },
      '&::after': {
        right: 6,
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#C3C3C3',
      opacity: '1',
    }
  }
})

interface Props {
  name: string;
  column: any;
  setActiveRow: (activeRow: boolean) => void;
}

function ToggleButton ({ name, column, setActiveRow }: Props) {
  const { setFieldValue, values } = useFormikContext<any>()
  const fieldValue: boolean = values.columns.some((col: any) => col.code === column.code)
  const classes = useStyles()

  useEffect(() => {
    setActiveRow(fieldValue)
  }, [fieldValue])

  const handleToggle = () => {
    const updatedColumns = fieldValue
      ? values.columns.filter((c: any) => c.code !== column.code)
      : [...values.columns, column]
    setFieldValue('columns', updatedColumns)
  }

  return (
    <FormControlLabel
      className={`${classes.labels} ${fieldValue ? classes.activeLabelRoot : ''}`}
      control={
        <Switch
          onChange={handleToggle}
          className={classes.switchToggle}
          checked={fieldValue}
          id={name}
        />
      }
      label={
        <Typography variant="body2">
          {fieldValue ? 'Вкл' : 'Откл'}
        </Typography>
      }
      labelPlacement="start"
    />
  )
}

export default ToggleButton
