import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { useSnackbar } from 'context/snackbar'
import { usePut } from 'hooks/usePut'
import { useGetDetail } from 'hooks/useGetDetail'

import { updateBrand, getBrandDetail } from '../api'
import BrandUpdateForm from '../components/BrandUpdateForm'

function BrandCreateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const brandUpdate = usePut(updateBrand)
  const brandDetail = useGetDetail(getBrandDetail, { params: { id } })

  const handleSubmit = useCallback((values: any) => {
    return brandUpdate.putData({ data: values })
      .then(() => snackbar({ message: 'Бренд успешно обновлена' }))
      .then(() => navigate(ROUTES.CATALOG_BRAND_LIST))
  }, [])

  return (
    <AppLayout>
      {!brandDetail.loading && (
        <BrandUpdateForm
          onSubmit={handleSubmit}
          initialValues={brandDetail.result}
          enableReinitialize={true}
        />
      )}
      {brandDetail.loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px'
          }}
        >
          Loading...
        </div>
      )}
    </AppLayout>
  )
}

export default BrandCreateContainer
