import { AppMenuItemProps, MenuProps, SubMenuProps } from 'constants/App'

import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import cx from 'classnames'
import { propOr } from 'ramda'

import FlexBox from './FlexBox'

import MenuIcon from '../icons/MenuIcon'

const useStyles = makeStyles({
  menuLink: {
    color: '#DBDBDB',
  },
  menuItem: {
    cursor: 'pointer',
    color: '#DBDBDB',
    fontSize: '14px',
    padding: '7px 10px',
    borderRadius: '10px',
    lineHeight: '16.94px',
    fontWeight:'400',
    position: 'relative',
    display:'block',
    transition: '0.2s',
    '&.active, &:hover': {
      background: '#1557FF',
      color:'#fff',
    },
    '&:hover .subMenuRoot': {
      pointerEvents: 'all',
      transform: 'translateY(0) translateX(-50%)'
    },
    '&:hover .subMenu': {
      opacity: 1,
      visibility: 'visible',
    }
  },
  menu:{
    boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    border: '1px solid #fff',
    background:'#FEFEFE',
    overflow: 'hidden',
    transition: 'all 0.3s',
    opacity: 0,
    visibility: 'hidden',
    minWidth: '500px',
    width: '100%'
  },
  menuHeader:{
    minHeight:'52px',
    padding: '16px 20px',
    background: '#FEFEFE',
    borderBottom:'1px solid #EFEFEF'
  },
  menuTitle:{
    color: '#202020',
    fontSize: '14px !important',
    lineHeight:'19.36px',
    fontWeight: '700 !important'
  },
  menuList:{
    padding: '0 20px'
  },
  menuItemsWrapper:{
    minWidth:'220px'
  },
  subMenuWrapper: {
    position: 'absolute',
    paddingTop: '12px',
    left: '50%',
    transition: '0.23s',
    zIndex: 1000,
    pointerEvents: 'none',
    transform: 'translateY(-10px) translateX(-50%)'

  },
  subMenu: {
    boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    border: '1px solid #fff',
    background:'#FEFEFE',
    overflow: 'hidden',
    transition: 'all 0.3s',
    opacity: 0,
    visibility: 'hidden',
    minWidth: '220px',
    width: '100%'
  },

  subMenuTitle: {
    color: '#202020',
    fontSize: '16px !important',
    lineHeight:'19.36px',
    fontWeight: '700 !important'
  },
  subMenuHeader: {
    padding: '20px',
    background: '#FEFEFE',
    borderBottom:'1px solid #EFEFEF'
  },
  subMenuList: {
    padding: '0 20px'
  },
  subMenuListItems: {
    color: '#7C7C7C',
    fontSize: '13px',
    lineHeight:'15.73px',
    fontWeight:'400',
    transition: '0.23s',
    display: 'flex',
    width: '100%',
    padding: '14px 0',
    borderBottom:'1px solid #f1f1f1',
    '&:last-child':{
      borderBottom:'none'
    },
    '&.active': {
      color: '#1657FC',
      fontWeight:'400',
    },
    '&:hover': {
      color: '#202020'
    }
  },
  proButton: {
    width: '35px',
    height: '18px',
    background: 'linear-gradient(99.84deg, #75F7FF 5.87%, #4272EC 33.83%, #D135FB 60.99%, #FFD874 97.67%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    borderRadius: '8px',
    fontSize: '10px',
    marginLeft: '10px'
  }
})

interface Props {
  readonly page: AppMenuItemProps
}

function AppHeaderMenuItem (props: Props) {
  const { page } = props
  const classes = useStyles()
  const location = useLocation()

  const pageSubMenu = propOr([], 'subMenu', page) as SubMenuProps[]
  const menu = propOr([], 'menu', page) as MenuProps[]
  const activeMenu = location.pathname.startsWith(page.path)

  return (
    <>
      <div key={page.path} className={cx(classes.menuItem, { 'active': activeMenu })}>
        {(pageSubMenu.length > 0 || menu.length > 0) && (
          <>
            {page.title}
            <FlexBox className={cx(classes.subMenuWrapper, 'subMenuRoot')}>
              <FlexBox direction="column" flex className={cx(classes.subMenu, 'subMenu')}>
                <FlexBox align="center" gap={10} className={classes.subMenuHeader}>
                  <MenuIcon />
                  <Typography className={classes.subMenuTitle}>
                    {page.title}
                  </Typography>
                </FlexBox>
                <FlexBox align="flex-start" flex={true}>
                  {menu?.length > 0 ? menu.map((menu:any) => (
                    <FlexBox key={`${page.key}-${menu.key}`} direction="column" className={classes.menuItemsWrapper}>
                      <FlexBox className={classes.menuHeader}>
                        <Typography className={classes.menuTitle}>
                          {menu.title}
                        </Typography>
                      </FlexBox>
                      <FlexBox className={classes.subMenuList} flex={true}>
                        <FlexBox direction="column" flex={true}>
                          {menu.subMenu.map((item:any) => {
                            const activeSubMenu = location.pathname.startsWith(item.path)
                            return (
                              <Link
                                key={`${page.key}-${item.path}`}
                                to={item.path}
                                className={cx(classes.subMenuListItems, { 'active': activeSubMenu })}
                              >
                                {item.title}
                                {item.pro && <div className={classes.proButton}>PRO</div>}
                              </Link>
                            )
                          })}
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  )) : (
                    <FlexBox className={classes.subMenuList} flex={true}>
                      <FlexBox direction="column" flex={true}>
                        {pageSubMenu.map(item => {
                          const activeSubMenu = location.pathname.startsWith(item.path)
                          return (
                            <Link
                              key={`${page.key}-${item.path}`}
                              to={item.path}
                              className={cx(classes.subMenuListItems, { 'active': activeSubMenu })}
                            >
                              {item.title}
                              {item.pro && <div className={classes.proButton}>PRO</div>}
                            </Link>
                          )
                        })}
                      </FlexBox>
                    </FlexBox>)}
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </>
        )}
        {(menu.length === 0 && pageSubMenu.length === 0) && (
          <Link to={page.path} className={classes.menuLink}>
            {page.title}
          </Link>
        )}
      </div>
    </>
  )
}

export default AppHeaderMenuItem
