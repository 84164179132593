import { path, prop, propOr } from 'ramda'
import { toNumber } from 'utils/formatMoney'

export const priceLevelCreateSerializer = (values: any) => {
  const territories = propOr([], 'territoryIds', values) as any
  const newTerritories = territories.map((territory: any) => territory.id)
  const currency = path(['currency', 'code'], values)
  return { ...values, territoryIds: newTerritories, currency }
}

export const priceLevelDetailSerializer = (values:any) => {
  const territoryIds = prop('territories', values)
  return { ...values, territoryIds }
}

export const priceLevelLineItemsInitialValuesSerializer = (values: any) => {
  const list = propOr([], 'content', values) as any
  return { lineItemDTOs: [...list] }
}

export const priceLevelUpdateSerializer = (values: any) => {
  const territories = propOr([], 'territoryIds', values) as any
  const newTerritories = territories.map((territory: any) => territory.id)
  const currency = path(['currency', 'code'], values)
  const getLineItems = prop('lineItemDTOs', values)
  const lineItemDTOs = getLineItems && getLineItems.map((item:any) => {
    const maxPrice = toNumber(prop('maxPrice', item))
    const minPrice = toNumber(prop('minPrice', item))
    const value = toNumber(prop('value', item))
    return { ...item, maxPrice, minPrice, value }
  })
  return { ...values, territoryIds: newTerritories, currency, lineItemDTOs }
}
