import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'CASH', name:'Наличкой' },
  { value: 'CARD', name:'Картой' },
  { value: 'BANK_TRANSFER', name:'Банковский переводом' },
]

function PaymentMethodField (props: any) {
  return (
    <SelectField
      name="paymentMethod"
      label="Метод оплаты"
      options={OPTIONS}
      data-cy="paymentMethod"
      {...props}
    />
  )
}

export default PaymentMethodField
