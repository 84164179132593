import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import { Button, Grid } from '@mui/material'

import AgentBasic from './create/AgentBasic'
import AttachmentField from './form/AttachmentField'
import ImageUpload from './form/ImageUpload'

function SharedContactCreateForm () {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Создать поделенный контакт"
          rightButton={(
            <>
              <Button type="submit">Создать</Button>
            </>
          )}
        />
      </Header>

      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <AgentBasic />
          </Grid>
          <Grid item={true} lg={12}>
            <ImageUpload
              isCreateForm={true}
            />
          </Grid>
          <Grid item={true} lg={12}>
            <AttachmentField name="attachments" />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(SharedContactCreateForm)
