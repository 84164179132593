import React from 'react'
import { Button, Grid } from '@mui/material'
import TextField from 'components/form/TextField'
import withForm from 'components/form/withForm'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import PhoneField from 'components/form/PhoneField'
import DialogActions from '@mui/material/DialogActions/DialogActions'

import ClientCurrencyField from './form/ClientCurrencyField'
import MultiTerritoryField from './form/MultiTerritoryField'

interface Props {
  readonly handleClose: () => void
  readonly open: boolean
  readonly loading: boolean
  readonly form?: any
}

function ClientCreateDialogue ({ handleClose, open, loading, form }: Props) {
  const { handleSubmit } = form
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
    >
      <DialogTitle>Создать клиента</DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={3} mt={1}>
          <Grid item={true} xs={12} sm={6} md={4} lg={4}>
            <TextField label="Юр. наименование" name="name" />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={4}>
            <TextField label="Короткое имя" name="shortName" />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={4}>
            <TextField label="ИНН" name="inn" />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={4}>
            <PhoneField label="Телефон" name="number" value="998" />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={4}>
            <TextField label="МФО" name="mfo" />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={4}>
            <ClientCurrencyField />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={4}>
            <TextField label="РС" name="rs" />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={4}>
            <TextField label="Идентификатор аккаунта" name="accountId" />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={4}>
            <MultiTerritoryField />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={4}>
            <TextField label="Веб-сайт" name="website" />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4} lg={8} />
          <Grid item={true} xs={12} sm={6} md={4} lg={4}>
            <TextField label="Примечания" name="note" multiline={true} minRows={4} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          fullWidth={true}
          disabled={loading}
        >
          Отменить
        </Button>
        <Button
          data-cy="add"
          variant="contained"
          fullWidth={true}
          type="submit"
          disabled={loading}
          onClick={() => handleSubmit()}
        >
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withForm(ClientCreateDialogue)
