import { useTranslation } from 'react-i18next'

export function useLocales (module?: string) {
  const { t, i18n } = useTranslation(module)

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('antext-language', lang)
  }

  return {
    t,
    changeLanguage,
    currentLanguage: i18n.language, // Add this line
  }
}
