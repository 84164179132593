import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, patchRequest, postRequest, putRequest } from 'api/BaseApi'

export const getSalesOrderReturnList = (options?: Options) => {
  return getRequest<any>(API.SalesOrderReturnList, options)
}

export const createSalesOrderReturn = (options?: Options) => {
  return postRequest<any>(API.SalesOrderReturnCreate, options)
}

export const getByIdSalesOrderReturn = (options?:Options) => {
  return getRequest<any>(API.SalesOrderReturnGetById, options)
}

export const updateSalesOrderReturn = (options?: Options) => {
  return putRequest<any>(API.SalesOrderReturnUpdate, options)
}

export const salesOrderReturnStatus = (options?: Options) => {
  return patchRequest<any>(API.SalesOrderReturnStatus, options)
}

export const deleteSalesOrderReturn = (options?: Options) => {
  return deleteRequest<any>(API.SalesOrderReturnDelete, options)
}

export const createShippingAddress = (options?: Options) => {
  return postRequest<any>(API.ShippingAddressCreate, options)
}

export const getSaleOrderDetail = (options?: Options) => {
  return getRequest<any>(API.SaleOrderDetail, options)
}

export const getProductSuggestions = (options?: Options) => {
  return getRequest<any>(API.ProductDetailList, options)
}

export const getProductSuggestionsByOrderId = (options?: Options) => {
  return getRequest<any>(API.SalesOrderProductByOrderId, options)
}
