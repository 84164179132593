import { Button, Grid } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import { pathOr } from 'ramda'

import BillingModuleField from './form/BillingModuleField'
import BillingChildModuleField from './form/BillingChildModuleField'

export const BillingModules = () => {
  const values = useFormikContext()
  const modules = pathOr([], ['values', 'modules'], values) as any
  return (
    <Grid item={true} lg={12}>
      <FieldArray name="modules">
        {({ push, remove }: any) => {
          return <Grid container={true} spacing={3}>
            {modules.map((item:any, index:number) => (
              <Grid item={true} lg={12} key={index}>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <BillingModuleField name={`modules[${index}].module`} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <BillingChildModuleField name={`modules[${index}].childModules`} />
                  </Grid>
                  {index >= 0 && (
                    <Grid item xs={8} lg={2}>
                      <Button onClick={() => remove(index)} fullWidth={true}>Удалить</Button>
                    </Grid>
                  )}
                  {modules.length === (index + 1) && (
                    <Grid item={true} lg={2}>
                      <Button
                        onClick={() => {
                          push({
                            module: '',
                            childModules: []
                          })
                        }}
                        fullWidth={true}
                      >
                        Добавить модуль
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
            {modules.length === 0 && (
              <Grid item={true} lg={3}>
                <Button
                  onClick={() => {
                    push({
                      module: '',
                      childModules: []
                    })
                  }}
                >
                  Добавить модуль
                </Button>
              </Grid>
            )}
          </Grid>
        }}
      </FieldArray>
    </Grid>
  )
}
