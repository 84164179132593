import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import AppLayout from 'components/main/AppLayout'
import useScrollList from 'hooks/useScrollList'
import { usePost } from 'hooks/usePost'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'context/snackbar'

import PurchaseCreateForm from '../components/PurchaseCreateForm'
import { createPurchase, getProductList } from '../api'
import { purchaseCreateSerializer } from '../serializers'

function PurchaseCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const productList = useScrollList(getProductList)
  const purchaseCreate = usePost(createPurchase)

  const handleSubmit = useCallback((values: any) =>
    purchaseCreate.postData({ data: purchaseCreateSerializer(values) })
      .then(() => navigate(ROUTES.FINANCE_PURCHASE_LIST))
      .then(() => snackbar({ message: 'Закупка успешно создана' }))
  , [])

  return (
    <AppLayout>
      <PurchaseCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
        productList={productList}
      />
    </AppLayout>
  )
}

export default PurchaseCreateContainer
