import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'
import { propOr, trim } from 'ramda'

import { deleteContact, getContactList } from '../api'
import SharedContactRowMenu from '../components/SharedContactRowMenu'

const columns = [
  {
    headerName: 'Юр. наименование',
    field: 'name',
  },
  {
    headerName:'Короткое имя',
    field: 'shortName'
  },
  {
    headerName: 'Телефон',
    field: 'number'
  },
  {
    headerName: 'Обновлено',
    field: 'updatedDate'
  }
]

function SharedContactListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const agentList = useGetList(getContactList)
  const agentDelete = useDelete(deleteContact)

  const handleDeleteAgent = useCallback((values: any) => {
    const message = `Вы уверены что хотите удалить контрагент ${values.name}`

    onConfirm({ message })
      .agree(() => {
        agentDelete.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Поставщик успешно удалён' }))
          .then(() => agentList.getList())
      })
      .disagree()
  }, [])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    agentList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Поделенный контакт"
        onAddClick={() => navigate(ROUTES.BACKEND_SHARED_CONTACT_CREATE)}
        dataRequest={agentList}
        columns={columns}
        onSearchClick={onTableSearch}
        tabs={[]}
        checkbox={true}
        searchPlaceholder="Поиск по названию, телефону, короткому имени, ИНН или ID"
        actions={(row) => (
          <SharedContactRowMenu
            item={row}
            handleDelete={handleDeleteAgent}
          />
        )}
      />
    </AppLayout>
  )
}

export default SharedContactListContainer
