import * as ROUTES from 'constants/Routes'

import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import TextField from 'components/form/TextField'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { useNavigate } from 'react-router'
import { path } from 'ramda'
import { useParams } from 'react-router-dom'

import LineItems from './form/LineItems'
import PickerStatusField from './form/PickerStatusField'

interface Props {
  readonly onPickItems: (items: any) => void
  readonly form: any
  readonly onPickerSet: () => void
  readonly onPrintPDF: (id:number) => void
}

function PickUpdateForm (props: Props) {
  const {
    onPickItems,
    form,
    onPickerSet,
    onPrintPDF,
  } = props
  const navigate = useNavigate()
  const { id } = useParams()

  const status = path(['values', 'status'], form)
  const name = path(['values', 'number'], form)
  const isAblePickerSet = status === 'BOOKED' || status === 'IN_PROCESS'
  const isShipping = status === 'IN_PROCESS'

  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить сборку (${name})`}
          rightButton={(
            <>
              <Button type="button" onClick={() => navigate(ROUTES.ORDER_PICKER_LIST)}>Назад</Button>
              <Button
                disabled={!isAblePickerSet}
                type="button"
                onClick={onPickerSet}
              >
                Отправить на сборку
              </Button>
              <Button
                type="button"
                onClick={() => onPrintPDF(Number(id))}
              >
                Распечатать PDF
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали сборки" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={3}>
                    <TextField label="Номер" name="number" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <TextField label="Имя сборщика" name="picker.name" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <TextField label="Склад" name="warehouse.name" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={3}>
                    <PickerStatusField disabled={true} />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems
              isStatus={isShipping}
              onPickItems={onPickItems}
            />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(PickUpdateForm)
