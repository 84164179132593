export function truncateText (text: any, maxLength: number) {
  if (typeof text !== 'string') {
    return text
  }

  if (text.length <= maxLength) {
    return text
  }

  return text.substring(0, maxLength) + '...'
}
