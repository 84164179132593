import React from 'react'
import SelectField from 'components/form/SelectField'

const OPTIONS = [
  { value: 'PARTIALLY_PAID', name: 'Частично оплачено' },
  { value: 'PAID', name: 'Оплачено' },
  { value: 'PAID', name: 'Не выплачивано' },
]

function ConsignmentItemStatusField (props:any) {
  return (
    <SelectField
      name="status"
      options={OPTIONS}
      data-cy="status"
      {...props}
    />
  )
}

export default ConsignmentItemStatusField
