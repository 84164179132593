import React, { useCallback, useState } from 'react'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { propOr, trim } from 'ramda'
import { useSnackbar } from 'context/snackbar'
import { useDelete } from 'hooks/useDelete'
import { usePut } from 'hooks/usePut'

import { countryDeleteBulk, countryUpdateStatusBulk, getCountryList } from '../api'
import CountryRowMenu from '../components/CountryRowMenu'
import TableActionsRow from '../components/TableActionRow'
import { selectedRowSerializer } from '../serializer'
import CountryStatusRow from '../components/CountryStatusRow'
import { useLocales } from '../../../../hooks/useLocales'

const columns = [
  {
    headerName: 'Country name',
    field: 'name',
  },
  {
    headerName: 'status',
    field:'status',
    renderCell:(item:any) => <CountryStatusRow status={item.status} />
  },
  {
    headerName: 'Description',
    field: 'notes',
  }
]

function CountryListContainer () {
  const { t } = useLocales('country')
  const snackbar = useSnackbar()
  const countryList = useGetList(getCountryList)
  const countryBulkUpdateStatus = usePut(countryUpdateStatusBulk)
  const countryBulkDelete = useDelete(countryDeleteBulk)
  const [selectedRows, setSelectedRows] = useState([])

  const onDeleteBulk = useCallback(() => {
    countryBulkDelete.deleteData({ data:selectedRowSerializer(selectedRows) })
      .then(() => countryList.getList())
      .then(() => setSelectedRows([]))
      .then(() => snackbar({ message:'Выбранная страна успешно удалена' }))
  }, [selectedRows])

  const onUpdateBulkStatus = useCallback((status:string) => {
    countryBulkUpdateStatus.putData({ data: { countryIds:selectedRowSerializer(selectedRows), status } })
      .then(() => countryList.getList())
      .then(() => setSelectedRows([]))
      .then(() => snackbar({ message:'Статус выбранной страны успешно обновлен' }))
  }, [selectedRows])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    countryList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title={t('Country')}
        dataRequest={countryList}
        columns={columns}
        onSearchClick={onTableSearch}
        translationModule="country"
        tabs={['Все', 'Активы', 'Неактивы']}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkbox={true}
        searchPlaceholder={t('Search by name or ID')}
        actions={(row:any) => (
          <CountryRowMenu
            item={row}
          />
        )}
        tableActions={(
          <TableActionsRow
            onDeleteBulk={onDeleteBulk}
            onUpdateBulkStatus={onUpdateBulkStatus}
          />
        )}
      />
    </AppLayout>
  )
}

export default CountryListContainer
