import { omit, path, prop } from 'ramda'

export const orderFilterSerializer = (values:any) => {
  const result = omit(['availableColumns'], values)
  const getUsers = prop('users', result)
  const users = getUsers && getUsers.map((user:any) => user.id)
  const getRoles = prop('roles', result)
  const roles = getRoles && getRoles.map((role:any) => role.role)
  const getCriteria = prop('criteria', values)
  const criteria = getCriteria && getCriteria.map((criteria:any) => {
    const code = prop('code', criteria)
    const comparator = prop('comparator', criteria)
    const value = path(['value', 'id'], criteria) || prop('value', criteria)
    return { comparator, code, value }
  }).filter((item: any) => (item.comparator || item.value) != null)
  return { ...result, users, roles, criteria }
}

export const productFilterPreviewSerializer = (values:any) => {
  const result = omit(['availableColumns', 'users', 'roles', 'name', 'type', 'visibleTo', 'default'], values)
  const getCriteria = prop('criteria', values)
  const criteria = getCriteria && getCriteria.map((criteria:any) => {
    const value = path(['value', 'id'], criteria) || prop('value', criteria)
    if (value) {
      const code = prop('code', criteria)
      const comparator = prop('comparator', criteria)
      return { comparator, code, value }
    }
  }).filter((item: any) => item != null)
  return { ...result, criteria }
}

export const orderFilterEditSerializer = (values:any) => {
  const result = omit(['availableColumns'], values)
  const getUsers = prop('users', result)
  const users = getUsers && getUsers.map((user:any) => user.id)
  const getRoles = prop('roles', result)
  const roles = getRoles && getRoles.map((role:any) => role.role)
  const getCriteria = prop('criteria', values)
  const criteria = getCriteria && getCriteria.map((criteria:any) => {
    const code = path(['code', 'code'], criteria)
    const comparator = prop('comparator', criteria)
    const value = path(['value', 'id'], criteria) || prop('value', criteria)
    return { comparator, code, value }
  }).filter((item: any) => (item.comparator || item.value) != null)
  return { ...result, users, roles, criteria }
}

export const queryPatternSerializer = (getCriteria: any[]) => {
  if (!getCriteria || getCriteria.length === 0) {
    return { criteria: '' }
  }

  const filteredCriteria = getCriteria.filter((item) => (item.comparator || item.value) != null)

  const groupedCriteria = filteredCriteria.reduce((acc, criteria, index) => {
    const criteriaString = `${index + 1}`

    if (index === 0) {
      // First criteria is added as is
      acc.push(criteriaString)
    } else {
      const previousCriteriaType = filteredCriteria[index - 1].criteriaType
      if (criteria.criteriaType === 'AND') {
        if (previousCriteriaType === 'OR') {
          // If previous was OR and current is AND, group with previous in parenthesis
          acc[acc.length - 1] = `(${acc[acc.length - 1]} OR ${criteriaString})`
        } else {
          // Otherwise, just add current with AND
          acc[acc.length - 1] = `${acc[acc.length - 1]} AND ${criteriaString}`
        }
      } else if (criteria.criteriaType === 'OR') {
        if (previousCriteriaType === 'AND') {
          // If previous was AND and current is OR, group with previous in parenthesis
          acc[acc.length - 1] = `(${acc[acc.length - 1]} AND ${criteriaString})`
        } else {
          // Otherwise, just add current with OR
          acc[acc.length - 1] = `${acc[acc.length - 1]} OR ${criteriaString}`
        }
      }
    }

    return acc
  }, [])

  const result = groupedCriteria.join(' ')

  // Ensure the result is wrapped in parentheses
  return { criteria: groupedCriteria.length > 1 ? `(${result})` : `(${result})` }
}

export const tabSerializer = (values:any) => {
  const listingColumnId = path(['listingColumnId', 'id'], values)
  const listingFilterId = path(['listingFilterId', 'id'], values)
  const listingSortId = path(['listingSortId', 'id'], values)
  return { ...values, listingColumnId, listingFilterId, listingSortId }
}

export const tabDetailSerializer = (values:any) => {
  const listingColumnId = prop('listingColumn', values)
  const listingFilterId = prop('listingFilter', values)
  const listingSortId = prop('listingSort', values)
  return { ...values, listingColumnId, listingFilterId, listingSortId }
}

export const criteriaSerializer = (values:any) => {
  let queryPattern = ''
  let currentCriteriaType = ''
  if (values) {
    values.forEach((item:any, index:number) => {
      const criteriaIndex = index + 1

      if (index === 0) {
        queryPattern += `${criteriaIndex}`
      } else {
        if (item.criteriaType !== currentCriteriaType && currentCriteriaType !== '') {
          queryPattern = `(${queryPattern} ${currentCriteriaType} ${criteriaIndex})`
        } else {
          queryPattern = `(${queryPattern} ${item.criteriaType} ${criteriaIndex})`
        }
      }

      currentCriteriaType = item.criteriaType
    })
  }

  return queryPattern
}

export const parseQueryPattern = (pattern:any, criteria:any) => {
  if (pattern) {
    const allItems = pattern.split(' ')
    const logics = allItems.filter((item:any) => item.includes('AND') || item.includes('OR'))
    return criteria.map((item:any, index:number) => {
      return {
        ...item,
        criteriaType: logics[index] || 'AND',
      }
    })
  }
  return null
}

export const filterInitialValues = (values:any) => {
  const getCriteria = prop('criteria', values)
  const patternField = prop('queryPattern', values)
  const criteria = parseQueryPattern(patternField, getCriteria)
  return {
    ...values,
    criteria
  }
}
