import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  header: {
    padding: '17px 20px',
    background: 'rgba(253, 253, 253, 0.5)',
    backdropFilter: 'blur(15px)',
    borderBottom:'1px solid #f1f1f1'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-between'
  },
  wrapper:{
    width:'600px',
    background:'#fff',
    minHeight: 'calc(100vh - 60px)'
  },
  addButton: {
    cursor: 'pointer'
  },
  searchBox:{
    padding:'20px',
    borderBottom:'1px solid #f1f1f1'
  },
  columnBox:{
    padding:'20px',
    height: 'calc(100vh - 256px)',
    overflowY:'auto'
  },
  menuItem:{
    padding:'10px 20px',
    '&:hover':{
      background:'#F6F8FD'
    }
  },
  actions:{
    padding:'20px',
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end'
  },
  button: {
    fontSize: '13px',
    display: 'flex',
    alignItems:'center',
    gap:'5px',
    height: '40px',
    borderRadius: '5px',
    padding: '9px 15px',
    textTransform:'capitalize',
    '&.disabled': {
      background: '#202020 !important',
      opacity: '0.5'
    }
  }
})
