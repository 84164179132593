import * as API from 'constants/Api'

import { getRequest, Options } from 'api/BaseApi'

export const getEmployeeList = (options?: Options) => {
  return getRequest<any>(API.EmployeeList, options)
}

export const trackingList = (options?: Options) => {
  return getRequest<any>(API.TrackingListByUser, options)
}

export const trackingListByUsers = (options?: Options) => {
  return getRequest<any>(API.TrackingListByUsers, options)
}

export const taskListByUserId = (options?: Options) => {
  return getRequest<any>(API.VisitListByUserId, options)
}

export const taskListByUsersIds = (options?: Options) => {
  return getRequest<any>(API.VisitListByUsersIds, options)
}

export const contactListTracking = (options?:Options) => {
  return getRequest<any>(API.ContactListTracking, options)
}

export const trackingDataDaily = (options?:Options) => {
  return getRequest<any>(API.TrackingListDaily, options)
}
