import * as API from 'constants/Api'

import { deleteRequest, getRequest, Options, postRequest, putRequest } from 'api/BaseApi'

export const getTimeSlotList = (options?: Options) => {
  return getRequest<any>(API.TimeSlotList, options)
}

export const timeSlotCreate = (options?: Options) => {
  return postRequest<any>(API.TimeSlotCreate, options)
}

export const timeSlotUpdate = (options?: Options) => {
  return putRequest<any>(API.TimeSlotUpdate, options)
}

export const timeSlotGetById = (options?: Options) => {
  return getRequest<any>(API.TimeSlotGetById, options)
}

export const timeSlotDelete = (options?: Options) => {
  return deleteRequest<any>(API.TimeSlotDelete, options)
}
