import React from 'react'
import Button from '@mui/material/Button'
import withForm, { WithFormProps } from 'components/form/withForm'
import { useField } from 'formik'
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { path, pathOr, prop } from 'ramda'
import SkeletonList from 'components/skeleton/SkeletonList'
import TextField from 'components/form/TextField'
import { useTranslationI18n } from 'i18n/I18nContext'
import Container from 'components/container/Container'
import Header from 'components/container/Header'
import PageTitle from 'components/main/PageTitle'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { formatMoney } from 'utils/formatMoney'
import DateField from 'components/form/DateField'
import { makeStyles } from '@mui/styles'

import WarehouseField from './form/WarehouseField'
import SupplierField from './form/SupplierField'
import MultipleTerritoryField from './form/MultipleTerritoryField'
import CurrencyField from './form/CurrencyField'
import PriceLevelField from './form/PriceLevelField'

const useStyles = makeStyles({
  tableRoot:{
    borderRadius:'7px',
    border:'1px solid #E9E9E9'
  },
  tableHeader: {
    background: '#F1F1F1',
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset'
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      boxShadow: '2px 2px 4px rgba(167, 172, 176, 0.1)',
      background:'#FAFAFA'
    }
  },
})

interface Props extends WithFormProps {
  readonly loading?: boolean
  readonly handleComplete: (id: any) => void
  readonly id: number
  readonly form:any
}

function ReceiveUpdateForm ({ loading, handleComplete, id, form }: Props) {
  const { t } = useTranslationI18n()
  const classes = useStyles()
  const statusField = useField('status')
  const itemsField = useField('items')
  const name = path(['values', 'number'], form)
  const items = itemsField[0].value || []
  const status = statusField[0].value
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={`Завершить приход (${name})`}
          rightButton={(
            <>
              <Button
                type="button"
                onClick={() => handleComplete(id)}
                disabled={status === 'COMPLETED'}
              >
                Завершить
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={4}>
                    <TextField name={`order.name`} label="Название заказа на поставку" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <TextField name="number" label="Номер получения" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <SupplierField disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <WarehouseField disabled />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <MultipleTerritoryField disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <PriceLevelField disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <CurrencyField disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DateField name="expectedDate" label="Дата ожидания" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4}>
                    <DateField name="receivedDate" label="Дата получения" disabled={true} />
                  </Grid>
                  <Grid item={true} lg={4} >
                    <TextField name="notes" label="Примечания" multiline={true} minRows={4} disabled={true} />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Продукты" />
              <CardContent>
                <Grid item={true} lg={12}>
                  <TableContainer className={classes.tableRoot}>
                    <Table size="small">
                      <TableHead className={classes.tableHeader}>
                        <TableRow>
                          <TableCell>{t('Название продукта')}</TableCell>
                          <TableCell width="15%">{t('Количество получения')}</TableCell>
                          <TableCell>{t('Заказанное количетсво')}</TableCell>
                          <TableCell>{t('Цена')}</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBody}>
                        {loading && <SkeletonList columnCount={3} />}
                        {items.map((item: any, index: number) => {
                          const productName = pathOr('', ['product', 'name'], item)
                          const expectedQuantity = prop('expectedQuantity', item)
                          const price = prop('price', item)
                          return (
                            <TableRow>
                              <TableCell>{productName}</TableCell>
                              <TableCell>
                                <TextField name={`items[${index}].receivedQuantity`} type="number" disabled={true} />
                              </TableCell>
                              <TableCell>{expectedQuantity}</TableCell>
                              <TableCell>{formatMoney(price)}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(ReceiveUpdateForm)
