import React from 'react'
import withForm from 'components/form/withForm'
import Container from 'components/container/Container'
import PageTitle from 'components/main/PageTitle'
import Header from 'components/container/Header'
import Content from 'components/container/Content'
import CardHeader from 'components/card/CardHeader'
import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import TextField from 'components/form/TextField'
import { path } from 'ramda'

import WarehouseField from './form/WarehouseField'
import LineItems from './form/LineItems'

interface Props {
  readonly productList: any
  readonly form:any
}

function TransferUpdateForm ({ productList, form }: Props) {
  const name = path(['values', 'referenceNumber'], form)
  return (
    <Container>
      <Header>
        <PageTitle
          form={form}
          pageTitle={`Обновить перемешение (${name})`}
          rightButton={(
            <>
              <Button type="submit">Сохранить изменения</Button>
            </>
          )}
        />
      </Header>

      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Card>
              <CardHeader title="Детали" />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} lg={6}>
                    <TextField name="referenceNumber" label="Привязанный номер" />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <WarehouseField name="fromWarehouseId" label="Со склада" />
                  </Grid>
                  <Grid item={true} lg={6}>
                    <WarehouseField name="toWarehouseId" label="На склад" />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item={true} lg={12}>
            <LineItems productList={productList} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default withForm(TransferUpdateForm)
