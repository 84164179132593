import React from 'react'

function ClearIcon (props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 72 72"
      {...props}
    >
      <path d="M36 12c13.255 0 24 10.745 24 24S49.255 60 36 60 12 49.255 12 36s10.745-24 24-24zm4.243 32.485a3 3 0
      004.243-4.243L40.243 36l4.243-4.243a3 3 0 00-4.243-4.243L36 31.757l-4.243-4.243a3 3 0 00-4.243 4.243L31.757
      36l-4.243 4.243a3.001 3.001 0 004.243 4.243L36 40.243l4.243 4.242z" />
    </svg>
  )
}

export default ClearIcon
