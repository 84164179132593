import * as ROUTES from 'constants/Routes'

import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/Table'
import AppLayout from 'components/main/AppLayout'
import { useGetList } from 'hooks/useGetList'
import { useDelete } from 'hooks/useDelete'
import { useConfirm } from 'context/confirm'
import { useSnackbar } from 'context/snackbar'
import { propOr, trim } from 'ramda'

import { getNewsList, newsDelete, newsDeleteByIds } from '../api'
import NewsRowMenu from '../components/NewsRowMenu'
import { rowSerializer } from '../serializers'
import TableActionsRow from '../components/TableActionsRow'

const columns = [
  {
    headerName: 'Название',
    field: 'nameUz',
  },
  {
    headerName: 'Название',
    field: 'nameRu',
  },
  {
    headerName: 'Название',
    field: 'nameEn',
  },
  {
    headerName: 'Дата создания',
    field: 'createdDate',
  }
]

function NewsListContainer () {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const newsList = useGetList(getNewsList)
  const deleteNews = useDelete(newsDelete)
  const deleteBulkNews = useDelete(newsDeleteByIds)
  const [selectedRows, setSelectedRows] = useState([])
  const handleDelete = useCallback((values:any) => {
    const message = `Вы уверены что хотите удалить новости ${values.name}?`

    onConfirm({ message })
      .agree(() => {
        deleteNews.deleteData({ params: { id: values.id } })
          .then(() => snackbar({ message: 'Новости успешно удален' }))
          .then(() => newsList.getList())
      })
      .disagree()
  }, [])

  const onDeleteByIds = useCallback(() => {
    const message = `Вы уверены, что хотите удалить выбранные ${selectedRows.length} новости?`

    onConfirm({ message })
      .agree(() => {
        deleteBulkNews.deleteData({ data: rowSerializer(selectedRows) })
          .then(() => snackbar({ message: 'Новости успешно удален' }))
          .then(() => newsList.getList())
      })
      .disagree()
  }, [selectedRows])

  const onTableSearch = useCallback((value: any) => {
    const search = trim(propOr('', 'search', value))
    newsList.getList({ query: { searchKey: search } })
  }, [])

  return (
    <AppLayout>
      <Table
        title="Новости"
        onAddClick={() => navigate(ROUTES.BACKEND_NEWS_CREATE)}
        dataRequest={newsList}
        columns={columns}
        onSearchClick={onTableSearch}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        tabs={['Все', 'Активы', 'Неактивы']}
        checkbox={true}
        actions={(row:any) => (
          <NewsRowMenu
            item={row}
            handleDelete={handleDelete}
          />
        )}
        tableActions={(
          <TableActionsRow
            onDeleteSelected={onDeleteByIds}
          />
        )}
      />
    </AppLayout>
  )
}

export default NewsListContainer
