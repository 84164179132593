import React, { useEffect, useState } from 'react'
import { prop } from 'ramda'
import { FieldArray, useField } from 'formik'
import { useGetList } from 'hooks/useGetList'
import { Grid } from '@mui/material'
import SingleRow from 'components/row/Row'

import { getTranslatedColumn } from '../../translations'
import { availableColumns } from '../../api'

function ColumnsField () {
  const api = useGetList(availableColumns, { params: { type: 'SHARED_CONTACT_LIST_PANEL' } })
  const [columns, setColumns] = useState([] as any)
  const loading = prop('loading', api)
  const [field, meta] = useField('columns')

  useEffect(() => {
    api.getList()
      .then((response: any) => {
        if (meta.initialValue) {
          const uniqueColumns = response.filter((col: any) =>
            !meta.initialValue.some((existingCol: any) => existingCol.code === col.code))
          setColumns([...meta.initialValue, ...uniqueColumns])
        }
      })
  }, [])

  useEffect(() => {
    if (field.value) {
      const uniqueColumns = columns.filter((col: any) =>
        !field.value.some((existingCol: any) => existingCol.code === col.code))
      setColumns([...field.value, ...uniqueColumns])
    }
  }, [field.value])

  return (
    <Grid item={true} lg={12}>
      <FieldArray name="columns">
        {() => (
          <Grid container={true} spacing={1}>
            {!loading && columns.map((column: any, index: number) => (
              <Grid item={true} lg={6} key={`column-${column.code}-${index}`}>
                <SingleRow
                  column={column}
                  index={index}
                  toggleFieldName={column.code}
                  rowTitle={getTranslatedColumn(column.code, column.name)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </FieldArray>
    </Grid>
  )
}

export default ColumnsField
