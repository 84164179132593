import * as ROUTES from 'constants/Routes'

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/main/AppLayout'
import { usePost } from 'hooks/usePost'
import { useSnackbar } from 'context/snackbar'

import { createBulkPayment } from '../api'
import BulkPaymentCreateForm from '../components/BulkPaymentCreateForm'
import { bulkPaymentCreateSerializer } from '../serializer'

function BulkPaymentCreateContainer () {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const brandCreate = usePost(createBulkPayment)

  const handleSubmit = useCallback((values: any) => {
    return brandCreate.postData({ data: bulkPaymentCreateSerializer(values) })
      .then(() => snackbar({ message: 'Массовый платеж успешно создан' }))
      .then(() => navigate(ROUTES.ORDER_BULK_PAYMENT_LIST))
  }, [])

  return (
    <AppLayout>
      <BulkPaymentCreateForm
        onSubmit={handleSubmit}
        initialValues={{}}
      />
    </AppLayout>
  )
}

export default BulkPaymentCreateContainer
