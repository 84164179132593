import React, { useCallback, useMemo } from 'react'
import { useGetDetail } from 'hooks/useGetDetail'
import { usePut } from 'hooks/usePut'
import { useSnackbar } from 'context/snackbar'
import { useGetList } from 'hooks/useGetList'

import SortingUpdateForm from './components/SortingUpdateForm'
import { initialSortingValues, sortingUpdateSerializer } from './serializer'
import { getByIdSorting, updateSorting } from './api'

import { getAvailableColumns } from '../../api'

interface Props {
  readonly id:number
  readonly onBack:() => void
}

const SortingUpdate = ({ id, onBack }:Props) => {
  const snackbar = useSnackbar()
  const columnDetail = useGetDetail(getByIdSorting, { params: { id } })
  const columnUpdate = usePut(updateSorting)
  const columnList = useGetList(getAvailableColumns, { params: { type: 'SALES_ORDER_LIST_PANEL' } })
  const onUpdate = useCallback((values:any) => {
    columnUpdate.putData({ data:sortingUpdateSerializer(values) })
      .then(() => onBack())
      .then(() => snackbar({ message: 'Вкладка успешно обновлён' }))
  }, [])

  const initialValues = useMemo(() =>
    initialSortingValues(columnDetail.result),
  [columnDetail])

  return (
    <>
      {!columnDetail.loading && (
        <SortingUpdateForm
          onBack={onBack}
          initialValues={initialSortingValues(initialValues)}
          onSubmit={onUpdate}
          columnList={columnList}
        />
      )}
    </>
  )
}

export default SortingUpdate
