import React, { useEffect, useState } from 'react'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton
} from '@mui/material'
import { useTranslationI18n } from 'i18n/I18nContext'
import { path, prop } from 'ramda'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import TextOverflow from 'components/main/TextOverflow'
import TableSkeletonList from 'components/table/TableSkeletonList'
import FlexBox from 'components/main/FlexBox'
import { useField } from 'formik'
import { useGetList } from 'hooks/useGetList'
import { makeStyles } from '@mui/styles'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import cx from 'classnames'
import SwitchField from 'components/form/SwitchField'

import { getAvailableColumns } from '../../../../../api'

export const useStyles = makeStyles({
  tableRoot:{
    maxHeight:'460px',
    borderRadius:'7px',
    border:'1px solid #E9E9E9',
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHeader: {
    height:'36px',
    '& .MuiTableCell-root': {
      color: '#202020',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      lineHeight: 'unset',
      background: '#F1F1F1',
    }
  },
  tableBody:{
    '& .MuiTableCell-root': {
      height:'46px',
      padding: '2px 16px',
      fontSize:'13px',
      lineHeight:'15.73px',
      fontWeight: 400,
      borderBottom: '1px solid #F1F1F1',
      color:'#020020'
    },
    '& .MuiTableRow-root': {
      cursor:'pointer',
      transition: '0.23s',
      '&.selected': {
        background: '#F6F8FD !important'
      }
    },
    '& .MuiTableRow-root:hover': {
      background:'#FAFAFA'
    }
  },
})

interface Props {
  readonly initial: any
  readonly name:string
}

const getTranslatedColumn = (code:string, name:string) => {
  if (code === 'NAME') {
    return 'Наименование товара'
  }
  if (code === 'SHORT_NAME') {
    return 'Короткое наименование'
  }
  if (code === 'SORT_NUMBER') {
    return 'Порядковый номер'
  }
  if (code === 'PIECES') {
    return 'Штук'
  }
  if (code === 'CODE') {
    return 'Штрих-код'
  }
  if (code === 'PRODUCED_IN') {
    return 'Страна'
  }
  if (code === 'DESCRIPTION') {
    return 'Описание'
  }
  if (code === 'SKU') {
    return 'Артикул'
  }
  if (code === 'WEIGHT') {
    return 'Вес нетто'
  }
  if (code === 'WEIGHT_BRUTTO') {
    return 'Вес брутто'
  }
  if (code === 'HEIGHT') {
    return 'Высота'
  }
  if (code === 'PRODUCT_TYPE') {
    return 'Тип продукта'
  }
  if (code === 'SUPPLIERS') {
    return 'Контрагенты'
  }
  if (code === 'CATEGORIES') {
    return 'Продуктовая корзина'
  }
  if (code === 'PACKAGE_LINE_ITEM') {
    return 'Упаковки'
  }
  if (code === 'BRAND') {
    return 'Бренд'
  }
  if (code === 'UNIT_MEASUREMENT') {
    return 'Единица измерения'
  }
  if (code === 'ENABLE_EXPIRY_DATE_TRACKING') {
    return 'Отслеживание срока действия'
  }
  if (code === 'ENABLE_BATCH_NUMBERS') {
    return 'Номера пакетов'
  }
  if (code === 'MODIFIED_DATE') {
    return 'Дата изменения'
  }
  if (code === 'CREATED_DATE') {
    return 'Дата создания'
  }
  if (code === 'CREATED_BY') {
    return 'Сделано'
  }
  if (code === 'MODIFIED_BY') {
    return 'Модифицирован'
  }
  if (code === 'SELL_IN_PACKAGES') {
    return 'Продовать только в упаковке'
  }
  if (code === 'STATUS') {
    return 'Статус'
  }
  return name
}

function FilterColumns ({ initial, name }: Props) {
  const api = useGetList(getAvailableColumns, { params:{ type:'PRODUCT_LIST_PANEL' } })
  const { t } = useTranslationI18n()
  const classes = useStyles()
  const field = useField(name)
  const columnsValue = field[0].value
  const fieldSetValue = path([2, 'setValue'], field)
  const [columns, setColumns] = useState([] as any)
  const [selected, setSelected] = useState([] as any)
  const loading = prop('loading', api)

  useEffect(() => {
    api.getList()
      .then((response:any) => setColumns(response))
    setSelected(initial)
  }, [])

  const handleCheck = (checked: boolean, variant: any) => {
    const id = prop('code', variant)
    if (checked) {
      setSelected((prevSelected: any) => prevSelected.filter((select: any) => {
        return prop('code', select) !== id
      }))
    } else {
      setSelected((prevSelected: any) => [...prevSelected, variant])
    }
  }

  useEffect(() => {
    fieldSetValue(selected)
  }, [selected])

  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} lg={6}>
        <TableContainer className={classes.tableRoot}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell width={200}>{t('Колонки')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {!loading && columns.map((variant: any) => {
                const id = prop('code', variant)
                const checked = selected.find((select: any) => prop('code', select) === id)
                const code = prop('code', variant)
                const name = prop('name', variant)
                return (
                  <TableRow
                    className={cx({ 'selected': checked })}
                    onClick={() => handleCheck(checked, variant)}>
                    <TableCell>
                      <FlexBox align="center" gap="8px">
                        <Checkbox checked={Boolean(checked)} color="secondary" />
                        <TextOverflow lines={1}>
                          {getTranslatedColumn(code, name)}
                        </TextOverflow>
                      </FlexBox>
                    </TableCell>
                  </TableRow>
                )
              })}
              {loading && (
                <TableSkeletonList columnsCount={3} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item={true} lg={6}>
        <TableContainer className={classes.tableRoot}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell width={200}>{t('Выбранные колонки')}</TableCell>
                <TableCell width={200}>{t('Заморозить колонки')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {!loading && columnsValue?.map((variant: any, index:number) => {
                const id = prop('code', variant)
                const checked = columnsValue.find((select: any) => prop('code', select) === id)
                const code = prop('code', variant)
                const name = prop('name', variant)
                return (
                  <TableRow>
                    <TableCell>
                      <TextOverflow lines={1}>
                        {getTranslatedColumn(code, name)}
                      </TextOverflow>
                    </TableCell>
                    <TableCell
                      sx={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'space-between'
                      }}
                    >
                      <SwitchField name={`columns[${index}].freeze`} />
                      <IconButton onClick={() => handleCheck(checked, variant)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              {selected.length === 0 && (
                <TableRow>
                  <TableCell>
                    Выберите из списка слева
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default FilterColumns
